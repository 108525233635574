export function convertDBOToInsertSql(dbo, table, conflictStrategy) {
  if (!dbo || Object.keys(dbo).length <= 0) {
    throw new Error('dbo is null or empty');
  }

  if (!table) {
    throw new Error('table is null or empty');
  }

  let columns = '';
  let values = '';
  Object.keys(dbo).forEach(k => {
    columns += `${k},`;
    const v_t = typeof dbo[k];
    values +=
      dbo[k] === null || dbo[k] === undefined
        ? 'null,'
        : v_t === 'string'
        ? `'${dbo[k].replaceAll("'", "''")}',`
        : `${dbo[k]},`;
  });
  columns = columns.substring(0, columns.length - 1);
  values = values.substring(0, values.length - 1);
  let sql = `INSERT ${
    conflictStrategy ? ' OR ' + conflictStrategy : ''
  } INTO ${table} (${columns}) VALUES (${values});`;
  return sql;
}

export function convertDBOToUpsertSql(dbo, table, key) {
  if (!dbo || Object.keys(dbo).length <= 0) {
    throw new Error('dbo is null or empty');
  }

  if (!table) {
    throw new Error('table is null or empty');
  }

  if (!key) {
    throw new Error('key is null or empty');
  }

  let columns = [];
  let values = [];
  let updates = '';
  Object.keys(dbo).forEach(k => {
    columns.push(k);
    const v_t = typeof dbo[k];
    if (dbo[k] === null || dbo[k] === undefined) {
      values.push('null');
    } else if (v_t === 'string') {
      values.push(`'${dbo[k].replaceAll("'", "''")}'`);
    } else {
      values.push(`${dbo[k]}`);
    }
  });

  updates = (() => {
    return columns
      .map((t, i) => {
        return `${t}=${values[i]}`;
      })
      .join(',');
  })();
  const sql = `INSERT OR REPLACE INTO ${table} (${columns.join(',')}) VALUES (${values.join(
    ','
  )});`;
  //ON CONFLICT(${key}) DO UPDATE SET ${updates};`;
  return sql;
}

export function convertDBOArrayToUpsertSql(dboArray, dboConvertFunc, table) {
  if (!dboArray || dboArray.length <= 0) {
    throw new Error('dboArray is null or empty');
  }

  if (!table) {
    throw new Error('table is null or empty');
  }

  let columns = [];
  let values = [];
  let valuesSql = '';
  //warm up
  let dbo = dboConvertFunc(dboArray[0]);

  Object.keys(dbo).forEach(k => {
    columns.push(k);
    const v_t = typeof dbo[k];
    if (dbo[k] === null || dbo[k] === undefined) {
      values.push('null');
    } else if (v_t === 'string') {
      values.push(`'${dbo[k].replaceAll("'", "''")}'`);
    } else {
      values.push(`${dbo[k]}`);
    }
  });
  valuesSql = '(' + values.join(',') + ')';

  //iterate through the array and generate sql values
  for (let i = 1; i < dboArray.length; i++) {
    dbo = dboConvertFunc(dboArray[i]);
    values = [];
    columns.forEach(k => {
      const v_t = typeof dbo[k];
      if (dbo[k] === null || dbo[k] === undefined) {
        values.push('null');
      } else if (v_t === 'string') {
        values.push(`'${dbo[k].replaceAll("'", "''")}'`);
      } else {
        values.push(`${dbo[k]}`);
      }
    });
    valuesSql += ',(' + values.join(',') + ')';
  }

  const sql = `INSERT OR REPLACE INTO ${table} (${columns.join(',')}) VALUES ${valuesSql};`;
  return sql;
}
