export function vehicleStatsObjectToDBO(vehicleStats) {
  return {
    vehicle_id: vehicleStats.vehicleId,
    trip_id: vehicleStats.tripId,
    current_user_id: vehicleStats.currentUserId,
    gps_odometer: vehicleStats.gpsOdometer,
    gps_odometerRef: vehicleStats.gpsOdometerRef,
    gps_engineHours: vehicleStats.gpsEngineHours,
    gps_engine_hours_ref: vehicleStats.gpsEngineHoursRef,
    can_diff_odometer: vehicleStats.canDiffOdometer,
    can_diff_oOdometer_ref: vehicleStats.canDiffOOdometerRef,
    can_diff_engine_hours: vehicleStats.canDiffEngineHours,
    can_diff_engine_hours_ref: vehicleStats.canDiffEngineHoursRef,
    can_diff_fuel: vehicleStats.canDiffFuel,
    can_diff_fuel_ref: vehicleStats.canDiffFuelRef,
    can_odometer: vehicleStats.canOdometer,
    can_engine_hours: vehicleStats.canEngineHours,
    can_fuel: vehicleStats.canFuel,
    last_readings_at: vehicleStats.lastReadingsAt,
    current_device_id: vehicleStats.currentDeviceId,
    current_user: vehicleStats.currentUser ? JSON.stringify(vehicleStats.current_user) : null,
    meters: vehicleStats.meters ? JSON.stringify(vehicleStats.meters) : null,
    runsheet_id: vehicleStats.runsheetId,
    position: vehicleStats.position,
    gps: vehicleStats.gps ? JSON.stringify(vehicleStats.gps) : null,
    eld_seq: vehicleStats.eldSeq,
    vin_status: vehicleStats.vinStatus,
    last_auth_at: vehicleStats.lastAuthAt,
    last_eld_diagnostic_codes: vehicleStats.lastEldDiagnosticCodes
      ? JSON.stringify(vehicleStats.last_eld_diagnostic_codes)
      : null,
    last_eld_diagnostics_at: vehicleStats.lastEldDiagnosticsAt,
    current_user2_id: vehicleStats.currentUser2Id,
    current_user2: vehicleStats.currentUser2 ? JSON.stringify(vehicleStats.current_user2) : null,
    last_trip_started_at: vehicleStats.lastTripStartedAt,
    cumulated_range: vehicleStats.cumulatedRange,
    cumulated_energy_usage: vehicleStats.cumulatedEnergyUsage,
    last_bleconfig: vehicleStats.lastBleconfig,
    last_bleconfig_at: vehicleStats.lastBleconfigAt,
    last_event_type: vehicleStats.lastEventType,
    last_event_subtype: vehicleStats.lastEventSubtype,
    last_event_at: vehicleStats.lastEventAt,
    last_event_origin: vehicleStats.lastEventOrigin,
    attr: vehicleStats.attr,
    fleets: vehicleStats.fleets ? JSON.stringify(vehicleStats.fleets) : null,
    tacho_fatigue_status_event: vehicleStats.tachoFatigueStatusEvent
      ? JSON.stringify(vehicleStats.tacho_fatigue_status_event)
      : null,
    ev_device_meters: vehicleStats.evDeviceMeters
      ? JSON.stringify(vehicleStats.ev_device_meters)
      : null
  };
}

export function vehicleStatsDBOToObject(vehicleStats) {
  return {
    vehicleId: vehicleStats.vehicle_id,
    tripId: vehicleStats.trip_id,
    currentUserId: vehicleStats.current_user_id,
    gpsOdometer: vehicleStats.gps_odometer,
    gpsOdometerRef: vehicleStats.gps_odometerRef,
    gpsEngineHours: vehicleStats.gps_engineHours,
    gpsEngineHoursRef: vehicleStats.gps_engine_hours_ref,
    canDiffOdometer: vehicleStats.can_diff_odometer,
    canDiffOOdometerRef: vehicleStats.can_diff_oOdometer_ref,
    canDiffEngineHours: vehicleStats.can_diff_engine_hours,
    canDiffEngineHoursRef: vehicleStats.can_diff_engine_hours_ref,
    canDiffFuel: vehicleStats.can_diff_fuel,
    canDiffFuelRef: vehicleStats.can_diff_fuel_ref,
    canOdometer: vehicleStats.can_odometer,
    canEngineHours: vehicleStats.can_engine_hours,
    canFuel: vehicleStats.can_fuel,
    lastReadingsAt: vehicleStats.last_readings_at,
    currentDeviceId: vehicleStats.current_device_id,
    currentUser: vehicleStats.current_user ? JSON.parse(vehicleStats.current_user) : undefined,
    meters: vehicleStats.meters ? JSON.parse(vehicleStats.meters) : [],
    runsheetId: vehicleStats.runsheet_id,
    position: vehicleStats.position,
    gps: vehicleStats.gps ? JSON.parse(vehicleStats.gps) : undefined,
    eldSeq: vehicleStats.eld_seq,
    vinStatus: vehicleStats.vin_status,
    lastAuthAt: vehicleStats.last_auth_at,
    lastEldDiagnosticCodes: vehicleStats.last_eld_diagnostic_codes
      ? JSON.parse(vehicleStats.last_eld_diagnostic_codes)
      : undefined,
    lastEldDiagnosticsAt: vehicleStats.last_eld_diagnostics_at,
    currentUser2Id: vehicleStats.current_user2_id,
    currentUser2: vehicleStats.current_user2 ? JSON.parse(vehicleStats.current_user2) : undefined,
    lastTripStartedAt: vehicleStats.last_trip_started_at,
    cumulatedRange: vehicleStats.cumulated_range,
    cumulatedEnergyUsage: vehicleStats.cumulated_energy_usage,
    lastBleconfig: vehicleStats.last_bleconfig,
    lastBleconfigAt: vehicleStats.last_bleconfig_at,
    lastEventType: vehicleStats.last_event_type,
    lastEventSubtype: vehicleStats.last_event_subtype,
    lastEventAt: vehicleStats.last_event_at,
    lastEventOrigin: vehicleStats.last_event_origin,
    attr: vehicleStats.attr,
    fleets: vehicleStats.fleets ? JSON.parse(vehicleStats.fleets) : undefined,
    tachoFatigueStatusEvent: vehicleStats.tacho_fatigue_status_event
      ? JSON.parse(vehicleStats.tacho_fatigue_status_event)
      : undefined,
    evDeviceMeters: vehicleStats.ev_device_meters
      ? JSON.parse(vehicleStats.ev_device_meters)
      : undefined
  };
}
