import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Space, Typography, Select } from 'antd';
import styles from '../VehiclesBulkEdit.module.scss';
import { useCan } from 'features/permissions';
import { useDispatch } from 'react-redux';
import { prepareVehicleTypes } from '../../helpers';
import { fetchVehicleTypesByCompany } from 'features/vehicles/vehicleTypesSlice';

export const TypeSelectModal = ({ companyId, show, onOk, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();
  const [value, setValue] = useState();
  const [vehicleTypes, setVehicleTypes] = useState([]);

  useEffect(async () => {
    const types = await dispatch(fetchVehicleTypesByCompany(companyId));
    setVehicleTypes(prepareVehicleTypes(types, companyId));
  }, [companyId]);

  return (
    <Modal
      okButtonProps={{ disabled: !value }}
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(value);
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{`${t('Vehicles.Form.Type')}`}</Typography.Title>
        <Select
          allowClear
          showSearch
          options={vehicleTypes}
          filterOption={(input, option) =>
            option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          onChange={v => {
            setValue(v);
          }}
          placeholder={t('Vehicles.Form.TypePlaceholder')}
        />
      </Space>
    </Modal>
  );
};
