import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePageTitle, useBackButton } from 'features/page/pageSlice';
import { useUserInfo } from 'features/user/userSlice';

import CanRoute from 'components/route/CanRoute';
import Page from 'components/pages/Page';
import { NoAccessBody } from 'components/pages/NoAccess';

import { administrationPages, configurationPages, userPages } from 'Pages';
import GlobalRoles from 'features/permissions/GlobalRoles';
import entities from 'features/permissions/entities';
import {
  useCan,
  useCanOneOfEntities,
  useCanOneOfRoles,
  useCanEveryCompanyService,
  services,
  useIQCameraUser
} from 'features/permissions';

const Body = () => {
  const { t } = useTranslation();
  const user = useUserInfo();
  const can = useCan();
  const canAdminRole = useCanOneOfRoles([
    GlobalRoles.Admin,
    GlobalRoles.IQCameraAdministrator,
    GlobalRoles.SubCompanyAdmin
  ]);
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const canBulkImport = useCanOneOfEntities([entities.BULKIMPORT]);
  const canResellerRole = useCanOneOfRoles([GlobalRoles.Reseller]);
  const isAssetsUsage = useCanEveryCompanyService([services.ASSETSUSAGE]);
  const canCompanyConfig = canAdminRole || canBulkImport || canResellerRole || isAssetsUsage;
  const configMenuItems = configurationPages(
    t,
    user,
    canCompanyConfig,
    canAccessNonCameraFeatures
  ).filter(page => can({ ...page }));

  const userMenuItems = userPages(t, canAccessNonCameraFeatures).filter(page => can({ ...page }));

  const subPages = administrationPages(t)
    .concat(configMenuItems)
    .concat(userMenuItems);

  const subPageComponents = subPages.map(page => {
    const Component = page.component;
    return (
      <CanRoute
        key={page.path}
        path={page.path}
        exact={page.exact}
        everyService={page.everyService}
        everyEntity={page.everyEntity}
        oneOfServices={page.oneOfServices}
        oneOfEntities={page.oneOfEntities}
        everyRole={page.everyRole}
        oneOfRoles={page.oneOfRoles}
        companyConfigs={page.companyConfigs}
        featureFlag={page.featureFlag}
        featureHide={page.featureHide}
        onFail={<NoAccessBody />}
        render={() => {
          return <Component key={page.path} />;
        }}
      />
    );
  });

  return subPageComponents;
};

export const AppSettings = () => {
  const backButton = useBackButton();
  const title = usePageTitle();

  return (
    <Page
      page={{
        title,
        useBackButton: backButton,
        component: Body
      }}
    />
  );
};
