import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { orderBy, isEqual, sortBy } from 'lodash';

import { updateDevicesSnapshotsQueryParams } from 'features/dashboard/dashboardSlice';
import { fetchSnapshots } from 'features/camera/cameraApi';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { prepareDataForMultiselect } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';

import { RECIPIENT_TYPE } from 'containers/Messaging/constants';

import { useCompanySnapshotsEntities } from './useCompanySnapshotsEntities';
import { getPositionedFootages, SortInitValue } from '../helpers';

const RowCountLimit = 100;
const useFilter = ({
  allFleets = [],
  allVehicles = [],
  allCompanies = [],
  companyId,
  vehiclesAndDevicesIds = {}
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryParams = useSelector(state => state.dashboardData.devicesSnapshots.queryParams);

  const {
    period,
    rowOffset,
    selectedYear,
    selectedMonth,
    from,
    to,
    searchText,
    orderField,
    orderDirection
  } = useMemo(
    () => ({
      selectedYear: queryParams.period.from.get('year'),
      selectedMonth: queryParams.period.from.get('month') + 1,
      from: queryParams.period.from.format(),
      to: queryParams.period.to.format(),
      rowOffset: queryParams.rowOffset,
      searchText: queryParams.searchText,
      orderField: queryParams.orderField,
      orderDirection: queryParams.orderDirection
    }),
    [queryParams]
  );

  const [filterEntities, setFilterEntities] = useState({
    companies: [],
    fleets: [],
    vehicles: []
  });
  const [sortOption, setSortOption] = useState(SortInitValue);

  const filterText = useDebounce(searchText, 300);
  const setRowOffset = useCallback(rowOffset =>
    dispatch(updateDevicesSnapshotsQueryParams({ rowOffset }))
  );

  const { filterCompanies, filterFleets, filterVehicles, filteredDeviceIds } = useMemo(() => {
    const allCompanyOptions = prepareDataForMultiselect(
      allCompanies,
      t('Common.AllCompanies'),
      null
    );
    const allFleetOptions = prepareDataForMultiselect(
      sortBy(allFleets, 'name'),
      t('Common.AllFleets'),
      null
    );
    const allVehicleOptions = prepareDataForMultiselect(
      sortBy(allVehicles, 'name'),
      t('Common.AllVehicles'),
      null
    );

    const { companies, fleets, vehicles } = filterEntities;
    const filterCompanies = companies.length === 0 ? allCompanyOptions : companies;
    const filterFleets = fleets.length === 0 ? allFleetOptions : fleets;
    const filterVehicles = vehicles.length === 0 ? allVehicleOptions : vehicles;

    const deviceIds = new Set();
    for (const v of filterVehicles) {
      if (!v.checked || !v?.id) {
        continue;
      }
      const id = String(v.id);
      if (id.startsWith('vehicle_')) {
        for (const deviceId of Object.keys(vehiclesAndDevicesIds[id] || {})) {
          deviceIds.add(deviceId);
        }
      } else if (id.startsWith('device_')) {
        deviceIds.add(id.replace('device_', ''));
      }
    }
    return {
      filterFleets,
      filterCompanies,
      filterVehicles,
      filteredDeviceIds: Array.from(deviceIds)
    };
  }, [t, allFleets, allCompanies, allVehicles, filterEntities, vehiclesAndDevicesIds]);

  useEffect(() => {
    setFilterEntities({
      companies: [],
      fleets: [],
      vehicles: []
    });
    dispatch(
      updateDevicesSnapshotsQueryParams({
        companyId,
        rowCountLimit: RowCountLimit,
        rowOffset: 0
      })
    );
  }, [dispatch, companyId]);

  useEffect(() => {
    return () => {
      dispatch(
        updateDevicesSnapshotsQueryParams({
          searchText: '',
          orderDirection: 'desc',
          orderField: 'EventTime',
          period: {
            from: moment()
              .startOf('month')
              .startOf('day'),
            to: moment()
              .endOf('month')
              .endOf('day')
          }
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      updateDevicesSnapshotsQueryParams({
        deviceIds: filteredDeviceIds
      })
    );
  }, [dispatch, filteredDeviceIds]);

  useEffect(() => {
    dispatch(
      updateDevicesSnapshotsQueryParams({
        orderField: sortOption.item === 'date' ? 'EventTime' : 'Vehicle',
        orderDirection: sortOption.order,
        rowOffset: 0
      })
    );
  }, [dispatch, sortOption]);

  const handleFilterCompanies = useCallback(
    pCompanyOps => {
      const noFleet = allFleets.find(f => f.id === -1);
      let fleets = [];
      if (noFleet) {
        const noFleetCompanies = Object.assign({}, noFleet.companyId);
        pCompanyOps.forEach(c => {
          if (!c.checked) delete noFleetCompanies[c.id];
          let companyFleets = allFleets.filter(f => c.checked && f.companyId === c.id);
          if (companyFleets.length > 0) {
            fleets = fleets.concat(companyFleets);
          }

          if (noFleetCompanies[c.id] && !fleets.find(f => f.id === -1)) {
            let fleet = Object.assign({}, noFleet);
            fleet.companyId = noFleetCompanies;
            fleets.push(fleet);
          }
        });
      }
      setFilterEntities(entities => {
        const fleetsOptions = prepareDataForMultiselect(
          sortBy(fleets, 'name'),
          t('Common.AllFleets'),
          null
        );
        return {
          ...entities,
          companies: pCompanyOps,
          fleets: fleetsOptions,
          vehicles: prepareDataForMultiselect(
            sortBy(
              allVehicles.filter(
                v =>
                  fleetsOptions.some(i => v.fleetIds[i.id] && i.checked) &&
                  pCompanyOps.find(c => c.id === v.companyId)?.checked
              ),
              'name'
            ),
            t('Common.AllVehicles'),
            null
          )
        };
      });
    },
    [allFleets, allVehicles, t]
  );

  const handleFilterFleets = useCallback(
    fleets => {
      const vehicles = allVehicles.filter(
        v =>
          fleets.some(i => v.fleetIds[i.id] && i.checked) &&
          filterCompanies?.find(c => c.id === v.companyId)?.checked
      );
      setFilterEntities(entities => {
        const vehiclesOptions = prepareDataForMultiselect(
          sortBy(vehicles, 'name'),
          t('Common.AllVehicles'),
          null
        );
        return {
          ...entities,
          fleets,
          vehicles: vehiclesOptions
        };
      });
    },
    [allVehicles, filterCompanies, t]
  );

  const handleFilterVehicles = useCallback(
    vehicles =>
      setFilterEntities(entities => ({
        ...entities,
        vehicles
      })),
    []
  );

  const handleSearch = useCallback(
    e =>
      dispatch(
        updateDevicesSnapshotsQueryParams({
          searchText: e,
          rowOffset: 0
        })
      ),
    [dispatch]
  );

  const handleMonthSelected = useCallback(
    e =>
      dispatch(
        updateDevicesSnapshotsQueryParams({
          period: {
            from: moment(e)
              .startOf('month')
              .startOf('day'),
            to: moment(e)
              .endOf('month')
              .endOf('day')
          },
          rowOffset: 0
        })
      ),
    [dispatch]
  );

  return {
    sortOption,
    filterText,
    filterCompanies,
    onFilterCompanies: handleFilterCompanies,
    filterFleets,
    onFilterFleets: handleFilterFleets,
    filterVehicles,
    filteredDeviceIds,
    onFilterVehicles: handleFilterVehicles,
    onSearch: handleSearch,
    onMonthSelected: handleMonthSelected,
    onSortChange: setSortOption,
    selectedYear,
    selectedMonth,
    period,
    from,
    to,
    orderField,
    orderDirection,
    rowOffset,
    setRowOffset
  };
};

export const useSnapshots = ({ showNonCameraVehicle = false }) => {
  const {
    isLoading,
    snapshotsEntities: entities,
    fleets: allFleets,
    vehiclesAndDevices: allVehicles,
    companies: allCompanies,
    companyId,
    vehiclesAndDevicesIds
  } = useCompanySnapshotsEntities({ showNonCameraVehicle });

  const {
    sortOption,
    filterText,
    selectedYear,
    selectedMonth,
    filterVehicles,
    filteredDeviceIds,
    from,
    to,
    orderField,
    orderDirection,
    rowOffset,
    setRowOffset,
    ...filterProps
  } = useFilter({
    allCompanies,
    allFleets,
    allVehicles,
    companyId,
    vehiclesAndDevicesIds
  });

  const [showPopup, setShowpopup] = useState(false);
  const [showFootageModal, setShowFootageModal] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [requestVideoDeviceId, setRequestVideoDeviceId] = useState(null);
  const [requestVideoVehicleId, setRequestVideoVehicleId] = useState(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const reqCtxRef = useRef({
    request: null,
    ctxParams: null,
    fetchTime: null,
    isFetching: null,
    error: null,
    totalCount: null
  });

  const [data, setData] = useState(null);

  const allLoaded = useMemo(
    () =>
      data?.length &&
      !isNaN(Number(reqCtxRef?.current?.totalCount)) &&
      data.length >= Number(reqCtxRef?.current?.totalCount),
    [data?.length, reqCtxRef?.current?.totalCount]
  );

  useEffect(() => {
    if (!companyId || !filteredDeviceIds?.length) {
      reqCtxRef.current.isFetching = false;
      return;
    }

    const curCtxParams = {
      startTime: from,
      endTime: to,
      rowOffset,
      search: filterText,
      devices: filteredDeviceIds,
      orderField,
      orderDirection
    };

    let sameParams = false,
      prevReqCtx = reqCtxRef.current;
    if (prevReqCtx?.ctxParams) {
      const {
        startTime: prevStart,
        endTime: prevEnd,
        rowOffset: prevOffset,
        search: prevSearch,
        devices: prevIds,
        orderField: prevOrderField,
        orderDirection: prevOrderDirection
      } = prevReqCtx.ctxParams;
      const {
        startTime: curStart,
        endTime: curEnd,
        rowOffset: curOffset,
        search: curSearch,
        devices: curIds,
        orderField: curOrderField,
        orderDirection: curOrderDirection
      } = curCtxParams;
      sameParams =
        isEqual(prevOffset || 0, curOffset || 0) &&
        isEqual(prevSearch || '', curSearch || '') &&
        isEqual(prevStart, curStart) &&
        isEqual(prevEnd, curEnd) &&
        isEqual(prevOrderField, curOrderField) &&
        isEqual(prevOrderDirection, curOrderDirection) &&
        isEqual(new Set(prevIds), new Set(curIds));
    }

    if (
      !prevReqCtx.ctxParams ||
      (!reqCtxRef.current?.isFetching && !prevReqCtx.fetchTime) ||
      !sameParams
    ) {
      const onReqDone = (totalCount, error) => {
        if (reqCtxRef.current.request) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current.request = null;
        reqCtxRef.current.fetchTime = moment().valueOf();
        reqCtxRef.current.isFetching = false;
        reqCtxRef.current.error = error || null;
        if (totalCount !== null) {
          reqCtxRef.current.totalCount = totalCount;
        }
      };

      if (prevReqCtx.request) {
        prevReqCtx.request.abort();
        reqCtxRef.current.request = null;
      }
      reqCtxRef.current.ctxParams = curCtxParams;
      if (curCtxParams.rowOffset === 0) {
        reqCtxRef.current.totalCount = 0;
        setData([]);
      }
      reqCtxRef.current.request = dispatch(
        fetchSnapshots({
          ...curCtxParams,
          rowCountLimit: RowCountLimit,
          latestOnly: true,
          onSuccess: res => {
            onReqDone(res?.totalCount || 0);
            setData(prev =>
              rowOffset === 0 ? res?.events || [] : [...prev, ...(res?.events || [])]
            );
          },
          onError: errMsg => {
            onReqDone(rowOffset === 0 ? 0 : null, errMsg);
            setData(prev => (rowOffset === 0 ? [] : prev));
          }
        })
      );
      const fetchData = async () => {
        try {
          reqCtxRef.current.isFetching = true;
          await reqCtxRef.current.request;
        } catch (e) {
          onReqDone(e);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: parseErrorMessage(e)
            })
          );
        }
      };
      fetchData();
    }
  }, [
    t,
    dispatch,
    reqCtxRef,
    from,
    to,
    rowOffset,
    filterText,
    filteredDeviceIds,
    orderDirection,
    orderField
  ]);

  const { snapshots, snapshotsCount } = useMemo(() => {
    if (!filteredDeviceIds?.length) {
      return {
        snapshots: [],
        snapshotsCount: 0
      };
    }
    const snapshots = data?.length
      ? orderBy(
          Object.values(
            data.reduce((acc, snapshotEvent, eventOrder) => {
              const { deviceId, attachments } = snapshotEvent;
              const matchedEntity = entities.find(entity =>
                (entity.nodeType === 'Vehicle' && entity.devices
                  ? entity.devices
                  : [{ id: entity.deviceId }]
                ).some(d => d.id && String(d.id) === String(deviceId))
              );
              if (matchedEntity) {
                acc[matchedEntity.nodeId] = acc[matchedEntity.nodeId] || {
                  ...matchedEntity,
                  eventOrder,
                  footages: []
                };
                acc[matchedEntity.nodeId].footages.push(...attachments);
              }
              return acc;
            }, {})
          ).map(entity => {
            const positionedFootages = getPositionedFootages(entity.footages);
            return {
              ...entity,
              positionedFootages,
              snapshotsCount: Object.values(positionedFootages).filter(f => !!f).length
            };
          }),
          ['eventOrder'],
          ['asc']
        )
      : [];
    return {
      snapshots,
      snapshotsCount: snapshots.reduce((a, s) => a + s.snapshotsCount, 0)
    };
  }, [entities, data, sortOption, filteredDeviceIds]);

  const handleMessageVehicle = useCallback(({ id, deviceId }) => {
    const recipient = !!id
      ? {
          recipientType: RECIPIENT_TYPE.VEHICLE,
          recipientId: id
        }
      : {
          recipientType: RECIPIENT_TYPE.DEVICE,
          recipientId: deviceId
        };
    setRecipient(recipient);
    setShowpopup(true);
  }, []);

  const handleRequestVideo = useCallback((vehicleId, deviceId) => {
    setRequestVideoDeviceId(deviceId);
    setRequestVideoVehicleId(vehicleId);
    setShowFootageModal(true);
  }, []);

  const onLoadMore = useCallback(() => setRowOffset(data?.length || 0), [data?.length]);

  return {
    isDataFetching: isLoading,
    filters: {
      snapshotsCount,
      filterText,
      filterVehicles,
      selectedYear,
      selectedMonth,
      sortOption,
      ...filterProps
    },
    snapshots: {
      isLoading: reqCtxRef.current.isFetching === null || reqCtxRef.current.isFetching,
      snapshots,
      snapshotsCount,
      snapshotYear: selectedYear,
      snapshotMonth: selectedMonth,
      onLoadMore,
      allLoaded,
      onMessageVehicle: handleMessageVehicle,
      onRequestFootage: handleRequestVideo
    },
    messagingProps: {
      showPopup,
      visible: showPopup,
      showModal: setShowpopup,
      recipients: [recipient]
    },
    requestVideoProps: {
      showFootageModal,
      showModal: showFootageModal,
      vehicleId: requestVideoVehicleId,
      deviceId: requestVideoDeviceId,
      onClose: () => {
        setShowFootageModal(false);
      }
    }
  };
};
