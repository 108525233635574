import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { useEffect } from 'react';
import { vehicleStatsDataAdapter } from 'data/dataService/tn/adapter/vehicleStats';
import dayjs from 'dayjs';

export const vehiclesStats = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false,
    companyId: null,
    fetchingCompanyId: null
  }
};

function startLoading(state, action) {
  state.meta.isFetching = true;
  state.meta.fetchingCompanyId = action.payload.companyId;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyId = action.payload.companyId;
  state.meta.fetchingCompanyId = null;
}
const vehiclesStatsSlice = createSlice({
  name: 'vehiclesStats',
  initialState: vehiclesStats,
  reducers: {
    fetchVehiclesStatsStart: startLoading,
    fetchVehiclesStatsSuccess(state, { payload }) {
      state.list = payload.list;
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyId = payload.companyId;
      state.meta.fetchingCompanyId = null;
    },
    fetchVehiclesStatsFailure: loadingFailed
  }
});

const {
  fetchVehiclesStatsStart,
  fetchVehiclesStatsSuccess,
  fetchVehiclesStatsFailure
} = vehiclesStatsSlice.actions;

export const fetchVehiclesStats = () => async (dispatch, getState) => {
  const companyId = getState().companies?.current?.id;
  const userKey = getState().user?.current?.auth?.key;
  const isFetchingSameCompanyId =
    getState().vehiclesStats?.meta?.isFetching &&
    getState().vehiclesStats?.meta?.fetchingCompanyId === companyId;
  try {
    if (!companyId || !userKey || isFetchingSameCompanyId) {
      return;
    }
    dispatch(fetchVehiclesStatsStart({ companyId }));
    let requestUrl = `${API_PATH}/vehicles/stats?direction=DOWN&embed=users,meters&pruning=APP&company_id=${companyId}&interim=true`;
    const isLocalCacheAvailable = await vehicleStatsDataAdapter.available();
    if (isLocalCacheAvailable) {
      const lastUpdated = await vehicleStatsDataAdapter.lastUpdatedDate();
      if (lastUpdated) {
        requestUrl += `&last_updated=${encodeURIComponent(lastUpdated)}`;
      }
    }

    request('GET', requestUrl)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(async resp => {
        const data = resp.body;
        let allVehicleStats = null;
        if (isLocalCacheAvailable) {
          await vehicleStatsDataAdapter.persist(data);
          allVehicleStats = await vehicleStatsDataAdapter.load();
          vehicleStatsDataAdapter.updateLastUpdatedDate(dayjs().format());
        } else {
          allVehicleStats = data;
        }

        dispatch(fetchVehiclesStatsSuccess({ list: allVehicleStats, companyId }));
      })
      .catch(err => {
        dispatch(fetchVehiclesStatsFailure({ err: err.toString(), companyId }));
      });
  } catch (err) {
    dispatch(fetchVehiclesStatsFailure({ err: err.toString(), companyId }));
  }
};

export const useVehiclesStats = () => {
  const dispatch = useDispatch();
  const vehiclesStats = useSelector(state => state.vehiclesStats.list);
  const isFetching = useSelector(state => state.vehiclesStats.meta.isFetching);
  const isListEmpty = useSelector(state => state.vehiclesStats.meta.isListEmpty);
  const currentCompanyId = useCurrentCompanyId();
  const lastFetchedCompanyId = useSelector(state => state.vehiclesStats.meta.companyId);
  const isCompanyIdDifferent = currentCompanyId !== lastFetchedCompanyId;

  useEffect(() => {
    if (!isFetching && (isCompanyIdDifferent || (vehiclesStats.length === 0 && !isListEmpty))) {
      dispatch(fetchVehiclesStats());
    }
  }, [isFetching, isCompanyIdDifferent, vehiclesStats.length, isListEmpty, currentCompanyId]);

  return vehiclesStats;
};

export const useIsFetchingVehicleStats = () =>
  useSelector(state => state.vehiclesStats.meta.isFetching);

export default vehiclesStatsSlice.reducer;
