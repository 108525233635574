import { Button } from 'antd';
import { DeleteFilled, DeleteOutlined } from '@ant-design/icons';
import { Cell, Pie, PieChart } from 'recharts';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Widget.module.scss';

const COLORS = ['#4CAF50', '#E0E0E0'];

export const Widget = ({ size, type = 'default', widgetData, widget, handleDelete }) => {
  const sizeClass = styles[widget.size] || styles.small;
  const history = useHistory();

  const handleDeleteWidget = () => {
    handleDelete(widget.id);
  };

  const getWidgetByType = type => {
    switch (type) {
      case 'activeDrivers': {
        const data = [
          {
            name: 'Active Drivers',
            value: widgetData?.activeDrivers
          },
          {
            name: 'Inactive Drivers',
            value: widgetData?.inactiveDrivers
          }
        ];
        return (
          <>
            <div className={styles.widgetTitle} onClick={() => history.push('/tracking')}>
              Active Drivers
            </div>
            <div className={styles.boxContainer}>
              <div className={styles.chartContainer}>
                <PieChart width={100} height={100}>
                  <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={50}
                    startAngle={90}
                    endAngle={-270}
                    fill="#8884d8"
                    paddingAngle={0}
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
              <div className={styles.dataContainer}>
                <div>
                  <span className={styles.dataValue}>
                    {widgetData?.activeDrivers + widgetData?.inactiveDrivers}
                  </span>{' '}
                  Drivers
                </div>
                {data.map(d => (
                  <div>
                    <span className={styles.dataValue}>{d.value}</span> {d.name}
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      }
      case 'activeDevices': {
        const data = [
          {
            name: 'Active',
            value: widgetData?.active
          },
          {
            name: 'Inactive',
            value: widgetData?.inactive
          }
        ];
        return (
          <>
            <div className={styles.widgetTitle} onClick={() => history.push('/tracking')}>
              Active Assets
            </div>
            <div className={styles.boxContainer}>
              <div className={styles.chartContainer}>
                <PieChart width={100} height={100}>
                  <Pie
                    data={data}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={30}
                    outerRadius={50}
                    startAngle={90}
                    endAngle={-270}
                    fill="#8884d8"
                    paddingAngle={0}
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
              <div className={styles.dataContainer}>
                <div>
                  <span className={styles.dataValue}>
                    {widgetData?.vehicles + widgetData?.devices}
                  </span>{' '}
                  Vehicles & Devices
                </div>
                {data.map(d => (
                  <div>
                    <span className={styles.dataValue}>{d.value}</span> {d.name}
                  </div>
                ))}
              </div>
            </div>
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={classNames(styles.widgetContainer, sizeClass)}>
      {getWidgetByType(type)}
      <h4 className={styles.widgetTitle}>{widget.title}</h4>
      <Button
        type="secondary"
        size="small"
        className={styles.widgetDelete}
        onClick={handleDeleteWidget}
      >
        <DeleteFilled className={styles.deleteIcon} />
      </Button>
    </div>
  );
};
