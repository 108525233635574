export function regionObjectToDBO(region) {
  return {
    id: region.id,
    name: region.name,
    code: region.code,
    states: region.states,
    geocode: region.geocode,
    time_zones: region.timezone,
    config: region.config,
    locale: region.locale,
    platform: region.platform,
    nextgen_url: region.nextgenUrl,
    tn360_url: region.tn360Url,
    tn360_pdf_url: region.tn360PdfUrl,
    api_url: region.api_url,
    support_email: region.supportEmail,
    instance: region.instance
  };
}

export function regionDBOToObject(region) {
  return {
    id: region.id,
    name: region.name,
    code: region.code,
    states: region.states,
    geocode: region.geocode,
    timezone: region.time_zones,
    config: region.config,
    locale: region.locale,
    platform: region.platform,
    nextgenUrl: region.nextgen_url,
    tn360Url: region.tn360_url,
    tn360PdfUrl: region.tn360_pdf_url,
    apiUrl: region.api_url,
    supportEmail: region.support_email,
    instance: region.instance
  };
}
