// Author: Prasad K
// Date: 2023-10-12
// Description: This component handles the scheduled report form functionality using Formly.js.

import React from 'react';
import { Field } from '@formily/react';
import { FormItem, Input, Select, Radio, Checkbox } from '@formily/antd';
import { SearchOutlined } from '@ant-design/icons';
import styles from './ScheduledReportForm.module.scss';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const DynamicFormBuilder = ({ form, fields }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.dynamicForm}>
      {fields
        .reduce((rows, field, index) => {
          // Create rows with two components per row
          const lastRow = rows[rows.length - 1];
          if (!lastRow || lastRow.length === 2) {
            rows.push([field]);
          } else {
            lastRow.push(field);
          }
          return rows;
        }, [])
        .map((row, rowIndex) => (
          <div key={rowIndex} className={styles.formRow}>
            {row.map((field, index) => {
              const value = field?.value;
              const commonProps = {
                key: index,
                name: field.name,
                title: (
                  <span>
                    {t(`SupportToolsELD.ScheduledReports.${field.label}`)}
                    <span className={styles.requiredAsterisk}>*</span>
                  </span>
                ), // Correctly set as a property
                decorator: [FormItem]
              };

              switch (field.type) {
                case 'select':
                  return (
                    <Field
                      {...commonProps}
                      component={[
                        Select,
                        {
                          mode: field.multiple ? 'multiple' : 'default',
                          showSearch: true,
                          allowClear: true,
                          className: styles.componentProps,
                          placeholder: t(`SupportToolsELD.ScheduledReports.${field.placeholder}`), // Remove unnecessary brackets around `t`
                          options: field.options,
                          maxTagCount: 'responsive',
                          filterOption: (input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase()),
                          onChange: field.onChange,
                          value: { value },
                          dropdownRender: menu => (
                            <div>
                              <Button type="text" onClick={() => field.toggleAll()}>
                                {field.selectLabel}
                              </Button>
                              {menu}
                            </div>
                          )
                        }
                      ]}
                      validator={value => {
                        if (!value || value.length === 0) {
                          return {
                            type: 'error',
                            message: t(
                              `SupportToolsELD.ScheduledReports.ErrorMessages.${field.name}`
                            )
                          };
                        }
                        return true;
                      }}
                    />
                  );
                case 'radio':
                  return (
                    <Field
                      {...commonProps}
                      component={[
                        Radio.Group,
                        {
                          options: field.options.map(option => ({
                            label: option.label,
                            value: option.value
                          }))
                        }
                      ]}
                    />
                  );
                case 'checkbox':
                  return (
                    <Field
                      {...commonProps}
                      component={[
                        Checkbox.Group,
                        {
                          options: field.options.map(option => ({
                            label: option.label,
                            value: option.value
                          }))
                        }
                      ]}
                    />
                  );
                case 'input':
                  return (
                    <Field
                      {...commonProps}
                      component={[
                        Input,
                        {
                          placeholder: field.placeholder,
                          onChange: field.onChange,
                          required: field.required
                        }
                      ]}
                    />
                  );
                case 'textarea':
                  return (
                    <Field
                      {...commonProps}
                      component={[
                        Input,
                        {
                          type: 'textarea',
                          placeholder: field.placeholder,
                          onChange: field.onChange,
                          required: field.required
                        }
                      ]}
                    />
                  );
                case 'password':
                  return (
                    <Field
                      {...commonProps}
                      component={[
                        Input.Password,
                        {
                          placeholder: field.placeholder,
                          onChange: field.onChange,
                          required: field.required
                        }
                      ]}
                    />
                  );
                case 'email':
                  return (
                    <Field
                      {...commonProps}
                      component={[
                        Input,
                        {
                          type: 'email',
                          placeholder: field.placeholder,
                          onChange: field.onChange,
                          required: field.required
                        }
                      ]}
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        ))}
    </div>
  );
};

export default DynamicFormBuilder;
