import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Switch, Button, Badge, Tooltip } from 'antd';
import moment from 'moment';
import ResizeObserver from 'rc-resize-observer';

import { useLocalization } from 'features/localization/localizationSlice';
import { VehicleParameter } from 'features/vehicles/vehicleParameters';
import {
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey
} from 'features/company/companySlice';

import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import { TabNavLink } from 'components/nav/NavLinks';
import { TrackEventsList } from './TrackEventsList';
import { TrackEventsTripList } from './TrackEventsTripList';
import { NoTripDeviceTypes } from './Track';
import { TrackingPaths } from '../constants';
import { VehicleDetails } from '../Trips/VehicleDetails';
import { VehicleParameters } from '../Trips/VehicleParameters';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { formatDistance } from 'utils/dates';

import { useSupportedEventsFilter } from './Modals/FilterEventsModal/FilterEvents';

import { disableOutsideOfNDays, buildHDDataUrl } from 'containers/Tracking/helpers';

import { FilterEventsModal } from './Modals/FilterEventsModal/FilterEventsModal';

import { useUserGridSettings } from 'features/user/userGridSettingsSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import { ReloadOutlined } from '@ant-design/icons';

import eventStyles from './TrackEvents.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import {
  useIQCameraUser,
  useCan,
  FeatureFlag,
  services,
  useCanFeatureFlag
} from 'features/permissions';
import dayjs from 'dayjs';

export const TrackEventsTabs = {
  Events: 'Events',
  Details: 'Details',
  Parameters: 'Parameters'
};

// Width at which to switch header controls over to their smaller versions
const HEADER_SMALL_WIDTH = 570;
const HEADER_TAB_TOOLTIP_THRESHOLD = 360;

const getTripsCount = trips => {
  let count = 0;
  if (trips) {
    count = trips.filter(trip => trip.IgnOnGPS || trip.IgnOffGPS).length;
  }
  return count;
};

export const TrackEvents = ({
  device,
  trips,
  tab = TrackEventsTabs.Events,
  isLoading,
  gridSettingsKey,
  dateRange,
  datePickerRef,
  selectedTrip,
  selectedEvent,
  onDateRangeSelected,
  onDateRangeClose,
  onTripClicked,
  onEventClicked,
  onDeviceClicked,
  groupByTripsEnabled,
  onGroupByTripsChange
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const vehicles = useVehicles();
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const [hasVehicleParameters, setHasVehicleParameters] = useState(false);
  const [showFilterEventsModal, setShowFilterEventsModal] = useState(false);
  const [groupByTripsSwitchEnabled, setGroupByTripsSwitchEnabled] = useState(groupByTripsEnabled);
  const [width, setWidth] = useState(700);
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const hideNonBusinessTrips = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const can = useCan();
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const loadMoreEnabled = useCanFeatureFlag({ featureFlag: FeatureFlag.trackingLoadMore.flag });

  const showHDData = can({
    everyService: [services.TRACKINGHD],
    everyCompanyService: [services.TRACKINGHD]
  });

  const onGroupByTripsSwitchChange = checked => {
    setGroupByTripsSwitchEnabled(checked);
    onGroupByTripsChange(checked);
  };

  const handleHDDataClick = () => {
    device.id &&
      history.push(
        buildHDDataUrl(
          device.id,
          moment()
            .startOf('day')
            .format(),
          moment()
            .endOf('day')
            .format()
        )
      );
  };

  useEffect(() => {
    if (device?.deviceStats?.lastVpm) {
      try {
        const vpm = JSON.parse(device?.deviceStats?.lastVpm);
        if (
          vpm &&
          Object.keys(vpm).filter(
            key =>
              ![VehicleParameter.Odometer, VehicleParameter.Hours].includes(key) && vpm[key] !== -1
          ).length > 0
        ) {
          setHasVehicleParameters(true);
        }
      } catch (e) {}
    }
  }, [device]);

  useEffect(() => {
    setGroupByTripsSwitchEnabled(groupByTripsEnabled);
  }, [groupByTripsEnabled]);

  // For group by trips, get distance and duration for each trip to pass down to the list for rendering
  // and filter ignition on off events and empty trips
  const tripsForGroupByTrips = useMemo(() => {
    if (!groupByTripsEnabled) {
      return trips;
    }

    // console.debug('TrackEvents - trips', trips);

    let enhancedTrips = [];
    if (trips) {
      enhancedTrips = trips.map(trip => {
        let enhancedTrip = { ...trip };

        // Get tripDistance
        const vehicleDetail = !trip.vehicle?.engineSummarySource
          ? vehicles?.find(v => v.id === trip?.vehicle?.id)
          : trip.vehicle;

        if (trip.odometerInfo?.statusCode === 0 && trip.odometerInfo?.distance) {
          enhancedTrip.tripDistance = trip.odometerInfo?.distance;
        } else if (
          trip.distanceStart &&
          trip.distanceEnd &&
          vehicleDetail &&
          (vehicleDetail.engineSummarySource === 'CAN' ||
            vehicleDetail.engineSummarySource === 'CAN_DIFF')
        ) {
          enhancedTrip.tripDistance = trip.distanceEnd - trip.distanceStart;
        } else if (trip.gpsOdoEnd && trip.gpsOdoStart) {
          enhancedTrip.tripDistance = trip.gpsOdoEnd - trip.gpsOdoStart;
        } else {
          enhancedTrip.tripDistance = 0;
        }

        // Get trip distance display value too
        enhancedTrip.tripDistanceDisplayValue =
          enhancedTrip.tripDistance >= 0
            ? localization.formatDistance(enhancedTrip.tripDistance)
            : t('Tracking.NotAvailable');

        // Get duration display value
        enhancedTrip.durationDisplayValue = '';
        if (trip.ignitionOn) {
          if (trip.ignitionOff) {
            enhancedTrip.durationDisplayValue = formatDistance(
              moment(trip.ignitionOn).toDate(),
              moment(trip.ignitionOff).toDate(),
              'seconds'
            );
          } else {
            enhancedTrip.durationDisplayValue = formatDistance(
              moment(trip.ignitionOn).toDate(),
              moment().toDate(),
              'seconds'
            );
          }
        }

        enhancedTrip.driverName = device?.driverName;
        enhancedTrip.driver = device?.driver;
        enhancedTrip.lastLocation = device?.deviceStats?.location;

        return enhancedTrip;
      });
    }

    // Reverse the trip so most recent ones are displayed at top
    const reversedTrips = enhancedTrips ? [...enhancedTrips].reverse() : [];
    // console.debug('TrackEvents - tripsForGroupByTrips', reversedTrips);
    return reversedTrips;
  }, [trips, vehicles, groupByTripsEnabled]);

  const isNoTripDevice = device?.type?.code && NoTripDeviceTypes.includes(device?.type?.code);

  const { getEventsFilterCount, getFilteredEventsForAllTrips } = useSupportedEventsFilter();
  const eventsFilterCount = getEventsFilterCount(userGridSettings);

  const allEventsFiltered = hideNonBusinessTrips =>
    getFilteredEventsForAllTrips(userGridSettings, trips, hideNonBusinessTrips);

  const numItemsValue = hideNonBusinessTrips =>
    groupByTripsEnabled
      ? getTripsCount(tripsForGroupByTrips) || 0
      : allEventsFiltered(hideNonBusinessTrips)?.length || 0;

  const numItemsLabel = hideNonBusinessTrips =>
    groupByTripsEnabled
      ? tripsForGroupByTrips?.length === 1
        ? t('Entity.Trip')
        : t('Tracking.Lens.Trips')
      : allEventsFiltered(hideNonBusinessTrips)?.length === 1
      ? t('Easydocs.Event')
      : t('SmartJobs.Events');

  const dateRangePickerNumDays = loadMoreEnabled || groupByTripsEnabled ? 6 : 0;

  const headerSmallMode = width <= HEADER_SMALL_WIDTH;
  const tabShowTooltip = width <= HEADER_TAB_TOOLTIP_THRESHOLD;

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setWidth(width);
      }}
    >
      <div className={eventStyles.trackEventsContainer}>
        <div className={eventStyles.trackEventsPaneHeader}>
          <TabNavLink
            to={TrackingPaths.TrackSummary.replace(':deviceId', device?.id)}
            isActive={() => tab === TrackEventsTabs.Events}
          >
            <Tooltip title={tabShowTooltip ? t(`Tracking.Tabs.${TrackEventsTabs.Events}`) : ''}>
              {t(`Tracking.Tabs.${TrackEventsTabs.Events}`)}
            </Tooltip>
          </TabNavLink>
          {canAccessNonCameraFeatures && (
            <TabNavLink
              to={TrackingPaths.TrackDetails.replace(':deviceId', device?.id)}
              isActive={() => tab === TrackEventsTabs.Details}
            >
              <Tooltip title={tabShowTooltip ? t(`Tracking.Tabs.${TrackEventsTabs.Details}`) : ''}>
                {t(`Tracking.Tabs.${TrackEventsTabs.Details}`)}
              </Tooltip>
            </TabNavLink>
          )}
          {hasVehicleParameters &&
            device?.services?.includes('VPM') &&
            device?.deviceStats?.lastVpmAt && (
              <TabNavLink
                to={TrackingPaths.TrackParameters.replace(':deviceId', device?.id)}
                isActive={() => tab === TrackEventsTabs.Parameters}
              >
                <Tooltip
                  title={tabShowTooltip ? t(`Tracking.Tabs.${TrackEventsTabs.Parameters}`) : ''}
                >
                  {t(`Tracking.Tabs.${TrackEventsTabs.Parameters}`)}
                </Tooltip>
              </TabNavLink>
            )}
          <div className={eventStyles.trackEventsPaneDatePickerDiv}>
            <DateRangePicker
              size="small"
              format={localization.formats.time.formats.dby.toUpperCase()}
              maxDayRange={dateRangePickerNumDays}
              defaultDates={[dayjs(dateRange.from), dayjs(dateRange.to)]}
              datePickerRef={datePickerRef}
              disabledDate={current => disableOutsideOfNDays(current, dateRangePickerNumDays)}
              onDateRangeChanged={dates => {
                onDateRangeSelected(dayjs(dates[0]), dayjs(dates[1]));
              }}
              onOpenChange={onDateRangeClose}
              showWeekToDate={loadMoreEnabled ? true : groupByTripsEnabled}
              showPast7Days={loadMoreEnabled ? true : groupByTripsEnabled}
              showPast14Days={false}
              showLastMonth={false}
              className={
                headerSmallMode
                  ? eventStyles.trackEventsPaneDatePickerSmall
                  : eventStyles.trackEventsPaneDatePicker
              }
            />
          </div>
          <Badge
            offset={showHDData ? [-14, 2] : [-10, 2]}
            className={eventStyles.filterEventsBadge}
            count={eventsFilterCount}
            size="small"
            overflowCount={999}
          >
            <Button
              className={eventStyles.filterEventsButton}
              onClick={() => {
                setShowFilterEventsModal(true);
              }}
              id={BUTTON_IDS.trackEventsShowFilter}
            >
              <FontAwesomeIcon className={eventStyles.filterIcon} icon={faFilter} />
            </Button>
          </Badge>
          {showHDData && (
            <Button
              className={eventStyles.hdDataButton}
              onClick={handleHDDataClick}
              id={BUTTON_IDS.trackEventsHDData}
            >
              {headerSmallMode ? (
                <>
                  <ReloadOutlined className={eventStyles.hdDataSmallIcon} />
                  <span className={eventStyles.hdDataSmallText}>HD</span>
                </>
              ) : (
                t('Tracking.HDData')
              )}
            </Button>
          )}
        </div>
        {tab === TrackEventsTabs.Events && (
          <div className={eventStyles.trackEventsPaneListContainer}>
            <div className={eventStyles.eventsHeaderRow}>
              <div>
                <Switch
                  size="small"
                  checked={groupByTripsSwitchEnabled}
                  onChange={onGroupByTripsSwitchChange}
                />
                <span className={eventStyles.toggleSwitchLabel}>{t('Tracking.GroupByTrips')}</span>
              </div>
              <div className={eventStyles.trackEventsPaneNum}>
                {numItemsValue(hideNonBusinessTrips)} {numItemsLabel(hideNonBusinessTrips)}
              </div>
            </div>

            {groupByTripsEnabled ? (
              isNoTripDevice ? (
                <div className={eventStyles.tripsNotSupportedMessage}>
                  {t('Tracking.TripsNotSupported')}
                </div>
              ) : (
                <TrackEventsTripList
                  device={device}
                  trips={tripsForGroupByTrips}
                  userGridSettings={userGridSettings}
                  isLoading={isLoading}
                  selectedTrip={selectedTrip}
                  selectedEvent={selectedEvent}
                  onTripClicked={onTripClicked}
                  onDateRangeSelected={onDateRangeSelected}
                  onEventClicked={onEventClicked}
                  onDeviceClicked={onDeviceClicked}
                />
              )
            ) : (
              <TrackEventsList
                device={device}
                allEvents={allEventsFiltered(hideNonBusinessTrips)}
                tripSummaryEvents={null}
                trip={null}
                nextTrip={null}
                groupByTripsEnabled={false}
                isOutOfTripEvents={false}
                isLoading={isLoading}
                isExpanded={true}
                selectedEvent={selectedEvent}
                onDateRangeSelected={onDateRangeSelected}
                onEventClicked={onEventClicked}
                onDeviceClicked={onDeviceClicked}
              />
            )}
          </div>
        )}
        {tab === TrackEventsTabs.Details && (
          <div className={eventStyles.detailsContainer}>
            <VehicleDetails device={device} />
          </div>
        )}
        {tab === TrackEventsTabs.Parameters && (
          <div className={eventStyles.parametersContainer}>
            <VehicleParameters device={device} />
          </div>
        )}
        {showFilterEventsModal && (
          <FilterEventsModal
            gridSettingsKey={gridSettingsKey}
            isOpen={showFilterEventsModal}
            onClose={() => {
              setShowFilterEventsModal(false);
            }}
          />
        )}
      </div>
    </ResizeObserver>
  );
};
