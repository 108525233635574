import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';
import request from 'superagent';
import { ApiClient } from 'nextgen_api';
import { API_PATH } from 'config';

import { useBranches } from 'features/locations/locationsSlice';
import { DOCUMENTS_UPLOAD_URL, DOCUMENTS_URL } from 'features/easydocs/documentsSlice';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { toLower } from 'lodash';
import { api } from 'utils/api';
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import { useUser } from 'features/user/userSlice';
import { usersDataAdapter } from 'data/dataService/tn/adapter/users';
import dayjs from 'dayjs';

export const USERS_URL = '/users';

const users = {
  usersList: [],
  drivers: [],
  deletedUsersList: [],
  meta: {
    isFetching: {
      users: null,
      deletedUsers: null
    },
    isSuccess: {
      users: false,
      deletedUsers: false
    },
    isError: {
      users: false,
      deletedUsers: false
    },
    companyKey: {
      users: null,
      deletedUsers: null
    },
    embedsLoaded: false
  }
};

function startLoading(state, { payload }) {
  state.meta.companyKey[payload.type] = payload.companyKey;
  state.meta.isFetching[payload.type] = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching[action.payload.type] = false;
  state.meta.isError[action.payload.type] = true;
  state.meta.companyKey[action.payload.type] = action.payload.companyKey;
}

const usersSlice = createSlice({
  name: 'users',
  initialState: users,
  reducers: {
    fetchUsersStart: startLoading,
    fetchUsersSuccess(state, { payload }) {
      const enabledUsers = payload.usersList.filter(u => u.status === 'ENABLED');
      const disabledUsers = payload.usersList.filter(u => u.status === 'DISABLED');
      state.usersList = enabledUsers.sort((a, b) =>
        a.firstName.toUpperCase() > b.firstName.toUpperCase() ? 1 : -1
      );
      //treat Support Personnel as a driver
      state.drivers = enabledUsers.filter(
        user => toLower(user?.type?.code) === 'driver' || toLower(user?.type?.code) === 'support'
      );
      state.deletedUsersList = disabledUsers.sort((a, b) =>
        a.firstName.toUpperCase() > b.firstName.toUpperCase() ? 1 : -1
      );
      state.meta.isFetching.users = false;
      state.meta.isSuccess.users = true;
      state.meta.companyKey.users = payload.companyKey;
      state.meta.embedsLoaded = payload.embedsLoaded;
    },
    fetchDeletedUsersSuccess(state, { payload }) {
      state.deletedUsersList = payload.deletedUsersList.sort((a, b) =>
        a.firstName.toUpperCase() > b.firstName.toUpperCase() ? 1 : -1
      );

      state.meta.isFetching.deletedUsers = false;
      state.meta.isSuccess.deletedUsers = true;
      state.meta.companyKey.deletedUsers = payload.companyKey;
    },
    fetchUsersFailure: loadingFailed,
    fetchUsersCancelled(state, { payload }) {
      state.meta.isFetching.users = false;
      state.meta.isSuccess.users = false;
      state.meta.isError.users = false;
    }
  }
});

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchDeletedUsersSuccess,
  fetchUsersFailure,
  fetchUsersCancelled
} = usersSlice.actions;

export const fetchUsers = ({
  forceFetch = false,
  embed = 'associations',
  fetchUsersRequest = null
}) => async (dispatch, getState) => {
  const companyKey = getState().companies?.current?.api_key;
  const companyId = getState().companies?.current?.id;
  const authKey = getState().user?.current?.auth?.key;
  const isFetching = getState().users.meta.isFetching.users;

  if (!companyKey || !companyId || !authKey || isFetching) {
    return;
  }

  dispatch(fetchUsersStart({ type: 'users', companyKey }));

  //embedsLoaded - required by export to excel - Users
  const embedsLoaded = embed !== 'associations';
  const localCacheAvailable = await usersDataAdapter.available();

  try {
    if (localCacheAvailable) {
      const lastUpdated = (await usersDataAdapter.lastUpdatedDate()) || null;
      const syncDate = dayjs().format();
      const usersData = await usersDataAdapter.load();
      if (!embedsLoaded && usersData?.length > 0 && !forceFetch) {
        dispatch(fetchUsersSuccess({ usersList: usersData, companyKey, embedsLoaded }));
      } else {
        const getRequest = api.get(USERS_URL, {
          authKey,
          query: {
            pruning: 'ALL',
            embed: embed,
            company_id: companyId,
            statusList: 'E,D',
            ...(lastUpdated && { last_updated: lastUpdated })
          }
        });

        if (fetchUsersRequest) {
          fetchUsersRequest.current = getRequest;
        }

        getRequest.on('abort', () => {
          if (fetchUsersRequest) {
            fetchUsersRequest.current = null;
          }
          dispatch(fetchUsersCancelled({ err: 'Aborted', companyKey, type: 'users' }));
        });
        const response = await getRequest;
        if (fetchUsersRequest) {
          fetchUsersRequest.current = null;
        }
        const { body } = response;
        // Update only if response is not empty
        //if (body.length > 0) {
        await usersDataAdapter.persist(body);
        const cachedData = await usersDataAdapter.load(true);
        dispatch(fetchUsersSuccess({ usersList: cachedData, companyKey, embedsLoaded }));
        // }
        usersDataAdapter.updateLastUpdatedDate(syncDate);
      }
    } else {
      const getRequest = api.get(USERS_URL, {
        authKey,
        query: {
          pruning: 'ALL',
          embed: embed,
          company_id: companyId,
          statusList: 'E,D'
        }
      });

      if (fetchUsersRequest) {
        fetchUsersRequest.current = getRequest;
      }

      getRequest.on('abort', () => {
        if (fetchUsersRequest) {
          fetchUsersRequest.current = null;
        }
        dispatch(fetchUsersCancelled({ err: 'Aborted', companyKey, type: 'users' }));
      });
      const response = await getRequest;
      if (fetchUsersRequest) {
        fetchUsersRequest.current = null;
      }
      const { body } = response;
      dispatch(fetchUsersSuccess({ usersList: body, companyKey, embedsLoaded }));
    }
  } catch (err) {
    console.error(err);
    if (fetchUsersRequest) {
      fetchUsersRequest.current = null;
    }
    dispatch(fetchUsersFailure({ err: err?.toString(), companyKey, type: 'users' }));
  }
};

export const fetchDeletedUsers = () => async (dispatch, getState) => {
  const companyKey = getState().companies?.current?.api_key;
  const companyId = getState().companies?.current?.id;
  const authKey = getState().user?.current?.auth?.key;

  if (!companyKey || !companyId || !authKey) {
    return;
  }

  const usersUrl = `${USERS_URL}?status=DELETED&pruning=ALL&company_id=${companyId}`;
  try {
    dispatch(fetchUsersStart({ type: 'deletedUsers', companyKey }));
    const usersData = await usersDataAdapter.load();
    const disabledUsers = usersData.filter(u => u.status === 'DISABLED');

    if (disabledUsers.length > 0) {
      dispatch(fetchDeletedUsersSuccess({ deletedUsersList: disabledUsers, companyKey }));
    } else {
      const response = await api.get(usersUrl, { authKey });
      const { body } = response;
      usersDataAdapter.persist(body);
      dispatch(fetchDeletedUsersSuccess({ deletedUsersList: body, companyKey }));
    }
  } catch (err) {
    console.error(err);
    dispatch(fetchUsersFailure({ err: err?.toString(), companyKey, type: 'deletedUsers' }));
  }
};

export const attachFiles = (files, userId) => async (dispatch, getState) => {
  const {
    companies: { current },
    user,
    documents
  } = getState();
  const authKey = user.current.auth.key;
  const returnedUploadResponses = [];
  // Upload the files and create an array of uploaded files ids
  for (let file of files) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploadParams', JSON.stringify({ overwrite: true, userId }));

    try {
      const uploadResponse = await api.post(
        DOCUMENTS_UPLOAD_URL,
        {
          authKey,
          company_id: documents.uploadCompanyId || current.id
        },
        formData
      );

      returnedUploadResponses.push(uploadResponse);

      if (!uploadResponse.ok) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18n.t('Users.Notifications.FileWasNotSaved', {
              name: file.name ?? file.filename
            })
          })
        );
      }
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('Users.Notifications.FileWasNotSavedError', {
            name: file.name ?? file.filename,
            err
          })
        })
      );
    }
  }

  return returnedUploadResponses;
};

export const removeFiles = file => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;

  try {
    const response = await api.delete(`${DOCUMENTS_URL}/${file.id}`, { authKey: userKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Users.Notifications.DeleteSuccess', {
            name: file?.name || file?.filename
          })
        })
      );
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: i18n.t('Users.Notifications.DeleteError', {
          name: file?.name || file?.filename,
          err
        })
      })
    );
  }
};

export const useUsers = () => {
  const dispatch = useDispatch();
  const users = useUsersList();
  const branches = useBranches();
  const isFetching = useSelector(state => state.users.meta.isFetching.users);
  const isSuccess = useSelector(state => state.users.meta.isSuccess.users);
  const isError = useSelector(state => state.users.meta.isError.users);
  const isFetchingFinished = isSuccess || isError;
  const companyKey = useCompanyKey('users');
  const currentCompanyKey = useCurrentCompanyKey();
  const isCompanyKeyDifferent = (currentCompanyKey && companyKey !== currentCompanyKey) || false;
  const currentUser = useUser();

  const fetchUsersRequest = useRef(null);
  const couldFetch = !isFetching && !isFetchingFinished;
  useEffect(() => {
    if ((couldFetch || isCompanyKeyDifferent) && !fetchUsersRequest.current) {
      dispatch(
        fetchUsers({
          forceFetch: false,
          embed: 'associations',
          fetchUsersRequest: fetchUsersRequest
        })
      );
    }
  }, [dispatch, couldFetch, isCompanyKeyDifferent]);

  useEffect(() => {
    return () => {
      if (fetchUsersRequest.current && !!companyKey) {
        fetchUsersRequest.current.abort();
        fetchUsersRequest.current = null;
      }
    };
  }, [companyKey]);

  const filteredUsers = useMemo(() => {
    if (users && branches && currentUser?.type?.code !== 'DRIVER') {
      return users.filter(u =>
        branches.some(b => b.id === u.location?.id || (b.id === -1 && u.location == null))
      );
    }
    return users;
  }, [users, branches, currentUser]);

  return filteredUsers;
};

export const useDeletedUsers = flagForApiCall => {
  // const dispatch = useDispatch();
  const deletedUsers = useSelector(state => state.users.deletedUsersList);
  // const isFetching = useSelector(state => state.users.meta.isFetching.deletedUsers);
  // const isSuccess = useSelector(state => state.users.meta.isSuccess.deletedUsers);
  // const isError = useSelector(state => state.users.meta.isError.deletedUsers);
  // const isComplete = isSuccess || isError;
  // const companyKey = useCompanyKey('deletedUsers');
  // const currentCompanyKey = useCurrentCompanyKey();
  // const isCompanyKeyDifferent = companyKey && currentCompanyKey && companyKey !== currentCompanyKey;

  // if (flagForApiCall && ((!isFetching && !isComplete) || isCompanyKeyDifferent)) {
  //   dispatch(fetchDeletedUsers());
  // }

  return deletedUsers;
};

export const useDrivers = () => {
  useUsers();
  const drivers = useSelector(state => state.users.drivers);
  const branches = useBranches();
  const currentUser = useUser();

  const filteredDrivers = useMemo(() => {
    if (drivers && branches && currentUser?.type?.code !== 'DRIVER') {
      const branchesMap = branches.reduce((r, b) => {
        r[b.id] = b;
        return r;
      }, {});
      return drivers.filter(u => branchesMap[u.location?.id] || (branchesMap[-1] && !u.location));
    }
    return drivers;
  }, [drivers, branches, currentUser]);
  return filteredDrivers;
};

export const useUsersList = () => useSelector(state => state.users.usersList);
const useCompanyKey = (type = 'users') => useSelector(state => state.users.meta.companyKey[type]);
export const useIsCompanyKeyDifferent = () =>
  useSelector(state => state.users.meta.companyKey.users) !== useCurrentCompanyKey();
export const useIsFetching = () => useSelector(state => state.users.meta.isFetching.users);
export const useIsUserEmbedsLoaded = () => useSelector(state => state.users.meta.embedsLoaded);

export const deleteUserApi = (data, history) => async (dispatch, getState) => {
  const authKey = getState().user?.current?.auth?.key;
  const companyKey = getState().companies.current.api_key;
  const { id, username } = data;
  const url = `/users/${id}`;
  try {
    const response = await api.delete(url, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Users.Notifications.Deleted', {
            name: username
          })
        })
      );
      const localCacheAvailable = await usersDataAdapter.available();
      if (localCacheAvailable) {
        await usersDataAdapter.delete(id);
        const cachedData = await usersDataAdapter.load(true);
        dispatch(fetchUsersSuccess({ usersList: cachedData, companyKey }));
      } else {
        dispatch(fetchUsers({ forceFetch: true }));
        dispatch(fetchDeletedUsers());
      }
      history && canHistoryGoBack(history, '/settings/users');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18n.t('Users.Notifications.UserDeletedError')}: ${err}`
      })
    );
  }
};

export const restoreUserApi = (data, doRefetch) => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, username } = data;
  const url = `${API_PATH}/users/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Users.Notifications.Restored', { name: username })
          })
        );
        dispatch(fetchUsers({ forceFetch: true }));
        //dispatch(fetchDeletedUsers());
        doRefetch && doRefetch();
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('Users.Notifications.RestoreError', {
            name: username,
            error: parseErrorMessage(err)
          })
        })
      );
    });
};

export const fetchELDDrivingConditions = driverId => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const fetchPromise = new Promise((resolve, reject) => {
    const url = '/users/{driverId}/config/eld.special_driving_conditions';
    const apiClient = new ApiClient();
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    apiClient.basePath = API_PATH;
    apiClient.callApi(
      url,
      'GET',
      {
        driverId: driverId
      },
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      'String',
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err.message);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await fetchPromise;
    return data;
  } catch (err) {
    throw err;
  }
};

export default usersSlice.reducer;
