import * as moment from 'moment';
import { sortBy } from 'lodash';
import { CAMERA_POSITION } from '../../constant';

export const SortInitValue = { item: 'date', order: 'desc' };

export const FootagePerRow = {
  Default: 4,
  DailyMode: 5
};

export const sizeCalculator = (tableWidth, isDailyMode, footageNum = FootagePerRow.Default) => {
  //divide rowWidth into footageNum + 1 equal parts, 1 for infoCard and the remaining parts are footage-cards
  const tablePaddingLeft = 10,
    marginX = 4 * 2,
    marginY = 16 * 2,
    cardGaps = 16,
    cardRatio = 16 / 9,
    cardCount = footageNum + 1,
    elipsisBtn = 32,
    sliderWidth = 16,
    rowGap = 8,
    scrollBarWidth = 12,
    elipsisBtnWidth = 32;
  const rowMiscellaneous =
    tablePaddingLeft + marginX + cardGaps * footageNum + scrollBarWidth + elipsisBtn;
  const cardWidth =
    (tableWidth - rowMiscellaneous + (isDailyMode ? elipsisBtn / 2 : 0)) / cardCount;
  const cardHeight = cardWidth / cardRatio;
  const rowHeight = cardHeight + marginY + rowGap;
  return {
    rowHeight,
    sizeInRow: {
      infoCardWidth: cardWidth - sliderWidth,
      footageSize: [cardWidth, cardHeight],
      elipsisBtnWidth
    }
  };
};

const getLatestSnapshotOfCameraPosition = (
  snapshots = [],
  camPosition = CAMERA_POSITION.UNKNOWN
) => {
  if (snapshots?.length) {
    return sortBy(
      snapshots.filter(
        snapshot =>
          snapshot.cameraPosition === camPosition ||
          (camPosition === CAMERA_POSITION.UNKNOWN &&
            snapshots.length &&
            snapshots.every(s => !s.cameraPosition))
      ),
      snapshot => moment(snapshot.timeAt).valueOf()
    )[snapshots.length - 1];
  }
};

export const getPositionedFootages = footages => {
  const footagesByPosition = (footages || []).reduce((a, footage) => {
    const camPosition = CAMERA_POSITION[footage?.cameraPosition]
      ? footage.cameraPosition
      : CAMERA_POSITION.UNKNOWN;
    const footagesByPosition = a[camPosition] || [];
    footagesByPosition.push(footage);
    return {
      ...a,
      [camPosition]: footagesByPosition
    };
  }, {});
  return Object.keys(CAMERA_POSITION).reduce((a, camPosition) => {
    const latestSnapshot = getLatestSnapshotOfCameraPosition(
      footagesByPosition[camPosition],
      camPosition
    );
    return latestSnapshot
      ? {
          ...a,
          [camPosition]: latestSnapshot
        }
      : a;
  }, {});
};

export const getAvailablePositionedFootages = (
  positionedFootages = {},
  availablePositions = []
) => {
  if (Object.keys(positionedFootages).every(position => availablePositions?.includes(position))) {
    return Object.keys(CAMERA_POSITION).reduce(
      (a, camPosition) => ({
        ...a,
        ...(availablePositions.some(c => c === camPosition)
          ? { [camPosition]: positionedFootages[camPosition] || { cameraPosition: camPosition } }
          : {})
      }),
      {}
    );
  } else {
    return Object.keys(CAMERA_POSITION).reduce(
      (a, camPosition) => ({
        ...a,
        ...(positionedFootages[camPosition]
          ? { [camPosition]: positionedFootages[camPosition] }
          : {})
      }),
      {}
    );
  }
};

export const CAMERA_DIAGNOSTIC_STATUS = {
  AMBER: 'AMBER',
  RED: 'RED',
  GREEN: 'GREEN',
  INSUFFICIENT_DATA: 'INSUFFICIENT_DATA'
};

export const canDeviceBeDiagnosed = (deviceModelName, deviceTypeCode) =>
  deviceTypeCode === 'CAMERA' && /^(iq\s?camera|MultiIQ)$/i.test(deviceModelName);
