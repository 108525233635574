import { deviceDBOToObject, deviceObjectToDBO } from 'data/dataService/tn/mapper/devices';
import { LocalDBDataService } from '../localDbDataService';
import { vehicleDBOToObject, vehicleObjectToDBO } from 'data/dataService/tn/mapper/vehicles';
import {
  fleetDBOToObject,
  fleetDeviceDBOToObject,
  fleetDeviceObjectToDBO,
  fleetObjectToDBO,
  fleetVehicleDBOToObject,
  fleetVehicleObjectToDBO
} from 'data/dataService/tn/mapper/fleets';

import {
  companyTables,
  deviceModelsTable,
  deviceStatsTable,
  deviceTypesTable,
  devicesTable,
  entityLastUpdateTable,
  fleetsDevicesTable,
  fleetsTable,
  fleetsVehiclesTable,
  geofenceAssociationsTable,
  geofencesTable,
  locationTypesTable,
  locationsTable,
  regionsTable,
  rolesTable,
  rolePermissionsTable,
  permissionsTable,
  rulesetsTable,
  schema_version,
  userRolesTable,
  userRulesetsTable,
  usersTable,
  vehicleStatsTable,
  vehicleTypesTable,
  vehiclesTable,
  carriersTable
} from './schema';
import {
  convertDBOArrayToUpsertSql,
  convertDBOToInsertSql,
  convertDBOToUpsertSql
} from 'data/sqlite/util/insertSqlGen';
import { companyDBOToObject, companyObjectToDBO } from './mapper/companies';
import { deviceModelDBOToObject, deviceModelObjectToDBO } from './mapper/deviceModels';
import { deviceTypesDBOToObject, deviceTypesObjectToDBO } from './mapper/deviceTypes';
import { locationTypeDBOToObject, locationTypeObjectToDBO } from './mapper/locationTypes';
import { locationDBOToObject, locationObjectToDBO } from './mapper/locations';
import { regionDBOToObject, regionObjectToDBO } from './mapper/regions';
import { vehicleTypeDBOToObject, vehicleTypeObjectToDBO } from './mapper/vehicleTypes';
import {
  userDBOToObject,
  userObjectToDBO,
  userRoleDBOToObject,
  userRoleObjectToDBO,
  userRulesetDBOToObject,
  userRulesetObjectToDBO
} from './mapper/users';
import {
  roleDBOToObject,
  roleObjectToDBO,
  rolePermissionDBOToObject,
  rolePermissionObjectToDBO
} from './mapper/roles';
import { permissionDBOToObject, permissionObjectToDBO } from './mapper/permissions';
import { rulesetDBOToObject, rulesetObjectToDBO } from './mapper/rulesets';
import {
  geofenceAssociationDBOToObject,
  geofenceAssociationObjectToDBO,
  geofenceDBOToObject,
  geofenceObjectToDBO
} from './mapper/geofences';
import { vehicleStatsDBOToObject, vehicleStatsObjectToDBO } from './mapper/vehicleStats';
import { deviceStatsDBOToObject, deviceStatsObjectToDBO } from './mapper/deviceStats';
import { carriersDBOToObject, carriersObjectToDBO } from './mapper/carriers';

export const DataServiceStatus = {
  None: 0,
  Init: 1,
  InitDone: 2,
  Loading: 3,
  Done: 4,
  StandBy: 100
};

export class TNDataService {
  static #status = DataServiceStatus.None;

  static get bulk_limit() {
    return 1024 * 1024;
  }

  static init() {
    if (TNDataService.#status !== DataServiceStatus.None) {
      return;
    }

    TNDataService.#status = DataServiceStatus.Init;
    LocalDBDataService.getInstance();
    TNDataService.#status = DataServiceStatus.InitDone;
  }

  static get status() {
    return TNDataService.#status;
  }

  static async openDb(user, companyId, updateServiceStatus) {
    if (
      TNDataService.#status !== DataServiceStatus.InitDone &&
      TNDataService.#status !== DataServiceStatus.StandBy
    ) {
      return false;
    }
    let result = false;
    TNDataService.#status = DataServiceStatus.Loading;
    updateServiceStatus(DataServiceStatus.Loading);
    const dbService = LocalDBDataService.getInstance();
    const isDbOpened = await dbService.isDbOpened();
    if (!isDbOpened) {
      const dbName = user.id + '_' + companyId;
      await dbService.migrateDb(dbName, schema_version);
      result = await dbService.openDb(dbName, schema_version).then(async () => {
        //create tables
        try {
          const sql = [
            fleetsTable,
            vehiclesTable,
            devicesTable,
            fleetsVehiclesTable,
            fleetsDevicesTable,
            companyTables,
            deviceTypesTable,
            deviceModelsTable,
            locationTypesTable,
            locationsTable,
            regionsTable,
            vehicleTypesTable,
            rolesTable,
            rolePermissionsTable,
            permissionsTable,
            usersTable,
            userRolesTable,
            rulesetsTable,
            userRulesetsTable,
            geofencesTable,
            geofenceAssociationsTable,
            entityLastUpdateTable,
            vehicleStatsTable,
            deviceStatsTable,
            carriersTable
          ].join(' ');
          await dbService.runSql(sql);
          TNDataService.#status = DataServiceStatus.Done;
          return true;
        } catch (e) {
          TNDataService.#status = DataServiceStatus.StandBy;
          console.error(e);
          throw e;
        }
      });
    }
    return result;
  }

  static async deleteAllUserDBs(userId) {
    if (TNDataService.#status === DataServiceStatus.Done) {
      const dbService = LocalDBDataService.getInstance();
      await dbService.deleteAllUserDBs(userId);
      TNDataService.#status = DataServiceStatus.StandBy;
    }
  }

  static async close() {
    if (TNDataService.#status === DataServiceStatus.Done) {
      const dbService = LocalDBDataService.getInstance();
      await dbService.closeDb();
      TNDataService.#status = DataServiceStatus.StandBy;
    }
  }

  static async getAllFleets(loadNoFleet = false) {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_fleets');
    if (!loadNoFleet) {
      data = data.filter(f => f.id);
    }
    data = data.map(d => fleetDBOToObject(d));
    return data;
  }

  static async getAllVehicles() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_vehicles');
    data = data.map(d => vehicleDBOToObject(d));
    return data;
  }

  static async getAllDevices() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_devices');
    data = data.map(d => deviceDBOToObject(d));
    return data;
  }

  static async getFleetsVehiclesAssociaitons() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_fleet_vehicles');
    data = data.map(d => fleetVehicleDBOToObject(d));
    return data;
  }

  static async getFleetsDevicesAssociations() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_fleet_devices');
    data = data.map(d => fleetDeviceDBOToObject(d));
    return data;
  }

  static async getAllCompanies() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_companies');
    data = data.map(d => companyDBOToObject(d));
    return data;
  }

  static async getAllDeviceModels() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_device_models');
    data = data.map(d => deviceModelDBOToObject(d));
    return data;
  }

  static async getAllDeviceTypes() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_device_types');
    data = data.map(d => deviceTypesDBOToObject(d));
    return data;
  }

  static async getAllLocationTypes() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_location_types');
    data = data.map(d => locationTypeDBOToObject(d));
    return data;
  }

  static async getAllLocations() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_locations');
    data = data.map(d => locationDBOToObject(d));
    return data;
  }

  static async getAllRegions() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_regions');
    data = data.map(d => regionDBOToObject(d));
    return data;
  }

  static async getAllVehicleTypes() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_vehicle_types');
    data = data.map(d => vehicleTypeDBOToObject(d));
    return data;
  }

  static async getAllUsers() {
    const dbService = LocalDBDataService.getInstance();
    let start = performance.now();
    let data = await dbService.executeSqlV2('SELECT * FROM ng_users');
    console.log('get all users, sql time', performance.now() - start);
    start = performance.now();
    data = data.map(d => userDBOToObject(d));
    console.log('get all users, convert time', performance.now() - start);
    return data;
  }

  static async getAllRoles() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_roles');
    data = data.map(d => roleDBOToObject(d));
    return data;
  }

  static async getAllRolePermissions() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_role_permissions');
    data = data.map(d => rolePermissionDBOToObject(d));
    return data;
  }

  static async insertOrUpdateRolePermissionsInBulk(rolePermissions) {
    if (!rolePermissions) {
      return Promise.reject('Role permissions is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sqls = '';
    let result = null;
    sqls = convertDBOArrayToUpsertSql(
      rolePermissions,
      rolePermissionObjectToDBO,
      'ng_role_permissions'
    );

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }

    return result;
  }

  static async deleteRolePermissionAssociation(roleId) {
    if (!roleId) {
      return Promise.reject('Role id is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const result = await dbService.executeSql(
      `delete from ng_role_permissions where role_id = ${roleId};`
    );
    return result;
  }

  static async getAllPermissions() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_permissions');
    data = data.map(d => permissionDBOToObject(d));
    return data;
  }

  static async getAllRulesets() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_rulesets');
    data = data.map(d => rulesetDBOToObject(d));
    return data;
  }

  static async getAllGeofences() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_geofences');
    data = data.map(d => geofenceDBOToObject(d));
    return data;
  }

  static async getAllUserRoles() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_user_roles');
    data = data.map(d => userRoleDBOToObject(d));
    return data;
  }

  static async getAllUserRulesets() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_user_rulesets');
    data = data.map(d => userRulesetDBOToObject(d));
    return data;
  }

  static async getAllGeofencesAssociations() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_geofence_associations');
    data = data.map(d => geofenceAssociationDBOToObject(d));
    return data;
  }

  static async getAllVehicleStats() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_vehicle_stats');
    data = data.map(d => vehicleStatsDBOToObject(d));
    return data;
  }

  static async getAllDeviceStats() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_device_stats');
    data = data.map(d => deviceStatsDBOToObject(d));
    return data;
  }

  static async getAllCarriers() {
    const dbService = LocalDBDataService.getInstance();
    let data = await dbService.executeSql('SELECT * FROM ng_carriers');
    data = data.map(d => carriersDBOToObject(d));
    return data;
  }

  static async insertOrUpdateFleet(fleet) {
    if (!fleet) {
      return Promise.reject('Fleet is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const fleetDbo = fleetObjectToDBO(fleet);
    const sql = convertDBOToUpsertSql(fleetDbo, 'ng_fleets', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateFleetInBulk(fleets) {
    let sqls = '';
    const dbService = LocalDBDataService.getInstance();
    let result = null;
    sqls = convertDBOArrayToUpsertSql(fleets, fleetObjectToDBO, 'ng_fleets');

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }

    return result;
  }

  static async insertOrUpdateVehicle(vehicle) {
    if (!vehicle) {
      return Promise.reject('Vehicle is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const vehicleDbo = vehicleObjectToDBO(vehicle);
    const sql = convertDBOToUpsertSql(vehicleDbo, 'ng_vehicles', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateVehiclesInBulk(vehicles) {
    let sqls = '';
    const dbService = LocalDBDataService.getInstance();
    let result = null;
    sqls = convertDBOArrayToUpsertSql(vehicles, vehicleObjectToDBO, 'ng_vehicles');

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }

    return result;
  }

  static async insertOrUpdateDevice(device) {
    if (!device) {
      return Promise.reject('Device is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const deviceDbo = deviceObjectToDBO(device);
    const sql = convertDBOToUpsertSql(deviceDbo, 'ng_devices', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateDevicesInBulk(devices) {
    let sqls = '';
    const dbService = LocalDBDataService.getInstance();
    let result = null;

    sqls = convertDBOArrayToUpsertSql(devices, deviceObjectToDBO, 'ng_devices');
    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }

    return result;
  }

  static async insertFleetVehicleAssociation(fv) {
    if (!fv) {
      return Promise.reject('Fleet Vehicle Associaiton is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const fvDbo = fleetVehicleObjectToDBO(fv);
    const sql = convertDBOToInsertSql(fvDbo, 'ng_fleet_vehicles', 'IGNORE');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertFleetVehicleAssociationsInBulk(fvs) {
    if (!fvs) {
      return Promise.reject('Fleet Vehicle Associaiton is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sqls = convertDBOArrayToUpsertSql(fvs, fleetVehicleObjectToDBO, 'ng_fleet_vehicles');

    sqls = 'BEGIN; ' + sqls + 'COMMIT;';
    const result = await dbService.runSql(sqls);
    return result;
  }

  static async insertFleetDeviceAssociation(fd) {
    if (!fd) {
      return Promise.reject('Fleet Device Associaiton is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const fdDbo = fleetDeviceObjectToDBO(fd);
    const sql = convertDBOToInsertSql(fdDbo, 'ng_fleet_devices', 'IGNORE');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertFleetDeviceAssociationsInBulk(fds) {
    if (!fds) {
      return Promise.reject('Fleet Device Associaiton is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sqls = convertDBOArrayToUpsertSql(fds, fleetDeviceObjectToDBO, 'ng_fleet_devices');

    sqls = 'BEGIN; ' + sqls + 'COMMIT;';
    const result = await dbService.runSql(sqls);
    return result;
  }

  static async insertOrUpdateCompany(company) {
    if (!company) {
      return Promise.reject('Company is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const companyDbo = companyObjectToDBO(company);
    const sql = convertDBOToUpsertSql(companyDbo, 'ng_companies', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateDeviceType(deviceType) {
    if (!deviceType) {
      return Promise.reject('Device Type is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const deviceTypeDbo = deviceTypesObjectToDBO(deviceType);
    const sql = convertDBOToUpsertSql(deviceTypeDbo, 'ng_device_types', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateDeviceModel(deviceModel) {
    if (!deviceModel) {
      return Promise.reject('Device Model is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const deviceModelDbo = deviceModelObjectToDBO(deviceModel);
    const sql = convertDBOToUpsertSql(deviceModelDbo, 'ng_device_models', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateLocationType(locationType) {
    if (!locationType) {
      return Promise.reject('Location Type is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const locationTypeDbo = locationTypeObjectToDBO(locationType);
    const sql = convertDBOToUpsertSql(locationTypeDbo, 'ng_location_types', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateLocation(location) {
    if (!location) {
      return Promise.reject('Location is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const locationDbo = locationObjectToDBO(location);
    const sql = convertDBOToUpsertSql(locationDbo, 'ng_locations', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateRegion(region) {
    if (!region) {
      return Promise.reject('Region is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const regionDbo = regionObjectToDBO(region);
    const sql = convertDBOToUpsertSql(regionDbo, 'ng_regions', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateVehicleType(vehicleType) {
    if (!vehicleType) {
      return Promise.reject('Vehicle Type is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const vehicleTypeDbo = vehicleTypeObjectToDBO(vehicleType);
    const sql = convertDBOToUpsertSql(vehicleTypeDbo, 'ng_vehicle_types', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateUser(user) {
    if (!user) {
      return Promise.reject('User is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const userDbo = userObjectToDBO(user);
    const sql = convertDBOToUpsertSql(userDbo, 'ng_users', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async deleteUser(userId) {
    if (!userId) {
      return Promise.reject('User id is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const result = await dbService.executeSql(`delete from ng_users where id = ${userId};`);
    return result;
  }

  static async insertOrUpdateUsersInBulk(users) {
    if (!users) {
      return Promise.reject('Users is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sqls = '';
    let result = null;
    sqls = convertDBOArrayToUpsertSql(users, userObjectToDBO, 'ng_users');

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }
    return result;
  }

  static async insertUserRole(userRole) {
    if (!userRole) {
      return Promise.reject('User role is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const userRoleDbo = userRoleObjectToDBO(userRole);
    const sql = convertDBOToInsertSql(userRoleDbo, 'ng_user_roles', 'IGNORE');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateUserRolesInBulk(userRoles) {
    if (!userRoles) {
      return Promise.reject('User roles is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sqls = convertDBOArrayToUpsertSql(userRoles, userRoleObjectToDBO, 'ng_user_roles');

    sqls = 'BEGIN; ' + sqls + 'COMMIT;';
    const result = await dbService.runSql(sqls);
    return result;
  }

  static async deleteUserRoleAssociation(userId) {
    if (!userId) {
      return Promise.reject('User id is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const result = await dbService.executeSql(
      `delete from ng_user_roles where user_id = ${userId};`
    );
    return result;
  }

  static async deleteUserRulesetAssociation(userId) {
    if (!userId) {
      return Promise.reject('User id is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const result = await dbService.executeSql(
      `delete from ng_user_rulesets where user_id = ${userId};`
    );
    return result;
  }

  static async insertOrUpdateUserRuleset(userRuleset) {
    if (!userRuleset) {
      return Promise.reject('User ruleset is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const userRulesetDbo = userRulesetObjectToDBO(userRuleset);
    const sql = convertDBOToUpsertSql(userRulesetDbo, 'ng_user_rulesets', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateUserRulesetsInBulk(userRulesets) {
    if (!userRulesets) {
      return Promise.reject('User ruleset is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sqls = convertDBOArrayToUpsertSql(userRulesets, userRulesetObjectToDBO, 'ng_user_rulesets');

    sqls = 'BEGIN; ' + sqls + 'COMMIT;';
    const result = await dbService.runSql(sqls);
    return result;
  }

  static async insertOrUpdateRole(role) {
    if (!role) {
      return Promise.reject('Role is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const roleDbo = roleObjectToDBO(role);
    const sql = convertDBOToUpsertSql(roleDbo, 'ng_roles', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateRolesInBulk(roles) {
    if (!roles || !roles?.length) {
      return Promise.reject('Roles is null or empty');
    }
    const dbService = LocalDBDataService.getInstance();
    let sqls = convertDBOArrayToUpsertSql(roles, roleObjectToDBO, 'ng_roles');

    sqls = 'BEGIN; ' + sqls + 'COMMIT;';
    const result = await dbService.runSql(sqls);
    return;
  }

  static async deleteRole(roleId) {
    if (!roleId) {
      return Promise.reject('Role id is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const result = await dbService.executeSql(`delete from ng_roles where id = ${roleId};`);
    return result;
  }

  static async insertOrUpdatePermission(permission) {
    if (!permission) {
      return Promise.reject('Permission is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const permissionDbo = permissionObjectToDBO(permission);
    const sql = convertDBOToUpsertSql(permissionDbo, 'ng_permissions', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateRuleset(ruleset) {
    if (!ruleset) {
      return Promise.reject('Ruleset is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const rulesetDbo = rulesetObjectToDBO(ruleset);
    const sql = convertDBOToUpsertSql(rulesetDbo, 'ng_rulesets', 'name');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateGeofence(geofence) {
    if (!geofence) {
      return Promise.reject('Geofence is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const geofenceDbo = geofenceObjectToDBO(geofence);
    const sql = convertDBOToUpsertSql(geofenceDbo, 'ng_geofences', 'id');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateGeofencesInBulk(geofences) {
    if (!geofences) {
      return Promise.reject('Geofences is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let result = null;
    let sqls = convertDBOArrayToUpsertSql(geofences, geofenceObjectToDBO, 'ng_geofences');

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }

    return result;
  }

  static async insertGeofenceAssociation(geofenceAssociation) {
    if (!geofenceAssociation) {
      return Promise.reject('Geofence association is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const geofenceAssociationDBO = geofenceAssociationObjectToDBO(geofenceAssociation);
    const sql = convertDBOToInsertSql(geofenceAssociationDBO, 'ng_geofence_associations');
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertGeofenceAssociationsInBulk(geofenceAssociations) {
    if (!geofenceAssociations) {
      return Promise.reject('Geofence associations is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let result = null;
    let sqls = convertDBOArrayToUpsertSql(
      geofenceAssociations,
      geofenceAssociationObjectToDBO,
      'ng_geofence_associations'
    );

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }
    return result;
  }

  static async insertOrUpdateVehicleStatsInBulk(vehicleStats) {
    if (!vehicleStats) {
      return Promise.reject('Vehicle stats is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let result = null;
    let sqls = convertDBOArrayToUpsertSql(
      vehicleStats,
      vehicleStatsObjectToDBO,
      'ng_vehicle_stats'
    );

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }
    return result;
  }

  static async insertOrUpdateDeviceStatsInBulk(deviceStats) {
    if (!deviceStats) {
      return Promise.reject('Device stats is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let result = null;
    let sqls = convertDBOArrayToUpsertSql(deviceStats, deviceStatsObjectToDBO, 'ng_device_stats');

    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }
    return result;
  }

  static async getEntityLastUpdate(tableName) {
    if (!tableName) {
      return Promise.reject('Table name is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const data = await dbService.executeSql(
      `select last_updated_date from ng_entity_last_update where tableName = '${tableName}';`
    );
    if (data?.length) {
      return data[0].last_updated_date;
    } else {
      return null;
    }
  }

  static async deleteFleetVehicleAssociation(fleetId) {
    if (!fleetId) {
      return Promise.reject('Fleet id is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const result = await dbService.executeSql(
      `delete from ng_fleet_vehicles where fleet_id = ${fleetId};`
    );
    return result;
  }

  static async deleteFleetDeviceAssociation(fleetId) {
    if (!fleetId) {
      return Promise.reject('Fleet id is null');
    }
    const dbService = LocalDBDataService.getInstance();
    const result = await dbService.executeSql(
      `delete from ng_fleet_devices where fleet_id = ${fleetId};`
    );
    return result;
  }

  static async deleteRolePermissionsInBulk(rolePermissions) {
    if (!rolePermissions) {
      return Promise.reject('Role permissions is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sql = `delete from ng_role_permissions where role_id in (${rolePermissions.join(',')});`;
    const result = await dbService.runSql(sql);
    return result;
  }

  static async deleteRolesInBulk(roles) {
    if (!roles) {
      return Promise.reject('Roles is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sql = `delete from ng_roles where id in (${roles.join(',')});`;
    const result = await dbService.runSql(sql);
    return result;
  }

  static async deleteUserRoleAssociationInBulk(userIds) {
    if (!userIds) {
      return Promise.reject('User ids is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sql = `delete from ng_user_roles where user_id in (${userIds.join(',')});`;
    const result = await dbService.runSql(sql);
    return result;
  }

  static async deleteUserRulesetAssociationInBulk(userIds) {
    if (!userIds) {
      return Promise.reject('User ids is null');
    }
    const dbService = LocalDBDataService.getInstance();
    let sql = `delete from ng_user_rulesets where user_id in (${userIds.join(',')});`;
    const result = await dbService.runSql(sql);
    return result;
  }

  static async putEntityLastUpdate(tableName, updatedDate) {
    if (!tableName) {
      return Promise.reject('Table name is null');
    }
    if (!updatedDate) {
      return Promise.reject('Updated date is null');
    }

    const dbService = LocalDBDataService.getInstance();
    const sql = convertDBOToUpsertSql(
      {
        tableName: tableName,
        last_updated_date: updatedDate
      },
      'ng_entity_last_update',
      'tableName'
    );
    const result = await dbService.executeSql(sql);
    return result;
  }

  static async insertOrUpdateCarriersInBulk(carriers) {
    if (!carriers?.length) {
      return;
    }

    let sqls = '';
    const dbService = LocalDBDataService.getInstance();
    let result = null;

    sqls = convertDBOArrayToUpsertSql(carriers, carriersObjectToDBO, 'ng_carriers');
    if (sqls.length > 0) {
      sqls = 'BEGIN; ' + sqls + 'COMMIT;';
      result = await dbService.runSql(sqls);
    }

    return result;
  }

  static async deleteAllData() {
    const dbService = LocalDBDataService.getInstance();
    await dbService.runSql(`
            delete from ng_fleet_vehicles;
            delete from ng_fleet_devices;
            delete from ng_devices;
            delete from ng_vehicles;
            delete from ng_fleets;
        `);
  }
}
