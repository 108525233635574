import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareAuthHeaders } from 'services/util';

import { API_PATH } from 'config';

export const ngHomepageApi = createApi({
  reducerPath: 'ngHomepageApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getFatigue: builder.query({
      query: () => '/home/fatigue'
    }),
    getDrivers: builder.query({
      query: () => '/home/drivers'
    }),
    getActiveDevices: builder.query({
      query: () => '/home/activevehiclesdevices'
    })
  })
});

export const { useGetFatigueQuery, useGetDriversQuery, useGetActiveDevicesQuery } = ngHomepageApi;
