import React from 'react';
import { useSelector } from 'react-redux';

import { LoadingTable } from 'components/grid/LoadingTable';
import { Column, Table, AutoSizer } from 'react-virtualized';
import {
  cache,
  detailsCellRenderer,
  branchCellRenderer,
  fullNameCellRenderer,
  ellipsisButtonCellRenderer,
  licenceCellRenderer,
  tachoCardCellRender,
  tachoCellCache
} from './CellRenderers';
import { useBranches } from 'features/locations/locationsSlice';
import { useTranslation } from 'react-i18next';
import { useCan, entities, useCanOneOfRoles, GlobalRoles } from 'features/permissions';
import { Empty } from 'components/ant';
import { Checkbox } from 'antd';
import styles from './Users.module.scss';
import { BulkEditControls } from './BulkEdit/BulkEditControls';
import { useBulkEdit } from 'features/bulkEdit/BulkEditProvider';
import { UserActionsCell } from './BulkEdit/Cell/UserActionsCell';

export const UsersTable = ({
  users,
  companies,
  isLoading,
  typeOfEntityToDelete,
  deleteUser,
  setToastMessage,
  setTableRef,
  filterTab,
  handleAction,
  handleDeleteAction,
  showTachoColumn,
  tachoData,
  localization,
  handleLogInAsUser
}) => {
  const { t } = useTranslation();
  const loggedInUserInfo = useSelector(state => state.user.currentUserInfo);
  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);
  const branches = useBranches();
  const getUserBranchById = id => branches.find(branch => branch.id === id);
  const can = useCan();
  const canViewUser = can && can({ everyEntity: [entities.USER_VIEW] });

  const {
    isBulkEditMode,
    selectedRowKeys,
    toggleHeaderSelection,
    toggleRowSelection
  } = useBulkEdit();

  const hideEdit = filterTab === 'deleted';
  const hideDelete = filterTab === 'deleted';
  const enableRestoreAction = filterTab === 'deleted';
  const columnDefinitions = [
    {
      dataKey: 'selection',
      minWidth: 20,
      weight: 0.2,
      headerRenderer: () => (
        <Checkbox
          checked={selectedRowKeys.length === users.length}
          indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < users.length}
          onChange={() => toggleHeaderSelection(users.map(user => user.id))}
        />
      ),
      cellRenderer: ({ rowData }) => (
        <Checkbox
          checked={selectedRowKeys.includes(rowData.id)}
          onChange={() => toggleRowSelection(rowData.id)}
        />
      ),
      condition: isBulkEditMode
    },
    {
      dataKey: 'firstName',
      label: t('Users.Table.Name'),
      minWidth: 200,
      weight: 2,
      cellRenderer: props => fullNameCellRenderer({ ...props, canViewUser })
    },
    {
      dataKey: 'companyId',
      label: t('Users.Table.Details'),
      minWidth: 200,
      weight: 3,
      cellRenderer: props =>
        detailsCellRenderer({
          ...props,
          companies: companies,
          filterTab: filterTab,
          showTachoColumn: showTachoColumn,
          localization: localization
        })
    },
    {
      dataKey: 'licenceNumber',
      label: t('Users.Table.DriversLicense'),
      minWidth: 150,
      weight: 2,
      cellRenderer: licenceCellRenderer
    },
    {
      dataKey: 'branch',
      label: t('Users.Table.Branch'),
      minWidth: 250,
      weight: 2,
      cellRenderer: props => {
        const branch = getUserBranchById(props.rowData?.location?.id);
        return branchCellRenderer(branch);
      }
    },
    {
      dataKey: 'tachoCard',
      label: t('Users.Table.TachoCard'),
      minWidth: 300,
      weight: 3,
      cellRenderer: props => tachoCardCellRender({ ...props, tachoData, localization }),
      condition: showTachoColumn
    },
    {
      dataKey: 'actions',
      label: t('Users.Table.Actions'),
      minWidth: 60,
      weight: 1,
      style: { overflow: 'visible' },
      cellRenderer: props =>
        ellipsisButtonCellRenderer({
          ...props,
          typeOfEntityToDelete,
          deleteUser,
          setToastMessage,
          hideEdit,
          hideDelete,
          enableRestoreAction,
          handleAction,
          handleDeleteAction,
          loggedInUserInfo,
          companies,
          handleLogInAsUser,
          hasSiteAdminOrResellerRole
        }),
      condition: !isBulkEditMode
    },
    {
      dataKey: 'actions',
      label: t('Users.Table.Actions'),
      minWidth: 60,
      weight: 1,
      cellRenderer: ({ rowData }) => (
        <UserActionsCell user={rowData} onDelete={handleDeleteAction} />
      ),
      condition: isBulkEditMode && selectedRowKeys.length === 0
    }
  ];

  const calculateColumnWidths = width => {
    const visibleColumns = columnDefinitions.filter(col => col.condition !== false);

    const totalWeight = visibleColumns.reduce((sum, col) => sum + (col.weight || 0), 0);

    return visibleColumns.map(col => {
      const { minWidth, weight, condition, ...rest } = col;
      const calculatedWidth = Math.max(minWidth, (weight / totalWeight) * width);

      return {
        ...rest,
        width: calculatedWidth
      };
    });
  };

  if (isLoading) return <LoadingTable nrOfRows={10} columnSizes={[110, 57, 57, 57, 94, 19]} />;
  const cellCache = showTachoColumn ? tachoCellCache : cache;
  return (
    <>
      {isBulkEditMode && (
        <div className={styles.topBar}>
          <div>
            <span>{`${t('Devices.BulkEdit.SelectedRows', {
              count: selectedRowKeys.length
            })}.`}</span>
            {!selectedRowKeys.length && (
              <span className={styles.topBarInfo}>{`${t(
                'Devices.BulkEdit.SelectedRowsInfo'
              )}.`}</span>
            )}
          </div>
          <BulkEditControls />
        </div>
      )}
      <AutoSizer>
        {({ height, width }) => {
          const columns = calculateColumnWidths(width);

          return (
            <Table
              deferredMeasurementCache={cellCache}
              width={width}
              height={height}
              headerHeight={52}
              headerStyle={{ paddingTop: '18px' }}
              rowHeight={cellCache.rowHeight}
              rowCount={users?.length || 0}
              rowGetter={({ index }) => users[index]}
              rowKey="id"
              rowStyle={{ alignItems: 'flex-start' }}
              rowClassName="tableRow"
              ref={ref => setTableRef(ref)}
              noRowsRenderer={() => <Empty />}
              rowSe
            >
              {columns.map(col => (
                <Column key={col.dataKey} {...col} />
              ))}
            </Table>
          );
        }}
      </AutoSizer>
    </>
  );
};
