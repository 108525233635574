import { useState, useEffect, useReducer, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useEventComments } from './useEventComments';
import { useEventAttachments } from './useEventAttachments';
import {
  addCameraEventAttachment,
  addCameraEventComment,
  deleteCameraEventAttachment,
  deleteCameraEventComment,
  updateCameraEventComment
} from 'features/camera/cameraEventApi';

export const Actions = {
  Init: 'Init',
  Comment: {
    Add: 'AddComment',
    Update: 'UpdateComment',
    Delete: 'DeleteComment'
  },
  Attachment: {
    Add: 'AddAttachment',
    Upload: 'UploadAttachment',
    Delete: 'DeleteAttachment'
  }
};

export const EventDetailsEntity = {
  Comment: 'Comment',
  Attachment: 'Attachment'
};

export function useEventDetailsReducer({ eventId, eventTimeAt, onInit }) {
  const dispatch = useDispatch();

  const {
    comments,
    isFetching: isFetchingComments,
    isFetched: isFetchedComments
  } = useEventComments({ eventId, eventTimeAt });
  const {
    attachments,
    isFetching: isFetchingAttachments,
    isFetched: isFetchedAttachments
  } = useEventAttachments({ eventId });

  const isFetching = useMemo(() => isFetchingComments || isFetchingAttachments, [
    isFetchingComments,
    isFetchingAttachments
  ]);
  const [details, updateDetails] = useReducer(
    (state, action) => {
      switch (action.type) {
        case Actions.Init:
          return {
            ...state,
            comments: action.payload.comments?.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            ),
            attachments: action.payload.attachments,
            init: true
          };
        case Actions.Comment.Add:
          return {
            ...state,
            comments: [action.payload, ...(state.comments || [])]
          };
        case Actions.Comment.Update:
          return {
            ...state,
            comments: state.comments
              .map(item => (item.id === action.payload.id ? { ...item, ...action.payload } : item))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          };
        case Actions.Comment.Delete:
          return {
            ...state,
            comments: state.comments
              .filter(item => item.id !== action.payload.id)
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          };
        // Handle attachments similarly...
        default:
          return state;
      }
    },
    { init: false, comments: [], attachments: [] }
  );

  const [actions] = useState({
    onAdd: entity => (data, onSuccess, onError) => {
      if (entity === EventDetailsEntity.Comment) {
        dispatch(
          addCameraEventComment({
            eventId,
            eventTimeAt,
            comment: data?.comment,
            onSuccess: comm => {
              updateDetails({ type: Actions.Comment.Add, payload: comm });
              if (onSuccess) {
                onSuccess(comm); // Call onSuccess with the added comment
              }
            },
            onError: error => {
              if (onError) onError(error); // Call onError if an error occurs
            }
          })
        );
      } else if (entity === EventDetailsEntity.Attachment) {
        dispatch(
          addCameraEventAttachment({
            eventId,
            attachment: data,
            onSuccess: attachment => {
              updateDetails({ type: Actions.Attachment.Add, payload: attachment });
              if (onSuccess) {
                onSuccess(attachment); // Call onSuccess with the added attachment
              }
            },
            onError: error => {
              if (onError) onError(error); // Call onError if an error occurs
            }
          })
        );
      }
    },

    onUpdate: entity => (data, onSuccess, onError) => {
      if (entity === EventDetailsEntity.Comment) {
        dispatch(
          updateCameraEventComment({
            eventId,
            eventTimeAt,
            commentId: data.id,
            comment: data?.comment,
            onSuccess: comm => {
              updateDetails({ type: Actions.Comment.Update, payload: comm });
              if (onSuccess) {
                onSuccess(comm); // Call onSuccess with the updated comment
              }
            },
            onError: error => {
              if (onError) onError(error); // Call onError if an error occurs
            }
          })
        );
      }
    },

    onDelete: entity => (data, onSuccess, onError) => {
      if (entity === EventDetailsEntity.Comment) {
        dispatch(
          deleteCameraEventComment({
            eventId,
            eventTimeAt,
            commentId: data?.id,
            onSuccess: comm => {
              updateDetails({ type: Actions.Comment.Delete, payload: comm });
              if (onSuccess) {
                onSuccess(comm); // Call onSuccess with the deleted comment
              }
            },
            onError: error => {
              if (onError) onError(error); // Call onError if an error occurs
            }
          })
        );
      } else if (entity === EventDetailsEntity.Attachment) {
        dispatch(
          deleteCameraEventAttachment({
            eventId,
            attachmentId: data?.attachmentId,
            onSuccess: attachment => {
              updateDetails({ type: Actions.Attachment.Delete, payload: attachment });
              if (onSuccess) {
                onSuccess(attachment); // Call onSuccess with the deleted attachment
              }
            },
            onError: error => {
              if (onError) onError(error); // Call onError if an error occurs
            }
          })
        );
      }
    }
  });

  useEffect(() => {
    if (isFetchedAttachments && isFetchedComments && !details.init) {
      updateDetails({ type: Actions.Init, payload: { comments, attachments } });
      if (onInit) {
        onInit({ comments, attachments });
      }
    }
  }, [isFetchedComments, isFetchedAttachments, details.init, comments, attachments, onInit]);

  return { details, actions, isFetching };
}
