import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const rulesetsDataAdapter = reduxAdapter({
  persist: async rulesets => {
    if (rulesets && rulesets.length) {
      for (const r of rulesets) {
        if (r.name) {
          await TNDataService.insertOrUpdateRuleset(r);
        }
      }
    }
  },
  load: async (includeRulesetPreRelease = false, forELDRule = false) => {
    const rulesets = await TNDataService.getAllRulesets();
    return rulesets.filter(
      r => (includeRulesetPreRelease || !r.isPreRelease) && (forELDRule ? r.forELD : !r.forELD)
    );
  }
});

export { rulesetsDataAdapter };
