import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { get, groupBy, sortBy } from 'lodash';

import { Table } from 'components/ant/Table';
import { VirtualTable, ResizableTitle } from 'components/ant/Table';
import { useLocalization } from 'features/localization/localizationSlice';
import { PATH_SPLIT, selectAll } from 'components/form/treeselect/TreeSelect';
import { GridHeader } from './GridHeader';
import { ViewsGridHeader } from './ViewsGridHeader/ViewsGridHeader';
import { applyViewsGridFilters } from './ViewsGridHeader/Modals/FilterColumnsModal/Filters';

import styles from './grid.module.scss';

export { ExpandIcon } from 'components/ant/Table';
const EMPTY_ARRAY = [];

// newer version of getRowValue that supports getValue function on column if it exists (new Tracking page)
export const getValue = (row, column, localization) => {
  if (column.getValue) {
    return column.getValue(row);
  } else {
    return getRowValue(row, column.dataIndex, localization);
  }
};

const getRowValue = (row, dataIndex, localization) => {
  if (!dataIndex) {
    return null;
  }

  if (typeof dataIndex === 'string') {
    let meter = row?.deviceStats?.meters?.find(meter => {
      if (meter?.label === dataIndex) {
        return meter?.value;
      }
    });
    let oneWireFilterData;
    oneWireFilterData = localization.convertTemperature(meter?.value);
    return row[dataIndex]?.toString() || oneWireFilterData;
  } else if (Array.isArray(dataIndex) && dataIndex.length > 0) {
    return get(row, dataIndex.join('.'))?.toString();
  } else {
    return row[dataIndex.toString()]?.toString();
  }
};

export const Grid = ({
  data,
  columns,
  allColumns,
  fleets = null,
  rowKey,
  recordTypeSingular,
  recordTypePlural,
  useViewsGridHeader = false,
  viewsGridFilters = null,
  onFilteredDevicesChanged = null,
  gridSettingsKey = null,
  defaultGridConfig = null,
  isLoading = false,
  showHeader = true,
  showSearch = true,
  showFilters = true,
  showCount = true,
  enableMultipleExpansion = true,
  enableVirtualScrolling = false,
  enableColumnResize = false,
  expandable,
  expandedRowKeys = EMPTY_ARRAY,
  onSearch,
  onFilter,
  onRowClick,
  onRowExpand = null,
  tableClassName,
  virtualClassName,
  oneWireMultiTempData,
  ...props
}) => {
  const [filters, setFilters] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [_expandedRowKeys, setExpandedRowKeys] = useState(expandedRowKeys);
  const [initialRowsInDom, setInitialRowsInDom] = useState(0);
  const virtualTableRef = useRef(null);

  const { t } = useTranslation();
  const localization = useLocalization();

  // init filters
  // todo: support props.childrenColumnName
  useEffect(() => {
    if (!data?.length) {
      return;
    }

    const initFilters = {};
    const valueGroups = {};

    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];

      if (column.isFilterable) {
        const node = {
          label: column.title,
          function: undefined,
          nodeKey: (i + 1).toString(),
          id: i + 1,
          children: [
            {
              ...selectAll,
              label: t('Common.' + selectAll.name),
              nodeKey: i + 1 + PATH_SPLIT + 0
            }
          ]
        };
        initFilters[node.id] = node;
        valueGroups[node.id] = {};

        const sortedList = sortBy(data, [o => getRowValue(o, column.dataIndex, localization)]);

        for (let j = 0, l = sortedList.length; j < l; j++) {
          const item = sortedList[j];
          const parentNode = initFilters[i + 1];
          const value = getRowValue(item, column.dataIndex, localization);

          if (!value) {
            continue;
          }

          if (!valueGroups[parentNode.id][value]) {
            const nodeId = parentNode.children.length;
            const childNode = {
              id: nodeId,
              label: value,
              function: undefined,
              parent: parentNode.nodeKey,
              nodeKey: parentNode.nodeKey + PATH_SPLIT + nodeId,
              dataKey: column.dataIndex
            };
            parentNode.children.push(childNode);
            valueGroups[parentNode.id][value] = true;
          }
        }
      }
    }

    setFilters(initFilters);
  }, [data]);

  // search & filter
  useEffect(() => {
    const searchableColumns = columns?.filter(col => col.isSearchable);
    let selectedChildFilters = [];

    for (let key in selectedFilters) {
      selectedChildFilters = selectedChildFilters.concat(
        selectedFilters[key]?.children.filter((node, index) => index > 0 && node.checked)
      );
    }
    const hasSelectedChildFilters = selectedChildFilters.length > 0;
    selectedChildFilters = groupBy(selectedChildFilters, 'parent');

    // apply search criteria
    let filtered = data.filter(row => {
      return searchText?.trim()
        ? searchableColumns.some(col =>
            getValue(row, col, localization)
              ?.toString()
              ?.toLowerCase()
              .includes(searchText?.trim()?.toLowerCase())
          )
        : true;
    });

    // apply filters
    if (hasSelectedChildFilters) {
      filtered = filtered?.filter(row => {
        let matchesFilters = true;
        for (let key in selectedChildFilters) {
          matchesFilters =
            matchesFilters &&
            selectedChildFilters[key]?.find(
              filter => getRowValue(row, filter.dataKey) === filter.label
            );
        }
        return matchesFilters;
      });
    }

    // apply viewsGridFilters
    if (viewsGridFilters && viewsGridFilters.length > 0) {
      filtered = applyViewsGridFilters(
        viewsGridFilters,
        filtered,
        columns,
        getRowValue,
        localization
      );
    }

    setFilteredData(filtered);

    if (onFilteredDevicesChanged) {
      onFilteredDevicesChanged(filtered);
    }
  }, [data, searchText, selectedFilters, viewsGridFilters, localization]);

  const handleRowExpand = (expanded, record) => {
    let newExpandedRowKeys;
    const key = rowKey ? rowKey : 'key';

    // console.debug('Grid - _expandedRowKeys', _expandedRowKeys);

    if (enableMultipleExpansion) {
      if (expanded) {
        newExpandedRowKeys = [..._expandedRowKeys, record[key]];
      } else {
        newExpandedRowKeys = _expandedRowKeys.filter(item => item !== record[key]);
      }
    } else {
      newExpandedRowKeys = expanded ? [record[key]] : [];
      const currentRowsInDom = virtualTableRef?.current?.getElementsByClassName(
        'ant-table-row-level-0'
      )?.length;
      setInitialRowsInDom(currentRowsInDom);
      if (!expanded) {
        // this is a workaround for TN360WEB-7313 and only for the current <VirtualTable> component
        // if the total number of rows in the DOM after the collapse is less than the inital number of rows
        // we're trigerring a 1px scroll to fix the virtual scroll missing rows from the DOM
        if (currentRowsInDom < initialRowsInDom) {
          virtualTableRef?.current?.getElementsByClassName('ant-table-body')?.[0]?.scrollTo(0, 2);
        }
      }
    }

    setExpandedRowKeys(newExpandedRowKeys);

    // console.debug('Grid - newExpandedRowKeys', newExpandedRowKeys);

    if (onRowExpand) {
      onRowExpand(expanded, record);
    }
  };

  const handleFiltersChange = filters => {
    setSelectedFilters(filters);
  };

  const handleSearchChange = searchText => {
    setSearchText(searchText);
  };

  let components = null;
  let columnsWidth = null;
  if (enableColumnResize) {
    if (columns && columns.length > 0) {
      // If supporting resizable columns, set grid width equal to column widths and add expandable column if needed
      columnsWidth = columns.reduce(
        (totalWidth, currentColumn) => totalWidth + currentColumn.width,
        0
      );
      columnsWidth += expandable ? 48 : 0;
    }

    components = {
      header: {
        cell: ResizableTitle
      }
    };
  }

  useEffect(() => {
    setExpandedRowKeys(expandedRowKeys);
  }, [expandedRowKeys]);

  return (
    <>
      {showHeader &&
        (useViewsGridHeader ? (
          <ViewsGridHeader
            allColumns={allColumns}
            fleets={fleets}
            count={filteredData.length}
            gridSettingsKey={gridSettingsKey}
            defaultGridConfig={defaultGridConfig}
            recordTypeSingular={recordTypeSingular}
            recordTypePlural={recordTypePlural}
            showSearch={showSearch}
            showCount={showCount}
            onSearchChange={handleSearchChange}
            oneWireMultiTempData={oneWireMultiTempData}
          />
        ) : (
          <GridHeader
            filters={filters}
            count={filteredData.length}
            recordTypeSingular={recordTypeSingular}
            recordTypePlural={recordTypePlural}
            showSearch={showSearch}
            showFilters={showFilters}
            showCount={showCount}
            onSearchChange={handleSearchChange}
            onFiltersChange={handleFiltersChange}
          />
        ))}
      {enableVirtualScrolling ? (
        <VirtualTable
          ref={virtualTableRef}
          className={`${styles.grid} ${styles.gridShowHorizontalScrollbar} ${virtualClassName}`}
          dataSource={filteredData}
          columns={columns}
          rowKey={rowKey}
          loading={isLoading}
          showSorterTooltip={false}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={false}
          expandable={expandable}
          onExpand={handleRowExpand}
          expandedRowKeys={_expandedRowKeys}
          components={components}
          columnsWidth={columnsWidth}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                onRowClick(record?.id);
              }
            };
          }}
          rowClassName={(record, index) =>
            _expandedRowKeys?.includes(record.id) ? styles.expandedRow : ''
          }
          {...props}
        />
      ) : (
        <Table
          className={`${styles.grid} ${tableClassName}`}
          dataSource={filteredData}
          columns={columns}
          rowKey={rowKey}
          loading={isLoading}
          showSorterTooltip={false}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={false}
          sticky={true}
          scroll={{ x: columnsWidth ? columnsWidth : 'max-content' }}
          expandable={expandable}
          onExpand={handleRowExpand}
          expandedRowKeys={_expandedRowKeys}
          components={components}
          columnsWidth={columnsWidth}
          onRow={record => {
            return {
              onClick: () => {
                onRowClick && onRowClick(record?.id);
              }
            };
          }}
          rowClassName={(record, index) =>
            _expandedRowKeys?.includes(record.id) ? styles.expandedRow : ''
          }
          {...props}
        />
      )}
    </>
  );
};
