import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useBulkEdit } from 'features/bulkEdit/BulkEditProvider';

export const BulkEditControls = () => {
  const { t } = useTranslation();
  const { isBulkDeleting, selectedRowKeys, handleBulkDelete } = useBulkEdit();

  return selectedRowKeys.length ? (
    <Space size={16}>
      <Button loading={isBulkDeleting} onClick={handleBulkDelete}>
        {`Delete ${selectedRowKeys.length} users`}
      </Button>
    </Space>
  ) : null;
};
