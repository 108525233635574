import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const deviceTypesDataAdapter = reduxAdapter({
  persist: async deviceTypes => {
    if (deviceTypes && deviceTypes.length) {
      for (const d of deviceTypes) {
        if (d.id) {
          await TNDataService.insertOrUpdateDeviceType(d);
        }
      }
    }
  },
  load: async () => {
    const deviceTypes = await TNDataService.getAllDeviceTypes();
    return deviceTypes;
  }
});

export { deviceTypesDataAdapter };
