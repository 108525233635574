import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const geofencesDataAdapter = reduxAdapter({
  persist: async geofences => {
    if (geofences && geofences.length) {
      await TNDataService.insertOrUpdateGeofencesInBulk(geofences);
      const geofenceAssociations = [];
      for (const g of geofences) {
        if (g.id) {
          if (g.fleets?.length > 0) {
            g.fleets.forEach(f => {
              geofenceAssociations.push({
                geofenceId: g.id,
                fleetId: f.id
              });
            });
          }
        }
      }
      if (geofenceAssociations.length) {
        await TNDataService.insertGeofenceAssociationsInBulk(geofenceAssociations);
      }
    }
  },
  load: async (loadFleets = false) => {
    const startTime = performance.now();
    const geofences = await TNDataService.getAllGeofences();
    if (loadFleets) {
      let fleets = await TNDataService.getAllFleets(true);
      fleets = fleets.reduce((a, f) => {
        a[f.id] = f;
        return a;
      }, {});
      const geofencesAssociations = await TNDataService.getAllGeofencesAssociations();
      geofences.forEach(g => {
        const geofenceFleetsIds = geofencesAssociations.filter(ga => ga.geofenceId === g.id);
        const geofenceFleets = geofenceFleetsIds.map(ga => fleets[ga.fleetId]);
        g.fleets = Array.from(geofenceFleets);
      });
    }
    const endTime = performance.now();
    console.log('Loading Done - takes time ' + (endTime - startTime));
    return geofences;
  }
});

export { geofencesDataAdapter };
