import React from 'react';
import { useLocation } from 'react-router';
import { Switch } from 'react-router-dom';

import { Can, entities } from 'features/permissions';

import CanRoute from 'components/route/CanRoute';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Vehicles } from './Vehicles';
import { VehicleView } from './VehicleView';
import { VehicleForm } from './VehicleForm';
import { VehicleAudits } from './VehicleAudits';
import { VehicleGPIO } from './VehicleGPIO';
import VehicleCalibrate from './VehicleCalibrate/VehicleCalibrate';
import { ACTIONS } from './constants';
import { VehiclesBulkEditAudit } from './BulkEdit/VehiclesBulkEditAudit';
import { bulkDeleteVehicleApi, bulkEditVehicleApi } from './APICalls';
import { fetchBulkEditAudits } from 'features/audits';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { BulkEditProvider } from 'features/bulkEdit/BulkEditProvider';
import { BULK_EDIT_AUDIT_ENTITY } from 'components/auditsTable/BulkEditAudits';

export const VehiclesRouter = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <BulkEditProvider
      title={t('Vehicles.Vehicles')}
      entity={entities.VEHICLE}
      onDelete={bulkDeleteVehicleApi}
      onSave={bulkEditVehicleApi}
      fetchAudit={() => {
        dispatch(fetchBulkEditAudits({ entityName: BULK_EDIT_AUDIT_ENTITY.VEHICLE }));
      }}
    >
      <Can everyEntity={[entities.VEHICLE]} onFail={<NoAccessBody />}>
        <Switch>
          <CanRoute
            path={'/settings/vehicles/id'}
            everyEntity={[entities.VEHICLE_VIEW]}
            render={<VehicleView />}
          />
          <CanRoute
            path={'/settings/vehicles/newVehicle'}
            everyEntity={[entities.VEHICLE_CREATE]}
            render={<VehicleForm action={ACTIONS.ADD} />}
          />
          <CanRoute
            path={'/settings/vehicles/edit/id'}
            everyEntity={[entities.VEHICLE_UPDATE]}
            render={<VehicleForm action={ACTIONS.EDIT} />}
          />
          <CanRoute
            path={'/settings/vehicles/calibrate/id'}
            everyEntity={[entities.VEHICLE_UPDATE]}
            render={<VehicleCalibrate />}
          />
          <CanRoute
            path={'/settings/vehicles/gpio/:vehicleId'}
            everyEntity={[entities.VEHICLE_UPDATE]}
            render={<VehicleGPIO isModal={false} />}
          />
          <CanRoute
            path={'/settings/vehicles/bulk_edit_audit'}
            everyEntity={[entities.VEHICLE_AUDIT]}
            render={<VehiclesBulkEditAudit />}
          />
          <CanRoute
            path={'/settings/vehicles/audit/id'}
            everyEntity={[entities.VEHICLE_AUDIT]}
            render={<VehicleAudits />}
          />
          <CanRoute
            path={'/settings/vehicles'}
            render={
              <Vehicles
                fleetId={location.state?.fleetId}
                gpioConfigurationId={location.state?.gpioConfigurationId}
              />
            }
          />
        </Switch>
      </Can>
    </BulkEditProvider>
  );
};
