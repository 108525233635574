export function deviceObjectToDBO(device) {
  return {
    id: device.id,
    name: device.name,
    path: device.path,
    version: device.version,
    status: device.status,
    company_id: device.company?.id,
    vehicle_id: device.vehicle?.id,
    device_type_id: device.type?.id,
    device_model_id: device.model?.id,
    did: device.did,
    registration: device.registration,
    serial_number: device.serialNumber,
    imei: device.imei,
    sim: device.sim,
    phone: device.phone,
    operating_system: device.operatingSystem,
    firmware_version: device.firmwareVersion,
    software_version: device.softwareVersion,
    note: device.note,
    auto: device.auto,
    iap_id: device.iapId,
    doc_id: device.docId,
    release_version: device.releaseVersion,
    external_id: device.externalId,
    tags: device.tags,
    trgm_compilation: device.trgmCompilation,
    registered_at: device.registeredAt,
    location_id: device.locationId,
    camera_sensitivity: device.cameraSensitivity,
    volume: device.volume,
    created_at: device.createdAt,
    updated_at: device.updatedAt,
    services: JSON.stringify(device.services || [])
  };
}

export function deviceDBOToObject(device) {
  return {
    id: device.id,
    name: device.name,
    path: device.path,
    version: device.version,
    status: device.status,
    companyId: device.company_id,
    company: {
      id: device.company_id
    },
    vehicle: device.vehicle_id && {
      id: device.vehicle_id
    },
    type: {
      id: device.device_type_id
    },
    model: {
      id: device.device_model_id
    },
    did: device.did,
    registration: device.registration,
    serialNumber: device.serial_number,
    imei: device.imei,
    sim: device.sim,
    phone: device.phone,
    operatingSystem: device.operating_system,
    firmwareVersion: device.firmware_version,
    softwareVersion: device.software_version,
    note: device.note,
    auto: device.auto,
    iapId: device.iap_id,
    docId: device.doc_id,
    releaseVersion: device.release_version,
    externalId: device.external_id,
    tags: device.tags,
    trgmCompilation: device.trgm_compilation,
    registeredAt: device.registered_at,
    location: {
      id: device.location_id
    },
    cameraSensitivity: device.camera_sensitivity,
    volume: device.volume,
    companyId: device.company_id,
    createdAt: device.created_at,
    updatedAt: device.updated_at,
    services: device.services ? JSON.parse(device.services) : []
  };
}
