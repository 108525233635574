import React from 'react';
import { Space, Button } from 'antd';
import { MoreMenu } from 'components/ant';
import { omit } from 'lodash';

/**
 * Buttons
 * @description Primary Action Buttons + MoreMenu; can be extended with a secondary action button
 * @param padding Padding wrapper
 * @param primaryButtons Buttons need to be outside ellipsis menu
 * @param moreButtons Array of objects with the buttons inside the MoreMenu
 * @param preventClose Boolean to prevent closing the Popover from the MoreMenu - used for showing button loaders
 *
 */

export const Buttons = ({
  padding = 16,
  primaryButtons = [],
  moreButtons = null,
  preventClose = false
}) => {
  return (
    <Space size={padding}>
      {primaryButtons
        .filter(button => button.visible !== false)
        .map(button => (
          <Button {...omit(button, ['visible'])} key={button.name} size={button.size ?? 'large'}>
            {button.name}
          </Button>
        ))}
      <MoreMenu size="xl" border items={moreButtons || []} useNewIcon preventClose={preventClose} />
    </Space>
  );
};
