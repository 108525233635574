import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useCan, entities } from 'features/permissions';

const VehicleName = ({ id, name, canViewVehicle }) =>
  canViewVehicle ? <Link to={`/settings/vehicles/id/${id}`}>{name}</Link> : <span>{name}</span>;

export const VehicleNameCell = ({ id, name, errMessage }) => {
  const can = useCan();
  return (
    <Space wrap size={2}>
      <VehicleName
        canViewVehicle={can({ everyEntity: [entities.VEHICLE_VIEW] })}
        id={id}
        name={name}
      />
      {errMessage && (
        <Tooltip title={errMessage} placement="rightTop">
          <FontAwesomeIcon style={{ fontSize: '18px', color: '#ffbf00' }} icon={faInfoCircle} />
        </Tooltip>
      )}
    </Space>
  );
};
