import React from 'react';
import MeterSourcesWrapper from './MeterSourcesWrapper';
import { useTranslation } from 'react-i18next';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

const VehicleManageMeterSources = ({
  options,
  onSourceChange,
  source,
  onCalculatedByChange,
  calculatedBy
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MeterSourcesWrapper
        title={t('Vehicles.EngineHours')}
        type="hours"
        options={options}
        onSourceChange={onSourceChange}
        source={source}
        onCalculatedByChange={onCalculatedByChange}
        calculatedBy={calculatedBy}
      />
      <MeterSourcesWrapper
        title={t('Vehicles.Odometer')}
        type="odometer"
        options={options}
        onSourceChange={onSourceChange}
        source={source}
        onCalculatedByChange={onCalculatedByChange}
        calculatedBy={calculatedBy}
      />
      <MeterSourcesWrapper
        title={t('Vehicles.Idle')}
        type="idle"
        options={options}
        onSourceChange={onSourceChange}
        source={source}
        onCalculatedByChange={onCalculatedByChange}
        calculatedBy={calculatedBy}
      />
    </React.Fragment>
  );
};

export default VehicleManageMeterSources;
