import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useDevices, useIsFetchingDevicesFinished } from 'features/fleets/fleetsSlice';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';

// helpers
import { getIDFromPathname } from 'utils/methods';

//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { useHistory } from 'react-router';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { PATHS } from './constants';

export const DeviceAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const history = useHistory();
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.DEVICE_DEFAULT);
  }, [history]);
  const audits = useAudits(AUDIT_ENTITY.DEVICE, id);
  const devices = useDevices();
  const device = devices?.find(device => device.id === Number(id));
  const hasDevicesFetched = useIsFetchingDevicesFinished();
  const pageTitle = !device
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: device?.name });

  useRedirectToMainFeaturePageOnCompanyChange('/settings/devices');

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  if (id <= 0 || isNaN(id) || (hasDevicesFetched && !device && !audits)) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t('Common.Invalid Request ID')
      })
    );
    handleFetchError();
  }

  const onRenderChangeItemKey = useCallback(
    changeKey =>
      changeKey === 'camera.channels' ? t('Devices.MultiView.Configurations') : changeKey,
    []
  );

  const onRenderChangeItemValue = useCallback((displayValue, { changeItem, changeKey, colKey }) => {
    if (changeKey === 'camera.channels') {
      const item = colKey === 'from' ? changeItem[0] : changeItem[1];
      if (Array.isArray(item)) {
        return item.map(channelItem => {
          if (channelItem && Object.keys(channelItem).length) {
            const { name, channel, position, hflip, vflip, ...others } = channelItem;
            const channelDisplayName =
                channel === 'UVC'
                  ? name
                  : `${name} (${t([`Devices.MultiView.${position}`, position])})`,
              channelHFlip = `${t('Devices.MultiView.hflip')}: ${t(
                `Common.${hflip ? 'On' : 'Off'}`
              )}`;
            const channelVFlip = `${t('Devices.MultiView.vflip')}: ${t(
              `Common.${vflip ? 'On' : 'Off'}`
            )}`;
            return (
              <div key={channel}>
                <div>{channelDisplayName}</div>
                <div>{channelHFlip}</div>
                <div>{channelVFlip}</div>
                {Object.entries(others).map(([key, value]) => (
                  <div key={`${channel}-${key}`}>{`${key}: ${value}`}</div>
                ))}
              </div>
            );
          }
        });
      }
    }
    return displayValue;
  }, []);

  return (
    <AuditsTable
      audits={audits}
      entity={AUDIT_ENTITY.DEVICE}
      onRenderChangeItemKey={onRenderChangeItemKey}
      onRenderChangeItemValue={onRenderChangeItemValue}
    />
  );
};
