import { vehicleTypeMetrics } from 'features/configuration/constants';

export const setLocalizedValues = ({
  localization,
  metrics,
  values,
  id,
  services,
  nullableMetrics
}) => {
  const result = [];
  nullableMetrics = nullableMetrics || [];
  Object.keys(metrics).forEach(metric => {
    const isNullableMetric = nullableMetrics.some(nullableMetric => nullableMetric === metric);
    if (!!Number(values[metric]) || isNullableMetric) {
      result.push({
        vehicleTypeId: id,
        service: services,
        key: metrics[metric],
        value: getLocalizedValue({
          localization,
          metricKey: metrics[metric],
          metricValue: values[metric],
          setInDatabaseUnit: true
        })
      });
    }
  });
  return result;
};

export const getLocalizedValue = ({
  localization,
  metricKey,
  metricValue,
  setInDatabaseUnit = false
}) => {
  switch (metricKey) {
    case vehicleTypeMetrics.harshAcceleration:
    case vehicleTypeMetrics.harshBrake:
    case vehicleTypeMetrics.harshCornering:
    case vehicleTypeMetrics.harshCorneringLeft:
    case vehicleTypeMetrics.harshCorneringRight:
    case vehicleTypeMetrics.impact:
      if (isNaN(Number(metricValue))) {
        return metricValue;
      }
      return localization.convertAcceleration(Number(metricValue), setInDatabaseUnit);
    case vehicleTypeMetrics.oilOverTemp:
    case vehicleTypeMetrics.coolantOverTemp:
      return localization.convertTemperature(Number(metricValue), setInDatabaseUnit);
    case vehicleTypeMetrics.oilPressure:
      return localization.convertPressure(Number(metricValue), setInDatabaseUnit);
    case vehicleTypeMetrics.averagefuelefficiency:
      if (isNaN(Number(metricValue))) {
        return metricValue;
      }
      return localization.convertFuelEfficiency(Number(metricValue), setInDatabaseUnit).toFixed(2);
    case vehicleTypeMetrics.co2emissionsfactor:
      if (isNaN(Number(metricValue))) {
        return metricValue;
      }
      return localization.convertCO2Emissions(Number(metricValue), setInDatabaseUnit).toFixed(2);
    default:
      return Number(metricValue);
  }
};
