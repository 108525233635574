import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  InputNumber,
  Modal,
  Space,
  Spin,
  Table,
  Tooltip
} from 'antd';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';

import {
  FeatureFlag,
  services,
  useCanEveryCompanyService,
  useCanFeatureFlag
} from 'features/permissions';
import { useCurrentCompany } from 'features/company/companySlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import { fetchFleets } from 'features/fleets/fleetsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';

import {
  useAddGpioConfigurationTemplateMutation,
  useCurrentCompanyServicesMap,
  useUpdateGpioConfigurationTemplateMutation
} from 'services/nextgen/ngGpioConfigurationApi';

import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

import { GPIOFormModal } from './GPIOFormModal';
import { GPIOTableModal } from './GPIOTableModal';
import { gpioDiginEditColumns, gpioDigoutEditColumns, PATHS, Scope } from './constants';
import {
  enrichWithExtraServiceConfig,
  generateGpioConfigs,
  inputValidator,
  toTableStructure,
  useConfigMapping
} from './helpers';
import { useLocalization } from 'features/localization/localizationSlice';
import { UNITS } from 'features/localization/localization';
import { canHistoryGoBack } from 'utils/methods';
import { DevicesAssignmentTable } from '../components/DevicesAssignmentTable';
import { templateTypeEnum } from '../components/constants';
import style from './GPIOForm.module.scss';
import { BasicInfoSection } from './components/BasicInfoSection';
import { FleetsAssignmentTable } from '../components/FleetsAssignmentTable';
import { useEntityTemplateAssociations } from './hooks/useEntityTemplateAssociations';
import { HeaderFormSection } from '../components/HeaderFormSection';
import { useGPIORelatedConfigs } from './hooks/useGPIORelatedConfigs';
import { ngDeviceApi } from 'services/nextgen/ngDeviceApi';

export const GPIOForm = ({ templateId, action }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [gpioList, setGpioList] = useState([]);
  const [editingConfig, setEditingConfig] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [selectScope, setSelectScope] = useState(null);
  const [previousTemplateScope, setPreviousTemplateScope] = useState(null);
  const isCompanyLevel = selectScope === Scope.COMPANY;

  const [dirty, setDirty] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysForFilteredEntities, setSelectedRowKeysForFilteredEntities] = useState([]);
  const serviceMap = useCurrentCompanyServicesMap();
  const [modal, contextHolder] = Modal.useModal();
  const [gpioModalVisible, setGpioModalVisible] = useState(false);
  const [gpioModalData, setGpioModalData] = useState();
  const [gpioModalTempateName, setGpioModalTemplateName] = useState();
  const [formValid, setFormValid] = useState(true);

  const [diginData, setDiginData] = useState([]);
  const [digoutData, setDigoutData] = useState([]);

  const driverLoginalertFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.driverLoginAlert.flag
  });

  const canFleetLevelGpio = useCanFeatureFlag({
    featureFlag: FeatureFlag.fleetLevelGpio.flag
  });

  const isVPMEnabled = useCanEveryCompanyService(services.VPM);

  const isDriverPinServiceEnabled = useCanEveryCompanyService(services.DRIVERPIN);
  const isDriverEnabled = driverLoginalertFlag ? isDriverPinServiceEnabled : false;

  const vt202OutputFeature = useCanFeatureFlag({
    featureFlag: FeatureFlag.vt202Output.flag
  });

  const types = useDeviceTypesList();
  const hermes = types.find(type => type.code === 'HERMES');
  const { templateList, deviceList, fleetList, isFetching } = useEntityTemplateAssociations();

  const [
    addGpioConfigurationTemplate,
    { isLoading: isAddingTemplating }
  ] = useAddGpioConfigurationTemplateMutation();
  const [
    updateGpioConfigurationTemplate,
    { isLoading: isUpdatingTemplating }
  ] = useUpdateGpioConfigurationTemplateMutation();

  const hasCompanyScopeTemplate = useMemo(
    () =>
      templateList?.some(
        template => template.scope === Scope.COMPANY && template.id !== templateId
      ),
    [templateList]
  );

  const companyHermesDevices = useMemo(
    () =>
      deviceList.filter(
        device =>
          device?.type?.id === hermes?.id &&
          device?.company?.id === currentCompany.id &&
          device?.services?.includes('GPIO')
      ),
    [hermes, currentCompany, deviceList]
  );

  const [devices, setDevices] = useState(companyHermesDevices);
  const [fleets, setFleets] = useState(fleetList);

  useEffect(() => {
    setDevices(companyHermesDevices);
  }, [companyHermesDevices]);

  useEffect(() => {
    setFleets(fleetList);
  }, [fleetList]);

  const {
    gpioConfigs,
    vpmConfigs,
    bpmConfigs,
    idleConfigs,
    driverConfigs,
    sirenConfigs,
    extraConfigs,
    isFetching: isFetchingGpioData
  } = useGPIORelatedConfigs();

  const {
    inBiasMap,
    inTriggerMap,
    inOccurrenceMap,
    outBiasMap,
    outTriggerMap,
    outOccurrenceMap,
    diginFormConfig,
    digoutFormConfig,
    seatbeltSpeedThresholdDefault,
    channelTypes
  } = useConfigMapping(gpioConfigs);

  const [modalVisible, setModalVisible] = useState(false);

  const diginIODefinition = diginFormConfig.find(cfgField => cfgField.type === 'io');
  const digoutIODefinition = digoutFormConfig.find(cfgField => cfgField.type === 'io');

  const getChannels = channelType => {
    const digoutChannelSize = vt202OutputFeature ? 5 : 4;
    let channelSize = channelTypes.find(c => channelType === c.form + '.' + c.key)?.channels;
    if (!channelSize) {
      if (channelType?.startsWith('digout.')) {
        channelSize = digoutChannelSize;
      } else {
        channelSize = 9;
      }
    }
    const channels = Array.from({ length: channelSize }, (_, index) => ({
      key: `${index + 1}`,
      value: `${index + 1}`
    })).filter(o => {
      const index = gpioList.findIndex(i => i.channel === o.value && i.input === channelType);
      return index < 0 || index === editingIndex;
    });
    return channels;
  };

  const handleViewGpio = record => {
    const gpioConfigs = enrichWithExtraServiceConfig(
      record.template?.configurations,
      serviceMap,
      diginIODefinition,
      digoutIODefinition,
      channelTypes,
      localization
    );
    setGpioModalData(gpioConfigs);
    setGpioModalVisible(true);
    setGpioModalTemplateName(record.template?.name);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleValidate = values => {
    let foundIndex = gpioList.findIndex(
      (item, currentIndex) =>
        item.input === values.input &&
        item.channel === values.channel &&
        currentIndex !== editingIndex
    );
    if (foundIndex > -1) {
      throw new Error(t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Channel Already Exists'));
    }
    if (values.io === 'siren') {
      foundIndex = gpioList.findIndex(
        (item, currentIndex) =>
          item.input === values.input && item.io === 'siren' && currentIndex !== editingIndex
      );
      if (foundIndex > -1) {
        throw new Error(
          t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Siren Output Channel Already Exists')
        );
      }
    }
    if (values.io === 'led') {
      foundIndex = gpioList.findIndex(
        (item, currentIndex) =>
          item.input === values.input && item.io === 'led' && currentIndex !== editingIndex
      );
      if (foundIndex > -1) {
        throw new Error(
          t('CompanyConfig.DeviceConfigurations.GPIOTemplates.LED Output Channel Already Exists')
        );
      }
    }
  };

  const handleSubmit = values => {
    setDirty(true);
    if (editingIndex > -1) {
      const cloned = [...gpioList];
      cloned[editingIndex] = values;
      setGpioList(cloned);
    } else {
      setGpioList([...gpioList, values]);
    }
    setModalVisible(false);
  };

  const handleEdit = record => {
    setEditingIndex(gpioList.indexOf(record));
    setEditingConfig(record);
    setModalVisible(true);
  };

  const handleDelete = record => {
    const newData = gpioList.filter(d => d !== record);
    setGpioList(newData);

    setDirty(true);
  };

  const scopeMapping = {
    [Scope.COMPANY]: {},
    [Scope.FLEET]: {
      data: fleets,
      entityKey: 'fleets',
      idKey: 'fleetIds',
      associationKey: 'associatedFleets',
      entityType: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Fleets')
    },
    [Scope.DEVICE]: {
      data: devices,
      entityKey: 'devices',
      idKey: 'deviceIds',
      associationKey: 'associatedDevices',
      entityType: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Devices')
    }
  };

  const handleSaveForm = async () => {
    const { data, entityType } = scopeMapping[selectScope];

    let confirmed = true;
    if (previousTemplateScope === Scope.COMPANY && selectScope !== Scope.COMPANY) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.RemovingCompanyDefaultMessage')
      });
    }

    if (selectScope !== Scope.COMPANY) {
      const hasEntityChangeTemplate = data?.some(
        entity =>
          selectedRowKeys.includes(entity.id) &&
          entity.template &&
          entity.template.id !== templateId
      );

      if (confirmed && hasEntityChangeTemplate) {
        confirmed = await modal.confirm({
          content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeviceAlreadyAssigned', {
            entityType,
            templateName: templateTypeEnum.GPIO
          })
        });
      }
    }

    const templateServices = new Set();
    gpioList.forEach(item => {
      if (item.vpm?.['gpio.output']) {
        templateServices.add('VPM');
      }
      if (item.bpm?.['gpio.output']) {
        templateServices.add('BPM');
      }
      if (item.speedsiren?.['gpio.output']) {
        templateServices.add('SPEEDSIREN');
      }
    });

    if (selectScope === Scope.DEVICE) {
      const serviceArray = [...templateServices];
      const deviceNotFullfiled = [...new Set(selectedRowKeys)]
        .map(id => devices.find(d => d.id === id))
        .filter(d => !serviceArray.every(e => d?.services?.includes(e))).length;
      if (confirmed && deviceNotFullfiled > 0) {
        confirmed = await modal.confirm({
          content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeviceNotFulfilled', {
            count: deviceNotFullfiled
          })
        });
      }
    }

    if (confirmed) {
      submitForm();
    }
  };

  const submitForm = () => {
    const { idKey } = scopeMapping[selectScope];

    const gpioService = serviceMap['GPIO'];
    const driverService = serviceMap['DRIVERPIN'];

    let gpioConfigs = generateGpioConfigs(
      localization,
      gpioList,
      serviceMap,
      diginFormConfig,
      digoutFormConfig,
      isVPMEnabled
    );

    form.validateFields().then(values => {
      const seatbeltSpeedThreshold = form.getFieldValue('seatbelt.speed.threshold');
      if (seatbeltSpeedThreshold) {
        gpioConfigs.push({
          service: gpioService,
          key: 'seatbelt.speed.threshold',
          value: localization.convertSpeedWithUnit(
            Number(seatbeltSpeedThreshold),
            UNITS.KM,
            localization?.formats?.speed?.unit || UNITS.KM,
            0
          )
        });
      }

      const driverLoggedOffExists = gpioConfigs.some(
        config => config.key === 'driver.loggedoff.detection'
      );

      const driverGpioOutput = gpioConfigs.some(config => config.key === 'driver.gpio.output');

      const hasDigoutWithSiren = gpioConfigs.some(
        config => config.key?.startsWith('digout.') && config.value.includes('"label":"siren"')
      );
      // If 'driver.loggedoff.detection' does not exist, add it
      if (isDriverEnabled && !driverLoggedOffExists && hasDigoutWithSiren) {
        gpioConfigs.push({
          service: driverService,
          key: 'driver.loggedoff.detection',
          value: false
        });
      }

      if (isDriverEnabled && driverLoggedOffExists && !driverGpioOutput && hasDigoutWithSiren) {
        const newConfigs = gpioList
          .filter(item => item.driver?.['driver.loggedoff.detection'] === true)
          .map(item => ({
            service: driverService,
            key: 'driver.gpio.output',
            value: item.channel
          }));

        // Push the new configs into gpioConfigs
        gpioConfigs.push(...newConfigs); // Spread the array to push each config
      }

      const configurationTemplateRequest = {
        configurationTemplate: {
          name: values.name,
          description: values.description,
          type: 'GPIO',
          company: {
            id: currentCompany.id
          },
          status: 'ENABLED',
          configurations: gpioConfigs,
          scope: selectScope,
          default: canFleetLevelGpio ? selectScope === Scope.COMPANY : undefined
        }
      };

      if (!isCompanyLevel) {
        configurationTemplateRequest[idKey] = [...new Set(selectedRowKeys)];
      }

      if (action === 'add') {
        addGpioConfigurationTemplate({ body: configurationTemplateRequest })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      } else {
        updateGpioConfigurationTemplate({ body: configurationTemplateRequest, id: templateId })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      }
    });
  };

  const handleResult = result => {
    const { entityKey, associationKey, entityType } = scopeMapping[selectScope];

    const response = result.data;
    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(result?.error?.data)}`
        })
      );
      return;
    }

    dispatch(ngDeviceApi.util.invalidateTags(['deviceTemplates']));

    if (response?.errors?.length > 0) {
      const mainMessage = t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarning', {
        count: selectScope === response[associationKey]?.length,
        entityType
      });
      const errorMessages = response.errors.map(err => (
        <React.Fragment>
          {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarningDetail', {
            count: err[entityKey]?.length,
            entityType,
            error: t(
              'CompanyConfig.DeviceConfigurations.GPIOTemplates.error.' + err.message,
              err.message
            )
          })}
          <br />
        </React.Fragment>
      ));
      const finalMessage = (
        <React.Fragment>
          {mainMessage}
          <br />
          {errorMessages}
        </React.Fragment>
      );
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: finalMessage
        })
      );

      dispatch(fetchFleets());
      canHistoryGoBack(history, PATHS.GPIO_DEFAULT);
      return;
    }

    dispatch(fetchFleets());
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
      })
    );
    canHistoryGoBack(history, PATHS.GPIO_DEFAULT);
  };

  const onScopeChange = scope => {
    if (scope === selectScope) {
      return;
    }

    setSelectScope(scope);
    form.setFieldsValue({ scope });
    setDirty(true);
    updateEntitySelection(
      scope,
      !!templateId ? templateList.find(item => item.id === templateId)?.associations : undefined
    );
  };

  const updateEntitySelection = (scope, existingAssociations) => {
    const selectedIds =
      existingAssociations?.[scopeMapping[scope]?.entityKey]?.map(entity => entity.id) || [];

    setSelectedRowKeys(selectedIds);
    setSelectedRowKeysForFilteredEntities(selectedIds);
  };

  useEffect(() => {
    if (templateId && !isFetchingGpioData) {
      const editingTemplate = { ...templateList.find(item => item.id === templateId) };
      if (!diginIODefinition || !digoutIODefinition || !editingTemplate.configurations) {
        return;
      }
      form.setFieldsValue(editingTemplate);
      const seatbeltSpeedThreshold = editingTemplate.configurations.find(
        item => item.key === 'seatbelt.speed.threshold'
      );
      if (seatbeltSpeedThreshold) {
        form.setFieldValue(
          'seatbelt.speed.threshold',
          localization.convertDistance(seatbeltSpeedThreshold.value, 0)
        );
      }

      setSelectScope(
        canFleetLevelGpio
          ? editingTemplate.scope
          : editingTemplate.default
          ? Scope.COMPANY
          : Scope.DEVICE
      );
      setPreviousTemplateScope(
        canFleetLevelGpio
          ? editingTemplate.scope
          : editingTemplate.default
          ? Scope.COMPANY
          : Scope.DEVICE
      );
      updateEntitySelection(
        canFleetLevelGpio
          ? editingTemplate.scope
          : editingTemplate.default
          ? Scope.COMPANY
          : Scope.DEVICE,
        editingTemplate.associations
      );

      const gpioConfigs = enrichWithExtraServiceConfig(
        editingTemplate.configurations,
        serviceMap,
        diginIODefinition,
        digoutIODefinition,
        channelTypes,
        localization
      );

      setGpioList(gpioConfigs);
    } else if (!isFetchingGpioData) {
      setSelectScope(Scope.DEVICE);
      form.setFieldsValue({ scope: Scope.DEVICE });
    }
  }, [templateList, templateId, isFetchingGpioData]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        t(
          action === 'add'
            ? 'CompanyConfig.DeviceConfigurations.GPIOTemplates.AddTemplate'
            : 'CompanyConfig.DeviceConfigurations.GPIOTemplates.UpdateTemplate'
        )
      )
    );
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    const entityKeys = new Set(scopeMapping[selectScope]?.data?.map(d => d.id));

    // Keep only the state of the selected devices that are out of the filtered list.
    let newSelectedKeys = selectedRowKeys.filter(k => !entityKeys.has(k));
    newSelectedKeys = [...newSelectedKeys, ...selectedRowKeysForFilteredEntities];
    setSelectedRowKeys(newSelectedKeys);
  }, [selectedRowKeysForFilteredEntities]);

  useEffect(() => {
    setDiginData(gpioList.filter(cfg => cfg.input.startsWith('digin.')));
    setDigoutData(gpioList.filter(cfg => cfg.input.startsWith('digout.')));
  }, [gpioList]);

  const initializing = isFetching || isFetchingGpioData;

  const allChannelEmpty = useMemo(() => {
    for (const { key, form } of channelTypes) {
      const channelType = `${form}.${key}`;
      const channels = getChannels(channelType);
      if (channels.length !== 0) {
        return false;
      }
    }
    return true;
  }, [channelTypes, gpioList]);

  if (initializing) {
    return <Spin size="large"></Spin>;
  }

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // Update the selected rows in the state
      setSelectedRowKeysForFilteredEntities(selectedRowKeys);
      setDirty(true);
    }
  };

  const getGPIORowKey = record => `${record.input}-${record.channel}`;

  const inGPIOData = toTableStructure(channelTypes, diginData);
  const outGPIOData = toTableStructure(channelTypes, digoutData);

  const formItemStyle = {
    marginBottom: '3px' // Adjust the bottom margin as needed
  };

  const setCompanyLevel = checked => {
    onScopeChange(checked ? Scope.COMPANY : Scope.DEVICE);
  };

  const entityAgreementReminder = (
    <Alert
      className={style.alert}
      message={t(`CompanyConfig.DeviceConfigurations.GPIOTemplates.AgreementReminder`)}
      type="info"
      showIcon
    />
  );

  return (
    <div className={style.gpioForm}>
      {contextHolder}
      <EditRouteGuard when={dirty} navigate={history.push} />
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allValues) => {
          setDirty(true);
          setFormValid(allValues.name && allValues.name.trim() !== '');
        }}
      >
        <Card className={style.headerSectionCard}>
          {canFleetLevelGpio ? (
            <BasicInfoSection
              selectedScope={selectScope}
              onScopeChange={onScopeChange}
              seatbeltSpeedThresholdDefault={seatbeltSpeedThresholdDefault}
              hasCompanyScopeTemplate={hasCompanyScopeTemplate}
            />
          ) : (
            <HeaderFormSection
              formItemStyle={formItemStyle}
              setCompanyLevel={setCompanyLevel}
              templateName={'GPIO'}
              customComponent={
                <Form.Item
                  style={formItemStyle}
                  className={style.seatbeltSpeedInput}
                  tooltip={
                    gpioConfigs?.find(c => c.key === 'seatbelt.speed.threshold')?.description || ''
                  }
                  label={
                    t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Seatbelt Speed Threshold') +
                    ' (' +
                    localization.formats.speed.unit_per_hour +
                    ')'
                  }
                  rules={inputValidator().number}
                  name="seatbelt.speed.threshold"
                  initialValue={localization.convertDistance(seatbeltSpeedThresholdDefault, 0)}
                >
                  <InputNumber
                    precision={0}
                    placeholder={localization.convertDistance(seatbeltSpeedThresholdDefault, 0)}
                  />
                </Form.Item>
              }
            />
          )}
        </Card>
        <Card className={style.configSectionCard}>
          <div className={style.configSectionContainer}>
            <div className={style.title}>Configurations</div>
            <Tooltip
              placement="top"
              title={
                allChannelEmpty
                  ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllChannelIsAssigned')
                  : undefined
              }
              trigger="hover"
            >
              <Button
                disabled={allChannelEmpty}
                onClick={() => {
                  setEditingIndex(-1);
                  setEditingConfig({});
                  setModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AddConfig')}
              </Button>
            </Tooltip>
            {modalVisible ? (
              <GPIOFormModal
                visible={modalVisible}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                diginForm={diginFormConfig}
                digoutForm={digoutFormConfig.concat(extraConfigs)}
                values={editingConfig}
                onValidate={handleValidate}
                getChannels={getChannels}
                channelTypes={channelTypes}
              />
            ) : null}
          </div>
          {inGPIOData?.length > 0 &&
            inGPIOData.map(digin => {
              return (
                digin.data?.length > 0 && (
                  <>
                    <Divider orientation="left" orientationMargin={0} plain>
                      {t(
                        'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + digin.value,
                        digin.value
                      )}
                    </Divider>
                    <Table
                      columns={gpioDiginEditColumns(
                        t,
                        inBiasMap,
                        inTriggerMap,
                        inOccurrenceMap,
                        diginIODefinition,
                        handleEdit,
                        handleDelete
                      )}
                      dataSource={sortBy(digin.data, 'channel')}
                      pagination={false}
                      rowKey={getGPIORowKey}
                    ></Table>
                  </>
                )
              );
            })}
          {outGPIOData?.length > 0 &&
            outGPIOData.map(digout => {
              return (
                digout.data?.length > 0 && (
                  <>
                    <Divider orientation="left" orientationMargin={0} plain>
                      {t(
                        'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + digout.value,
                        digout.value
                      )}
                    </Divider>
                    <Table
                      columns={gpioDigoutEditColumns(
                        t,
                        outBiasMap,
                        outTriggerMap,
                        outOccurrenceMap,
                        vpmConfigs,
                        bpmConfigs,
                        idleConfigs,
                        driverConfigs,
                        sirenConfigs,
                        handleEdit,
                        handleDelete
                      )}
                      dataSource={sortBy(digout.data, 'channel')}
                      pagination={false}
                      rowKey={getGPIORowKey}
                    ></Table>
                  </>
                )
              );
            })}
        </Card>
      </Form>
      {isCompanyLevel && (
        <Card className={style.deviceSectionCardCompanyLevel}>
          <Alert
            message={t(`CompanyConfig.DeviceConfigurations.GPIOTemplates.CompanyLevelNote`)}
            type="info"
            showIcon
          />
        </Card>
      )}
      {!isCompanyLevel && gpioModalVisible && (
        <GPIOTableModal
          visible={gpioModalVisible}
          templateName={gpioModalTempateName}
          inBiasMap={inBiasMap}
          inOccurrenceMap={inOccurrenceMap}
          inTriggerMap={inTriggerMap}
          outBiasMap={outBiasMap}
          outOccurrenceMap={outOccurrenceMap}
          outTriggerMap={outTriggerMap}
          diginIODefinition={diginIODefinition}
          vpmData={vpmConfigs}
          bpmData={bpmConfigs}
          speedsirenData={sirenConfigs}
          idleData={idleConfigs}
          driverData={driverConfigs}
          data={gpioModalData}
          channelTypes={channelTypes}
          onClose={() => setGpioModalVisible(false)}
        ></GPIOTableModal>
      )}

      {selectScope === Scope.FLEET && (
        <FleetsAssignmentTable
          filteredFleets={fleets}
          setFilteredFleets={setFleets}
          titleAddon={entityAgreementReminder}
          rowSelection={rowSelection}
          handleViewTemplate={handleViewGpio}
        />
      )}

      {selectScope === Scope.DEVICE && (
        <DevicesAssignmentTable
          useTitle
          titleAddon={canFleetLevelGpio ? entityAgreementReminder : undefined}
          filteredDevices={companyHermesDevices}
          devices={devices}
          setDevices={setDevices}
          templates={templateList}
          currentCompany={currentCompany}
          handleViewTemplate={handleViewGpio}
          rowSelection={rowSelection}
          templateType={templateTypeEnum.GPIO}
        />
      )}
      <div className={style.footerSectionCard}>
        <Space>
          <Button
            type="primary"
            size="large"
            disabled={!dirty || !formValid || isAddingTemplating || isUpdatingTemplating}
            onClick={handleSaveForm}
            id={BUTTON_IDS.gpioFormSave}
          >
            {t('Common.SaveButton')}
          </Button>
          <Button size="large" id={BUTTON_IDS.gpioFormCancel} onClick={history.goBack}>
            {t('Common.CancelButton')}
          </Button>
        </Space>
      </div>
      <EditRouteGuard when={dirty} navigate={history.push}></EditRouteGuard>
    </div>
  );
};
