import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const locationTypesDataAdapter = reduxAdapter({
  persist: async locationTypes => {
    if (locationTypes && locationTypes.length) {
      for (const l of locationTypes) {
        if (l.id) {
          await TNDataService.insertOrUpdateLocationType(l);
        }
      }
    }
  },
  load: async () => {
    const locationTypes = await TNDataService.getAllLocationTypes();
    return locationTypes;
  }
});

export { locationTypesDataAdapter };
