import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchCameraEventAttachments } from 'features/camera/cameraEventApi';

export const useEventAttachments = ({ eventId, onSuccess, onError }) => {
  const dispatch = useDispatch();
  const reqCtxRef = useRef({
    request: null,
    fetchTime: null,
    error: null
  });
  const [attachments, setAttachments] = useState();

  const fetchAttachments = useCallback(() => {
    const reqCtx = reqCtxRef.current;
    if (!reqCtx.isFetching && !reqCtx.fetchTime) {
      const onReqDone = error => {
        if (reqCtxRef.current.request?.abort) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current = {
          request: null,
          isFetching: false,
          fetchTime: moment().valueOf(),
          error: error || null
        };
      };

      if (reqCtx.request?.abort) {
        reqCtx.request.abort();
        reqCtx.request = null;
      }
      reqCtx.request = dispatch(
        fetchCameraEventAttachments({
          eventId,
          onSuccess: atts => {
            onReqDone();
            setAttachments(atts);
            if (onSuccess) {
              onSuccess(atts);
            }
          },
          onError: errMsg => {
            onReqDone(errMsg);
            setAttachments(null);
            if (onError) {
              onError(errMsg);
            }
          }
        })
      );
      const fetchData = async () => {
        try {
          reqCtx.isFetching = true;
          await reqCtx.request;
        } catch (e) {
          onReqDone(e);
        }
      };
      fetchData();
    }
  }, [dispatch, eventId, reqCtxRef, onSuccess, onError]);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  return {
    attachments,
    isFetched: reqCtxRef.current.fetchTime && !reqCtxRef.current.isFetching,
    isFetching: !reqCtxRef.current.fetchTime || reqCtxRef.current.isFetching
  };
};
