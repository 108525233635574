import { API_PATH } from 'config';
import { t_error } from 'i18nextConfig';
import request from 'superagent';
import { Actions } from 'containers/home/Camera/Components/EventDetails/hooks/useEventDetailsReducer';

/**
 * Fetches company-level or global-level available event tags.
 */
export const fetchCameraEventTagsList = ({ companyId, onSuccess, onError }) => (
  dispatch,
  getState
) => {
  return request('GET', companyId ? `${API_PATH}/issue/tag/${companyId}` : `${API_PATH}/issue/tag`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok) {
        if (onSuccess) {
          onSuccess(resp.body);
        }
        return { success: true, result: resp.body };
      } else {
        const errMsg =
          t_error(resp.body) ||
          t_error(resp.error)?.message ||
          t_error(resp.error) ||
          resp.statusText;
        if (onError) {
          onError(errMsg);
          return { success: false, result: [], error: errMsg };
        }
      }
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return { success: false, result: [], error: errMsg };
    });
};

/**
 * Get tags, severity for a given event.
 */

export const fetchCameraEventTags = ({
  eventId,
  eventTimeAt,
  eventType = 'CAMERA',
  onSuccess,
  onError
}) => (dispatch, getState) =>
  request('GET', `${API_PATH}/issue`)
    .query(`eventId=${eventId}`)
    .query(`eventTimeAt=${eventTimeAt}`)
    .query(`eventType=${eventType}`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok) {
        if (onSuccess) {
          onSuccess(resp.body);
        }
        return { success: true, result: resp.body };
      } else {
        const errMsg =
          t_error(resp.body) ||
          t_error(resp.error)?.message ||
          t_error(resp.error) ||
          resp.statusText;
        if (onError) {
          onError(errMsg);
        }
        return { success: false, result: [], error: errMsg };
      }
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return { success: false, result: [], error: errMsg };
    });

/**
 * Updates the tags, severity, or both for a specified event.
 *
 * - If `tags` is an empty array or not provided, the tags will be removed.
 * - If `severity` is `null` or not provided, the severity will be removed.
 *
 */

export const updateEventTags = ({
  eventId,
  eventTimeAt,
  eventType = 'CAMERA',
  tags,
  severity,
  onSuccess,
  onError
}) => (dispatch, getState) =>
  request('PUT', `${API_PATH}/issue/event/tag`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .send({ eventId, eventTimeAt, eventType, tags, severity })
    .ok(resp => {
      if (resp.ok) {
        if (onSuccess) {
          onSuccess(resp.body);
        }
        return { success: true, result: resp.body };
      } else {
        const errMsg =
          t_error(resp.body) ||
          t_error(resp.error)?.message ||
          t_error(resp.error) ||
          resp.statusText;
        if (onError) {
          onError(errMsg);
        }
        return { success: false, result: [], error: errMsg };
      }
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return { success: false, result: [], error: errMsg };
    });

export const fetchCameraEventComments = ({
  eventId,
  eventType = 'CAMERA',
  eventTimeAt,
  onSuccess,
  onError
}) => (dispatch, getState) =>
  request('GET', `${API_PATH}/issue/comment`)
    .query(`eventId=${eventId}`)
    .query(`eventTimeAt=${eventTimeAt}`)
    .query(`eventType=${eventType}`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok) {
        if (onSuccess) {
          onSuccess(resp.body);
        }
        return { success: true, result: resp.body };
      } else {
        const errMsg =
          t_error(resp.body) ||
          t_error(resp.error)?.message ||
          t_error(resp.error) ||
          resp.statusText;
        if (onError) {
          onError(errMsg);
        }
        return { success: false, result: [], error: errMsg };
      }
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return { success: false, result: [], error: errMsg };
    });

export const addCameraEventComment = ({
  eventId,
  comment,
  eventType = 'CAMERA',
  eventTimeAt,
  onSuccess,
  onError
}) => async (dispatch, getState) => {
  try {
    const response = await request
      .post(`${API_PATH}/issue/comment`)
      .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
      .set('Content-Type', 'application/json')
      .send({
        eventId,
        comment,
        eventType,
        eventTimeAt
      });

    // Success handling
    if (response.ok) {
      if (onSuccess) onSuccess(response.body);
      dispatch({
        type: Actions.Comment.Add,
        payload: comment
      });
    } else {
      const errMsg = response.body?.error || 'Unexpected error occurred.';
      console.error(errMsg);
      if (onError) onError(errMsg);
    }
  } catch (error) {
    // Error handling
    const errMsg = error.response?.body?.error || error.message;
    console.error(errMsg);
    if (onError) onError(errMsg);
  }
};

export const updateCameraEventComment = ({
  eventId,
  eventType = 'CAMERA',
  eventTimeAt,
  commentId,
  comment,
  onSuccess,
  onError
}) => async (dispatch, getState) => {
  try {
    const response = await request
      .put(`${API_PATH}/issue/comment/${commentId}`)
      .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
      .set('Content-Type', 'application/json')
      .send({ eventId, eventType, eventTimeAt, comment });

    if (response.ok) {
      if (onSuccess) onSuccess(response.body);
      dispatch({
        type: Actions.Comment.Update,
        payload: { commentId, updatedComment: comment }
      });
      return { success: true, result: response.body };
    }

    const errMsg =
      t_error(response.body) ||
      t_error(response.error)?.message ||
      t_error(response.error) ||
      response.statusText;
    console.error(errMsg);
    if (onError) onError(errMsg);
    return { success: false, error: errMsg };
  } catch (error) {
    const errMsg = error.response?.body?.error || error.message;
    console.error(errMsg);
    if (onError) onError(errMsg);
    return { success: false, error: errMsg };
  }
};

export const deleteCameraEventComment = ({
  eventId,
  eventType = 'CAMERA',
  eventTimeAt,
  commentId,
  onSuccess,
  onError
}) => async (dispatch, getState) => {
  try {
    const response = await request
      .delete(`${API_PATH}/issue/comment/${commentId}`)
      .query(`eventId=${eventId}`)
      .query(`eventTimeAt=${eventTimeAt}`)
      .query(`eventType=${eventType}`)
      .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
      .set('Content-Type', 'application/json');

    if (response.ok) {
      if (onSuccess) onSuccess(response.body);
      dispatch({
        type: Actions.Comment.Delete,
        payload: { commentId }
      });
      return { success: true, result: response.body };
    }

    const errMsg =
      t_error(response.body) ||
      t_error(response.error)?.message ||
      t_error(response.error) ||
      response.statusText;

    if (onError) onError(errMsg);
    return { success: false, error: errMsg };
  } catch (error) {
    const errMsg = error.response?.body?.error || error.message;
    console.error(errMsg);
    if (onError) onError(errMsg);
    return { success: false, error: errMsg };
  }
};

export const fetchCameraEventAudits = ({ eventIssueId, onSuccess, onError }) => (
  dispatch,
  getState
) =>
  request('GET', `${API_PATH}/audit/issue/${eventIssueId}`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok) {
        if (onSuccess) {
          onSuccess(resp.body);
        }
        return { success: true, result: resp.body };
      } else {
        const errMsg =
          t_error(resp.body) ||
          t_error(resp.error)?.message ||
          t_error(resp.error) ||
          resp.statusText;
        if (onError) {
          onError(errMsg);
        }
        return { success: false, result: [], error: errMsg };
      }
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return { success: false, result: [], error: errMsg };
    });

export const fetchCameraEventAttachments = ({ eventId, onSuccess, onError }) => (
  dispatch,
  getState
) => {
  return new Promise(resolve => {
    const attachments = [
      {
        id: 1,
        name: 'file.docx',
        url: 'https://s3.signed.url',
        contentLength: 17799,
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        created_at: '2024-11-08T10:23:47.439+11:00',
        updated_at: '2024-11-08T10:23:47.439+11:00'
      },
      {
        id: 1,
        name: 'file.pdf',
        url: 'https://s3.signed.url',
        contentLength: 17799,
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        created_at: '2024-11-09T10:23:47.439+11:00',
        updated_at: '2024-11-09T10:23:47.439+11:00'
      }
    ];
    if (onSuccess) {
      onSuccess(attachments);
    }
    resolve(attachments);
  });
};

export const addCameraEventAttachment = ({ eventId, attachment, onSuccess, onError }) => (
  dispatch,
  getState
) => {
  return new Promise(resolve => {
    if (onSuccess) {
      onSuccess({ id: 'fakeId', ...attachment });
    }
    resolve({ id: 'fakeId', ...attachment });
  });
};

export const deleteCameraEventAttachment = ({ eventId, attachmentId, onSuccess, onError }) => (
  dispatch,
  getState
) => {
  return new Promise(resolve => {
    if (onSuccess) {
      onSuccess({ id: attachmentId });
    }
    resolve({ id: attachmentId });
  });
};
