import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUserKey } from 'features/user/userSlice';
import { useCanEveryEntity, entities } from 'features/permissions';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import {
  useAttachmentContentById,
  fetchAttachmentContent
} from 'features/attachments/attachmentSlice';
import {
  useCurrentCompany,
  CompanyConfigValue,
  CompanyConfigKey,
  useSubCompanyEntityConfig
} from 'features/company/companySlice';
import hideNonBusinessSVG from 'static/images/icons/incognito-circle.svg';
import styles from '../Snapshots.module.scss';
import { AttachmentStatus } from 'containers/home/Camera/constant';

const NonBusinessImage = ({ description }) => (
  <div className={`${styles.footage_no_business_trip}`}>
    <i style={{ width: '25px', content: `url(${hideNonBusinessSVG})` }} />
    <span>{description}</span>
  </div>
);

const NoFootageAtPosition = ({ t, camPosition, isPositionFailure }) => (
  <WithFootageBadge t={t} camPosition={camPosition}>
    <div
      className={isPositionFailure ? styles.footage_device_failure : styles.footage_not_available}
    >
      {t(`Home.${isPositionFailure ? 'DeviceFailure' : 'Snapshot not available'}`)}
    </div>
  </WithFootageBadge>
);

const NoAvaliableAttachment = ({ t, description, camPosition, formatedFootageDate, footageId }) => (
  <WithFootageBadge
    t={t}
    camPosition={camPosition}
    formatedFootageDate={formatedFootageDate}
    footageId={footageId}
  >
    <div className={styles.footage_not_available}>
      <div className={styles.unavailable_info}>{description}</div>
    </div>
  </WithFootageBadge>
);

const AttachmentLoadFailed = ({ description }) => (
  <div className={styles.footage_not_available}>
    <div className={styles.unavailable_info}>{description}</div>
  </div>
);

const LoadingImage = ({ description }) => <div className={styles.footage_desc}>{description}</div>;

function _FootageMedia({ footageMediaId, mimeType, year, month }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userKey = useUserKey();

  const attachmentContent = useAttachmentContentById(
    footageMediaId,
    mimeType,
    userKey,
    year,
    month
  );
  const canDownload = useCanEveryEntity([entities.VIDEO_VIEW]);

  const isVideo = useMemo(() => mimeType?.indexOf('video') >= 0, [mimeType]);
  const { isLoadingImage, isImageLoadFailed } = useMemo(
    () => ({
      isLoadingImage: !isVideo && !attachmentContent?.url,
      isImageLoadFailed: !isVideo && attachmentContent?.status?.error && !attachmentContent?.url
    }),
    [isVideo, attachmentContent]
  );

  const handleRightClick = useCallback(
    evt => {
      if (isVideo) {
        evt.stopPropagation();
        evt.preventDefault();
      } else if (evt.button === 2 && !canDownload) {
        evt.stopPropagation();
        evt.preventDefault();
      }
      return;
    },
    [isVideo, canDownload]
  );

  const handleVideoPlay = useCallback(() => {
    if (!attachmentContent?.url) {
      dispatch(fetchAttachmentContent(footageMediaId, userKey, false, year, month));
    }
  }, [footageMediaId, userKey, attachmentContent, dispatch, year, month]);
  if (isImageLoadFailed) {
    return <AttachmentLoadFailed description={t('Common.AttachmentStatus.FAILED')} />;
  } else if (isLoadingImage) {
    return <LoadingImage description={`${t('Home.Loading')} ${t(`Entity.Image`)}`} />;
  }
  return (
    <div className={styles.footage}>
      {isVideo ? (
        <video
          onContextMenu={handleRightClick}
          onPlay={handleVideoPlay}
          poster={attachmentContent?.thumb_url || ''}
          preload="none"
          controls
          muted
          controlsList="nodownload"
          disablePictureInPicture={true}
        >
          <source
            src={!!attachmentContent?.url ? attachmentContent.url : 'dummy.mp4'}
            type={mimeType}
          />
        </video>
      ) : (
        <img src={attachmentContent.url} alt="Snapshot" onContextMenu={handleRightClick} />
      )}
    </div>
  );
}

const FootageMedia = React.memo(_FootageMedia);

function _Footage({ t, year, month, camPosition, footage, isPositionFailure = false }) {
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const nonBusinessCompanyConfig = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );

  const isPrivate = useMemo(
    () => nonBusinessCompanyConfig && footage?.attr === CompanyConfigValue.Private,
    [nonBusinessCompanyConfig, footage?.attr]
  );

  const formatedFootageDate = useMemo(
    () =>
      footage?.timeAt ? format(footage?.timeAt, localization.formats.time.formats.dmY_imp) : '',
    [footage?.timeAt, localization]
  );

  if (!footage?.timeAt) {
    return (
      <NoFootageAtPosition t={t} camPosition={camPosition} isPositionFailure={isPositionFailure} />
    );
  } else if (
    !(
      footage.attachmentId &&
      [AttachmentStatus.ENABLED, AttachmentStatus.AVAILABLE].includes(footage.attachmentStatus)
    )
  ) {
    return (
      <NoAvaliableAttachment
        t={t}
        camPosition={camPosition}
        formatedFootageDate={formatedFootageDate}
        footageId={footage.id}
        description={t(
          `Common.AttachmentStatus.${footage.attachmentStatus || AttachmentStatus.UNAVAILABLE}`
        )}
      />
    );
  } else if (isPrivate) {
    return <NonBusinessImage description={t('CompanyConfig.HideNonBusiness.NonBusinessTrip')} />;
  }
  return (
    <WithFootageBadge
      t={t}
      camPosition={camPosition}
      formatedFootageDate={formatedFootageDate}
      footageId={footage.id}
    >
      <FootageMedia
        camPosition={camPosition}
        formatedFootageDate={formatedFootageDate}
        footageMediaId={footage.attachmentId}
        mimeType={footage.mimeType}
        year={year}
        month={month}
      />
    </WithFootageBadge>
  );
}

const WithFootageBadge = ({ t, children, camPosition, formatedFootageDate, footageId }) => (
  <div className={styles.positionedFootage}>
    <div className={styles.footageTags}>
      {[
        camPosition ? t(`Common.CameraChannels.${camPosition}`, camPosition) : null,
        formatedFootageDate
      ].map((tagText, tagIndex) =>
        tagText ? (
          <div key={`${footageId}-${tagIndex}`} className={styles.footageTag}>
            {tagText}
          </div>
        ) : null
      )}
    </div>
    {children}
  </div>
);

export const Footage = React.memo(_Footage);
