import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Spin, Tooltip, Button } from 'antd';
import { List as VList, CellMeasurer, CellMeasurerCache, AutoSizer } from 'react-virtualized';

import { WarningFilled } from '@ant-design/icons';
import moment from 'moment';

import { useLocalization } from 'features/localization/localizationSlice';
import {
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue
} from 'features/company/companySlice';
import { useCan, services, FeatureFlag } from 'features/permissions';

import { getEventAttributesByType, EventTypes } from 'containers/Tracking/EventTypes';

import { format } from 'utils/dates';
import { formatTimeDiff } from 'utils/methods';

import eventStyles from './TrackEvents.module.scss';

import svgHideNonBusiness from 'static/images/icons/incognito-circle.svg';
import imgCurrentTrip from 'components/map/markers/images/trip-in-progress.png';

const viewDetailsClassName = eventStyles.viewDetails;
const LOAD_MORE_COUNT = 10;

const EventListItem = ({
  device,
  event,
  style,
  isOutOfTripEvent,
  hasIridiumService,
  isExpanded,
  selectedEvent,
  tripSummaryEvents,
  trip,
  nextTrip,
  localization,
  t,
  isFirstTrip,
  isLastTrip,
  onDateRangeSelected,
  onEventClicked,
  onDeviceClicked,
  hideNonBusinessTrips,
  groupByTripsEnabled,
  numExtraEvents
}) => {
  const onRowClick = (e, isCurrentTripEvent) => {
    if (isExpanded) {
      if (e.target.className === viewDetailsClassName) {
        onViewDetailsClick(e);
      } else {
        if (isCurrentTripEvent) {
          onEventClicked(null);
        } else onEventClicked(event);
      }
    }
  };

  const onViewDetailsClick = e => {
    window.open(
      eventAttributes.url
        ?.replace(':id:', event?.id)
        ?.replace(':timeAt:', moment(event?.timeAt).unix()),
      '_blank'
    );
  };

  const onShowWholeTripClick = trip => {
    let from = moment(trip.expandDateRange.from);
    let to = moment(trip.expandDateRange.to);

    // limit date range we can expand to
    if (to.diff(from, 'days') >= 7) {
      console.log('date range is more than 7 days');
      to = moment(from)
        .add(6, 'days')
        .endOf('day');
    }

    onDateRangeSelected(from, to, true);
  };

  const eventAttributes = getEventAttributesByType(
    event?.eventType,
    event?.subType,
    event?.customSubType
  );

  const dateLabel = event?.timeAt
    ? format(new Date(event?.timeAt), localization.formats.time.formats.dby_imp)
    : '';

  let tripEndEvent =
    tripSummaryEvents?.length > 1 ? tripSummaryEvents[tripSummaryEvents.length - 1] : null;
  let tripStartEvent = tripSummaryEvents?.length > 1 ? tripSummaryEvents[0] : null;

  // Special case for trips with only one ignition on/off event (partial trips)
  let isSingleEventTrip = false;
  if (tripSummaryEvents?.length === 1) {
    if (tripSummaryEvents[0].eventType + tripSummaryEvents[0].subType === EventTypes.IgnitionOn.key)
      tripStartEvent = tripSummaryEvents[0];
    else tripEndEvent = tripSummaryEvents[0];

    isSingleEventTrip = true;
  }

  // For optional communication channel label that only shows is Iridium feature is enabled
  let comChannelLabel = null;
  let comChannelClass = null;
  if (hasIridiumService) {
    if (event?.origin?.includes('satellite')) {
      comChannelLabel = t('Tracking.Satellite');
      comChannelClass = eventStyles.trackEventsListSatellite;
    } else if (event?.origin?.includes('hermes')) {
      comChannelLabel = t('Tracking.Cellular');
      comChannelClass = eventStyles.trackEventsListCellular;
    } else {
      // Else, if not from satellite or device, show event came from the server
      comChannelLabel = t('Tracking.Server');
      comChannelClass = eventStyles.trackEventsListServer;
    }
  }

  const isSelected = event.id === selectedEvent?.id;
  const isTripStartEvent =
    event.id === tripStartEvent?.id ||
    event.eventType + event.subType === EventTypes.IgnitionOn.key;
  const isTripEndEvent =
    event.id === tripEndEvent?.id || event.eventType + event.subType === EventTypes.IgnitionOff.key;
  const isNonBusinessTrip = hideNonBusinessTrips && trip?.attr === CompanyConfigValue.Private;
  const isNonBusinessTripEvent = isNonBusinessTrip && (isTripStartEvent || isTripEndEvent);
  const isNormalTripEvent = event.eventType !== 'IOR';
  const isCurrentTripEvent = event.isCurrentTripEvent || false;

  let timeUntilNextTrip = null;
  if (isTripStartEvent && !isOutOfTripEvent) {
    if (trip?.ignitionOn && nextTrip?.ignitionOff) {
      timeUntilNextTrip = formatTimeDiff(
        moment.unix(trip.ignitionOn / 1000) - moment.unix(nextTrip.ignitionOff / 1000),
        t
      );
    }
  }

  const tripEndCircleStyle = isCurrentTripEvent
    ? eventStyles.filledGreenCircle
    : eventStyles.filledGrayCircle;

  let eventListItemClass = eventStyles.eventListItemNoHover;
  if (isExpanded) {
    eventListItemClass = isSelected
      ? eventStyles.eventListItemSelected
      : isOutOfTripEvent
      ? eventStyles.outOfTripEventListItem
      : eventStyles.eventListItem;
  }

  const eventListItemBorderClass = groupByTripsEnabled
    ? eventStyles.eventListItemBorderGroupByTrips
    : eventStyles.eventListItemBorderNonGroupByTrips;

  const verticalLineClass =
    hasIridiumService && comChannelLabel ? eventStyles.verticleLineLarge : eventStyles.verticleLine;

  const verticalLineTopClass =
    hasIridiumService && comChannelLabel
      ? eventStyles.verticleLineTopLarge
      : eventStyles.verticleLineTop;

  const verticleLineBottomClass =
    hasIridiumService && comChannelLabel
      ? eventStyles.verticleLineBottomLarge
      : eventStyles.verticleLineBottom;

  return (
    <div style={style}>
      {(isTripEndEvent || (isTripStartEvent && isSingleEventTrip)) &&
        !isOutOfTripEvent &&
        isFirstTrip &&
        trip.isPartialTrip &&
        !trip.ignitionOff && (
          <div className={eventStyles.moreEventsNextDay}>
            <WarningFilled className={eventStyles.warningIcon} />
            <span>{t('Tracking.MoreEventsOnLaterDays')} </span>
            <Tooltip title={t('Tracking.ShowTheWholeTripMessage')}>
              <span className={eventStyles.showWholeTrip} onClick={e => onShowWholeTripClick(trip)}>
                {t('Tracking.ShowTheWholeTrip')}
              </span>
            </Tooltip>
          </div>
        )}
      <div
        className={`${eventListItemClass} ${eventListItemBorderClass}`}
        onClick={!isNonBusinessTrip ? e => onRowClick(e, isCurrentTripEvent) : null}
      >
        {!isExpanded &&
          (isTripStartEvent || (isTripEndEvent && isSingleEventTrip)) &&
          !isOutOfTripEvent &&
          !isNonBusinessTripEvent && (
            <div className={eventStyles.moreEventsLabel}>
              {`+ ${numExtraEvents} ${t('Tracking.MoreEvents')}`}
            </div>
          )}
        <Row align="middle" className={eventStyles.eventListItemRow}>
          <Col span={6}>
            <span className={eventStyles.dateText}>{dateLabel}</span>
          </Col>
          <Col span={1}>
            {isNormalTripEvent ? (
              <>
                <div className={eventStyles.horizontalLine} />
                <div className={verticalLineClass} />
              </>
            ) : isTripStartEvent ? (
              <>
                <div className={eventStyles.horizontalLineShorter} />
                <div className={eventStyles.hollowGrayCircle} />
                <div className={verticalLineTopClass} />
              </>
            ) : (
              <>
                <div className={eventStyles.horizontalLineShorter} />
                <div className={tripEndCircleStyle} />
                <div className={verticleLineBottomClass} />
              </>
            )}
          </Col>
          <Col span={2}>
            {isNonBusinessTripEvent ? (
              <img
                className={eventStyles.eventTypeImage}
                src={svgHideNonBusiness}
                alt={t('CompanyConfig.HideNonBusiness.NonBusinessTrip')}
              />
            ) : isCurrentTripEvent ? (
              <img
                className={eventStyles.eventTypeImage}
                src={imgCurrentTrip}
                alt={t('Tracking.CurrentTrip')}
              />
            ) : (
              <img
                className={eventStyles.eventTypeImage}
                src={eventAttributes?.markerSvg}
                alt={t(`Tracking.Events.${eventAttributes?.label}`, {
                  defaultValue: eventAttributes?.label
                })}
              />
            )}
          </Col>
          <Col span={11}>
            <div>
              <div className={eventStyles.trackEventsListLabel}>
                {isNonBusinessTripEvent
                  ? ''
                  : isCurrentTripEvent
                  ? t('Tracking.CurrentTrip')
                  : t(`Tracking.Events.${eventAttributes?.label}`, {
                      defaultValue: eventAttributes?.label
                    })}
              </div>
            </div>
            <div className={eventStyles.trackEventsListLocation}>
              {isNonBusinessTripEvent
                ? t('CompanyConfig.HideNonBusiness.NonBusinessTrip')
                : event?.location}
            </div>
            {hasIridiumService && comChannelLabel && !event.isCurrentTripEvent && (
              <div className={comChannelClass}>{comChannelLabel}</div>
            )}
          </Col>
          <Col span={4}>
            {eventAttributes?.url && !isTripEndEvent && (
              <div className={eventStyles.viewDetails} onClick={() => onViewDetailsClick}>
                {t('Tracking.ViewDetails')}
              </div>
            )}
            {isTripEndEvent && groupByTripsEnabled && (
              <>
                <div className={eventStyles.tripDistanceDisplayValue}>
                  {trip.tripDistanceDisplayValue}
                </div>
                <div className={eventStyles.durationDisplayValue}>{trip.durationDisplayValue}</div>
              </>
            )}
          </Col>
        </Row>
        {(isTripStartEvent || (isTripEndEvent && isSingleEventTrip)) &&
          !isOutOfTripEvent &&
          isLastTrip &&
          trip.isPartialTrip &&
          !trip.ignitionOn && (
            <div className={eventStyles.moreEventsPrevDay}>
              <WarningFilled className={eventStyles.warningIcon} />
              <span>{t('Tracking.MoreEventsOnPreviousDays')} </span>
              <Tooltip title={t('Tracking.ShowTheWholeTripMessage')}>
                <span
                  className={eventStyles.showWholeTrip}
                  onClick={e => onShowWholeTripClick(trip)}
                >
                  {t('Tracking.ShowTheWholeTrip')}
                </span>
              </Tooltip>
            </div>
          )}
        {timeUntilNextTrip && isTripStartEvent && !isOutOfTripEvent && (
          <div className={eventStyles.timeUntilNextTripDisplay} onClick={e => e.stopPropagation()}>
            {timeUntilNextTrip}
          </div>
        )}
      </div>
    </div>
  );
};

export const TrackEventsList = ({
  device,
  allEvents,
  tripSummaryEvents,
  trip,
  nextTrip,
  groupByTripsEnabled,
  isOutOfTripEvents,
  isLoading,
  isExpanded,
  selectedEvent,
  isFirstTrip = false,
  isLastTrip = false,
  onDateRangeSelected,
  onEventClicked,
  onDeviceClicked
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const hideNonBusinessTrips = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const can = useCan();
  const canRemoveFakeIgnition = can({
    oneOfFeatureFlags: [FeatureFlag.removeFakeIgnition.flag]
  });
  const canUseLoadMore = can({ featureFlag: FeatureFlag.trackingLoadMore.flag });

  const [visibleCount, setVisibleCount] = useState(LOAD_MORE_COUNT);

  const modifiedTripSummaryEvents = canRemoveFakeIgnition
    ? (tripSummaryEvents || []).filter(
        event =>
          event && {
            ...event,
            key: `eventKey-${event?.id || event?.tripId || event?.createdAt}`
          }
      )
    : tripSummaryEvents;

  const modifiedAllEvents = canRemoveFakeIgnition
    ? (allEvents || []).filter(
        event =>
          event && {
            ...event,
            key: `eventKey-${event?.id || event?.tripId || event?.createdAt}`
          }
      )
    : allEvents;

  const hasIridiumService = device.services?.includes(services.IRIDIUM);

  const reversedEvents = allEvents ? [...modifiedAllEvents].reverse() : [];

  const reversedTripSummaryEvents = tripSummaryEvents
    ? [...modifiedTripSummaryEvents].reverse()
    : [];

  const eventsForList = isExpanded ? reversedEvents : reversedTripSummaryEvents;

  const numExtraEvents =
    !isExpanded && reversedEvents.length > reversedTripSummaryEvents.length
      ? reversedEvents.length - reversedTripSummaryEvents.length
      : 0;

  const sortedEventsForList = eventsForList
    .filter(event => event.timeAt)
    .sort((a, b) => b.timeAt - a.timeAt);

  const visibleEvents = canUseLoadMore
    ? sortedEventsForList.slice(0, visibleCount)
    : sortedEventsForList;

  const handleLoadMore = () => {
    setVisibleCount(prevCount => Math.min(prevCount + LOAD_MORE_COUNT, sortedEventsForList.length));
  };

  const hasMoreData = sortedEventsForList.length > visibleEvents.length;

  const cacheRef = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 70
    })
  );
  const cellMeasureCache = cacheRef.current;
  const listRef = useRef(null);
  const prevItemCount = useRef(0);

  useLayoutEffect(() => {
    const itemCountDifference = visibleEvents.length - prevItemCount.current;
    if (itemCountDifference !== 0) {
      const startIndex = prevItemCount.current;
      cellMeasureCache.clearAll();
      if (listRef.current) {
        listRef.current.recomputeRowHeights(startIndex);
      }
    }
    prevItemCount.current = visibleEvents.length;
  }, [visibleEvents]);

  return isLoading ? (
    <Spin className={eventStyles.eventListLoading} />
  ) : sortedEventsForList?.length > 0 ? (
    <div>
      <AutoSizer disableHeight>
        {({ width }) => {
          let totalHeight = 0;
          const length = visibleEvents.length + (hasMoreData ? 1 : 0);
          for (let i = 0; i < length; i++) {
            totalHeight += cellMeasureCache.getHeight(i) || 70;
          }
          const listHeight = Math.min(totalHeight, 400);
          return (
            <VList
              ref={listRef}
              className={eventStyles.eventList}
              height={listHeight}
              width={width}
              rowCount={visibleEvents.length + (hasMoreData ? 1 : 0)}
              overscanRowCount={5}
              deferredMeasurementCache={cellMeasureCache}
              rowHeight={cellMeasureCache?.rowHeight}
              estimatedRowSize={70}
              rowRenderer={({ index, parent, key, style }) => {
                const item = sortedEventsForList[index];
                return (
                  <CellMeasurer
                    key={key}
                    cache={cellMeasureCache}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    {index === visibleEvents.length && hasMoreData ? (
                      <div key={key} style={style} className={eventStyles.loadMoreButtonContainer}>
                        <Button type="link" onClick={handleLoadMore}>
                          {t('Common.Load More')}
                        </Button>
                      </div>
                    ) : (
                      <EventListItem
                        style={style}
                        key={key}
                        device={device}
                        event={item}
                        isOutOfTripEvent={isOutOfTripEvents}
                        hasIridiumService={hasIridiumService}
                        isExpanded={isExpanded}
                        selectedEvent={selectedEvent}
                        tripSummaryEvents={tripSummaryEvents}
                        trip={trip}
                        nextTrip={nextTrip}
                        localization={localization}
                        t={t}
                        isFirstTrip={isFirstTrip}
                        isLastTrip={isLastTrip}
                        onDateRangeSelected={onDateRangeSelected}
                        onEventClicked={onEventClicked}
                        onDeviceClicked={onDeviceClicked}
                        hideNonBusinessTrips={hideNonBusinessTrips}
                        groupByTripsEnabled={groupByTripsEnabled}
                        numExtraEvents={numExtraEvents}
                      />
                    )}
                  </CellMeasurer>
                );
              }}
            />
          );
        }}
      </AutoSizer>
    </div>
  ) : (
    <div className={eventStyles.eventListNoDataMessage}>
      {groupByTripsEnabled ? t('Tracking.NoTrips') : t('Scorecard.NoEvents')}
    </div>
  );
};
