import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Button, Input } from 'antd';
import ReactQuill from 'react-quill';
import TurndownService from 'turndown';
import { marked } from 'marked';
import { debounce } from 'lodash';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import styles from './CommentField.module.scss';

export const CommentField = ({
  onSave,
  onCancel,
  loading,
  eventId,
  userId,
  defaultValue = '',
  isExpanded,
  charLimit = 5000
}) => {
  const [value, setValue] = useState(marked(defaultValue));
  const [expanded, setIsExpanded] = useState(isExpanded);

  const { t } = useTranslation();

  const quillRef = useRef(null);
  const turndownService = new TurndownService();

  const saveDraftToLocalStorage = useCallback(
    debounce(newValue => {
      if (eventId && userId) {
        localStorage.setItem(`comment_draft_${eventId}_${userId}`, newValue);
      }
    }, 300),
    [eventId, userId]
  );

  useEffect(() => {
    if (eventId && userId) {
      const savedDraft = localStorage.getItem(`comment_draft_${eventId}_${userId}`);
      if (savedDraft) {
        setValue(savedDraft);
      }
    }
  }, [eventId, userId]);

  const handleCancel = () => {
    setIsExpanded(false);
    setValue('');
    onCancel();
    if (eventId && userId) {
      localStorage.removeItem(`comment_draft_${eventId}_${userId}`);
    }
  };

  const handleSave = () => {
    const markdown = turndownService.turndown(value);
    if (onSave) onSave(markdown);
    setValue('');
    setIsExpanded(false);
    if (eventId && userId) {
      localStorage.removeItem(`comment_draft_${eventId}_${userId}`);
    }
  };

  const handleInputChange = newValue => {
    setValue(newValue);
    saveDraftToLocalStorage(newValue); // Save draft to localStorage
  };

  const handleInputClick = () => {
    setIsExpanded(true);
    setTimeout(() => {
      if (quillRef.current) {
        quillRef.current.focus(); // Focus on the ReactQuill editor
      }
    }, 0); // Use a slight delay to ensure the editor is rendered before focusing
  };

  const getCharCount = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      return editor.getLength() - 1; // Subtract 1 to exclude the trailing newline character
    }
    return 0;
  };

  const charCount = getCharCount();

  const isSaveDisabled = useMemo(() => {
    const markdown = turndownService.turndown(value);
    return markdown === defaultValue || charCount === 0 || charCount > charLimit;
  }, [value, defaultValue, charCount, charLimit]);

  return (
    <div>
      {!expanded ? (
        <Input
          placeholder={
            value
              ? t('Event.Comment.Placeholder.HaveUnsavedComment')
              : t('Event.Comment.Placeholder.AddAComment')
          }
          onClick={handleInputClick}
        />
      ) : (
        <>
          <div id="richtext" className={styles.richtext}>
            <ReactQuill
              ref={quillRef}
              value={value}
              onChange={handleInputChange}
              placeholder={t('Event.Comment.Placeholder.WriteDownYourComment')}
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'], // Formatting options
                  [{ header: [1, 2, false] }], // Headings
                  [{ list: 'ordered' }, { list: 'bullet' }], // Lists
                  ['link'], // Links and images (add image if want to allow adding image here)
                  ['clean'] // Clear formatting
                ]
              }}
              formats={[
                'header',
                'bold',
                'italic',
                'underline',
                'strike',
                'list',
                'bullet',
                'link',
                'image'
              ]}
              style={{ marginBottom: 10, height: '250px', maxHeight: '250px' }}
              bounds={`#richtext`}
            />
          </div>
          <div className={styles.action}>
            <div style={{ color: charCount > charLimit ? 'red' : 'black' }}>
              {charCount}/{charLimit} characters
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px'
              }}
            >
              <Button
                type="primary"
                onClick={handleSave}
                loading={loading}
                disabled={isSaveDisabled}
              >
                Save
              </Button>
              <Button type="default" onClick={handleCancel} loading={loading}>
                Cancel
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
