import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

//components
import { Spin } from 'antd';
import { Buttons } from 'components/ant';
import { UsersTable } from './UsersTable';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import { exportFile } from 'components/excelFile';
import { CSVLink } from 'react-csv';

//slices & hooks
import {
  useUsers,
  useDeletedUsers,
  deleteUserApi,
  restoreUserApi,
  fetchUsers,
  useIsFetching as useIsUsersFetching,
  useIsUserEmbedsLoaded
} from 'features/users/usersSlice';
import { useUserKey, logInAsUser } from 'features/user/userSlice';
import { useBranches } from 'features/locations/locationsSlice';
import {
  Can,
  useCan,
  services,
  useCanEveryService,
  useCanOneOfCompanyServices,
  useIQCameraUser,
  FeatureFlag,
  useCanOneOfRoles,
  GlobalRoles
} from 'features/permissions';
import { useLocalization } from 'features/localization/localizationSlice';
import { fetchPermissions } from 'features/permissions/permissionsSlice';
import {
  useCompanies,
  useSubCompanies,
  fetchCompanies,
  useCurrentCompany
} from 'features/company/companySlice';
import { useGetTimezonesQuery } from 'services/nextgen';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  fetchTachoCardByCompanyId,
  fetchFatigueStatusByCompanyId,
  useTachoInfo
} from 'features/tacho/tachoSlice';

//methods & helpers
import { toLower, trim } from 'lodash';
import { getCSVFilename } from '../Locations/helpers';
import {
  prepareFileForExcelExport,
  prepareDataForCsvExport,
  buildFileName,
  getPrimaryButton,
  getMoreButtons
} from './helpers';
import { prepareDataForMultiselect } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';
import { useBulkEdit } from 'features/bulkEdit/BulkEditProvider';

//constants
import { PATHS as COMPANYCONFIG_PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import { BULK_TYPES } from 'containers/Administration/BulkManagement/components/Modal/constants';
import { TABS, columnWidth, BRANCH_TYPE, loadEmbeds, PATHS } from './constants';
import { cache } from './CellRenderers';

//styles
import styles from './Users.module.scss';

export const Users = ({ setToastMessage }) => {
  const { t } = useTranslation();
  const can = useCan();
  const { canAccessNonCameraFeatures } = useIQCameraUser() || {};
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const allUsers = useUsers();
  const branches = useBranches();
  const userKey = useUserKey();
  const companies = useCompanies();
  const company = useCurrentCompany();
  const subCompanies = useSubCompanies();
  const { data: newTimezones } = useGetTimezonesQuery();
  const isUserTachoEnabled = useCanEveryService(services.TACHO);
  const isCompanyTachoEnabled = useCanOneOfCompanyServices(services.TACHO);
  const isTachoEnabled = useMemo(() => isUserTachoEnabled && isCompanyTachoEnabled, [
    isUserTachoEnabled,
    isCompanyTachoEnabled
  ]);
  const tachoData = useTachoInfo();
  const localization = useLocalization();
  const [users, setUsers] = useState(allUsers);
  const [filterTab, setFilterTab] = useState(filterPath);
  const deletedUsers = useDeletedUsers(filterTab === TABS.deleted);
  const [filterText, setFilterText] = useState('');
  const [filterBranches, setFilterBranches] = useState([]);
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [tableRef, setTableRef] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const embedsLoaded = useIsUserEmbedsLoaded();
  const isUsersFetching = useIsUsersFetching();
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef(null);
  const isExporting = isExportingExcel || isExportingCsv;

  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);
  const isBulkEditUserEnabled = can({ featureFlag: FeatureFlag.bulkEditVehicle.flag });
  const { isBulkEditMode, toggleBulkEditMode } = useBulkEdit();

  useEffect(() => {
    if (path === '/settings/users') {
      setFilterTab(TABS.all);
    }
  }, [path]);

  useEffect(() => {
    dispatch(setPageTitle(t('Users.Users')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    if (isTachoEnabled && userKey != null && subCompanies != null) {
      subCompanies.forEach(c => {
        setTimeout(() => {
          dispatch(fetchTachoCardByCompanyId(c.id, userKey));
          dispatch(fetchFatigueStatusByCompanyId(c.id, userKey));
        });
      });
    }
  }, [dispatch, userKey, subCompanies, isTachoEnabled]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
    setFilterText('');
  }, [subCompanies, t]);

  useEffect(() => {
    const usersToFilter = filterTab === TABS.deleted ? deletedUsers : allUsers;
    setUsers(
      usersToFilter?.filter(user => {
        let validUser = true;

        // Filter by user type
        if (filterTab === TABS.all) {
          validUser = true;
        }
        if (filterTab === TABS.users) {
          validUser = validUser && toLower(user?.type?.code) === 'user';
        }
        if (filterTab === TABS.sentinel) {
          validUser =
            validUser &&
            toLower(user?.type?.code) === 'driver' &&
            user.rulesets &&
            user.rulesets.length > 0;
        }
        if (filterTab === TABS.ewd) {
          validUser =
            validUser &&
            !!user.associations?.find(assoc => {
              return (
                (assoc.domain === 'EWD' && assoc.externalId?.includes?.('user_id')) ||
                assoc.externalId?.includes?.('driver_id')
              );
            });
        }

        // Filter by search field
        if (debouncedSearchText) {
          const name = user.firstName.concat(` ${user.lastName}`);
          validUser =
            validUser &&
            [name, user.username, user.email].some(
              value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1
            );
        }

        // Filter by companies
        const checkedCompaniesIds = filterCompanies
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          validUser = validUser && checkedCompaniesIds.indexOf(parseInt(user.companyId, 10)) > -1;
        }
        // Filter by branches
        const checkedBranchesIds = filterBranches
          .filter(branch => branch.checked)
          .map(branch => parseInt(branch.id, 10));
        if (!(checkedBranchesIds.indexOf(0) > -1)) {
          validUser =
            validUser &&
            checkedBranchesIds.indexOf(user.location ? parseInt(user.location.id, 10) : -1) > -1;
        }
        return validUser;
      })
    );
  }, [
    allUsers,
    deletedUsers,
    filterTab,
    filterText,
    filterCompanies,
    filterBranches,
    debouncedSearchText,
    isTachoEnabled,
    tachoData
  ]);

  // Update branches based on the company filter
  useEffect(() => {
    let checkedCompaniesIds = filterCompanies
      .filter(company => company.checked)
      .map(company => parseInt(company.id, 10));

    const branchOptions = branches.filter(branch => {
      let validBranch = true;
      if (branch.id !== BRANCH_TYPE.ALL_BRANCHES && branch.id !== BRANCH_TYPE.NO_BRANCH) {
        validBranch = checkedCompaniesIds.indexOf(parseInt(branch.companyId, 10)) > -1;
      }
      return validBranch;
    });

    setFilterBranches(prepareDataForMultiselect(branchOptions, t('Users.AllBranches'), null));
  }, [filterCompanies, branches, t]);

  useEffect(() => {
    const formattedBranches = prepareDataForMultiselect(branches, t('Users.AllBranches'), null);
    setFilterBranches(formattedBranches);
  }, [branches, t]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [users, tableRef]);

  const handleAction = actionObject => {
    if (actionObject.action === 'restore') {
      dispatch(restoreUserApi(actionObject.data));
    }
  };

  const handleDeleteAction = data => () => {
    dispatch(deleteUserApi(data));
  };

  const handleLogInAsUser = userData => {
    dispatch(logInAsUser(userData, fetchPermissions, fetchCompanies, history, can));
  };

  const handleExportExcel = async () => {
    setIsExportingExcel(true);
    if (!embedsLoaded) {
      await dispatch(fetchUsers({ forceFetch: true, embed: loadEmbeds }));
    }
  };

  const handleBulkImport = () =>
    history.push({
      pathname: COMPANYCONFIG_PATHS.BULK_MANAGEMENT,
      state: { showImportModal: true, defaultImportType: BULK_TYPES.USERS }
    });

  const handleExportCSV = async () => {
    setIsExportingCsv(true);
    if (!embedsLoaded) {
      await dispatch(fetchUsers({ forceFetch: true, embed: loadEmbeds }));
    }
  };

  const handleViewBulkAudit = () => {
    history.push(PATHS.USER_BULK_AUDITS);
  };

  useEffect(() => {
    csvData?.length && csvLinkRef?.current?.link?.click();
  }, [csvData]);

  useEffect(() => {
    // triggers the excel export
    // moved this into an useEffect in order to have the users updated with the embeds
    if (embedsLoaded) {
      if (isExportingExcel) {
        setIsExportingExcel(false);
        const data = prepareFileForExcelExport({
          filteredUsers: users,
          companies,
          locations: branches,
          dateFormat: localization.formats.time.formats.dby_imsp,
          newTimezones
        });

        dispatch(
          exportFile(data, {
            title: buildFileName(filterTab),
            width: columnWidth,
            dateFormat: localization.formats.time.formats.dby_imsp
          })
        );
      }
      if (isExportingCsv) {
        setIsExportingCsv(false);
        setCsvData(
          prepareDataForCsvExport({
            users,
            branches,
            newTimezones,
            companies,
            dateFormat: localization.formats.time.formats.dby_imsp
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, isExportingExcel, isExportingCsv]);

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink
              to="/settings/users/all"
              isActive={(match, location) => {
                return ['/', '/settings', '/settings/users', '/settings/users/all'].includes(
                  location.pathname
                );
              }}
              onClick={() => {
                setFilterTab(TABS.all);
              }}
            >
              {t('Users.All')}
            </TabNavLink>
            <TabNavLink
              exact
              to={`/settings/users/users`}
              onClick={() => {
                setFilterTab(TABS.users);
              }}
            >
              {t('Users.Users')}
            </TabNavLink>
            <TabNavLink
              exact
              to={`/settings/users/sentinel`}
              onClick={() => {
                setFilterTab(TABS.sentinel);
              }}
            >
              {t('Users.Sentinel')}
            </TabNavLink>
            <Can everyService={[services.EWD]}>
              <TabNavLink
                exact
                to={`/settings/users/ewd`}
                onClick={() => {
                  setFilterTab(TABS.ewd);
                }}
              >
                {t('Users.EWD')}
              </TabNavLink>
            </Can>
            {!isBulkEditMode && (
              <TabNavLink
                exact
                to={`/settings/users/deleted`}
                onClick={() => {
                  setFilterTab(TABS.deleted);
                }}
              >
                {t('Users.Deleted')}
              </TabNavLink>
            )}
          </div>
          {canAccessNonCameraFeatures && !isBulkEditMode && (
            <div>
              <Buttons
                primaryButtons={[
                  getPrimaryButton({ can, history }),
                  {
                    name: t('Common.BulkEdit'),
                    onClick: toggleBulkEditMode,
                    visible:
                      hasSiteAdminOrResellerRole &&
                      filterTab !== TABS.deleted &&
                      isBulkEditUserEnabled
                  }
                ]}
                moreButtons={getMoreButtons({
                  handleExportExcel,
                  handleBulkImport,
                  handleExportCSV,
                  handleViewBulkAudit,
                  isLoadingCsv: isExportingCsv,
                  isLoadingExcel: isExportingExcel
                })}
                preventClose={!embedsLoaded}
              />
              <CSVLink
                data={csvData || []}
                enclosingCharacter={``}
                className={styles.hidden}
                filename={getCSVFilename({
                  entityName: t('Users.CSV.Filename'),
                  companyName: company?.name
                })}
                ref={csvLinkRef}
              ></CSVLink>
            </div>
          )}
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} value={filterText} />
            <AntMultiselect
              title={
                filterCompanies?.some(value => !value.checked)
                  ? t('Common.Companies')
                  : t('Common.AllCompanies')
              }
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
            <AntMultiselect
              title={
                filterBranches?.some(value => !value.checked)
                  ? t('ExportToExcel.Branches')
                  : t('Users.AllBranches')
              }
              onFilter={v => setFilterBranches(v)}
              data={filterBranches}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {users?.length} {users?.length === 1 ? `${t('Users.User')}` : `${t('Users.Users')}`}
          </label>
        </div>
        <div style={{ height: '100%' }} className={isExporting ? styles.isLoading : ''}>
          {isExporting && (
            <div className={styles.loaderContainer}>
              <Spin />
            </div>
          )}
          <UsersTable
            users={users}
            companies={companies}
            isLoading={isUsersFetching && !isExporting && users.length === 0}
            typeOfEntityToDelete={t('Common.user')}
            setToastMessage={msg => setToastMessage(msg)}
            handleAction={action => handleAction(action)}
            handleDeleteAction={handleDeleteAction}
            setTableRef={setTableRef}
            filterTab={filterTab}
            showTachoColumn={isTachoEnabled}
            tachoData={tachoData}
            localization={localization}
            handleLogInAsUser={handleLogInAsUser}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};
