export function companyObjectToDBO(company) {
  return {
    id: company.id,
    name: company.name,
    slug: company.slug,
    parent_id: company.parent?.id,
    path: company.path,
    version: company.version,
    status: company.status,
    logo: company.logo,
    doc_id: company.docId,
    created_at: company.createdAt,
    updated_at: company.updatedAt,
    customer_number: company.customerNumber,
    external_id: company.externalId,
    country: company.country,
    features: JSON.stringify(company.features),
    tags: company.tags,
    trgm_compilation: company.trgmCompilation,
    statistics_tz: company.statisticsTz,
    locale: company.locale,
    realm: company.realm,
    salesforce_id: company.salesforceId,
    stream_name: company.streamName,
    geofence_providers: JSON.stringify(company.geofenceProviders)
  };
}

export function companyDBOToObject(company) {
  return {
    id: company.id,
    name: company.name,
    slug: company.slug,
    parent: {
      id: company.parent_id
    },
    path: company.path,
    version: company.version,
    status: company.status,
    logo: company.logo,
    docId: company.doc_id,
    createdAt: company.created_at,
    updatedAt: company.updated_at,
    customerNumber: company.customer_number,
    externalId: company.external_id,
    country: company.country,
    features: JSON.parse(company.features),
    tags: company.tags,
    trgmCompilation: company.trgm_compilation,
    statisticsTz: company.statistics_tz,
    locale: company.locale,
    realm: company.realm,
    salesforceId: company.salesforce_id,
    streamName: company.stream_name,
    geofenceProviders: JSON.parse(company.geofence_providers)
  };
}
