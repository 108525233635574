import React, { useState, useRef, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Typography, Modal, Button, Select } from 'antd';

import {
  useCompanyGeofenceProviders,
  useCurrentCompany,
  useCompanies
} from 'features/company/companySlice';
import { useLocations } from 'features/locations/locationsSlice';
import { useGeofences } from 'features/geofences/geofencesSlice';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { useUsers, useIsUserEmbedsLoaded, fetchUsers } from 'features/users/usersSlice';

import { useDocuments } from 'features/easydocs/documentsSlice';
import { usePretripChecklists } from 'features/pretripChecklist/pretripChecklistSlice';
import { useLocalization } from 'features/localization/localizationSlice';

// helpers
import {
  helpers as geofencesHelpers,
  prepareDataForCsvExport as geofencesPrepareDataForCsvExport,
  prepareDataForGeojsonExport,
  downloadFile
} from 'containers/Administration/Geofences/helpers';
import { getNonManagedGeofences } from 'features/geofences/geofencesUtil';
import {
  prepareDataForCsvExport as locationsPrepareDataForCsvExport,
  getCSVFilename
} from 'containers/Administration/Locations/helpers';
import { prepareDataForCsvExport as usersPrepareDataForCsvExport } from 'containers/Administration/Users/helpers';
import { prepareDataForCsvExport as vehiclesPrepareDataForCsvExport } from 'containers/Administration/Vehicles/helpers';
import { entities, useCanEveryEntity, useCan, FeatureFlag } from 'features/permissions';
import { BULK_TYPES, FILE_TYPES } from './constants';
import { loadEmbeds } from 'containers/Administration/Users/constants';

import styles from './ExportModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useGetTimezonesQuery } from 'services/nextgen';

export const ExportModal = ({ show, handleShow }) => {
  const { Title } = Typography;
  const { Option } = Select;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const { data: newTimezones } = useGetTimezonesQuery();
  const can = useCan();

  const companies = useCompanies();
  const company = useCurrentCompany();
  const vehicles = useVehicles();
  const users = useUsers();
  const embedsLoaded = useIsUserEmbedsLoaded();
  const locations = useLocations();
  const geofences = useGeofences();
  const pretrips = usePretripChecklists();
  const documents = useDocuments();
  const filteredLocations = geofencesHelpers.getFilteredLocations(locations);
  const geofenceProviders = useCompanyGeofenceProviders();
  const filteredGeofences = getNonManagedGeofences(geofenceProviders, geofences);
  const csvLinkRef = useRef(null);
  const [csvData, setCsvData] = useState([]);
  const [selectedExportType, setSelectedExportType] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [entityName, setEntityName] = useState('');
  const [isExportingCsv, setIsExportingCsv] = useState(false);

  const canGeofence = useCanEveryEntity([entities.GEOFENCE_CREATE]);
  const canLocation = useCanEveryEntity([entities.LOCATION_CREATE]);
  const canUser = useCanEveryEntity([entities.USER_CREATE]);
  const canVehicle = useCanEveryEntity([entities.VEHICLE_CREATE]);

  useEffect(() => {
    // triggers the excel export
    // moved this into an useEffect in order to have the users updated with the necessary embeded data
    if (embedsLoaded && isExportingCsv) {
      setIsExportingCsv(false);
      setCsvData(
        usersPrepareDataForCsvExport({
          users,
          branches: filteredLocations,
          newTimezones,
          companies,
          dateFormat: localization.formats.time.formats.dby_imsp
        })
      );
    }
  }, [users, isExportingCsv]);

  const handleCancel = () => {
    handleShow(false);
  };

  const handleExport = async () => {
    switch (selectedExportType) {
      case BULK_TYPES.USERS:
        setEntityName(t('Users.CSV.Filename'));
        setIsExportingCsv(true);
        if (!embedsLoaded) {
          await dispatch(fetchUsers({ embed: loadEmbeds }));
        }
        break;
      case BULK_TYPES.VEHICLES:
        setEntityName(t('Vehicles.CSV.Filename'));
        setCsvData(vehiclesPrepareDataForCsvExport(vehicles));
        break;
      case BULK_TYPES.LOCATIONS:
        setEntityName(t('Locations.CSV.Filename'));
        setCsvData(locationsPrepareDataForCsvExport(filteredLocations));
        break;
      case BULK_TYPES.GEOFENCES:
        setEntityName(t('GeofencesFeature.CSV.Filename'));
        if (selectedFileType === FILE_TYPES.GEOJSON) {
          downloadFile({
            data: JSON.stringify(
              prepareDataForGeojsonExport({
                data: filteredGeofences,
                locations: filteredLocations,
                pretrips,
                documents,
                localization,
                t
              })
            ),
            fileName: `${getCSVFilename({
              entityName: t('GeofencesFeature.CSV.Filename'),
              companyName: company?.name,
              extension: 'geojson'
            })}`
          });
          return;
        }
        setCsvData(
          geofencesPrepareDataForCsvExport({
            data: filteredGeofences,
            locations: filteredLocations,
            pretrips,
            documents,
            localization,
            t
          })
        );
        break;
      default:
        return;
    }
  };

  const onChangeExportType = option => {
    setSelectedExportType(option);
    setSelectedFileType(FILE_TYPES.CSV);
  };

  const onChangeFileType = option => setSelectedFileType(option);

  useEffect(() => {
    csvData?.length && csvLinkRef?.current?.link?.click();
  }, [csvData]);

  const Content = () => {
    return (
      <div className={styles.exportContainer}>
        <Title>{t('CompanyConfig.BulkManagement.Modal.ExportType')}</Title>
        <Select
          placeholder={t('CompanyConfig.BulkManagement.Modal.SelectOptionPlaceholer')}
          className={styles.select}
          value={selectedExportType}
          onChange={onChangeExportType}
        >
          {canGeofence && <Option value={BULK_TYPES.GEOFENCES}>{t('Common.Geofences')}</Option>}
          {canLocation && <Option value={BULK_TYPES.LOCATIONS}>{t('Common.Locations')}</Option>}
          {canUser && <Option value={BULK_TYPES.USERS}>{t('Common.Users')}</Option>}
          {canVehicle && <Option value={BULK_TYPES.VEHICLES}>{t('Common.Vehicles')}</Option>}
        </Select>
        <Title>{t('CompanyConfig.BulkManagement.Modal.FileType')}</Title>
        <Select
          placeholder={t('CompanyConfig.BulkManagement.Modal.SelectOptionPlaceholer')}
          className={styles.select}
          value={selectedFileType}
          onChange={onChangeFileType}
        >
          {selectedExportType && (
            <Option value={FILE_TYPES.CSV}>
              {t('CompanyConfig.BulkManagement.Modal.SelectOptionCSV')}
            </Option>
          )}
          {selectedExportType === BULK_TYPES.GEOFENCES && (
            <Option value={FILE_TYPES.GEOJSON}>
              {t('CompanyConfig.BulkManagement.Modal.SelectOptionGeojson')}
            </Option>
          )}
        </Select>
        <CSVLink
          data={csvData || []}
          enclosingCharacter={``}
          className={styles.hidden}
          filename={getCSVFilename({
            entityName,
            companyName: company?.name
          })}
          ref={csvLinkRef}
        ></CSVLink>
      </div>
    );
  };

  const Footer = () => {
    return (
      <>
        <Button
          onClick={handleExport}
          type="primary"
          disabled={!selectedExportType}
          loading={isExportingCsv}
          id={BUTTON_IDS.bulkManagementExport}
        >
          {t('CompanyConfig.BulkManagement.Modal.ExportButton')}
        </Button>
        <Button id={BUTTON_IDS.bulkManagementExportCancel} onClick={handleCancel}>
          {t('Common.Modal.Close')}
        </Button>
      </>
    );
  };

  return (
    <Modal
      className={styles.modal}
      footer={Footer()}
      maskClosable={false}
      onCancel={handleCancel}
      title={t('CompanyConfig.BulkManagement.Modal.BulkExportTitle')}
      open={show}
      width={450}
    >
      <Content />
    </Modal>
  );
};
