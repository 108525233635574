import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Col, Input, Modal, Row } from 'antd';

import { useTranslation } from 'react-i18next';

import { GridColumnEditor } from './GridColumnEditor';

import {
  DEFAULT_VIEW_NAME,
  updateUserGridSettings,
  useIsUserGridSettingsUpdating,
  useUserGridSettings
} from 'features/user/userGridSettingsSlice';

import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { sortStrings } from 'utils/strings';

import { getIncludedAndAvailableColumns, validateViewName } from './ViewsConfigModalHelpers';

import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

import styles from './ViewsConfigModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ViewsConfigModal = ({
  allColumns,
  gridSettingsKey,
  defaultGridConfig,
  isOpen = false,
  onClose,
  onCreateNewView,
  oneWireMultiTempData
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const isUserGridSettingsUpdating = useIsUserGridSettingsUpdating(gridSettingsKey);
  const defaultColumnsLabels = allColumns
    .filter(column => (defaultGridConfig?.views?.Default.columns || []).includes(column.key))
    .map(column => column.label);

  const [includedColumns, setIncludedColumns] = useState([]);
  const [combinedColumns, setCombinedColumns] = useState([]);
  const [oneWireValues, setOneWireValues] = useState([]);
  const [isRenameViewEnabled, setIsRenameViewEnabled] = useState(false);
  const [newViewName, setNewViewName] = useState('');
  const [newUserGridSettings, setNewUserGridSettings] = useState(null);

  const onRename = e => {
    e.currentTarget.blur(); // to lose focus and highlight
    setIsRenameViewEnabled(true);
  };

  const handleViewNameChange = e => {
    const newName = e.target.value;
    setNewViewName(newName);
  };

  const onApply = e => {
    // Validation view name before saving
    if (
      !validateViewName(
        newViewName,
        newUserGridSettings,
        newUserGridSettings.selectedView,
        dispatch,
        t
      )
    ) {
      return;
    }

    e.currentTarget.blur(); // to lose focus and highlight

    // Update userGridSettings with new View name, remove old one
    if (newViewName !== newUserGridSettings.selectedView) {
      let updatedNewUserGridSettings = JSON.parse(JSON.stringify(newUserGridSettings)); // deep copy

      updatedNewUserGridSettings.views[newViewName] =
        updatedNewUserGridSettings.views[updatedNewUserGridSettings.selectedView];
      delete updatedNewUserGridSettings.views[updatedNewUserGridSettings.selectedView];
      updatedNewUserGridSettings.selectedView = newViewName;

      setNewUserGridSettings(updatedNewUserGridSettings);
    }

    setIsRenameViewEnabled(false);
  };

  const updateGroupBy = groupBy => {
    if (newUserGridSettings) {
      let updatedNewUserGridSettings = JSON.parse(JSON.stringify(newUserGridSettings)); // deep copy
      let viewConfig = updatedNewUserGridSettings.views[updatedNewUserGridSettings.selectedView];
      viewConfig.groupBy = groupBy;
      setNewUserGridSettings(updatedNewUserGridSettings);
    }
  };

  const handleCreate = () => {
    // Todo: Warn user if there are unsaved changes

    // Close current dialog and open Add View Modal
    onCreateNewView();
  };

  const handleDeleteClick = () => {
    // Show confirmation dialog first
    confirmationModal(
      `${t('Common.DeleteButton')} ${newViewName}`,
      t('Tracking.ViewsConfig.SureDeleteView', { name: newViewName }),
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      handleDeleteAction,
      'delete'
    );
  };

  const handleDeleteAction = () => {
    // remove view from userGridSettings and save
    const deletedView = newUserGridSettings.selectedView;
    delete newUserGridSettings.views[deletedView];

    // Get new selected view
    newUserGridSettings.selectedView = DEFAULT_VIEW_NAME;
    if (newUserGridSettings.views) {
      const views = Object.keys(newUserGridSettings.views);
      if (views.length > 0) {
        const sortedViews = views.sort((a, b) => sortStrings(a, b));
        newUserGridSettings.selectedView = sortedViews[0];
      }
    }

    dispatch(
      updateUserGridSettings(
        newUserGridSettings,
        gridSettingsKey,
        () => handleDeleteSuccess(deletedView),
        false
      )
    );
  };

  const handleDeleteSuccess = deletedView => {
    onClose();

    // show toast once delete is complete
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('Tracking.ViewsConfig.ViewDeleteSuccess', { name: deletedView })
      })
    );
  };

  const handleCancel = () => {
    // Todo: Warn user if there are unsaved changes

    onClose();
  };

  const onViewwConfigModalSave = () => {
    // update included column keys before saving
    const { selectedView, views } = newUserGridSettings;
    const viewConfig = views[selectedView];

    const userColumnConfig = userGridSettings.config?.views?.[selectedView]?.includedColumns ?? [];

    viewConfig.includedColumns = combinedColumns.map(({ key, width }) => {
      const matchedUserCol = userColumnConfig.find(uc => uc.key === key);
      return {
        key,
        width: matchedUserCol?.width ?? width
      };
    });

    // Save old columns schema for backwards compatibility
    viewConfig.columns = combinedColumns.map(c => c.key);
    dispatch(updateUserGridSettings(newUserGridSettings, gridSettingsKey, onClose, true));
  };

  const onResetSelection = () => {
    const defaultColumnKeys = defaultGridConfig?.views?.Default.columns || [];
    const defaultColumns = allColumns.filter(column => defaultColumnKeys.includes(column.key));
    setIncludedColumns(defaultColumns);
  };

  useEffect(() => {
    if (userGridSettings && userGridSettings.lastFetched) {
      let combinedColumnsWithOutSelectedValue = [
        ...allColumns,
        ...Object.values(oneWireMultiTempData || {}) // Filter selected user-defined fields
          .map(value => ({
            title: value,
            label: value,
            key: value,
            width: 150, // Ensure a default width or assign if necessary
            isConfigurable: true
          }))
      ];

      combinedColumnsWithOutSelectedValue = combinedColumnsWithOutSelectedValue.filter(
        (item, index, self) => index === self.findIndex(t => t.title === item.title)
      );

      const columnSettings = getIncludedAndAvailableColumns(
        userGridSettings,
        combinedColumnsWithOutSelectedValue,
        t
      );

      setOneWireValues(combinedColumnsWithOutSelectedValue);
      setIncludedColumns(columnSettings.includedColumns);
      // Make a copy of the config from the API call for editing
      let newConfig;
      if (!userGridSettings.config) {
        newConfig = JSON.parse(JSON.stringify(defaultGridConfig)); // deep copy
      } else {
        newConfig = JSON.parse(JSON.stringify(userGridSettings.config)); // deep copy
      }
      setNewUserGridSettings(newConfig);

      const viewName = newConfig?.selectedView;
      setNewViewName(viewName);
    }
  }, [userGridSettings, oneWireMultiTempData]);

  const isDeleteButtonVisible = newUserGridSettings?.views
    ? Object.keys(newUserGridSettings.views).length > 1
    : false;

  const groupBy = newUserGridSettings
    ? newUserGridSettings.views[newUserGridSettings.selectedView].groupBy
    : [];

  return (
    <Modal
      title={t('Tracking.ViewsConfig.EditView')}
      open={isOpen}
      centered={true}
      closable={false}
      width={500}
      footer={null}
      wrapClassName={styles.viewsConfigDialog}
    >
      <>
        <Row wrap={false} className={styles.viewConfigHeaderContainer}>
          {!isRenameViewEnabled ? (
            <Col span={24}>
              <span className={styles.viewName}>{newViewName}</span>
              <Button
                id={BUTTON_IDS.renameViewsConfig}
                size="medium"
                className={styles.renameButton}
                onClick={e => onRename(e)}
              >
                {t('Tracking.ViewsConfig.RenameView')}
              </Button>
              <Button
                id={BUTTON_IDS.addNewViewsConfig}
                size="medium"
                className={styles.createNewButton}
                onClick={handleCreate}
              >
                {t('Tracking.ViewsConfig.AddNewView')}
              </Button>
            </Col>
          ) : (
            <Col span={24}>
              <Input
                className={styles.viewNameEdit}
                value={newViewName}
                onChange={e => handleViewNameChange(e)}
              />
              <Button
                size="medium"
                className={styles.applyButton}
                onClick={e => onApply(e)}
                name="applyButton"
                id={BUTTON_IDS.applyViewsConfig}
              >
                {t('Common.Apply')}
              </Button>
            </Col>
          )}
        </Row>
        <GridColumnEditor
          allColumns={oneWireValues}
          includedColumns={includedColumns}
          setIncludedColumns={setIncludedColumns}
          groupBy={groupBy}
          updateGroupBy={updateGroupBy}
          onResetSelection={onResetSelection}
          defaultColumnsLabels={defaultColumnsLabels}
          oneWireMultiFilterValues={oneWireMultiTempData}
          setCombinedColumns={setCombinedColumns}
        />
        <Row wrap={false}>
          <Col span={24} style={{ display: 'flex' }}>
            <Button
              type="primary"
              size="middle"
              loading={isUserGridSettingsUpdating}
              className={styles.saveButton}
              onClick={onViewwConfigModalSave}
              id={BUTTON_IDS.saveViewsConfig}
            >
              {t('Tracking.ViewsConfig.SaveView')}
            </Button>
            <Button
              size="medium"
              id={BUTTON_IDS.cancelViewsConfig}
              className={styles.cancelButton}
              onClick={handleCancel}
            >
              {t('Common.Cancel')}
            </Button>
            {isDeleteButtonVisible && (
              <Button
                size="medium"
                danger
                className={styles.deleteButton}
                onClick={handleDeleteClick}
                id={BUTTON_IDS.deleteViewsConfig}
              >
                {t('Tracking.ViewsConfig.DeleteView')}
              </Button>
            )}
          </Col>
        </Row>
      </>
    </Modal>
  );
};
