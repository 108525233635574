import { TNDataService } from '../tnDataService';
import { deviceModelsDataAdapter } from './deviceModels';
import { deviceTypesDataAdapter } from './deviceTypes';
import { reduxAdapter } from './reduxAdapter';

const devicesDataAdapter = reduxAdapter({
  persist: async devices => {
    if (devices && devices.length) {
      await TNDataService.insertOrUpdateDevicesInBulk(devices);
    }
  },
  load: async embedVehicle => {
    const devices = await TNDataService.getAllDevices();
    const deviceTypes = await deviceTypesDataAdapter.load();
    const deviceModels = await deviceModelsDataAdapter.load();
    const deviceTypesMap = deviceTypes.reduce((a, dt) => {
      a[dt.id] = dt;
      return a;
    }, {});
    const deviceModelsMap = deviceModels.reduce((a, dm) => {
      a[dm.id] = dm;
      return a;
    }, {});

    let vehiclesMap = null;
    if (embedVehicle) {
      const vehicles = await TNDataService.getAllVehicles();
      vehiclesMap = vehicles.reduce((a, v) => {
        a[v.id] = v;
        return a;
      }, {});
    }

    devices.forEach(d => {
      if (d.type?.id) {
        const deviceType = deviceTypesMap[d.type.id];
        if (deviceType) {
          d.type = deviceType;
        }
      }

      if (d.model?.id) {
        const deviceModel = deviceModelsMap[d.model.id];
        if (deviceModel) {
          d.model = deviceModel;
        }
      }

      if (embedVehicle && d.vehicle?.id) {
        const vehicle = vehiclesMap[d.vehicle.id];
        if (vehicle) {
          d.vehicle = vehicle;
        }
      }
    });
    return devices;
  }
});

export { devicesDataAdapter };
