import { Button, Empty, Space, Table } from 'antd';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { useLocalization } from 'features/localization/localizationSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import i18n from 'i18next';
import { format } from 'utils/dates';
import styles from '../InstallationLog.module.scss';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { DeleteIcon, EditIcon } from '../svgs/SvgIcons';

import {
  deleteScheduledReportsById,
  fetchScheduledReportsByUser,
  useScheduledReportsByUser
} from 'features/installation_logs/InstallationLogsSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { Comparators } from 'utils/sorting';
import { NewScheduledReportModal } from './NewScheduledReportModal';
import { orderBy } from 'lodash';

export const ColumnKeys = {
  REPORTNAME: 'reportname',
  DESCRIPTION: 'description',
  SCHEDULE: 'schedule',
  COMPANY: 'company',
  NEXTRUN: 'nextrun',
  ACTION: 'action'
};

const ScheduleFrequencyEnum = {
  ONCE_OFF: 'Once',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly'
};

export const getAllColumns = localization => {
  const columns = [
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.ReportName'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.REPORTNAME,
      key: ColumnKeys.REPORTNAME,
      width: '150px',
      isSearchable: true,
      sorter: Comparators.String(ColumnKeys.REPORTNAME),
      render: (value, rowData, rowIndex) => {
        return rowData.reportname || '';
      },
      className: `${styles.first_column}`
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Description'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.DESCRIPTION,
      key: ColumnKeys.DESCRIPTION,
      width: '150px',
      isSearchable: true,
      sorter: Comparators.String(ColumnKeys.DESCRIPTION),
      render: (value, rowData, rowIndex) => {
        return rowData.description || '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Company'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.COMPANY,
      key: ColumnKeys.COMPANY,
      width: '150px',
      isSearchable: true,
      sorter: Comparators.String(ColumnKeys.COMPANY),
      render: (value, rowData, rowIndex) => {
        return rowData.company || '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Schedule'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.SCHEDULE,
      key: ColumnKeys.SCHEDULE,
      width: '150px',
      isSearchable: true,
      sorter: Comparators.String(ColumnKeys.SCHEDULE),
      render: (value, rowData, rowIndex) => {
        return ScheduleFrequencyEnum[rowData.schedule] || '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.NextRun'),
      type: ColumnTypes.Date,
      dataIndex: ColumnKeys.NEXTRUN,
      key: ColumnKeys.NEXTRUN,
      width: '150px',
      isSearchable: true,
      sorter: Comparators.String(ColumnKeys.NEXTRUN),
      render: (value, rowData, rowIndex) => {
        return rowData.nextrun
          ? format(rowData.nextrun, localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.ScheduledReports.Action'),
      type: ColumnTypes.String,
      dataIndex: ColumnKeys.ACTION,
      key: ColumnKeys.ACTION,
      width: '100px',
      fixed: 'right',
      isSearchable: false,
      render: (value, rowData, rowIndex) => {
        return rowData.action || '';
      }
    }
  ];
  return columns;
};
export const ScheduledReports = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const history = useHistory();
  const [allColumns, setAllColumns] = useState(getAllColumns(localization));
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [selectedSchedule, setSelectedSchedule] = useState(null); // For holding the schedule to be edited
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [allData, setAllData] = useState([]);
  const [exisitngReportNames, setExisitngReportNames] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle(`${t('SupportToolsELD.ScheduledReportsTitle')}`));
    dispatch(setBackButton(true));
    dispatch(fetchScheduledReportsByUser());
  }, [dispatch, t]);

  const { loading, errMsg, data: resp } = useScheduledReportsByUser();

  const handleSearchChange = text => {
    setSearchText(text);
  };

  useEffect(() => {
    if (resp && resp.length > 0) {
      setAllData(resp);
      setExisitngReportNames(resp?.map(p => p?.name));
    } else {
      setAllData([]);
    }
  }, [resp]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    if (resp) {
      setData(PrepareDataForTable({ allData, searchText: debouncedSearchText }));
    }
  }, [debouncedSearchText, allData]);

  const PrepareDataForTable = ({ allData, searchText }) => {
    const searchableColumns = getAllColumns()?.filter(col => col.isSearchable);
    let returnData = [];
    let filteredData = [];

    // Delete confirmation modal handler
    const handleDeleteModal = entry => {
      confirmationModal(
        `${t('Common.DeleteButton')} ${entry?.name}`,
        `${t('Common.SureDelete')} ${entry?.name}?`,
        t('Common.DeleteButton'),
        t('Common.CancelButton'),
        () => handleDelete(entry),
        'delete'
      );
    };
    // Delete entry handler
    const handleDelete = entry => {
      dispatch(deleteScheduledReportsById(entry?.id, entry?.name));
    };
    // Edit entry handler
    const handleEdit = entry => {
      setModalTitle(t('SupportToolsELD.ScheduledReports.Edit'));
      setSelectedSchedule(entry);
      setIsOpen(true);
    };

    // Map allData to returnData for the table
    returnData = allData.map((entry, index) => ({
      key: entry.id,
      [ColumnKeys.REPORTNAME]: entry.name,
      [ColumnKeys.DESCRIPTION]: entry.description,
      [ColumnKeys.COMPANY]: entry.company?.name,
      [ColumnKeys.SCHEDULE]: entry.frequency,
      [ColumnKeys.NEXTRUN]: entry.nextRunAt,
      [ColumnKeys.ACTION]: (
        <Space size={16}>
          <div
            style={{
              cursor: 'pointer'
            }}
            onClick={() => handleEdit(entry)}
          >
            <EditIcon />
          </div>
          <div
            style={{
              cursor: 'pointer'
            }}
            onClick={() => handleDeleteModal(entry)}
          >
            <DeleteIcon />
          </div>
        </Space>
      )
    }));
    // Filter the mapped data based on searchText
    filteredData = returnData?.filter(row => {
      if (!searchText?.trim()) {
        // Return all rows if searchText is empty
        return true;
      }
      const lowerSearchText = searchText.trim().toLowerCase();
      // Check if any searchable column contains the search text
      return searchableColumns.some(col =>
        row[col.dataIndex]
          ?.toString()
          .toLowerCase()
          .includes(lowerSearchText)
      );
    });
    // Sort filtered data by NEXTRUN column in descending order
    if (filteredData.length > 0) {
      filteredData = orderBy(filteredData, [ColumnKeys.NEXTRUN], ['asc']);
    }
    return filteredData;
  };

  const handleAddNewSchedule = () => {
    setModalTitle(t('SupportToolsELD.ScheduledReports.NewSchedule'));
    setSelectedSchedule(null);
    setIsOpen(true);
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div className={`${styles.btnLeftAlign}`}>
            <Button type="primary" size="large" onClick={handleAddNewSchedule}>
              {t('SupportToolsELD.AddNewSchedule')}
            </Button>
          </div>
        </HeaderPageWrapper>
        <div className={`${styles.searchDiv}`}>
          <FilterWrapper>
            <AntSearchbar
              onFilter={handleSearchChange}
              value={searchText}
              size={'medium'}
              className={`${styles.search}`}
            />
          </FilterWrapper>
        </div>
        <div>
          <Table
            className={`${styles.scheduler_table_header}`}
            dataSource={data}
            pagination={false}
            columns={allColumns}
            loading={loading}
            sticky
            scroll={{ y: 400 }} // The body will scroll within 400px
            locale={{
              emptyText: i18n.t('SupportToolsELD.InstallationLogsColumns.NoScheduledReports')
            }}
          />
        </div>
        <div>
          <NewScheduledReportModal
            title={modalTitle} // Pass dynamic title
            isOpen={isOpen}
            currentReport={selectedSchedule} // Pass the selected schedule object
            exisitngReportNames={exisitngReportNames}
            onClose={() => {
              setIsOpen(false);
              setSelectedSchedule(null); // Clear selected schedule on close
            }}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};
