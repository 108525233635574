import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Can, FeatureFlag } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { InstallationLogs } from './InstallationLogs';
import { InstallationAuditLogsView } from './InstallationAuditLogsView';
import DownloadAuditLogsView from './DownloadAuditLogsView';
import InstallationAuditLogsPhotos from './InstallationAuditLogsPhotos';
import { ScheduledReports } from './scheduledReports/ScheduledReports';

export const InstallationAuditLogsRouter = () => {
  return (
    <>
      <Can onFail={<NoAccessBody />}>
        <Switch>
          <Route exact path={['/supportTools/installationAuditLog']}>
            <InstallationLogs />
          </Route>
          <Route path={['/supportTools/installationAuditLog/view']}>
            <InstallationAuditLogsView />
          </Route>
          <Route path={['/supportTools/installationAuditLog/logview/export/id']}>
            <DownloadAuditLogsView />
          </Route>
          <Route path={['/supportTools/installationAuditLog/photos/:supportType/:companyId/:id']}>
            <InstallationAuditLogsPhotos />
          </Route>
          <Route path={['/supportTools/installationAuditLog/scheduleReports']}>
            <Can featureFlag={FeatureFlag.ScheduledReports.flag}>
              <ScheduledReports />
            </Can>
          </Route>
        </Switch>
      </Can>
    </>
  );
};

export default InstallationAuditLogsRouter;
