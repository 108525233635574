export function deviceTypesObjectToDBO(deviceTypes) {
  return {
    id: deviceTypes.id,
    name: deviceTypes.name,
    description: deviceTypes.description,
    code: deviceTypes.code,
    created_at: deviceTypes.createdAt,
    updated_at: deviceTypes.updatedAt,
    dynamic_features: JSON.stringify(deviceTypes.features),
    priority: deviceTypes.priority
  };
}

export function deviceTypesDBOToObject(deviceTypes) {
  return {
    id: deviceTypes.id,
    name: deviceTypes.name,
    description: deviceTypes.description,
    code: deviceTypes.code,
    createdAt: deviceTypes.created_at,
    updatedAt: deviceTypes.updated_at,
    features: JSON.parse(deviceTypes.dynamic_features),
    priority: deviceTypes.priority
  };
}
