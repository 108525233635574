import React, { useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { DashboardTab } from './DashboardTab';
import { TabNavLink } from '../../components/nav/NavLinks';
import {
  Can,
  entities,
  services,
  useIQCameraUser,
  FeatureFlag,
  useCanFeatureFlag
} from 'features/permissions';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { useParentCallback } from 'utils/hooks/useParentCallback';
import { useTranslation } from 'react-i18next';
import { CameraRoute } from './Camera/CameraRoute';

export const Home = () => {
  const [actions, subscribeActions] = useParentCallback(['handleRefresh']);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const moveCamerasInMainMenu = useCanFeatureFlag({
    featureFlag: FeatureFlag.NewHome.flag
  });

  useEffect(() => {
    if (
      !canAccessNonCameraFeatures &&
      (location.pathname === '/home' || location.pathname === '/')
    ) {
      history.push('/home/cameras');
    }
  }, [canAccessNonCameraFeatures, history, location]);

  return (
    <ContainerPageWrapper>
      <HeaderPageWrapper>
        <div>
          {canAccessNonCameraFeatures && (
            <TabNavLink
              to={'/home'}
              isActive={(_, location) => location.pathname.match(/^\/(?:home(?:\/dashboard)?)?$/)}
            >
              {t('Home.Dashboard')}
            </TabNavLink>
          )}
          {!moveCamerasInMainMenu && (
            /* Remove this entire block when the featureflag [moveCamerasMainMenu] is removed */
            <Can
              everyService={[services.CAMERA]}
              oneOfEntities={[entities.VIDEO, entities.VIDEO_VIEW]}
            >
              <TabNavLink to={'/home/cameras'} isActive={(match, location) => match}>
                {t('Home.Cameras')}
              </TabNavLink>
            </Can>
          )}
        </div>
      </HeaderPageWrapper>
      <Switch>
        {canAccessNonCameraFeatures && (
          <Route exact path={['/', '/home', '/home/dashboard']}>
            <DashboardTab />
          </Route>
        )}

        <CameraRoute subscribeActions={subscribeActions} actions={actions} />
      </Switch>
    </ContainerPageWrapper>
  );
};
