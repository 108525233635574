import React, { useEffect, useState } from 'react';
import styles from '../VehiclesBulkEdit.module.scss';
import { useVehicleModels } from 'features/vehicles/vehicleModels';

export const VehicleMakeModelYearCell = ({ companyId, vehicleModelId }) => {
  const makeModelYearFullList = useVehicleModels(companyId);
  const [vehicleModel, setVehicleModel] = useState({});

  useEffect(() => {
    if (makeModelYearFullList && vehicleModelId) {
      setVehicleModel(makeModelYearFullList.find(i => i.id === vehicleModelId));
    }
  }, [makeModelYearFullList, vehicleModelId]);

  return (
    <div className={styles.cellMultilineContainer}>
      {vehicleModel ? (
        <>
          <div className={styles.cellSubtitle}>{vehicleModel?.make}</div>
          <div className={styles.cellSubtitle}>{vehicleModel?.model}</div>
          <div className={styles.cellSubtitle}>{vehicleModel?.yearOfManufacture}</div>
        </>
      ) : (
        <> - </>
      )}
    </div>
  );
};
