import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Modal, Tooltip } from 'antd';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { CommentField } from './CommentField';
import { useUsers } from 'features/users/usersSlice';
import { useUser } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import ReactMarkdown from 'react-markdown';
import styles from './EventComments.module.scss';

export function EventComments({ comments, eventId, onAdd, onEdit, onDelete }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const allUsers = useUsers();
  const currentUser = useUser();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);

  const allUsersInCurrentCompany = useMemo(() => {
    return allUsers.filter(user => user.companyId === currentCompany?.id);
  }, [allUsers, currentCompany]);

  const normalizedComments = useMemo(() => {
    return comments?.map(comment => {
      const user = allUsersInCurrentCompany.find(user => user.id === comment.userId);
      return {
        ...comment,
        name: `${user?.firstName || ''} ${user?.lastName || ''}`.trim()
      };
    });
  }, [comments, allUsersInCurrentCompany]);

  const handleAddComment = useCallback(
    markdown => {
      setIsProcessing(true);
      onAdd(
        { comment: markdown },
        () => {
          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('Event.Notifications.Added', { name: t('Audits.Comment') })
            })
          );
          setIsProcessing(false);
        },
        errMsg => {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Event.Notifications.AddFailed', {
                name: t('Audits.Comment'),
                error: errMsg
              })
            })
          );
          setIsProcessing(false);
        }
      );
    },
    [dispatch, t, onAdd]
  );

  const handleEditSubmit = useCallback(
    markdown => {
      if (!editingCommentId) return;

      setIsProcessing(true);
      const commentToUpdate = comments.find(comment => comment.id === editingCommentId);
      onEdit(
        { ...commentToUpdate, comment: markdown }, // Include updated content
        () => {
          setEditingCommentId(null); // Exit edit mode
          setIsProcessing(false);
          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('Event.Notifications.Updated', { name: t('Audits.Comment') })
            })
          );
        },
        errMsg => {
          setIsProcessing(false);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Event.Notifications.UpdateFailed', {
                name: t('Audits.Comment'),
                error: errMsg
              })
            })
          );
        }
      );
    },
    [dispatch, t, onEdit, editingCommentId, comments]
  );

  const handleDeleteComment = useCallback(
    id => {
      Modal.confirm({
        title: t('Event.Comment.ConfirmDeleteTitle'),
        content: t('Event.Comment.ConfirmDeleteComment'),
        onOk: () => {
          setIsProcessing(true);
          onDelete(
            { id },
            () => {
              setIsProcessing(false);
              dispatch(
                openToast({
                  type: ToastType.Success,
                  message: t('Event.Notifications.Deleted', { name: t('Audits.Comment') })
                })
              );
            },
            errMsg => {
              setIsProcessing(false);
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: t('Event.Notifications.DeleteFailed', {
                    name: t('Audits.Comment'),
                    error: errMsg
                  })
                })
              );
            }
          );
        }
      });
    },
    [dispatch, t, onDelete]
  );

  const handleEditClick = comment => {
    setEditingCommentId(comment.id);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setIsEditing(false);
  };

  const CommentList = ({ comments = [] }) =>
    comments?.map(comment => {
      const isEdited = comment.updatedAt && moment(comment.updatedAt).isAfter(comment.createdAt);

      return (
        <div className={styles.list} key={comment.id}>
          <div className={styles.meta}>
            <span className={styles.author}>{comment.name}</span>
            <span>
              {moment(comment.createdAt).fromNow()}{' '}
              {isEdited && (
                <Tooltip title={`${moment(comment.updatedAt).fromNow()} by ${comment.name}`}>
                  <span className={styles.edited}>{t('Event.Comment.Edited')}</span>
                </Tooltip>
              )}
            </span>
          </div>
          <div className={styles.comment}>
            {isEditing && editingCommentId === comment.id ? (
              <CommentField
                onSave={handleEditSubmit}
                loading={isProcessing}
                isExpanded={true}
                defaultValue={comment.comment}
                onCancel={handleCancelEdit}
              />
            ) : (
              <ReactMarkdown>{comment.comment}</ReactMarkdown>
            )}
          </div>
          <div className={styles.actions}>
            {editingCommentId !== comment.id && comment.userId === currentUser.id && (
              <>
                <span className={styles.edit} key="edit" onClick={() => handleEditClick(comment)}>
                  {t('Common.Edit')}
                </span>
                <span key="delete" onClick={() => handleDeleteComment(comment.id)}>
                  {t('Common.Delete')}
                </span>
              </>
            )}
          </div>
        </div>
      );
    });

  return (
    <>
      <CommentField
        onSave={handleAddComment}
        loading={isProcessing}
        eventId={eventId}
        userId={currentUser.id}
      />

      <CommentList comments={normalizedComments} />

      <Divider />
    </>
  );
}
