export function locationObjectToDBO(location) {
  return {
    id: location.id,
    name: location.name,
    path: location.path,
    version: location.version,
    company_id: location.companyId,
    location_type_id: location.type?.id,
    address_id: location.address?.id,
    site_notes: location.siteNotes,
    external_id: location.externalId,
    sync_in_at: location.syncInAt,
    sync_out_at: location.syncOutAt,
    verify: location.verify,
    sync: location.sync,
    status: location.status,
    doc_id: location.docId,
    created_at: location.createdAt,
    updated_at: location.updatedAt,
    position: location.position,
    time_zone: location.timeZone,
    customer_id: location.customerId
  };
}

export function locationDBOToObject(location) {
  return {
    id: location.id,
    name: location.name,
    path: location.path,
    version: location.version,
    companyId: location.company_id,
    type: {
      id: location.location_type_id
    },
    address: {
      id: location.address_id
    },
    siteNotes: location.site_notes,
    externalId: location.external_id,
    syncInAt: location.sync_in_at,
    syncOutAt: location.sync_out_at,
    verify: location.verify,
    sync: location.sync,
    status: location.status,
    docId: location.doc_id,
    createdAt: location.created_at,
    updatedAt: location.updated_at,
    position: location.position,
    timeZone: location.time_zone,
    customerId: location.customer_id,
    geofences: []
  };
}
