import React, { useRef } from 'react';
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useState } from 'react';
import { useCallback } from 'react';
import { Tabs, Row, Col } from 'antd/lib';
import { TNDataService } from 'data/dataService/tn/tnDataService';
import { LocalDBDataService } from 'data/dataService/localDbDataService';
import {
  loadDataFromLocalDB,
  persistDataToLocalDB
} from 'data/dataService/tn/adapter/reduxDataAdapter';
import store from '../../store';
import { useUser } from 'features/user/userSlice';

export function DataTest(props) {
  const [tables, setTables] = useState([]);
  const sqlRef = useRef('');
  const [isBusy, setIsBusy] = useState(false);
  const [resultHistory, setResultHistory] = useState([]);
  const currentUser = useUser();

  const loadTables = useCallback(async () => {
    const tables = await LocalDBDataService.getInstance().getTables();
    setTables(tables);
  }, []);

  const executeSql = useCallback(async ({ query }) => {
    setIsBusy(true);
    const sql = query ? query : sqlRef.current;
    const result = await LocalDBDataService.getInstance().executeSqlV2(sql);
    setResultHistory(prev => {
      return [{ sql: sql, result: JSON.stringify(result) }, ...prev];
    });
    setIsBusy(false);
  }, []);

  const saveReduxData = useCallback(async () => {
    await TNDataService.deleteAllData();
    persistDataToLocalDB(store);
  }, []);

  const loadDataToRedux = useCallback(() => {
    loadDataFromLocalDB(store, currentUser);
  }, [currentUser]);

  const tabItems = [
    {
      key: '1',
      label: 'Tables in DB',
      children: (
        <div>
          <button onClick={loadTables}>Show Tables</button>
          <div>
            <p>Tables:</p>
            <div>
              {tables.map(t => (
                <p key={t} onClick={() => executeSql({ query: `select * from ${t}` })}>
                  {t}
                </p>
              ))}
            </div>
          </div>
        </div>
      )
    },
    {
      key: 'ReduxData',
      label: 'Redux Data',
      children: (
        <div>
          <button onClick={saveReduxData}>Save Redux Data</button>
          <button onClick={loadDataToRedux}>Load Data to Redux</button>
        </div>
      )
    },
    {
      key: 'SQL',
      label: 'Run SQL',
      children: (
        <div style={{ display: 'flex' }}>
          <div>
            <button onClick={loadTables}>Show Tables</button>
            <div>
              <p>Tables:</p>
              <div>
                {tables.map(t => (
                  <p key={t} onClick={() => executeSql({ query: `select * from ${t}` })}>
                    {t}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div>
            <input
              type="text"
              width={250}
              onChange={e => {
                sqlRef.current = e.target.value;
              }}
              onKeyDown={event => {
                if (event.which === 13 /* Enter */) {
                  executeSql({ query: false });
                }
              }}
            />
            <button onClick={executeSql} onSubmit={executeSql} disabled={isBusy}>
              Execute Sql
            </button>
            {resultHistory.map((r, i) => (
              <div key={i}>
                <p>
                  <span>Sql:</span>
                  <span>{r.sql}</span>
                </p>
                <section>
                  <p>Result:</p>
                  <span>{JSON.parse(JSON.stringify(r.result))}</span>
                </section>
              </div>
            ))}
          </div>
        </div>
      )
    }
  ];

  return (
    <div style={{ height: '100%' }}>
      <Tabs defaultActiveKey="1" centered items={tabItems} />
    </div>
  );
}
