import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, Card, Form, InputNumber, Select, Spin, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './Configurations.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useAddDeviceConfigsMutation,
  useCurrentCompanyServicesMap,
  useDeleteDeviceConfigsMutation,
  useGetDeviceGpioConfigsQuery,
  useGetGpioConfigurationTemplatesQuery,
  useUpdateDeviceAssociatedTemplateMutation
} from 'services/nextgen/ngGpioConfigurationApi';
import { useCurrentCompany } from 'features/company/companySlice';
import { executeUpdateConfig, useUpdateConfigsMutation } from 'services/nextgen/ngConfigKeyApi';
import {
  enrichWithExtraServiceConfig,
  generateGpioConfigs,
  toTableStructure,
  useConfigMapping
} from '../../../Configuration/CompanyConfig/Forms/GPIO/helpers';
import sortBy from 'lodash/sortBy';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  AdditionalConfigCard,
  ConfigCard
} from '../../../Configuration/CompanyConfig/Forms/GPIO/components/ConfigCard';
import useSetState from 'utils/hooks/useSetState';
import { GPIOConfigForm } from '../../../Configuration/CompanyConfig/Forms/GPIO/components/GPIOConfigForm';
import {
  FeatureFlag,
  services,
  useCanEveryCompanyService,
  useCanFeatureFlag
} from 'features/permissions';
import { BUTTON_IDS } from 'utils/globalConstants';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { confirmationModal } from '../../../../components/ant/Button/confirmationModal/confirmationModal';
import { ngDeviceApi, useGetDeviceTemplateQuery } from 'services/nextgen/ngDeviceApi';
import { Scope } from '../../../Configuration/CompanyConfig/Forms/GPIO/constants';
import { useGPIORelatedConfigs } from '../../../Configuration/CompanyConfig/Forms/GPIO/hooks/useGPIORelatedConfigs';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { UNITS } from 'features/localization/localization';
import { parseErrorMessage } from 'utils/strings';
import { lowerCase, startCase } from 'lodash';
import { inputValidator } from '../helpers';

const getInputColumns = t => {
  return [
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Bias'),
      dataIndex: 'bias',
      render: text => <span>{text}</span>,
      width: '25%',
      key: 'bias'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Trigger'),
      dataIndex: 'trigger',
      render: text => <span>{text}</span>,
      width: '25%',
      key: 'trigger'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Occurrence'),
      dataIndex: 'occurrence',
      render: text => <span>{text} </span>,
      width: '25%',
      key: 'occurrence'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.I/O Definition'),
      width: '25%',
      dataIndex: 'io',
      key: 'io'
    }
  ];
};

const getOutputColumns = t => {
  return [
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Bias'),
      dataIndex: 'bias',
      render: text => <span>{text}</span>,
      width: '25%',
      key: 'bias'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Trigger'),
      dataIndex: 'trigger',
      render: text => <span>{text}</span>,
      width: '25%',
      key: 'trigger'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Occurrence'),
      dataIndex: 'occurrence',
      render: text => <span>{text} </span>,
      width: '25%',
      key: 'occurrence'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.I/O Definition'),
      dataIndex: 'io',
      width: '25%',
      key: 'io'
    }
  ];
};

const mergeConfigurations = configList => {
  const scopePriority = {
    [Scope.DEVICE_DIRECT]: 4,
    [Scope.DEVICE]: 3,
    [Scope.FLEET]: 2,
    [Scope.COMPANY]: 1
  };

  const configMap = {};

  for (const config of configList) {
    const compositeKey = `${config.key}-${config.service.id}`;
    const scope = config.scope;
    const currentPriority = scopePriority[scope] || 0;

    if (!configMap[compositeKey]) {
      configMap[compositeKey] = config;
    } else {
      const existingConfig = configMap[compositeKey];
      const existingScope = existingConfig.scope;
      const existingPriority = scopePriority[existingScope] || 0;

      if (currentPriority > existingPriority) {
        configMap[compositeKey] = config;
      }
    }
  }

  return Object.values(configMap);
};

export const GPIOConfig = ({ data, onClose }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [configForm] = Form.useForm();
  const company = useCurrentCompany();
  const serviceMap = useCurrentCompanyServicesMap();

  const [overrideSettings, setOverrideSettings] = useState(false);
  const [gpioFormState, setGpioFormState] = useSetState({
    editIndex: -1,
    editConfig: {},
    open: false
  });
  const gpioFormRef = useRef(null);
  const [isGpioConfigValid, setIsGpioConfigValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [currentTemplate, setCurrentTemplate] = useSetState({
    [Scope.COMPANY]: null,
    [Scope.FLEET]: null,
    [Scope.DEVICE]: null
  });

  const [seatbeltSpeedThreshold, setSeatbeltSpeedThreshold] = useState(8);
  const [gpioList, setGpioList] = useState([]);
  const [diginData, setDiginData] = useState([]);
  const [digoutData, setDigoutData] = useState([]);

  const vt202OutputFeature = useCanFeatureFlag({
    featureFlag: FeatureFlag.vt202Output.flag
  });
  const isVPMEnabled = useCanEveryCompanyService(services.VPM);

  const [updateConfigs] = useUpdateConfigsMutation();
  const { gpioConfigs, extraConfigs, isFetching: isFetchingConfigKeys } = useGPIORelatedConfigs();

  const { diginFormConfig, digoutFormConfig, channelTypes } = useConfigMapping(gpioConfigs);

  const {
    data: inheritedTemplates,
    isFetching: isFetchingDeviceTemplate
  } = useGetDeviceTemplateQuery({
    id: data.id
  });

  const {
    data: templateData,
    isFetching: isFetchingGpioTemplates
  } = useGetGpioConfigurationTemplatesQuery(
    { companyId: company?.id },
    { skip: company?.id === undefined }
  );

  const { data: deviceConfigs, isFetching: isFetchingDeviceConfigs } = useGetDeviceGpioConfigsQuery(
    { deviceId: data.id },
    { skip: company?.id === undefined }
  );

  const isFetching =
    isFetchingConfigKeys ||
    isFetchingDeviceTemplate ||
    isFetchingGpioTemplates ||
    isFetchingDeviceConfigs;

  const [updateDeviceAssociatedTemplate] = useUpdateDeviceAssociatedTemplateMutation();
  const [deleteDeviceConfigs] = useDeleteDeviceConfigsMutation();
  const [addDeviceConfigs] = useAddDeviceConfigsMutation();

  const enabledTemplates = useMemo(() => {
    return (templateData ?? []).filter(
      template => template.configurationTemplate.status === 'ENABLED'
    );
  }, [templateData]);

  const updateGpioConfigs = () => {
    const diginIODefinition = diginFormConfig.find(cfgField => cfgField.type === 'io');
    const digoutIODefinition = digoutFormConfig.find(cfgField => cfgField.type === 'io');

    let templateConfigs = Object.values(currentTemplate).reduce((prev, template) => {
      const configWithScope =
        template?.configurations?.map(config => ({
          ...config,
          scope: template.scope
        })) ?? [];
      return [...prev, ...configWithScope];
    }, []);
    const deviceDirectConfigs = deviceConfigs.map(config => ({
      ...config,
      service: {
        id: config.serviceId
      },
      scope: Scope.DEVICE_DIRECT
    }));
    templateConfigs = mergeConfigurations([...templateConfigs, ...deviceDirectConfigs]);

    const configurations = sortBy(
      enrichWithExtraServiceConfig(
        templateConfigs,
        serviceMap,
        diginIODefinition,
        digoutIODefinition,
        channelTypes,
        localization
      ),
      'channel'
    );

    const sst = templateConfigs.find(config => config.key === 'seatbelt.speed.threshold')?.value;
    if (sst) {
      setSeatbeltSpeedThreshold(parseInt(sst, 10));
    }

    setGpioList(configurations);
  };

  const getChannels = channelType => {
    const digoutChannelSize = vt202OutputFeature ? 5 : 4;
    let channelSize = channelTypes.find(c => channelType === c.form + '.' + c.key)?.channels;
    if (!channelSize) {
      if (channelType?.startsWith('digout.')) {
        channelSize = digoutChannelSize;
      } else {
        channelSize = 9;
      }
    }
    const channels = Array.from({ length: channelSize }, (_, index) => ({
      key: `${index + 1}`,
      value: `${index + 1}`
    })).filter(o => {
      const index = gpioList.findIndex(i => i.channel === o.value && i.input === channelType);
      return index < 0 || index === gpioFormState.editIndex;
    });
    return channels;
  };

  const onEditItem = data => {
    setGpioFormState({ editIndex: gpioList.indexOf(data), editConfig: data, open: true });
  };

  const onDeleteItem = data => {
    setGpioList(gpioList.filter(item => item !== data));
    setIsFormDirty(true);
  };

  const onDeviceLevelTemplateChange = templateId => {
    setIsFormDirty(true);
    const currentDeviceTemplate = enabledTemplates.find(
      template => template.configurationTemplate.id === templateId
    ).configurationTemplate;
    setCurrentTemplate({ [Scope.DEVICE]: currentDeviceTemplate });
  };

  const confirmUnsavedChange = (warningMessage, onOk) => {
    confirmationModal(
      t('RouteGuard.Title'),
      warningMessage,
      t('Common.Modal.Confirm'),
      t('Common.Modal.Cancel'),
      onOk,
      () => {}
    );
  };

  const onOverrideSettingsChange = override => {
    if (!override) {
      if (isFormDirty) {
        confirmUnsavedChange(
          t('CompanyConfig.DeviceConfigurations.GPIOConfig.Settings.OverrideWarning'),
          () => {
            setOverrideSettings(false);
            initialAssignedTemplate();
            setIsFormDirty(false);
          }
        );
      } else {
        setOverrideSettings(false);
        initialAssignedTemplate();
      }
    } else {
      setOverrideSettings(true);
      form.setFieldsValue({ deviceTemplateId: null });
    }
  };

  const onCancel = () => {
    if (isFormDirty) {
      confirmUnsavedChange(t('Common.Modal.SureQuestionDiscardChanges'), () => {
        onClose();
      });
    } else {
      onClose();
    }
  };

  const onSeatbeltThresholdChange = value => {
    setSeatbeltSpeedThreshold(value);
    setIsFormDirty(true);
  };

  const validateGpioConfig = values => {
    let foundIndex = gpioList.findIndex(
      (item, currentIndex) =>
        item.input === values.input &&
        item.channel === values.channel &&
        currentIndex !== gpioFormState.editIndex
    );
    if (foundIndex > -1) {
      throw new Error(t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Channel Already Exists'));
    }
    if (values.io === 'siren') {
      foundIndex = gpioList.findIndex(
        (item, currentIndex) =>
          item.input === values.input &&
          item.io === 'siren' &&
          currentIndex !== gpioFormState.editIndex
      );
      if (foundIndex > -1) {
        throw new Error(
          t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Siren Output Channel Already Exists')
        );
      }
    }
    if (values.io === 'led') {
      foundIndex = gpioList.findIndex(
        (item, currentIndex) =>
          item.input === values.input &&
          item.io === 'led' &&
          currentIndex !== gpioFormState.editIndex
      );
      if (foundIndex > -1) {
        throw new Error(
          t('CompanyConfig.DeviceConfigurations.GPIOTemplates.LED Output Channel Already Exists')
        );
      }
    }
  };

  const onGpioConfigSave = () => {
    try {
      validateGpioConfig(configForm.getFieldsValue());
      gpioFormRef.current?.setErrorMessage(undefined);
    } catch (error) {
      gpioFormRef.current?.setErrorMessage(error.message);
      return;
    }

    configForm.validateFields().then(values => {
      if (gpioFormState.editIndex > -1) {
        const cloned = [...gpioList];
        cloned[gpioFormState.editIndex] = values;
        setGpioList(cloned);
      } else {
        setGpioList([...gpioList, values]);
      }

      setIsFormDirty(true);
      setGpioFormState({
        editIndex: -1,
        editConfig: {},
        open: false
      });
      configForm.resetFields();
    });
  };

  const onGpioConfigCancel = () => {
    setGpioFormState({
      editIndex: -1,
      editConfig: {},
      open: false
    });
  };

  const onPushConfig = () => {
    const gpioService = data.services?.find(service => service === services.GPIO);
    if (gpioService) {
      const payload = {
        deviceId: data.id,
        service: services.GPIO
      };

      executeUpdateConfig(payload, updateConfigs, dispatch);
    }
  };

  const generateConfigs = () => {
    let gpioConfigs = generateGpioConfigs(
      localization,
      gpioList,
      serviceMap,
      diginFormConfig,
      digoutFormConfig,
      isVPMEnabled
    );

    gpioConfigs = gpioConfigs.map(config => ({
      ...config,
      device: {
        id: data.id
      },
      service: config.service.code
    }));

    gpioConfigs.push({
      device: {
        id: data.id
      },
      service: 'GPIO',
      key: 'seatbelt.speed.threshold',
      value: localization.convertSpeedWithUnit(
        Number(seatbeltSpeedThreshold),
        UNITS.KM,
        localization?.formats?.speed?.unit || UNITS.KM,
        0
      )
    });

    return gpioConfigs;
  };

  const onSaveSettings = async () => {
    setIsSaving(true);
    try {
      if (!overrideSettings) {
        const selectedDeviceTemplateId = form.getFieldValue('deviceTemplateId');
        if (!inheritedTemplates.some(template => template.id === selectedDeviceTemplateId)) {
          await updateDeviceAssociatedTemplate({
            deviceId: data.id,
            templateId: selectedDeviceTemplateId
          }).unwrap();

          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('CompanyConfig.DeviceConfigurations.GPIOConfig.Success')
            })
          );
        }
      } else {
        const deviceDirectConfigsNeedToDelete = deviceConfigs.filter(
          config => !gpioList.some(item => item.id === config.id && item.value === config.value)
        );

        if (deviceDirectConfigsNeedToDelete.length > 0) {
          await deleteDeviceConfigs({ configs: deviceDirectConfigsNeedToDelete }).unwrap();
        }

        await updateDeviceAssociatedTemplate({
          deviceId: data.id
        }).unwrap();

        await addDeviceConfigs({ configs: generateConfigs() }).unwrap();

        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
          })
        );
      }
      dispatch(ngDeviceApi.util.invalidateTags(['deviceTemplates']));
      onClose();
    } catch (error) {
      setIsSaving(false);
      const errorMessage =
        typeof error === 'string' ? error : error.data?.message || JSON.stringify(error);

      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(errorMessage)}`
        })
      );
    }
  };

  const initialAssignedTemplate = () => {
    [Scope.COMPANY, Scope.FLEET, Scope.DEVICE].forEach(scope => {
      const inheritTemplate = inheritedTemplates.find(template => template.scope === scope);
      setCurrentTemplate({
        [scope]: enabledTemplates.find(
          template => template.configurationTemplate.id === inheritTemplate?.id
        )?.configurationTemplate
      });

      if (scope === Scope.DEVICE) {
        form.setFieldsValue({
          deviceTemplateId: inheritTemplate?.id
        });
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      configForm.validateFields().then(
        () => {
          setIsGpioConfigValid(true);
        },
        e => {
          setIsGpioConfigValid(false);
        }
      );
    }, 0);
  }, [Form.useWatch([], configForm)]);

  useEffect(() => {
    if (!isFetching) {
      initialAssignedTemplate();
    }
  }, [isFetching]);

  useEffect(() => {
    setDiginData(gpioList.filter(cfg => cfg.input.startsWith('digin.')));
    setDigoutData(gpioList.filter(cfg => cfg.input.startsWith('digout.')));
  }, [gpioList]);

  useEffect(() => {
    if (!isFetching) {
      updateGpioConfigs();
    }
  }, [currentTemplate, isFetching]);

  const inGPIOData = toTableStructure(channelTypes, diginData);
  const outGPIOData = toTableStructure(channelTypes, digoutData);

  const { inputColumns, outputColumns } = useMemo(() => {
    return {
      inputColumns: getInputColumns(t),
      outputColumns: getOutputColumns(t)
    };
  }, [t]);

  return isFetching ? (
    <div className={styles.gridContainer}>
      <Spin size="large" />
    </div>
  ) : (
    <div className={styles.gpioConfig}>
      <Form form={form} layout="vertical">
        <Card styles={{ body: { borderRadius: '8px' } }}>
          <h4 className={styles.sectionHeader}>
            {t('CompanyConfig.DeviceConfigurations.View.Template')}
          </h4>
          <Alert
            message={t('Devices.DeviceConfigurations.TemplateLevelAlert')}
            type="info"
            showIcon
          />
          <div className={styles.flexRow}>
            <Form.Item
              label={t('Devices.DeviceConfigurations.FleetTemplate')}
              name="fleetTemplateId"
              className={styles.formItem}
            >
              <Select
                disabled={true}
                placeholder={
                  currentTemplate[Scope.FLEET]?.name || t('Devices.DeviceConfigurations.None')
                }
              />
            </Form.Item>
            <Form.Item
              label={t('Devices.DeviceConfigurations.DeviceTemplate')}
              name="deviceTemplateId"
              className={styles.formItem}
            >
              <Select
                disabled={overrideSettings}
                placeholder={t('Devices.DeviceConfigurations.None')}
                options={enabledTemplates
                  .filter(template => template.configurationTemplate.scope === Scope.DEVICE)
                  .map(template => ({
                    label: template?.configurationTemplate?.name,
                    value: template?.configurationTemplate?.id
                  }))}
                onChange={onDeviceLevelTemplateChange}
              />
            </Form.Item>
          </div>
        </Card>
      </Form>

      {gpioFormState.open ? (
        <Card styles={{ body: { borderRadius: '8px' } }}>
          <h4 className={styles.sectionHeader}>
            {!!gpioFormState.editConfig.input
              ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Edit Config')
              : t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Add Config')}
          </h4>
          <GPIOConfigForm
            ref={gpioFormRef}
            form={configForm}
            values={gpioFormState.editConfig}
            diginForm={diginFormConfig}
            digoutForm={digoutFormConfig.concat(extraConfigs)}
            channelTypes={channelTypes}
            getChannels={getChannels}
          />
          <div className={cn(styles.flexRow, styles.sectionFooter)}>
            <Button
              type="primary"
              size="large"
              onClick={onGpioConfigSave}
              disabled={!isGpioConfigValid}
            >
              {t('Common.SaveButton')}
            </Button>
            <Button size="large" onClick={onGpioConfigCancel}>
              {t('Common.CancelButton')}
            </Button>
          </div>
        </Card>
      ) : (
        <>
          <Card styles={{ body: { borderRadius: '8px' } }}>
            <div className={styles.verticalFlex}>
              <div className={styles.spaceBetween}>
                <h4 className={styles.sectionHeader}>
                  {t('Devices.DeviceConfigurations.Settings')}
                </h4>
                <div>
                  <Switch
                    checked={overrideSettings}
                    onChange={onOverrideSettingsChange}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                  <span className={styles.switchLabel}>
                    {t('Devices.DeviceConfigurations.OverrideSettings')}
                  </span>
                </div>
              </div>
              {overrideSettings && (
                <Alert
                  type="info"
                  showIcon
                  message={t('Devices.DeviceConfigurations.OverrideSettingsAlert')}
                />
              )}
              {overrideSettings && (
                <div className={styles.flexEnd}>
                  <Button icon={<PlusOutlined />} onClick={() => setGpioFormState({ open: true })}>
                    {t('Devices.DeviceConfigurations.AddConfig')}
                  </Button>
                </div>
              )}
              <AdditionalConfigCard
                items={[
                  {
                    label: (
                      <div className={styles.spaceBetween}>
                        <div className={styles.collapseHeader}>
                          {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AdditionalSettings')}
                        </div>
                        {!overrideSettings && (
                          <Tooltip
                            title={t(
                              `CompanyConfig.DeviceConfigurations.GPIOConfig.Settings.Tooltip.${startCase(
                                lowerCase(Scope.DEVICE_DIRECT)
                              )}`
                            )}
                          >
                            <div className={styles.scope}>{`${startCase(
                              lowerCase(Scope.DEVICE_DIRECT)
                            )} Settings`}</div>
                          </Tooltip>
                        )}
                      </div>
                    ),
                    key: 'seatbelt.speed.threshold',
                    children: (
                      <div className={styles.verticalFlex}>
                        <div className={styles.channelHeader}>
                          {t(
                            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Seatbelt Speed Threshold'
                          )}
                        </div>
                        <Form layout="vertical" form={configForm}>
                          <Form.Item
                            rules={inputValidator().number}
                            initialValue={seatbeltSpeedThreshold}
                            name="seatbelt.speed.threshold"
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              precision={0}
                              addonAfter={localization.formats.speed.unit_per_hour}
                              disabled={!overrideSettings}
                              value={seatbeltSpeedThreshold}
                              onChange={onSeatbeltThresholdChange}
                            />
                          </Form.Item>
                        </Form>
                      </div>
                    )
                  }
                ]}
              />
              {inGPIOData.map(config => (
                <ConfigCard
                  key={currentTemplate.id + config.key}
                  config={config}
                  columns={inputColumns}
                  allowEditing={overrideSettings}
                  onEdit={onEditItem}
                  onDelete={onDeleteItem}
                />
              ))}
              {outGPIOData.map(config => (
                <ConfigCard
                  key={currentTemplate.id + config.key}
                  config={config}
                  columns={outputColumns}
                  allowEditing={overrideSettings}
                  onEdit={onEditItem}
                  onDelete={onDeleteItem}
                />
              ))}
            </div>
          </Card>
          <Card className={styles.stickyFooter}>
            <Button
              type="primary"
              size="large"
              id={BUTTON_IDS.configureFormSave}
              loading={isSaving}
              onClick={onSaveSettings}
              disabled={!isFormDirty || !isGpioConfigValid}
            >
              {t('Common.SaveButton')}
            </Button>
            <Button size="large" id={BUTTON_IDS.configureFormCancel} onClick={onCancel}>
              {t('Common.CancelButton')}
            </Button>
            <Button
              className={styles.flexEndItem}
              size="large"
              id={BUTTON_IDS.configureFormPush}
              onClick={onPushConfig}
            >
              {t('Common.PushButton')}
            </Button>
          </Card>
        </>
      )}
    </div>
  );
};
