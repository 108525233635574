import { LoadingTable } from 'components/grid/LoadingTable';
import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  associationsCellRenderer,
  templateCache as cache,
  ellipsisButtonCellRenderer,
  nameCellRenderer,
  sortableHeaderRenderer,
  configCellRenderer
} from '../components/CellRenderers';
import { GlobalRoles, useCan } from 'features/permissions';
import { useTranslation } from 'react-i18next';
import { useUser } from 'features/user/userSlice';
import { templateTypeEnum } from '../components/constants';
import { useLocalization } from 'features/localization/localizationSlice';
import services from 'features/permissions/services';

export const TemplatesTable = ({
  templates,
  isLoading,
  filterTab,
  handleDeleteAction,
  typeOfEntityToDelete,
  handleAction,
  handleSort,
  sortBy,
  sortDirection,
  templateType
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;
  const can = useCan();
  const canControlAssetsConfiguration = can({
    oneOfCompanyServices: [services.ASSETSUSAGE]
  });

  if (isLoading) return <LoadingTable columnSizes={[200, 300]} />;
  const showDelete = filterTab === 'all' && (isSiteAdmin || canControlAssetsConfiguration);
  const hideEdit = filterTab === 'deleted';
  const hideCopy = filterTab === 'deleted';
  const enableRestoreAction =
    filterTab === 'deleted' && (isSiteAdmin || canControlAssetsConfiguration);

  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 300,
          associations: 300,
          companyLevel: 240,
          assetselection: 240,
          ellipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          ((filterTab !== 'deleted' ? fixedColumnsWidth.name : 0) +
            fixedColumnsWidth.ellipsisButton);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={templates.length}
            rowGetter={({ index }) => templates[index]}
            rowStyle={{ alignItems: 'flex-start' }}
          >
            <Column
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Name')}
              dataKey="name"
              width={filterTab !== 'deleted' ? fixedColumnsWidth.name : dynamicColumnWidth}
              cellRenderer={props => nameCellRenderer({ ...props, t, templateType })}
              headerRenderer={() =>
                sortableHeaderRenderer({
                  label: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Name'),
                  name: 'name',
                  sortBy,
                  sortDirection,
                  handleSort
                })
              }
            />
            {filterTab !== 'deleted' && (
              <Column
                label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.CompanyTemplate')}
                dataKey="default"
                width={fixedColumnsWidth.companyLevel}
                cellRenderer={props => (
                  <div>{props.rowData.default ? t('Common.Yes') : t('Common.No')}</div>
                )}
              />
            )}
            {filterTab !== 'deleted' && (
              <Column
                label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.Associations')}
                dataKey="associations"
                width={fixedColumnsWidth.associations}
                cellRenderer={props => associationsCellRenderer({ ...props, t })}
              />
            )}

            {filterTab !== 'deleted' && (
              <Column
                label={t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetSelection')}
                dataKey="asset.selection"
                width={fixedColumnsWidth.assetselection}
                cellRenderer={configCellRenderer('asset.selection', t, localization)}
                headerRenderer={() =>
                  sortableHeaderRenderer({
                    label: t('CompanyConfig.DeviceConfigurations.AssetsTemplates.AssetSelection'),
                    name: 'assetselection',
                    sortBy,
                    sortDirection,
                    handleSort
                  })
                }
              />
            )}

            <Column
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.ActionsLabel')}
              dataKey=""
              width={fixedColumnsWidth.ellipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({
                  ...props,
                  handleDeleteAction,
                  typeOfEntityToDelete,
                  hideEdit,
                  showDelete,
                  hideCopy,
                  enableRestoreAction,
                  handleAction,
                  isSiteAdmin,
                  templateType: templateTypeEnum.Assets
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
