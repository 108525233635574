import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useDevices, useVehicles } from 'features/fleets/fleetsSlice';
import { useDeletedDevices } from 'features/devices/devicesDeletedSlice';
import { useCompanies } from 'features/company/companySlice';
import { useCan, entities } from 'features/permissions';
import { PATHS as DevicePath } from 'containers/Administration/Devices/constants';
import { PATHS as VehiclePath } from 'containers/Administration/Vehicles/constants';
import { PATHS as UserPath } from 'containers/Administration/Users/constants';
import { BULK_EDIT_AUDIT_ENTITY } from '../../helpers';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

import { EllipsisContent } from './EllipsisContent';
import { useDeletedVehicles } from 'features/vehicles/vehiclesDeletedSlice';
import { useDeletedUsers, useUsers } from 'features/users/usersSlice';

export const EntityListCell = ({ entity, entities = [] }) => (
  <EllipsisContent>
    {entities.map(entityData => (
      <EntityName
        key={entityData.id}
        entity={entity}
        entityId={entityData.id}
        entityName={entityData.name}
        entityCompanyId={entityData.companyId}
      />
    ))}
  </EllipsisContent>
);

const EntityName = ({ entity, entityId, entityName, entityCompanyId }) => {
  switch (entity) {
    case BULK_EDIT_AUDIT_ENTITY.DEVICE:
    case AUDIT_ENTITY.CAMERA_VEHICLE_DEVICE_ASSOCIATION:
      return (
        <DeviceName deviceId={entityId} deviceName={entityName} deviceCompanyId={entityCompanyId} />
      );

    case BULK_EDIT_AUDIT_ENTITY.VEHICLE:
      return (
        <VehicleName
          vehicleId={entityId}
          vehicleName={entityName}
          vehicleCompanyId={entityCompanyId}
        />
      );

    case BULK_EDIT_AUDIT_ENTITY.USER:
      return <UserName userId={entityId} userName={entityName} userCompanyId={entityCompanyId} />;
    default:
      return null;
  }
};

const DeviceName = ({ deviceId, deviceName, deviceCompanyId }) => {
  const { t } = useTranslation();
  const can = useCan();
  const canViewDevice = can({ oneOfEntities: [entities.DEVICE_VIEW] });
  const devices = useDevices();
  const deletedDevices = useDeletedDevices();
  const companies = useCompanies();
  const { canView, name, id, companyName } = useMemo(() => {
    const deviceFromCurrentCompany = [...devices, ...deletedDevices].find(
      d => String(d.id) === String(deviceId)
    );
    const companyName = companies.find(comp => String(comp.id) === String(deviceCompanyId))?.name;
    const id = deviceFromCurrentCompany?.id || deviceId;
    return {
      canView: deviceFromCurrentCompany?.id && canViewDevice,
      id,
      name: deviceFromCurrentCompany?.name || deviceName || (id ? `id : ${id}` : ''),
      companyName: companyName
        ? `${t('Devices.Company')} : ${companyName}`
        : deviceCompanyId
        ? `${t('Devices.Company')} id : ${deviceCompanyId}`
        : ''
    };
  }, [t, devices, deletedDevices, deviceId, deviceName, deviceCompanyId, companies, canViewDevice]);
  return (
    <div>
      {canView ? (
        <Link to={`${DevicePath.VIEW_DEVICE}/${id}`}>{name}</Link>
      ) : (
        <Tooltip title={companyName}>{name}</Tooltip>
      )}
    </div>
  );
};

const VehicleName = ({ vehicleId, vehicleName, vehicleCompanyId }) => {
  const { t } = useTranslation();
  const can = useCan();
  const canViewVehicle = can({ oneOfEntities: [entities.VEHICLE_VIEW] });
  const vehicles = useVehicles();
  const deletedVehicles = useDeletedVehicles();
  const companies = useCompanies();
  const { canView, name, id, companyName } = useMemo(() => {
    const vehicleFromCurrentCompany = [...vehicles, ...deletedVehicles].find(
      d => String(d.id) === String(vehicleId)
    );
    const companyName = companies.find(comp => String(comp.id) === String(vehicleCompanyId))?.name;
    const id = vehicleFromCurrentCompany?.id || vehicleId;
    return {
      canView: vehicleFromCurrentCompany?.id && canViewVehicle,
      id,
      name: vehicleFromCurrentCompany?.name || vehicleName || (id ? `id : ${id}` : ''),
      companyName: companyName
        ? `${t('Vehicles.Company')} : ${companyName}`
        : vehicleCompanyId
        ? `${t('Vehicles.Company')} id : ${vehicleCompanyId}`
        : ''
    };
  }, [
    t,
    vehicles,
    deletedVehicles,
    vehicleId,
    vehicleName,
    vehicleCompanyId,
    companies,
    canViewVehicle
  ]);
  return (
    <div>
      {canView ? (
        <Link to={`${VehiclePath.VEHICLE_VIEW}/${id}`}>{name}</Link>
      ) : (
        <Tooltip title={companyName}>{name}</Tooltip>
      )}
    </div>
  );
};

const UserName = ({ userId, userName, userCompanyId }) => {
  const { t } = useTranslation();
  const can = useCan();
  const canViewUser = can({ oneOfEntities: [entities.USER_VIEW] });
  const users = useUsers();
  const deletedUsers = useDeletedUsers();
  const companies = useCompanies();

  const { canView, name, id, companyName } = useMemo(() => {
    const userFromCurrentCompany = [...users, ...deletedUsers].find(
      user => String(user.id) === String(userId)
    );

    const companyName = companies.find(comp => String(comp.id) === String(userCompanyId))?.name;
    const id = userFromCurrentCompany?.id || userId;
    return {
      canView: userFromCurrentCompany?.id && canViewUser,
      id,
      name: userFromCurrentCompany?.name || userName || (id ? `id : ${id}` : ''),
      companyName: companyName
        ? `${t('Users.View.Company')} : ${companyName}`
        : userCompanyId
        ? `${t('Users.View.Company')} id : ${userCompanyId}`
        : ''
    };
  }, [t, users, deletedUsers, userId, userName, userCompanyId, companies, canViewUser]);

  return (
    <div>
      {canView ? (
        <Link to={`${UserPath.USER_VIEW}/${id}`}>{name}</Link>
      ) : (
        <Tooltip title={companyName}>{name}</Tooltip>
      )}
    </div>
  );
};
