import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/util';

import { API_PATH } from 'config';

export const ngDeviceApi = createApi({
  reducerPath: 'ngDeviceApi',
  tagTypes: ['deviceTemplates'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getDeviceTemplate: builder.query({
      query: ({ id }) => `devices/${id}/templates`,
      providesTags: ['deviceTemplates']
    })
  })
});

export const { useGetDeviceTemplateQuery } = ngDeviceApi;
