import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Divider } from 'antd';
import { useCan, entities } from 'features/permissions';
import { useLocalization } from 'features/localization/localizationSlice';

import { ChangesByKeyTable } from './NestedTable/ChangesByKeyTable';

import { format } from 'utils/dates';
import { PATHS } from 'containers/Administration/Users/constants';
import { BULK_EDIT_AUDIT_ENTITY, CHANGE_WIDTH } from './helpers';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

import styles from './BulkEditAuditsTable.module.scss';

export const BulkEditAuditsTable = ({ entity, dataSource = [], isLoading }) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const can = useCan();
  const canViewUser = can({ oneOfEntities: [entities.USER_VIEW] });

  const getUserName = useCallback(
    user => {
      const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
      return !!user?.id && canViewUser ? (
        <Link to={`${PATHS.USER_VIEW}/${user.id}`}>{fullName}</Link>
      ) : (
        fullName
      );
    },
    [canViewUser]
  );
  const getTime = useCallback(
    time =>
      time && localization
        ? format(new Date(time), localization.formats.time.formats.dby_imsp)
        : '',
    [localization]
  );
  const getAction = useCallback(action => (action ? t(`Audits.${action}`) : ''), [t]);

  const { showPagination, paginationHeight } = useMemo(() => {
    const showPagination =
      (dataSource?.length > 100 && { pageSize: 100, showSizeChanger: false }) || false;
    return {
      showPagination,
      paginationHeight: (showPagination && 55) || 0
    };
  }, [dataSource]);

  return (
    <Table
      dataSource={dataSource}
      pagination={showPagination}
      loading={isLoading}
      sticky
      size="small"
      scroll={{ y: `calc(100vh - 100px - ${paginationHeight}px)` }}
      rowClassName={() => styles.tableRow}
      rowKey={record => String(record.id)}
    >
      <Table.Column title={t('Audits.Time')} dataIndex="createdAt" width="15%" render={getTime} />
      <Table.Column title={t('Audits.Action')} dataIndex="action" width="10%" render={getAction} />
      <Table.Column title={t('Audits.User')} dataIndex="user" width="10%" render={getUserName} />
      <Table.Column
        className={styles.changesCol}
        title={<ChangesTableHeader t={t} entity={entity} />}
        dataIndex="changes"
        render={(changes, audit) => (
          <Table
            dataSource={changes}
            pagination={false}
            showHeader={false}
            className={styles.nestedTable}
          >
            <Table.Column
              title={t('Audits.Key')}
              dataIndex="title"
              width="20%"
              className={styles.keyCol}
              ellipsis
              render={value => t(value)}
            />
            <Table.Column
              title={t('Audits.Changes')}
              dataIndex="changes"
              render={(changesByKey, record) => (
                <ChangesByKeyTable
                  changeKey={`${audit.id}-${record.key}`}
                  changesByKey={changesByKey}
                  entity={entity}
                />
              )}
            />
          </Table>
        )}
      />
    </Table>
  );
};

const ChangesTableHeader = ({ t, entity }) => {
  const entityHeader = useMemo(() => {
    switch (entity) {
      case BULK_EDIT_AUDIT_ENTITY.DEVICE:
      case AUDIT_ENTITY.CAMERA_VEHICLE_DEVICE_ASSOCIATION:
        return t('Devices.Devices');
      case BULK_EDIT_AUDIT_ENTITY.VEHICLE:
        return t('Vehicles.Vehicles');
      case BULK_EDIT_AUDIT_ENTITY.USER:
        return t('Users.Users');
      default:
        return null;
    }
  }, [t, entity]);

  return (
    <div className={styles.changesHeader}>
      <div key="headerChanges">{t('Audits.Changes')}</div>
      <Divider style={{ margin: '8px 0' }} />
      <div key="headerChangesDetails" className={styles.changesSubHeader}>
        <div key="headerKey" style={{ width: CHANGE_WIDTH.KEY }}>
          {t('Audits.Key')}
        </div>
        <div key="headerFrom" style={{ width: CHANGE_WIDTH.FROM }}>
          {t('Audits.From')}
        </div>
        <div key="headerTo" style={{ width: CHANGE_WIDTH.TO }}>
          {t('Audits.To')}
        </div>
        <div key="headerEntity" style={{ width: CHANGE_WIDTH.ENTITY }}>
          {entityHeader}
        </div>
      </div>
    </div>
  );
};
