import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import { useUserKey } from 'features/user/userSlice';
import request from 'superagent';
import { deviceTypesDataAdapter } from 'data/dataService/tn/adapter/deviceTypes';

const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

export const deviceTypesSlice = createSlice({
  name: 'device_types',
  initialState: {
    data: {},
    list: [],
    status: {
      fetching: actionTypes.init,
      error: null
    }
  },
  reducers: {
    fetchDeviceTypesStart(state) {
      state.status.fetching = actionTypes.processing;
      state.status.error = null;
    },
    fetchDeviceTypesSucceeded(state, { payload }) {
      if (payload != null && payload.length) {
        let deviceTypes = {};
        payload.forEach(i => (deviceTypes[i.name] = i));
        state.data = deviceTypes;
        state.list = payload;
      } else {
        state.data = payload;
        state.list = payload;
      }

      state.status.fetching = actionTypes.done;
    },
    fetchDeviceTypesFailed(state, { payload }) {
      state.status.error = payload;
      state.status.fetching = actionTypes.done;
    }
  }
});

export const {
  fetchDeviceTypesStart,
  fetchDeviceTypesSucceeded,
  fetchDeviceTypesFailed
} = deviceTypesSlice.actions;

export const fetchDeviceTypes = userKey => async dispatch => {
  dispatch(fetchDeviceTypesStart());

  const promise = new Promise((resolve, reject) => {
    deviceTypesDataAdapter.load().then(deviceTypes => {
      if (deviceTypes?.length > 0) {
        resolve(deviceTypes);
      } else {
        request('GET', `${API_PATH}/devices/types?pruning=APP`)
          .set('Authorization', `Token token="${userKey}"`)
          .set('Content-Type', 'application/json')
          .then(resp => {
            deviceTypesDataAdapter.persist(resp.body);
            resolve(resp.body);
          })
          .catch(err => {
            reject(err);
          });
      }
    });
  });

  try {
    const data = await promise;
    dispatch(fetchDeviceTypesSucceeded(data));
  } catch (err) {
    console.log(err);
    dispatch(fetchDeviceTypesFailed(err));
  }
};

export const useDeviceTypes = () => useSelector(state => state.deviceTypes);
export const useDeviceTypesList = () => {
  const userKey = useUserKey();
  const dispatch = useDispatch();
  const deviceTypes = useSelector(state => state.deviceTypes.list);
  const fetching = useSelector(state => state.deviceTypes.status.fetching);
  if (deviceTypes.length === 0 && fetching !== actionTypes.processing) {
    dispatch(fetchDeviceTypes(userKey));
  }
  return deviceTypes;
};

export default deviceTypesSlice.reducer;
