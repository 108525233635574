import { result } from 'lodash';
import { TNDataService } from '../tnDataService';
import { devicesDataAdapter } from './devices';
import { reduxAdapter } from './reduxAdapter';
import { vehiclesDataAdapter } from './vehicles';
import { carriersDataAdapter } from './carriers';

const fleetsDataAdapter = reduxAdapter({
  persist: async fleets => {
    if (fleets && fleets.length) {
      const fleetsToAdd = [];
      const fvs = [];
      const fds = [];
      const carriers = {};
      for (const f of fleets) {
        if (f.id) {
          await TNDataService.deleteFleetVehicleAssociation(f.id);
          await TNDataService.deleteFleetDeviceAssociation(f.id);
          //await TNDataService.insertOrUpdateFleet(f);
          fleetsToAdd.push(f);
          if (f.vehicles?.length > 0) {
            //await vehiclesDataAdapter.persist(f.vehicles);
            for (const v of f.vehicles) {
              if (v.id) {
                const fv = {
                  fleetId: f.id,
                  vehicleId: v.id
                };
                fvs.push(fv);
              } else if (v.devices?.length > 0) {
                //await devicesDataAdapter.persist(v.devices);
                for (const d of v.devices) {
                  if (d.id) {
                    const fd = {
                      fleetId: f.id,
                      deviceId: d.id
                    };
                    fds.push(fd);
                  }
                }
              }
            }
            //await TNDataService.insertFleetVehicleAssociationsInBulk(fvs);
          }

          if (f.devices?.length > 0) {
            //await devicesDataAdapter.persist(f.devices);
            for (const d of f.devices) {
              if (d.id) {
                const fd = {
                  fleetId: f.id,
                  deviceId: d.id
                };
                fds.push(fd);
              }
            }
            //await TNDataService.insertFleetDeviceAssociationsInBulk(fds);
          }

          if (f.carrier) {
            carriers[f.carrierId] = f.carrier;
          }
        } else {
          //no fleet insert
          fleetsToAdd.push({
            id: -1,
            name: 'No Fleet',
            version: 1,
            path: '/',
            company: {
              id: 1
            }
          });
          /*
          await TNDataService.insertOrUpdateFleet({
            id: -1,
            name: 'No Fleet',
            version: 1,
            path: '/',
            company: {
              id: 1
            }
          });

          //standalone vehicles and devices
          if (f.vehicles?.length) {
            await vehiclesDataAdapter.persist(f.vehicles);
            if (!f.vehicles[f.vehicles.length - 1].id) {
              await devicesDataAdapter.persist(f.vehicles[f.vehicles.length - 1].devices);
            }
          }*/
        }
      }

      if (fleetsToAdd.length > 0) {
        await TNDataService.insertOrUpdateFleetInBulk(fleetsToAdd);
      }
      if (fds.length > 0) {
        await TNDataService.insertFleetDeviceAssociationsInBulk(fds);
      }
      if (fvs.length > 0) {
        await TNDataService.insertFleetVehicleAssociationsInBulk(fvs);
      }
      if (Object.keys(carriers).length > 0) {
        await carriersDataAdapter.persist(Object.values(carriers));
      }
    }
  },
  load: async (loadNoFleet = false) => {
    const startTime = performance.now();
    const fleets = await TNDataService.getAllFleets(loadNoFleet);
    const vehicles = await vehiclesDataAdapter.load(true);
    const devices = await devicesDataAdapter.load(true);
    const fleetsVehicles = await TNDataService.getFleetsVehiclesAssociaitons();
    const fleetsDevices = await TNDataService.getFleetsDevicesAssociations();
    const carriers = await carriersDataAdapter.load();
    const normalFleets = [];
    const deletedFleets = [];

    if (fleets?.length > 0 || vehicles?.length > 0 || devices?.length > 0) {
      const vehicleDict = vehicles.reduce((dict, v) => {
        dict[v.id] = v;
        return dict;
      }, {});

      const deviceDict = devices.reduce((dict, d) => {
        dict[d.id] = d;
        if (d.vehicle?.id && vehicleDict[d.vehicle.id]) {
          const v = vehicleDict[d.vehicle.id];
          d.vehicle = { ...v, devices: null };
          if (!v.devices) {
            v.devices = [];
          }
          v.devices.push({ ...d });
        }
        return dict;
      }, {});

      const fleetsAssociationDict = {};
      const vehiclesWithFleets = new Set();
      const devicesWithFleets = new Set();

      fleetsVehicles.forEach(fv => {
        if (!fleetsAssociationDict[fv.fleetId]) {
          fleetsAssociationDict[fv.fleetId] = {
            vehicles: [],
            devices: []
          };
        }
        if (vehicleDict[fv.vehicleId] && vehicleDict[fv.vehicleId].status === 'ENABLED') {
          fleetsAssociationDict[fv.fleetId].vehicles.push(vehicleDict[fv.vehicleId]);
        }

        vehiclesWithFleets.add(fv.vehicleId);
      });

      fleetsDevices.forEach(fd => {
        if (!fleetsAssociationDict[fd.fleetId]) {
          fleetsAssociationDict[fd.fleetId] = {
            vehicles: [],
            devices: []
          };
        }
        if (
          deviceDict[fd.deviceId] &&
          deviceDict[fd.deviceId].status === 'ENABLED' &&
          !deviceDict[fd.deviceId]?.vehicle?.id
        ) {
          fleetsAssociationDict[fd.fleetId].devices.push(deviceDict[fd.deviceId]);
        }

        devicesWithFleets.add(fd.deviceId);
      });

      for (const f of fleets) {
        if (f.status === 'E' || f.status === 'ENABLED') {
          normalFleets.push(f);
        } else {
          deletedFleets.push(f);
        }

        //associate vehicles
        f.vehicles = fleetsAssociationDict[f.id]?.vehicles || [];

        //associate standalone devices
        f.devices = fleetsAssociationDict[f.id]?.devices || [];

        //put devices into vehicles array
        f.vehicles.push({
          devices: Array.from(f.devices)
        });

        //check carrier
        if (f.carrierId) {
          f.carrier = carriers?.find(c => c.id === f.carrierId);
        }
      }

      //populate No Fleet Vehicles & Devices
      const noFleetVehiclesEnabled = [];
      //const noFleetVehiclesDeleted = [];
      const noFleetDevicesEnabled = [];
      //const noFleetDevicesDeleted = [];

      vehicles.forEach(v => {
        if (!vehiclesWithFleets.has(v.id)) {
          if (v.status === 'E' || v.status === 'ENABLED') {
            noFleetVehiclesEnabled.push(v);
          } /* no need deleted vehicles at the moment
                     else {
                        noFleetVehiclesDeleted.push(v);
                    }*/
        }
      });

      devices.forEach(d => {
        if (!devicesWithFleets.has(d.id)) {
          if ((d.status === 'E' || d.status === 'ENABLED') && !d.vehicle?.id) {
            noFleetDevicesEnabled.push(d);
          }
          /* else {
                        noFleetDevicesDeleted.push(d);
                    }*/
        }
      });
      noFleetVehiclesEnabled.push({
        devices: Array.from(noFleetDevicesEnabled)
      });

      normalFleets.push({
        vehicles: noFleetVehiclesEnabled,
        devices: [],
        geofences: []
      });

      deletedFleets.push({
        vehicles: noFleetVehiclesEnabled,
        devices: [],
        geofences: []
      });
    }

    const endTime = performance.now();
    console.log('Loading Done - takes time ' + (endTime - startTime));
    return {
      normalFleets,
      deletedFleets
    };
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_fleets');
    return result;
  },
  updateLastUpdatedDate: async lastpUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_fleets', lastpUpdatedDate);
    return result;
  }
});

export { fleetsDataAdapter };
