export function userObjectToDBO(user) {
  return {
    id: user.id,
    email: user.email,
    company_id: user.company.id,
    user_type_id: user.type.id,
    location_id: user.location?.id,
    first_name: user.firstName,
    last_name: user.lastName,
    username: user.username,
    mobile: user.mobile,
    path: user.path,
    version: user.version,
    status: user.status,
    site_admin: user.siteAdmin,
    time_zone: user.timeZone,
    external_id: user.externalId,
    sync_in_at: user.syncInAt,
    doc_id: user.docId,
    licence_state: user.licenceState,
    licence_number: user.licenceNumber,
    sentinel_driver_id: user.sentinelDriverId,
    sentinel_driver_code: user.sentinelDriverCode,
    created_at: user.createdAt,
    updated_at: user.updatedAt,
    sentinel_time_zone: user.sentinelTimeZone,
    sentinel: user.hasSentinel,
    sentinel_platform: user.sentinelPlatform,
    external_reference: user.externalReference,
    callable: user.callable,
    tags: user.tags,
    trgm_compilation: user.trgmCompilation,
    features: JSON.stringify(user.featureList || []),
    image_url: user.imageUrl,
    licence_country: user.licenceCountry,
    sentinel_exemption_notes: user.sentinelExemptionNotes,
    start_time_of_day: user.startTimeOfDay,
    associations: JSON.stringify(user.associations || [])
  };
}

export function userDBOToObject(user) {
  return {
    id: user.id,
    email: user.email,
    companyId: user.company_id,
    type: {
      id: user.user_type_id
    },
    location: user.location_id ? { id: user.location_id } : undefined,
    firstName: user.first_name,
    lastName: user.last_name,
    username: user.username,
    mobile: user.mobile,
    path: user.path,
    version: user.version,
    status: user.status,
    siteAdmin: user.site_admin,
    timeZone: user.time_zone,
    externalId: user.external_id,
    syncInAt: user.sync_in_at,
    docId: user.doc_id,
    licenceState: user.licence_state,
    licenceNumber: user.licence_number,
    sentinelDriverId: user.sentinel_driver_id,
    sentinelDriverCode: user.sentinel_driver_code,
    createdAt: user.created_at,
    updatedAt: user.updated_at,
    sentinelTimeZone: user.sentinel_time_zone,
    hasSentinel: user.sentinel,
    sentinelPlatform: user.sentinel_platform,
    externalReference: user.external_reference,
    callable: user.callable,
    tags: user.tags,
    trgmCompilation: user.trgm_compilation,
    featureList: JSON.parse(user.features),
    imageUrl: user.image_url,
    licenceCountry: user.licence_country,
    sentinelExemptionNotes: user.sentinel_exemption_notes,
    startTimeOfDay: user.start_time_of_day,
    associations: JSON.parse(user.associations)
  };
}

export function userRoleDBOToObject(userRole) {
  return {
    userId: userRole.user_id,
    roleId: userRole.role_id
  };
}

export function userRoleObjectToDBO(userRole) {
  return {
    user_id: userRole.userId,
    role_id: userRole.roleId
  };
}

export function userRulesetDBOToObject(userRuleset) {
  return {
    id: userRuleset.id,
    user: {
      id: userRuleset.user_id
    },
    company: {
      id: userRuleset.company_id
    },
    ruleset: userRuleset.ruleset,
    enabledAt: userRuleset.enabled_at,
    createdAt: userRuleset.created_at,
    updatedAt: userRuleset.updated_at
  };
}

export function userRulesetObjectToDBO(userRuleset) {
  return {
    id: userRuleset.id,
    user_id: userRuleset.user.id,
    company_id: userRuleset.company.id,
    ruleset: userRuleset.ruleset,
    enabled_at: userRuleset.enabledAt,
    created_at: userRuleset.createdAt,
    updated_at: userRuleset.updatedAt
  };
}
