import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Tooltip, Button, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useCan, entities } from 'features/permissions';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { BUTTON_IDS } from '../helper';
import { useUserInfo } from 'features/user/userSlice';

export const VehicleActionsCell = ({ vehicle, onDelete }) => {
  const { t } = useTranslation();

  const can = useCan();
  const currentUser = useUserInfo();
  const isSiteAdmin = currentUser.siteAdmin;
  const canEditVehicle = can({ everyEntity: [entities.VEHICLE_UPDATE] });
  const isEdrVehicle = vehicle?.devices?.some(d => d.type?.code === 'EDR');
  const canDeleteVehicle =
    can({ everyEntity: [entities.VEHICLE_DESTROY] }) && isSiteAdmin && !isEdrVehicle;

  const history = useHistory();

  const handleEdit = useCallback(() => {
    history.push(`/settings/vehicles/edit/id/${vehicle.id}`);
  }, [history, vehicle?.id]);

  const handleDelete = useCallback(() => {
    let content = `${t('Common.SureDelete')} ${t('Common.vehicle')} ${vehicle.name}?`;

    confirmationModal(
      `${t('Common.DeleteButton')} ${vehicle.name}`,
      content,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      onDelete(vehicle),
      'delete'
    );
  }, [vehicle, onDelete]);

  return (
    <Space size={4}>
      <Tooltip title={!canEditVehicle && t('Messaging.NoPermission')}>
        <Button
          disabled={!canEditVehicle}
          type="text"
          icon={<FontAwesomeIcon icon={faPen} color="#64748B" />}
          onClick={handleEdit}
          id={BUTTON_IDS.edit_single_vehicle}
        ></Button>
      </Tooltip>
      <Tooltip
        title={
          isEdrVehicle ? (
            <Trans
              i18nKey={'Vehicles.Form.DeleteEDRError'}
              components={{
                1: <span style={{ fontWeight: 900 }} />,
                2: <br />
              }}
            />
          ) : (
            !canDeleteVehicle && t('Messaging.NoPermission')
          )
        }
      >
        <Button
          disabled={!canDeleteVehicle}
          type="text"
          icon={<FontAwesomeIcon icon={faTrash} color="#FF7875" />}
          onClick={handleDelete}
          id={BUTTON_IDS.delete_single_vehicle}
        ></Button>
      </Tooltip>
    </Space>
  );
};
