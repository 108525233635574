import { Drawer } from 'antd';
import { WidgetCard } from './WidgetCard';
import { useTranslation } from 'react-i18next';
import styles from './WidgetsDrawer.module.scss';

export const WidgetsDrawer = ({ open, setOpen, widgetItems, handleAdd }) => {
  const { t } = useTranslation();

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  return (
    <Drawer
      title={t('NewHome.Drawer.Title')}
      placement="right"
      onClose={handleCloseDrawer}
      open={open}
      maskClosable={true}
    >
      <div className={styles.widgetsDrawerContainer}>
        {widgetItems.map(
          widget => !widget.isSelected && <WidgetCard widget={widget} handleAdd={handleAdd} />
        )}
      </div>
    </Drawer>
  );
};
