import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { Can } from 'features/permissions';

import { TabNavLink } from 'components/nav/NavLinks';

import { TrackingPaths, TrackingLens } from './constants';

export const Tabs = ({ isFullScreen }) => {
  const { t } = useTranslation();
  const path = window.location.pathname;

  return (
    <>
      <Can otherConditions={[() => !isFullScreen]}>
        <TabNavLink
          to={TrackingPaths.Track}
          onClick={e => {
            if (e.target.classList.contains('tn-active')) {
              e.preventDefault();
            }
          }}
          isActive={match => {
            return (
              !!match || path.startsWith(TrackingPaths.Track) || path === TrackingPaths.Default
            );
          }}
        >
          {TrackingLens(t).Track}
        </TabNavLink>
      </Can>

      <Can otherConditions={[() => !isFullScreen]}>
        <TabNavLink to={TrackingPaths.Proximity}>{TrackingLens(t).Proximity}</TabNavLink>
      </Can>

      <TabNavLink to={TrackingPaths.MapTiles}>{TrackingLens(t).MapTiles}</TabNavLink>

      <Can otherConditions={[() => !isFullScreen]}>
        <TabNavLink to={TrackingPaths.CardView}>{TrackingLens(t).CardView}</TabNavLink>
      </Can>
    </>
  );
};
