import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { Button } from 'antd';
import { Table, Layout } from 'components/ant';
import { prepareDataForMultiselect } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';

import { toLower } from 'lodash';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useEnrolments, useIsEnrolmentsFetching, useSchemes } from 'features/enrolments';
import { useCompanies, useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useDevices, useIsFetching as useIsFetchingDevices } from 'features/fleets/fleetsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { getCompanySchemes, parseEnrolment } from './EnrolmentsForm/helpers';
import { ITEM_NAMES } from './EnrolmentsForm/constants';

import { ExportEnrolmentsModal } from './ExportEnrolmentsModal';

import style from './TCAEnrolments.module.scss';
import { Paths, EnrolmentStatuses } from './constants';
import {
  prepareEnrolmentsDataForTable,
  prepareColumnsForTable,
  convertEnrolmentIDWithPrefixAndZeroPadding
} from './helpers';
import { BUTTON_IDS } from 'utils/globalConstants';

export const TCAEnrolments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();

  const enrolments = useEnrolments();
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const devices = useDevices();
  const currentCompany = useCurrentCompany();
  const schemes = useSchemes(currentCompany.id);
  const companySchemes = getCompanySchemes(schemes);

  const isEnrolmentsFetching = useIsEnrolmentsFetching();
  const isDevicesFetching = useIsFetchingDevices();
  const loading = isEnrolmentsFetching || isDevicesFetching;

  const mappedEnrolments = enrolments.map(enrolment => {
    const parsedEnrolment = parseEnrolment(enrolment);

    return {
      ...enrolment,
      company: companies.find(company => company.id === enrolment.companyId) || {},
      deviceDetails: parsedEnrolment?.deviceDetails,
      deviceName: (devices.find(device => device.id === enrolment.deviceId) || {})?.name,
      vehicleRegistration:
        parsedEnrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER],
      scheme: companySchemes?.find(scheme => scheme.id === enrolment?.scheme)
    };
  });

  const [filterText, setFilterText] = useState('');
  const [filteredEnrolments, setFilteredEnrolments] = useState(mappedEnrolments);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [filterStatuses, setFilterStatuses] = useState(
    prepareDataForMultiselect(
      [
        {
          id: EnrolmentStatuses.APPROVED,
          name: t('TCAEnrolments.Approved')
        },
        {
          id: EnrolmentStatuses.CANCELLED,
          name: t('TCAEnrolments.Cancelled')
        }
      ],
      t('TCAEnrolments.AllStatuses'),
      EnrolmentStatuses.APPROVED
    )
  );
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const debouncedSearchText = useDebounce(filterText, 300);

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('Common.TCAEnrolments')));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    const enrolmentsToFilter = mappedEnrolments;
    setFilteredEnrolments(
      enrolmentsToFilter
        .filter(enrolment => {
          let validEnrolment = true;

          // Filter by search field
          if (debouncedSearchText) {
            validEnrolment =
              validEnrolment &&
              [
                convertEnrolmentIDWithPrefixAndZeroPadding(enrolment.id),
                enrolment.scheme?.id,
                enrolment.deviceDetails?.imei,
                enrolment.vehicleRegistration
              ].some(value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1);
          }

          // Filter by companies
          const checkedCompaniesIds = filterCompanies
            .filter(company => company.checked)
            .map(company => parseInt(company.id, 10));
          if (!(checkedCompaniesIds.indexOf(0) > -1)) {
            validEnrolment =
              validEnrolment && checkedCompaniesIds.indexOf(parseInt(enrolment.companyId, 10)) > -1;
          }

          //Filter by status
          const checkedStatuses = filterStatuses
            .filter(status => status.checked)
            .map(status => status.id);
          if (!(checkedStatuses.indexOf(0) > -1)) {
            validEnrolment = validEnrolment && checkedStatuses.indexOf(enrolment.status) > -1;
          }

          return validEnrolment;
        })
        .sort((a, b) => (a.id > b.id ? 1 : -1))
    );
  }, [
    history.location.state,
    filterText,
    debouncedSearchText,
    filterCompanies,
    filterStatuses,
    enrolments,
    devices
  ]);

  const getStickyContainer = () => {
    return document.querySelector('.' + style.overviewGrid);
  };

  const onCloseExportModal = () => {
    setIsExportModalOpen(false);
  };

  return (
    <div>
      <Layout.Content>
        <div className={style.pageHeader}>
          <div className={style.enrolmentActionsContainer}>
            <Button
              type="primary"
              onClick={() => {
                history.push(Paths.ADD_NEW_ENROLMENT);
              }}
              id={BUTTON_IDS.tcaEnrolmentsNew}
            >
              {t('TCAEnrolments.NewTCAEnrolemnt')}
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                setIsExportModalOpen(true);
              }}
              id={BUTTON_IDS.tcaEnrolmentsExportReport}
            >
              {t('TCAEnrolments.ExportReport')}
            </Button>
          </div>
          <div className={style.filterWrapper}>
            <FilterWrapper>
              <AntSearchbar onFilter={value => setFilterText(value)} />
              <AntMultiselect
                title={
                  filterCompanies?.some(value => !value.checked)
                    ? t('Common.Companies')
                    : t('Common.AllCompanies')
                }
                onFilter={v => setFilterCompanies(v)}
                data={filterCompanies}
              />
              <AntMultiselect
                title={
                  filterStatuses?.some(value => !value.checked)
                    ? t('TCAEnrolments.Statuses')
                    : t('TCAEnrolments.AllStatuses')
                }
                data={filterStatuses}
                onFilter={value => {
                  setFilterStatuses(value);
                }}
              />
            </FilterWrapper>
            <div className={style.enrolmentsCounter}>
              {filteredEnrolments?.length}{' '}
              {filteredEnrolments?.length === 1
                ? `${t('TCAEnrolments.enrolment')}`
                : `${t('TCAEnrolments.enrolments')}`}
            </div>
          </div>
        </div>
        <Table
          className={style.overviewGrid}
          dataSource={prepareEnrolmentsDataForTable(filteredEnrolments, localization, t, schemes)}
          columns={prepareColumnsForTable(t)}
          pagination={false}
          loading={loading}
          sticky={{
            offsetHeader: 116,
            getContainer: getStickyContainer
          }}
          scroll={{ y: `calc(100vh - 116px)` }}
        />
        <ExportEnrolmentsModal
          visible={isExportModalOpen}
          onCloseExportModal={onCloseExportModal}
          filterStatuses={filterStatuses}
          mappedEnrolments={mappedEnrolments}
        />
      </Layout.Content>
    </div>
  );
};
