import { Typography } from 'antd';
import i18n from 'i18nextConfig';
import { Trans } from 'react-i18next';

export const BULK_TYPES = {
  GEOFENCES: 'geofences',
  LOCATIONS: 'locations',
  USERS: 'users',
  VEHICLES: 'vehicles',
  VEHICLES_EDIT: 'vehicles_edit',
  USERS_EDIT: 'users_edit',
  SDM: 'sdm',
  SDC: 'sdc'
};

export const FILE_TYPES = {
  CSV: 'csv',
  GEOJSON: 'geojson'
};

export const ALERT_STATUS_TYPE = {
  SUCCESS: 'success',
  PARTIAL: 'warning',
  FAILED: 'error'
};

export const ALERT_STATUS_MESSAGES = handleErrorFileDownload => ({
  SUCCESS: i18n.t('CompanyConfig.BulkManagement.Modal.Alerts.SuccessfulImport'),
  PARTIAL: (
    <Trans
      i18nKey={'CompanyConfig.BulkManagement.Modal.Alerts.PartialImport'}
      components={{
        1: <Typography.Link onClick={handleErrorFileDownload} />
      }}
    />
  ),
  FAILED: (
    <Trans
      i18nKey={'CompanyConfig.BulkManagement.Modal.Alerts.FailedImport'}
      components={{
        1: <Typography.Link onClick={handleErrorFileDownload} />
      }}
    />
  )
});
