import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import request from 'superagent';
import { Button, Tooltip } from 'antd';
import { Row, Col, FormCheck, FormGroup, FormLabel } from 'react-bootstrap';
import { Row as AntRow, Col as AntCol } from 'antd';
import { Formik, Form } from 'formik';
import { QuestionCircleOutlined } from '@ant-design/icons';

// components
import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import FormDatePicker from 'components/form/form-date-picker/FormDatePicker';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { DocumentsDisplay } from 'components/Documents';
import { FileUpload } from 'components/FileUpload';

// slices
import { fetchInspection } from 'features/vehicleMaintenanceInspections';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useFleets, useVehicles } from 'features/fleets/fleetsSlice';
import { useVehicleMntTypes } from 'features/vehicleMntTypes/vehicleMntTypesSlice';
import {
  refetchSchedules,
  useScheduleWithVehicleOdometer,
  attachFiles
} from 'features/vehicleMaintenance/schedulesSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useUserKey, useUser } from 'features/user/userSlice';
import { useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { useVehicleMeters } from 'features/vehicles/vehiclesMetersSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useVehicleConfig } from 'features/vehicles/vehiclesConfigsSlice';

//helpers
import { format } from 'utils/dates';
import { api } from 'utils/api';
import { getMeterValue, getMeterByType } from 'features/meters';
import { getRoundValue } from 'utils/methods';
import { renderUploadedFiles, handleFileAttachment } from '../DriverManagement/helpers';
import { renderFailedItems } from './utils/helpers';

//constants
import { API_PATH } from 'config';
import { ToastType } from 'components/notifications/toasts/Toast';
import { LocalizationUtil, DATE_FORMAT } from 'features/localization/localization';
import { initialValues, validationSchema, SCHEDULE_BY_ID_URL, Paths } from './utils/constants';
import { METER_TYPES, VehicleConfig } from '../Administration/Vehicles/constants';

//styles
import styles from './VehicleMaintenanceSchedules.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { getConfig, useConfig } from 'features/easydocs/documentsSlice';

const getVehicleFormFields = (
  vehicleId,
  vehicleMeters,
  vehicleConfig,
  vehiclesStats,
  localization
) => {
  const meter = { meters: vehicleMeters || [] }; //use vehicleMeters' data fetched and attached to each schedule in fetchSchedules
  let odometerMeter;
  if (vehicleConfig?.isUsingRUCOdometerForMnt) {
    odometerMeter = vehicleConfig?.rucDeviceMeter;
  } else {
    odometerMeter = getMeterByType(meter, METER_TYPES.ODOMETER);
  }
  const engineHoursMeter = getMeterByType(meter, METER_TYPES.HOURS);
  const odometerReading = odometerMeter
    ? localization.convertDistance(getMeterValue(odometerMeter))
    : 0;
  const engineHoursReading = engineHoursMeter
    ? getRoundValue(getMeterValue(engineHoursMeter), 1)
    : 0;
  const vehicleStats = vehiclesStats.find(
    vs => parseInt(vs.vehicleId, 10) === parseInt(vehicleId, 10)
  );
  const dateReading = vehicleStats?.lastReadingsAt
    ? format(new Date(vehicleStats.lastReadingsAt), localization.formats.time.formats.dmY)
    : '';
  return {
    entityId: vehicleId,
    odometer_reading: odometerReading,
    engineHours_reading: engineHoursReading,
    date_reading: dateReading
  };
};

const useVehicleFormFields = () => {
  const [vehicleId, setVehicleId] = useState();
  const vehicleMeters = useVehicleMeters(vehicleId);
  const vehicleConfig = useVehicleConfig(vehicleId, VehicleConfig.OdometerForMnt.value);
  const localization = useLocalization();
  const vehiclesStats = useVehiclesStats();
  const fields = useMemo(
    () =>
      getVehicleFormFields(vehicleId, vehicleMeters, vehicleConfig, vehiclesStats, localization),
    [vehicleId, vehicleMeters, vehicleConfig, vehiclesStats, localization]
  );
  return { vehicleId, setVehicleId, fields, vehiclesStats, localization };
};

export const ScheduleAddEditForm = ({ action }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const company = useCurrentCompany();
  const userKey = useUserKey();
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [files, saveFiles] = useState([]);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const _fleets = useFleets();
  const fleets = useMemo(() => {
    return _fleets.filter(fleet => fleet.id);
  }, [_fleets]);
  const initialVehicles = useVehicles();
  const types = useVehicleMntTypes();
  const [odometerBool, setOdometerBool] = useState(false);
  const [engineHoursBool, setEngineHoursBool] = useState(false);
  const [dateBool, setDateBool] = useState(false);
  const [gpioBool, setGPIOBool] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [inspectionData, setInspectionData] = useState(null);
  const uploadConfig = useConfig();
  const path = window.location.pathname;
  const scheduleId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const pathArray = path.split('/');
  const rescheduleType = pathArray[pathArray.length - 3];
  const maintenanceInfo = useLocation().state;
  const {
    setVehicleId,
    fields: initalSelectedVehicleFormFields,
    localization,
    vehiclesStats,
    vehicleId: currentVehicleId
  } = useVehicleFormFields();
  const [selectedVehicle, setSelectedVehicle] = useState({ vehicle: null, setFieldValue: null });
  const selectedVehicleMeters = useVehicleMeters(selectedVehicle.vehicle?.id);
  const selectedVehicleConfig = useVehicleConfig(
    selectedVehicle.vehicle?.id,
    VehicleConfig.OdometerForMnt.value
  );
  const [selectedGPIOmeters, setSelectedGPIOMeters] = useState(
    (selectedVehicleMeters || []).filter(
      meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio'
    )
  );
  const dateFormat = localization.formats.time.formats.dby_imp.toUpperCase().replace(':MM', ':mm');
  const [completionParameters, setCompletionParameters] = useState(null);
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(null);
  const todayDate = moment(new Date());
  const [isGPIOCheckboxEnabled, setIsGPIOCheckboxEnabled] = useState(
    selectedVehicleMeters?.some(
      meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio'
    )
  );
  const [gpioTypeValues, setGpioTypeValues] = useState(
    selectedVehicleMeters
      ?.filter(meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio')
      .map(meter => ({
        value: meter.type,
        label: t(`Vehicles.GPIO.${meter.type}`)
      }))
  );

  // save setFieldValue function
  let changeValueFormMethod;

  useEffect(() => {
    const isGPIOCheckboxEnabled = selectedVehicleMeters?.some(
      meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio'
    );
    const gpioTypeValues = (selectedVehicleMeters || [])
      ?.filter(meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio')
      .map(meter => ({
        value: meter.type,
        label: t(`Vehicles.GPIO.${meter.type}`)
      }));
    setIsGPIOCheckboxEnabled(isGPIOCheckboxEnabled);
    onChangeGPIOType('');
    setGpioTypeValues(gpioTypeValues);
  }, [selectedVehicleMeters]);

  useEffect(() => {
    changeValueFormMethod('GPIOTypes', '');
    if (!isGPIOCheckboxEnabled || !gpioBool) {
      !isGPIOCheckboxEnabled && setGPIOBool(false);
      changeValueFormMethod('GPIO', null);
      changeValueFormMethod('alert_GPIO', null);
      changeValueFormMethod('repeat_GPIO_value', null);
      changeValueFormMethod('overdue_GPIO', null);
      changeValueFormMethod('gpio_reading', '');
    }
  }, [isGPIOCheckboxEnabled]);

  // For schedules added via the DVIR - inspections; fetch the inspection data in order to get and displat the Failed Questions
  useEffect(async () => {
    if (history?.location?.state?.inspection?.inspectionId) {
      const fetchInspectionData = async () => {
        const data = await dispatch(
          fetchInspection({ id: history?.location?.state?.inspection?.inspectionId })
        );
        setInspectionData(data?.payload);
      };

      fetchInspectionData().catch(console.error);
    }
  }, []);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (action === 'add') {
      dispatch(setPageTitle(`${t('Vehicles.Form.AddNewSchedule')}`));
    }
  }, [action, dispatch, t]);

  useEffect(() => {
    if (action === 'edit' && formikInitialValues.name) {
      dispatch(setPageTitle(`${t('Vehicles.Form.Edit')} ${formikInitialValues.name}`));
    }
  }, [action, dispatch, formikInitialValues.name, t]);

  useEffect(() => {
    if (action === 'reschedule') {
      dispatch(setPageTitle(`${t('Vehicles.Form.Reschedule')}`));
    }
  }, [action, dispatch, t]);

  useEffect(() => {
    const inspection = maintenanceInfo?.inspection;
    if (action === 'add' && maintenanceInfo) {
      if (maintenanceInfo.date) {
        setDateBool(true);
      }

      if (vehicles.length > 0 && maintenanceInfo.vehicleId) {
        updateVehicleStats(maintenanceInfo.vehicleId, changeValueFormMethod);
      }

      setFormikInitialValues({
        name: maintenanceInfo.name,
        ...(maintenanceInfo.date && {
          schedulesOn: moment(maintenanceInfo.date.split('+')[0]).format()
        }),
        fleetId: inspection?.inspectionId ? inspection?.fleetIds?.[0] : maintenanceInfo.fleetId,
        manageTypeId: inspection?.inspectionId
          ? types.find(type => type?.name === 'Failed Inspection')?.id
          : '',
        entityId: inspection?.inspectionId ? inspection?.id : maintenanceInfo.vehicleId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, maintenanceInfo?.schedule?.id, vehicles.length]);

  useEffect(() => {
    async function getScheduleById() {
      const response = await api.get(`${SCHEDULE_BY_ID_URL}/${scheduleId}`, {
        authKey: user.auth.key,
        query: { embed: 'vehicle,user,events,company,managetype', pruning: 'ALL' }
      });
      if (response?.body?.completionParameters) {
        setCompletionParameters(JSON.parse(response.body.completionParameters));
      }
    }
    if (action === 'edit' && !completionParameters) {
      getScheduleById();
    }
  }, [action]);

  //Update notes and documents
  useEffect(() => {
    changeValueFormMethod('notes', completionParameters?.notes);
    if (completionParameters?.documents) {
      const documents = completionParameters.documents.map(document => ({
        ...document,
        name: document.filename,
        size: document.filesize,
        isSaved: true
      }));
      saveFiles([...documents]);
    }
  }, [completionParameters]);

  //Set formik values on Edit or Reschedule only
  const scheduleDetails = useScheduleWithVehicleOdometer(scheduleId);
  useEffect(() => {
    if (scheduleId && scheduleDetails && Number(scheduleDetails.id) === Number(scheduleId)) {
      const data = scheduleDetails;
      if (data) {
        let parameters = {};
        try {
          parameters = JSON.parse(data?.parameters || '{}');
        } catch (e) {
          console.error(e);
        }

        //GPIO Paramaters handling
        if (selectedVehicleMeters?.length || data?.vehicle?.meters) {
          const meters = selectedVehicleMeters || data?.vehicle?.meters;
          const isGPIOCheckboxEnabled = meters.some(
            meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio'
          );
          if (isGPIOCheckboxEnabled) {
            parameters?.gpio_meters?.[0] && setGPIOBool(true);
            setIsGPIOCheckboxEnabled(true);
            const gpioTypeValues = meters
              ?.filter(meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio')
              .map(meter => ({
                value: meter.type,
                label: t(`Vehicles.GPIO.${meter.type}`)
              }));
            setGpioTypeValues(gpioTypeValues);
            setSelectedGPIOMeters(
              meters?.filter(meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio')
            );
          }
        }
        //END - GPIO Paramaters handling

        const meter = { meters: data?.vehicle?.meters || [] };
        const odometerMeter = data?.vehicle?.vehicleOdometer?.odometerMeter;
        const engineHoursMeter = getMeterByType(meter, METER_TYPES.HOURS);
        const currentGPIOMeter = (meter?.meters || []).find(
          meter => meter.type === parameters?.gpio_meters?.[0]
        );

        const currentOdometer = odometerMeter ? getMeterValue(odometerMeter) : 0;
        const currentEngineHours = engineHoursMeter ? getMeterValue(engineHoursMeter) : 0;
        const currentGPIO = currentGPIOMeter ? getMeterValue(currentGPIOMeter) : 0;

        const repeatOdometer =
          parameters && parameters.repeat_odometer_value
            ? Number(parameters.repeat_odometer_value)
            : '';
        const scheduledOdometer =
          parameters && parameters.odometer ? Number(parameters.odometer) : '';
        const scheduledEngineHours =
          parameters && parameters.engine_hours ? getRoundValue(parameters.engine_hours, 1) : '';
        const repeatEngineHours =
          parameters && parameters.repeat_engine_hours_value
            ? getRoundValue(parameters.repeat_engine_hours_value, 1)
            : '';
        const currentReading =
          parameters && parameters.current_readings && parameters.current_readings.last_readings_at
            ? format(
                new Date(parameters.current_readings.last_readings_at),
                localization.formats.time.formats.default
              )
            : '';
        const scheduledDate = data.schedulesOn && moment(data.schedulesOn.split('+')[0]).format();
        const repeatDate =
          parameters && parameters.repeat_schedules_on
            ? moment(parameters.repeat_schedules_on, DATE_FORMAT.DEFAULT).format()
            : '';

        const scheduledGPIO =
          parameters && parameters.gpio_meters?.[0] && parameters[parameters.gpio_meters?.[0]]
            ? getRoundValue(parameters[parameters.gpio_meters?.[0]], 1)
            : '';

        const repeatGPIO =
          parameters &&
          parameters.gpio_meters?.[0] &&
          parameters[`repeat_${parameters.gpio_meters?.[0]}_value`]
            ? getRoundValue(parameters[`repeat_${parameters.gpio_meters?.[0]}_value`], 1)
            : '';

        if (parameters) {
          if (
            parameters.alert_odometer ||
            parameters.odometer ||
            parameters.overdue_odometer ||
            parameters.repeat_odometer_value
          ) {
            setOdometerBool(true);
          }
          if (
            parameters.alert_engine_hours ||
            parameters.engine_hours ||
            parameters.overdue_engine_hours ||
            parameters.repeat_engine_hours_value
          ) {
            setEngineHoursBool(true);
          }
          if (
            parameters.days ||
            parameters.overdue_days ||
            parameters.repeat_schedules_on ||
            data.schedulesOn
          ) {
            setDateBool(true);
          }
        }

        // Reschedule data
        if (action === 'reschedule' && rescheduleType) {
          let completedParams = {};
          try {
            completedParams = JSON.parse(data?.completionParameters || '{}');
          } catch (e) {
            console.error(e);
          }
          const currentStateOdometer = Number(currentOdometer + repeatOdometer) || '';
          const currentStateEngineHours = Number(currentEngineHours + repeatEngineHours) || '';
          const currentStateGPIO = Number(currentGPIO + repeatGPIO) || '';
          const scheduledStateOdometer = Number(scheduledOdometer + repeatOdometer) || '';
          const scheduledStateEngineHours = Number(scheduledEngineHours + repeatEngineHours) || '';
          const scheduledStateGPIO = Number(scheduledGPIO + repeatGPIO) || '';
          const completedStateOdometer = completedParams?.odometer
            ? Number(completedParams.odometer) + repeatOdometer
            : currentStateOdometer;
          const completedStateEngineHours = completedParams?.engine_hours
            ? Number(completedParams.engine_hours) + repeatEngineHours
            : currentStateEngineHours;
          const completedStateGPIO =
            completedParams &&
            parameters &&
            parameters.gpio_meters?.[0] &&
            completedParams[parameters.gpio_meters?.[0]]
              ? Number(completedParams[parameters.gpio_meters?.[0]]) + repeatGPIO
              : currentStateGPIO;

          if (rescheduleType === 'current') {
            parameters.odometer = currentStateOdometer;
            parameters.engine_hours = currentStateEngineHours;
            parameters[parameters.gpio_meters?.[0]] = currentStateGPIO;
          }
          if (rescheduleType === 'scheduled') {
            parameters.odometer = scheduledStateOdometer;
            parameters.engine_hours = scheduledStateEngineHours;
            parameters[parameters.gpio_meters?.[0]] = scheduledStateGPIO;
          }
          if (rescheduleType === 'completed') {
            if (
              parameters.alert_odometer ||
              parameters.overdue_odometer ||
              parameters.repeat_odometer_value
            ) {
              completedParams.odometer && setOdometerBool(true);
              parameters.odometer = completedStateOdometer;
            }
            if (
              parameters.alert_engine_hours ||
              parameters.overdue_engine_hours ||
              parameters.repeat_engine_hours_value
            ) {
              completedParams.engine_hours && setEngineHoursBool(true);
              parameters.engine_hours = getRoundValue(completedStateEngineHours, 1);
            }
            if (
              parameters[parameters.gpio_meters?.[0]] ||
              parameters[`overdue_${parameters.gpio_meters?.[0]}`] ||
              parameters[`repeat${parameters.gpio_meters?.[0]}_value`]
            ) {
              completedParams &&
                parameters &&
                parameters.gpio_meters?.[0] &&
                completedParams[parameters.gpio_meters?.[0]] &&
                setGPIOBool(true);
              parameters[parameters.gpio_meters[0]] = getRoundValue(completedStateGPIO, 1);
            }
          }
        }

        //setSelectedVehicle from editingSchedule
        if (data?.vehicle?.id) {
          setVehicleId(data?.vehicle?.id);
        }

        // convert distance to localized kms or miles
        parameters.alert_odometer = parameters.alert_odometer
          ? localization.convertDistance(parameters.alert_odometer)
          : '';
        parameters.odometer = parameters.odometer
          ? localization.convertDistance(parameters.odometer)
          : '';
        parameters.overdue_odometer = parameters.overdue_odometer
          ? localization.convertDistance(parameters.overdue_odometer)
          : '';
        parameters.repeat_odometer_value = parameters.repeat_odometer_value
          ? localization.convertDistance(parameters.repeat_odometer_value)
          : '';
        setFormikInitialValues({
          ...initialValues,
          ...data,
          ...parameters,
          ...(action === 'edit' &&
            data.schedulesOn && {
              schedulesOn: scheduledDate
            }),
          // Round engine hours values
          alert_engine_hours: getRoundValue(parameters.alert_engine_hours, 1) || '',
          engine_hours: getRoundValue(parameters.engine_hours, 1) || '',
          overdue_engine_hours: getRoundValue(parameters.overdue_engine_hours, 1) || '',
          repeat_engine_hours_value: getRoundValue(parameters.repeat_engine_hours_value, 1) || '',
          ...(action === 'reschedule' && {
            schedulesOn: repeatDate || scheduledDate
          }),
          ...(parameters.repeat_schedules_on && {
            repeat_schedules_on: moment(
              parameters.repeat_schedules_on,
              DATE_FORMAT.DEFAULT
            ).format()
          }),
          manageTypeId: data.manageType.id || '',
          fleetId: (data.fleetIds || [])[0],
          entityId: data.vehicle.id,
          ...(isGPIOCheckboxEnabled &&
            parameters?.gpio_meters?.length &&
            parameters?.gpio_meters?.[0] && {
              GPIOTypes: parameters?.gpio_meters[0],
              GPIO: parameters[parameters?.gpio_meters[0]],
              alert_GPIO: parameters[`alert_${parameters?.gpio_meters[0]}`],
              repeat_GPIO_value: parameters[`repeat_${parameters?.gpio_meters[0]}_value`],
              overdue_GPIO: parameters[`overdue_${parameters?.gpio_meters[0]}`],
              gpio_reading: (data?.vehicle?.meters || []).find(
                meter => meter.type === parameters?.gpio_meters[0]
              )
                ? getMeterValue(
                    (data?.vehicle?.meters || []).find(
                      meter => meter.type === parameters?.gpio_meters[0]
                    )
                  ).toFixed(1)
                : ''
            }),
          odometer_reading: localization.convertDistance(currentOdometer),
          engineHours_reading: getRoundValue(currentEngineHours, 1),
          date_reading: currentReading,
          ...(initalSelectedVehicleFormFields || {})
        });
      }
    }
  }, [
    scheduleDetails,
    scheduleId,
    dispatch,
    action,
    rescheduleType,
    localization,
    initalSelectedVehicleFormFields,
    setVehicleId,
    selectedVehicle
  ]);

  useEffect(() => {
    const vehicles = initialVehicles.filter(vehicle => vehicle.id);
    setVehicles(vehicles);
  }, [initialVehicles]);

  const onFleetChange = (id, setFieldValue) => {
    setFieldValue('fleetId', id || '');
    if (!id) {
      // All fleets case
      setVehicles(initialVehicles);
      return;
    }
    const filteredVehiclesByFleetId = initialVehicles.filter(vehicle =>
      vehicle.fleets?.find(fleet => vehicle.id && Number(fleet.id) === Number(id))
    );
    if (
      filteredVehiclesByFleetId &&
      filteredVehiclesByFleetId.length > 0 &&
      filteredVehiclesByFleetId[0].id
    ) {
      setVehicles(filteredVehiclesByFleetId);
      const vehicleId = filteredVehiclesByFleetId[0].id;
      const vehicle = filteredVehiclesByFleetId[0];
      setFieldValue('entityId', vehicleId);
      updateVehicleStats(vehicleId, setFieldValue, vehicle);
    } else {
      resetVehicleStats(setFieldValue);
    }
  };

  const resetVehicleStats = setFieldValue => {
    setFieldValue('odometer_reading', 0);
    setFieldValue('engineHours_reading', 0);
    setFieldValue('date_reading', '');
  };

  useEffect(() => {
    const vehicleId = selectedVehicle.vehicle?.id;
    if (vehicleId) {
      const setFieldValue = selectedVehicle.setFieldValue;
      const fields = getVehicleFormFields(
        vehicleId,
        selectedVehicleMeters,
        selectedVehicleConfig,
        vehiclesStats,
        localization
      );

      if (setFieldValue) {
        Object.keys(fields).map(key => setFieldValue(key, fields[key]));
      }
    }
  }, [selectedVehicleMeters, selectedVehicle, selectedVehicleConfig, vehiclesStats, localization]);

  const updateVehicleStats = (vehicleId, setFieldValue, vehicle) => {
    const _selectedVehicle = vehicle
      ? vehicle
      : vehicles.find(vehicle => vehicle.id === parseInt(vehicleId, 10));
    if (!_selectedVehicle) return;
    setSelectedVehicle({ vehicle: _selectedVehicle, setFieldValue });
  };

  const onVehicleChange = (vehicleId, setFieldValue) => {
    updateVehicleStats(vehicleId, setFieldValue);
  };

  const getCompanyIdFromVehicle = useCallback(
    vehicleId => {
      return vehicles.find(vehicle => Number(vehicle.id) === Number(vehicleId))?.companyId;
    },
    [vehicles]
  );

  const onSubmit = async (values, actions) => {
    const { setSubmitting, resetForm } = actions;
    // eslint-disable-next-line no-unused-vars
    let alert_odometer, odometer, overdue_odometer, repeat_odometer_value;
    ({ alert_odometer, odometer, overdue_odometer, repeat_odometer_value } = values);
    if (localization.formats.speed.unit_per_hour === 'mph') {
      alert_odometer = LocalizationUtil.miletokm(parseFloat(alert_odometer));
      odometer = LocalizationUtil.miletokm(parseFloat(odometer));
      overdue_odometer = LocalizationUtil.miletokm(parseFloat(overdue_odometer));
      repeat_odometer_value = LocalizationUtil.miletokm(parseFloat(repeat_odometer_value));
    }

    const parameters = {
      ...(odometerBool && {
        ...(alert_odometer && { alert_odometer: Number(alert_odometer) }),
        ...(odometer && { odometer: Number(odometer) }),
        ...(overdue_odometer && { overdue_odometer: Number(overdue_odometer) }),
        ...(repeat_odometer_value && { repeat_odometer_value: Number(repeat_odometer_value) })
      }),
      ...(engineHoursBool && {
        ...(values.alert_engine_hours && { alert_engine_hours: values.alert_engine_hours }),
        ...(values.engine_hours && { engine_hours: values.engine_hours }),
        ...(values.overdue_engine_hours && { overdue_engine_hours: values.overdue_engine_hours }),
        ...(values.repeat_engine_hours_value && {
          repeat_engine_hours_value: values.repeat_engine_hours_value
        })
      }),
      ...(dateBool && {
        ...(values.days && { days: values.days }),
        ...(values.overdue_days && { overdue_days: values.overdue_days }),
        ...(values.repeat_schedules_on && {
          repeat_schedules_on: moment(values.repeat_schedules_on).format(DATE_FORMAT.DEFAULT)
        })
      }),
      ...(gpioBool && {
        ...(values['GPIOTypes'] && { gpio_meters: [values['GPIOTypes']] }),
        ...(values['GPIO'] && { [values.GPIOTypes]: values['GPIO'] }),
        ...(values.alert_GPIO && { [`alert_${values.GPIOTypes}`]: values.alert_GPIO }),
        ...(values.repeat_GPIO_value && {
          [`repeat_${values.GPIOTypes}_value`]: values.repeat_GPIO_value
        }),
        ...(values.overdue_GPIO && {
          [`overdue_${values.GPIOTypes}`]: values.overdue_GPIO
        })
      })
    };

    const submitValues = {
      companyId: getCompanyIdFromVehicle(values.entityId) || company.id,
      manageTypeId: values.manageTypeId,
      entityId: values.entityId,
      name: values.name,
      ...(dateBool &&
        values.schedulesOn && {
          schedulesOn: moment(values.schedulesOn).format(DATE_FORMAT.DEFAULT)
        }),
      ...(!dateBool && { schedulesOn: '' }),
      ...(action === 'reschedule' &&
        (selectedVehicle.vehicle || currentVehicleId) === scheduleDetails?.vehicle?.id && {
          previousMaintenanceId: scheduleId
        }),
      ...((action === 'add' || action === 'reschedule') && { createdAt: moment().format() }),
      ...(action === 'edit' && { updatedAt: moment().format() }),
      parameters: JSON.stringify(parameters),
      completionParameters: JSON.stringify({ notes: values.notes }),
      // For schedules added from the DVIR Inspections page -> Add the inspectionId
      ...(history?.location?.state?.inspection?.inspectionId && {
        inspectionId: history?.location?.state?.inspection?.inspectionId || null
      })
    };

    let url = `${API_PATH}/schedules/vehiclemaintenance`;
    let method = 'POST';
    let successMessage = t('Vehicles.Notifications.ScheduleAdded', {
      name: values.name
    });

    if (action === 'edit') {
      url = `${API_PATH}/schedules/vehiclemaintenance/${scheduleId}`;
      method = 'PUT';
      successMessage = t('Vehicles.Notifications.ScheduleUpdated', {
        name: values.name
      });
    }

    if (action === 'reschedule') {
      url = `${API_PATH}/schedules/vehiclemaintenance/reschedule`;
      successMessage = t('Vehicles.Notifications.Rescheduled', {
        name: values.name
      });
    }

    Mixpanel.sendTrackEvent(MPTrackingEvents.VehicleMaintenance.Schedule[action], {
      action,
      typeName: types.find(type => `${type.id}` === `${submitValues.manageTypeId}`)?.name,
      scheduleSelectors: MPTrackingEvents.VehicleMaintenance.Schedule.getScheduleSelectors(
        odometerBool,
        engineHoursBool,
        dateBool
      ),
      serviceName: submitValues.name
    });
    request(method, url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(submitValues)
      .then(async res => {
        setPromptModalWhenLeaving(false);
        dispatch(
          openToast({
            type: ToastType.Success,
            message: successMessage
          })
        );
        const filesToAttach = files.filter(file => !file.isSaved);
        if (filesToAttach) {
          await dispatch(attachFiles(filesToAttach, res?.body?.id || scheduleId));
        }
        dispatch(refetchSchedules(res.body || { id: scheduleId }));
        setSubmitting(false);
        resetForm();
        history.push(Paths.VEHICLEMAINTENANCE_SCHEDULES);
      })
      .catch(err => {
        setSubmitting(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err.response.text
          })
        );
      });
  };

  useEffect(() => {
    dispatch(getConfig(company?.id));
  }, [company]);

  const handleAttachment = event => {
    const {
      target: { files: uploadFiles }
    } = event;
    handleFileAttachment({
      uploadFiles,
      files,
      dispatch,
      updateFilesCb,
      maxFileSizeBytes: uploadConfig?.maxFileSizeBytes
    });
  };

  const updateFilesCb = payload => {
    saveFiles(payload);
  };

  const cancelPictureModal = () => {
    setIsPictureModalOpen(null);
  };

  const showPictureModal = id => {
    setIsPictureModalOpen(id);
  };

  const onChangeGPIOType = value => {
    const metersArrayToCheck = selectedGPIOmeters.length
      ? selectedGPIOmeters
      : selectedVehicleMeters;
    const selectedGPIOMeter = (metersArrayToCheck || []).find(meter => meter.type === value);
    changeValueFormMethod(
      'gpio_reading',
      selectedGPIOMeter ? getMeterValue(selectedGPIOMeter).toFixed(1) : ''
    );
    changeValueFormMethod('GPIOTypes', value);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...formikInitialValues }}
        validationSchema={validationSchema(gpioBool)}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ isSubmitting, isValid, dirty, setFieldValue }) => {
          if (!changeValueFormMethod) {
            changeValueFormMethod = setFieldValue;
          }
          return (
            <>
              <EditRouteGuard when={dirty && promptModalWhenLeaving} navigate={history.push} />
              <Form id="ScheduleForm">
                <div>
                  <div style={{ width: '100%' }}>
                    <div className={styles.formContainer}>
                      <Row>
                        <Col>
                          <Row>
                            <FormInput
                              name="name"
                              label={t('Vehicles.Form.ServiceName')}
                              placeholder={t('Vehicles.Form.ServiceNamePlaceholder')}
                              isRequired
                            />
                          </Row>
                          <Row>
                            <FormSelect
                              name="manageTypeId"
                              label={t('Vehicles.Form.Type')}
                              placeholder={t('Vehicles.Form.TypePlaceholder')}
                              values={types.map(type => ({
                                label: type.name,
                                value: type.id
                              }))}
                              isRequired
                            />
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <FormSelect
                              name="fleetId"
                              label={t('Vehicles.Form.Fleet')}
                              placeholder={t('Vehicles.Form.AllFleets')}
                              values={fleets.map(fleet => ({
                                label: fleet.name,
                                value: fleet.id
                              }))}
                              onChange={id => onFleetChange(id, setFieldValue)}
                              isDisabled={!!inspectionData}
                            />
                          </Row>
                          <Row>
                            <FormSelect
                              name="entityId"
                              label={t('Vehicles.Form.Vehicle')}
                              placeholder={t('Vehicles.Form.VehiclePlaceholder')}
                              values={
                                vehicles &&
                                vehicles.map(vehicle => ({
                                  label: vehicle.name,
                                  value: vehicle.id
                                }))
                              }
                              onChange={vehicle => onVehicleChange(vehicle, setFieldValue)}
                              isRequired
                              isDisabled={!!inspectionData}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <div className={styles.infoContainer}>
                          {t('Vehicles.Form.FillSchedule')}
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup controlId="odometerBool">
                            <FormCheck
                              value={odometerBool}
                              checked={odometerBool}
                              type="checkbox"
                              label={t('Vehicles.Form.Odometer')}
                              onChange={() => setOdometerBool(!odometerBool)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {odometerBool && (
                        <Row>
                          <Col>
                            <Row>
                              <FormInput
                                name="alert_odometer"
                                label={`${t('Vehicles.Form.AlertSimple')} (${
                                  localization.formats.speed.unit_pluralize
                                } ${t('Vehicles.Form.BeforeScheduleDue')})`}
                                type="number"
                                placeholder={t('Vehicles.Form.AlertPlaceholder')}
                                isValidated
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="overdue_odometer"
                                label={`${t('Vehicles.Form.OverdueSimple')} (${
                                  localization.formats.speed.unit_pluralize
                                } ${t('Vehicles.Form.AfterScheduleDue')})`}
                                type="number"
                                placeholder={t('Vehicles.Form.OverduePlaceholder')}
                                isValidated
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormInput
                                name="odometer"
                                label={`${t('Vehicles.Form.ScheduleDue')} (${
                                  localization.formats.speed.unit_pluralize
                                })`}
                                type="number"
                                placeholder={t('Vehicles.Form.ScheduleDuePlaceholder')}
                                isValidated
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="repeat_odometer_value"
                                label={`${t('Vehicles.Form.Repeat')} (${
                                  localization.formats.speed.unit_pluralize
                                })`}
                                type="number"
                                placeholder={t('Vehicles.Form.RepeatPlaceholder')}
                                isValidated
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormInput
                                name="odometer_reading"
                                label={`${t('Vehicles.Form.CurrentReading')} (${
                                  localization.formats.speed.unit_pluralize
                                })`}
                                placeholder=""
                                disabled
                              />
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <FormGroup controlId="engineHoursBool">
                            <FormCheck
                              value={engineHoursBool}
                              checked={engineHoursBool}
                              type="checkbox"
                              label={t('Vehicles.Form.EngineHours')}
                              onChange={() => setEngineHoursBool(!engineHoursBool)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {engineHoursBool && (
                        <Row>
                          <Col>
                            <Row>
                              <FormInput
                                name="alert_engine_hours"
                                label={t('Vehicles.Form.AlertHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.AlertPlaceholder')}
                                isValidated
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="overdue_engine_hours"
                                label={t('Vehicles.Form.OverdueHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.OverduePlaceholder')}
                                isValidated
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormInput
                                name="engine_hours"
                                label={t('Vehicles.Form.ScheduleDueHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.ScheduleDuePlaceholder')}
                                isValidated
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="repeat_engine_hours_value"
                                label={t('Vehicles.Form.RepeatHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.RepeatPlaceholder')}
                                isValidated
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormInput
                                name="engineHours_reading"
                                label={t('Vehicles.Form.CurrentReadingHrs')}
                                placeholder=""
                                disabled
                              />
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <FormGroup controlId="dateBool">
                            <FormCheck
                              value={dateBool}
                              checked={dateBool}
                              type="checkbox"
                              label={t('Vehicles.Form.Date')}
                              onChange={() => setDateBool(!dateBool)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {dateBool && (
                        <Row>
                          <Col>
                            <Row>
                              <FormInput
                                name="days"
                                label={t('Vehicles.Form.Alert')}
                                type="number"
                                placeholder={t('Vehicles.Form.AlertPlaceholder')}
                                isValidated
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="overdue_days"
                                label={t('Vehicles.Form.Overdue')}
                                type="number"
                                placeholder={t('Vehicles.Form.OverduePlaceholder')}
                                isValidated
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormDatePicker
                                name="schedulesOn"
                                label={t('Vehicles.Form.ScheduleDue')}
                                format={localization.formats.time.formats.dby}
                                placeholder={t('Vehicles.Form.SelectDate')}
                                setFieldValue={setFieldValue}
                                drops="up"
                                minDate={todayDate}
                              />
                            </Row>
                            <Row>
                              <FormDatePicker
                                name="repeat_schedules_on"
                                label={t('Vehicles.Form.Repeat')}
                                format={localization.formats.time.formats.dby}
                                placeholder={t('Vehicles.Form.SelectDate')}
                                setFieldValue={setFieldValue}
                                drops="up"
                                minDate={todayDate}
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormInput
                                name="date_reading"
                                label={t('Vehicles.Form.CurrentReading')}
                                placeholder=""
                                disabled
                              />
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <FormGroup controlId="gpioBool" className={styles.gpioCheckbox}>
                            <FormCheck
                              value={gpioBool}
                              checked={gpioBool}
                              disabled={!isGPIOCheckboxEnabled}
                              type="checkbox"
                              label={t('Vehicles.Form.GPIO')}
                              onChange={() => setGPIOBool(prev => !prev)}
                            />
                            {!isGPIOCheckboxEnabled && (
                              <Tooltip
                                placement={'rightBottom'}
                                title={t('Vehicles.Form.GPIOTooltipInfo')}
                              >
                                <QuestionCircleOutlined className={styles.gpioTooltipIcon} />
                              </Tooltip>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      {gpioBool && (
                        <Row>
                          <Col>
                            <Row>
                              <FormSelect
                                name="GPIOTypes"
                                label={t('Vehicles.Form.Type')}
                                placeholder={t('Vehicles.Form.TypePlaceholder')}
                                onChange={onChangeGPIOType}
                                values={gpioTypeValues || []}
                                isRequired
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="overdue_GPIO"
                                label={t('Vehicles.Form.OverdueHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.OverduePlaceholder')}
                                isValidated
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormInput
                                name="GPIO"
                                label={t('Vehicles.Form.ScheduleDueHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.ScheduleDuePlaceholder')}
                                isValidated
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="repeat_GPIO_value"
                                label={t('Vehicles.Form.RepeatHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.RepeatPlaceholder')}
                                isValidated
                              />
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <FormInput
                                name="alert_GPIO"
                                label={t('Vehicles.Form.AlertHrs')}
                                type="number"
                                placeholder={t('Vehicles.Form.AlertPlaceholder')}
                                isValidated
                              />
                            </Row>
                            <Row>
                              <FormInput
                                name="gpio_reading"
                                label={t('Vehicles.Form.CurrentReadingHrs')}
                                placeholder=""
                                disabled
                              />
                            </Row>
                          </Col>
                        </Row>
                      )}
                      <div style={{ marginLeft: '-16px' }}>
                        <AntRow>
                          <AntCol span={16}>
                            <FormInput
                              name="notes"
                              label={t('VehicleMntSchedules.View.Notes')}
                              as="textarea"
                              rows={5}
                              isValidated
                            />
                          </AntCol>
                        </AntRow>
                        {inspectionData &&
                          renderFailedItems({
                            inspectionData,
                            isPictureModalOpen,
                            showPictureModal,
                            cancelPictureModal,
                            styles,
                            t
                          })}
                        <AntRow>
                          <FormLabel className={styles.customFormLabel}>
                            {t('VehicleMntSchedules.View.Documents')}
                          </FormLabel>
                        </AntRow>
                        <AntRow>
                          <AntCol span={16}>
                            <DocumentsDisplay
                              rows={renderUploadedFiles({
                                files,
                                currentUser: user,
                                dateFormat,
                                updateFilesCb,
                                dispatch
                              })}
                            />
                          </AntCol>
                          <AntCol span={8}>
                            <span className={styles.fileUpload}>
                              <FileUpload onAdd={handleAttachment} />
                            </span>
                          </AntCol>
                        </AntRow>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.formFooter}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isValid || isSubmitting}
                    id={BUTTON_IDS.scheduleAddEditSave}
                  >
                    {t('Common.SaveButton')}
                  </Button>
                  <Button id={BUTTON_IDS.scheduleAddEditCancel} onClick={history.goBack}>
                    {t('Common.CancelButton')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};
