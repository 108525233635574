import moment from 'moment';
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Select, TimePicker, Button, Space, Checkbox, Modal } from 'antd';
import { CameraModelConfig } from 'features/camera/CameraModelConfig';
import { onlyUnique } from 'utils/filters';
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useDeviceModels } from 'features/device_models/deviceModelsSlice';
import { useDeviceTypes } from 'features/device_types/deviceTypesSlice';
import { useUser, useUserKey } from 'features/user/userSlice';
import { useAllRegions } from 'features/regions/regionsSlice';
import {
  useCameraSnapshotConfig,
  updateCameraSnapshotConfig,
  deleteCameraSnapshotConfig,
  actionTypes,
  RESET_TIME
} from 'features/cameraSnapshot_config/cameraSnapshotConfigSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import styles from './SnapshotConfig.module.scss';

import { entities, useCanEveryEntity } from 'features/permissions';
import { useDevices, useIsFetchingDevicesFinished } from 'features/fleets/fleetsSlice';

function SnapshotConfigFilter({
  isLoading,
  companiesList,
  companyId,
  provider,
  cameraProviders,
  hasSchedule,
  onCompanyChanged,
  onProviderChanged,
  onScheduleChanged,
  disabled
}) {
  const { t } = useTranslation();

  return (
    <Space
      direction="vertical"
      className={`${styles.configFilter} ${isLoading ? styles.loading : ''}`}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {t('Home.Company')}
        <Select
          showSearch
          style={{
            width: '100%'
          }}
          onChange={onCompanyChanged}
          optionFilterProp="label"
          value={companyId}
          options={companiesList?.map(company => ({
            value: company.id,
            label: company.name
          }))}
        />
      </Space>
      <Space direction="vertical" style={{ width: '100%' }}>
        {t('Home.Provider')}
        <Select
          showSearch
          style={{
            width: '100%'
          }}
          optionFilterProp="label"
          value={provider}
          onChange={onProviderChanged}
          options={cameraProviders?.map(provider => ({
            value: provider.name,
            label: provider.name.toLowerCase() === 'iqcamera' ? 'IQ Camera' : provider.name
          }))}
        />
      </Space>
      <Space>
        <Switch
          onChange={onScheduleChanged}
          checked={hasSchedule}
          disabled={!provider || disabled}
        />
        {hasSchedule ? t('Home.Schedule Snapshots') : t('Home.Enable Schedule Config')}
      </Space>
    </Space>
  );
}

function SnapshotScheduleConfig({ config, timeZones, show, onChange, isLoading, disabled }) {
  const [showPanel, setShowPanel] = useState(show);

  const { t } = useTranslation();

  const [daysOfWeeksOptions, weeksOptions] = useMemo(
    () => [
      [
        { label: t('Home.Sunday'), value: 'sunday' },
        { label: t('Home.Monday'), value: 'monday' },
        { label: t('Home.Tuesday'), value: 'tuesday' },
        { label: t('Home.Wednesday'), value: 'wednesday' },
        { label: t('Home.Thursday'), value: 'thursday' },
        { label: t('Home.Friday'), value: 'friday' },
        { label: t('Home.Saturday'), value: 'saturday' }
      ],
      [
        { label: t('Home.Week1'), value: 'week1' },
        { label: t('Home.Week2'), value: 'week2' },
        { label: t('Home.Week3'), value: 'week3' },
        { label: t('Home.Week4'), value: 'week4' },
        { label: t('Home.Week5'), value: 'week5' }
      ]
    ],
    [t]
  );

  const handleChange = useCallback(
    (prop, value) => {
      const newConfig = { ...config };
      if (typeof prop === 'object') {
        for (const key in prop) {
          newConfig[key] = prop[key];
        }
      } else {
        newConfig[prop] = value;
      }
      if (onChange) {
        onChange(newConfig);
      }
    },
    [config, onChange]
  );

  useEffect(() => {
    let timer = null;
    if (!show) {
      timer = setTimeout(() => {
        setShowPanel(false);
      }, 300);
    } else {
      setShowPanel(true);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [show]);

  if (!showPanel) {
    return <></>;
  }

  return (
    <Space
      direction="vertical"
      className={`${styles.configPanel} ${show ? styles.expand : styles.collapse} ${
        isLoading ? styles.loading : ''
      }`}
    >
      <div className={styles.configTitle}>{t('Home.CameraConfigTitle')}</div>
      <Space align="start" direction="vertical" className={styles.configSelector}>
        {t('Home.CameraConfigWeek')}
        <MultiSelectWithSelectAll
          t={t}
          required
          options={weeksOptions}
          config={config}
          placeholder={t('Home.SelectWeeks')}
          onChange={handleChange}
          disabled={disabled}
          id="snapshotConfigMultiselectSelectweeks"
        />
      </Space>
      <Space align="start" direction="vertical" className={styles.configSelector}>
        {t('Home.Days')}
        <MultiSelectWithSelectAll
          t={t}
          required
          options={daysOfWeeksOptions}
          config={config}
          placeholder={t('Home.SelectDays')}
          onChange={handleChange}
          disabled={disabled}
          id="snapshotConfigMultiselectSelectdays"
        />
      </Space>
      <Space align="start" direction="vertical">
        {t('Home.Time')}
        <TimePicker
          allowClear={false}
          format={'HH:mm'}
          value={moment()
            .set('hour', config.time.hour)
            .set('minute', config.time.minute)}
          onChange={time => {
            handleChange('time', { hour: time.hour(), minute: time.minute() });
          }}
          disabled={disabled}
          id="snapshotConfigTimepickerTime"
        />
      </Space>
      <Space align="start" direction="vertical" className={styles.configSelector}>
        {t('Home.Time Zone')}
        <Select
          showSearch
          style={{
            width: '100%'
          }}
          value={config.timeZone}
          optionFilterProp="label"
          onChange={value => handleChange('timeZone', value)}
          options={timeZones?.map(timeZone => ({
            value: timeZone,
            label: timeZone
          }))}
          disabled={disabled}
          id="snapshotConfigSelectTimezone"
        />
      </Space>
    </Space>
  );
}

const initialConfig = {
  week1: false,
  week2: false,
  week3: false,
  week4: false,
  week5: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
  time: {
    hour: 1,
    minute: 0
  },
  timeZone: ''
};

const MultiSelectWithSelectAll = ({
  t,
  options,
  config,
  placeholder,
  onChange,
  required,
  disabled,
  id
}) => {
  const [touched, setTouched] = useState(false);
  const { value, isAllSelected } = useMemo(
    () => ({
      value: options.reduce((a, opt) => (config?.[opt.value] ? [...a, opt.value] : a), []),
      isAllSelected: !options.some(opt => !config?.[opt.value])
    }),
    [options, config]
  );

  const handleSelect = useCallback(
    selected => {
      if (onChange) {
        onChange(
          options.reduce((a, opt) => ({ ...a, [opt.value]: selected.includes(opt.value) }), {})
        );
      }
      setTouched(t => t || true);
    },
    [onChange, options]
  );

  const onToggleSelectAll = useCallback(
    e => {
      if (onChange) {
        onChange(options.reduce((a, opt) => ({ ...a, [opt.value]: !isAllSelected }), {}));
      }
      setTouched(t => t || true);
    },
    [onChange, options, isAllSelected]
  );

  return (
    <Select
      id={id}
      mode="multiple"
      placeholder={placeholder}
      optionLabelProp="title"
      value={value}
      onChange={handleSelect}
      disabled={disabled}
      style={{ width: '100%' }}
      status={required && touched && !value?.length ? 'error' : ''}
      options={options.map(option => ({
        label: (
          <Space size={[8]} direction="horizontal">
            <Checkbox checked={config?.[option.value]}></Checkbox>
            <div>{option.label}</div>
          </Space>
        ),
        title: option.label,
        value: option.value
      }))}
      dropdownRender={menu => (
        <div>
          <Button type="text" onClick={onToggleSelectAll} className={styles.selectAll}>
            {isAllSelected ? t('Home.UnselectAll') : t('Home.SelectAll')}
          </Button>
          {menu}
        </div>
      )}
    />
  );
};

export function SnapshotConfigPopup({ show, onHide }) {
  const dispatch = useDispatch();
  const companiesList = useCompanies();
  const deviceModels = useDeviceModels();
  const deviceTypes = useDeviceTypes();
  const curCompany = useCurrentCompany();
  const userKey = useUserKey();
  const { t } = useTranslation();
  const regionList = useAllRegions();
  const userInfo = useUser();
  const devices = useDevices();
  const isDevicesEntitiesFetched = useIsFetchingDevicesFinished();
  const canVideoEntities = useCanEveryEntity([entities.VIDEO, entities.VIDEO_CREATE]);

  const [isLoading, setIsLoading] = useState(false);
  const [hasConfig, setHasConfig] = useState(false);
  const actionType = useRef(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(curCompany?.id);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [canUpdate, setCanUpdate] = useState(false);
  const [cameraConfig, setCameraConfig] = useState(initialConfig);
  const [updateButtonWords, setUpdateButtonWords] = useState(t('Home.Update'));
  const [defaultTimezone, setDefaultTimezone] = useState(
    companiesList?.find(comp => comp?.id === selectedCompanyId)?.statisticsTz
  );

  const isValidConfig = useCallback(config => {
    const isEmptyWeeksOfMonth = ['week1', 'week2', 'week3', 'week4', 'week5'].every(
        w => !config[w]
      ),
      isEmptyDaysOfWeek = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
      ].every(d => !config[d]);
    return !isEmptyWeeksOfMonth && !isEmptyDaysOfWeek && config.timeZone && config.time;
  }, []);

  useEffect(() => {
    if (curCompany?.id) {
      setSelectedCompanyId(curCompany.id);
      setIsLoading(true);
      actionType.current = 'fetching';
    }
  }, []);

  const timeZones = useMemo(() => {
    const list = [];
    if (regionList) {
      regionList.forEach(r => {
        if (r.timezone) {
          const timeZoneJSON = JSON.parse(r.timezone);
          for (let t in timeZoneJSON) {
            list.push(timeZoneJSON[t]);
          }
        }
      });
    }
    if (userInfo?.timeZone && list.every(tz => tz !== userInfo.timeZone)) {
      list.push(userInfo.timeZone);
    }
    const selectedCompany =
      selectedCompanyId && companiesList?.find(comp => comp?.id === selectedCompanyId);
    if (selectedCompany?.statisticsTz) {
      if (list.every(tz => tz !== selectedCompany?.statisticsTz)) {
        list.push(selectedCompany?.statisticsTz);
      }
    }
    return list.filter(onlyUnique).sort();
  }, [regionList, userInfo, selectedCompanyId, companiesList]);

  useEffect(() => {
    const selectedCompany =
      selectedCompanyId && companiesList?.find(comp => comp?.id === selectedCompanyId);
    if (selectedCompany?.statisticsTz) {
      setDefaultTimezone(selectedCompany?.statisticsTz);
    } else if (userInfo?.timeZone) {
      setDefaultTimezone(userInfo.timeZone);
    } else {
      setDefaultTimezone(timeZones && timeZones[0]);
    }
  }, [timeZones, userInfo, selectedCompanyId, companiesList]);

  useEffect(() => {
    if (!cameraConfig?.timeZone && defaultTimezone) {
      const config = { ...cameraConfig, timeZone: defaultTimezone };
      setCameraConfig(config);
    }
  }, [cameraConfig, defaultTimezone]);

  useEffect(() => {
    setSelectedCompanyId(curCompany.id);
  }, [curCompany]);

  const cameraProviders = useMemo(() => {
    let cameraTypeId = -1;
    const providers = [];
    const availableCameraModel = {};

    if (deviceTypes?.data['Camera']) {
      cameraTypeId = deviceTypes.data['Camera'].id;
    }
    if (devices?.length > 0 && cameraTypeId) {
      devices.forEach(d => {
        if (
          d.type?.id === cameraTypeId &&
          d.companyId === selectedCompanyId &&
          d.status === 'ENABLED'
        ) {
          availableCameraModel[d.model.id] = true;
        }
      });
    }
    if (deviceModels && cameraTypeId && cameraTypeId !== -1) {
      for (let modelName in deviceModels.data) {
        const model = deviceModels.data[modelName];
        if (
          model?.deviceType?.id === cameraTypeId &&
          !['Seeing Machines', 'DEFAULT'].includes(model.name) &&
          (userInfo.siteAdmin || availableCameraModel[model?.id])
        ) {
          const modelToExclude = [CameraModelConfig.Stonkam, CameraModelConfig.MultiIQ];
          if (!modelToExclude.some(item => item.name === model.name)) {
            providers.push(model);
          }
        }
      }
    }

    if (providers.length > 0) {
      setSelectedProvider(providers[0].name);
    } else if (isDevicesEntitiesFetched) {
      setIsLoading(false);
      setSelectedProvider(null);
      setHasConfig(false);
      setCanUpdate(false);
    }
    return providers;
  }, [deviceModels, deviceTypes, devices, userInfo, selectedCompanyId, isDevicesEntitiesFetched]);

  const handleScheduleChanged = useCallback(() => {
    if (canUpdate) {
      if (cameraConfig.id) {
        setIsLoading(true);
        actionType.current = 'deleting';
        dispatch(
          deleteCameraSnapshotConfig(userKey, selectedCompanyId, selectedProvider, cameraConfig.id)
        );
      } else {
        setHasConfig(!hasConfig);
      }
    }
  }, [
    dispatch,
    hasConfig,
    canUpdate,
    cameraConfig.id,
    actionType,
    selectedCompanyId,
    selectedProvider,
    userKey
  ]);

  const handleCompanyChanged = useCallback(
    value => {
      setSelectedCompanyId(Number(value));
      setSelectedProvider(null);
      setIsLoading(true);
      actionType.current = 'fetching';
    },
    [actionType]
  );

  const handleProviderChanged = useCallback(
    value => {
      setSelectedProvider(value);
      setIsLoading(true);
      actionType.current = 'fetching';
    },
    [actionType]
  );

  const handleConfigChange = useCallback(newConfig => {
    setCameraConfig(newConfig);
  }, []);

  const handleHide = useCallback(
    forceClose => {
      if (isLoading && !forceClose) {
        return;
      }
      setSelectedCompanyId(curCompany.id);
      if (cameraProviders?.length) {
        setSelectedProvider(cameraProviders[0].name);
      }
      setHasConfig(false);
      actionType.current = null;
      if (onHide) {
        onHide();
      }
    },
    [isLoading, curCompany, cameraProviders, actionType, onHide]
  );

  const getCameraConfigPayload = cameraConfig => {
    const weekOfMonth = [];
    const dayOfWeek = [];
    if (cameraConfig.week1) {
      weekOfMonth.push(1);
    }

    if (cameraConfig.week2) {
      weekOfMonth.push(2);
    }

    if (cameraConfig.week3) {
      weekOfMonth.push(3);
    }

    if (cameraConfig.week4) {
      weekOfMonth.push(4);
    }

    if (cameraConfig.week5) {
      weekOfMonth.push(5);
    }

    if (cameraConfig.sunday) {
      dayOfWeek.push(1);
    }

    if (cameraConfig.monday) {
      dayOfWeek.push(2);
    }

    if (cameraConfig.tuesday) {
      dayOfWeek.push(3);
    }

    if (cameraConfig.wednesday) {
      dayOfWeek.push(4);
    }

    if (cameraConfig.thursday) {
      dayOfWeek.push(5);
    }

    if (cameraConfig.friday) {
      dayOfWeek.push(6);
    }

    if (cameraConfig.saturday) {
      dayOfWeek.push(7);
    }

    return {
      Config: JSON.stringify({
        week: {
          weekOfMonth: weekOfMonth,
          dayOfWeek: dayOfWeek
        },
        time: cameraConfig.time.hour + ':' + cameraConfig.time.minute,
        timezone: cameraConfig.timeZone
      }),
      Provider: selectedProvider
    };
  };

  const cameraConfigs = useSelector(state => state.cameraSnapshotConfig);

  const handleUpdating = useCallback(() => {
    //check if can be updated
    const cameraConfigData = cameraConfigs.data[selectedCompanyId];
    const cameraConfigDataByProvider = cameraConfigData[selectedProvider];
    const addingConfigToCompany = !cameraConfigData;
    const addingConfigToCompanyProvider =
      !cameraConfigDataByProvider || !cameraConfigDataByProvider?.status;
    const exceedConfigUpdateResetTime =
      cameraConfigDataByProvider?.status?.updating !== actionTypes.processing &&
      moment().valueOf() - (cameraConfigDataByProvider?.status?.updatingTime || 0) >= RESET_TIME;
    if (!addingConfigToCompany && !addingConfigToCompanyProvider && !exceedConfigUpdateResetTime) {
      return dispatch(
        openToast({
          type: ToastType.Warning,
          message: t('Home.CameraConfigCannotUpdate.message'),
          title: t('Home.CameraConfigCannotUpdate.title'),
          autohide: true
        })
      );
    }
    setIsLoading(true);
    actionType.current = 'updating';
    const updateConfig = getCameraConfigPayload(cameraConfig);
    dispatch(
      updateCameraSnapshotConfig(userKey, selectedCompanyId, selectedProvider, updateConfig)
    );
  }, [
    dispatch,
    userKey,
    selectedCompanyId,
    selectedProvider,
    cameraConfig,
    actionType,
    cameraConfigs
  ]);

  const cameraConfigStore = useCameraSnapshotConfig(
    actionType.current,
    selectedCompanyId,
    selectedProvider,
    userKey
  );

  useEffect(() => {
    if (!show) {
      return;
    }

    const action = actionType.current;
    if (!cameraConfigStore) {
      return;
    }

    const cameraConfigStoreStatus = cameraConfigStore?.status || {};
    const { fetching, updating, deleting } = cameraConfigStoreStatus;

    if (
      fetching === actionTypes.processing ||
      updating === actionTypes.processing ||
      deleting === actionTypes.processing
    ) {
      return;
    }

    setIsLoading(false);

    if (action === 'fetching') {
      if (fetching === actionTypes.error) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: cameraConfigStoreStatus?.fetchingError?.message,
            title: t('Home.CameraConfigLoadFailed'),
            autohide: true
          })
        );
        setCanUpdate(false);
      } else {
        setCanUpdate(true);
        if (!cameraConfigStore?.config?.value) {
          setUpdateButtonWords(t('Home.Create Config'));
        }
      }
    }

    if (action === 'updating') {
      if (updating === actionTypes.error) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: cameraConfigStoreStatus?.updatingError?.message,
            title: t('Home.CameraConfigUpdateFailed'),
            autohide: true
          })
        );
      } else {
        dispatch(
          openToast({
            type: ToastType.Success,
            title: cameraConfigStore?.config?.value
              ? t('Home.ConfigUpdated')
              : t('Home.ConfigCreated')
          })
        );
        handleHide(true);
      }
      setCanUpdate(true);
    }

    if (action === 'deleting') {
      if (updating === actionTypes.error) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: cameraConfigStoreStatus?.updatingError?.message,
            title: t('Home.ConfigDeleteFailed'),
            autohide: true
          })
        );
      } else {
        dispatch(openToast({ type: ToastType.Success, title: t('Home.ConfigDeleted') }));
        handleHide(true);
      }
      setCanUpdate(true);
    }

    if (cameraConfigStore?.config?.value) {
      setHasConfig(true);
      setCanUpdate(true);
      setUpdateButtonWords(t('Home.Update'));
      const obj = JSON.parse(cameraConfigStore.config.value);
      const timeStrs = (obj.time || '0:0').split(':');
      let newCameraConfig = {
        id: cameraConfigStore.config.id,
        week1:
          (obj.week && obj.week.weekOfMonth && obj.week.weekOfMonth.some(i => parseInt(i) === 1)) ||
          false,
        week2:
          (obj.week && obj.week.weekOfMonth && obj.week.weekOfMonth.some(i => parseInt(i) === 2)) ||
          false,
        week3:
          (obj.week && obj.week.weekOfMonth && obj.week.weekOfMonth.some(i => parseInt(i) === 3)) ||
          false,
        week4:
          (obj.week && obj.week.weekOfMonth && obj.week.weekOfMonth.some(i => parseInt(i) === 4)) ||
          false,
        week5:
          (obj.week && obj.week.weekOfMonth && obj.week.weekOfMonth.some(i => parseInt(i) === 5)) ||
          false,
        sunday:
          (obj.week && obj.week.dayOfWeek && obj.week.dayOfWeek.some(i => parseInt(i) === 1)) ||
          false,
        monday:
          (obj.week && obj.week.dayOfWeek && obj.week.dayOfWeek.some(i => parseInt(i) === 2)) ||
          false,
        tuesday:
          (obj.week && obj.week.dayOfWeek && obj.week.dayOfWeek.some(i => parseInt(i) === 3)) ||
          false,
        wednesday:
          (obj.week && obj.week.dayOfWeek && obj.week.dayOfWeek.some(i => parseInt(i) === 4)) ||
          false,
        thursday:
          (obj.week && obj.week.dayOfWeek && obj.week.dayOfWeek.some(i => parseInt(i) === 5)) ||
          false,
        friday:
          (obj.week && obj.week.dayOfWeek && obj.week.dayOfWeek.some(i => parseInt(i) === 6)) ||
          false,
        saturday:
          (obj.week && obj.week.dayOfWeek && obj.week.dayOfWeek.some(i => parseInt(i) === 7)) ||
          false,

        time: {
          hour: parseInt(timeStrs[0]),
          minute: parseInt(timeStrs[1])
        },
        timeZone: obj.timezone
      };
      //No timezone from backend, means it using defaultTimezone(timezones[0]) on creating but didn't save to backend, cover it by making one update call at this point
      if (!newCameraConfig.timeZone && defaultTimezone) {
        newCameraConfig = { ...newCameraConfig, timeZone: defaultTimezone };
        dispatch(
          updateCameraSnapshotConfig(
            userKey,
            selectedCompanyId,
            selectedProvider,
            getCameraConfigPayload(newCameraConfig)
          )
        );
      }
      setCameraConfig(newCameraConfig);
    } else {
      setHasConfig(false);
      const config = defaultTimezone
        ? { ...initialConfig, timeZone: defaultTimezone }
        : initialConfig;
      setCameraConfig(config);
      setUpdateButtonWords(t('Home.Create Config'));
    }

    actionType.current = null;
  }, [show, actionType, cameraConfigStore, dispatch, handleHide, t]);

  return (
    <Modal
      centered
      open={show}
      onCancel={() => handleHide()}
      title={t('Home.ConfigSnapshotTitle')}
      wrapClassName={styles.configModal}
      footer={
        <Space className={styles.footerBtns}>
          <Button
            type="primary"
            disabled={
              !(canUpdate && hasConfig) ||
              !canVideoEntities ||
              isLoading ||
              !isValidConfig(cameraConfig)
            }
            onClick={handleUpdating}
            id="btn_snapshotConfigUpdate"
          >
            {updateButtonWords}
          </Button>
          <Button onClick={() => handleHide()} id="btn_snapshotConfigCancel">
            {t('Common.CancelButton')}
          </Button>
        </Space>
      }
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <SnapshotConfigFilter
          isLoading={isLoading}
          companyId={selectedCompanyId}
          provider={selectedProvider}
          hasSchedule={hasConfig}
          companiesList={companiesList}
          cameraProviders={cameraProviders}
          onScheduleChanged={handleScheduleChanged}
          onCompanyChanged={handleCompanyChanged}
          onProviderChanged={handleProviderChanged}
          disabled={!canVideoEntities}
        />
        <SnapshotScheduleConfig
          isLoading={isLoading}
          show={hasConfig}
          config={cameraConfig}
          timeZones={timeZones}
          onChange={handleConfigChange}
          disabled={!canVideoEntities}
        />
      </Space>
    </Modal>
  );
}
