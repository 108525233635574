import { CellMeasurerCache } from 'react-virtualized';
import { isEqual, filter, join, isBoolean } from 'lodash';
import moment from 'moment';
import i18n from 'i18nextConfig';
import { BULK_EDIT_AUDIT_ENTITY } from 'components/auditsTable/BulkEditAudits';

export const BUTTON_IDS = {
  edit_single_device: 'bulkEditDevices_edit_single_device',
  delete_single_device: 'bulkEditDevices_delete_single_device',
  delete_multi_devices: 'bulkEditDevices_delete_multi_devices',
  edit_dropdown: 'bulkEditDevices_devices_dropdown',
  edit_company: 'bulkEditDevices_company',
  edit_type: 'bulkEditDevices_type',
  edit_model: 'bulkEditDevices_model',
  edit_vehicle: 'bulkEditDevices_vehicle',
  vehicleSelectTable_device: 'bulkEditDevices_vehicleSelectTable_device',
  vehicleSelectTable_vehicle: 'bulkEditDevices_vehicleSelectTable_vehicle',
  edit_fleets: 'bulkEditDevices_fleets',
  edit_phone: 'bulkEditDevices_phone',
  edit_gpio_configuration: 'bulkEditDevices_gpio_configuration',
  edit_notes: 'bulkEditDevices_notes'
};

export const bulkEditCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 100
});

export const BULK_EDIT_ENTITY = {
  COMPANY: {
    entity: 'COMPANY',
    key: 'companyId',
    getPayload(entityValue, device) {
      //reset fleets=[] when company changed
      return {
        id: device.id,
        companyId: entityValue,
        fleets: []
      };
    },
    compareFn: (id, newId) => {
      return String(id) !== String(newId);
    }
  },
  TYPE: {
    entity: 'TYPE',
    key: 'typeId',
    getPayload(entityValue, device) {
      // {deviceTypeModel:[typeId,modelId],meta:{cameraSensitivity,volume}}
      const deviceTypeModel = entityValue?.deviceTypeModel || [];
      const meta = entityValue?.meta || {};
      const ret = {
        id: device.id,
        type: {
          id: deviceTypeModel[0]
        },
        model: {
          id: deviceTypeModel[1]
        }
      };
      if (meta.cameraSensitivity) {
        ret.cameraSensitivity = meta.cameraSensitivity;
      }
      if (meta.volume) {
        ret.volume = meta.volume;
      }
      if (meta.audioAlertsEnabled) {
        ret.audioAlertsEnabled = meta.audioAlertsEnabled;
      }
      if (isBoolean(meta.enableDriverCamera)) {
        ret.enableDriverCamera = meta.enableDriverCamera;
      }
      return ret;
    },
    compareFn: (id, newId) => {
      return String(id) !== String(newId);
    }
  },
  MODEL: {
    entity: 'MODEL',
    key: 'modelId',
    getPayload(entityValue, device) {
      //{modelId,meta:{cameraSensitivity,volume}}
      const modelId = entityValue?.modelId || [];
      const meta = entityValue?.meta || {};
      const ret = {
        id: device.id,
        model: {
          id: modelId
        }
      };
      if (meta.cameraSensitivity) {
        ret.cameraSensitivity = meta.cameraSensitivity;
      }
      if (meta.volume) {
        ret.volume = meta.volume;
      }
      if (meta.audioAlertsEnabled) {
        ret.audioAlertsEnabled = meta.audioAlertsEnabled;
      }
      if (isBoolean(meta.enableDriverCamera)) {
        ret.enableDriverCamera = meta.enableDriverCamera;
      }
      return ret;
    },
    compareFn: (id, newId) => {
      return String(id) !== String(newId);
    }
  },
  VEHICLE: {
    entity: 'VEHICLE',
    key: 'vehicleId',
    getPayload(entityValue, device) {
      //By selecting a vehicle, device's fleets will be set to the vehicle's existing fleets
      const vehicleId = entityValue[device.id]?.vehicleId;
      if (vehicleId) {
        return {
          id: device.id,
          vehicle: {
            id: vehicleId
          },
          copyVehicleFleets: true,
          fleets: []
        };
      } else {
        return {
          id: device.id,
          vehicle: null,
          copyVehicleFleets: false
        };
      }
    },
    compareFn: (id, newId) => {
      return String(id) !== String(newId);
    }
  },
  FLEETS: {
    entity: 'FLEETS',
    key: 'fleetIds',
    getPayload(entityValue, device) {
      return {
        id: device.id,
        fleets: entityValue?.map(fleetId => ({ id: fleetId })) || []
      };
    },
    compareFn: (ids, newIds) => {
      if (!ids) {
        return !!newIds?.length;
      } else if (!newIds) {
        return !!ids?.length;
      }
      return ids.length !== newIds.length || !isEqual(ids, newIds);
    }
  },
  PHONE_NUMBER: {
    entity: 'PHONE_NUMBER',
    key: 'phone',
    compareFn: (num, newNum) => {
      return String(num) !== String(newNum);
    }
  },
  GPIO_CONFIGURATION: {
    entity: 'GPIO_CONFIGURATION',
    key: 'gpioTemplateId',
    compareFn: (num, newNum) => {
      return String(num) !== String(newNum);
    },
    getPayload(entityValue, device) {
      return {
        id: device.id,
        gpioTemplateId: entityValue
      };
    }
  },
  NOTES: {
    entity: 'NOTES',
    key: 'note',
    compareFn: (note, newNote) => {
      return String(note) !== String(newNote);
    },
    getPayload(entityValue, device, { getDateTime }) {
      const note = `${device.note || ''}
*Bulk added note on ${getDateTime(moment.now())}:*
${entityValue || ''}`;
      return { id: device.id, note };
    }
  }
};

export const getRouteGuardProps = t => ({
  title: t('RouteGuard.Title'),
  message: t('RouteGuard.Message'),
  leaveBtnText: t('RouteGuard.LeavePage'),
  stayBtnText: t('RouteGuard.StayOnPage')
});

export const getDirtyRows = tableMap => filter(tableMap, device => device.isDirty) || [];
export const getSelectedRows = tableMap => filter(tableMap, device => device.checked) || [];

export const toBulkEditTableData = (devices = []) =>
  devices.map(device => {
    const rawData = {
      name: device.name,
      id: device.id,
      imei: device.imei,
      serialNumber: device.serialNumber,
      fleetIds: device.fleetInfo?.filter(f => !!f.id).map(f => f.id),
      companyId: device.companyId,
      typeId: device.type?.id,
      modelId: device.model?.id,
      modelName: device.model?.name,
      vehicleId: device.vehicle?.id,
      phone: device.phone,
      gpioTemplate: device?.gpioTemplate?.name,
      note: device.note,
      services: device?.services
    };
    return {
      ...rawData,
      checked: false,
      isDirty: false,
      getIsDirty(entity, entityValue) {
        return BULK_EDIT_ENTITY[entity]?.compareFn(
          rawData[BULK_EDIT_ENTITY[entity].key],
          entityValue
        );
      }
    };
  }, {});

export const toBulkUpdatePayload = (
  entity,
  entityValue,
  selectedRows = [],
  extra = { getDateTime: date => date }
) => {
  const getEntityPayload =
    BULK_EDIT_ENTITY[entity].getPayload ||
    ((entityValue, device, extra) => ({
      id: device.id,
      [BULK_EDIT_ENTITY[entity].key]: entityValue
    }));
  return selectedRows.map(device => getEntityPayload(entityValue, device, extra));
};

const spliter = '-';
export const BULK_EDIT_Entity_OF_Audit = {
  company_id: {
    title: 'Entity.Company',
    key: 'company_id'
  },
  type: {
    title: 'Devices.ActualForm.TypeLabel',
    key: 'type',
    getChangeValueKey(types = []) {
      return join(
        types.map(type => type?.id || ''),
        spliter
      );
    }
  },
  model: {
    title: 'Devices.ActualForm.ModelLabel',
    key: 'model',
    getChangeValueKey(models = []) {
      return join(
        models.map(model => model?.id || ''),
        spliter
      );
    }
  },
  vehicle: {
    title: 'Entity.Vehicle',
    key: 'vehicle',
    getChangeValueKey(vehicles = []) {
      return join(
        vehicles.map(v => v?.id || v),
        spliter
      );
    }
  },
  fleets: {
    title: 'Fleets.Fleets',
    key: 'fleets',
    getChangeValueKey(fleets = []) {
      //[[],[added],[removed],[]]
      return join(
        fleets.map(f => f?.id || f),
        spliter
      );
    }
  },
  phone: {
    title: 'Devices.ActualForm.PhoneLabel',
    key: 'phone'
  },
  note: {
    title: 'Devices.ActualForm.NotesLabel',
    key: 'note'
  },
  agreements: {
    title: 'Agreement.Agreements',
    key: 'agreements',
    getChangeValueKey(agreements = []) {
      return join(
        agreements.map(f => f?.id || f),
        spliter
      );
    }
  },
  dutyType: {
    title: can => 'Devices.ActualForm.CameraSensitivity',
    key: 'dutyType',
    getChangeValue(changeValueByKey, can) {
      return [
        changeValueByKey?.[0] ? i18n.t(`CompanyConfig.IQCamera.${changeValueByKey?.[0]}`) : '',
        changeValueByKey?.[1] ? i18n.t(`CompanyConfig.IQCamera.${changeValueByKey?.[1]}`) : ''
      ];
    }
  },
  deviceAudioVolume: {
    title: 'CompanyConfig.IQCamera.Device Volume',
    key: 'deviceAudioVolume'
  },
  audioAlertsEnabled: {
    title: 'Devices.ActualForm.CameraAudioAlerts',
    key: 'audioAlertsEnabled',
    getChangeValueKey(typeKeys = []) {
      return join(typeKeys, spliter);
    },
    getChangeValue(changeValueByKey = []) {
      let from = '',
        to = '';
      try {
        from = (JSON.parse(changeValueByKey?.[0]) || []).map(typeKey =>
          i18n.t(`Devices.View.CameraAudioAlert.${typeKey}`)
        );
      } catch (error) {
        from = '';
      }
      try {
        to = (JSON.parse(changeValueByKey?.[1]) || []).map(typeKey =>
          i18n.t(`Devices.View.CameraAudioAlert.${typeKey}`)
        );
      } catch (error) {
        to = '';
      }
      return [from, to];
    }
  },
  enableDriverCamera: {
    title: 'Devices.ActualForm.DriverCamera',
    key: 'enableDriverCamera'
  },
  status: {
    title: 'Common.status',
    key: 'status'
  }
};
