export function vehicleTypeObjectToDBO(vehicleType) {
  return {
    id: vehicleType.id,
    name: vehicleType.name,
    code: vehicleType.code,
    company_id: vehicleType.companyId,
    description: vehicleType.description,
    global: vehicleType.global,
    icon: vehicleType.icon,
    status: vehicleType.status,
    created_at: vehicleType.createdAt,
    updated_at: vehicleType.updatedAt,
    fule_type: vehicleType.fuleType,
    speed_limit_class: vehicleType.speedLimitClass
  };
}

export function vehicleTypeDBOToObject(vehicleType) {
  return {
    id: vehicleType.id,
    name: vehicleType.name,
    code: vehicleType.code,
    companyId: vehicleType.company_id,
    description: vehicleType.description,
    global: vehicleType.global,
    icon: vehicleType.icon,
    status: vehicleType.status,
    createdAt: vehicleType.created_at,
    updatedAt: vehicleType.updated_at,
    fuleType: vehicleType.fuleType,
    speedLimitClass: vehicleType.speed_limit_class
  };
}
