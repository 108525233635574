import React, { useEffect, useMemo, useState } from 'react';
import { Drawer, Form, Tabs } from 'antd';
import styles from '../Devices.module.scss';
import cn from 'classnames';
import {
  useGetSpeedAssistDeviceSelectIdQuery,
  useGetSpeedAssistValuesQuery
} from 'services/nextgen/ngSpeedAssistConfigurationApi';
import {
  useGetAssetDeviceSelectIdQuery,
  useGetAssetValuesQuery
} from 'services/nextgen/ngAssetConfigurationApi';
import {
  useGetDriverIdDeviceSelectIdQuery,
  useGetDriverValuesQuery
} from 'services/nextgen/ngDriverIdConfigurationApi';

import { useTranslation } from 'react-i18next';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useAvailableServices } from '../hooks/useAvailableServices';
import services from 'features/permissions/services';

export const Configure = ({ open, onClose, size, width, data }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState('left');
  const currentCompany = useCurrentCompany();
  const localization = useLocalization();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [initialDriverIdValues, setDriverIdInitialValues] = useState({});
  const [initialValuesAssets, setInitialValuesAssets] = useState({});
  const availableServicesMap = useAvailableServices(data);
  const [activeTab, setActiveTab] = useState('');

  const sortedServices = useMemo(
    () =>
      Array.from(availableServicesMap)
        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
        .map(([, value]) => value),
    [availableServicesMap]
  );

  useEffect(() => {
    const firstService = sortedServices[0];
    setActiveTab(firstService?.key ?? '');
  }, [sortedServices]);

  const { data: selectedId, refetch: refetchSelectId } = useGetSpeedAssistDeviceSelectIdQuery(
    { companyId: currentCompany?.id, deviceId: data.id },
    { skip: !open || currentCompany?.id === undefined }
  );

  const {
    data: selectedDriverId,
    refetch: refetchSelectDriverId
  } = useGetDriverIdDeviceSelectIdQuery(
    { companyId: currentCompany?.id, deviceId: data.id },
    { skip: !open || currentCompany?.id === undefined }
  );

  const { data: selectedIdAssets, refetch: refetchSelectIdAssets } = useGetAssetDeviceSelectIdQuery(
    { companyId: currentCompany?.id, deviceId: data.id },
    { skip: !open || currentCompany?.id === undefined }
  );

  const {
    data: speedAssistValues,
    refetch: refetchSpeedAssistValues
  } = useGetSpeedAssistValuesQuery({ deviceId: data.id }, { skip: !open });

  const { data: assetsValues, refetch: refetchAssetsValues } = useGetAssetValuesQuery(
    { deviceId: data.id },
    { skip: !open }
  );

  const { data: DriverIdValues, refetch: refetchDriverIdValues } = useGetDriverValuesQuery(
    { deviceId: data.id },
    { skip: !open }
  );

  useEffect(() => {
    if (open) {
      refetchSelectId();
      refetchSpeedAssistValues();
    }
  }, [open, refetchSelectId, refetchSpeedAssistValues]);

  useEffect(() => {
    if (open) {
      refetchSelectDriverId();
      refetchDriverIdValues();
    }
  }, [open, refetchSelectDriverId, refetchDriverIdValues]);

  useEffect(() => {
    if (open) {
      refetchSelectIdAssets();
      refetchSpeedAssistValues();
    }
  }, [open, refetchSelectIdAssets, refetchAssetsValues]);

  useEffect(() => {
    if (open && DriverIdValues && availableServicesMap.has(services.DRIVERPIN)) {
      const updatedValues = {
        driverTemplateId: selectedDriverId?.id || '',
        DelayAutoLogOff:
          DriverIdValues?.find(config => config.key === 'delayed.logoff')?.value || 0,
        DriverLoggedOn:
          DriverIdValues?.find(config => config.key === 'driver.loggedoff.threshold')?.value || 300,
        AudiableAlert:
          DriverIdValues?.find(config => config.key === 'driver.pattern.gpio')?.value === '8080'
            ? true
            : false
      };

      setDriverIdInitialValues(updatedValues);
      form.setFieldsValue({
        ...updatedValues,
        'delayed.logoff': updatedValues.DelayAutoLogOff,
        'driver.loggedoff.threshold': updatedValues.DriverLoggedOn,
        'driver.pattern.gpio': updatedValues.AudiableAlert
      });
    }
  }, [open, DriverIdValues, selectedDriverId, availableServicesMap]);

  useEffect(() => {
    if (open && speedAssistValues && availableServicesMap.has(services.SPEEDASSIST)) {
      const updatedValues = {
        speedassistTemplateId: selectedId?.id || '',
        maxThreshold: localization.convertSpeed(
          speedAssistValues?.find(config => config.key === 'company.max.threshold')?.value || 0
        ),
        maxOffset: localization.convertSpeed(
          speedAssistValues?.find(config => config.key === 'company.max.offset')?.value || 0
        ),
        maxDuration:
          speedAssistValues?.find(config => config.key === 'company.max.duration')?.value || '',
        signpostedDisable:
          speedAssistValues?.find(config => config.key === 'signposted.disable')?.value ===
            'true' || false,
        companyManaged:
          speedAssistValues?.find(config => config.key === 'company.managed')?.value === 'true' ||
          false
      };

      setInitialValues(updatedValues);
      form.setFieldsValue({
        ...updatedValues,
        'company.max.threshold': updatedValues.maxThreshold,
        'company.max.offset': updatedValues.maxOffset,
        'company.max.duration': updatedValues.maxDuration,
        'signposted.disable': updatedValues.signpostedDisable,
        'company.managed': updatedValues.companyManaged
      });
    }
  }, [open, speedAssistValues, selectedId, availableServicesMap]);

  useEffect(() => {
    if (open && assetsValues && availableServicesMap.has(services.ASSETSUSAGE)) {
      const updatedValues = {
        assetsTemplateId: selectedId?.id || '',
        radioSelected:
          assetsValues?.find(config => config.key === 'asset.selection')?.value || 'movement',
        inUse: assetsValues?.find(config => config.key === 'movement.inuse')?.value || false,
        gpioChannel: assetsValues?.find(config => config.key === 'gpio.channel')?.value || '',
        gpioStatus:
          assetsValues?.find(config => config.key === 'gpio.status')?.value === 'true' || '',
        rpmValue: assetsValues?.find(config => config.key === 'rpm.over')?.value === 'true' || 0,
        rpmSeconds: assetsValues?.find(config => config.key === 'rpm.min')?.value === 'true' || 0
      };

      setInitialValuesAssets(updatedValues);
      form.setFieldsValue({
        ...updatedValues,
        'asset.selection': updatedValues.radioSelected,
        'movement.inuse': updatedValues.inUse,
        'gpio.channel': updatedValues.gpioChannel,
        'gpio.status': updatedValues.gpioStatus,
        'rpm.over': updatedValues.rpmValue,
        'rpm.min': updatedValues.rpmSeconds
      });
    }
  }, [open, assetsValues, selectedIdAssets, availableServicesMap]);

  const handleTabChange = key => {
    setActiveTab(key);
  };

  const ActiveComponent = useMemo(() => availableServicesMap.get(activeTab)?.Component || null, [
    activeTab
  ]);

  return (
    <Drawer
      width={width}
      placement="right"
      onClose={onClose}
      open={open}
      size={size}
      bodyStyle={{ padding: '8px' }}
      headerStyle={{
        borderBottom: 'none',
        padding: '0px',
        flexDirection: 'column',
        marginTop: '15px',
        alignItems: 'end'
      }}
    >
      <div className={styles.drawer}>
        {data.name + ' ' + t('Devices.DeviceConfigurations.Configurations')}
      </div>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flex: '1' }}>
          <Tabs
            tabPosition={mode}
            onChange={handleTabChange}
            className={styles.configureTabs}
            moreIcon={null}
            activeKey={activeTab}
            items={sortedServices.map(({ key, tabTitle }) => {
              return {
                key,
                label: (
                  <div className={cn(styles.tab, { [styles.active]: activeTab === key })}>
                    {tabTitle}
                  </div>
                )
              };
            })}
          ></Tabs>
        </div>
        <div
          style={{
            flex: '3',
            padding: '5px 8px 0px 8px',
            overflowY: 'auto',
            marginTop: '10px',
            marginRight: '13px'
          }}
          className={styles.form}
        >
          {/* NOTE: The conditional props here are passed to ensure the proper functioning of previous components.
              In the future, for each Service-specific Component, apart from "data" and "onClose",
              any additional required data should be managed within the respective Service Component itself.
              This approach helps avoid mixing the logic of all services in this component. */}
          {ActiveComponent && (
            <>
              <div className={styles.formHeader}>
                {availableServicesMap.get(activeTab)?.tabTitle}
              </div>
              <div className={styles.formWrapper}>
                <ActiveComponent
                  key={`key - ${open}`}
                  data={data}
                  onClose={onClose}
                  {...(activeTab === services.DRIVERPIN && {
                    initialDriverIdValues,
                    selectedId: selectedDriverId
                  })}
                  {...(activeTab === services.SPEEDASSIST && { initialValues, selectedId })}
                  {...(activeTab === services.ASSETSUSAGE && {
                    initialValues: initialValuesAssets,
                    selectedId: selectedIdAssets
                  })}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Drawer>
  );
};
