import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BulkEditAuditsTable, BULK_EDIT_AUDIT_ENTITY } from 'components/auditsTable/BulkEditAudits';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useBulkEditAudits, useIsBulkAuditsFetching } from 'features/audits';
import { useCan } from 'features/permissions';
import { BulkEditAuditColumns, getAuditChanges } from 'features/bulkEdit/helpers';
import { bulkEditParams } from './helper';

export const UsersBulkEditAudit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const can = useCan();

  const audits = useBulkEditAudits(BULK_EDIT_AUDIT_ENTITY.USER);
  const isLoading = useIsBulkAuditsFetching(BULK_EDIT_AUDIT_ENTITY.USER);

  useEffect(() => {
    dispatch(setPageTitle(t('Audits.UsersBulkEditAuditHistory')));
    dispatch(setBackButton(true));
  }, [t, dispatch]);

  const dataSource = useMemo(
    () =>
      (
        audits?.map(audit =>
          getAuditChanges({ audit, can }, BULK_EDIT_AUDIT_ENTITY.USER, bulkEditParams)
        ) || []
      ).filter(audit => !!audit?.changes?.length),
    [audits]
  );
  const columns = useMemo(
    () => Object.values(BulkEditAuditColumns).map(col => ({ ...col, title: t(col.title) })),
    [t]
  );

  return (
    <BulkEditAuditsTable
      dataSource={dataSource}
      columns={columns}
      entity={BULK_EDIT_AUDIT_ENTITY.USER}
      isLoading={isLoading}
    />
  );
};
