import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const carriersDataAdapter = reduxAdapter({
  persist: async carriers => {
    if (carriers && carriers.length) {
      await TNDataService.insertOrUpdateCarriersInBulk(carriers);
    }
  },
  load: async () => {
    const carriers = await TNDataService.getAllCarriers();
    return carriers;
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_carriers');
    return result;
  },
  updateLastUpdatedDate: async lastpUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_carriers', lastpUpdatedDate);
    return result;
  }
});

export { carriersDataAdapter };
