//import Worker from './worker-loader!./'
let sqliteSqlWorker = null;

export function init() {
  if (sqliteSqlWorker === null) {
    console.log('load worker');
    const workerUrl = new URL('/sqlite/sqliteWorker.js', window.location.origin);
    workerUrl.search = '?sqlite3.dir=/sqlite';
    sqliteSqlWorker = new Worker(workerUrl);
    console.log('load worker done');
  }
  return sqliteSqlWorker;
}
