import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'antd';

import { useCurrentCompany } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { fetchDeviceSnapshots } from 'features/dashboard/dashboardSlice';
import { useSnapshotEntityCameraStatus } from '../../hooks/useSnapshotEntityCameraStatus';
import { PositionedFootages } from '../../PositionedFootages/PositionedFootages';
import { SnapshotInfoCard } from './SnapshotInfoCard';
import { SnapshotMenu } from './SnapshotMenu';
import styles from '../../Snapshots.module.scss';
import { getAvailablePositionedFootages, sizeCalculator } from '../../helpers';

function _SnapshotRow({
  t,
  style,
  width,
  vehicleId,
  deviceId,
  deviceName,
  driverId,
  driverLoginStatus,
  nodeType,
  snapshotYear,
  snapshotMonth,
  devices,
  deviceModelName,
  deviceTypeCode,
  messagingDevice,
  onMessageVehicle = () => {},
  onRequestFootage = () => {},
  showRefresh = false,
  positionedFootages
}) {
  const { infoCardWidth, footageSize, elipsisBtnWidth } = useMemo(
    () => sizeCalculator(width).sizeInRow,
    [width]
  );
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const currentCompany = useCurrentCompany();

  const { availablePositions, getIsPositionFailure } = useSnapshotEntityCameraStatus({
    deviceId,
    nodeType,
    deviceModelName,
    deviceTypeCode,
    devices
  });
  const [openElipsis, setOpenElipsis] = useState(false);

  const { disableMenu, ...snapshotMenuProps } = useMemo(
    () => ({
      onRefresh: () => {
        if (nodeType === 'Device') {
          dispatch(
            fetchDeviceSnapshots(userKey, deviceId, snapshotYear, snapshotMonth, currentCompany?.id)
          );
        } else {
          devices?.forEach(d =>
            dispatch(
              fetchDeviceSnapshots(userKey, d.id, snapshotYear, snapshotMonth, currentCompany?.id)
            )
          );
        }
        setOpenElipsis(false);
      },
      onMessageVehicle: () => {
        if (messagingDevice?.id && onMessageVehicle) {
          onMessageVehicle({ id: vehicleId, deviceId: messagingDevice.id });
        }
        setOpenElipsis(false);
      },
      onRequestFootage: (vehicleId, deviceId) => {
        if (onRequestFootage) {
          onRequestFootage(vehicleId, deviceId);
        }
        setOpenElipsis(false);
      },
      titles: {
        refresh: t('Common.Refresh'),
        messaging:
          nodeType === 'Vehicle' ? t('Tracking.MessageVehicle') : t('Tracking.MessageDevice'),
        viewVehicle: t(`Home.View Vehicle`),
        viewDevice: t(`Home.View Device`),
        viewSnapshotHistory: t('Home.ViewSnapshotHistory')
      },
      canRefresh: showRefresh && deviceId,
      canMessaging: messagingDevice?.id && onMessageVehicle,
      devices,
      deviceId,
      messagingDevice,
      vehicleId,
      deviceName,
      nodeType,
      disableMenu: !(nodeType === 'Device' || devices?.length > 0)
    }),
    [
      t,
      dispatch,
      devices,
      nodeType,
      userKey,
      currentCompany?.id,
      deviceId,
      vehicleId,
      snapshotYear,
      snapshotMonth,
      onRequestFootage,
      onMessageVehicle,
      messagingDevice
    ]
  );

  return (
    <div style={{ ...style, padding: '4px 10px' }}>
      <Row className={styles.snapshotRow} wrap={false}>
        <Col
          style={{ width: infoCardWidth, minWidth: infoCardWidth }}
          className={`${styles.infoCard} ${!!driverId ? styles.loggedIn : ''}`}
        >
          <SnapshotInfoCard
            driverLoginStatus={driverLoginStatus}
            nodeType={nodeType}
            deviceId={deviceId}
            vehicleId={vehicleId}
            deviceName={deviceName}
            deviceModelName={deviceModelName}
            deviceTypeCode={deviceTypeCode}
            devices={devices}
            viewSnapshotHistoryTitle={t('Home.ViewSnapshotHistory')}
          />
        </Col>
        <Col flex={1} className={styles.positionedFootages}>
          <PositionedFootages
            t={t}
            footages={getAvailablePositionedFootages(positionedFootages, availablePositions)}
            getIsPositionFailure={getIsPositionFailure}
            footageSize={footageSize}
            snapshotYear={snapshotYear}
            snapshotMonth={snapshotMonth}
          />
        </Col>
        <Col
          className={styles.elipsismenu}
          style={{ width: elipsisBtnWidth, minWidth: elipsisBtnWidth }}
        >
          {!disableMenu && (
            <SnapshotMenu {...snapshotMenuProps} open={openElipsis} onOpenChange={setOpenElipsis} />
          )}
        </Col>
      </Row>
    </div>
  );
}
export const SnapshotRow = React.memo(_SnapshotRow);
