import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, Row, Col, Input } from 'antd';

import { useTranslation } from 'react-i18next';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';
import { GridColumnEditor } from './GridColumnEditor';
import { useOneWireMultiProbeFields } from 'features/company_config';
import { useCurrentCompany } from 'features/company/companySlice';

import {
  useUserGridSettings,
  useIsUserGridSettingsUpdating,
  updateUserGridSettings,
  DEFAULT_VIEW_NAME
} from 'features/user/userGridSettingsSlice';

import { getIncludedAndAvailableColumns, validateViewName } from './ViewsConfigModalHelpers';

import styles from './ViewsConfigModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const AddViewModal = ({
  allColumns,
  gridSettingsKey,
  defaultGridConfig,
  isOpen = false,
  onClose,
  oneWireMultiTempData
}) => {
  const { t } = useTranslation();
  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const isUserGridSettingsUpdating = useIsUserGridSettingsUpdating(gridSettingsKey);

  const defaultColumnsLabels = allColumns
    .filter(column => (defaultGridConfig?.views?.Default.columns || []).includes(column.key))
    .map(column => column.label);

  const [includedColumns, setIncludedColumns] = useState([]);
  const [combinedColumns, setCombinedColumns] = useState([]);
  const [oneWireValues, setOneWireValues] = useState([]);
  const [newViewName, setNewViewName] = useState('');

  // use default view as a starting point for new view
  const defaultUserGridSettings = JSON.parse(JSON.stringify(defaultGridConfig));
  const defaultView = defaultUserGridSettings.views[DEFAULT_VIEW_NAME];
  const [viewConfig, setViewConfig] = useState(defaultView);

  const handleViewNameChange = e => {
    const newName = e.target.value;
    setNewViewName(newName);
  };

  const updateGroupBy = groupBy => {
    let newViewConfig = JSON.parse(JSON.stringify(viewConfig)); // deep copy
    newViewConfig.groupBy = groupBy;
    setViewConfig(newViewConfig);
  };

  const handleCancel = () => {
    // Todo: Warn user if there are unsaved changes

    onClose();
  };

  const handleAddViewModalSave = () => {
    // Validation view name before saving
    if (!validateViewName(newViewName, userGridSettings.config, null, dispatch, t)) {
      return;
    }

    // Make a copy userGridSettings for editing
    let newUserGridSettings;
    if (!userGridSettings.config) {
      newUserGridSettings = JSON.parse(JSON.stringify(defaultGridConfig)); // deep copy
      delete newUserGridSettings.views[DEFAULT_VIEW_NAME]; // When doing add, don't need default since we will be adding a new one
    } else {
      newUserGridSettings = JSON.parse(JSON.stringify(userGridSettings.config)); // deep copy
    }

    // Add new view
    newUserGridSettings.views[newViewName] = JSON.parse(JSON.stringify(viewConfig)); // deep copy

    // Add configured columns
    newUserGridSettings.views[newViewName].includedColumns = combinedColumns.map(col => ({
      key: col.key,
      width: col.width
    }));

    // Save old columns schema for backwards compatibility
    newUserGridSettings.views[newViewName].columns = combinedColumns.map(c => c.key);

    // Update selected view to be this new one
    newUserGridSettings.selectedView = newViewName;

    dispatch(updateUserGridSettings(newUserGridSettings, gridSettingsKey, onClose, true));
  };

  const onResetSelection = () => {
    const defaultColumnKeys = defaultGridConfig?.views?.Default.columns || [];
    const defaultColumns = allColumns.filter(column => defaultColumnKeys.includes(column.key));
    setIncludedColumns(defaultColumns);
  };

  useEffect(() => {
    let combinedColumnsWithOutSelectedValue = [
      ...allColumns,
      ...Object.values(oneWireMultiTempData || {}).map(value => ({
        title: value,
        label: value,
        key: value,
        width: 150,
        isConfigurable: true
      }))
    ];
    combinedColumnsWithOutSelectedValue = combinedColumnsWithOutSelectedValue.filter(
      (item, index, self) => index === self.findIndex(t => t.title === item.title)
    );
    // Get default included and available columns
    const columnSettings = getIncludedAndAvailableColumns(
      null,
      combinedColumnsWithOutSelectedValue,
      t
    );
    setIncludedColumns(columnSettings.includedColumns);
    setOneWireValues(combinedColumnsWithOutSelectedValue);
  }, [oneWireMultiTempData]);

  const groupBy = viewConfig.groupBy;

  return (
    <Modal
      title={t('Tracking.ViewsConfig.AddNewView')}
      open={isOpen}
      centered={true}
      closable={false}
      width={500}
      footer={null}
      wrapClassName={styles.viewsConfigDialog}
    >
      <>
        <Row wrap={false} className={styles.viewsConfigRow}>
          <Col span={24}>
            <div>
              <span>{t('Tracking.ViewsConfig.NewViewName')}</span>
              <span className={styles.newViewNameStar}> *</span>
            </div>
            <Input
              className={styles.viewNameEdit}
              value={newViewName}
              placeholder={t('Common.TypeHere')}
              onChange={e => handleViewNameChange(e)}
            />
          </Col>
        </Row>
        <GridColumnEditor
          allColumns={oneWireValues}
          includedColumns={includedColumns}
          setIncludedColumns={setIncludedColumns}
          groupBy={groupBy}
          updateGroupBy={updateGroupBy}
          defaultColumnsLabels={defaultColumnsLabels}
          onResetSelection={onResetSelection}
          oneWireMultiFilterValues={oneWireMultiTempData}
          setCombinedColumns={setCombinedColumns}
        />
        <Row wrap={false}>
          <Col span={24}>
            <Button
              type="primary"
              size="middle"
              loading={isUserGridSettingsUpdating}
              className={styles.saveButton}
              id={BUTTON_IDS.saveAddViewModal}
              onClick={handleAddViewModalSave}
            >
              {t('Common.Save')}
            </Button>
            <Button
              size="medium"
              id={BUTTON_IDS.cancelAddViewModal}
              className={styles.cancelButton}
              onClick={handleCancel}
            >
              {t('Common.Cancel')}
            </Button>
          </Col>
        </Row>
      </>
    </Modal>
  );
};
