// Author: Prasad K
// Date: 2023-10-12
// Description: This component handles the scheduled report form functionality using Formly.js.

import React, { useEffect, useMemo, useState } from 'react';
import { createForm } from '@formily/core';
import { Form, Input, Select, Radio, FormItem, DatePicker } from '@formily/antd';
import { Field } from '@formily/react';
import { Button, Card, Tooltip, Tag } from 'antd';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useSubCompanies } from 'features/company/companySlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import DynamicFormBuilder from './DynamicFormBuilder';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import styles from './ScheduledReportForm.module.scss';
import dayjs from 'dayjs';

const ScheduledReportForm = ({ initialValues, exisitngReportNames, onSubmit, onClose }) => {
  const subCompanies = useSubCompanies();
  const fleets = useFleets();
  const form = useMemo(() => createForm({ initialValues }), []);
  const { t } = useTranslation();
  const localization = useLocalization();
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [fleetOptions, setFleetOptions] = useState([]);
  const [selectedFleets, setSelectedFleets] = useState([]);
  const [recipientList, setRecipientList] = useState([]);
  const dateFormat =
    localization?.formats?.time?.formats?.dby?.toUpperCase() + ' HH:mm' || 'YYYY-MM-DD HH:mm';

  // Prepare company options with proper naming
  const companyOptions = useMemo(
    () =>
      subCompanies.map(company => ({
        label: company.name,
        value: company.id
      })),
    [subCompanies]
  );

  // Reset state whenever `schedule` changes
  useEffect(() => {
    setRecipientList(initialValues?.recipientList);
    setSelectedCompanies(initialValues?.companies);
    setSelectedFleets(initialValues?.fleets);
    form.setValues(initialValues);

    if (initialValues?.nextPeriodFrom === '') handleFrequencyChange(initialValues?.frequency);
  }, [initialValues, form]);

  // Update nextPeriodFrom and nextPeriodTo on frequency change
  const handleFrequencyChange = frequency => {
    const now = dayjs();
    const nextPeriodTo = now.startOf('day')?.format(dateFormat); // Default "To" is midnight today (start of today);
    let nextPeriodFrom;
    switch (frequency) {
      case 'ONCE_OFF':
      case 'DAILY':
        nextPeriodFrom = now.subtract(1, 'day').startOf('day');
        break;
      case 'WEEKLY':
        nextPeriodFrom = now.subtract(1, 'week').startOf('day');
        break;
      case 'MONTHLY':
        nextPeriodFrom = now.subtract(1, 'month').startOf('day');
        break;
      default:
        nextPeriodFrom = now.subtract(1, 'day').startOf('day');
        break;
    }
    form.setValues({
      ...form.getState().values,
      nextPeriodFrom: nextPeriodFrom?.format(dateFormat),
      nextPeriodTo: nextPeriodTo
    });
  };

  // Dynamically update fleet options based on selected companies
  useEffect(() => {
    const currentCompanies = form.getState(state => state.values?.companies || []);
    if (currentCompanies?.length === 0) {
      form.setFieldState('fleets', state => {
        state.dataSource = [{ label: t('Common.NoFleet'), value: -1 }];
        state.value = []; //
        state.disabled = true;
      });
      setFleetOptions([{ label: t('Common.NoFleet'), value: -1 }]); // Ensure fleet options are cleared
    } else {
      const filteredFleets = fleets.filter(fleet => selectedCompanies.includes(fleet.company?.id));

      const updatedFleetOptions = filteredFleets.map(fleet => {
        const company = subCompanies.find(subCompany => subCompany.id === fleet.company.id);
        return {
          label: `${fleet.name} (${company?.name ?? 'Unknown Company'})`,
          value: fleet.id
        };
      });

      form.setFieldState('fleets', state => {
        state.dataSource =
          updatedFleetOptions.length > 0
            ? updatedFleetOptions
            : [{ label: t('Common.NoFleet'), value: -1 }];
        state.disabled = false;
      });

      setFleetOptions(
        updatedFleetOptions.length > 0
          ? updatedFleetOptions
          : [{ label: t('Common.NoFleet'), value: -1 }]
      );
    }
  }, [selectedCompanies, fleets, initialValues]);

  // Generic toggle selection method for both companies and fleets
  const toggleSelection = (selectedItems, options, setState, fieldName) => {
    if (selectedItems.length === options.length) {
      // Deselect all
      setState([]);
      form.setFieldState(fieldName, state => {
        state.value = [];
      });
    } else {
      // Select all
      const allValues = options.map(option => option.value);
      setState(allValues);
      form.setFieldState(fieldName, state => {
        state.value = allValues;
      });
    }
  };

  // Toggle all companies
  const toggleAllCompanies = () => {
    const currentCompanies = form.getState(state => state.values?.companies || []);
    toggleSelection(currentCompanies, companyOptions, setSelectedCompanies, 'companies');
  };

  // Toggle all fleets
  const toggleAllFleets = () => {
    const currentFleets = form.getState(state => state.values?.fleets || []);
    const currentOptions = form.getState(state => state.fields?.fleets?.dataSource || []);
    toggleSelection(currentFleets, currentOptions, setSelectedFleets, 'fleets');
  };

  const formatDateForServer = date => (date ? dayjs(date).toISOString() : null);

  const handleSubmit = async () => {
    const values = await form.submit();
    const {
      companies,
      fleets,
      nextRunAt,
      endAt,
      nextPeriodFrom,
      nextPeriodTo,
      recipientList,
      ...restValues
    } = values;

    const enrichedData = {
      ...restValues,
      nextRunAt: formatDateForServer(nextRunAt),
      endAt: formatDateForServer(endAt),
      nextPeriodFrom: formatDateForServer(nextPeriodFrom),
      nextPeriodTo: formatDateForServer(nextPeriodTo),
      filters: JSON.stringify({
        companyIds: companies,
        fleetIds: fleets
      }),
      otherEmails: recipientList + '',
      source: 'API',
      type: 'Ng::Report::InstallationEvent'
    };

    onSubmit(enrichedData);
  };

  // Email validation regex
  const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Validate recipient list
  const validateRecipientList = (value, existingList) => {
    const cleanedList = Array.isArray(value)
      ? value.map(email => email.trim())
      : value.split(',').map(email => email.trim());
    if (!cleanedList || cleanedList.length === 0) {
      return {
        type: 'error',
        message: t('SupportToolsELD.ScheduledReports.ErrorMessages.EmailRequired')
      };
    }
    const lastEntered = cleanedList[cleanedList.length - 1]; // Get the most recent email
    // Check for invalid email
    if (!isValidEmail(lastEntered)) {
      return {
        type: 'error',
        message: t('SupportToolsELD.ScheduledReports.ErrorMessages.InvalidEmail')
      };
    }
    // Check for duplicates
    if (existingList?.includes(lastEntered)) {
      return {
        type: 'error',
        message: t('SupportToolsELD.ScheduledReports.ErrorMessages.DuplicateEmail')
      };
    }
    // Check recipient limit
    if (cleanedList.length > 50) {
      return {
        type: 'error',
        message: t('SupportToolsELD.ScheduledReports.ErrorMessages.RecipientLimit')
      };
    }
    return null; // No errors
  };

  const handleRecipientChange = (value, existingList) => {
    const validationResult = validateRecipientList(value, existingList);
    if (validationResult !== null) {
      return validationResult.message;
    }
    setRecipientList(value);
    return value;
  };

  const handleRemoveRecipient = email => {
    setRecipientList(prevList => {
      const updatedList = prevList?.filter(item => item !== email);
      return updatedList; // Return the updated list
    });
  };

  // Report fields configuration
  const reportDefinition = [
    {
      name: 'companies',
      label: 'SelectCompanies',
      type: 'select',
      selectLabel: 'All Companies',
      multiple: true,
      placeholder: 'SelectPlaceholder',
      options: companyOptions,
      value: selectedCompanies,

      onChange: setSelectedCompanies,
      toggleAll: toggleAllCompanies
    },
    {
      name: 'fleets',
      label: 'SelectFleets',
      type: 'select',
      multiple: true,
      selectLabel: 'All Fleets',
      placeholder: 'SelectPlaceholder',
      options: fleetOptions,
      value: selectedFleets,
      onChange: setSelectedFleets,
      toggleAll: toggleAllFleets
    }
  ];

  return (
    <Form form={form} onSubmit={handleSubmit} id="scheduledForm">
      <div className={styles.formInput}>
        {/* Card 1: Report Configuration using DynamicFormBuilder */}
        <Card className={styles.reportConfigurationCard}>
          <h6> {t('SupportToolsELD.ScheduledReports.ReportConfiguration')}</h6>
          <DynamicFormBuilder form={form} fields={reportDefinition} />
        </Card>

        <Card className={styles.reportConfigurationCard}>
          <h6> {t('SupportToolsELD.ScheduledReports.ReportDateRange')}</h6>
          <div className={styles.dateRangePickers}>
            <Field
              name="nextPeriodFrom"
              title={
                <span>
                  {t('SupportToolsELD.ScheduledReports.From')}

                  <span className={styles.requiredAsterisk}>*</span>
                </span>
              }
              decorator={[FormItem]}
              component={[
                DatePicker,
                {
                  className: styles.datePickerStyle,
                  showTime: { format: 'HH:mm' },
                  format: localization.formats.time.formats.dby.toUpperCase() + ' HH:mm'
                }
              ]}
              validator={[
                {
                  required: true,
                  message: t('SupportToolsELD.ScheduledReports.ErrorMessages.From')
                }
              ]}
            />
            <Field
              name="nextPeriodTo"
              title={
                <span>
                  {t('SupportToolsELD.ScheduledReports.To')}

                  <span className={styles.requiredAsterisk}>*</span>
                </span>
              }
              decorator={[FormItem]}
              component={[
                DatePicker,
                {
                  className: styles.datePickerStyle,
                  showTime: { format: 'HH:mm' },
                  format: localization.formats.time.formats.dby.toUpperCase() + ' HH:mm',
                  disabledDate: current => {
                    const fromDate = form.getState(state => state.values.nextPeriodFrom);
                    if (!fromDate) {
                      return true; // Disable if no start date is selected
                    }
                    // Disable dates before the selected nextRunAt
                    return current && current.isBefore(dayjs(fromDate), 'day');
                  },
                  disabledTime: current => {
                    const nextPeriodFrom = form.getState(state => state.values.nextPeriodFrom);
                    if (!nextPeriodFrom) return {}; // If no start date is selected, don't disable times

                    // If the selected date is the same as the start date, disable hours and minutes before the start time
                    if (current?.isSame(dayjs(nextPeriodFrom), 'day')) {
                      return {
                        disabledHours: () => {
                          const hours = [];
                          for (let i = 0; i < dayjs(nextPeriodFrom).hour(); i++) {
                            hours.push(i); // Disable all hours before the start hour
                          }
                          return hours;
                        },
                        disabledMinutes: hour => {
                          // Disable minutes before the selected start minute, if it's the same hour
                          if (hour === dayjs(nextPeriodFrom).hour()) {
                            const minutes = [];
                            for (let i = 0; i < dayjs(nextPeriodFrom).minute(); i++) {
                              minutes.push(i); // Disable minutes before the start minute
                            }
                            return minutes;
                          }
                          return []; // No restriction for other hours
                        }
                      };
                    }
                    return {}; // If it's a later day, no restrictions on time
                  }
                }
              ]}
            />
          </div>
        </Card>

        {/* Card for Scheduling Reports */}
        <Card className={styles.scheduleReportsCard}>
          <h6 className={styles.header}>
            {t('SupportToolsELD.ScheduledReports.ScheduleYourReport')}
          </h6>

          {/* Report Name */}
          <div className={styles.inputField}>
            <Field
              name="name"
              title={
                <span>
                  {t('SupportToolsELD.ScheduledReports.ReportName')}
                  <span className={styles.requiredAsterisk}>*</span>
                  {/* <Tooltip title="">
                    <QuestionCircleOutlined className={styles.questionIconStyle} />
                  </Tooltip> */}
                </span>
              }
              decorator={[FormItem]}
              component={[
                Input,
                {
                  placeholder: t('SupportToolsELD.ScheduledReports.ReportNamePlaceholder'),
                  className: styles.reportName
                }
              ]}
              validator={value => {
                if (!value || value.trim() === '') {
                  return {
                    type: 'error',
                    message: t('SupportToolsELD.ScheduledReports.ErrorMessages.ReportName')
                  };
                }
                const isDuplicate = exisitngReportNames?.some(
                  name =>
                    name.trim().toLowerCase() === value.trim().toLowerCase() &&
                    name.trim().toLowerCase() !== initialValues?.name?.trim()?.toLowerCase()
                );
                if (isDuplicate) {
                  return {
                    type: 'error',
                    message: t('SupportToolsELD.ScheduledReports.ErrorMessages.DuplicateReportName')
                  };
                }
                return true;
              }}
            />
          </div>

          {/* Description */}
          <div className={styles.inputField}>
            <Field
              name="description"
              title={t('SupportToolsELD.ScheduledReports.Description')}
              decorator={[FormItem]}
              component={[
                Input,
                {
                  className: styles.description,
                  placeholder: t('SupportToolsELD.ScheduledReports.DescriptionPlaceholder')
                }
              ]}
            />
          </div>

          {/* Frequency, First Run, and End By */}
          <div className={styles.dateFieldGroup}>
            <div className={styles.inputField}>
              <Field
                name="frequency"
                title={t('SupportToolsELD.ScheduledReports.SelectFrequency')}
                decorator={[FormItem]}
                component={[
                  Radio.Group,
                  {
                    options: [
                      {
                        label: t('SupportToolsELD.ScheduledReports.Frequency.Once'),
                        value: 'ONCE_OFF'
                      },
                      {
                        label: t('SupportToolsELD.ScheduledReports.Frequency.Daily'),
                        value: 'DAILY'
                      },
                      {
                        label: t('SupportToolsELD.ScheduledReports.Frequency.Weekly'),
                        value: 'WEEKLY'
                      },
                      {
                        label: t('SupportToolsELD.ScheduledReports.Frequency.Monthly'),
                        value: 'MONTHLY'
                      }
                    ],
                    onChange: e => handleFrequencyChange(e.target.value)
                  }
                ]}
              />
            </div>
            <div className={styles.datePickers}>
              <Field
                name="nextRunAt"
                title={
                  <span>
                    {t('SupportToolsELD.ScheduledReports.NextRun')}
                    <span className={styles.requiredAsterisk}>*</span>
                  </span>
                }
                decorator={[FormItem]}
                component={[
                  DatePicker,
                  {
                    className: styles.datePickerStyle,
                    showTime: { format: 'HH:mm' },
                    format: localization.formats.time.formats.dby.toUpperCase() + ' HH:mm',
                    disabledDate: current => current && current.isBefore(dayjs(), 'day'), // Disable previous dates
                    disabledTime: current => {
                      // Disable previous hours and minutes for today
                      if (current && current.isSame(dayjs(), 'day')) {
                        return {
                          disabledHours: () => {
                            const hours = [];
                            for (let i = 0; i < dayjs().hour(); i++) {
                              hours.push(i);
                            }
                            return hours; // Disable hours before current hour
                          },
                          disabledMinutes: hour => {
                            if (hour === dayjs().hour()) {
                              const minutes = [];
                              for (let i = 0; i < dayjs().minute(); i++) {
                                minutes.push(i);
                              }
                              return minutes; // Disable minutes before current minute for the current hour
                            }
                            return [];
                          }
                        };
                      }
                      return {}; // Allow any time for dates after today
                    }
                  }
                ]}
                validator={[
                  {
                    required: true,
                    message: t('SupportToolsELD.ScheduledReports.ErrorMessages.NextRun')
                  }
                ]}
              />
              <Field
                name="endAt"
                title={t('SupportToolsELD.ScheduledReports.EndBy')}
                decorator={[FormItem]}
                component={[
                  DatePicker,
                  {
                    className: styles.datePickerStyle,
                    showTime: { format: 'HH:mm' },
                    format: localization.formats.time.formats.dby.toUpperCase() + ' HH:mm',
                    disabledDate: current => {
                      const nextRunAt = form.getState(state => state.values.nextRunAt);
                      if (!nextRunAt) {
                        return true; // Disable if no start date is selected
                      }
                      // Disable dates before the selected nextRunAt
                      return current && current.isBefore(dayjs(nextRunAt), 'day');
                    },
                    disabledTime: current => {
                      const nextRunAt = form.getState(state => state.values.nextRunAt);
                      if (!nextRunAt) return {}; // If no start date is selected, don't disable times

                      // If the selected date is the same as the start date, disable hours and minutes before the start time
                      if (current?.isSame(dayjs(nextRunAt), 'day')) {
                        return {
                          disabledHours: () => {
                            const hours = [];
                            for (let i = 0; i < dayjs(nextRunAt).hour(); i++) {
                              hours.push(i); // Disable all hours before the start hour
                            }
                            return hours;
                          },
                          disabledMinutes: hour => {
                            // Disable minutes before the selected start minute, if it's the same hour
                            if (hour === dayjs(nextRunAt).hour()) {
                              const minutes = [];
                              for (let i = 0; i < dayjs(nextRunAt).minute(); i++) {
                                minutes.push(i); // Disable minutes before the start minute
                              }
                              return minutes;
                            }
                            return []; // No restriction for other hours
                          }
                        };
                      }
                      return {}; // If it's a later day, no restrictions on time
                    }
                  }
                ]}
              />
            </div>
          </div>

          <div className={styles.inputField}>
            <Field
              name="deliveryFormat"
              title={t(t('SupportToolsELD.ScheduledReports.FileFormat'))}
              decorator={[FormItem]}
              component={[
                Radio.Group,
                {
                  options: [
                    {
                      label: t('SupportToolsELD.ScheduledReports.Excel'),
                      value: 'EXCEL'
                    }
                  ],
                  onChange: e => handleFrequencyChange(e.target.value)
                }
              ]}
            />
          </div>

          {/* Recipient List */}
          <div className={styles.inputField}>
            <FormItem
              label={
                <span>
                  {t('SupportToolsELD.ScheduledReports.RecipientList')}
                  <span className={styles.requiredAsterisk}>*</span>
                  {/* <Tooltip title="">
                    <QuestionCircleOutlined className={styles.questionIconStyle} />
                  </Tooltip> */}
                </span>
              }
            >
              <Field
                name="recipientList"
                decorator={[FormItem]}
                component={[
                  Select,
                  {
                    mode: 'tags',
                    name: 'recipientList',
                    showSearch: true,
                    allowClear: true,
                    className: styles.recipientList,
                    placeholder: t('SupportToolsELD.ScheduledReports.RecipientListPlaceholder'),
                    options: [], // No dropdown options; user inputs custom tags
                    value: recipientList,
                    maxTagCount: 'responsive',
                    suffixIcon: <SearchOutlined />,
                    open: false, // Disable dropdown
                    onChange: value => {
                      handleRecipientChange(value, recipientList);
                    },
                    tagRender: ({ value, closable, onClose }) => (
                      <Tag
                        className={styles.tagRender}
                        closable={closable}
                        onClose={() => {
                          onClose();
                          handleRemoveRecipient(value);
                        }}
                      >
                        {value}
                      </Tag>
                    )
                  }
                ]}
                validator={value => {
                  const validationResult = validateRecipientList(value, recipientList);
                  return validationResult;
                }}
              />{' '}
            </FormItem>
            <div className={styles.recipientsCnt}>
              {recipientList?.length ?? 0}/50 {t('SupportToolsELD.ScheduledReports.recipients')}
            </div>
          </div>
        </Card>

        {/* Buttons outside of the Card */}
        <div className={styles.btns}>
          <Button type="default" className={styles.cancelButton} onClick={onClose}>
            {t('Common.CancelButton')}
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            {t('Common.ScheduleButton')}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ScheduledReportForm;
