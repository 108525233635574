import React, { useCallback, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { isEqual, sortBy } from 'lodash';
import { Tag, Button, Tooltip, Divider, Checkbox, Popover, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useCurrentCompany } from 'features/company/companySlice';
import { useCameraEventTagsList } from 'features/camera/cameraSlice';
import { updateEventTags } from 'features/camera/cameraEventApi';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { EVENT_TAG } from '../../constant';

import styles from './EventTags.module.scss';

const CheckboxGroup = Checkbox.Group;

const TAG_SIZE = {
  small: 'small',
  large: 'large'
};

export function EventTags({
  eventId,
  eventTimeAt,
  eventTags,
  eventSeverity,
  onTagsUpdated = ({ eventId, tags }) => {},
  size = TAG_SIZE.small
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const { tags: tagOptions } = useCameraEventTagsList(currentCompany?.id);
  const [tags, setTags] = useState(eventTags);
  const [checkedTags, setCheckedTags] = useState();
  const [open, setOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const options = useMemo(
    () =>
      tagOptions?.map((tagKey, tagIndex) => ({
        key: `tag-${tagIndex}`,
        label: t([`Event.Tag.${tagKey}`, tagKey]),
        value: tagKey
      })),
    [t, tagOptions]
  );

  const reqCtxRef = useRef({
    request: null,
    updateTime: null,
    error: null
  });

  const handleEventTagsChange = useCallback(
    async ({ tags = [], onSuccess, onError }) => {
      const reqCtx = reqCtxRef.current;
      const onReqDone = error => {
        if (reqCtxRef.current.request?.abort) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current = {
          request: null,
          updateTime: moment().valueOf(),
          error: error || null
        };
        setIsUpdating(false);
        setOpen(false);
        setCheckedTags(null);
      };

      if (reqCtx.request?.abort) {
        reqCtx.request.abort();
      }
      reqCtx.request = dispatch(
        updateEventTags({
          eventId,
          eventTimeAt,
          tags,
          severity: eventSeverity,
          onSuccess: res => {
            const updatedTags = res?.tags || [];
            onReqDone();
            setTags(updatedTags);
            if (onSuccess) {
              onSuccess(updatedTags);
            } else {
              dispatch(
                openToast({
                  type: ToastType.Success,
                  message: t(
                    `Event.Notifications.${
                      !updatedTags?.length ? 'Deleted' : !eventTags?.length ? 'Added' : 'Updated'
                    }`,
                    {
                      name: t('Tracking.Tags')
                    }
                  )
                })
              );
            }
            if (onTagsUpdated) {
              onTagsUpdated({ eventId, tags: updatedTags });
            }
          },
          onError: errMsg => {
            onReqDone(errMsg);
            if (onError) {
              onError(errMsg);
            } else {
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: t(
                    `Event.Notifications.${!eventTags?.length ? 'AddFailed' : 'UpdateFailed'}`,
                    {
                      name: t('Tracking.Tags'),
                      error: errMsg
                    }
                  )
                })
              );
            }
          }
        })
      );

      try {
        setIsUpdating(true);
        await reqCtx.request;
      } catch (e) {
        onReqDone(e);
      }
    },
    [t, dispatch, eventId, eventTimeAt, eventTags, eventSeverity, onTagsUpdated]
  );

  const isDisabled = useMemo(
    () => !checkedTags || isEqual(new Set(tags || []), new Set(checkedTags || [])),
    [tags, checkedTags]
  );

  return (
    <div className={styles.tags}>
      {!!tagOptions?.length && (
        <Popover
          placement="bottomLeft"
          trigger={'click'}
          open={open}
          onOpenChange={open => {
            if (!isUpdating) {
              if (!open) {
                setCheckedTags(null);
              }
              setOpen(open);
            }
          }}
          content={
            <>
              <CheckboxGroup
                className={styles.tagsSelect}
                options={options}
                value={checkedTags || tags}
                onChange={list => setCheckedTags(list)}
              />
              <Divider
                style={{
                  margin: 0
                }}
              />
              <div style={{ padding: 8, textAlign: 'right' }}>
                <Button
                  type="primary"
                  size="small"
                  loading={isUpdating}
                  disabled={isDisabled}
                  onClick={() => handleEventTagsChange({ tags: checkedTags })}
                >
                  {t('Common.Modal.OK')}
                </Button>
              </div>
            </>
          }
        >
          <Tooltip title={!!tags?.length && `${t('Common.Update')} ${t('Tracking.Tags')}`}>
            <div
              className={`${size === TAG_SIZE.large ? styles.largeAddTag : styles.addTag} ${
                open ? styles.active : ''
              }`}
            >
              <PlusOutlined />
              {!tags?.length && t('Event.Add Tags')}
            </div>
          </Tooltip>
        </Popover>
      )}
      <EllipsisTags
        ellipsis={size === TAG_SIZE.small}
        tags={sortBy(tags || [], tagKey => Object.keys(EVENT_TAG).indexOf(tagKey)).map(
          (eventTag, eventTagIndex) => (
            <EventTag
              key={`${eventId}-${eventTagIndex}`}
              onRemove={({ onSuccess, onError }) =>
                handleEventTagsChange({
                  tags: tags.filter(existingTag => existingTag !== eventTag),
                  onSuccess,
                  onError
                })
              }
              tag={eventTag}
              size={size}
            />
          )
        )}
      />
    </div>
  );
}

function EventTag({ tag, onRemove = ({ onSuccess, onError }) => {}, size = TAG_SIZE.small }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const onClose = useCallback(
    e => {
      e.preventDefault();
      setIsDeleting(true);
      onRemove({
        onSuccess: () => {
          setIsDeleting(false);
          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('Event.Notifications.Deleted', {
                name: t('Event.Tag.Tag')
              })
            })
          );
        },
        onError: errMsg => {
          setIsDeleting(false);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Event.Notifications.DeleteFailed', {
                name: t('Event.Tag.Tag'),
                error: errMsg
              })
            })
          );
        }
      });
    },
    [t, dispatch, onRemove]
  );
  return (
    <Tag
      className={size === TAG_SIZE.large ? styles.largeTag : styles.tag}
      bordered={size === TAG_SIZE.large}
      closable={!isDeleting}
      closeIcon
      onClose={onClose}
    >
      <Space>
        {t([`Event.Tag.${tag}`, tag])}
        {isDeleting && <SyncOutlined spin style={{ fontSize: '0.8em' }} />}
      </Space>
    </Tag>
  );
}

function EllipsisTags({ tags, maxShow = 1, ellipsis = true }) {
  if (!tags?.length) {
    return null;
  } else if (tags.length === 1) {
    return tags[0];
  } else {
    return (
      <Space size={2} wrap>
        {ellipsis ? tags.slice(0, maxShow) : tags}
        {ellipsis && (
          <Popover
            arrow
            overlayClassName={styles.ellipsisTooltip}
            overlayInnerStyle={{ padding: '8px' }}
            color="#fff"
            placement="top"
            content={<Space direction="vertical">{tags.slice(maxShow)}</Space>}
          >
            <Button
              size="small"
              icon={<FontAwesomeIcon size="xs" icon={faPlus} />}
              className={styles.ellipsisBtn}
            >
              {tags.length - maxShow}
            </Button>
          </Popover>
        )}
      </Space>
    );
  }
}
