import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { PermissionsTypes } from 'containers/Administration/Roles/constants';
import i18next from 'i18next';
import { t_error } from 'i18nextConfig';
import { rolesDataAdapter } from 'data/dataService/tn/adapter/roles';
import { permissionsDataAdapter } from 'data/dataService/tn/adapter/permissions';

import dayjs from 'dayjs';

// Endpoint URLS
export const ROLES_URL = '/roles';
export const PERMISSIONS_METADATA_URL = '/permissions/metadata';

const roles = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    isFetchingPermissions: false,
    lastFetchedPermissions: null,
    error: null,
    errorPermissions: null,
    isListEmpty: false,
    isListPermissionsEmpty: false,
    companyKey: null
  },
  specificRole: {},
  permissions: [],
  entityGlobalPermissions: [],
  moduleGlobalPermissions: []
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function stopLoadingRoles(state) {
  state.meta.isFetching = false;
  state.meta.error = null;
  state.meta.isListEmpty = false;
}

function startLoadingPermissions(state) {
  state.meta.isFetchingPermissions = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  //state.list = []; //todo - this clears the list in redux when roles api fails (hint: the last_updated issue)
  state.meta.companyKey = action.payload.companyKey;
}

function loadingPermissionsFailed(state, action) {
  state.meta.isFetchingPermissions = false;
  state.meta.lastFetchedPermissions = 'now';
  state.meta.errorPermissions = action.payload.err;
  state.meta.isListPermissionsEmpty = true;
  state.permissions = [];
  state.meta.companyKey = action.payload.companyKey;
}

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: roles,
  reducers: {
    fetchRolesStart: startLoading,
    fetchRolesStop: stopLoadingRoles,
    fetchPermissionsStart: startLoadingPermissions,
    fetchRolesSuccess(state, { payload }) {
      state.list = sortBy(payload.list, [
        role => {
          return role.name.toLowerCase();
        }
      ]);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchPermissionsSuccess(state, { payload }) {
      state.permissions = payload.list;
      state.entityGlobalPermissions = payload.list.filter(
        per => per.type === PermissionsTypes.Entity
      );
      state.moduleGlobalPermissions = payload.list.filter(
        per => per.type === PermissionsTypes.Module
      );
      state.meta.isFetchingPermissions = false;
      state.meta.lastFetchedPermissions = 'now';
      state.meta.errorPermissions = null;
      state.meta.isListPermissionsEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchRolesFailure: loadingFailed,
    fetchPermissionsFailure: loadingPermissionsFailed,
    fetchSpecificRoleDataSuccess(state, { payload }) {
      state.specificRole[payload.id] = payload;
      state.specificRoleId = payload.id;
      state.meta.isFetching = false;
    },
    fetchSpecificRoleDataFailure(state, { payload }) {
      state.meta.isFetching = false;
      state.specificRole[payload.id] = null;
    },
    removeIdRoleData(state, { payload }) {
      delete state.specificRole[payload];
    }
  }
});

// HOOKS

export const useRoles = (forceFetch = false) => {
  const dispatch = useDispatch();
  const roles = useSelector(state => state.roles.list);
  const isFetching = useSelector(state => state.roles.meta.isFetching);
  const isListEmpty = useSelector(state => state.roles.meta.isListEmpty);
  const companyKey = useSelector(state => state.roles.meta.companyKey);
  const currentCompanyKey = useSelector(state => state.companies.current.api_key);

  const couldFetch =
    !isFetching && roles.length === 0 && (!isListEmpty || companyKey !== currentCompanyKey);

  if (couldFetch) {
    dispatch(fetchRoles(forceFetch));
  }

  return roles;
};

export const usePermissionsMetadata = () => {
  const dispatch = useDispatch();
  const permissions = useSelector(state => state.roles.permissions);
  const isFetching = useSelector(state => state.roles.meta.isFetchingPermissions);
  const isListEmpty = useSelector(state => state.roles.meta.isListPermissionsEmpty);

  if (!isFetching && permissions.length === 0 && !isListEmpty) {
    dispatch(fetchPermissionsMetadata());
  }
  return permissions;
};

export const useEntityPermissionsMetadata = () => {
  return useSelector(state => state.roles.entityGlobalPermissions);
};

export const useModulePermissionsMetadata = () => {
  return useSelector(state => state.roles.moduleGlobalPermissions);
};

export const useIsFetching = () => useSelector(state => state.roles.meta.isFetching);

export const useSpecificRole = (id, onError) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(state => state.roles.meta.isFetching);
  const roleData = useSelector(state => state.roles.specificRole);
  const roleKeys = Object.keys(roleData);

  if (id === 'newRole') {
    return;
  }
  if (!roleKeys.includes(id) && !isFetching) {
    dispatch(fetchRolesStart());
    dispatch(fetchSpecificRoleData(id, onError));
  }
  return roleData[id];
};

// METHODS

export const fetchRoles = (forceFetch = false) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const urlForRoles = `${ROLES_URL}`;
  if (!authKey) {
    return;
  }
  dispatch(fetchRolesStart());
  try {
    const isLocalCacheAvailable = await rolesDataAdapter.available();
    if (isLocalCacheAvailable) {
      const lastUpdated = (await rolesDataAdapter.lastUpdatedDate()) || null;
      const syncDate = dayjs().format();
      const cachedRoles = await rolesDataAdapter.load();

      if (cachedRoles.length > 0 && !forceFetch) {
        dispatch(fetchRolesSuccess({ list: cachedRoles, companyKey }));
      } else {
        const response = await api.get(urlForRoles, {
          authKey,
          query: {
            pruning: 'ALL',
            ...(lastUpdated && { last_updated: lastUpdated, statusList: 'E,D' })
          }
        });
        const { body } = response;
        await rolesDataAdapter.persist(body);
        const cachedData = await rolesDataAdapter.load(true);
        dispatch(fetchRolesSuccess({ list: cachedData, companyKey }));
        rolesDataAdapter.updateLastUpdatedDate(syncDate);
      }
    } else {
      const response = await api.get(urlForRoles, { authKey, query: { pruning: 'ALL' } });
      const { body } = response;
      dispatch(fetchRolesSuccess({ list: body, companyKey }));
    }
  } catch (err) {
    console.error(err);
    dispatch(fetchRolesFailure({ err: err.toString(), companyKey }));
  }
};

export const fetchPermissionsMetadata = (forceFetch = false) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const urlForPermissions = PERMISSIONS_METADATA_URL;
  if (!authKey) {
    return;
  }
  dispatch(fetchPermissionsStart());

  try {
    const isLocalCacheAvailable = await permissionsDataAdapter.available();
    if (isLocalCacheAvailable) {
      const lastUpdated =
        (await permissionsDataAdapter.lastUpdatedDate()) || dayjs('2000-01-01').format();
      const syncDate = dayjs().format();
      const cachedPermissions = await permissionsDataAdapter.load();
      if (cachedPermissions.length > 0 && !forceFetch) {
        dispatch(fetchPermissionsSuccess({ list: cachedPermissions, companyKey }));
      } else {
        const response = await api.get(urlForPermissions, {
          authKey,
          query: {
            last_updated: lastUpdated
          }
        });
        const { body } = response;
        await permissionsDataAdapter.persist(body);
        const cachedData = await permissionsDataAdapter.load(true);
        dispatch(fetchPermissionsSuccess({ list: cachedData, companyKey }));
        permissionsDataAdapter.updateLastUpdatedDate(syncDate);
      }
    } else {
      const response = await api.get(urlForPermissions, { authKey });
      const { body } = response;
      dispatch(fetchPermissionsSuccess({ list: body, companyKey }));
    }
  } catch (err) {
    console.error(err);
    dispatch(fetchPermissionsFailure({ err: err.toString(), companyKey }));
  }
};
const fetchSpecificRoleData = (id, onError) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlForRole = `${ROLES_URL}/${id}?pruning=ALL`;
  if (!authKey) {
    return;
  }
  try {
    const response = await api.get(urlForRole, { authKey });
    const { body } = response;
    dispatch(fetchSpecificRoleDataSuccess(body));
  } catch (err) {
    dispatch(fetchSpecificRoleDataFailure({ id }));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t_error(err.response?.body || err.toString())
      })
    );
    if (onError) {
      onError();
    }
  }
};

export const addRole = data => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  if (!authKey) {
    return;
  }
  try {
    const uploadResponse = await api.post(
      ROLES_URL,
      {
        authKey
      },
      data
    );

    if (!uploadResponse.ok) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Roles.ToastMessages.RoleAddedError', {
            name: data?.name
          })
        })
      );
    } else {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Roles.ToastMessages.RoleAddedSuccess', {
            name: data?.name
          })
        })
      );
      dispatch(fetchRoles(true));
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t('Roles.ToastMessages.RoleAddedError', {
          name: data?.name
        })}: ${err}`
      })
    );
  }
};

export const updateRole = (id, data) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlForUpdateRole = `${ROLES_URL}/${id}`;
  if (!authKey) {
    return;
  }
  try {
    const updateResponse = await api.put(
      urlForUpdateRole,
      {
        authKey
      },
      data
    );

    if (!updateResponse.ok) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Roles.ToastMessages.RoleUpdatedError', {
            name: data?.name
          })
        })
      );
    } else {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Roles.ToastMessages.RoleUpdatedSuccess', {
            name: data?.name
          })
        })
      );
      dispatch(fetchRoles(true));
      dispatch(removeIdRoleData(id.toString()));
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t('Roles.ToastMessages.RoleUpdatedError', {
          name: data?.name
        })}: ${err}`
      })
    );
  }
};

export const removeRole = role => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  try {
    const response = await api.delete(`${ROLES_URL}/${role.id}`, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Roles.ToastMessages.RoleDeletedSuccess', {
            name: role?.name
          })
        })
      );
      await rolesDataAdapter.delete(role.id);
      const cachedData = await rolesDataAdapter.load(true);
      dispatch(fetchRolesSuccess({ list: cachedData, companyKey }));
      //dispatch(fetchRoles());
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t('Roles.ToastMessages.RoleDeletedError', {
          name: role?.name
        })} ${err}`
      })
    );
  }
};

export const {
  fetchRolesStart,
  fetchRolesStop,
  fetchPermissionsStart,
  fetchRolesSuccess,
  fetchPermissionsSuccess,
  fetchRolesFailure,
  fetchPermissionsFailure,
  fetchSpecificRoleDataSuccess,
  fetchSpecificRoleDataFailure,
  removeIdRoleData
} = rolesSlice.actions;

export default rolesSlice.reducer;
