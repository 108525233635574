import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { Button, Col, Divider, Modal, Row, Select, Typography } from 'antd';
import { CountrySelect } from 'features/common/CountrySelect';
import { StateSelect } from 'features/common/StateSelect';
import { FormLabel } from 'react-bootstrap';
import { BULK_TYPES } from './constants';

import { fileDownload } from 'containers/Administration/BulkManagement/utils/fileDownload';
import { columnWidth } from 'containers/Administration/Users/constants';

import { CopyToClipboardButton } from 'components/buttons/CopyToClipboardButton';
import { exportFile } from 'components/excelFile';
import { useAllRegions } from 'features/regions/regionsSlice';
import { BottomPanelImport } from './components/BottomPanelImport';

import {
  getBulkData,
  getBulkErrors,
  getBulkStatus,
  getTemplate,
  uploadBulkFile,
  useGetCurrentBulkItem,
  useIsFetchingTemplates,
  useTemplatesError,
  useTemplatesSuccess
} from 'features/bulk_management';
import { useCurrentCompany } from 'features/company/companySlice';
import { fetchFleets } from 'features/fleets/fleetsSlice';
import { fetchGeofences } from 'features/geofences/geofencesSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { fetchLocations } from 'features/locations/locationsSlice';
import {
  entities,
  services,
  FeatureFlag,
  useCanFeatureFlag,
  useCanEveryCompanyService,
  useCanEveryEntity
} from 'features/permissions';
import { fetchUsers } from 'features/users/usersSlice';
import {
  useGetTimezonesQuery,
  useLazyGetSDCDataQuery,
  useLazyGetSDMDataQuery
} from 'services/nextgen';

import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';
import { formatTemplates } from '../../utils/helpers';

import styles from './ImportModal.module.scss';

export const BulkManagementModal = ({ isTmaBatch = false, handleShow, show, templates }) => {
  const { Option } = Select;
  const { Text, Title, Link } = Typography;

  const { t } = useTranslation();
  const regions = useAllRegions();
  const [country, setCountry] = useState();
  const [state, setState] = useState('');
  const localization = useLocalization();
  const canGeofence = useCanEveryEntity([entities.GEOFENCE_CREATE]);
  const canLocation = useCanEveryEntity([entities.LOCATION_CREATE]);
  const canUser = useCanEveryEntity([entities.USER_CREATE]);
  const canVehicle = useCanEveryEntity([entities.VEHICLE_CREATE]);
  const canSdcSdm = useCanEveryCompanyService([services.TMA]);

  const currentBulkItem = useGetCurrentBulkItem();
  const currentCompany = useCurrentCompany();
  const dispatch = useDispatch();
  const isFetchingTemplates = useIsFetchingTemplates();
  const isTemplatesError = useTemplatesError();
  const isTemplatesSuccess = useTemplatesSuccess();
  const location = useLocation();
  const { data: newTimezones } = useGetTimezonesQuery();
  const [triggerSdc] = useLazyGetSDCDataQuery();
  const [triggerSdm] = useLazyGetSDMDataQuery({
    companyId: currentCompany?.id,
    fromDate: dayjs().subtract(30, 'days'),
    toDate: dayjs()
  });

  const [fileList, setFileList] = useState([]);
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImportType, setSelectedImportType] = useState(null);
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const [uploadFailedMessage, setUploadFailedMessage] = useState('');
  const [checkStatus, setCheckStatus] = useState(false);
  const [importStatus, setImportStatus] = useState({});
  const [showCountrySelection, setShowCountrySelection] = useState(false);
  const [uniqueKey, setUniqueKey] = useState(null);
  const bulkEditVehicle = useCanFeatureFlag({
    featureFlag: FeatureFlag.bulkEditVehicle.flag
  });
  const bulkEditUser = useCanFeatureFlag({
    featureFlag: FeatureFlag.bulkEditUser.flag
  });
  const isBulkEdit =
    selectedImportType === BULK_TYPES.USERS_EDIT || selectedImportType === BULK_TYPES.VEHICLES_EDIT;

  const handleCountryChanged = value => {
    setCountry(value);
    setState(undefined);
  };

  useEffect(() => {
    if (show) {
      const region = regions?.find(r => r.code === currentCompany.country);
      if (region) {
        handleCountryChanged(region.name);
      }
    }
  }, [show, regions]);

  const timezonesExcelData = (newTimezones || []).map(entry => ({
    [t('CompanyConfig.BulkManagement.Timezones.ID')]: entry.id,
    [t('CompanyConfig.BulkManagement.Timezones.Label')]: entry.label
  }));

  useEffect(() => {
    if (location?.state?.defaultImportType && templates) {
      const option = location?.state?.defaultImportType;
      setSelectedImportType(option);
      setSelectedTemplates(formatTemplates(templates, option));
      setSelectedFile(null);
      setIsUploadFailed(false);
    }
  }, [location, templates]);

  useEffect(() => {
    if (selectedImportType && templates && Object.keys(selectedTemplates).length === 0) {
      setSelectedTemplates(formatTemplates(templates, selectedImportType));
    }
  }, [templates]);

  useEffect(() => {
    const apiInterval =
      checkStatus &&
      currentBulkItem.processingStatus === 'IN_PROGRESS' &&
      setInterval(async () => {
        const statusResponse = await dispatch(
          getBulkStatus({
            companyId: currentCompany.id,
            apiEndpoint: 'status',
            itemId: currentBulkItem.batchId
          })
        );

        setImportStatus(statusResponse?.payload);

        const { recordTotal, errorTotal, processedTotal } = statusResponse?.payload;

        if (recordTotal === errorTotal + processedTotal) {
          clearInterval(apiInterval);
          await dispatch(getBulkData());
          if (processedTotal !== 0) {
            switch (selectedImportType) {
              case BULK_TYPES.GEOFENCES:
                await dispatch(fetchGeofences());
                break;
              case BULK_TYPES.LOCATIONS:
                await dispatch(fetchLocations());
                break;
              case BULK_TYPES.USERS:
              case BULK_TYPES.USERS_EDIT:
                await dispatch(fetchUsers({ forceFetch: true }));
                break;
              case BULK_TYPES.VEHICLES:
              case BULK_TYPES.VEHICLES_EDIT:
                await dispatch(fetchFleets());
                break;
              case BULK_TYPES.SDM:
                triggerSdm({
                  companyId: currentCompany?.id,
                  fromDate: dayjs()
                    .subtract(30, 'days')
                    .toISOString(),
                  toDate: dayjs().toISOString()
                });
                break;
              case BULK_TYPES.SDC:
                triggerSdc({
                  companyId: currentCompany?.id,
                  fromDate: dayjs()
                    .subtract(30, 'days')
                    .toISOString(),
                  toDate: dayjs().toISOString()
                });
                break;
              default:
                break;
            }
          }
        }
      }, 2000);

    return () => {
      clearInterval(apiInterval);
    };
  }, [checkStatus, currentBulkItem]);

  const handleResetModal = canShowModal => {
    setSelectedFile(null);
    setIsUploadFailed(false);
    setIsUploading(false);
    setUploadFailedMessage('');
    setCheckStatus(false);
    setImportStatus({});
    !canShowModal && handleShow(false);
  };

  const handleErrorFileDownload = async ({ entry }) => {
    const errorFile = await dispatch(
      getBulkErrors({
        companyId: currentCompany?.id,
        apiEndpoint: 'errors',
        itemId: currentBulkItem.batchId,
        responseType: 'blob'
      })
    );
    fileDownload(errorFile.payload, `${importStatus.processingStatus}_${importStatus.filename}`);
  };

  const handleFileUpload = async () => {
    const { 0: file } = fileList;
    const fileData = new FormData();
    const fileExtension = file.name.split('.')[1];

    setIsUploadFailed(false);
    setIsUploading(true);
    const params = {
      companyId: currentCompany.id,
      templateId:
        fileExtension === 'geojson'
          ? selectedTemplates['geojson']?.id
          : selectedTemplates['csv']?.id
    };

    if (isBulkEdit) {
      params['bulkEdit'] = true;
      params['uniqueKey'] = uniqueKey;
    }

    fileData.append('file', file);
    fileData.append('uploadParams', JSON.stringify(params));

    const uploadResponse = await dispatch(
      uploadBulkFile({
        apiEndpoint: 'upload',
        body: fileData,
        companyId: currentCompany.id
      })
    );

    if (uploadResponse.meta.requestStatus === 'fulfilled') {
      setIsUploading(false);
      setCheckStatus(true);
      setImportStatus(uploadResponse.payload);
      await dispatch(getBulkData());
    } else if (uploadResponse.meta.requestStatus === 'rejected') {
      [502, 503, 504, undefined].includes(uploadResponse.payload?.status)
        ? setUploadFailedMessage(t('CompanyConfig.BulkManagement.Modal.ErrorConnection'))
        : setUploadFailedMessage(parseErrorMessage(uploadResponse.payload));
      setIsUploadFailed(true);
      setIsUploading(false);
    }
  };

  const handleTemplateDownload = async template => {
    if (!template) {
      return;
    }

    const templateResponse = await dispatch(
      getTemplate({
        companyId: currentCompany.id,
        apiEndpoint: 'templates',
        itemId: template.id,
        responseType: 'blob'
      })
    );
    fileDownload(templateResponse.payload, template.fileName);
  };

  const onChangeImportType = option => {
    setSelectedImportType(option);
    setSelectedTemplates(formatTemplates(templates, option));
    switch (option) {
      case BULK_TYPES.USERS_EDIT:
        setUniqueKey('username');
        break;
      case BULK_TYPES.VEHICLES_EDIT:
        setUniqueKey('name');
        break;
      default:
    }
    handleResetModal(true);
  };

  const onChangeUniqueKey = option => {
    setUniqueKey(option);
  };

  const TopPanel = () => {
    return (
      <div className={styles['top-panel']}>
        <div className={styles['top-panel__left-side']}>
          <Title type="secondary" className={styles['title--import-type']}>
            {t('CompanyConfig.BulkManagement.Modal.ImportType')}
          </Title>
          <Select
            placeholder={t('CompanyConfig.BulkManagement.Modal.SelectOptionPlaceholer')}
            value={selectedImportType}
            onChange={onChangeImportType}
            className={styles['select--import-type']}
            popupClassName={styles['select__dropdown--import-type']}
            title={selectedImportType} // title is added as an identifier for WalkMe
          >
            {!isTmaBatch && canGeofence && (
              <Option value={BULK_TYPES.GEOFENCES}>{t('Common.Geofences')}</Option>
            )}
            {!isTmaBatch && canLocation && (
              <Option value={BULK_TYPES.LOCATIONS}>{t('Common.Locations')}</Option>
            )}
            {!isTmaBatch && canUser && (
              <Option value={BULK_TYPES.USERS}>{t('Common.Users')}</Option>
            )}
            {!isTmaBatch && canUser && bulkEditUser && (
              <Option value={BULK_TYPES.USERS_EDIT}>
                {t('CompanyConfig.BulkManagement.Modal.UserBulkEdit')}
              </Option>
            )}
            {!isTmaBatch && canVehicle && (
              <Option value={BULK_TYPES.VEHICLES}>{t('Common.Vehicles')}</Option>
            )}
            {!isTmaBatch && canVehicle && bulkEditVehicle && (
              <Option value={BULK_TYPES.VEHICLES_EDIT}>
                {t('CompanyConfig.BulkManagement.Modal.VehicleBulkEdit')}
              </Option>
            )}
            {canSdcSdm && <Option value={BULK_TYPES.SDM}>{t('Common.SDM')}</Option>}
            {canSdcSdm && <Option value={BULK_TYPES.SDC}>{t('Common.SDC')}</Option>}
          </Select>
        </div>
        <div className={styles['top-panel__right-side']}>
          <div className={styles.header}>
            <Text type="secondary">{t('Common.Company')}</Text>
            <Text>{currentCompany?.name}</Text>
          </div>
          <Divider className={styles.divider} />
        </div>
      </div>
    );
  };

  const TemplateInstructions = () => {
    if (!selectedImportType) {
      return null;
    }

    if (isFetchingTemplates) {
      return <li key={'loading-templates'}>...</li>;
    }

    if (isTemplatesSuccess) {
      return (
        <>
          <li key={'info-content-2'}>
            <Text>
              {selectedTemplates.geojson ? (
                <Trans
                  i18nKey={'CompanyConfig.BulkManagement.Modal.TemplateInformationBoth'}
                  values={{
                    selectedTemplateFirstFormat: selectedTemplates?.csv?.infoName,
                    selectedTemplateSecondFormat: selectedTemplates?.geojson?.infoName
                  }}
                  components={{
                    1: <Link onClick={() => handleTemplateDownload(selectedTemplates.csv)} />,
                    2: <Link onClick={() => handleTemplateDownload(selectedTemplates.geojson)} />
                  }}
                />
              ) : (
                <Trans
                  i18nKey={'CompanyConfig.BulkManagement.Modal.TemplateInformationSingle'}
                  values={{ selectedTemplate: selectedTemplates?.csv?.infoName }}
                  components={{
                    1: <Link onClick={() => handleTemplateDownload(selectedTemplates.csv)} />
                  }}
                />
              )}
            </Text>
          </li>
          {['sdm', 'sdc'].includes(selectedImportType) && (
            <li key={'info-content-6'}>
              <Trans i18nKey={'CompanyConfig.BulkManagement.Modal.SDTypeTimeFormat'} />
            </li>
          )}
          <li key={'info-content-3'}>
            <Text>{t('CompanyConfig.BulkManagement.Modal.ChooseTemplateInformation')}</Text>
          </li>
          {(selectedImportType === BULK_TYPES.USERS ||
            selectedImportType === BULK_TYPES.USERS_EDIT) && (
            <li key={'info-content-5'}>
              <Trans
                i18nKey={'CompanyConfig.BulkManagement.Modal.TimezonesInfo'}
                components={{
                  1: (
                    <Link
                      onClick={exportFile(timezonesExcelData, {
                        title: t('CompanyConfig.BulkManagement.Timezones.Entity'),
                        width: columnWidth,
                        dateFormat: localization.formats.time.formats.dby_imsp
                      })}
                    />
                  )
                }}
              />
            </li>
          )}
          {selectedImportType === BULK_TYPES.VEHICLES_EDIT && (
            <li key={'info-content-6'}>
              <Trans i18nKey={'CompanyConfig.BulkManagement.Modal.UniqueKey'} />
              <Select
                style={{ width: '90%' }}
                placeholder={t('CompanyConfig.BulkManagement.Modal.SelectOptionPlaceholer')}
                onChange={onChangeUniqueKey}
                value={uniqueKey}
              >
                <Option value="name">
                  {t('CompanyConfig.BulkManagement.Modal.VehicleBulkEditKey.name')}
                </Option>
                <Option value="vin">
                  {t('CompanyConfig.BulkManagement.Modal.VehicleBulkEditKey.vin')}
                </Option>
                <Option value="registration">
                  {t('CompanyConfig.BulkManagement.Modal.VehicleBulkEditKey.registration')}
                </Option>
              </Select>
            </li>
          )}
          {selectedImportType === BULK_TYPES.USERS_EDIT && (
            <li key={'info-content-6'}>
              <Trans i18nKey={'CompanyConfig.BulkManagement.Modal.UserUniqueKey'} />
              <Select
                style={{ width: '90%' }}
                placeholder={t('CompanyConfig.BulkManagement.Modal.SelectOptionPlaceholer')}
                onChange={onChangeUniqueKey}
                value={uniqueKey}
              >
                <Option value="username">
                  {t('CompanyConfig.BulkManagement.Modal.UserBulkEditKey.username')}
                </Option>
                <Option value="email">
                  {t('CompanyConfig.BulkManagement.Modal.UserBulkEditKey.email')}
                </Option>
                <Option value="externalId">
                  {t('CompanyConfig.BulkManagement.Modal.UserBulkEditKey.externalId')}
                </Option>
              </Select>
            </li>
          )}
          {!['geofences', 'sdm', 'sdc'].includes(selectedImportType) && (
            <li key={`${selectedImportType}-country-info`}>
              <Trans
                i18nKey={`CompanyConfig.BulkManagement.Modal.${
                  showCountrySelection ? 'CountryInfoHide' : 'CountryInfoShow'
                }`}
                components={{
                  1: (
                    <Link
                      onClick={() => {
                        setShowCountrySelection(prev => !prev);
                      }}
                    />
                  )
                }}
              />
              {showCountrySelection && (
                <Row className={styles.countryStateRow} gutter={15}>
                  <Col span={12}>
                    <FormLabel className={styles.countryStateLabel}>
                      {t('CompanyConfig.BulkManagement.Modal.Country')}
                      <CopyToClipboardButton
                        value={country}
                        tooltip={t('CompanyConfig.BulkManagement.Modal.CopyCountry')}
                      />
                    </FormLabel>
                    <CountrySelect
                      value={country}
                      onChange={value => {
                        handleCountryChanged(value);
                      }}
                      size="large"
                      style={{ width: '100%' }}
                    />
                  </Col>
                  <Col span={12}>
                    <FormLabel className={styles.countryStateLabel}>
                      {t('CompanyConfig.BulkManagement.Modal.State')}
                      <CopyToClipboardButton
                        value={state}
                        tooltip={t('CompanyConfig.BulkManagement.Modal.CopyState')}
                      />
                    </FormLabel>
                    <StateSelect
                      country={country}
                      size="large"
                      addCountryAsRegion={true}
                      onChange={(value, obj) => {
                        setState(obj.key);
                      }}
                      value={state}
                      style={{ width: '100%' }}
                      disabled={country === undefined}
                    />
                  </Col>
                </Row>
              )}
            </li>
          )}
        </>
      );
    }

    if (isTemplatesError) {
      return (
        <li key={'info-content-2'}>
          <Text type="danger">
            <Trans i18nKey={'CompanyConfig.BulkManagement.Modal.TemplateError'} />
          </Text>
        </li>
      );
    }
  };

  const Footer = () => {
    return (
      <>
        <Button id={BUTTON_IDS.bulkManagementImportReset} onClick={() => handleResetModal(false)}>
          {t('Common.Close')}
        </Button>
      </>
    );
  };

  const propsBottomModal = {
    checkStatus,
    fileList,
    handleErrorFileDownload,
    handleFileUpload,
    handleResetModal,
    importStatus,
    isUploadFailed,
    isUploading,
    selectedFile,
    selectedImportType,
    selectedTemplates,
    setFileList,
    setIsUploadFailed,
    setSelectedFile,
    TemplateInstructions,
    uploadFailedMessage
  };

  return (
    <Modal
      footer={Footer()}
      onCancel={() => handleResetModal(false)}
      title={t('CompanyConfig.BulkManagement.Modal.Title')}
      maskClosable={false}
      open={show}
      width={866}
    >
      <div className={styles['modal-wrapper']}>
        <TopPanel />
        <BottomPanelImport {...propsBottomModal} />
      </div>
    </Modal>
  );
};
