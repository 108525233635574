import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const deviceStatsDataAdapter = reduxAdapter({
  persist: async deviceStats => {
    if (deviceStats && deviceStats.length) {
      await TNDataService.insertOrUpdateDeviceStatsInBulk(deviceStats);
    }
  },
  load: async () => {
    const deviceStats = await TNDataService.getAllDeviceStats();
    return deviceStats;
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_device_stats');
    return result;
  },
  updateLastUpdatedDate: async lastpUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_device_stats', lastpUpdatedDate);
    return result;
  }
});

export { deviceStatsDataAdapter };
