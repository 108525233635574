import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';
import { rolesDataAdapter } from './roles';

const usersDataAdapter = reduxAdapter({
  persist: async users => {
    if (users && users.length) {
      const userRolesToDelete = [];
      const userRulesetsToDelete = [];
      const userToBeProcessed = [];
      const userRoles = [];
      const userRulesets = [];
      for (const u of users) {
        if (u.id) {
          userRolesToDelete.push(u.id);
          userRulesetsToDelete.push(u.id);
          userToBeProcessed.push(u);
          if (u.roles) {
            //await rolesDataAdapter.persist(u.roles);
            u.roles.forEach(async r => {
              userRoles.push({
                userId: u.id,
                roleId: r.id
              });
            });
          }

          if (u.rulesets) {
            userRulesets.push(...u.rulesets);
          }
        }
      }
      if (userRolesToDelete.length) {
        await TNDataService.deleteUserRoleAssociationInBulk(userRolesToDelete);
      }

      if (userRulesetsToDelete.length) {
        await TNDataService.deleteUserRulesetAssociationInBulk(userRulesetsToDelete);
      }

      if (userRoles.length) {
        await TNDataService.insertOrUpdateUserRolesInBulk(userRoles);
      }

      if (userRulesets.length) {
        await TNDataService.insertOrUpdateUserRulesetsInBulk(userRulesets);
      }

      if (userToBeProcessed.length) {
        await TNDataService.insertOrUpdateUsersInBulk(userToBeProcessed);
      }
    }
  },
  load: async (loadRulesets = true, loadRoles = true) => {
    const users = await TNDataService.getAllUsers();
    let roles = null;
    let userRoles = null;
    let userRulesets = null;

    if (loadRoles) {
      roles = await TNDataService.getAllRoles();
      userRoles = await TNDataService.getAllUserRoles();
    }

    if (loadRulesets) {
      userRulesets = await TNDataService.getAllUserRulesets();
    }

    if (loadRulesets || loadRoles) {
      users.forEach(u => {
        if (loadRulesets) {
          const ur = userRulesets.filter(d => d.userId === u.id);
          u.rulesets = ur;
        }

        if (loadRoles) {
          const ur = userRoles.reduce((a, c) => {
            if (c.userId === u.id) {
              a.add(c.roleId);
            }
            return a;
          }, new Set());
          u.roles = Array.from(roles.filter(r => ur.has(r.id)) || []);
        }
      });
    }

    return users;
  },
  delete: async userId => {
    await TNDataService.deleteUser(userId);
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_users');
    return result;
  },
  updateLastUpdatedDate: async lastUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_users', lastUpdatedDate);
    return result;
  }
});

export { usersDataAdapter };
