import React, { useEffect } from 'react';

export const Iframe = React.forwardRef(({ id, title, src, ...props }, ref) => {
  let url = src;

  useEffect(() => {
    if (src && !src.startsWith('http')) {
      url = 'http://' + src;
    }
  }, [src]);

  const refreshOnLoad = () => {
    if (ref?.current?.hidden) {
      ref.current.hidden = true;
      window.setTimeout(() => {
        ref.current.hidden = false;
      }, 50);
    }
    if (props?.onLoad) {
      props.onLoad();
    }
  };

  return (
    <iframe
      credentialless="true"
      ref={ref}
      id={id}
      title={title}
      src={url}
      onLoad={refreshOnLoad}
      {...props}
    />
  );
});
