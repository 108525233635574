import { KEYCLOAK_URL } from 'config';
import {
  logout,
  useUser,
  useUserKey,
  REMOTE_SIGN_IN_ERROR,
  remoteSignInFailed,
  REMOTE_SIGN_IN_TIMEOUT,
  setRemoteSignInAvaliable,
  useCompatibleLoginState,
  compatibleLogout
} from 'features/user/userSlice';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTimer } from 'utils/hooks/useTimer';
import { KEYCLOAK_CONFIG } from 'config';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useTranslation } from 'react-i18next';
import { FeatureFlag, services, useCan } from 'features/permissions';
import { useHistory } from 'react-router';
import './sso.scss';

export function useSSO() {
  const user = useUser();
  const userKey = useUserKey();
  const dispatch = useDispatch();

  useSSOAvailableCheck();
  useEffect(() => {
    if (user == null || userKey == null || user.auth?.keycloakConfig == null) return;

    if (document.querySelector('#keycloakScript') == null) {
      const keycloakScriptNode = document.createElement('script');
      keycloakScriptNode.type = 'text/javascript';
      keycloakScriptNode.src = KEYCLOAK_URL;
      keycloakScriptNode.crossOrigin = 'use-credentials';
      keycloakScriptNode.onload = async () => {
        const keycloakConfig = user.auth.keycloakConfig;
        window.keycloak = new window.Keycloak(keycloakConfig);
        const logoutUser = () => {
          let redirectUrl = '/sso';
          if (user.auth.keycloakConfig.realm) {
            redirectUrl += '?realm=' + user.auth.keycloakConfig.realm;
          }
          dispatch(logout());
          dispatch(remoteSignInFailed());
          window.location.href = redirectUrl;
        };
        window.keycloak.onAuthLogout = logoutUser;

        window.keycloak.onTokenExpired = () => {
          window.keycloak
            .updateToken(30)
            .then(() => {})
            .catch(e => {
              logoutUser();
              dispatch(remoteSignInFailed(REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR));
            });
        };
        const { error } = await checkSSO(true);
        dispatch(
          setRemoteSignInAvaliable({ remoteSignInAvaliable: !error, remoteSignInError: error })
        );
      };
      keycloakScriptNode.onerror = () => {
        dispatch(
          setRemoteSignInAvaliable({
            remoteSignInAvaliable: false,
            remoteSignInError: REMOTE_SIGN_IN_ERROR.KC_ADAPTER_LOAD_ERROR
          })
        );
      };
      keycloakScriptNode.id = 'keycloakScript';
      document.head.appendChild(keycloakScriptNode);
    }
  }, [user, userKey, dispatch]);
}

const KC_AVALIABLE_CHECK_INTERVAL = 300000; //5*60*1000

const useSSOAvailableCheck = () => {
  const dispatch = useDispatch();
  const can = useCan();

  const { isNormalLogin } = useCompatibleLoginState();

  const needKCAvailableCheck = useMemo(
    () =>
      isNormalLogin &&
      can({
        oneOfServices: [
          services.INSIGHTSBASIC,
          services.INSIGHTSSEARCH,
          services.INSIGHTSADVPROD,
          services.INSIGHTSADVSAFETY,
          services.INSIGHTSADVCOMP,
          services.INSIGHTSBUILDER,
          services.INSIGHTSVIZ,
          services.INSIGHTSAI,
          services.INSIGHTSSUSTAINABILITYSNAPSHOT,
          services.INSIGHTSSUSTAINABILITYGOALS,
          services.INSIGHTSSUSTAINABILITYFLEETS,
          services.INSIGHTSREPORTS,
          services.INSIGHTSNPI,
          services.DIRECTORINSIGHTS
        ],
        featureFlag: FeatureFlag.ssoGuard.flag,
        otherConditions: [() => !window.localStorage.getItem('AutomationTest')]
      }),
    [isNormalLogin, can]
  );

  const runCheck = useCallback(async () => {
    if (document.querySelector('#keycloakScript') == null) {
      const keycloakScriptNode = document.createElement('script');
      keycloakScriptNode.type = 'text/javascript';
      keycloakScriptNode.src = KEYCLOAK_URL;
      keycloakScriptNode.crossOrigin = 'use-credentials';
      keycloakScriptNode.onload = async () => {
        const keycloakConfig = JSON.parse(KEYCLOAK_CONFIG);
        window.keycloak = new window.Keycloak(keycloakConfig);
        const { error } = await checkSSO();
        dispatch(
          setRemoteSignInAvaliable({ remoteSignInAvaliable: !error, remoteSignInError: error })
        );
      };
      keycloakScriptNode.id = 'keycloakScript';
      document.head.appendChild(keycloakScriptNode);
    } else if (window.Keycloak) {
      const keycloakConfig = JSON.parse(KEYCLOAK_CONFIG);
      window.keycloak = new window.Keycloak(keycloakConfig);
      const { error } = await checkSSO();
      dispatch(
        setRemoteSignInAvaliable({ remoteSignInAvaliable: !error, remoteSignInError: error })
      );
    }
  }, [dispatch]);

  useTimer(KC_AVALIABLE_CHECK_INTERVAL, runCheck, needKCAvailableCheck);
};

const checkSSO = async (authRequired = false) => {
  return new Promise(resolve => {
    window.keycloak
      .init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin,
        flow: 'standard',
        messageReceiveTimeout: REMOTE_SIGN_IN_TIMEOUT,
        checkLoginIframe: false
      })
      .then(authenticated =>
        resolve({
          error: !authenticated && authRequired ? REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR : null
        })
      )
      .catch(e => resolve({ error: REMOTE_SIGN_IN_ERROR.KC_INIT_ERROR }));
  });
};

export const SSO_GUARD_FEATURE = {
  Insights: 'Insights'
};

export const SSO_GUARD_FEATURE_CONFIG = {
  Insights: {
    ssoLoginRedirectText: t => t('Login.SSO.Guard', { feature: t('Common.Insights') }),
    ssoUnavailableText: t => t('Login.SSO.NoAccessInsights')
  }
};

export const useSSOGuard = ssoGuardFeature => {
  const guardConfig = useMemo(() => SSO_GUARD_FEATURE_CONFIG[ssoGuardFeature], [ssoGuardFeature]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modalRef = useRef();
  const can = useCan();
  const ssoGuardEnabled = can({
    featureFlag: FeatureFlag.ssoGuard.flag,
    otherConditions: [() => !window.localStorage.getItem('AutomationTest')]
  });
  const history = useHistory();

  const { isNormalLogin, isKCAvaliable, isLoggedIn } = useCompatibleLoginState();

  const { ssoUnAvailable, ssoUnAvailableTooltip, needGuard, ssoAuthed } = useMemo(() => {
    return {
      ssoUnAvailable: ssoGuardEnabled ? !isKCAvaliable : false,
      ssoUnAvailableTooltip: ssoGuardEnabled && guardConfig?.ssoUnavailableText(t),
      needGuard: ssoGuardEnabled ? isNormalLogin && isKCAvaliable : false,
      ssoAuthed: ssoGuardEnabled ? isLoggedIn && !isNormalLogin : true
    };
  }, [t, guardConfig, isNormalLogin, isKCAvaliable, isLoggedIn, ssoGuardEnabled]);

  const runGuard = useCallback(
    (onProceed = () => {}, onCancel = () => {}) => {
      if (modalRef.current) {
        return;
      }
      modalRef.current = confirmationModal(
        '',
        guardConfig?.ssoLoginRedirectText(t) ||
          t('Login.SSO.Guard', { feature: t('Login.SSO.Feature') }),
        t('Common.Modal.OK'),
        t('Common.Modal.Cancel'),
        () => {
          modalRef.current = null;
          dispatch(compatibleLogout(can));
          onProceed();
          history.push('/sso');
        },
        'warning',
        () => {
          modalRef.current = null;
          onCancel();
        },
        'sso-guard-modal'
      );
    },
    [t, guardConfig, dispatch, modalRef, history]
  );

  return {
    runGuard,
    ssoUnAvailable,
    ssoUnAvailableTooltip,
    needGuard,
    ssoAuthed
  };
};
