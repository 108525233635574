import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Row } from 'react-bootstrap';
import FormSelect from 'components/form/form-select/FormSelect';
import FormInput from 'components/form/form-input/FormInput';
import { useLocalization } from 'features/localization/localizationSlice';
import FormTitle from 'components/form/form-title/FormTitle';
import * as Yup from 'yup';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { API_PATH } from 'config';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import request from 'superagent';
import { useUserKey } from 'features/user/userSlice';
import { Alert, Button, Descriptions, Space } from 'antd';
import { useEWDUser } from 'features/user/EWDUserHooks';
import { useDispatch } from 'react-redux';
import { fetchUsers } from 'features/users/usersSlice';
import {
  actionTypes,
  loadingFailed,
  loadingFinished,
  loadingStarted,
  status
} from 'utils/reduxFetchingUtils';
import { isFunction } from 'lodash';
import i18next from 'i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

const validationSchema = () =>
  Yup.object().shape({
    ewdUserFirstName: Yup.string().required(i18next.t('Users.ValidationErrors.FirstNameRequired')),
    ewdUserLastName: Yup.string().required(i18next.t('Users.ValidationErrors.LastNameRequired')),
    ewdUserEmail: Yup.string().required(i18next.t('Users.ValidationErrors.RequiredEmail'))
  });

const Fields = ({ isReadonly, timezones }) => (
  <>
    <Row>
      <FormInput
        name="ewdUserFirstName"
        label={i18next.t('Users.Form.FirstName')}
        placeholder={i18next.t('Users.Form.FirstNamePlaceholder')}
        isReadonly={isReadonly}
        isRequired
      />
      <FormInput
        name="ewdUserLastName"
        label={i18next.t('Users.Form.LastName')}
        placeholder={i18next.t('Users.Form.LastNamePlaceholder')}
        isReadonly={isReadonly}
        isRequired
      />
    </Row>
    <Row>
      <FormInput
        name="ewdUserEmail"
        label={i18next.t('Users.Form.Email')}
        placeholder={i18next.t('Users.Form.EmailPlaceholder')}
        isReadonly={isReadonly}
        isRequired
      />
      <FormSelect
        name="ewdUserTimeZone"
        label={i18next.t('Users.Form.UserTimeZone')}
        placeholder={i18next.t('Users.Form.UserTimeZonePlaceholder')}
        values={
          timezones &&
          timezones.map(timezone => ({
            label: timezone,
            value: timezone
          }))
        }
        isDisabled={isReadonly}
      />
    </Row>
    <Row>
      <FormInput
        name="ewdUserMobile"
        label={i18next.t('Users.Form.MobileNumber')}
        placeholder={i18next.t('Users.Form.MobileNumberPlaceholder')}
        isReadonly={isReadonly}
      />
    </Row>
  </>
);

export const EWDAssociateUserForm = ({ userData, timezones, doRefetch }) => {
  const userKey = useUserKey();
  const localization = useLocalization();
  const tn360UserId = userData.id;
  const dispatch = useDispatch();
  const ewdUser = useEWDUser();
  const isEWDUser = !!ewdUser?.auth?.token;
  const [EWDAccount, setEWDAccount] = useState(null);
  const [EWDAccountStatus, setEWDAccountStatus] = useState(status);
  const association = userData.associations?.find(
    assoc => assoc.domain === 'EWD' && assoc.externalId.includes('user_id')
  );

  useEffect(() => {
    let request = false;

    const fetchEWDAccount = async () => {
      const assoc = JSON.parse(association.externalId);
      setEWDAccountStatus(loadingStarted(EWDAccountStatus, {}));
      request = await ewdAPI.userDetails(assoc.user_id);

      if (request?.error && request?.error !== '') {
        setEWDAccountStatus(loadingFailed(EWDAccountStatus, {}));
      } else {
        setEWDAccount(request);
        setEWDAccountStatus(loadingFinished(EWDAccountStatus, {}));
      }
    };

    if (isEWDUser && association && !EWDAccount && EWDAccountStatus.fetching === actionTypes.init) {
      request = fetchEWDAccount();
    }

    return () => {
      if (request && isFunction(request.abort)) {
        request.abort();
      }
    };
  }, [EWDAccount, EWDAccountStatus, association, isEWDUser]);

  const linkEWDUserAccount = (values, formikBag) => {
    const payload = {
      firstName: values.ewdUserFirstName,
      lastName: values.ewdUserLastName,
      timeZone: values.ewdUserTimeZone,
      email: values.ewdUserEmail,
      username: values.ewdUserEmail,
      mobile: values.ewdUserMobile
    };
    ewdAPI.userUpsert(payload).then(response => {
      if (response.error) {
        const toastMessageError = `${response.error} - ${response.message}`;
        dispatch(
          openToast({
            message: `${i18next.t('Users.Notifications.UserEWDLinkError')}: ${toastMessageError}`,
            type: ToastType.Error
          })
        );
        formikBag.setSubmitting(false);
        return;
      }
      let urlPath = `${API_PATH}/users`;

      request('POST', `${urlPath}/${tn360UserId}/associations/`)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .send({
          domain: 'EWD',
          externalId: JSON.stringify({
            user_id: response.id,
            accessToken: response.accessToken
          })
        })
        .then(res => {
          dispatch(
            openToast({
              message: i18next.t('Users.Notifications.UserEWDLinkSuccess', {
                firstName: userData.firstName,
                lastName: userData.lastName
              }),
              type: ToastType.Success
            })
          );
          doRefetch();
          dispatch(fetchUsers({ forceFetch: true }));
          formikBag.setSubmitting(false);
        })
        .catch(err => {
          console.warn('ERROR', err);
          const toastMessageError = `${err.response.statusText} - ${err.response.text}`;
          dispatch(
            openToast({
              message: `${i18next.t('Users.Notifications.UserEWDLinkError')}:${toastMessageError}`,
              type: ToastType.Error
            })
          );
          formikBag.setSubmitting(false);
        });
    });
  };

  const unlinkEWDUserAccount = (associationId, formikBag) => {
    let urlPath = `${API_PATH}/users`;
    request('DELETE', `${urlPath}/${tn360UserId}/associations/${associationId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(res => {
        dispatch(
          openToast({
            message: i18next.t('Users.Notifications.UserUnlinkSuccess', {
              firstName: userData.firstName,
              lastName: userData.lastName
            }),
            type: ToastType.Success
          })
        );
        doRefetch();
        dispatch(fetchUsers({ forceFetch: true }));
        formikBag.setSubmitting(false);
      })
      .catch(err => {
        console.warn('ERROR', err);
        const toastMessageError = `${err.response.statusText} - ${err.response.text}`;
        dispatch(
          openToast({
            message: `${i18next.t('Users.Notifications.UserEWDLinkError')}:${toastMessageError}`,
            type: ToastType.Error
          })
        );
        formikBag.setSubmitting(false);
      });
  };

  if (!userData.id) {
    return (
      <div className="formContainer">
        <FormTitle title={i18next.t('Users.EWDTab.EWDUserLink')} underlined />
        <Alert
          message={i18next.t('Users.EWDTab.EWDUserRecord')}
          description={i18next.t('Users.EWDTab.EWDUserRecordDescriptionWarning')}
          type="warning"
          showIcon
        />
      </div>
    );
  }

  if (association) {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          ewdUserFirstName: userData.firstName,
          ewdUserLastName: userData.lastName,
          ewdUserTimeZone: userData.timeZone,
          ewdUserEmail: userData.email,
          ewdUserMobil: userData.mobile
        }}
        onSubmit={(values, formikBag) => unlinkEWDUserAccount(association.id, formikBag)}
      >
        {({ handleSubmit, isSubmitting, isValid, setFieldValue, errors }) => (
          <div className="formContainer">
            <FormTitle title={i18next.t('Users.EWDTab.EWDUserLink')} underlined />
            <Space direction="vertical" style={{ width: '100%' }}>
              <Alert
                message={i18next.t('Users.EWDTab.EWDUserRecord')}
                description={
                  i18next.t('Users.EWDTab.EWDUserRecordDescriptionSuccess') +
                  (EWDAccountStatus?.fetching === actionTypes.error
                    ? ' ' + i18next.t('Users.EWDTab.EWDUserNoPermission')
                    : '')
                }
                type="success"
                showIcon
              />
              {!isEWDUser && (
                <Alert
                  message={i18next.t('Users.EWDTab.NoAccess')}
                  description={i18next.t('Users.EWDTab.NoAccessDescriptionWarning')}
                  type="warning"
                  showIcon
                />
              )}
            </Space>
            {isEWDUser && EWDAccount && (
              <>
                <Row className={'mt-3 mx-0'}>
                  <Descriptions layout="horizontal" column={2} bordered style={{ width: '100%' }}>
                    <Descriptions.Item label={i18next.t('Users.Form.FirstName')}>
                      {EWDAccount.firstName}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.Form.LastName')}>
                      {EWDAccount.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.Form.Username')}>
                      {EWDAccount.username}
                    </Descriptions.Item>
                  </Descriptions>
                </Row>
                <Row className={'mt-3 mx-1'}>
                  <Button
                    onClick={() => handleSubmit()}
                    type="primary"
                    danger
                    loading={isSubmitting}
                    disabled={!isValid || !isEWDUser}
                    id={BUTTON_IDS.ewdTabUnlink}
                  >
                    {i18next.t('Users.EWDTab.Unlink')}
                  </Button>
                </Row>
              </>
            )}
          </div>
        )}
      </Formik>
    );
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{
        ewdUserFirstName: userData.firstName,
        ewdUserLastName: userData.lastName,
        ewdUserTimeZone: userData.timeZone,
        ewdUserEmail: userData.email,
        ewdUserMobil: userData.mobile
      }}
      onSubmit={(values, formikBag) => linkEWDUserAccount(values, formikBag)}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty, setFieldValue }) => (
        <div className="formContainer">
          <FormTitle title={i18next.t('Users.EWDTab.EWDUserLink')} underlined />
          <Space direction="vertical" style={{ width: '100%' }}>
            <Alert
              message={i18next.t('Users.EWDTab.EWDUserRecord')}
              description={i18next.t('Users.EWDTab.EWDUserRecordDescriptionInfo')}
              type="info"
              showIcon
            />
            {!isEWDUser && (
              <Alert
                message={i18next.t('Users.EWDTab.NoAccess')}
                description={i18next.t('Users.EWDTab.NoAccessDescriptionWarning')}
                type="warning"
                showIcon
              />
            )}
          </Space>
          {isEWDUser && (
            <>
              <Fields
                isReadonly={!isEWDUser}
                localization={localization}
                setFieldValue={setFieldValue}
                timezones={timezones}
              />
              <Row className={'mt-3 mx-1'}>
                <Button
                  onClick={() => handleSubmit()}
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !isEWDUser}
                  id={BUTTON_IDS.ewdTabLink}
                >
                  {i18next.t('Users.EWDTab.Link')}
                </Button>
              </Row>
            </>
          )}
        </div>
      )}
    </Formik>
  );
};
