import { join } from 'lodash';

export const BulkEditAuditColumns = {
  TIME: {
    title: 'Audits.Time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '20%'
  },
  ACTION: {
    title: 'Audits.Action',
    dataIndex: 'action',
    key: 'action',
    width: '10%'
  },
  USER: {
    title: 'Audits.User',
    dataIndex: 'user',
    key: 'user',
    width: '10%'
  },
  CHANGES: {
    title: 'Audits.Changes',
    dataIndex: 'changes',
    key: 'changes',
    width: '50%'
  }
};

const parseParameters = (parameters, defaultValue) => {
  let obj;
  try {
    obj = parameters ? JSON.parse(parameters) : defaultValue;
  } catch (error) {
    obj = defaultValue;
  }
  return obj || defaultValue;
};

export const getAuditChanges = ({ audit, can }, entity, bulkEditParams) => {
  const rows = {};
  const _getChangeValueKey = (change = []) => join([change?.[0] || '', change?.[1] || ''], '-');
  const parameters = parseParameters(audit.parameters, []);
  for (const entityAudit of parameters) {
    const deviceChange = parseParameters(entityAudit.parameters, {});
    for (const changeKey in deviceChange) {
      const canShowAuditOfChangeKey =
        bulkEditParams[changeKey] &&
        (bulkEditParams[changeKey]?.can ? can(bulkEditParams[changeKey].can) : true);
      if (canShowAuditOfChangeKey) {
        const getChangeValueKey = bulkEditParams[changeKey].getChangeValueKey || _getChangeValueKey;
        const changeValueKey = getChangeValueKey(deviceChange[changeKey]);
        rows[changeKey] = rows[changeKey] || {};
        rows[changeKey][changeValueKey] = rows[changeKey][changeValueKey] || {};
        rows[changeKey][changeValueKey][entity] = rows[changeKey][changeValueKey][entity] || {};
        rows[changeKey][changeValueKey][entity][entityAudit.entityId] = {
          id: entityAudit.entityId,
          name: entityAudit.entityName,
          companyId: entityAudit.entityCompanyId
        };
        const getChangeValueByChangeKey =
          bulkEditParams[changeKey].getChangeValue || (changeValueByKey => changeValueByKey);
        rows[changeKey][changeValueKey].change = getChangeValueByChangeKey(
          deviceChange[changeKey],
          can
        );
      }
    }
  }
  return {
    id: audit.id,
    action: audit.action,
    user: audit.user,
    createdAt: audit.createdAt,
    changes: Object.keys(rows).map(bulkEditEntityOfAudit => ({
      key: bulkEditEntityOfAudit,
      title:
        typeof bulkEditParams[bulkEditEntityOfAudit].title === 'function'
          ? bulkEditParams[bulkEditEntityOfAudit].title(can)
          : bulkEditParams[bulkEditEntityOfAudit].title,
      changes: Object.values(rows[bulkEditEntityOfAudit])
    }))
  };
};
