import store from '../../../../store';
import { DataServiceStatus, TNDataService } from '../tnDataService';

export function reduxAdapter(adapter) {
  return new Proxy(adapter, {
    get: function(target, prop, receiver) {
      if (prop === 'available') {
        return async () => TNDataService.status === DataServiceStatus.Done;
      }

      const currentUser = store.getState().user.current;
      //const currentCompany = store.getState().companies.current;
      if (!currentUser || TNDataService.status !== DataServiceStatus.Done) {
        return async (...args) => [];
      } else {
        return async (...args) => {
          return target[prop](...args);
        };
      }
    }
  });
}
