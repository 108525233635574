import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Checkbox, List, Skeleton, Space, Switch } from 'antd';
import { updateCameraChannels, useCameraChannels } from 'features/camera/cameraSlice';
import { FlipCard, FLIP_DIR } from 'components/visualization/FlipCard';
import { parseErrorMessage } from 'utils/strings';
import { FeatureFlag, useCan } from 'features/permissions';

const UVC_Channel_Config = {
  channel: 'UVC',
  name: 'Additional Driver Facing Camera',
  position: 'FRONT_BLIND_SPOT',
  hflip: false,
  vflip: false
};

export function useChannelFlipsUpdater(deviceImei) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { channels } = useCameraChannels(deviceImei);

  const [channelFlips, setChannelFlips] = useState(null);
  const removedChannels = useRef(new Set());
  const [isChannelFlipsChanged, setIsChannelFlipsChanged] = useState(false);

  const onFlip = useCallback(
    (flipDir, flipOn, { channel, name, position, ...initialChannelProps }, initialChannels) => {
      const otherFlipDir = flipDir === FLIP_DIR.hflip ? FLIP_DIR.vflip : FLIP_DIR.hflip;
      setChannelFlips(prevSettings => {
        const currSettings = {
          ...prevSettings,
          [channel]: {
            channel,
            name,
            position,
            [otherFlipDir]:
              prevSettings?.[channel]?.[otherFlipDir] ?? initialChannelProps[otherFlipDir],
            [flipDir]: flipOn
          }
        };
        setIsChannelFlipsChanged(
          !initialChannels.some(initialChannel => initialChannel.channel === channel) ||
            initialChannels.some(initialChannel => {
              const updatedChannel = currSettings[initialChannel.channel];
              return (
                updatedChannel &&
                (updatedChannel[FLIP_DIR.vflip] !== initialChannel[FLIP_DIR.vflip] ||
                  updatedChannel[FLIP_DIR.hflip] !== initialChannel[FLIP_DIR.hflip])
              );
            })
        );
        return currSettings;
      });
    },
    []
  );

  const onChannelToggle = useCallback((enabled, channel, initialChannels) => {
    setChannelFlips(prevSettings => {
      const isChannelInInitial = initialChannels.some(
        initialChannel => initialChannel.channel === channel.channel
      );
      const isChannelRemoved = !enabled && isChannelInInitial;
      const isChannelAdded = enabled && !isChannelInInitial;

      if (isChannelAdded) {
        removedChannels.current.delete(channel.channel);
      } else if (isChannelRemoved) {
        removedChannels.current.add(channel.channel);
      }

      setIsChannelFlipsChanged(
        isChannelAdded ||
          isChannelRemoved ||
          initialChannels.some(initialChannel => {
            const c = prevSettings[initialChannel.channel];
            return (
              c &&
              (c[FLIP_DIR.hflip] !== initialChannel[FLIP_DIR.hflip] ||
                c[FLIP_DIR.vflip] !== initialChannel[FLIP_DIR.vflip])
            );
          })
      );

      if (enabled) {
        return { ...prevSettings, [channel.channel]: channel };
      } else {
        return Object.fromEntries(
          Object.entries(prevSettings || {}).filter(([key]) => key !== channel.channel)
        );
      }
    });
  }, []);

  const update = useCallback(async () => {
    if (!deviceImei) {
      return {
        updated: false,
        errorMsg: t(`Devices.MultiView.ImeiRequired`)
      };
    }
    const remainingChannels = (channels || []).reduce(
      (acc, initialChannel) =>
        removedChannels.current.has(initialChannel.channel)
          ? acc
          : [
              ...acc,
              channelFlips?.[initialChannel.channel]
                ? {
                    ...initialChannel,
                    ...channelFlips[initialChannel.channel]
                  }
                : initialChannel
            ],
      []
    );
    const addtionalChannels = Object.values(channelFlips || {}).filter(
      changedChannel => !channels.some(c => c.channel === changedChannel.channel)
    );

    const { updated, error } = await dispatch(
      updateCameraChannels({
        deviceImei,
        channels: [...remainingChannels, ...addtionalChannels]
      })
    );
    if (updated) {
      setIsChannelFlipsChanged(false);
    }
    return {
      updated,
      errorMsg:
        error &&
        t(`Devices.MultiView.UpdateError`, {
          error: parseErrorMessage(error)
        })
    };
  }, [dispatch, deviceImei, channelFlips, channels]);

  return {
    update,
    isChannelFlipsChanged,
    channelFlips,
    onFlip,
    onChannelToggle
  };
}

export const ChannelFlipsField = ({
  deviceImei,
  label,
  channelFlips,
  onFlip = (flipDir, flipOn, channel, channels) => {},
  channelItemStyle,
  bordered = false,
  readOnly = false,
  container,
  className,
  onChannelToggle = (enabled, channel, channels) => {}
}) => {
  const { isFetching, channels } = useCameraChannels(deviceImei);
  const can = useCan();
  const canUVC = useMemo(
    () =>
      channels?.length &&
      can({
        oneOfFeatureFlags: [
          FeatureFlag.multiIQUVC.flag
          //FeatureFlag.evoPhaseTGE.flag - TO BE ADDED when NPI removal for TN360-11236
        ]
      }),
    [channels]
  );

  const Container = useMemo(() => container || (({ children }) => <>{children}</>), []);
  const isFlip = useCallback(
    (channelId, flipDir) =>
      channelFlips?.[channelId]
        ? channelFlips?.[channelId]?.[flipDir]
        : channels?.find(c => c.channel === channelId)?.[flipDir],
    [channels, channelFlips]
  );
  return (
    deviceImei && (
      <Skeleton active loading={isFetching} style={{ width: '100%' }}>
        {channels?.length && (
          <Container>
            {label}
            <List
              size="small"
              itemLayout={readOnly ? 'horizontal' : 'vertical'}
              bordered={bordered}
              dataSource={channels}
              rowKey={'channel'}
              className={className}
              renderItem={channel => {
                const isUVCChannel = channel.channel === UVC_Channel_Config.channel;
                const shouldRender = readOnly
                  ? (isUVCChannel && canUVC) || !isUVCChannel
                  : !isUVCChannel;
                return (
                  shouldRender && (
                    <ChannelFlipsItem
                      key={channel.channel}
                      channel={channel.channel}
                      name={channel.name}
                      position={channel.position}
                      defaultHflip={channel[FLIP_DIR.hflip]}
                      defaultVflip={channel[FLIP_DIR.vflip]}
                      hflip={isFlip(channel.channel, FLIP_DIR.hflip)}
                      vflip={isFlip(channel.channel, FLIP_DIR.vflip)}
                      onHFlip={flip => onFlip(FLIP_DIR.hflip, flip, channel, channels)}
                      onVFlip={flip => onFlip(FLIP_DIR.vflip, flip, channel, channels)}
                      style={channelItemStyle}
                      readOnly={readOnly}
                      showPosition={!isUVCChannel}
                    />
                  )
                );
              }}
              footer={
                !readOnly &&
                canUVC && (
                  <UVCChannelField
                    initialValue={channels?.find(c => c.channel === UVC_Channel_Config.channel)}
                    value={channelFlips?.[UVC_Channel_Config.channel]}
                    onToggle={(enabled, channel) => onChannelToggle(enabled, channel, channels)}
                    isFlip={isFlip}
                    onFlip={(flipDir, flipOn, channel) =>
                      onFlip(flipDir, flipOn, channel, channels)
                    }
                  />
                )
              }
            />
          </Container>
        )}
      </Skeleton>
    )
  );
};

const ChannelFlipsItem = ({
  channel,
  name,
  position,
  defaultHflip,
  defaultVflip,
  hflip,
  vflip,
  onHFlip = flipOn => {},
  onVFlip = flipOn => {},
  style,
  readOnly = false,
  showName = true,
  showPosition = false
}) => {
  const { t } = useTranslation();
  const channelTitle = useMemo(
    () =>
      `${showName ? t([`Devices.MultiView.${name}`, name]) : ''}${
        showPosition ? ` (${t(`Devices.MultiView.${position}`)})` : ''
      }`,
    [t, showName, name, showPosition, position]
  );
  return (
    <List.Item
      style={style}
      actions={[FLIP_DIR.hflip, FLIP_DIR.vflip].map(dir => (
        <Space key={`${channel.channel}-${dir}`}>
          <span style={{ fontSize: 13, color: readOnly ? 'inherit' : '#000000e0' }}>
            {t(`Devices.MultiView.${dir}`)}
          </span>
          <Switch
            title={t(`Devices.MultiView.${dir}`)}
            size="small"
            defaultChecked={dir === FLIP_DIR.hflip ? defaultHflip : defaultVflip}
            disabled={readOnly}
            checked={dir === FLIP_DIR.hflip ? hflip : vflip}
            onChange={dir === FLIP_DIR.hflip ? onHFlip : onVFlip}
          />
        </Space>
      ))}
      extra={!readOnly && <FlipCard hflip={hflip} vflip={vflip} size={60} />}
    >
      {channelTitle && (readOnly ? channelTitle : <List.Item.Meta description={channelTitle} />)}
    </List.Item>
  );
};

const UVCChannelField = ({
  initialValue,
  isFlip = (channelId, flipDir) => {},
  onFlip = (flipDir, flipOn, channel) => {},
  onToggle = (enabled, channel) => {}
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(initialValue?.channel);
  const uvcChannel = useMemo(() => (initialValue?.channel ? initialValue : UVC_Channel_Config), [
    initialValue
  ]);

  const onChange = useCallback(
    e => {
      setChecked(e.target.checked);
      onToggle(e.target.checked, uvcChannel);
    },
    [onToggle, uvcChannel]
  );

  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Checkbox checked={checked} onChange={onChange}>
        {t(`Devices.MultiView.HaveUVC`)}
      </Checkbox>
      {checked && (
        <ChannelFlipsItem
          style={{ padding: 0 }}
          channel={uvcChannel?.channel}
          name={uvcChannel?.name}
          position={uvcChannel?.position}
          defaultHflip={uvcChannel?.[FLIP_DIR.hflip]}
          defaultVflip={uvcChannel?.[FLIP_DIR.vflip]}
          hflip={isFlip(uvcChannel?.channel, FLIP_DIR.hflip)}
          vflip={isFlip(uvcChannel?.channel, FLIP_DIR.vflip)}
          onHFlip={flip => onFlip(FLIP_DIR.hflip, flip, uvcChannel)}
          onVFlip={flip => onFlip(FLIP_DIR.vflip, flip, uvcChannel)}
          showName={false}
        />
      )}
    </Space>
  );
};
