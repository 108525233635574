export function rulesetObjectToDBO(ruleset) {
  return {
    name: ruleset.name,
    actionMap: JSON.stringify(ruleset.actionMap || {}),
    actions: JSON.stringify(ruleset.actions || []),
    desc: ruleset.desc,
    forELD: ruleset.forELD,
    isPreRelease: ruleset.isPreRelease,
    minRest: ruleset.minRest,
    options: JSON.stringify(ruleset.options || {}),
    period: ruleset.period,
    region: ruleset.region,
    reportMap: JSON.stringify(ruleset.reportMap),
    rules: JSON.stringify(ruleset.rules)
  };
}

export function rulesetDBOToObject(ruleset) {
  return {
    name: ruleset.name,
    actionMap: JSON.parse(ruleset.actionMap),
    actions: JSON.parse(ruleset.actions),
    desc: ruleset.desc,
    forELD: ruleset.forELD,
    isPreRelease: ruleset.isPreRelease,
    minRest: ruleset.minRest,
    options: JSON.parse(ruleset.options),
    period: ruleset.period,
    region: ruleset.region,
    reportMap: JSON.parse(ruleset.reportMap),
    rules: JSON.parse(ruleset.rules)
  };
}
