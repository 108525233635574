import { TNDataService } from '../tnDataService';
import { devicesDataAdapter } from './devices';
import { reduxAdapter } from './reduxAdapter';
import { vehicleTypesDataAdapter } from './vehicleTypes';

const vehiclesDataAdapter = reduxAdapter({
  persist: async vehicles => {
    if (vehicles && vehicles.length) {
      await TNDataService.insertOrUpdateVehiclesInBulk(vehicles);
    }
  },
  load: async embedDevice => {
    const vehicles = await TNDataService.getAllVehicles();
    const vehicleTypes = await vehicleTypesDataAdapter.load();
    const vehicleTypesMap =
      vehicleTypes?.reduce((a, vt) => {
        a[vt.id] = vt;
        return a;
      }, {}) || {};
    let allDevices = null;
    if (embedDevice) {
      allDevices = await devicesDataAdapter.load();
    }

    vehicles.forEach(v => {
      if (v.type?.id) {
        const vehicleType = vehicleTypesMap[v.type.id];
        if (vehicleType) {
          v.type = vehicleType;
        }
      }

      if (embedDevice) {
        const devices = allDevices.filter(d => v.id === d.vehicle?.id) || [];
        devices.forEach(d => {
          d.vehicle = Object.assign({}, v);
        });
        v.devices = devices;
      }
    });
    return vehicles;
  }
});

export { vehiclesDataAdapter };
