import { Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useLocalization } from 'features/localization/localizationSlice';
import { InfoCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const TMADeclarationTable = ({ tmaData, registrations, isDataLoading }) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const columns = [
    {
      title: (
        <>
          {t('Regulatory.TMADeclarationTable.Date')}{' '}
          <Tooltip placement={'top'} title={t('Regulatory.TMADeclarationTable.DateTooltip')}>
            <InfoCircleFilled />
          </Tooltip>
        </>
      ),
      dataIndex: 'timeAt',
      key: 'date',
      width: 150,
      render: text => (text ? dayjs(text).format(localization.formats.time.formats.dby_imsp) : '')
    },
    {
      title: t('Regulatory.TMADeclarationTable.Registration'),
      dataIndex: 'vehicleId',
      key: 'registration',
      render: text => registrations?.find(reg => reg.vehicleId === text)?.registration || ''
    },
    {
      title: t('Regulatory.TMADeclarationTable.SDDataRecordType'),
      dataIndex: 'type',
      key: 'sdDataRecordType',
      width: 100,
      render: text => (text ? text.charAt(0).toUpperCase() + text.slice(1) : '')
    },
    {
      title: t('Regulatory.TMADeclarationTable.VehicleType'),
      dataIndex: 'attributes',
      key: 'type',
      render: text => text?.vehicleType || ''
    },
    {
      title: t('Regulatory.TMADeclarationTable.Axles'),
      dataIndex: 'attributes',
      key: 'axles',
      width: 100,
      render: text => text?.numberOfAxles || ''
    },
    {
      title: t('Regulatory.TMADeclarationTable.TotalCombinationMass'),
      dataIndex: 'attributes',
      width: 120,
      key: 'totalCombinationMass',
      render: text =>
        text?.totalCombinationMass ? parseFloat(text?.totalCombinationMass).toFixed(1) : ''
    },
    {
      title: t('Regulatory.TMADeclarationTable.CommentType'),
      dataIndex: 'attributes',
      key: 'commentType',
      render: text => text?.commentType || ''
    },
    {
      title: t('Regulatory.TMADeclarationTable.Comment'),
      dataIndex: 'attributes',
      key: 'comment',
      render: text => text?.comment || ''
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={tmaData}
      pagination={false}
      loading={isDataLoading}
      scroll={{ y: `calc(100vh - 250px)` }}
    />
  );
};

export default TMADeclarationTable;
