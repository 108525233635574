import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'components/ant';
import { Space } from 'antd';
import i18n from 'i18nextConfig';
//helpers
import { format } from 'utils/dates';
import { capitalize } from 'utils/strings';
import { isString } from 'lodash';

//constants
import { IQCameraDeviceConfig } from 'containers/Administration/Devices/constants';
import { PATHS } from 'containers/Administration/Users/constants';
import { ColumnKeys, Columns, ChangesColumns, AUDITS_ACTIONS } from './constants';

//styles
import styles from './AuditsTable.module.scss';

export const prepareAuditsColumnsForTable = t =>
  Columns.map(column => ({
    ...column,
    title: t(`Audits.${column.title}`)
  }));

const prepareColumnsForChangesTable = (rowId, t) =>
  ChangesColumns.map(column => ({
    ...column,
    key: column.key + rowId,
    title: t(`Audits.${column.title}`),
    render: (text, record, rowIndex) =>
      Array.isArray(text) ? (
        <Space direction="vertical" size="small">
          {text.map((item, index) => (
            <span key={`${record.rowKey}${rowIndex}-${index}`} className={styles.wrapText}>
              {item}
            </span>
          ))}
        </Space>
      ) : (
        <span className={styles.wrapText}>{text}</span>
      ),
    ...(column.key === ColumnKeys.KEY && {
      onCell: record => (record.isPartial ? { rowSpan: record.rowSpan } : {})
    })
  }));

export const prepareAuditsDataForTable = (
  data = [],
  localization,
  t,
  timeKeys = [],
  hasPermission = () => true,
  onRenderChangeItemValue = (displayValue, { changeItem, changeKey, colKey }) => displayValue,
  onRenderChangeItemKey = changeKey => changeKey,
  hideEmptyChangeItem = false
) => {
  const dataForTable = [];
  data.forEach(audit => {
    const ret = {
      key: audit.id,
      [ColumnKeys.TIME]: renderTime(audit[ColumnKeys.TIME], localization),
      [ColumnKeys.ACTION]: (
        <span>{audit[ColumnKeys.ACTION] && t(`Audits.${audit[ColumnKeys.ACTION]}`)}</span>
      ),
      [ColumnKeys.USER]: renderUser(audit[ColumnKeys.USER], audit[ColumnKeys.USERID]),
      [ColumnKeys.COMMENT]: <span>{audit[ColumnKeys.COMMENT]}</span>,
      [ColumnKeys.CHANGES]: renderChangesTable(
        audit[ColumnKeys.CHANGES],
        audit.id,
        t,
        localization,
        timeKeys,
        hasPermission,
        onRenderChangeItemValue,
        onRenderChangeItemKey,
        hideEmptyChangeItem
      )
    };
    const hasCommentOnly = !!audit[ColumnKeys.COMMENT] && !ret[ColumnKeys.CHANGES],
      hasChangeTableData = !!ret[ColumnKeys.CHANGES]?.props?.dataSource?.length;
    if (
      hasCommentOnly ||
      hasChangeTableData ||
      [AUDITS_ACTIONS.DELETE, AUDITS_ACTIONS.RESTORE].includes(audit[ColumnKeys.ACTION])
    ) {
      dataForTable.push(ret);
    }
  });
  return dataForTable;
};

const renderTime = (time, localization) => {
  if (!time || !localization) {
    return;
  }
  return <span>{format(new Date(time), localization.formats.time.formats.dby_imsp)}</span>;
};

const renderUser = (user, userId) => {
  if (!user?.id) {
    // fallback to 'userId', if 'user' stanza is not provided
    return <Link to={`${PATHS.USER_VIEW}/${userId}`}>{userId}</Link>;
  }
  const fullName = `${user?.firstName} ${user?.lastName}`;
  return !!user && <Link to={`${PATHS.USER_VIEW}/${user?.id}`}>{fullName}</Link>;
};

const preFixedChangeItem = {
  audioAlertsEnabled: item => {
    let from = '',
      to = '';
    try {
      from = (JSON.parse(item?.[0]) || [])
        .filter(typeKey => !!IQCameraDeviceConfig.AudioAlertTypes[typeKey])
        .map(typeKey => i18n.t(`Devices.View.CameraAudioAlert.${typeKey}`))
        .join(', ');
    } catch (error) {
      from = '';
    }
    try {
      to = (JSON.parse(item?.[1]) || [])
        .filter(typeKey => !!IQCameraDeviceConfig.AudioAlertTypes[typeKey])
        .map(typeKey => i18n.t(`Devices.View.CameraAudioAlert.${typeKey}`))
        .join(', ');
    } catch (error) {
      to = '';
    }
    return [from, to];
  }
};

const prepareDataForChangesColumn = (
  data,
  localization,
  timeKeys,
  hasKeyPermission = key => true,
  onRenderChangeItemValue = (displayValue, { changeItem, changeKey, colKey }) => displayValue,
  onRenderChangeItemKey = changeKey => changeKey,
  hideEmptyChangeItem = false
) => {
  // Build an object with "key", "from" and "to" values
  // in order to render them in the inner table
  const changesDataSource = Object.keys(data)
    .filter(hasKeyPermission)
    .reduce((collection, changeKey, changeKeyIndex) => {
      const timeConfig = (timeKeys || []).find(timeKey => timeKey.key === changeKey);
      let keyChanges = data[changeKey] || [];
      try {
        keyChanges = isString(keyChanges) ? JSON.parse(keyChanges) : keyChanges;
      } catch (error) {
        keyChanges = data[changeKey] || [];
      }
      if (keyChanges.forEach && keyChanges.length > 2) {
        //display the removal and addition of the same key in the same record [[],[added],[removed],[]]
        const partialRecords = [];
        keyChanges.forEach((keyChange, keyChangeIndex) => {
          if (keyChangeIndex % 2 === 0) {
            const partialFrom = keyChange || [];
            const partialTo = keyChanges[keyChangeIndex + 1] || [];
            if (partialFrom?.length || partialTo?.length) {
              //in case only adding/removing
              const partialIndex = partialRecords.length;
              partialRecords.push({
                rowKey: `${changeKey}-${changeKeyIndex}-${partialIndex}`,
                [ColumnKeys.KEY]: changeKey,
                [ColumnKeys.FROM]: partialFrom.map(item =>
                  renderPartialChangeItem(item, timeConfig, localization)
                ),
                [ColumnKeys.TO]: partialTo.map(item =>
                  renderPartialChangeItem(item, timeConfig, localization)
                ),
                isPartial: true
              });
            }
          }
        });
        collection.push(
          ...partialRecords.map((partialRecord, partialRecordIndex) => ({
            ...partialRecord,
            rowSpan: partialRecords.length > 1 ? (partialRecordIndex === 0 ? 2 : 0) : 1
          }))
        );
      } else {
        const item = preFixedChangeItem[changeKey]
          ? preFixedChangeItem[changeKey](data[changeKey])
          : data[changeKey];

        const changeItem = {
          rowKey: `${changeKey}-${changeKeyIndex}`,
          [ColumnKeys.KEY]: onRenderChangeItemKey(changeKey),
          [ColumnKeys.FROM]: onRenderChangeItemValue(
            renderChangeItem(item, 0, timeConfig, localization),
            {
              changeItem: item,
              changeKey,
              colKey: ColumnKeys.FROM
            }
          ),
          [ColumnKeys.TO]: onRenderChangeItemValue(
            renderChangeItem(item, 1, timeConfig, localization, true),
            {
              changeItem: item,
              changeKey,
              colKey: ColumnKeys.TO
            }
          )
        };
        if (
          !(
            hideEmptyChangeItem &&
            !changeItem[ColumnKeys.FROM]?.length &&
            !changeItem[ColumnKeys.TO]?.length
          )
        ) {
          collection.push(changeItem);
        }
      }
      return collection;
    }, []);
  return changesDataSource;
};
const getChangeItemStr = value => (typeof value === 'string' ? value : JSON.stringify(value));
const getEntityChange = entityChange => {
  if (entityChange?.hasOwnProperty('entity')) {
    //show name only for standard entity, otherwise get rid of entity
    if (entityChange?.hasOwnProperty('name') && entityChange?.hasOwnProperty('id')) {
      return `${entityChange?.name}${
        entityChange?.entityType ? `(${capitalize(entityChange.entityType)})` : ''
      }`;
    } else {
      delete entityChange?.entity;
      delete entityChange?.entityType;
      return getChangeItemStr(entityChange);
    }
  }
};

const renderPartialChangeItem = (itemValue, timeConfig, localization) => {
  let ret = '';
  if (itemValue) {
    ret =
      getEntityChange(Array.isArray(itemValue) ? itemValue[0] : itemValue) ||
      getChangeItemStr(itemValue);
  }
  if (timeConfig && ret && ret !== 'null') {
    return timeConfig.hasOwnProperty('get') ? timeConfig.get(ret) : renderTime(ret, localization);
  }
  return ret;
};

const renderChangeItem = (item, itemKey, timeConfig, localization, nonNull = false) => {
  let ret = '';
  if (Array.isArray(item)) {
    const toValue = item[itemKey];
    if (Array.isArray(toValue)) {
      ret = toValue.map(getEntityChange).filter(item => !!item);
      ret = ret.length ? ret : getChangeItemStr(toValue);
    } else {
      ret = getEntityChange(toValue) || getChangeItemStr(toValue);
    }
  } else if (nonNull) {
    ret = isString(item) ? item : JSON.stringify(item);
  }
  if (timeConfig && ret && ret !== 'null') {
    return timeConfig.hasOwnProperty('get') ? timeConfig.get(ret) : renderTime(ret, localization);
  }
  return ret;
};

// render 3 column inner table "Key, From, To"
const renderChangesTable = (
  data,
  rowId,
  t,
  localization,
  timeKeys,
  hasPermission = () => true,
  onRenderChangeItemValue = (displayValue, { changeItem, changeKey, colKey }) => displayValue,
  onRenderChangeItemKey = changeKey => changeKey,
  hideEmptyChangeItem = false
) => {
  try {
    const changes = JSON.parse(data || '{}');
    const { version, id, ...filteredChanges } = changes;

    return (
      Object.keys(filteredChanges).length > 0 && (
        <Table
          rowKey={record => record.rowKey + '-' + rowId}
          dataSource={prepareDataForChangesColumn(
            filteredChanges,
            localization,
            timeKeys,
            hasPermission,
            onRenderChangeItemValue,
            onRenderChangeItemKey,
            hideEmptyChangeItem
          )}
          columns={prepareColumnsForChangesTable(rowId, t)}
          pagination={false}
          bordered
          size="small"
        />
      )
    );
  } catch (err) {
    console.log(err);
  }
};
