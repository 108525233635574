import { Button, Card, Collapse, Table, Tooltip } from 'antd';
import styles from '../GPIOForm.module.scss';
import { useTranslation } from 'react-i18next';
import { lowerCase, startCase } from 'lodash';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { formatDiginData, formatDigoutTableData, useConfigMapping } from '../helpers';
import { useGpioRelatedConfigKeys } from 'services/nextgen/ngConfigKeyApi';
import { BPM_VPM_KEYS, DRIVER_KEYS, IDLE_KEYS, SIREN_KEYS } from '../constants';
import { services, useCanEveryCompanyService } from 'features/permissions';

const useFormattedConfig = data => {
  const isVPMEnabled = useCanEveryCompanyService(services.VPM);

  const { gpioData, vpmData, bpmData, driverData, speedsirenData } = useGpioRelatedConfigKeys();

  const {
    inBiasMap,
    inTriggerMap,
    inOccurrenceMap,
    outBiasMap,
    outTriggerMap,
    outOccurrenceMap,
    diginFormConfig
  } = useConfigMapping(gpioData);

  if (data.input.startsWith('digin')) {
    const diginIODefinition = diginFormConfig.find(cfgField => cfgField.type === 'io');
    return formatDiginData(data, inBiasMap, inTriggerMap, inOccurrenceMap, diginIODefinition);
  } else {
    const vpmConfigs = vpmData?.filter(s => BPM_VPM_KEYS.includes(s.key));
    const bpmConfigs = bpmData?.filter(s => BPM_VPM_KEYS.includes(s.key));
    const idleConfigs = isVPMEnabled
      ? vpmData?.filter(s => IDLE_KEYS.includes(s.key))
      : bpmData?.filter(s => IDLE_KEYS.includes(s.key));
    const driverConfigs = driverData?.filter(s => DRIVER_KEYS.includes(s.key));
    const sirenConfigs = speedsirenData?.filter(s => SIREN_KEYS.includes(s.key));
    return formatDigoutTableData(
      data,
      outBiasMap,
      outOccurrenceMap,
      outTriggerMap,
      vpmConfigs,
      bpmConfigs,
      idleConfigs,
      driverConfigs,
      sirenConfigs
    );
  }
};

const ConfigItem = ({ data, columns, allowEditing, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const formattedData = useFormattedConfig(data);

  return (
    <Card className={styles.configItemCard}>
      <div className={styles.spaceBetween}>
        <div className={styles.header}>{`Ch${data.channel} ${data?.custom_name ?? ''}`}</div>
        {!allowEditing && (
          <Tooltip
            title={t(
              `CompanyConfig.DeviceConfigurations.GPIOConfig.Settings.Tooltip.${startCase(
                lowerCase(data.scope)
              )}`
            )}
          >
            <div className={styles.scope}>{`${startCase(lowerCase(data.scope))} Settings`}</div>
          </Tooltip>
        )}
      </div>
      {allowEditing && (
        <div className={styles.actionIcons}>
          <Button type="link" icon={<EditFilled />} onClick={() => onEdit(data)} />
          <Button
            type="link"
            icon={<DeleteFilled style={{ color: 'red' }} />}
            onClick={() => onDelete(data)}
          />
        </div>
      )}
      <Table
        size="small"
        columns={columns}
        dataSource={[formattedData]}
        rowKey={row => row.id}
        pagination={false}
      />
      {Object.keys(formattedData.ioDetail ?? {}).length > 0 &&
        (formattedData.key.startsWith('digin') ? (
          <div className={styles.extraDetails}>
            <div className={styles.header}>
              {t(`CompanyConfig.DeviceConfigurations.Table.IO Definition Detail`)}
            </div>
            <div className={styles.extraBody}>
              {Object.keys(formattedData.ioDetail).map(key => (
                <div key={key + formattedData.id}>{`${key}${
                  formattedData.ioDetail[key] ? ' : ' + formattedData.ioDetail[key] : ''
                }`}</div>
              ))}
            </div>
          </div>
        ) : (
          Object.keys(formattedData.ioDetail).map(serviceKey => (
            <div className={styles.extraDetails} key={serviceKey}>
              <div className={styles.header}>{serviceKey}</div>
              <Table
                pagination={false}
                columns={Object.keys(formattedData.ioDetail[serviceKey]).map(key => ({
                  title: key,
                  dataIndex: key,
                  key
                }))}
                dataSource={[formattedData.ioDetail[serviceKey]]}
              />
            </div>
          ))
        ))}
    </Card>
  );
};

export const ConfigCard = ({ config, ...props }) => {
  const { key, value, data } = config;
  const { t } = useTranslation();

  const configKey = key + data.map(item => item.id);

  return (
    <Collapse
      key={configKey}
      defaultActiveKey={configKey}
      rootClassName={styles.configCollapse}
      bordered={false}
      expandIconPosition="end"
      items={[
        {
          label: (
            <div className={styles.spaceBetween}>
              <div className={styles.header}>
                {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.' + value, value)}
              </div>
              <div className={styles.channelCount}>{`${data.length} Channel(s)`}</div>
            </div>
          ),
          key: configKey,
          children: data.map(item => <ConfigItem key={key + item.channel} data={item} {...props} />)
        }
      ]}
    />
  );
};

export const AdditionalConfigCard = ({ items }) => {
  return (
    <Collapse
      defaultActiveKey={items[0]?.key}
      rootClassName={styles.configCollapse}
      bordered={false}
      expandIconPosition="end"
      items={items}
    />
  );
};
