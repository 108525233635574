export const SQLResponseCode = {
  Success: 1,
  Failed: 0
};

export const SqlMsgRequestType = {
  Tables: 'tables',
  ExecuteSql: 'executeSql',
  ExecuteSqlV2: 'executeSqlV2',
  ExecuteSqlV3: 'executeSqlV3',
  RunSql: 'runSql',
  ExecuteMultipleSql: 'executeMultipleSql',
  IsDbOpened: 'isDbOpened',
  MigrateDb: 'migrateDb',
  OpenDb: 'openDb',
  CloseDb: 'closeDb',
  DropDb: 'dropDb',
  DeleteAllUserDBs: 'deleteAllUserDBs'
};
