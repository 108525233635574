import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const regionsDataAdapter = reduxAdapter({
  persist: async regions => {
    if (regions && regions.length) {
      for (const r of regions) {
        if (r.id) {
          await TNDataService.insertOrUpdateRegion(r);
        }
      }
    }
  },
  load: async () => {
    const regions = await TNDataService.getAllRegions();
    return regions;
  }
});

export { regionsDataAdapter };
