import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Space, Dropdown } from 'antd';
import { useCan, entities } from 'features/permissions';

import styles from './VehiclesBulkEdit.module.scss';
import { useHistory } from 'react-router';
import { useUser } from 'features/user/userSlice';
import { BULK_EDIT_ENTITY, BUTTON_IDS } from './helper';
import { useBulkEdit } from 'features/bulkEdit/BulkEditProvider';
import { EntityEditModal } from './EntityEdit/EntityEditModal';
import {
  getRegistrationCountryLabel,
  getRegistrationTerritoryLabel
} from 'features/localization/localization';
import { useCurrentCompany } from 'features/company/companySlice';

export const BulkEditHeader = ({
  selectedRows = [],
  onBulkSave = () => {},
  onBulkDelete = () => {}
}) => {
  const { t } = useTranslation();
  const [bulkEditEntity, setBulkEditEntity] = useState(null);
  const [edrVehicles, setEdrVehicles] = useState([]);
  const can = useCan();
  const currentCompany = useCurrentCompany();
  const canEditVehicle = can({ everyEntity: [entities.VEHICLE_UPDATE] });
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;

  const canDeleteVehicle = can({ everyEntity: [entities.VEHICLE_DESTROY] }) && isSiteAdmin;
  const { isBulkDeleting: isDeleting, isBulkUpdating, selectedRowKeys } = useBulkEdit();
  const isProcessing = useMemo(() => isBulkUpdating || isDeleting, [isBulkUpdating, isDeleting]);

  const { getMenuItemMeta, menu } = useMemo(() => {
    const getMenuItemMeta = entity => {
      const selectedCompanyIds = new Set(
        selectedRows.map(v => v.companyId).filter(companyId => !!companyId)
      );
      const isAllSameCompany = selectedCompanyIds.size < 2;

      switch (entity) {
        case BULK_EDIT_ENTITY.FLEETS.entity:
          return {
            label: t('Fleets.Fleets'),
            disabled: !(isAllSameCompany && Array.from(selectedCompanyIds)?.[0]),
            disabledTootip: t(`Vehicles.BulkEdit.SameCompanyOnly`),
            companyId: Array.from(selectedCompanyIds)[0],
            id: BUTTON_IDS.edit_fleets
          };
        case BULK_EDIT_ENTITY.PHONE_NUMBER.entity:
          return {
            label: t('Vehicles.Form.PhoneNumber'),
            id: BUTTON_IDS.edit_phone
          };
        case BULK_EDIT_ENTITY.REGISTRATION_COUNTRY_AND_STATE.entity:
          return {
            label:
              getRegistrationCountryLabel(currentCompany?.country) +
              ' / ' +
              getRegistrationTerritoryLabel(currentCompany?.country),
            id: BUTTON_IDS.edit_registration
          };
        case BULK_EDIT_ENTITY.NOTES.entity:
          return {
            label: t('Vehicles.Form.Notes'),
            placeholder: t('Vehicles.Form.NotesPlaceholder'),
            id: BUTTON_IDS.edit_notes
          };
        case BULK_EDIT_ENTITY.OUT_SERVICE_AT.entity:
          return {
            label: t('Vehicles.Form.OutServiceAt'),
            placeholder: t('Vehicles.Form.OutServiceAtPlaceHolder'),
            id: BUTTON_IDS.edit_out_service_at
          };
        case BULK_EDIT_ENTITY.IN_SERVICE_AT.entity:
          return {
            label: t('Vehicles.Form.InServiceAt'),
            placeholder: t('Vehicles.Form.InServiceAtPlaceHolder'),
            id: BUTTON_IDS.edit_in_service_at
          };
        case BULK_EDIT_ENTITY.ELD_VEHICLE.entity:
          return {
            label: t('Vehicles.Form.IsEldVehicle'),
            id: BUTTON_IDS.edit_is_eld
          };
        case BULK_EDIT_ENTITY.TYPE.entity:
          return {
            label: t('Vehicles.Form.Type'),
            disabled: !(isAllSameCompany && Array.from(selectedCompanyIds)?.[0]),
            disabledTootip: t(`Vehicles.BulkEdit.SameCompanyOnly`),
            companyId: Array.from(selectedCompanyIds)[0],
            id: BUTTON_IDS.edit_type
          };
        case BULK_EDIT_ENTITY.VEHICLE_MODEL.entity:
          return {
            label: t('Vehicles.Form.VehicleModel'),
            disabled: !(isAllSameCompany && Array.from(selectedCompanyIds)?.[0]),
            disabledTootip: t(`Vehicles.BulkEdit.SameCompanyOnly`),
            companyId: Array.from(selectedCompanyIds)[0],
            id: BUTTON_IDS.edit_model
          };
        case BULK_EDIT_ENTITY.DEVICE.entity:
          return {
            label: t('Vehicles.Form.Device'),
            id: BUTTON_IDS.edit_devices
          };
        default:
          return {};
      }
    };

    return {
      getMenuItemMeta,
      menu: {
        onClick: ({ key }) => setBulkEditEntity(key),
        items: Object.values(BULK_EDIT_ENTITY)
          .filter(i => i.can === undefined || can(i.can))
          .map(opt => {
            const meta = getMenuItemMeta(opt.entity);
            return {
              label: (
                <Tooltip title={meta.disabled && meta.disabledTootip} placement="left">
                  <div>{meta.label}</div>
                </Tooltip>
              ),
              id: meta.id,
              key: opt.entity,
              disabled: meta.disabled
            };
          })
      }
    };
  });

  useEffect(() => {
    setEdrVehicles(selectedRows.filter(v => v?.devices?.some(d => d.type?.code === 'EDR')));
  }, [selectedRows]);

  const onEditConfirm = useCallback(
    (entityValues, callback = ({ allSuccess, allFailed, devices, entity, entityValue }) => {}) => {
      onBulkSave(bulkEditEntity, entityValues, selectedRows, callback);
      setBulkEditEntity(null);
    },
    [onBulkSave, selectedRows, bulkEditEntity]
  );

  const onEditCancel = useCallback(() => {
    setBulkEditEntity(null);
  }, []);

  return (
    <>
      <div>
        <span>{`${t('BulkEdit.SelectedRows', { count: selectedRows.length })}.`}</span>
        {!selectedRows.length && (
          <span className={styles.topBarInfo}>{`${t('BulkEdit.SelectedRowsInfo')}.`}</span>
        )}
      </div>
      {!!selectedRows.length && (
        <Space>
          {canEditVehicle && (
            <Dropdown menu={menu} disabled={isProcessing}>
              <Button
                type="primary"
                id={BUTTON_IDS.edit_dropdown}
                className={styles.bulkEditDropdown}
              >
                <Space>
                  {t('BulkEdit.EditColumn')}
                  <i className={'tn-i-chevron-down'} />
                </Space>
              </Button>
            </Dropdown>
          )}
          {canDeleteVehicle && (
            <Tooltip title={edrVehicles.length !== 0 && t('Vehicles.Form.DeleteEDRBulkError')}>
              <Button
                disabled={isProcessing || edrVehicles.length !== 0}
                id={BUTTON_IDS.delete_multi_vehicles}
                onClick={() => onBulkDelete(selectedRows)}
              >
                <>
                  <span>{`${t('Common.DeleteButton')} ${selectedRows.length} `}</span>
                  <span style={{ textTransform: 'lowercase' }}>{`${
                    selectedRows.length < 2 ? t('Vehicles.Vehicle') : t('Vehicles.Vehicles')
                  }`}</span>
                </>
              </Button>
            </Tooltip>
          )}
        </Space>
      )}
      <EntityEditModal
        show={bulkEditEntity && getMenuItemMeta(bulkEditEntity)}
        entity={bulkEditEntity}
        vehicles={selectedRows}
        onOk={onEditConfirm}
        onCancel={onEditCancel}
        {...getMenuItemMeta(bulkEditEntity)}
      />
    </>
  );
};
