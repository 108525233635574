import { useMemo } from 'react';
import { useCamerasStatus } from 'features/camera/cameraSlice';
import { CAMERA_DIAGNOSTIC_STATUS } from '../helpers';

export const useSnapshotEntityCameraStatus = snapshotsEntity => {
  const cameraIds = useMemo(
    () =>
      (snapshotsEntity?.nodeType === 'Vehicle'
        ? snapshotsEntity?.devices?.filter(
            device => device?.id && device?.type?.code === 'CAMERA'
          ) || []
        : snapshotsEntity?.deviceId && snapshotsEntity?.deviceTypeCode === 'CAMERA'
        ? [{ id: snapshotsEntity?.deviceId }]
        : []
      ).map(c => c.id),
    [snapshotsEntity]
  );

  const camerasStatus = useCamerasStatus(cameraIds);

  const { availablePositions, getIsPositionFailure } = useMemo(() => {
    const availablePositions = Object.values(camerasStatus || {}).reduce(
      (a, cameraStatus) => Array.from(new Set([...a, ...(cameraStatus?.cameraPositions || [])])),
      []
    );
    const { workingPositions, failurePositions } = cameraIds.reduce(
      (acc, deviceId) =>
        camerasStatus?.[deviceId]?.status === CAMERA_DIAGNOSTIC_STATUS.RED
          ? {
              ...acc,
              failurePositions: Array.from(
                new Set([
                  ...acc.failurePositions,
                  ...(camerasStatus?.[deviceId]?.cameraPositions || [])
                ])
              )
            }
          : {
              ...acc,
              workingPositions: Array.from(
                new Set([
                  ...acc.workingPositions,
                  ...(camerasStatus?.[deviceId]?.cameraPositions || [])
                ])
              )
            },
      {
        workingPositions: [],
        failurePositions: []
      }
    );
    return {
      availablePositions,
      getIsPositionFailure: position =>
        failurePositions.includes(position) && !workingPositions.includes(position)
    };
  }, [camerasStatus, snapshotsEntity]);

  return {
    availablePositions,
    getIsPositionFailure
  };
};
