import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, Column, Table, CellMeasurer } from 'react-virtualized';
import { Checkbox, Layout, Spin, Typography } from 'antd';
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useCan, entities } from 'features/permissions';
import { bulkEditCache, toBulkUpdatePayload } from './helper';
import { LoadingTable } from 'components/grid/LoadingTable';
import { BulkEditHeader } from './BulkEditHeader';
import { format } from 'utils/dates';

import styles from './VehiclesBulkEdit.module.scss';
import { VehicleFleetsCell } from './Cell/VehicleFleetsCell';
import { VehicleActionsCell } from './Cell/VehicleActionsCell';
import { useBulkEdit } from 'features/bulkEdit/BulkEditProvider';
import { VehicleNameCell } from './Cell/VehicleNameCell';
import { VehicleMakeModelYearCell } from './Cell/VehicleMakeModelYearCell';
import {
  getRegistrationCountryLabel,
  getRegistrationTerritoryLabel
} from 'features/localization/localization';
import { useLocalization } from 'features/localization/localizationSlice';
import { devicesCellRenderer } from '../CellRenderers';

export const VehiclesBulkEditTable = ({ isLoading, onDelete, vehicles }) => {
  const can = useCan();
  const { t } = useTranslation();
  const [tableRef, setTableRef] = useState(null);

  const companies = useCompanies();
  const localization = useLocalization();
  const getCompanyName = useCallback(
    companyId => companies?.find(comp => String(comp.id) === String(companyId))?.name || '',
    [companies]
  );
  const getDateTime = useCallback(date => format(date, localization.formats.time.formats.dby_imp), [
    localization
  ]);

  const {
    isBulkDeleting: isDeleting,
    isBulkUpdating: isEditing,
    selectedRowKeys,
    errors: bulkErrors,
    toggleRowSelection,
    toggleHeaderSelection,
    handleBulkDelete,
    handleBulkEdit
  } = useBulkEdit();

  const currentCompany = useCurrentCompany();
  const isProcessing = useMemo(() => isEditing || isDeleting, [isEditing, isDeleting]);

  const getErrMsg = useCallback(vehicleId => bulkErrors?.[vehicleId]?.message, [bulkErrors]);
  const canViewDevice = can && can({ everyEntity: [entities.DEVICE_VIEW] });

  useEffect(() => {
    if (tableRef) {
      bulkEditCache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [vehicles, tableRef]);

  const {
    fixedColumnsWidth,
    getDynamicColWidth,
    showActions,
    selectedRows,
    rowGetter
  } = useMemo(() => {
    const selectedRows = vehicles.filter(i => selectedRowKeys.includes(i.id));
    const showActions =
      !selectedRows.length &&
      can({ oneOfEntities: [entities.VEHICLE_UPDATE, entities.VEHICLE_DESTROY] });
    const fixedColumnsWidth = {
      select: 20,
      name: 150,
      company: 100,
      inServiceAt: 150,
      outServiceAt: 150,
      registrationCountry: 100,
      registrationState: 100,
      vehicleModel: 150,
      devices: 150,
      type: 100,
      isEldVehicle: 100,
      fleets: 150,
      phone: 100,
      note: 100,
      actions: showActions ? 65 : 0
    };
    return {
      fixedColumnsWidth,
      showActions,
      getDynamicColWidth(width) {
        const dynamicWidth = width - Object.values(fixedColumnsWidth).reduce((a, b) => a + b, 0);
        return isNaN(dynamicWidth) || dynamicWidth < 0 ? fixedColumnsWidth.note : dynamicWidth;
      },
      selectedRows,
      rowGetter: ({ index }) => vehicles[index]
    };
  }, [vehicles, selectedRowKeys]);

  if (isLoading) {
    return <LoadingTable columnSizes={[10, 60, 60, 60, 50, 40, 40, 50, 30]} />;
  }

  return (
    <>
      <div id="vehicleBulkEditTable" className={styles.bulkEditTable}>
        <Layout.Header className={styles.topBar}>
          <BulkEditHeader
            selectedRows={selectedRows}
            onBulkSave={(entity, entityValue) => {
              handleBulkEdit(
                toBulkUpdatePayload(
                  entity,
                  entityValue,
                  vehicles.filter(i => selectedRowKeys.includes(i.id)),
                  { getDateTime }
                )
              );
            }}
            onBulkDelete={handleBulkDelete}
          />
        </Layout.Header>
        <Layout.Content className={styles.tableContent}>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Spin spinning={isProcessing} wrapperClassName={styles.tableLoader}>
                  <Table
                    deferredMeasurementCache={bulkEditCache}
                    width={width}
                    height={height}
                    headerHeight={48}
                    headerClassName={styles.tableHeader}
                    gridClassName="showScrollbarsOnHover"
                    rowClassName={({ index }) =>
                      vehicles?.[index]?.checked ? styles.tableRowSelected : styles.tableRow
                    }
                    rowHeight={bulkEditCache.rowHeight}
                    rowCount={vehicles.length}
                    rowGetter={rowGetter}
                    rowStyle={{ alignItems: 'flex-start', paddingTop: 0, paddingBottom: 0 }}
                    ref={ref => setTableRef(ref)}
                  >
                    <Column
                      width={fixedColumnsWidth.select}
                      dataKey="checked"
                      cellRenderer={({ rowData }) => {
                        return (
                          <Checkbox
                            checked={!!selectedRows.find(i => i.id === rowData.id)}
                            onChange={() => toggleRowSelection(rowData.id)}
                          />
                        );
                      }}
                      className={styles.tableCol}
                      headerRenderer={({ dataKey, label, sortBy, sortDirection }) => (
                        <Checkbox
                          checked={selectedRows?.length && selectedRows.length === vehicles.length}
                          indeterminate={
                            selectedRows?.length && selectedRows.length !== vehicles.length
                          }
                          onChange={() => toggleHeaderSelection(vehicles.map(i => i.id))}
                        />
                      )}
                    />
                    <Column
                      label={t('Vehicles.Table.Name')}
                      dataKey="name"
                      width={fixedColumnsWidth.name}
                      className={styles.unEditableCol}
                      cellRenderer={({ cellData, rowData }) => (
                        <VehicleNameCell
                          id={rowData.id}
                          name={cellData}
                          errMessage={getErrMsg(rowData.id)}
                        />
                      )}
                    />
                    <Column
                      label={t('Devices.Company')}
                      dataKey="companyId"
                      width={fixedColumnsWidth.company}
                      className={styles.unEditableCol}
                      cellRenderer={({ rowData }) => {
                        const company = companies.find(comp => comp.id === rowData.companyId);
                        const companyName = company ? company.name : '';
                        return companyName;
                      }}
                    />
                    <Column
                      label={t('Vehicles.Form.InServiceAt')}
                      dataKey="inServiceAt"
                      width={fixedColumnsWidth.inServiceAt}
                      className={styles.tableCol}
                      cellRenderer={({ cellData, rowData }) =>
                        rowData.inServiceAt
                          ? format(
                              new Date(rowData.inServiceAt),
                              localization.formats.time.formats.dby_imp
                            )
                          : ''
                      }
                    />
                    <Column
                      label={t('Vehicles.Form.OutServiceAt')}
                      dataKey="outServiceAt"
                      width={fixedColumnsWidth.outServiceAt}
                      className={styles.tableCol}
                      cellRenderer={({ cellData, rowData }) =>
                        rowData.outServiceAt
                          ? format(
                              new Date(rowData.outServiceAt),
                              localization.formats.time.formats.dby_imp
                            )
                          : ''
                      }
                    />
                    <Column
                      label={t(getRegistrationCountryLabel(currentCompany?.country))}
                      dataKey="registrationCountryName"
                      width={fixedColumnsWidth.registrationCountry}
                      className={styles.tableCol}
                    />
                    <Column
                      label={getRegistrationTerritoryLabel(currentCompany?.country)}
                      dataKey="registrationStateName"
                      width={fixedColumnsWidth.registrationState}
                      className={styles.tableCol}
                    />
                    <Column
                      label={t('Vehicles.Form.Type')}
                      dataKey="vehicleId"
                      width={fixedColumnsWidth.type}
                      className={styles.tableCol}
                      cellRenderer={props => {
                        return props?.rowData?.type?.name ?? '';
                      }}
                    />
                    <Column
                      className={styles.tableCol}
                      label={t('Vehicles.Table.Devices')}
                      dataKey="devices"
                      width={fixedColumnsWidth.devices}
                      cellRenderer={props => devicesCellRenderer({ ...props, canViewDevice })}
                    />
                    <Column
                      label={t('Vehicles.Form.VehicleModel')}
                      dataKey="vehicleModel"
                      width={fixedColumnsWidth.vehicleModel}
                      className={styles.tableCol}
                      cellRenderer={props => (
                        <CellMeasurer
                          cache={bulkEditCache}
                          columnIndex={8}
                          key={`cell-measurer-${props.dataKey}-${props.rowData.id}`}
                          parent={props.parent}
                          rowIndex={props.rowIndex}
                        >
                          <VehicleMakeModelYearCell
                            companyId={props.rowData.companyId}
                            vehicleModelId={props.rowData.vehicleModelId}
                          />
                        </CellMeasurer>
                      )}
                    />
                    <Column
                      label={t('Fleets.Fleets')}
                      dataKey="fleetIds"
                      width={fixedColumnsWidth.fleets}
                      className={styles.tableCol}
                      cellRenderer={props => (
                        <CellMeasurer
                          cache={bulkEditCache}
                          columnIndex={0}
                          key={`cell-measurer-${props.dataKey}-${props.rowData.id}`}
                          parent={props.parent}
                          rowIndex={props.rowIndex}
                        >
                          <VehicleFleetsCell
                            rowIndex={props.rowIndex}
                            tableRef={tableRef}
                            fleets={props.rowData.fleets}
                            getCompanyName={getCompanyName}
                          />
                        </CellMeasurer>
                      )}
                    />
                    <Column
                      label={t('Vehicles.Form.IsEldVehicle')}
                      dataKey="eldVehicle"
                      width={fixedColumnsWidth.isEldVehicle}
                      className={styles.tableCol}
                      cellRenderer={props =>
                        props.rowData.eldVehicle ? t('Common.Yes') : t('Common.No')
                      }
                    />
                    <Column
                      label={t('Vehicles.View.Phone')}
                      dataKey="phone"
                      width={fixedColumnsWidth.phone}
                      className={styles.tableCol}
                    />
                    <Column
                      label={t('Vehicles.Form.Notes')}
                      dataKey="note"
                      width={getDynamicColWidth(width)}
                      className={styles.tableCol}
                      cellRenderer={({ cellData }) => (
                        <Typography.Paragraph
                          ellipsis={{ rows: 3, expandable: false, tooltip: cellData }}
                          className={styles.note}
                        >
                          {cellData}
                        </Typography.Paragraph>
                      )}
                    />
                    {showActions && (
                      <Column
                        label={t('Vehicles.Table.Actions')}
                        dataKey=""
                        style={{ overflow: 'visible' }}
                        width={fixedColumnsWidth.actions}
                        className={styles.tableCol}
                        cellRenderer={props => (
                          <VehicleActionsCell vehicle={props.rowData} onDelete={onDelete} />
                        )}
                      />
                    )}
                  </Table>
                </Spin>
              );
            }}
          </AutoSizer>
        </Layout.Content>
      </div>
    </>
  );
};
