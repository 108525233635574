import { ngAwsQsApi } from './ngAwsQsApi';
import { ngAwsQsEmbedApi } from './ngAwsQsEmbedApi';
import { ngDevicesGpioApi } from './ngDevicesGpioApi';
import { ngDeviceApi } from './ngDeviceApi';
import { ngTimezonesApi } from './ngTimezonesApi';
import { ngRegulatoryApi } from './ngRegulatoryApi';
import { ngAgreementApi } from './ngAgreementApi';
import { ngGpioConfigurationApi } from './ngGpioConfigurationApi';
import { ngAssetConfigurationApi } from './ngAssetConfigurationApi';
import { ngSpeedAssistConfigurationApi } from './ngSpeedAssistConfigurationApi';
import { ngConfigKeyApi } from './ngConfigKeyApi';
import { ngB2bIntegrationsApi } from './ngB2bIntegrationsApi';
import { ngSharedLinksApi } from './ngSharedLinksApi';
import { ngUserPrefConfigApi } from './ngUserPrefConfigApi';
import { ngDriverIdConfigurationApi } from './ngDriverIdConfigurationApi';

export const nextgenMiddleware = [
  ngAwsQsApi.middleware,
  ngAwsQsEmbedApi.middleware,
  ngDevicesGpioApi.middleware,
  ngDeviceApi.middleware,
  ngTimezonesApi.middleware,
  ngAgreementApi.middleware,
  ngGpioConfigurationApi.middleware,
  ngAssetConfigurationApi.middleware,
  ngDriverIdConfigurationApi.middleware,
  ngSpeedAssistConfigurationApi.middleware,
  ngConfigKeyApi.middleware,
  ngUserPrefConfigApi.middleware,
  ngB2bIntegrationsApi.middleware,
  ngRegulatoryApi.middleware,
  ngSharedLinksApi.middleware
];
