export function vehicleObjectToDBO(vehicle) {
  return {
    id: vehicle.id,
    name: vehicle.name,
    path: vehicle.path,
    version: vehicle.version,
    status: vehicle.status,
    company_id: vehicle.company?.id,
    vehicle_type_id: vehicle.type?.id,
    registration: vehicle.registration,
    registration_state: vehicle.registrationState,
    registration_country: vehicle.registrationCountry,
    registration_country_name: vehicle.registrationCountryName,
    registration_state_name: vehicle.registrationStateName,
    vin: vehicle.vin,
    engine_number: vehicle.engineNumber,
    make: vehicle.make,
    model: vehicle.model,
    note: vehicle.note,
    external_id: vehicle.externalId,
    external_reference: vehicle.externalReference,
    phone: vehicle.phone,
    tags: vehicle.tags,
    image_url: vehicle.imageUrl,
    default_driver: vehicle.defaultDriver,
    vehicle_model_id: vehicle.vehicleModelId,
    operational_vehicle_model_id: vehicle.operationalVehicleModelId,
    eld_vehicle: vehicle.eldVehicle,
    created_at: vehicle.createdAt,
    updated_at: vehicle.updatedAt
  };
}

export function vehicleDBOToObject(vehicle) {
  return {
    id: vehicle.id,
    name: vehicle.name,
    path: vehicle.path,
    version: vehicle.version,
    status: vehicle.status,
    companyId: vehicle.company_id,
    company: {
      id: vehicle.company_id
    },
    type: {
      id: vehicle.vehicle_type_id
    },
    registration: vehicle.registration,
    registration_state: vehicle.registration_state,
    registrationCountry: vehicle.registration_country,
    registrationCountryName: vehicle.registration_country_name,
    registrationState: vehicle.registration_state,
    registrationStateName: vehicle.registration_state_name,
    vin: vehicle.vin,
    engineNumber: vehicle.engine_number,
    make: vehicle.make,
    model: vehicle.model,
    note: vehicle.note,
    externalId: vehicle.external_id,
    externalReference: vehicle.external_reference,
    phone: vehicle.phone,
    tags: vehicle.tags,
    imageUrl: vehicle.image_url,
    defaultDriver: vehicle.default_driver,
    vehicleModelId: vehicle.vehicle_model_id,
    operationalVehicleModelId: vehicle.operational_vehicle_model_id,
    eldVehicle: vehicle.eld_vehicle,
    createdAt: vehicle.createdAt,
    updatedAt: vehicle.updatedAt
  };
}
