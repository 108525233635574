/* global google */
import React, { useCallback, useEffect, useState } from 'react';
import { Empty } from 'antd';
import { Button } from 'components/ant';
import { LeftOutlined, RightOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { velocityAltitudeBrushGraphHDData } from './velocityAltitudeBrushGraphHDData.js';
import { graphScrollbar } from './graphScrollbar.js';
import { useLocalization } from 'features/localization/localizationSlice.js';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay } from 'components/map/overlays';
import { useUserPreferences } from 'features/user/userPreferencesSlice.js';
import { useUser } from 'features/user/userSlice';

import styles from './VelocityAltitudeGraph.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants.js';

const ZOOM_FACTOR = 2;
const PAN_FACTOR = 0.5;
const MAX_ZOOM = 15 * 1000; // zoom limit of 15 seconds

export const VelocityAltitudeGraph = ({
  isLoading = false,
  trips,
  startDate,
  endDate,
  focusedEvent,
  selectedEvent,
  selectedReplay,
  onSetSelectedReplay,
  onMouseOver,
  onMouseMove,
  onMouseOut,
  onMapBoundsChanged,
  onEventClicked,
  size
}) => {
  const localization = useLocalization();
  const currentUser = useUser();
  const { timeZone } = currentUser;
  const { t } = useTranslation();
  const preferences = useUserPreferences();
  const [currentBounds, setCurrentBounds] = useState({
    startDate: startDate,
    endDate: endDate
  });
  const [tripsInBounds, setTripsInBounds] = useState(trips);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const maxBounds = {
    startDate: startDate,
    endDate: endDate
  };

  const handlePanLeft = () => {
    if (currentBounds.startDate >= maxBounds.startDate) {
      const width = currentBounds.endDate - currentBounds.startDate;
      const pan = width * PAN_FACTOR;

      let newStartDate = currentBounds.startDate - pan;
      if (newStartDate < maxBounds.startDate) newStartDate = maxBounds.startDate;

      const newEndDate = newStartDate + width;

      updateCurrentBounds({ startDate: newStartDate, endDate: newEndDate });
    }
  };

  const handlePanRight = () => {
    if (currentBounds.endDate <= maxBounds.endDate) {
      const width = currentBounds.endDate - currentBounds.startDate;
      const pan = width * PAN_FACTOR;

      let newEndDate = currentBounds.endDate + pan;
      if (newEndDate > maxBounds.endDate) newEndDate = maxBounds.endDate;

      const newStartDate = newEndDate - width;

      updateCurrentBounds({ startDate: newStartDate, endDate: newEndDate });
    }
  };

  const handleZoomReset = () => {
    updateCurrentBounds(maxBounds);
  };

  const handleZoomOut = () => {
    const width = (currentBounds.endDate - currentBounds.startDate) * ZOOM_FACTOR;
    const center = (currentBounds.endDate - currentBounds.startDate) / 2 + currentBounds.startDate;

    let newStartDate = center - width / 2;
    let newEndDate = center + width / 2;

    newStartDate = Math.max(newStartDate, maxBounds.startDate);
    newEndDate = Math.min(newEndDate, maxBounds.endDate);

    updateCurrentBounds({ startDate: newStartDate, endDate: newEndDate });
  };

  const handleZoomIn = () => {
    const width = (currentBounds.endDate - currentBounds.startDate) / ZOOM_FACTOR;
    const newStartDate = currentBounds.startDate + width / 2;
    let newEndDate = newStartDate + width;

    if (newEndDate - newStartDate < MAX_ZOOM) newEndDate = newStartDate + MAX_ZOOM;

    updateCurrentBounds({ startDate: newStartDate, endDate: newEndDate });
  };

  const updateCurrentBounds = newBounds => {
    setCurrentBounds(newBounds);

    const newShowScrollbar =
      maxBounds.startDate !== newBounds.startDate || maxBounds.endDate !== newBounds.endDate;
    setShowScrollbar(newShowScrollbar);

    if (trips && trips.length) {
      // Find which trips are in bounds and for each trip, filter to events that are in bounds
      let newTripsInBounds = [];
      trips.forEach(trip => {
        // Get replay and events in range and see if trip is still in view
        let filteredReplay = trip.replay.filter(
          gps => gps.At >= newBounds.startDate && gps.At <= newBounds.endDate
        );

        let filterdDeviceEvents = [];
        if (trip.events && trip.events.length) {
          filterdDeviceEvents = trip.events.filter(
            event => event.timeAt >= newBounds.startDate && event.timeAt <= newBounds.endDate
          );
        }

        filterdDeviceEvents = reorderArray(filterdDeviceEvents);

        if (filteredReplay?.length || filterdDeviceEvents?.length) {
          newTripsInBounds.push({
            id: trip.id,
            replay: filteredReplay,
            events: filterdDeviceEvents
          });
        }
      });

      setTripsInBounds(newTripsInBounds);

      if (newTripsInBounds.length) {
        // For HDData, new bounds includes all replay points and events
        const bounds = new google.maps.LatLngBounds();
        newTripsInBounds.forEach(trip => {
          if (trip.replay?.length) {
            trip.replay.forEach(point => {
              bounds.extend(new google.maps.LatLng(point.Lat, point.Lng));
            });
          }
        });

        onMapBoundsChanged(bounds);
      }
    }
  };

  const reorderArray = arr => {
    // Let's render POSITION first to ensure it doesn't block other event types.
    const typeToPrioritize = 'POSITION';
    return arr.sort((a, b) => {
      if (a.eventType === typeToPrioritize && b.eventType !== typeToPrioritize) {
        return -1; // a comes before b
      } else if (a.eventType !== typeToPrioritize && b.eventType === typeToPrioritize) {
        return 1; // b comes before a
      } else {
        return 0; // no change in order
      }
    });
  };

  const onMouseMoveWithDistance = useCallback(
    (data, distance) => {
      const showCalculatedDistand =
        preferences.calculateDistance || preferences.calculateDistance === undefined;
      if (showCalculatedDistand) {
        return onMouseMove && onMouseMove({ ...data, distance });
      } else {
        return onMouseMove && onMouseMove(data);
      }
    },
    [preferences, onMouseMove]
  );

  // If selectedEvent was just selected, make sure it is displayed on the graph
  useEffect(() => {
    if (selectedEvent) {
      const endDate = Math.max(selectedEvent.timeAt, currentBounds.endDate);
      const startDate = Math.min(selectedEvent.timeAt, currentBounds.startDate);

      if (startDate !== currentBounds.startDate || endDate !== currentBounds.endDate)
        updateCurrentBounds({ startDate, endDate });
    }
  }, [selectedEvent]);

  // If selectedReplay was just selected, make sure it is displayed on the graph
  useEffect(() => {
    if (selectedReplay) {
      const endDate = Math.max(selectedReplay.data.At, currentBounds.endDate);
      const startDate = Math.min(selectedReplay.data.At, currentBounds.startDate);

      if (startDate !== currentBounds.startDate || endDate !== currentBounds.endDate)
        updateCurrentBounds({ startDate, endDate });
    }
  }, [selectedReplay]);

  useEffect(() => {
    updateCurrentBounds(currentBounds);
  }, [trips]);

  useEffect(() => {
    updateCurrentBounds({ startDate, endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    // subtract off size of buttons header from height
    const graphSize = {
      width: size.width,
      height: size.height - (showScrollbar ? 50 : 34)
    };

    trips?.length &&
      velocityAltitudeBrushGraphHDData(
        '#vaAreaGraph',
        graphSize,
        tripsInBounds,
        trips,
        currentBounds.startDate,
        currentBounds.endDate,
        localization,
        timeZone,
        focusedEvent,
        selectedEvent,
        selectedReplay,
        onSetSelectedReplay,
        onMouseOver,
        onMouseMoveWithDistance,
        onMouseOut,
        onEventClicked,
        updateCurrentBounds,
        t
      );

    if (showScrollbar && !isScrolling) {
      graphScrollbar(
        '#vaGraphScrollbar',
        { width: size.width, height: 16 },
        maxBounds,
        currentBounds,
        updateCurrentBounds,
        setIsScrolling
      );
    }
  }, [
    trips,
    tripsInBounds,
    currentBounds,
    size,
    selectedReplay,
    focusedEvent,
    selectedEvent,
    localization
  ]);

  const maxWidth = maxBounds.endDate - maxBounds.startDate;
  const currentWidth = currentBounds.endDate - currentBounds.startDate;

  const hasNoData = !isLoading && !trips?.length;

  const disablePanLeft = hasNoData || currentBounds.startDate <= maxBounds.startDate;
  const disablePanRight = hasNoData || currentBounds.endDate >= maxBounds.endDate;
  const disableReset = hasNoData || currentWidth >= maxWidth;
  const disableZoomOut = hasNoData || disableReset;
  const disableZoomIn = hasNoData || currentWidth <= MAX_ZOOM;

  return (
    <div className={styles.graphContainer}>
      <div className={styles.controlsHeader}>
        <Button
          className={styles.panLeftButton}
          disabled={disablePanLeft}
          icon={<LeftOutlined className={styles.panLeftIcon} />}
          onClick={handlePanLeft}
          size="small"
          id={BUTTON_IDS.velocityGraphPanLeft}
        />
        <Button
          className={styles.zoomOutButton}
          disabled={disableZoomOut}
          icon={<MinusOutlined className={styles.zoomOutIcon} />}
          onClick={handleZoomOut}
          size="small"
          id={BUTTON_IDS.velocityGraphZoomOut}
        />
        <Button
          className={styles.resetButton}
          disabled={disableReset}
          onClick={handleZoomReset}
          size="small"
          id={BUTTON_IDS.velocityGraphZoomReset}
        >
          {t('Common.Reset')}
        </Button>
        <Button
          className={styles.zoomInButton}
          disabled={disableZoomIn}
          icon={<PlusOutlined className={styles.zoomInIcon} />}
          onClick={handleZoomIn}
          size="small"
          id={BUTTON_IDS.velocityGraphZoomIn}
        />
        <Button
          className={styles.panRightButton}
          disabled={disablePanRight}
          icon={<RightOutlined className={styles.panRightIcon} />}
          onClick={handlePanRight}
          size="small"
          id={BUTTON_IDS.velocityGraphPanRight}
        />
      </div>
      <div id="vaAreaGraph" style={{ height: showScrollbar ? 'calc(100% - 50px)' : '100%' }} />
      {showScrollbar && <div id="vaGraphScrollbar" className={styles.vaGraphScrollbar} />}
      <LoadingOverlay isLoading={isLoading} />
      {hasNoData && (
        <div className={styles.noDataOverlay}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Tracking.NoData')} />
        </div>
      )}
    </div>
  );
};
