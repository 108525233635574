/* global google */
import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { Tabs, Tab, FormLabel, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toLower, uniqBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import { Radio, ColorPicker } from 'antd';

import { prepareDataForMultiselect } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';
import { canHistoryGoBack } from 'utils/methods';

import {
  useCompanies,
  useCompanyGeofenceProviders,
  useCurrentCompany,
  useCompanyConfig,
  fetchCompanyConfig,
  useIsELDCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useIsCompanyKeyDifferent
} from 'features/company/companySlice';
import {
  extractVehicles,
  extractDevices,
  useFleets,
  addFleet,
  updateFleet,
  useIsFetchingFinished
} from 'features/fleets/fleetsSlice';
import { fetchGeofences, useGeofences } from 'features/geofences/geofencesSlice';
import { FeatureFlag, useCan, useCanEveryCompanyService } from 'features/permissions';
import services from 'features/permissions/services';
import entities from 'features/permissions/entities';
import { openToast } from 'features/toasts/toastsSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useUserKey } from 'features/user/userSlice';

import FormInput from 'components/form/form-input/FormInput';
import FormTitle from 'components/form/form-title/FormTitle';
import FormSelect from 'components/form/form-select/FormSelect';
import { Tooltip, Button } from 'components/ant';
import { VehiclesTableAddFleet } from './VehiclesTableAddFleet';
import { DevicesTableAddFleet } from './DevicesTableAddFleet';
import { cacheVehicles, cacheDevices, cacheGeofences } from './CellRenderers';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import Map, { MapMode } from 'components/map/Map';
import { Loading } from 'components/loading/Loading';

import { getFleetsByCompany, getGeofencesByCompany } from './APICalls';
import {
  MARKER_TYPE,
  MARKER_COLOR,
  MARKER_COLOR_TYPE,
  MARKER_SIZE
} from 'components/map/markers/constants';
import {
  validationSchema,
  postBodyShape,
  newFleet,
  GeofencesTypes,
  filterGeofenceTypes,
  PATHS
} from './constants';

import { GeofenceTable } from './GeofenceTable';

import styles from './Fleets.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const FleetsNew = ({ setToastMessage, action }) => {
  const path = window.location.pathname;
  const fleetId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const companies = useCompanies();
  const userKey = useUserKey();
  const history = useHistory();
  const flagNotEdit = true;
  const companyGeofences = useGeofences();
  const can = useCan();
  const geofenceProviders = useCompanyGeofenceProviders();
  const hasOnsiteTraffic = useCanEveryCompanyService([services.ONSITE_TRAFFIC]);
  const hasSmartJobHeadless = useCanEveryCompanyService([services.SMARTJOBS_HEADLESS]);
  const isELDUS = useCanEveryCompanyService([services.ELDUS]);
  const hasFleetsFetched = useIsFetchingFinished();
  const [isExtractingVehiclesAndDevices, setIsExtractingVehiclesAndDevices] = useState(false);
  const allFleets = useFleets();
  const allFleetNames = allFleets?.map(fleet => fleet?.name);
  const isELDCompany = useIsELDCompany();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [fleets, setFleets] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [devices, setDevices] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState(vehicles);
  const [filteredVehiclesIDs, setFilteredVehiclesIDs] = useState(filteredVehicles);
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [filteredDevicesIDs, setFilteredDevicesIDs] = useState(
    filteredDevices ? filteredDevices : []
  );
  const [markerType, setMarkerType] = useState(MARKER_TYPE.asset);
  const [markerColorType, setMarkerColorType] = useState(MARKER_COLOR_TYPE.status);
  const [markerColor, setMarkerColor] = useState(null);
  const [markerSize, setMarkerSize] = useState(MARKER_SIZE.small);
  const [checkedVehicles, setCheckedVehicles] = useState([]);
  const [checkedDevices, setCheckedDevices] = useState([]);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);
  const [tableRef, setTableRef] = useState(null);
  const [filteredFleetsForVehicles, setFilterFleetsForVehicles] = useState([]);
  const [filteredFleetsForDevices, setFilterFleetsForDevices] = useState([]);
  const [filteredVehicleTypes, setFilteredVehicleTypes] = useState([]);
  const [filteredDeviceTypes, setFilteredDeviceTypes] = useState([]);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [geofences, setGeofences] = useState([]);
  const [filteredGeofenceTypes, setFilteredGeofenceTypes] = useState([]);
  const [filteredGeofences, setFilteredGeofences] = useState([]);
  const [selectedGeofences, setSelectedGeofences] = useState([]);
  const [carrierData, setCarrierData] = useState([]);
  const [fleetData, setFleetData] = useState(newFleet);
  var carrierDataObjectOrArray = useCompanyConfig();

  const hasCompanyChanged = useIsCompanyKeyDifferent('fleets');
  useRedirectToMainFeaturePageOnCompanyChange('/settings/fleets');

  const handleFetchError = useCallback(() => {
    history.replace(PATHS.FLEETS_DEFAULT);
  }, [history]);

  useEffect(() => {
    if (selectedCompany && userKey) {
      dispatch(fetchCompanyConfig(selectedCompany, userKey, isELDUS));
    }
  }, [dispatch, selectedCompany, userKey]);

  useEffect(() => {
    const carriers = Array.isArray(carrierDataObjectOrArray)
      ? carrierDataObjectOrArray.filter(c => c.status === 'ENABLED')
      : [carrierDataObjectOrArray];

    let carrierInFleets = fleets.reduce((carrier, fleet) => {
      if (fleet.carrierId > 0) carrier.push(fleet.carrierId);
      return carrier;
    }, []);

    if (action === 'edit' && carrierInFleets.length > 0) {
      const fleetEdit = fleets.find(fleet => fleet.id === parseInt(fleetId, 10));
      if (fleetEdit) {
        carrierInFleets = fleets.reduce((carrier, fleet) => {
          if (fleet.carrierId !== fleetEdit.carrierId) carrier.push(fleet.carrierId);
          return carrier;
        }, []);
      }
    }
    // exclude carriers that have already been associated with the fleet (carrier and fleet are one to one relationship)
    setCarrierData(carriers.filter(c => !carrierInFleets.includes(c?.id)));
  }, [action, fleetId, fleets, carrierDataObjectOrArray]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    let geoTypes = filterGeofenceTypes({
      hasManagedAccess: geofenceProviders?.length > 0,
      hasOnsiteTraffic,
      hasSmartJobHeadless
    });
    setFilteredGeofenceTypes(
      prepareDataForMultiselect(
        geoTypes.map(k => {
          return {
            id: k.toUpperCase(),
            name: GeofencesTypes[k]
          };
        }),
        t('Common.All Geofence Types'),
        null
      )
    );
  }, [geofenceProviders, hasOnsiteTraffic, hasSmartJobHeadless]);

  useEffect(() => {
    if (action === 'add') {
      handleCompanyChange(currentCompany?.id);
    }
  }, [currentCompany, action]);

  useEffect(() => {
    const fleetsForFilter = fleets
      .filter(fleet => fleet.id)
      .map(fleet => {
        const fleetCompany = companies.find(company => company.id === fleet.company.id);
        return { ...fleet, name: `${fleet?.name} (${fleetCompany?.name})` };
      });
    fleetsForFilter.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleetsForVehicles(
      prepareDataForMultiselect(fleetsForFilter, t('Common.AllFleets'), null)
    );
    setFilterFleetsForDevices(
      prepareDataForMultiselect(fleetsForFilter, t('Common.AllFleets'), null)
    );
  }, [fleets, t]);

  useEffect(() => {
    const vehicleTypes = uniqBy(
      vehicles.map(vehicle => vehicle.type),
      'id'
    ).filter(item => item !== undefined);
    setFilteredVehicleTypes(
      prepareDataForMultiselect(vehicleTypes, t('Fleets.Form.AllVehicleTypes'), null)
    );
  }, [vehicles, t]);

  useEffect(() => {
    const deviceTypes = uniqBy(
      devices.map(device => device.type),
      'id'
    ).filter(item => item !== undefined);
    setFilteredDeviceTypes(
      prepareDataForMultiselect(deviceTypes, t('Fleets.Form.AllDeviceTypes'), null)
    );
  }, [devices, t]);

  useEffect(() => {
    setFilteredVehicles(
      vehicles.filter(vehicle => {
        if (!vehicle.id) {
          return false;
        }
        let validVehicle = true;
        // Filter by search field
        if (debouncedSearchText) {
          validVehicle =
            validVehicle &&
            [vehicle.name, vehicle.registration].some(
              value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1
            );
        }
        // Filter by fleets
        const checkedFleetIds = filteredFleetsForVehicles
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));
        const vehicleFleetsIds = vehicle.fleets
          .filter(fleet => fleet && fleet.id)
          .map(fleet => fleet.id);

        if (!(checkedFleetIds.indexOf(0) > -1)) {
          validVehicle =
            validVehicle &&
            checkedFleetIds.filter(fleetId => vehicleFleetsIds.includes(fleetId)).length > 0;
        }

        // Filter by vehicle type
        const checkedVehicleTypeIds = filteredVehicleTypes
          .filter(type => type.checked)
          .map(type => parseInt(type.id, 10));
        if (!(checkedVehicleTypeIds.indexOf(0) > -1)) {
          validVehicle =
            validVehicle && vehicle.type && checkedVehicleTypeIds.includes(vehicle.type.id);
        }

        return validVehicle;
      })
    );
  }, [
    vehicles,
    filterText,
    filteredFleetsForVehicles,
    filteredVehicleTypes,
    debouncedSearchText,
    action
  ]);

  useEffect(() => {
    setFilteredVehiclesIDs(filteredVehicles.map(vehicle => vehicle.id));
  }, [filteredVehicles]);

  useEffect(() => {
    setFilteredDevices(
      devices.filter(device => {
        if (!device.id) {
          return false;
        }
        let validDevice = true;
        // Filter by search field
        if (debouncedSearchText) {
          validDevice =
            validDevice &&
            [device.name, device.imei, device.serialNumber].some(
              value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1
            );
        }
        // Filter by fleets
        const checkedFleetIds = filteredFleetsForDevices
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));

        const deviceFleetIds = device.fleetInfo.filter(fleet => fleet.id).map(fleet => fleet.id);
        if (!(checkedFleetIds.indexOf(0) > -1)) {
          validDevice =
            validDevice &&
            !!checkedFleetIds.filter(fleetId => deviceFleetIds.includes(fleetId)).length;
        }
        // Filter by vehicle type
        const checkedDeviceTypeIds = filteredDeviceTypes
          .filter(type => type.checked)
          .map(type => parseInt(type.id, 10));
        if (!(checkedDeviceTypeIds.indexOf(0) > -1)) {
          validDevice = validDevice && device.type && checkedDeviceTypeIds.includes(device.type.id);
        }

        return validDevice;
      })
    );
  }, [
    devices,
    filterText,
    filteredFleetsForDevices,
    filteredDeviceTypes,
    debouncedSearchText,
    action
  ]);

  useEffect(() => {
    setFilteredDevicesIDs(filteredDevices.map(device => device.id));
  }, [filteredDevices]);

  useEffect(() => {
    if (tableRef) {
      cacheDevices.clearAll();
      cacheVehicles.clearAll();
      cacheGeofences.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredVehicles, filteredDevices, filteredGeofences, tableRef]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        `${
          fleetData?.name
            ? `${t('Common.EllipsisButton.Edit')} ${fleetData?.name || ''}`
            : t('Fleets.AddNewFleet')
        }`
      )
    );
  }, [dispatch, fleetData, t]);

  useEffect(() => {
    if (action === 'edit') {
      const fleetEdit = allFleets.find(fleet => fleet.id === parseInt(fleetId, 10));
      if (!fleetEdit) {
        return;
      }

      const fleetTempEdit = {
        name: fleetEdit.name,
        companyId: Number(fleetEdit.company?.id),
        description: fleetEdit.description,
        iconType: MARKER_TYPE[fleetEdit.iconType] || MARKER_TYPE.asset,
        iconSize: MARKER_SIZE[fleetEdit.iconSize] || MARKER_SIZE.small,
        colourType: !fleetEdit.colour ? MARKER_COLOR_TYPE.status : MARKER_COLOR_TYPE.custom,
        colour: fleetEdit.colour,
        carrierId: fleetEdit.carrierId,
        vehicles: [],
        devices: []
      };

      // console.debug('FleetEdit', { fleetEdit, fleetTempEdit });

      const checkedEditVehicles = fleetEdit?.vehicles
        .map(vehicle => vehicle.id)
        .filter(vehicle => vehicle);

      const vehiclesLength = fleetEdit.vehicles.length;
      let checkedEditDevices;
      if (
        fleetEdit.vehicles &&
        fleetEdit.vehicles[vehiclesLength - 1] &&
        fleetEdit.vehicles[vehiclesLength - 1].devices
      ) {
        checkedEditDevices = fleetEdit.vehicles[vehiclesLength - 1].devices.map(
          device => device.id
        );
      }

      if (fleetEdit.company && fleetEdit.company.id) {
        handleCompanyChange(fleetEdit.company.id);
      }

      setMarkerType(fleetTempEdit.iconType);
      setMarkerSize(fleetTempEdit.iconSize);
      setMarkerColorType(fleetTempEdit.colourType);
      setMarkerColor(fleetTempEdit.colour);
      setCheckedVehicles(checkedEditVehicles);
      setCheckedDevices(checkedEditDevices);
      setFleetData(fleetTempEdit);
    }
  }, [action, allFleets, fleetId, userKey]);

  useEffect(() => {
    setGeofences(companyGeofences);
  }, [companyGeofences]);

  useEffect(() => {
    let geos = geofences;
    const conditions = [];
    if (debouncedSearchText && debouncedSearchText !== '') {
      const searchText = debouncedSearchText.toLowerCase();
      conditions.push(g => g.name?.toLowerCase().includes(searchText));
    }
    if (!filteredGeofenceTypes.some(t => t.id === 0 && t.checked)) {
      const checkedGeoTypes = filteredGeofenceTypes.filter(g => g.checked).map(g => g.id);
      if (checkedGeoTypes.includes('MANAGED') && geofenceProviders) {
        checkedGeoTypes.push(...geofenceProviders);
      }
      conditions.push(g => checkedGeoTypes.includes(g.type));
    }
    geos = (geos || []).filter(g => conditions.every(c => c(g)));
    setFilteredGeofences(geos);
    cacheGeofences.clearAll();
  }, [geofences, debouncedSearchText, filteredGeofenceTypes, geofenceProviders]);

  useEffect(() => {
    setSelectedGeofences(
      geofences?.filter(g => g.fleets?.some(f => f.id === Number(fleetId))).map(g => g.id)
    );
  }, [geofences, fleetId]);

  const handleCompanyChange = id => {
    const embed = 'vehicles,devices';
    if (id != null) setSelectedCompany({ id: id });
    setIsExtractingVehiclesAndDevices(true);
    getFleetsByCompany(id, userKey, 'DOWN', embed)
      .then(newFleets => {
        setFleets(newFleets);
        const newVehicles = extractVehicles(newFleets);
        const newDevices = extractDevices(newFleets);
        setVehicles(newVehicles.filter(vehicle => vehicle.id));
        setDevices(newDevices.filter(device => !device.vehicle));
        setIsExtractingVehiclesAndDevices(false);
      })
      .catch(err => {
        console.error(err);
        setIsExtractingVehiclesAndDevices(false);
      });

    getGeofencesByCompany(id, userKey, { geofenceProviders }).then(geofences => {
      setGeofences(geofences);
    });

    if (flagNotEdit) {
      setCheckedVehicles([]);
      setCheckedDevices([]);
      setSelectedGeofences([]);
    }
  };

  const handleMarkerTypeChange = value => {
    setMarkerType(value);
    setIsFormDirty(true);
  };

  const handleMarkerColorTypeChange = value => {
    if (value === MARKER_COLOR_TYPE.status) {
      setMarkerColor(null);
    } else if (value === MARKER_COLOR_TYPE.custom) {
      setMarkerColor(markerColor || Object.keys(MARKER_COLOR)[0]);
    }
    setMarkerColorType(value);
    setIsFormDirty(true);
  };

  const handleMarkerColorChange = value => {
    setMarkerColor(value);
    setIsFormDirty(true);
  };

  const handleMarkerSizeChange = value => {
    setMarkerSize(value);
    setIsFormDirty(true);
  };

  const handleCheckedDevicesChange = useCallback(devices => {
    setCheckedDevices(devices);
    setIsFormDirty(true);
  }, []);

  const handleCheckedVehiclesChange = useCallback(vehicles => {
    setCheckedVehicles(vehicles);
    setIsFormDirty(true);
  }, []);

  const handleCheckedGeofences = useCallback(geofences => {
    setSelectedGeofences(geofences);
    setIsFormDirty(true);
  }, []);

  const onCompanyChange = (companyId, setFieldValue) => {
    setFieldValue('companyId', Number(companyId));
    setFieldValue('carrierId', Number(-1));
    handleCompanyChange(companyId);
  };

  const onCarrierChange = (carrierId, setFieldValue) => {
    if (carrierId === '' || carrierId == null) setFieldValue('carrierId', Number(-1));
    else {
      setFieldValue('carrierId', Number(carrierId));
    }
  };

  const handleSubmit = (values, actions) => {
    let postBody = {
      ...postBodyShape,
      name: values.name,
      company: { id: values.companyId },
      description: values.description,
      carrierId: values.carrierId,
      iconType: markerType,
      ...(markerType === MARKER_TYPE.arrow ? { iconSize: markerSize } : {}),
      colour: markerColor,
      vehicles: checkedVehicles.map(vehicle => {
        return { id: vehicle };
      }),
      devices:
        checkedDevices &&
        checkedDevices.map(device => {
          return { id: device };
        })
    };

    const prevGeofences =
      geofences?.filter(g => g.fleets?.some(f => f.id === Number(fleetId))).map(g => g.id) || [];
    let i = 0;
    while (i < selectedGeofences.length) {
      const item = selectedGeofences[i];
      const pi = prevGeofences.indexOf(item);
      if (pi >= 0) {
        prevGeofences.splice(pi, 1);
        selectedGeofences.splice(i, 1);
        continue;
      }
      i++;
    }
    postBody.geofenceOps = selectedGeofences
      .map(g => {
        return {
          s: 'A',
          id: g
        };
      })
      .concat(
        prevGeofences.map(g => {
          return {
            s: 'D',
            id: g
          };
        })
      );

    if (action === 'add') {
      dispatch(addFleet(postBody, userKey)).then(([res, err]) => {
        if (res) {
          setPromptModalWhenLeaving(false);
          actions.resetForm();
          actions.setSubmitting(false);
          setToastMessage(
            i18next.t('Fleets.Notifications.FleetAddedSuccess', {
              name: postBody.name
            })
          );
          dispatch(fetchGeofences({ geofenceProviders }));
          canHistoryGoBack(history, '/settings/fleets');
        } else {
          setToastMessage(
            `${i18next.t('Fleets.Notifications.FleetAddedError', {
              name: postBody.name
            })}: ${err}`,
            ToastType.Error
          );
          actions.setSubmitting(false);
        }
      });
    } else {
      updateFleet(fleetId, postBody, userKey, dispatch).then(([res, err]) => {
        if (res) {
          setPromptModalWhenLeaving(false);
          actions.resetForm();
          actions.setSubmitting(false);
          setToastMessage(
            i18next.t('Fleets.Notifications.FleetUpdatedSuccess', {
              name: postBody.name
            })
          );
          dispatch(fetchGeofences({ geofenceProviders }));
          canHistoryGoBack(history, '/settings/fleets');
        } else {
          setToastMessage(
            `${i18next.t('Fleets.Notifications.FleetUpdatedError', {
              name: postBody.name
            })}: ${err}`,
            ToastType.Error
          );
          actions.setSubmitting(false);
        }
      });
    }
  };

  const isCompanySelectorDisabled =
    checkedVehicles?.length || checkedDevices?.length || selectedGeofences?.length;

  useEffect(() => {
    if (action === 'edit') {
      const parsedId = parseInt(fleetId);
      if (
        parsedId <= 0 ||
        isNaN(parsedId) ||
        (hasFleetsFetched && !allFleets.some(f => f.id === parsedId))
      ) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Common.Invalid Request ID')
          })
        );
        handleFetchError();
      }
    }
  }, [action, fleetId, hasFleetsFetched, allFleets, dispatch]);

  // Declarations for map props, to prevent unnecessary re-renders
  const devicesMapArray = [
    {
      id: 1,
      deviceStats: {
        gps: { Lat: -32.504353567034215, Lng: 136.52745474322623, Dir: 225 },
        ignition: 'ON'
      },
      vehicle: { id: 1 },
      icon: '',
      markerType: markerType,
      markerColor: markerColorType === MARKER_COLOR_TYPE.status ? undefined : markerColor,
      markerSize: markerSize
    },
    {
      id: 2,
      deviceStats: {
        gps: { Lat: -32.504353567034215, Lng: 137.52745474322623, Dir: 0 },
        ignition: 'OFF'
      },
      vehicle: { id: 2 },
      icon: 'tn-i-crane',
      markerType: markerType,
      markerColor: markerColorType === MARKER_COLOR_TYPE.status ? undefined : markerColor,
      markerSize: markerSize
    },
    {
      id: 3,
      deviceStats: {
        gps: { Lat: -32.504353567034215, Lng: 138.52745474322623, Dir: 45 },
        ignition: 'ON',
        lastEventAt: moment(Date.now()).subtract(7, 'h')
      },
      vehicle: { id: 3 },
      icon: 'tn-i-asset',
      markerType: markerType,
      markerColor: markerColorType === MARKER_COLOR_TYPE.status ? undefined : markerColor,
      markerSize: markerSize
    },
    {
      id: 4,
      deviceStats: {
        gps: { Lat: -32.504353567034215, Lng: 139.52745474322623, Dir: 180 },
        ignition: 'OFF'
      },
      icon: '',
      markerType: markerType,
      markerColor: markerColorType === MARKER_COLOR_TYPE.status ? undefined : markerColor,
      markerSize: markerSize
    }
  ];

  const mapOptions = {
    mapTypeId: google.maps.MapTypeId.TERRAIN,
    zoomControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false
  };

  const containerElement = <div style={{ height: `100%`, width: `100%` }} />;
  const mapElement = <div style={{ height: `100%`, width: `100%` }} />;
  //END for declarations for map props

  if (!hasFleetsFetched) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema(allFleetNames, fleetData)}
        initialValues={{
          ...fleetData,
          ...(action === 'add' && { companyId: currentCompany.id })
        }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
          <>
            <EditRouteGuard
              when={dirty && promptModalWhenLeaving && !hasCompanyChanged}
              navigate={history.push}
            />
            <Form className={styles.fleetForm}>
              <div className={styles.formContainer}>
                <FormTitle title={t('Fleets.Form.FleetInformation')} underlined />
                <div
                  style={{
                    display: 'flex',
                    flex: '1 0 0',
                    justifyContent: 'space-between',
                    padding: '16px 0 0 0'
                  }}
                >
                  <div style={{ width: '100%', flex: '1', padding: '0 16px 0 0' }}>
                    <FormInput
                      name="name"
                      label={t('Common.Name')}
                      placeholder={t('Fleets.Form.TypeInFleetName')}
                      isRequired
                    />
                    <Tooltip
                      content={
                        isCompanySelectorDisabled
                          ? t('Fleets.Form.DisabledCompanySelectorTooltip')
                          : ''
                      }
                      target={
                        <FormSelect
                          name="companyId"
                          label={t('Common.Company')}
                          placeholder=""
                          values={companies.map(company => ({
                            label: company.name,
                            value: company.id
                          }))}
                          onChange={id => onCompanyChange(id, setFieldValue)}
                          isRequired
                          isDisabled={isCompanySelectorDisabled}
                        />
                      }
                    />
                    {isELDCompany && (
                      <FormSelect
                        name="carrierId"
                        label={t('CompanyConfig.ELDCarrier.CarrierName')}
                        placeholder={t('Fleets.Form.CarrierPlaceholder')}
                        values={
                          Array.isArray(carrierData)
                            ? carrierData?.map(carrier => ({
                                label:
                                  carrier.name +
                                  ' | DOT: #' +
                                  (carrier.officialIdentifer == null
                                    ? ''
                                    : carrier.officialIdentifer),
                                value: carrier.id
                              }))
                            : []
                        }
                        onChange={id => onCarrierChange(id, setFieldValue)}
                      />
                    )}
                  </div>
                  <div style={{ width: '100%', flex: '1', padding: '0 0 0 16px' }}>
                    <FormInput
                      name="description"
                      label={t('Common.Description')}
                      rows="6"
                      className="form-textarea"
                      placeholder={t('Fleets.Form.TypeInTheDescription')}
                      as="textarea"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flex: '1 0 0',
                    justifyContent: 'space-between'
                  }}
                >
                  <div
                    style={{
                      paddingBottom: '1rem'
                    }}
                  >
                    <Field name="iconType">
                      {({ field }) => (
                        <div style={{ marginBottom: '1rem' }}>
                          <FormLabel className="fleet-form-label">
                            {t('Fleets.MarkerType')}
                          </FormLabel>
                          <div style={{ display: 'flex' }}>
                            <Radio.Group
                              options={[
                                { label: t('Fleets.Form.Asset'), value: MARKER_TYPE.asset },
                                { label: t('Fleets.Form.Arrow'), value: MARKER_TYPE.arrow }
                              ]}
                              value={markerType}
                              onChange={e => handleMarkerTypeChange(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </Field>

                    <Field name="colourType">
                      {({ field }) => (
                        <div>
                          <FormLabel className="fleet-form-label">
                            {t('Fleets.MarkerColor')}
                          </FormLabel>
                          <div style={{ display: 'flex' }}>
                            <Radio.Group
                              options={[
                                { label: t('Fleets.Status'), value: MARKER_COLOR_TYPE.status },
                                { label: t('Fleets.Form.Custom'), value: MARKER_COLOR_TYPE.custom }
                              ]}
                              value={markerColorType}
                              onChange={e => handleMarkerColorTypeChange(e.target.value)}
                            />
                          </div>
                        </div>
                      )}
                    </Field>

                    {markerColorType === MARKER_COLOR_TYPE.custom && (
                      <ColorPicker
                        className={styles.colorPicker}
                        disabledAlpha
                        value={markerColor}
                        presets={[
                          {
                            label: t('Fleets.Form.PrimaryColor'),
                            colors: [
                              '#0FBF26',
                              '#F02631',
                              '#FFA100',
                              '#E5C306',
                              '#0D79DE',
                              '#914FCF',
                              '#9FA9B7',
                              '#709A31',
                              '#003A8C',
                              '#1CAB7C'
                            ]
                          }
                        ]}
                        onChangeComplete={e => {
                          handleMarkerColorChange('#' + e.toHex());
                        }}
                      />
                    )}

                    {markerType === MARKER_TYPE.arrow && (
                      <Field name="iconSize">
                        {({ field }) => (
                          <div style={{ marginTop: '20px' }}>
                            <FormLabel className="fleet-form-label">
                              {t('Fleets.MarkerSize')}
                            </FormLabel>
                            <div style={{ display: 'flex' }}>
                              <Radio.Group
                                options={[
                                  { label: t('Fleets.Form.Small'), value: MARKER_SIZE.small },
                                  { label: t('Fleets.Form.Large'), value: MARKER_SIZE.large }
                                ]}
                                value={markerSize}
                                onChange={e => handleMarkerSizeChange(e.target.value)}
                              />
                            </div>
                          </div>
                        )}
                      </Field>
                    )}
                  </div>

                  <div style={{ width: '50%', padding: '0 0 0 16px' }}>
                    <Map
                      mode={MapMode.Devices}
                      devices={devicesMapArray}
                      mapOptions={mapOptions}
                      containerElement={containerElement}
                      mapElement={mapElement}
                    />
                  </div>
                </div>
              </div>

              <Tabs defaultActiveKey="vehicles" onClick={() => setFilterText('')}>
                <Tab eventKey="vehicles" title={t('Vehicles.Vehicles')}>
                  <div style={{ flex: '1 0 0', height: 'calc(100vh - 560px)' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#f7f8f9',
                        padding: '10px',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: '#dadee3',
                        position: 'relative'
                      }}
                    >
                      <AntSearchbar value={filterText} onFilter={value => setFilterText(value)} />
                      <AntMultiselect
                        title={
                          filteredFleetsForVehicles?.some(value => !value.checked)
                            ? t('Common.Fleets')
                            : t('Common.AllFleets')
                        }
                        data={filteredFleetsForVehicles}
                        onFilter={v => setFilterFleetsForVehicles(v)}
                      />

                      <AntMultiselect
                        title={
                          filteredVehicleTypes?.some(value => !value.checked)
                            ? t('VehicleTypes.VehicleTypesTitle')
                            : t('Fleets.Form.AllVehicleTypes')
                        }
                        data={filteredVehicleTypes}
                        onFilter={v => setFilteredVehicleTypes(v)}
                      />
                      <div
                        style={{
                          fontWeight: '500',
                          color: '#9a9a9a',
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 0,
                          marginLeft: 'auto',
                          width: 'initial'
                        }}
                      >
                        <FormLabel style={{ justifyContent: 'flex-end' }}>
                          {filteredVehicles ? filteredVehicles.length : 0}
                          {` ${t('Fleets.Form.of')} `}
                          {vehicles && vehicles.length > 0 ? vehicles.length : '0'}
                          {filteredVehicles.length === 1
                            ? ` ${t('Vehicles.Vehicle').toLowerCase()}`
                            : ` ${t('Vehicles.Vehicles').toLowerCase()}`}
                        </FormLabel>
                        <FormLabel
                          style={{ margin: '0', display: 'flex', justifyContent: 'flex-end' }}
                        >
                          {checkedVehicles && checkedVehicles.length > 0
                            ? `${checkedVehicles.length} ${t('Fleets.Form.Selected').toLowerCase()}`
                            : `0 ${t('Fleets.Form.Selected').toLowerCase()}`}
                        </FormLabel>
                      </div>
                    </div>
                    <VehiclesTableAddFleet
                      vehicles={filteredVehicles}
                      vehiclesIDs={filteredVehiclesIDs}
                      companies={companies}
                      isLoading={isExtractingVehiclesAndDevices}
                      checkedItems={checkedVehicles}
                      setCheckedItems={handleCheckedVehiclesChange}
                      setTableRef={setTableRef}
                    />
                  </div>
                </Tab>
                <Tab eventKey="devices" title={t('Devices.Devices')}>
                  <div style={{ flex: '1 0 0', height: 'calc(100vh - 560px)' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#f7f8f9',
                        padding: '10px',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: '#dadee3'
                      }}
                    >
                      <AntSearchbar value={filterText} onFilter={value => setFilterText(value)} />
                      <AntMultiselect
                        title={
                          filteredFleetsForDevices?.some(value => !value.checked)
                            ? t('Common.Fleets')
                            : t('Common.AllFleets')
                        }
                        data={filteredFleetsForDevices}
                        onFilter={v => setFilterFleetsForDevices(v)}
                      />

                      <AntMultiselect
                        title={
                          filteredDeviceTypes?.some(value => !value.checked)
                            ? t('Devices.DeviceTypes')
                            : t('Fleets.Form.AllDeviceTypes')
                        }
                        data={filteredDeviceTypes}
                        onFilter={v => setFilteredDeviceTypes(v)}
                      />

                      <div
                        style={{
                          fontWeight: '500',
                          color: '#9a9a9a',
                          display: 'flex',
                          flexDirection: 'column',
                          marginBottom: 0,
                          marginLeft: 'auto',
                          width: 'initial'
                        }}
                      >
                        <FormLabel style={{ justifyContent: 'flex-end' }}>
                          {filteredDevices ? filteredDevices.length : 0}
                          {` ${t('Fleets.Form.of')} `}
                          {devices && devices.length > 0 ? devices.length : '0'}
                          {` ${t('Devices.Devices').toLowerCase()}`}
                        </FormLabel>
                        <FormLabel
                          style={{ margin: '0', display: 'flex', justifyContent: 'flex-end' }}
                        >
                          {checkedDevices && checkedDevices.length > 0
                            ? `${checkedDevices.length} ${t('Fleets.Form.Selected').toLowerCase()}`
                            : `0 ${t('Fleets.Form.Selected').toLowerCase()}`}
                        </FormLabel>
                      </div>
                    </div>
                    <DevicesTableAddFleet
                      devices={filteredDevices}
                      devicesIDs={filteredDevicesIDs}
                      fleets={fleets}
                      companies={companies}
                      isLoading={isExtractingVehiclesAndDevices}
                      checkedItems={checkedDevices}
                      setCheckedItems={handleCheckedDevicesChange}
                      setTableRef={setTableRef}
                    />
                  </div>
                </Tab>
                {can({
                  oneOfEntities: [entities.FLEET_CREATE, entities.FLEET_UPDATE],
                  everyEntity: [entities.GEOFENCE_UPDATE, entities.GEOFENCE_VIEW]
                }) && (
                  <Tab eventKey="geofences" title={t('Common.Geofences')}>
                    <div style={{ flex: '1 0 0', height: 'calc(100vh - 560px)' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: '#f7f8f9',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: '#dadee3'
                        }}
                      >
                        <AntSearchbar value={filterText} onFilter={value => setFilterText(value)} />
                        <AntMultiselect
                          title={
                            filteredGeofenceTypes?.some(value => !value.checked)
                              ? t('Common.Geofence Types')
                              : t('Common.All Geofence Types')
                          }
                          data={filteredGeofenceTypes}
                          onFilter={v => setFilteredGeofenceTypes(v)}
                        />

                        <div
                          style={{
                            fontWeight: '500',
                            color: '#9a9a9a',
                            display: 'flex',
                            flexDirection: 'column',
                            margin: 0,
                            marginLeft: 'auto',
                            width: 'initial'
                          }}
                        >
                          <FormLabel style={{ justifyContent: 'flex-end' }}>
                            {(filteredGeofences?.length || 0) +
                              ' ' +
                              t('Fleets.Form.of') +
                              ' ' +
                              (geofences?.length || 0) +
                              ' ' +
                              t('Common.Geofences').toLowerCase()}
                          </FormLabel>
                          <FormLabel
                            style={{ margin: '0', display: 'flex', justifyContent: 'flex-end' }}
                          >
                            {selectedGeofences?.length > 0
                              ? `${selectedGeofences.length} ${t(
                                  'Fleets.Form.Selected'
                                ).toLowerCase()}`
                              : `0 ${t('Fleets.Form.Selected').toLowerCase()}`}
                          </FormLabel>
                        </div>
                      </div>
                      <GeofenceTable
                        geofences={filteredGeofences}
                        checkedItems={selectedGeofences}
                        setCheckedItems={handleCheckedGeofences}
                        setTableRef={setTableRef}
                      />
                    </div>
                  </Tab>
                )}
              </Tabs>
              <div className={styles.formFooter}>
                <Button
                  size="large"
                  type="primary"
                  disabled={(!dirty && !isFormDirty) || !isValid || isSubmitting}
                  htmlType="submit"
                  id={BUTTON_IDS.fleetFormSave}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button size="large" id={BUTTON_IDS.fleetFormCancel} onClick={history.goBack}>
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
export default FleetsNew;
