import { set, isObject, pickBy, get, merge, some } from 'lodash';
import { UNITS } from 'features/localization/localization';
import { FeatureFlag } from 'features/permissions';
import { format } from 'utils/dates';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { capitalize } from 'utils/strings';

export const TABS = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  BULK_EDIT: 'BULK_EDIT'
};

export const DUTY_TYPE = {
  heavy: 'heavy',
  medium: 'medium',
  light: 'light'
};

const lowerCaseFirstLetter = string => {
  return string.charAt(0).toLocaleLowerCase() + string.slice(1);
};

export const Form_Field_Type = {
  InputNumber: 'InputNumber',
  Select: 'Select',
  Switch: 'Switch'
};

export const getDirtyCheckConfirmProps = ({ t }) => [
  t('Common.Modal.SureTitle'),
  t('CompanyConfig.IQCamera.Validation.SureQuestionDiscardChanges'),
  t('RouteGuard.SaveAndLeavePage'),
  t('RouteGuard.StayOnPage')
];

export const getSubmissionConfirmProps = ({ t, tab }) => [
  t(`CompanyConfig.IQCamera.${tab}.SubmissionConfirm.SureTitle`),
  t(`CompanyConfig.IQCamera.${tab}.SubmissionConfirm.SureQuestion`),
  t('Common.Modal.OK'),
  t('Common.Modal.Cancel')
];

const mphFormField = {
  getUnit: localization => localization.formats.speed.unit_per_hour,
  getFieldvalue: ({
    value_in_mile,
    localization,
    additionalConvertor = localizedValue => localizedValue
  }) => {
    if (!localization) {
      return value_in_mile;
    }
    const localizedValue = isNaN(Number(value_in_mile))
      ? 0
      : localization.convertSpeedWithUnit(
          Number(value_in_mile),
          localization?.formats?.speed?.unit || UNITS.KM,
          UNITS.MILE,
          0
        );
    return additionalConvertor(isNaN(Number(localizedValue)) ? 0 : Number(localizedValue));
  },
  getFieldLabel: ({ value_in_mile, localization }) => {
    if (!localization) {
      return `${value_in_mile} mph`;
    }

    const locale_unit = localization?.formats?.speed?.unit || UNITS.KM;
    let localizedValue = isNaN(Number(value_in_mile))
      ? 0
      : localization.convertSpeedWithUnit(Number(value_in_mile), locale_unit, UNITS.MILE, 2);

    localizedValue = isNaN(Number(localizedValue)) ? 0 : Number(localizedValue);

    return locale_unit === UNITS.MILE
      ? `${value_in_mile} mph`
      : `${Math.trunc(localizedValue * 10) / 10} ${
          localization.formats.speed.unit_per_hour
        } (${value_in_mile} mph)`;
  },
  getRules: ({ min_in_mile, max_in_mile }) => [
    { required: true },
    { pattern: /^\d+$/ },
    localization => ({
      min: Number(
        localization.convertSpeedWithUnit(
          min_in_mile,
          localization?.formats?.speed?.unit || UNITS.KM,
          UNITS.MILE,
          0
        )
      ),
      type: 'number'
    }),
    localization => ({
      max: Number(
        localization.convertSpeedWithUnit(
          max_in_mile,
          localization?.formats?.speed?.unit || UNITS.KM,
          UNITS.MILE,
          0
        )
      ),
      type: 'number'
    })
  ],
  getHelp: ({ min_in_mile, max_in_mile }) => ({
    getText: (t, localization) => {
      const locale_unit = localization?.formats?.speed?.unit || UNITS.KM;
      return t('CompanyConfig.IQCamera.Validation.range', {
        from: localization.convertSpeedWithUnit(min_in_mile, locale_unit, UNITS.MILE, 0),
        to: localization.convertSpeedWithUnit(max_in_mile, locale_unit, UNITS.MILE, 0)
      });
    }
  }),
  toPayloadValue(localization, localizedValue) {
    const payloadValue = isNaN(Number(localizedValue))
      ? 0
      : localization.convertSpeedWithUnit(
          Number(localizedValue),
          UNITS.MILE,
          localization?.formats?.speed?.unit || UNITS.KM,
          0
        );
    return isNaN(Number(payloadValue)) ? 0 : Number(payloadValue);
  }
};

const gForceFormField = {
  getFieldvalue: value => {
    const GRAVITY = 9.80665;
    return isNaN(Number(value)) ? 0 : Number(value) / GRAVITY;
  },
  getRules: ({ min_in_g_force, max_in_g_force }) => [
    { required: true },
    { min: min_in_g_force, type: 'number' },
    { max: max_in_g_force, type: 'number' }
  ],
  getHelp: ({ min_in_g_force, max_in_g_force }) => ({
    getText: t =>
      t('CompanyConfig.IQCamera.Validation.range', {
        from: min_in_g_force,
        to: max_in_g_force
      })
  }),
  toPayloadValue(localization, value_in_g_force) {
    const GRAVITY = 9.80665;
    const payloadValue = isNaN(Number(value_in_g_force)) ? 0 : Number(value_in_g_force) * GRAVITY;
    return isNaN(Number(payloadValue)) ? 0 : Number(payloadValue);
  }
};

const CONFIG_FIELDS = {
  eventVideoDriverCameraQuality: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoDriverCameraQuality${config_type}`,
        min = config_type === 'TrafficLightViolation' ? 1 : 0;
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Video Quality')} (${t(
              'CompanyConfig.IQCamera.Driver Camera'
            )})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min, type: 'number' },
          { max: 10, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: 10 })
        }
      };
    }
  },
  eventVideoDriverCameraResolution: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoDriverCameraResolution${config_type}`;
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Video Resolution')} (${t(
              'CompanyConfig.IQCamera.Driver Camera'
            )})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.VideoDriverCameraResolution,
        rules: [{ required: true }]
      };
    }
  },
  eventVideoQuality: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoQuality${config_type}`,
        min = config_type === 'TrafficLightViolation' ? 1 : 0;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Video Quality')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min, type: 'number' },
          { max: 10, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: 10 })
        }
      };
    }
  },
  eventVideoResolution: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoResolution${config_type}`;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Video Resolution')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.VideoResolutions,
        rules: [{ required: true }]
      };
    }
  },
  preEventVideoDuration: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `preEventVideoDuration${config_type}`;
      let min = 5,
        max = 9;
      switch (config_type) {
        case 'DistractedDriving':
        case 'CellphoneDistraction':
        case 'DrowsyDriving':
        case 'LizardEyeDistraction':
        case 'PotentialCrash':
          min = 7;
          break;
        case 'UnbuckledSeatBelt':
          min = 3;
          break;
        default:
          break;
      }
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Pre-event')} ${t(
              'CompanyConfig.IQCamera.Video Duration'
            )} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min, type: 'number' },
          { max, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: max })
        }
      };
    }
  },
  postEventVideoDuration: {
    toDutyFormField(dutyPayload, config_type) {
      const affix = config_type === 'StopSignViolation' ? 'StopSign' : config_type;
      let min = 2,
        max = 50;
      switch (config_type) {
        case 'DistractedDriving':
        case 'CellphoneDistraction':
        case 'LizardEyeDistraction':
          min = 3;
          break;
        case 'DrowsyDriving':
        case 'Speeding':
        case 'StopSignViolation':
          min = 1;
          break;
        case 'TrafficLightViolation':
          min = 5;
          break;
        default:
          break;
      }
      const payloadName = `postEventVideoDuration${affix}`;
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Post-event')} ${t(
              'CompanyConfig.IQCamera.Video Duration'
            )} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min, type: 'number' },
          { max, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: max })
        }
      };
    }
  },
  EventVideoType: {
    toDutyFormField(dutyPayload, config_type) {
      let prefix = lowerCaseFirstLetter(config_type);
      switch (config_type) {
        case 'StopSignViolation':
          prefix = 'stopSign';
          break;
        case 'TrafficLightViolation':
          prefix = 'trafficLight';
          break;
        default:
          break;
      }
      const payloadName = `${prefix}EventVideoType`;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Video Format')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.VideoFormats,
        rules: [{ required: true }]
      };
    }
  },
  eventControl: {
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      let payloadName = '',
        valueWhenEnabled = 'ON';
      switch (config_type) {
        case 'HarshAcceleration':
          payloadName = 'Harsh-Acceleration';
          break;
        case 'HardBraking':
          payloadName = 'Harsh-Braking';
          break;
        case 'Cornering':
          payloadName = 'Cornering';
          break;
        case 'Speeding':
          if (sub_config_type === 'PostedSpeed') {
            payloadName = 'Traffic-Speed-Violated';
          } else if (sub_config_type === 'MaximumSpeed') {
            payloadName = 'MaxSpeedExceeded';
          } else {
            payloadName = 'Traffic-Speed-Violated';
          }
          break;
        case 'MaxSpeed':
          payloadName = 'MaxSpeedExceeded';
          break;
        case 'LaneDrift':
          payloadName = 'Lane-Drift-Found';
          valueWhenEnabled = 'REQUEST';
          break;
        case 'Tailgating':
          payloadName = 'Tail-Gating-Detected';
          break;
        case 'StopSignViolation':
          payloadName = 'Traffic-STOP-Sign-Violated';
          break;
        case 'DistractedDriving':
          payloadName = 'Distracted-Driving';
          break;
        case 'CellphoneDistraction':
          payloadName = 'Cellphone-Distracted-Driving';
          break;
        case 'DrowsyDriving':
          payloadName = 'Drowsy-Driving-Detected';
          break;
        case 'LizardEyeDistraction':
          payloadName = 'Lizard-Eye-Distracted-Driving';
          break;
        case 'ForwardCollisionWarning':
          payloadName = 'Forward-Collision-Warning';
          break;
        case 'UnbuckledSeatBelt':
          payloadName = 'Unbuckled-Seat-Belt';
          break;
        case 'RollOver':
          payloadName = 'Roll-Over-Detected';
          break;
        case 'TrafficLightViolation':
          payloadName = 'Traffic-Light-Violated';
          break;
        default:
          payloadName = config_type;
          break;
      }
      const value = dutyPayload?.eventControl?.[payloadName]?.['control'];
      return {
        name: ['eventControl', payloadName, 'control'],
        value: value === 'TN360_ON' ? 'ON' : value,
        rules: [{ required: true }],
        isExtra: true,
        order: 1,
        selection: SELECTIONS.eventControl,
        onEventSwitch: (checked, setFieldsValue, formValues) => {
          if (checked) {
            //set value to default
            setFieldsValue(
              set(formValues, [dutyType, 'eventControl', payloadName, 'control'], valueWhenEnabled)
            );
          } else {
            //set value to 'DISABLED'
            setFieldsValue(
              set(formValues, [dutyType, 'eventControl', payloadName, 'control'], 'DISABLED')
            );
          }
        },
        isDisabled: value => value === 'DISABLED'
      };
    }
  },
  enabled: {
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      let payloadName = '',
        extras = {};
      const getSwitchWithSensors = sensorFields => ({
        sensorFields,
        getChecked(getFieldValue) {
          return !sensorFields.every(
            sensorField => !getFieldValue([dutyType, ...sensorField.name])
          );
        },
        onSwitch(checked, setFieldsValue, formValues) {
          //Trigger sensor-fields' value change
          const updateValues = sensorFields.reduce(
            (a, sensorField) => set(a, sensorField.name, checked),
            formValues?.[dutyType]
          );
          setFieldsValue({ [dutyType]: updateValues });
          //Trigger sensor-fields' onSensorSwitch
          sensorFields.forEach(sensorField => {
            if (sensorField?.onSensorSwitch) {
              sensorField.onSensorSwitch(checked, setFieldsValue, formValues);
            }
          });
        }
      });
      switch (config_type) {
        case 'HarshAcceleration':
          payloadName = 'harshAccelerationEnabled';
          break;
        case 'HardBraking':
          payloadName = 'hardBrakingEnabled';
          break;
        case 'Cornering':
          payloadName = 'corneringEnabled';
          break;
        case 'Speeding':
          payloadName = 'speedingEnabled';
          //Top-level's speeding swith itself doesn't contain any config data, but triggers sub-configs' swith(on/off) which contains config data(named sensorFields)
          extras = {};
          break;
        case 'LaneDrift':
          payloadName = 'laneDriftEnabled'; //false => laneDepartureEnabled =false;
          break;
        case 'Tailgating':
          payloadName = 'tailgatingEnabled';
          break;
        case 'StopSignViolation':
          payloadName = 'stopSignEnabled';
          break;
        case 'MaxSpeed':
          payloadName = 'maxSpeedEnabled';
          break;
        case 'DistractedDriving':
          payloadName = 'distractedDrivingEnabled';
          break;
        case 'CellphoneDistraction':
          payloadName = 'enableCellphoneDistraction';
          break;
        case 'DrowsyDriving':
          payloadName = 'drowsyDrivingEnabled';
          break;
        case 'LizardEyeDistraction':
          payloadName = 'lizardEyeDistractionEnabled';
          break;
        case 'ForwardCollisionWarning':
          payloadName = 'forwardCollisionWarningEnabled';
          break;
        case 'PotentialCrash':
          payloadName = 'potentialCrashEnabled';
          break;
        case 'UnbuckledSeatBelt':
          payloadName = 'enableUnbuckledSeatBelt';
          break;
        case 'RollOver':
          payloadName = 'rollOverEnabled';
          break;
        case 'TrafficLightViolation':
          payloadName = 'trafficLightEnabled';
          break;
        default:
          break;
      }
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isSwitch: true,
        ...extras
      };
    }
  },
  harshAccelerationThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'harshAccelerationThreshold';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.thresehold,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  corneringThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'corneringThreshold';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.thresehold,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  hardBrakingThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'hardBrakingThreshold';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.thresehold,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  speedingAllowance: {
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      const payloadName = 'speedingAllowance';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        withSlotAfter: false,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.allowance,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  minimumPostedSpeedEnforced: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minimumPostedSpeedEnforced';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.MinSpeedEnforced(Board Value)')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min: 0, type: 'number' },
          { max: 50, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0, to: 50 })
        }
      };
    }
  },
  speedUpperLimit: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'speedUpperLimit',
        min_in_mile = 0,
        max_in_mile = 150;
      return {
        label: {
          getText: (t, localization) =>
            `${t('CompanyConfig.IQCamera.MaxSpeedLimit')} (${mphFormField.getUnit(localization)})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  speedingEnabled: {
    belongs_to_sub_config_type: 'PostedSpeed',
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      const payloadName = 'speedingEnabled';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isSwitch: true,
        onSensorSwitch: (checked, setFieldsValue, formValues) => {
          const eventControl = CONFIG_FIELDS.eventControl.toDutyFormField(
            dutyPayload,
            config_type,
            dutyType,
            sub_config_type || 'PostedSpeed'
          );
          if (eventControl) {
            eventControl.onEventSwitch(checked, setFieldsValue, formValues);
          }
        }
      };
    }
  },
  maxSpeedEnabled: {
    belongs_to_sub_config_type: 'MaximumSpeed',
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      const payloadName = 'maxSpeedEnabled';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isSwitch: true,
        onSensorSwitch: (checked, setFieldsValue, formValues) => {
          const eventControl = CONFIG_FIELDS.eventControl.toDutyFormField(
            dutyPayload,
            config_type,
            dutyType,
            sub_config_type || 'MaximumSpeed'
          );
          if (eventControl) {
            eventControl.onEventSwitch(checked, setFieldsValue, formValues);
          }
        }
      };
    }
  },
  tailgatingSensitivity: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'tailgatingSensitivity';
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Sensitivity')} (${t(
              'CompanyConfig.IQCamera.ToBeDeprecated'
            )})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 1, type: 'number' }, { max: 5, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 1, to: 5 })
        }
      };
    }
  },
  TTCThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `${lowerCaseFirstLetter(config_type)}TTCThreshold`;
      return {
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.TTC Threshold')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 0.25, type: 'number' }, { max: 3, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0.25, to: 3 })
        }
      };
    }
  },
  potentialCrashThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'potentialCrashThreshold',
        min_in_g_force = 0.5,
        max_in_g_force = 20;
      return {
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.G Force Threshold')}`
        },
        name: [payloadName],
        value: _ => gForceFormField.getFieldvalue(dutyPayload?.[payloadName]),
        fieldType: Form_Field_Type.InputNumber,
        rules: gForceFormField.getRules({ min_in_g_force, max_in_g_force }),
        help: gForceFormField.getHelp({ min_in_g_force, max_in_g_force }),
        toPayloadValue: gForceFormField.toPayloadValue
      };
    }
  },
  potentialCrashSpeedThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'potentialCrashSpeedThreshold',
        min_in_mile = 0,
        max_in_mile = 100;
      return {
        compatible: true,
        label: {
          getText: (t, localization) =>
            `${t('Alerts.SpeedAlert.Speed Threshold')} (${mphFormField.getUnit(localization)})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({
            value_in_mile: dutyPayload?.[payloadName],
            localization,
            additionalConvertor: localizedValue => (localizedValue < 0 ? 0 : localizedValue)
          }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  stopSignMaximumSpeed: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'stopSignMaximumSpeed',
        min_in_mile = 1,
        max_in_mile = 19;
      return {
        compatible: true,
        label: {
          getText: (t, localization) =>
            `${t('CompanyConfig.IQCamera.MaxAllowedSpeed')} (${mphFormField.getUnit(localization)})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  tailgatingTimeThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'tailgatingTimeThreshold';
      return {
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.Duration Threshold')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 10, type: 'number' }, { max: 30, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 10, to: 30 })
        }
      };
    }
  },
  yawDistractionSpeedThresholdMph: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'yawDistractionSpeedThresholdMph',
        min_in_mile = 0,
        max_in_mile = 100;
      return {
        compatible: true,
        label: {
          getText: (t, localization) =>
            `${t('CompanyConfig.IQCamera.YawDistractionThreshold')} (${mphFormField.getUnit(
              localization
            )})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  minDistractionDurationShortPitch: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minDistractionDurationShortPitch';
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinShortPitchDuration')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 3, type: 'number' }, { max: 10, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 3, to: 10 })
        }
      };
    }
  },
  minYawDistractionDurationSeconds: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minYawDistractionDurationSeconds';
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinYawDistraction')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 3, type: 'number' }, { max: 15, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 3, to: 15 })
        }
      };
    }
  },
  pitchDistractionSpeedThresholdMph: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'pitchDistractionSpeedThresholdMph',
        min_in_mile = 0,
        max_in_mile = 10;
      return {
        compatible: true,
        label: {
          getText: (t, localization) =>
            `${t('CompanyConfig.IQCamera.PitchDistractionThreshold')} (${mphFormField.getUnit(
              localization
            )})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  minimumPitchDistractionDurationSeconds: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minimumPitchDistractionDurationSeconds';
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinPitchDistraction')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 2, type: 'number' }, { max: 15, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 2, to: 15 })
        }
      };
    }
  },
  minEyeCloseDuration: {
    toDutyFormField(dutyPayload, config_type) {
      let configType = config_type,
        min = 1,
        max = 10;
      switch (config_type) {
        case 'DrowsyDriving':
          configType = 'Drowsiness';
          break;
        case 'LizardEyeDistraction':
          configType = 'LizardEye';
          min = 3;
          max = 20;
          break;
        default:
          break;
      }
      const payloadName = `minEyeCloseDurationFor${configType}`;
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinEyeClosureDuration')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min, type: 'number' }, { max, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: max })
        }
      };
    }
  },
  triggerMinimumSpeedMph: {
    toDutyFormField(dutyPayload, config_type) {
      let payloadName = `triggerMinimumSpeed${config_type}Mph`,
        trans = t =>
          `${t(`CompanyConfig.IQCamera.${config_type}`)} ${t('CompanyConfig.IQCamera.MinSpeed')}`,
        min_in_mile = 0,
        max_in_mile = 20;
      switch (config_type) {
        case 'DrowsyDriving':
          payloadName = 'triggerMinimumSpeedDrowsinessMph';
          break;
        case 'LizardEyeDistraction':
          trans = t => t('CompanyConfig.IQCamera.MinSpeed');
          break;
        case 'LaneDrift':
        case 'Tailgating':
          min_in_mile = 30;
          max_in_mile = 50;
          break;
        case 'ForwardCollisionWarning':
          min_in_mile = 30;
          max_in_mile = 50;
          trans = t => t('CompanyConfig.IQCamera.MinSpeed');
          break;
        case 'UnbuckledSeatBelt':
          min_in_mile = 0;
          max_in_mile = 100;
          trans = t => t('CompanyConfig.IQCamera.MinSpeed');
          break;
        default:
          break;
      }
      return {
        compatible: true,
        label: {
          getText: (t, localization) => `${trans(t)} (${mphFormField.getUnit(localization)})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  eventMediaType: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventMediaType${config_type}`;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Media Type')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.MediaTypes,
        rules: [{ required: true }]
      };
    }
  },
  defaultLanguageCode: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultLanguageCode';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Default Language (Notifications)')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.Langs,
        rules: [{ required: true }]
      };
    }
  },
  deviceAudioVolume: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'deviceAudioVolume';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Device Volume')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min: 0, type: 'number' },
          { max: 15, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0, to: 15 })
        }
      };
    }
  },
  enableDriverCamera: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'enableDriverCamera';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Driver Camera')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Switch
      };
    }
  },
  defaultRoadSignsType: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultRoadSignsType';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Traffic Sign Type')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.TrafficSignTypes,
        rules: [{ required: true }]
      };
    }
  },
  defaultRoadSignUnits: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultRoadSignUnits';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Traffic Sign Unit')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.TrafficSignUnits,
        rules: [{ required: true }]
      };
    }
  },
  defaultDriverSeatSide: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultDriverSeatSide';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Driver Side in Cabin')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.DriverSignInCabin,
        rules: [{ required: true }]
      };
    }
  }
};

const COMMON_FIELDS = [
  CONFIG_FIELDS.postEventVideoDuration,
  CONFIG_FIELDS.eventVideoQuality,
  CONFIG_FIELDS.eventVideoDriverCameraQuality,
  CONFIG_FIELDS.eventVideoResolution,
  CONFIG_FIELDS.eventVideoDriverCameraResolution,
  CONFIG_FIELDS.EventVideoType,
  CONFIG_FIELDS.enabled
];

const CONFIG_TYPES = {
  HarshAcceleration: {
    id: 'HarshAcceleration',
    header: {
      getText: t => t('Scorecard.HarshAcceleration')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.harshAccelerationThreshold
    ]
  },
  HardBraking: {
    id: 'HardBraking',
    header: {
      getText: t => t('Scorecard.HarshBraking')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.hardBrakingThreshold
    ]
  },
  Cornering: {
    id: 'Cornering',
    header: {
      getText: t => t('Scorecard.HarshCornering')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.corneringThreshold
    ]
  },
  MaxSpeed: {
    id: 'MaxSpeed',
    header: {
      getText: t => t('CompanyConfig.IQCamera.MaximumSpeed')
    },
    compatible: true,
    fields: [
      ...COMMON_FIELDS,
      CONFIG_FIELDS.speedUpperLimit,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.eventControl
    ]
  },
  Speeding: {
    id: 'Speeding',
    header: {
      getText: t => t('CompanyConfig.IQCamera.SpeedLimitViolation')
    },
    fields: () => [
      ...COMMON_FIELDS,
      CONFIG_FIELDS.speedingAllowance,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.minimumPostedSpeedEnforced,
      CONFIG_FIELDS.eventControl
    ]
  },
  LaneDrift: {
    id: 'LaneDrift',
    header: {
      getText: t => t('CompanyConfig.IQCamera.LaneDrift')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.eventControl
    ]
  },
  Tailgating: {
    id: 'Tailgating',
    header: {
      getText: t => t('CompanyConfig.IQCamera.Tailgating')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.tailgatingSensitivity,
      CONFIG_FIELDS.tailgatingTimeThreshold,
      CONFIG_FIELDS.TTCThreshold,
      CONFIG_FIELDS.triggerMinimumSpeedMph
    ]
  },
  StopSignViolation: {
    id: 'StopSignViolation',
    header: {
      getText: t => t('CompanyConfig.IQCamera.StopSignViolation')
    },
    fields: [...COMMON_FIELDS, CONFIG_FIELDS.eventControl]
  },
  DistractedDriving: {
    id: 'DistractedDriving',
    header: {
      getText: t => t('CompanyConfig.IQCamera.DistractedDriving')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      CONFIG_FIELDS.postEventVideoDuration,
      CONFIG_FIELDS.eventVideoQuality,
      CONFIG_FIELDS.eventVideoDriverCameraQuality,
      CONFIG_FIELDS.yawDistractionSpeedThresholdMph,
      CONFIG_FIELDS.minYawDistractionDurationSeconds,
      CONFIG_FIELDS.pitchDistractionSpeedThresholdMph,
      CONFIG_FIELDS.minimumPitchDistractionDurationSeconds,
      CONFIG_FIELDS.minDistractionDurationShortPitch,
      CONFIG_FIELDS.eventVideoResolution,
      CONFIG_FIELDS.eventVideoDriverCameraResolution,
      CONFIG_FIELDS.EventVideoType,
      CONFIG_FIELDS.enabled,
      CONFIG_FIELDS.eventControl
    ]
  },
  DrowsyDriving: {
    id: 'DrowsyDriving',
    header: {
      getText: t => t('CompanyConfig.IQCamera.DrowsyDriving')
    },
    compatible: true,
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.minEyeCloseDuration,
      CONFIG_FIELDS.eventControl
    ]
  },
  CellphoneDistraction: {
    id: 'CellphoneDistraction',
    header: {
      getText: t => t('CompanyConfig.IQCamera.CellphoneDistraction')
    },
    compatible: true,
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.eventControl
    ]
  },
  LizardEyeDistraction: {
    id: 'LizardEyeDistraction',
    header: {
      getText: t => t('CompanyConfig.IQCamera.LizardEyeDistraction')
    },
    compatible: true,
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.minEyeCloseDuration,
      CONFIG_FIELDS.eventControl
    ]
  },
  ForwardCollisionWarning: {
    id: 'ForwardCollisionWarning',
    header: {
      getText: t => t('CompanyConfig.IQCamera.ForwardCollisionWarning')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.TTCThreshold,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.eventControl
    ]
  },
  PotentialCrash: {
    id: 'PotentialCrash',
    header: {
      getText: t => t('Common.CameraEventTypes.Potential Crash')
    },
    can: {
      oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.potentialCrashSpeedThreshold,
      CONFIG_FIELDS.potentialCrashThreshold
    ]
  },
  UnbuckledSeatBelt: {
    id: 'UnbuckledSeatBelt',
    header: {
      getText: t => t('Common.CameraEventTypes.Seatbelt Violation')
    },
    can: {
      oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.triggerMinimumSpeedMph
    ]
  },
  RollOver: {
    id: 'RollOver',
    header: {
      getText: t => t('Common.CameraEventTypes.Rollover Detection')
    },
    can: {
      oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
    },
    fields: [CONFIG_FIELDS.preEventVideoDuration, ...COMMON_FIELDS, CONFIG_FIELDS.eventControl]
  },
  TrafficLightViolation: {
    id: 'TrafficLightViolation',
    header: {
      getText: t => t('Common.CameraEventTypes.Traffic Light Violation')
    },
    can: {
      oneOfFeatureFlags: [FeatureFlag.addIQEvents.flag, FeatureFlag.addIQEventsCapitalElectric.flag]
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.eventControl
    ]
  },
  Device: {
    id: 'Device',
    header: {
      getText: (t, localization, can = () => false, plain = false) => {
        return plain ? (
          t('CompanyConfig.IQCamera.DeviceSettings')
        ) : (
          <Space>
            {t('CompanyConfig.IQCamera.DeviceSettings')}
            <Tooltip title={t('CompanyConfig.IQCamera.DeviceSettingsTooltip')}>
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        );
      }
    },
    isAdvanced: true,
    fields: [
      CONFIG_FIELDS.defaultLanguageCode,
      CONFIG_FIELDS.deviceAudioVolume,
      CONFIG_FIELDS.enableDriverCamera
    ]
  },
  Location: {
    id: 'Location',
    header: {
      getText: t => t('Entity.Location')
    },
    isAdvanced: true,
    fields: [
      CONFIG_FIELDS.defaultRoadSignsType,
      CONFIG_FIELDS.defaultRoadSignUnits,
      CONFIG_FIELDS.defaultDriverSeatSide
    ]
  }
};

const SELECTIONS = {
  thresehold: [
    {
      id: '4.5',
      value: 4.5,
      getText(t, localization, can = () => false) {
        return `${t(
          'CompanyConfig.IQCamera.tolerance.light'
        )} ${localization.convertAccelerationFromUnit(this.value)} ${
          localization.formats.acceleration.unit
        } (${this.value.toFixed(1)} mph/s)`;
      }
    },
    {
      id: '6',
      value: 6,
      getText(t, localization, can = () => false) {
        return `${t(
          'CompanyConfig.IQCamera.tolerance.medium'
        )} ${localization.convertAccelerationFromUnit(this.value)} ${
          localization.formats.acceleration.unit
        } (${this.value.toFixed(1)} mph/s)`;
      }
    },
    {
      id: '8',
      value: 8,
      getText(t, localization, can = () => false) {
        return `${t(
          'CompanyConfig.IQCamera.tolerance.severe'
        )} ${localization.convertAccelerationFromUnit(this.value)} ${
          localization.formats.acceleration.unit
        } (${this.value.toFixed(1)} mph/s)`;
      }
    }
  ],
  allowance: [
    {
      id: '5',
      value: 5,
      getText(t, localization, can = () => false) {
        return `${t('CompanyConfig.IQCamera.tolerance.light')} ${mphFormField.getFieldLabel({
          value_in_mile: this.value,
          localization
        })} ${t('CompanyConfig.IQCamera.overlimit')}`;
      }
    },
    {
      id: '10',
      value: 10,
      getText(t, localization, can = () => false) {
        return `${t('CompanyConfig.IQCamera.tolerance.medium')} ${mphFormField.getFieldLabel({
          value_in_mile: this.value,
          localization
        })} ${t('CompanyConfig.IQCamera.overlimit')}`;
      }
    },
    {
      id: '15',
      value: 15,
      getText(t, localization, can = () => false) {
        return `${t('CompanyConfig.IQCamera.tolerance.severe')} ${mphFormField.getFieldLabel({
          value_in_mile: this.value,
          localization
        })} ${t('CompanyConfig.IQCamera.overlimit')}`;
      }
    }
  ],
  VideoResolutions: [
    { id: '320x180', label: '320x180' },
    { id: '640x360', label: '640x360' },
    { id: '1280x720', label: '1280x720' },
    { id: '1920x1080', label: '1920x1080' }
  ],
  VideoDriverCameraResolution: [
    { id: '320x180', label: '320x180' },
    { id: '640x360', label: '640x360' },
    { id: '1280x720', label: '1280x720' }
  ],
  VideoFormats: [
    { id: 'road', getText: t => t('Preferences.MapSettings.Map.Type.Road') },
    { id: 'driver', getText: t => t('Tracking.Columns.Driver') },
    {
      id: 'sideBySide',
      getText: t => t('CompanyConfig.IQCamera.Video.sideBySide')
    },
    {
      id: 'pictureInPicture',
      getText: t => t('CompanyConfig.IQCamera.Video.pictureInPicture')
    },
    {
      id: 'separate',
      getText: t =>
        `${t('Preferences.MapSettings.Map.Type.Road')} + ${t('Tracking.Columns.Driver')}`
    }
  ],
  MediaTypes: [
    { id: 'video', getText: t => t('Entity.Video') },
    { id: 'image', getText: t => t('Entity.Image') }
  ],
  Langs: [
    { id: 'en-US', getText: t => t('Preferences.ChangeLanguage.en-US') },
    { id: 'en-GB', getText: t => t('Preferences.ChangeLanguage.en-GB') },
    { id: 'en-AU', getText: t => t('Preferences.ChangeLanguage.en-AU') },
    { id: 'en-CA', getText: t => t('Preferences.ChangeLanguage.en-CA') },
    { id: 'es-ES', getText: t => t('Preferences.ChangeLanguage.Spanish') },
    { id: 'fr-FR', getText: t => t('Preferences.ChangeLanguage.fr-FR') },
    { id: 'fr-CA', getText: t => t('Preferences.ChangeLanguage.fr-CA') },
    { id: 'pt-PT', getText: t => t('Preferences.ChangeLanguage.pt-PT') },
    { id: 'pt-BR', getText: t => t('Preferences.ChangeLanguage.pt-BR') }
  ],
  TrafficSignTypes: [
    { id: 'US', getText: t => t('Countries.codes.us') },
    { id: 'Canada', getText: t => t('Countries.codes.ca') },
    { id: 'Australia', getText: t => t('Countries.codes.au') },
    { id: 'Israel', getText: t => t('Countries.Israel') },
    { id: 'Vienna', getText: t => t('CompanyConfig.IQCamera.Vienna') },
    { id: 'UK', getText: t => t('Countries.codes.gb') },
    { id: 'MX', getText: t => t('Countries.codes.mx') },
    { id: 'South Africa', getText: t => t('Countries.South Africa') }
  ],
  TrafficSignUnits: [
    { id: 'mph', label: 'mph' },
    { id: 'kmph', label: 'kmph' }
  ],
  DriverSignInCabin: [
    { id: 'right', getText: t => t('Alerts.GPIO.right') },
    { id: 'left', getText: t => t('Alerts.GPIO.left') }
  ],
  eventControl: [
    {
      id: 'ON',
      value: 'ON',
      isDefault: true,
      getText: t => t('CompanyConfig.IQCamera.UploadMediaAutomatically')
    },
    {
      id: 'REQUEST',
      value: 'REQUEST',
      getText: t => t('CompanyConfig.IQCamera.UploadMediaOnRequest')
    },
    { id: 'OFF', value: 'OFF', getText: t => t('CompanyConfig.IQCamera.DisableMediaUpload') },
    {
      id: 'DISABLED',
      value: 'DISABLED',
      hidden: true,
      getText: t => t('GeofencesFeature.View.Disabled')
    }
  ]
};

export const EDIT_LEVEL = {
  NONE: 'NONE',
  FULL: 'FULL',
  PARTIAL: 'EventToglleAndOthers'
};

const toDutyFormData = (dutyPayload, dutyType, can = configTypeCanParams => true) => {
  const getConfigFields = config =>
    typeof config.fields === 'function' ? config.fields() : config.fields;
  const getSubConfig = config =>
    typeof config.subConfigs === 'function' ? config.subConfigs() : config.subConfigs;
  const isConfigAccessable = config => (config?.can ? can(config.can) : true);

  return Object.values(CONFIG_TYPES).reduce((a, config_type) => {
    if (!isConfigAccessable(config_type)) {
      return a;
    }
    const config = {
      ...config_type,
      fields: getConfigFields(config_type).map(field => ({
        ...field.toDutyFormField(dutyPayload, config_type.id, dutyType, null),
        config_type: config_type.id
      })),
      subConfigs: getSubConfig(config_type)
        ? Object.values(getSubConfig(config_type)).reduce((a, sub_config_type) => {
            if (!isConfigAccessable(sub_config_type)) {
              return a;
            }
            const subConfig = {
              ...sub_config_type,
              fields: getConfigFields(sub_config_type).map(field => ({
                ...field.toDutyFormField(dutyPayload, config_type.id, dutyType, sub_config_type.id),
                config_type: config_type.id
              }))
            };
            return getConfigFields(subConfig).length
              ? {
                  ...a,
                  [sub_config_type.id]: subConfig
                }
              : a;
          }, {})
        : {}
    };
    return !getConfigFields(config).length && !Object.keys(getSubConfig(config)).length
      ? a
      : {
          ...a,
          [config_type.id]: config
        };
  }, {});
};

export const toIQCameraFormData = (responseBody, can = configTypeCanParams => true) => {
  const configPayload = responseBody?.configuration || {};
  try {
    return Object.keys(DUTY_TYPE).reduce((a, dutyType) => {
      return {
        ...a,
        [dutyType]: toDutyFormData(configPayload?.[dutyType], dutyType, can)
      };
    }, {});
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const toIQCameraConfigPayload = (flattenedChangedConfig, configFormData) => {
  const nonAdvisoryEventControlKeys = [
    'MaxSpeedExceeded',
    'Tail-Gating-Detected',
    'Traffic-Speed-Violated',
    'Traffic-STOP-Sign-Violated',
    'Distracted-Driving',
    'Cellphone-Distracted-Driving',
    'Drowsy-Driving-Detected',
    'Lizard-Eye-Distracted-Driving',
    'Unbuckled-Seat-Belt',
    'Traffic-Light-Violated'
  ];
  //eventControl should always be included no matter it's changed or not
  const dutyEventControls = Object.keys(configFormData).reduce((a, dutyKey) => {
    const eventControl = merge({}, get(configFormData, [dutyKey, 'eventControl']));
    nonAdvisoryEventControlKeys.forEach(ck => {
      if (eventControl[ck] && eventControl[ck].control === 'ON') {
        eventControl[ck].control = 'TN360_ON';
      }
    });
    return {
      ...a,
      [dutyKey]: { eventControl }
    };
  }, {});

  const transformChangedValue = (flattenedChangedKey, changedValue) => {
    if (changedValue === 'ON') {
      const isNonAdvisoryEventControl = nonAdvisoryEventControlKeys.some(
        key =>
          key === flattenedChangedKey.match(/^(heavy|medium|light).eventControl.(.+).control$/)?.[2]
      );
      return isNonAdvisoryEventControl ? 'TN360_ON' : changedValue;
    }
    return changedValue;
  };

  const changedConfigs = Object.keys(flattenedChangedConfig || {}).reduce(
    (a, key) => set(a, key, transformChangedValue(key, flattenedChangedConfig[key])),
    {}
  );
  return merge({}, dutyEventControls, changedConfigs);
};

const flatObj = (obj, prefix = '') =>
  Object.keys(obj || {}).reduce((a, key) => {
    if (isObject(obj[key])) {
      return {
        ...a,
        ...flatObj(obj[key], `${prefix}${key}.`)
      };
    }
    return {
      ...a,
      [`${prefix}${key}`]: obj[key]
    };
  }, {});

export const getDifferentValues = (values, olderValues) => {
  return pickBy(flatObj(values), (value, key) => value !== flatObj(olderValues)[key]);
};

export const hasDifferentValues = (values, olderValues) => {
  return some(flatObj(values), (value, key) => value !== flatObj(olderValues)[key]);
};

export const isStandaloneDevice = device => !device?.vehicleInfo || !device.vehicleInfo.id;

const commenFieldsAuditPayloadToDisplay = ({ payloadName, t, localization, payloadValue }) => {
  const preEventVideoDuration = {
    reg: /preEventVideoDuration(.+|.?)/,
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Pre-event'
      )} ${t('CompanyConfig.IQCamera.Video Duration')} (${t('Common.Sec')})`
  };
  const postEventVideoDuration = {
    reg: /postEventVideoDuration(.+|.?)/,
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Post-event'
      )} ${t('CompanyConfig.IQCamera.Video Duration')} (${t('Common.Sec')})`,
    configTypeConvertor: configTypeId =>
      configTypeId === 'StopSign' ? 'StopSignViolation' : configTypeId
  };
  const eventVideoQuality = {
    getConfigTypeId: payloadName =>
      payloadName === 'videoQuality' ? '' : payloadName.match(/eventVideoQuality(.+|.?)/)?.[1],
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Video Quality'
      )}`
  };

  const eventVideoDriverCameraQuality = {
    getConfigTypeId: payloadName =>
      payloadName === 'driverCameraVideoQuality'
        ? ''
        : payloadName.match(/eventVideoDriverCameraQuality(.+|.?)/)?.[1],
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Video Quality'
      )} (${t('CompanyConfig.IQCamera.Driver Camera')})`
  };

  const eventVideoResolution = {
    reg: /eventVideoResolution(.+|.?)/,
    getConfigTypeId: payloadName =>
      payloadName === 'videoResolution'
        ? ''
        : payloadName.match(/eventVideoResolution(.+|.?)/)?.[1],
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Video Resolution'
      )}`
  };

  const eventVideoDriverCameraResolution = {
    getConfigTypeId: payloadName =>
      payloadName === 'driverCameraVideoResolution'
        ? ''
        : payloadName.match(/eventVideoDriverCameraResolution(.+|.?)/)?.[1],
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Video Resolution'
      )} (${t('CompanyConfig.IQCamera.Driver Camera')})`
  };

  const EventVideoType = {
    reg: /(.+|.?)(EventVideoType$|^dvrVideoType$)/,
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Video Format'
      )}`,
    getDisplayValue: payloadValue =>
      SELECTIONS.VideoFormats.find(opt => opt.id === payloadValue)?.getText(t) || payloadValue,
    configTypeConvertor: configTypeId => {
      let ret = capitalize(configTypeId);
      switch (configTypeId) {
        case 'stopSign':
          ret = 'StopSignViolation';
          break;
        case 'trafficLight':
          ret = 'TrafficLightViolation';
          break;
        default:
          break;
      }
      return ret;
    }
  };

  const eventMediaType = {
    reg: /eventMediaType(.+|.?)/,
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.Media Type'
      )}`,
    getDisplayValue: payloadValue =>
      SELECTIONS.MediaTypes.find(opt => opt.id === payloadValue)?.getText(t) || payloadValue,
    configTypeConvertor: configTypeId =>
      configTypeId === 'StopSign' ? 'StopSignViolation' : configTypeId
  };

  const triggerMinimumSpeedMph = {
    reg: /triggerMinimumSpeed(.+|.?)Mph/,
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.MinSpeed'
      )} (${mphFormField.getUnit(localization)})`,
    getDisplayValue: payloadValue =>
      mphFormField.getFieldvalue({ value_in_mile: payloadValue, localization })
  };

  const minEyeCloseDuration = {
    getConfigTypeId: payloadName => {
      let id = payloadName.match(/minEyeCloseDurationFor(.+|.?)/)?.[1];
      switch (id) {
        case 'Drowsiness':
          id = 'DrowsyDriving';
          break;
        case 'LizardEye':
          id = 'LizardEyeDistraction';
          break;
        default:
          break;
      }
      return id;
    },
    getDisplayName: configTypeDisplayName =>
      `${configTypeDisplayName ? configTypeDisplayName + ' - ' : ''}${t(
        'CompanyConfig.IQCamera.MinEyeClosureDuration'
      )} (${t('Common.Sec')})`
  };

  let configTypeId, display;

  for (const commenField of [
    preEventVideoDuration,
    postEventVideoDuration,
    eventVideoQuality,
    eventVideoDriverCameraQuality,
    eventVideoResolution,
    eventVideoDriverCameraResolution,
    EventVideoType,
    eventMediaType,
    triggerMinimumSpeedMph,
    minEyeCloseDuration
  ]) {
    const {
      reg,
      configTypeConvertor,
      getDisplayName,
      getDisplayValue,
      getConfigTypeId
    } = commenField;
    try {
      configTypeId = getConfigTypeId ? getConfigTypeId(payloadName) : payloadName.match(reg)?.[1];
      if (configTypeId !== undefined) {
        configTypeId = configTypeConvertor ? configTypeConvertor(configTypeId) : configTypeId;
        const configTypeDisplayName = Object.values(CONFIG_TYPES)
          .find(({ id }) => id === configTypeId)
          ?.header?.getText(t);
        display = {
          displayName: getDisplayName(configTypeDisplayName),
          displayValue: getDisplayValue ? getDisplayValue(payloadValue) : payloadValue
        };
      }
    } catch (error) {
      console.error(error);
    }

    if (display) {
      break;
    }
  }
  return display;
};

export const convertAuditPayloadToDisplay = ({ payloadName, t, localization, payloadValue }) => {
  const ret = commenFieldsAuditPayloadToDisplay({ payloadName, t, localization, payloadValue });
  if (ret) {
    return ret;
  }
  let displayName = payloadName,
    displayValue = payloadValue;
  switch (payloadName) {
    case 'Harsh-Acceleration':
      displayName = t('Scorecard.HarshAcceleration');
      break;
    case 'Cornering':
      displayName = t('Scorecard.HarshCornering');
      break;
    case 'Harsh-Braking':
      displayName = t('Scorecard.HarshBraking');
      break;
    case 'MaxSpeedExceeded':
      displayName = t('CompanyConfig.IQCamera.MaximumSpeed');
      break;
    case 'Traffic-Speed-Violated':
      displayName = t('CompanyConfig.IQCamera.SpeedLimitViolation');
      break;
    case 'Lane-Drift-Found':
      displayName = t('CompanyConfig.IQCamera.LaneDrift');
      break;
    case 'Tail-Gating-Detected':
      displayName = t('CompanyConfig.IQCamera.Tailgating');
      break;
    case 'Traffic-STOP-Sign-Violated':
      displayName = t('CompanyConfig.IQCamera.StopSignViolation');
      break;
    case 'Distracted-Driving':
      displayName = t('CompanyConfig.IQCamera.DistractedDriving');
      break;
    case 'Drowsy-Driving-Detected':
      displayName = t('CompanyConfig.IQCamera.DrowsyDriving');
      break;
    case 'Cellphone-Distracted-Driving':
      displayName = t('CompanyConfig.IQCamera.CellphoneDistraction');
      break;
    case 'Lizard-Eye-Distracted-Driving':
      displayName = t('CompanyConfig.IQCamera.LizardEyeDistraction');
      break;
    case 'Forward-Collision-Warning':
      displayName = t('CompanyConfig.IQCamera.ForwardCollisionWarning');
      break;
    case 'PotentialCrash':
      displayName = t('Common.CameraEventTypes.Potential Crash');
      break;
    case 'Unbuckled-Seat-Belt':
      displayName = t('Common.CameraEventTypes.Seatbelt Violation');
      break;
    case 'Roll-Over-Detected':
      displayName = t('Common.CameraEventTypes.Rollover Detection');
      break;
    case 'Traffic-Light-Violated':
      displayName = t('Common.CameraEventTypes.Traffic Light Violation');
      break;
    case 'enableDriverCamera':
      displayName = t('CompanyConfig.IQCamera.Driver Camera');
      displayValue = t(`Common.${payloadValue ? 'On' : 'Off'}`);
      break;
    case 'Texting-Distracted-Driving':
      displayName = t('Devices.View.CameraAudioAlert.DRIVER_TEXTING_DISTRACTION');
      break;
    case 'Smoking-Distracted-Driving':
      displayName = t('Devices.View.CameraAudioAlert.DRIVER_SMOKING_DISTRACTION');
      break;
    case 'lastUpdated':
      displayName = t('Footage.LastUpdated');
      displayValue =
        payloadValue && payloadValue !== 'null'
          ? format(new Date(payloadValue), localization.formats.time.formats.dby_imsp)
          : '';
      break;
    case 'control':
      displayName = t('CompanyConfig.IQCamera.MediaControls');
      displayValue =
        SELECTIONS.eventControl
          .find(opt => opt.value === (payloadValue === 'TN360_ON' ? 'ON' : payloadValue))
          ?.getText(t) || payloadValue;
      break;
    case 'pitchDistractionSpeedThresholdMph':
      displayName = `${t('CompanyConfig.IQCamera.DistractedDriving')} - ${t(
        'CompanyConfig.IQCamera.PitchDistractionThreshold'
      )} (${mphFormField.getUnit(localization)})`;
      displayValue = mphFormField.getFieldvalue({ value_in_mile: payloadValue, localization });
      break;
    case 'yawDistractionSpeedThresholdMph':
      displayName = `${t('CompanyConfig.IQCamera.DistractedDriving')} - ${t(
        'CompanyConfig.IQCamera.YawDistractionThreshold'
      )} (${mphFormField.getUnit(localization)})`;
      displayValue = mphFormField.getFieldvalue({ value_in_mile: payloadValue, localization });
      break;
    case 'speedUpperLimit':
      displayName = `${t('CompanyConfig.IQCamera.MaximumSpeed')} - ${t(
        'CompanyConfig.IQCamera.MaxSpeedLimit'
      )} (${mphFormField.getUnit(localization)})`;
      displayValue = mphFormField.getFieldvalue({ value_in_mile: payloadValue, localization });
      break;
    case 'minimumPostedSpeedEnforced':
      displayName = `${t('CompanyConfig.IQCamera.SpeedLimitViolation')} - ${t(
        'CompanyConfig.IQCamera.MinSpeedEnforced(Board Value)'
      )}`;
      break;
    case 'harshAccelerationThreshold':
      displayName = `${t('Scorecard.HarshAcceleration')} - ${t('GeofencesFeature.Tolerance')}`;
      displayValue =
        SELECTIONS.thresehold
          .find(opt => opt.value === Number(payloadValue))
          ?.getText(t, localization, () => true) || payloadValue;
      break;
    case 'hardBrakingThreshold':
      displayName = `${t('Scorecard.HarshBraking')} - ${t('GeofencesFeature.Tolerance')}`;
      displayValue =
        SELECTIONS.thresehold
          .find(opt => opt.value === Number(payloadValue))
          ?.getText(t, localization, () => true) || payloadValue;
      break;
    case 'corneringThreshold':
      displayName = `${t('Scorecard.HarshCornering')} - ${t('GeofencesFeature.Tolerance')}`;
      displayValue =
        SELECTIONS.thresehold
          .find(opt => opt.value === Number(payloadValue))
          ?.getText(t, localization, () => true) || payloadValue;
      break;
    case 'potentialCrashThreshold':
      displayName = `${t('Common.CameraEventTypes.Potential Crash')} - ${t(
        'CompanyConfig.IQCamera.G Force Threshold'
      )}`;
      displayValue = gForceFormField.getFieldvalue(payloadValue);
      break;
    case 'potentialCrashSpeedThreshold':
      displayName = `${t('Common.CameraEventTypes.Potential Crash')} - ${t(
        'Alerts.SpeedAlert.Speed Threshold'
      )} (${mphFormField.getUnit(localization)})`;
      displayValue = mphFormField.getFieldvalue({
        value_in_mile: payloadValue,
        localization,
        additionalConvertor: localizedValue => (localizedValue < 0 ? 0 : localizedValue)
      });
      break;
    case 'speedingAllowance':
      displayName = `${t('CompanyConfig.IQCamera.SpeedLimitViolation')} - ${t(
        'GeofencesFeature.Tolerance'
      )}`;
      displayValue =
        SELECTIONS.allowance
          .find(opt => opt.value === Number(payloadValue))
          ?.getText(t, localization, () => true) || payloadValue;
      break;
    case 'forwardCollisionWarningTTCThreshold':
      displayName = `${t('CompanyConfig.IQCamera.ForwardCollisionWarning')} ${t(
        'CompanyConfig.IQCamera.TTC Threshold'
      )} (${t('Common.Sec')})`;
      break;
    case 'tailgatingTTCThreshold':
      displayName = `${t('CompanyConfig.IQCamera.Tailgating')} ${t(
        'CompanyConfig.IQCamera.TTC Threshold'
      )} (${t('Common.Sec')})`;
      break;
    case 'tailgatingSensitivity':
      displayName = `${t('CompanyConfig.IQCamera.Tailgating')} - ${t(
        'CompanyConfig.IQCamera.Sensitivity'
      )} (${t('CompanyConfig.IQCamera.ToBeDeprecated')})`;
      break;
    case 'tailgatingTimeThreshold':
      displayName = `${t('CompanyConfig.IQCamera.Tailgating')} - ${t(
        'CompanyConfig.IQCamera.Duration Threshold'
      )} (${t('Common.Sec')})`;
      break;
    case 'minYawDistractionDurationSeconds':
      displayName = `${t('CompanyConfig.IQCamera.DistractedDriving')} - ${t(
        'CompanyConfig.IQCamera.MinYawDistraction'
      )} (${t('Common.Sec')})`;
      break;
    case 'minDistractionDurationShortPitch':
      displayName = `${t('CompanyConfig.IQCamera.DistractedDriving')} - ${t(
        'CompanyConfig.IQCamera.MinShortPitchDuration'
      )} (${t('Common.Sec')})`;
      break;
    case 'minimumPitchDistractionDurationSeconds':
      displayName = `${t('CompanyConfig.IQCamera.DistractedDriving')} - ${t(
        'CompanyConfig.IQCamera.MinPitchDistraction'
      )} (${t('Common.Sec')})`;
      break;
    case 'deviceAudioVolume':
      displayName = t('CompanyConfig.IQCamera.Device Volume');
      break;
    case 'defaultRoadSignsType':
      displayName = t('CompanyConfig.IQCamera.Traffic Sign Type');
      displayValue =
        SELECTIONS.TrafficSignTypes.find(opt => opt.id === payloadValue)?.getText(t) ||
        payloadValue;
      break;
    case 'defaultDriverSeatSide':
      displayName = t('CompanyConfig.IQCamera.Driver Side in Cabin');
      displayValue =
        SELECTIONS.DriverSignInCabin.find(opt => opt.id === payloadValue)?.getText(t) ||
        payloadValue;
      break;
    case 'defaultRoadSignUnits':
      displayName = t('CompanyConfig.IQCamera.Traffic Sign Unit');
      displayValue =
        SELECTIONS.TrafficSignUnits.find(opt => opt.id === payloadValue)?.label || payloadValue;
      break;
    case 'defaultLanguageCode':
      displayName = t('CompanyConfig.IQCamera.Default Language (Notifications)');
      displayValue =
        SELECTIONS.Langs.find(opt => opt.id === payloadValue)?.getText(t) || payloadValue;
      break;
    default:
      break;
  }
  return { displayName, displayValue };
};

export const ENABLED_THRESHOLDS = {
  HarshAcceleration: ['harshAccelerationThreshold'],
  HardBraking: ['hardBrakingThreshold'],
  Cornering: ['corneringThreshold'],
  MaxSpeed: ['speedUpperLimit'],
  Speeding: ['minimumPostedSpeedEnforced'],
  LaneDrift: ['triggerMinimumSpeedLaneDriftMph'],
  Tailgating: [
    'tailgatingTTCThreshold',
    'tailgatingTimeThreshold',
    'triggerMinimumSpeedTailgatingMph'
  ],
  DistractedDriving: [
    'yawDistractionSpeedThresholdMph',
    'minYawDistractionDurationSeconds',
    'pitchDistractionSpeedThresholdMph',
    'minimumPitchDistractionDurationSeconds',
    'minDistractionDurationShortPitch'
  ],
  DrowsyDriving: ['triggerMinimumSpeedDrowsinessMph', 'minEyeCloseDurationForDrowsiness'],
  CellphoneDistraction: ['triggerMinimumSpeedCellphoneDistractionMph'],
  LizardEyeDistraction: [
    'triggerMinimumSpeedLizardEyeDistractionMph',
    'minEyeCloseDurationForLizardEye'
  ],
  ForwardCollisionWarning: [
    'forwardCollisionWarningTTCThreshold',
    'triggerMinimumSpeedForwardCollisionWarningMph'
  ]
};

export const setDisabled = (isFieldEnabled, editPermissions) => {
  const { isAdminAndHasNPI, isSiteAdmin } = editPermissions;

  if (isSiteAdmin) return false; // Site Admins can modify everything
  if (!isAdminAndHasNPI) return true; // Non-admin users can't modify anything
  return !isFieldEnabled; // Admins can modify only enabled fields
};
