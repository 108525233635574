import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toLower, trim } from 'lodash';
import {
  useFleets,
  useVehiclesFromFleetsBoth,
  useVehicles,
  useIsFetching as isFleetsFetching
} from 'features/fleets/fleetsSlice';
import { useDeletedVehicles } from 'features/vehicles/vehiclesDeletedSlice';
import { useCompanies, useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import { VehiclesTable } from './Tables/VehiclesTable';
import { cache } from './CellRenderers';
import { uniqBy, sortBy } from 'lodash';
import useDebounce from 'utils/hooks/useDebounce';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { useDispatch } from 'react-redux';
import { useDevicesStats } from 'features/devices/devicesStatsSlice';
import { useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { TabsFilters, TABS, columnWidth, PATHS } from './constants';
import { deleteVehicleApi, restoreVehicleApi, exportVehiclesExcelFile } from './APICalls';
import { useCan, useCanOneOfRoles, GlobalRoles, FeatureFlag } from 'features/permissions';
import { Buttons } from 'components/ant';
import { CSVLink } from 'react-csv';
import { useTranslation, Trans } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { prepareDataForMultiselect } from 'utils/filters';
import { sortStrings } from 'utils/strings';
import { getCSVFilename } from '../Locations/helpers';
import { prepareDataForCsvExport, getPrimaryButton, getMoreButtons } from './helpers';
import { PATHS as COMPANYCONFIG_PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import { BULK_TYPES } from 'containers/Administration/BulkManagement/components/Modal/constants';

import styles from './Vehicles.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import services from 'features/permissions/services';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { useBulkEdit } from 'features/bulkEdit/BulkEditProvider';
import { Button, Space } from 'antd';
import entities from 'features/permissions/entities';
import { VehiclesBulkEditTable } from './BulkEdit/VehiclesBulkEditTable';
import { BulkEditRouteGuard } from 'features/bulkEdit/BulkEditRouteGuard';

export const Vehicles = ({ fleetId }) => {
  const { t } = useTranslation();
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const currentCompany = useCurrentCompany();
  const dispatch = useDispatch();
  const can = useCan();
  const vehicles = useVehicles();
  const deletedVehicles = useDeletedVehicles();
  const devicesStats = useDevicesStats();
  const vehiclesStats = useVehiclesStats();
  const fleets = useFleets();
  const vehiclesBoth = useVehiclesFromFleetsBoth();
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterTab, setFilterTab] = useState(filterPath);
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [tableRef, setTableRef] = useState(null);
  const history = useHistory();
  const localization = useLocalization();
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef(null);
  const isFleetLoading = isFleetsFetching();

  const {
    toggleBulkEditMode,
    isBulkEditMode,
    selectedRowKeys,
    isBulkUpdating,
    isBulkDeleting
  } = useBulkEdit();

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('Vehicles.Vehicles')));
  }, [dispatch]);

  useEffect(() => {
    if (path === '/settings/vehicles') {
      setFilterTab(TABS.all);
    }
  }, [path]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);

    const vehicleTypes = sortBy(
      uniqBy(
        vehicles
          .filter(
            vehicle =>
              vehicle.id !== undefined &&
              vehicle.companyId &&
              subCompaniesIdsFromFilter.includes(vehicle.companyId)
          )
          .map(vehicle => ({ id: vehicle.type.id, name: vehicle.type.name })),
        'id'
      ),
      'name'
    );

    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(
      prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), fleetId ? [fleetId] : null)
    );

    setFilterType(prepareDataForMultiselect(vehicleTypes, t('Common.AllTypes'), null));
  }, [fleets, history, vehicles, filterCompanies, t]);

  useEffect(() => {
    let vehiclesToFilter = vehicles;
    if (filterTab === TABS.deleted) {
      vehiclesToFilter = deletedVehicles;
    }
    setFilteredVehicles(
      vehiclesToFilter
        .filter(vehicle => {
          let validVehicle = true;
          const { devices } = vehicle;
          // Filter out vehicles without id
          if (!vehicle.id) {
            return false;
          }

          // Filter by filter tab
          switch (filterTab) {
            case TABS.assigned:
              if (!vehicle.devices || devices.length === 0) {
                validVehicle = false;
              }
              break;
            case TABS.standalone:
              if (vehicle.devices && devices.length > 0) {
                validVehicle = false;
              }
              break;
            default:
              validVehicle = true;
              break;
          }

          // Filter by search field
          if (debouncedSearchText) {
            validVehicle =
              validVehicle &&
              [
                vehicle.name,
                vehicle.registration,
                vehicle.vin,
                vehicle.engineNumber,
                vehicle.phone,
                vehicle.note,
                vehicle.externalId
              ].some(value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1);
          }

          // Filter by companies
          const checkedCompaniesIds = filterCompanies
            .filter(company => company.checked)
            .map(company => parseInt(company.id, 10));
          if (!(checkedCompaniesIds.indexOf(0) > -1)) {
            validVehicle =
              validVehicle && checkedCompaniesIds.indexOf(parseInt(vehicle.companyId, 10)) > -1;
          }

          // Filter by fleets
          const checkedFleetsIds = filterFleets
            .filter(fleet => fleet.checked)
            .map(fleet => parseInt(fleet.id, 10));
          if (!(checkedFleetsIds.indexOf(0) > -1)) {
            let isVehicleInFleet = false;
            // check if vehicle belongs to any of the checked fleets
            (vehicle.fleets || []).forEach(fleet => {
              isVehicleInFleet =
                isVehicleInFleet || checkedFleetsIds.indexOf(parseInt(fleet.id, 10)) > -1;
            });
            if (!isVehicleInFleet && checkedFleetsIds.indexOf(-1) > -1) {
              isVehicleInFleet =
                !Array.isArray(vehicle.fleets) || !vehicle.fleets.find(fleet => true)?.id;
            }
            validVehicle = validVehicle && isVehicleInFleet;
          }

          // Filter by type
          const checkeTypeIds = filterType
            .filter(type => type.checked)
            .map(type => parseInt(type.id, 10));
          if (!(checkeTypeIds.indexOf(0) > -1)) {
            validVehicle =
              validVehicle && checkeTypeIds.indexOf(parseInt(vehicle.type.id, 10)) > -1;
          }

          return validVehicle;
        })
        .sort((a, b) => sortStrings(a?.name, b?.name))
    );
  }, [
    filterType,
    debouncedSearchText,
    filterFleets,
    filterCompanies,
    vehicles,
    deletedVehicles,
    companies,
    filterTab
  ]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredVehicles, tableRef, vehiclesBoth]);

  const handleAction = actionObject => {
    if (actionObject.action === 'restore') {
      dispatch(restoreVehicleApi(actionObject.data));
    }
  };

  const handleDeleteAction = data => () => {
    if (data?.devices?.some(d => d.type?.code === 'EDR')) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: (
            <Trans
              i18nKey={'Vehicles.Form.DeleteEDRError'}
              components={{
                1: <span style={{ fontWeight: 900 }} />,
                2: <br />
              }}
            />
          )
        })
      );
    } else {
      dispatch(deleteVehicleApi(data));
    }
  };

  const handleExportExcelLoader = () => {
    setIsExportingExcel(false);
  };

  const handleExportExcel = () => {
    setIsExportingExcel(true);
    const data = {
      filteredVehicles,
      companies,
      countryCode: currentCompany.country,
      devicesStats,
      vehiclesStats,
      localization
    };
    dispatch(
      exportVehiclesExcelFile(
        data,
        {
          width: columnWidth,
          title: t('Vehicles.Vehicles'),
          dateFormat: localization.formats.time.formats.dby_imsp
        },
        handleExportExcelLoader
      )
    );
  };

  const handleBulkImport = () =>
    history.push({
      pathname: COMPANYCONFIG_PATHS.BULK_MANAGEMENT,
      state: { showImportModal: true, defaultImportType: BULK_TYPES.VEHICLES }
    });

  const handleExportCSV = () => {
    setCsvData(prepareDataForCsvExport(filteredVehicles, currentCompany?.country));
  };

  const handleViewBulkAudit = () => {
    history.push(PATHS.VEHICLE_BULK_AUDITS);
  };

  useEffect(() => {
    csvData?.length && csvLinkRef?.current?.link?.click();
  }, [csvData]);

  const canBulkEdit = useMemo(
    () =>
      can({
        oneOfEntities: [entities.VEHICLE_UPDATE, entities.VEHICLE_DESTROY],
        featureFlag: FeatureFlag.bulkEditVehicle.flag
      }) && filterTab !== 'deleted',
    [filterTab]
  );

  return (
    <>
      {isBulkEditMode && (
        <BulkEditRouteGuard
          entity={entities.VEHICLE}
          silentExit={!!selectedRowKeys.length}
          when={!!selectedRowKeys.length}
          exclude={location => location.pathname.includes(PATHS.VEHICLE_DEFAULT)}
        />
      )}
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabsFilters setFilterTab={setFilterTab} isBulkEditMode={isBulkEditMode} />
          </div>
          {!isBulkEditMode && (
            <div>
              <Space size={16}>
                <Buttons
                  primaryButtons={[
                    getPrimaryButton({ can, history }),
                    {
                      id: BUTTON_IDS.vehiclesBulkEdit,
                      name: t('Common.BulkEdit'),
                      onClick: toggleBulkEditMode,
                      disabled: isFleetLoading,
                      visible: canBulkEdit
                    }
                  ]}
                  moreButtons={getMoreButtons({
                    handleExportExcel,
                    handleBulkImport,
                    handleExportCSV,
                    handleViewBulkAudit,
                    isLoadingExcel: isExportingExcel
                  })}
                  preventClose
                />
                <CSVLink
                  data={csvData || []}
                  enclosingCharacter={``}
                  className={styles.hidden}
                  filename={getCSVFilename({
                    entityName: t('Vehicles.CSV.Filename'),
                    companyName: currentCompany?.name
                  })}
                  ref={csvLinkRef}
                ></CSVLink>
              </Space>
            </div>
          )}
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterCompanies?.some(value => !value.checked)
                  ? t('Common.Companies')
                  : t('Common.AllCompanies')
              }
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
            <AntMultiselect
              title={
                filterType?.some(value => !value.checked) ? t('Common.Types') : t('Common.AllTypes')
              }
              data={filterType}
              onFilter={v => setFilterType(v)}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {filteredVehicles.length}{' '}
            {filteredVehicles.length === 1
              ? `${t('Vehicles.Vehicle')}`
              : `${t('Vehicles.Vehicles')}`}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          {isBulkEditMode ? (
            <VehiclesBulkEditTable
              isLoading={isFleetLoading || isBulkUpdating || isBulkDeleting}
              vehicles={filteredVehicles}
              onDelete={handleDeleteAction}
            />
          ) : (
            <VehiclesTable
              vehicles={filteredVehicles}
              vehiclesBoth={vehiclesBoth}
              companies={companies}
              isLoading={isFleetLoading || isBulkUpdating || isBulkDeleting}
              setTableRef={setTableRef}
              typeOfEntityToDelete={t('Common.vehicle')}
              handleAction={action => handleAction(action)}
              handleDeleteAction={handleDeleteAction}
              filterTab={filterTab}
            />
          )}
        </div>
      </ContainerPageWrapper>
    </>
  );
};
