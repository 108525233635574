import React, { useCallback, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { useEventDetailsReducer, EventDetailsEntity } from './hooks/useEventDetailsReducer';
import { useEventTags } from './hooks/useEventTags';
import { Layout } from 'antd';
import { EventHeader } from './EventHeader';
import { EventDrawer } from './EventDrawer';
import { Iframe } from 'components/iframe/Iframe';
import { Loading } from 'components/loading/Loading';

import styles from './EventDetails.module.scss';

function _EventDetails({ frameRef, iframeLoaded, onIframeLoad, eventUrl }) {
  const { id: eventId } = useParams();
  const location = useLocation();
  const eventTimeAt = useMemo(() => {
    try {
      const time_at = location.search.match(/time_at=(\d+)$/)?.[1];
      return time_at ? Number(time_at) : null;
    } catch (error) {
      return null;
    }
  }, [location.search]);

  const {
    tags,
    setTags,
    severity,
    setSeverity,
    eventIssueId,
    isFetched: isFetchedTags
  } = useEventTags({
    eventId,
    eventTimeAt
  });

  const onTagsUpdated = useCallback(
    ({ eventId, tags }) => {
      setTags(tags);
      const matchedEvent = window.eventDataCache?.decoratedEvents?.find(
        e => Number(e.id) === Number(eventId)
      );
      if (matchedEvent) {
        matchedEvent.tags = tags;
        window.eventDataCache.decoratedEvents.splice(
          window.eventDataCache.decoratedEvents.findIndex(e => Number(e.id) === Number(eventId)),
          1,
          matchedEvent
        );
      }
    },
    [setTags]
  );

  const onSeverityUpdated = useCallback(
    ({ eventId, severity }) => {
      setSeverity(severity);
      const matchedEvent = window.eventDataCache?.decoratedEvents?.find(
        e => Number(e.id) === Number(eventId)
      );
      if (matchedEvent) {
        matchedEvent.severity = severity;
        window.eventDataCache.decoratedEvents.splice(
          window.eventDataCache.decoratedEvents.findIndex(e => Number(e.id) === Number(eventId)),
          1,
          matchedEvent
        );
      }
    },
    [setSeverity]
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const { isFetching, details, actions } = useEventDetailsReducer({
    eventId,
    eventTimeAt,
    onInit: ({ comments, attachments }) => {
      //Auto open drawer if has any details
      if (comments?.length || attachments?.length) {
        setOpenDrawer(true);
      }
    }
  });
  const loadComplete = useMemo(() => iframeLoaded && isFetchedTags && !isFetching, [
    iframeLoaded,
    isFetchedTags,
    isFetching
  ]);

  const eventDetailsUpdater = useMemo(
    () => ({
      onAddComment: actions.onAdd(EventDetailsEntity.Comment),
      onAddAttachment: actions.onAdd(EventDetailsEntity.Attachment),
      onEditComment: actions.onUpdate(EventDetailsEntity.Comment),
      onDeleteComment: actions.onDelete(EventDetailsEntity.Comment),
      onDeleteAttachment: actions.onDelete(EventDetailsEntity.Attachment)
    }),
    [actions]
  );

  return (
    <Layout className={styles.container}>
      {!loadComplete ? (
        <div className={styles.pageLoader}>
          <Loading />
        </div>
      ) : (
        <>
          <EventHeader
            eventId={eventId}
            eventTimeAt={eventTimeAt}
            eventTags={tags}
            eventSeverity={severity}
            onTagsUpdated={onTagsUpdated}
            onSeverityUpdated={onSeverityUpdated}
            setOpenDrawer={setOpenDrawer}
          />
          <EventDrawer
            eventId={eventId}
            eventIssueId={eventIssueId}
            open={openDrawer}
            setOpen={setOpenDrawer}
            eventDetails={details}
            eventDetailsUpdater={eventDetailsUpdater}
          />
        </>
      )}
      <Layout.Content>
        <Iframe
          ref={frameRef}
          src={eventUrl}
          className={styles.iframeContent}
          onLoad={onIframeLoad}
          allowFullScreen="true"
        ></Iframe>
      </Layout.Content>
    </Layout>
  );
}

export const EventDetails = React.memo(_EventDetails);
