import React from 'react';
import CanRoute from 'components/route/CanRoute';
import { services, entities } from 'features/permissions';
import { CAMERAS_PATH } from './constant';
import { CameraTab } from './CameraTab';

export function CameraRoute({ subscribeActions, actions, ...props }) {
  return (
    <CanRoute
      path={CAMERAS_PATH()}
      everyService={[services.CAMERA]}
      oneOfEntities={[entities.VIDEO, entities.VIDEO_VIEW]}
      render={props => <CameraTab subscribeActions={subscribeActions} actions={actions} />}
    />
  );
}
