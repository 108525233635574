import React, { useMemo } from 'react';
import * as moment from 'moment';
import { Col, Row } from 'antd';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import { PositionedFootages } from '../PositionedFootages/PositionedFootages';
import styles from '../Snapshots.module.scss';
import { sizeCalculator } from '../helpers';

function _DailySnapshotRow({
  t,
  style,
  visibleFootageCountPerRow,
  width,
  driverId,
  rowDate,
  positionedFootages,
  getIsPositionFailure,
  snapshotYear,
  snapshotMonth
}) {
  const { infoCardWidth, footageSize } = useMemo(
    () => sizeCalculator(width, true, visibleFootageCountPerRow).sizeInRow,
    [width, visibleFootageCountPerRow]
  );

  const localization = useLocalization();
  const snapShotTime = useMemo(
    () => format(moment(Number(rowDate)).valueOf(), localization.formats.time.formats.dby),
    [localization, rowDate]
  );

  return (
    <div style={{ ...style, padding: '4px 10px' }}>
      <Row className={styles.snapshotRow} wrap={false}>
        <Col
          style={{ width: infoCardWidth, minWidth: infoCardWidth }}
          className={`${styles.infoCard} ${!!driverId ? styles.loggedIn : ''}`}
        >
          <>
            <div></div>
            <div>
              <div>{snapShotTime}</div>
            </div>
          </>
        </Col>
        <Col flex={1} className={`${styles.positionedFootages} ${styles.daily}`}>
          <PositionedFootages
            t={t}
            isDailyMode
            footages={positionedFootages}
            getIsPositionFailure={getIsPositionFailure}
            footageSize={footageSize}
            snapshotYear={snapshotYear}
            snapshotMonth={snapshotMonth}
          />
        </Col>
      </Row>
    </div>
  );
}
export const DailySnapshotRow = React.memo(_DailySnapshotRow);
