import i18n from 'i18next';
import { KEY_TO_CONVERT } from './constants';
import { UNITS } from '../../../../../features/localization/localization';

export const useConfigMapping = gpioConfigs => {
  const diginFormConfig = JSON.parse(
    gpioConfigs?.find(c => c.key === 'channel.template')?.defaultValue || '[]'
  );

  const inBiasMap = (diginFormConfig.find(data => data.name === 'Bias')?.options || []).reduce(
    (map, option) => {
      map[option.key] = option.value;
      return map;
    },
    {}
  );

  const inTriggerMap = (
    diginFormConfig.find(data => data.name === 'Trigger')?.options || []
  ).reduce((map, option) => {
    map[option.key] = option.value;
    return map;
  }, {});

  const inOccurrenceMap = (
    diginFormConfig.find(data => data.name === 'Occurrence')?.options || []
  ).reduce((map, option) => {
    map[option.key] = option.value;
    return map;
  }, {});

  const channelTypes = [];
  const inInput = diginFormConfig.find(item => item.field === 'input');
  if (inInput) {
    inInput.options?.forEach(item => channelTypes.push({ ...item, form: 'digin' }));
  }

  const digoutFormConfig = JSON.parse(
    gpioConfigs?.find(c => c.key === 'channel.template-digout')?.defaultValue || '[]'
  );

  const outBiasMap = (
    digoutFormConfig.find(data => data.name === 'Initial State')?.options || []
  ).reduce((map, option) => {
    map[option.key] = option.value;
    return map;
  }, {});

  const outTriggerMap = (
    digoutFormConfig.find(data => data.name === 'Trigger')?.options || []
  ).reduce((map, option) => {
    map[option.key] = option.value;
    return map;
  }, {});

  const outOccurrenceMap = (
    digoutFormConfig.find(data => data.name === 'Occurrence')?.options || []
  ).reduce((map, option) => {
    map[option.key] = option.value;
    return map;
  }, {});

  const outInput = digoutFormConfig.find(item => item.field === 'input');
  if (outInput) {
    outInput.options?.forEach(item => channelTypes.push({ ...item, form: 'digout' }));
  }

  const seatbeltSpeedThresholdDefault = parseInt(
    gpioConfigs?.find(c => c.key === 'seatbelt.speed.threshold')?.defaultValue || '8'
  );

  return {
    inBiasMap,
    inTriggerMap,
    inOccurrenceMap,
    outBiasMap,
    outTriggerMap,
    outOccurrenceMap,
    diginFormConfig,
    digoutFormConfig,
    seatbeltSpeedThresholdDefault,
    channelTypes
  };
};

export const toTableStructure = (channelTypes, data) => {
  // Create a map for quick lookup
  const channelTypeMap = channelTypes.reduce((acc, curr) => {
    acc[`${curr.form}.${curr.key}`] = curr;
    return acc;
  }, {});

  // Construct the new list
  const newList = data.reduce((acc, curr) => {
    const channelType = channelTypeMap[curr.input];

    if (channelType) {
      const existingItem = acc.find(item => item.key === channelType.key);

      if (existingItem) {
        existingItem.data.push(curr);
      } else {
        acc.push({
          key: channelType.key,
          value: channelType.value,
          data: [curr]
        });
      }
    }

    return acc;
  }, []);
  return newList;
};

export const toUIConfig = (configItem, diginIODefinition, digoutIODefinition, channelTypes) => {
  const configFromDB = JSON.parse(configItem.value);
  const item = {};
  const parts = configItem.key.split('.');
  const input = channelTypes.find(t => t.key === parts[0]);
  item.id = configItem.id;
  item.scope = configItem.scope;
  item.input = input.form + '.' + parts[0];
  item.channel = parts[1];
  item.bias = configFromDB.bias;
  item.trigger = configFromDB.trigger;
  item.occurrence = configFromDB.occurrence;
  if (configFromDB.label) {
    item.io = configFromDB.label;
    const ioDefinition = item.input.startsWith('digin.') ? diginIODefinition : digoutIODefinition;
    if (Array.isArray(ioDefinition.field)) {
      const fieldsToExclude = ['name', 'field', 'verb'];
      const innerItem = ioDefinition.field.find(i => i.name === item.io);
      const allProperties = Object.keys(innerItem);
      const remaining = allProperties.filter(p => !fieldsToExclude.includes(p));
      if (remaining.length > 0) {
        item[item.io] = {};
        remaining.forEach(p => (item[item.io][p] = configFromDB[p]));
      }
    }
  }
  if (configItem.extras) {
    const extras = JSON.parse(configItem.extras);
    if (extras.overrides) {
      item.custom = true;
      if (extras.overrides.label) {
        item.custom_name = extras.overrides.label;
      }
      if (extras.overrides.verb) {
        const states = extras.overrides.verb.split(',');
        item.custom_state1 = states[0];
        item.custom_state2 = states[1];
      }
    }
  }
  return item;
};

export const enrichWithExtraServiceConfig = (
  configurations,
  serviceMap,
  diginIODefinition,
  digoutIODefinition,
  channelTypes,
  localization
) => {
  const vpmService = serviceMap['VPM'];
  const bpmService = serviceMap['BPM'];
  const driverService = serviceMap['DRIVERPIN'];
  const speedSirenService = serviceMap['SPEEDSIREN'];
  const channelPrefixes = channelTypes?.map(item => item.key);

  const parsedConfigTemplate = configurations
    .filter(item => channelPrefixes?.some(p => item.key.startsWith(p + '.')))
    .map(item => toUIConfig(item, diginIODefinition, digoutIODefinition, channelTypes));

  const sirenGpioConfig =
    parsedConfigTemplate.find(item => item.io === 'siren' && item.input?.startsWith('digout.')) ||
    {};
  const ledGpioConfig =
    parsedConfigTemplate.find(item => item.io === 'led' && item.input?.startsWith('digout.')) || {};

  configurations.forEach(item => {
    let cfg;
    if (item.key.startsWith('idle.')) {
      if (!sirenGpioConfig.idle) {
        sirenGpioConfig.idle = {};
      }
      cfg = sirenGpioConfig.idle;
    } else if (vpmService && item.service?.id === vpmService.id) {
      if (!sirenGpioConfig.vpm) {
        sirenGpioConfig.vpm = {};
      }
      cfg = sirenGpioConfig.vpm;
    } else if (bpmService && item.service?.id === bpmService.id) {
      if (!sirenGpioConfig.bpm) {
        sirenGpioConfig.bpm = {};
      }
      cfg = sirenGpioConfig.bpm;
    } else if (driverService && item.service?.id === driverService.id) {
      if (!sirenGpioConfig.driver) {
        sirenGpioConfig.driver = {};
      }
      cfg = sirenGpioConfig.driver;
    } else if (speedSirenService && item.service?.id === speedSirenService.id) {
      if (item.key.endsWith('.1')) {
        if (!ledGpioConfig.led) {
          ledGpioConfig.led = {};
        }
        cfg = ledGpioConfig.led;
      } else {
        if (!sirenGpioConfig.speedsiren) {
          sirenGpioConfig.speedsiren = {};
        }
        cfg = sirenGpioConfig.speedsiren;
      }
    } else {
      return;
    }
    let value = item.value;
    if (localization && KEY_TO_CONVERT.includes(item.key)) {
      value = localization.convertDistance(value, 1);
    }
    if (item.key === 'gpio.output' || item.key === 'gpio.output.1') {
      cfg['gpio.output'] = value && value !== '0';
    } else if (item.key === 'idle.gpio.output') {
      cfg['idle.gpio.output'] = value && value !== '0';
    } else if (item.key.startsWith('idle.')) {
      cfg[item.key] = value;
    } else if (item.key === 'driver.loggedoff.detection') {
      cfg['driver.loggedoff.detection'] = value && value === 'true';
    } else if (item.key.startsWith('driver.')) {
      cfg[item.key] = value;
    } else if (item.key === 'siren.events') {
      cfg['siren.events'] = JSON.parse(value);
    } else if (item.key.startsWith('speed.')) {
      cfg[item.key] = value;
    } else {
      cfg[item.key.endsWith('.1') ? item.key.substring(0, item.key.length - 2) : item.key] = value;
    }
  });
  return parsedConfigTemplate;
};

export const constructConfigForm = (service, configs) => {
  if (!configs) {
    return null;
  }
  return {
    name: service,
    type: 'service',
    default: 'siren',
    field: configs
      .sort((a, b) => (a.key === 'gpio.output' ? -1 : 0))
      .map(s => {
        const defaultValue =
          s.dataType !== 'rquery' && s.defaultValue
            ? JSON.parse(s.defaultValue.replace(/\\"/, '"'))
            : undefined;
        return {
          name: `service.${service}.${s.key.replace(/\./g, '_')}`,
          field: [service, s.key],
          type: s.dataType,
          options: defaultValue?.options,
          default: defaultValue?.default || defaultValue,
          visibleWhen:
            s.key === 'gpio.output'
              ? undefined
              : diginFormConfig =>
                  diginFormConfig[service]
                    ? diginFormConfig[service]['gpio.output'] === true
                    : false,
          tooltip: s.description
        };
      })
  };
};

export const formatDigoutData = (
  configuration,
  biasMap,
  occurrenceMap,
  triggerMap,
  vpmData,
  bpmData,
  idleData,
  driverData,
  sirenData
) => {
  const translatedJsonValue = { ...configuration };
  translatedJsonValue.bias = biasMap[configuration.bias];
  translatedJsonValue.occurrence = occurrenceMap[configuration.occurrence];
  translatedJsonValue.trigger = triggerMap[configuration.trigger] || configuration.trigger;

  const formattedVpmData = constructConfigForm('vpm', vpmData);
  const formattedBpmData = constructConfigForm('bpm', bpmData);
  const formattedDriverData = constructConfigForm('driver', driverData);
  const formattedIdleData = constructConfigForm('idle', idleData);
  const formattedSirenData = constructConfigForm('speedsiren', sirenData);

  const readableDefinition = {};
  if (configuration.bpm) {
    populateOutputDefinitionData(readableDefinition, configuration.bpm, formattedBpmData);
  }

  if (configuration.vpm) {
    populateOutputDefinitionData(readableDefinition, configuration.vpm, formattedVpmData);
  }

  if (configuration.driver) {
    populateOutputDefinitionData(readableDefinition, configuration.driver, formattedDriverData);
  }

  if (configuration.speedsiren) {
    populateOutputDefinitionData(readableDefinition, configuration.speedsiren, formattedSirenData);
  }

  if (configuration.idle) {
    populateOutputDefinitionData(readableDefinition, configuration.idle, formattedIdleData);
  }

  if (configuration.led) {
    populateOutputDefinitionData(readableDefinition, configuration.led, formattedSirenData);
  }

  translatedJsonValue.ioDetail = readableDefinition;
  translatedJsonValue.key = translatedJsonValue.input + '.' + translatedJsonValue.channel;

  return translatedJsonValue;
};

export const formatDigoutTableData = (
  configuration,
  biasMap,
  occurrenceMap,
  triggerMap,
  vpmData,
  bpmData,
  idleData,
  driverData,
  sirenData
) => {
  const translatedJsonValue = { ...configuration };
  translatedJsonValue.bias = biasMap[configuration.bias];
  translatedJsonValue.occurrence = occurrenceMap[configuration.occurrence];
  translatedJsonValue.trigger = triggerMap[configuration.trigger] || configuration.trigger;

  const formattedVpmData = constructConfigForm('vpm', vpmData);
  const formattedBpmData = constructConfigForm('bpm', bpmData);
  const formattedDriverData = constructConfigForm('driver', driverData);
  const formattedIdleData = constructConfigForm('idle', idleData);
  const formattedSirenData = constructConfigForm('speedsiren', sirenData);

  const readableDefinition = {};
  if (configuration.bpm) {
    populateOutputDefinitionTable(readableDefinition, configuration.bpm, formattedBpmData);
  }

  if (configuration.vpm) {
    populateOutputDefinitionTable(readableDefinition, configuration.vpm, formattedVpmData);
  }

  if (configuration.driver) {
    populateOutputDefinitionTable(readableDefinition, configuration.driver, formattedDriverData);
  }

  if (configuration.speedsiren) {
    populateOutputDefinitionTable(readableDefinition, configuration.speedsiren, formattedSirenData);
  }

  if (configuration.idle) {
    populateOutputDefinitionTable(readableDefinition, configuration.idle, formattedIdleData);
  }

  if (configuration.led) {
    populateOutputDefinitionTable(readableDefinition, configuration.led, formattedSirenData);
  }

  translatedJsonValue.ioDetail = readableDefinition;
  translatedJsonValue.key = translatedJsonValue.input + '.' + translatedJsonValue.channel;

  return translatedJsonValue;
};

export const formatDiginData = (
  configuration,
  biasMap,
  occurrenceMap,
  triggerMap,
  ioDefinition
) => {
  const translatedJsonValue = { ...configuration };
  translatedJsonValue.bias = biasMap[configuration.bias];
  translatedJsonValue.occurrence = occurrenceMap[configuration.occurrence];
  translatedJsonValue.trigger = triggerMap[configuration.trigger] || configuration.trigger;

  if (configuration[configuration.io]) {
    const definition = ioDefinition.field.find(i => i.name === configuration.io);
    const readableDefinition = {};
    Object.keys(configuration[configuration.io]).map(i => {
      readableDefinition[definition[i].name] = configuration[configuration.io][i];
    });

    translatedJsonValue.ioDetail = readableDefinition;
  }
  translatedJsonValue.key = translatedJsonValue.input + '.' + translatedJsonValue.channel;
  return translatedJsonValue;
};

const populateOutputDefinitionData = (data, value, configData) => {
  Object.keys(value)
    .filter(
      i => i !== 'gpio.output' && i !== 'idle.gpio.output' && i !== 'driver.loggedoff.detection'
    )
    .map(key => {
      const matchedFieldValue = configData?.field.find(i => i.field.includes(key));
      const matchedFieldProp = configData?.field.find(
        i =>
          i.field.includes('gpio.output') ||
          i.field.includes('idle.gpio.output') ||
          i.field.includes('driver.loggedoff.detection')
      );

      if (matchedFieldValue && matchedFieldProp) {
        const definitionKey =
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.' + matchedFieldProp?.name) +
          ' - ' +
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.' + matchedFieldValue?.name);
        switch (matchedFieldValue.type) {
          case 'mselect':
            data[definitionKey] = value[key].map(i => i18n.t('Alerts.GPIO.' + i, i)).join(', ');
            break;
          case 'select':
            data[definitionKey] = i18n.t('Alerts.GPIO.' + value[key], value[key]);
            break;
          default:
            data[definitionKey] = value[key];
        }
      }
    });
};

const populateOutputDefinitionTable = (data, value, configData) => {
  Object.keys(value)
    .filter(
      i => i !== 'gpio.output' && i !== 'idle.gpio.output' && i !== 'driver.loggedoff.detection'
    )
    .forEach(key => {
      const matchedFieldValue = configData?.field.find(i => i.field.includes(key));
      const matchedFieldProp = configData?.field.find(
        i =>
          i.field.includes('gpio.output') ||
          i.field.includes('idle.gpio.output') ||
          i.field.includes('driver.loggedoff.detection')
      );

      if (matchedFieldValue && matchedFieldProp) {
        const service = i18n.t(
          'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + matchedFieldProp?.name
        );
        const definitionKey = i18n.t(
          'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + matchedFieldValue?.name
        );
        let matchedValue;
        switch (matchedFieldValue.type) {
          case 'mselect':
            matchedValue = value[key].map(i => i18n.t('Alerts.GPIO.' + i, i)).join(', ');
            break;
          case 'select':
            matchedValue = i18n.t('Alerts.GPIO.' + value[key], value[key]);
            break;
          default:
            matchedValue = value[key];
        }
        data[service] = { ...(data[service] ?? {}), [definitionKey]: matchedValue };
      }
    });
};

export const constructForm = (service, configs, ioDefinition = 'siren') => {
  if (!configs) {
    return null;
  }
  return {
    name: service,
    type: 'service',
    default: ioDefinition,
    field: configs
      .sort((a, b) =>
        a.key === 'gpio.output' ||
        a.key === 'idle.gpio.output' ||
        a.key === 'driver.loggedoff.detection'
          ? -1
          : a.key.localeCompare(b.key)
      )
      .map(s => {
        const defaultValue =
          s.dataType !== 'rquery' && s.defaultValue
            ? JSON.parse(s.defaultValue.replace(/\\"/, '"'))
            : undefined;
        return {
          name: `service.${service}.${s.key.replace(/\./g, '_')}`,
          field: [service, s.key],
          type: s.dataType,
          options: defaultValue?.options,
          default: defaultValue?.default || defaultValue,
          visibleWhen:
            s.key === 'gpio.output' ||
            s.key === 'idle.gpio.output' ||
            s.key === 'driver.loggedoff.detection'
              ? undefined
              : diginFormConfig =>
                  diginFormConfig[service]
                    ? diginFormConfig[service]['gpio.output'] === true ||
                      diginFormConfig[service]['idle.gpio.output'] === true ||
                      diginFormConfig[service]['driver.loggedoff.detection'] === true
                    : service === 'driver',
          tooltip: s.description,
          max: ['harsh.pattern.delay', 'speed.pattern.delay', 'idle.pattern.delay'].includes(s.key)
            ? 255
            : undefined
        };
      })
  };
};

const toGpioConfig = (cfg, formConfig) => {
  const fieldsToExclude = ['channel', 'input'];
  const isInput = cfg.input.startsWith('digin.');
  const gpio = {};
  formConfig.forEach(cfgField => {
    if (cfgField.type === 'io' && cfg.io) {
      const innerField = cfgField.field.find(item => item.name === cfg.io);
      gpio[innerField.field] = cfg.io;
      if (innerField.verb) {
        gpio['verb'] = innerField.verb;
      }
      if (isInput && cfg[cfg.io]) {
        const ioFields = cfg[cfg.io];
        Object.keys(ioFields).forEach(key => (gpio[key] = ioFields[key]));
      }
    } else if (!fieldsToExclude.includes(cfgField.field) && cfg[cfgField.field]) {
      gpio[cfgField.field] = cfg[cfgField.field];
    }
  });
  return gpio;
};

const createServiceConfig = (localization, channel, item, service, suffix = '') => {
  const configurations = [];
  if (
    (item && (item['gpio.output'] || item['idle.gpio.output'])) ||
    item['driver.loggedoff.detection']
  ) {
    const keys = Object.keys(item);
    keys.forEach(k => {
      let value;
      if (k === 'gpio.output' || k === 'idle.gpio.output') {
        value = channel;
      } else if (k === 'driver.loggedoff.detection') {
        value = true;
      } else {
        value = Array.isArray(item[k]) ? JSON.stringify(item[k]) : item[k];
      }
      if (KEY_TO_CONVERT.includes(k)) {
        value = localization.convertSpeedWithUnit(
          Number(value),
          UNITS.KM,
          localization?.formats?.speed?.unit || UNITS.KM,
          0
        );
      }
      configurations.push({
        service: service,
        key: k.startsWith('speed.') ? k : k + suffix,
        value: value
      });
    });
  }
  return configurations;
};

export const generateGpioConfigs = (
  localization,
  gpioConfigList,
  serviceMap,
  diginFormConfig,
  digoutFormConfig,
  isVPMEnabled
) => {
  const gpioService = serviceMap['GPIO'];
  const vpmService = serviceMap['VPM'];
  const bpmService = serviceMap['BPM'];
  const driverService = serviceMap['DRIVERPIN'];
  const speedSirenService = serviceMap['SPEEDSIREN'];

  let gpioConfigs = [];
  if (gpioConfigList?.length > 0) {
    gpioConfigs = gpioConfigList.map(item => {
      const value = JSON.stringify(
        toGpioConfig(item, item.input.startsWith('digin.') ? diginFormConfig : digoutFormConfig)
      );
      const dotIndex = item.input.indexOf('.');
      const input = item.input.substring(dotIndex + 1);
      if (item.custom) {
        const custom = {
          overrides: {}
        };

        if (item.custom_name) {
          custom.overrides.label = item.custom_name;
        }
        if (item.custom_state1 && item.custom_state2) {
          custom.overrides.verb = item.custom_state1 + ',' + item.custom_state2;
        }
        const extras = JSON.stringify(custom);
        return {
          service: gpioService,
          key: `${input}.${item.channel}`,
          value,
          extras
        };
      }

      return {
        service: gpioService,
        key: `${input}.${item.channel}`,
        value
      };
    });
    // Process VPM, BPM, driver and SpeedSiren

    const configMap = [
      { prop: 'vpm', getService: () => vpmService },
      { prop: 'bpm', getService: () => bpmService },
      { prop: 'driver', getService: () => driverService },
      { prop: 'speedsiren', getService: () => speedSirenService },
      { prop: 'led', getService: () => speedSirenService, suffix: '.1' },
      { prop: 'idle', getService: () => (isVPMEnabled ? vpmService : bpmService) }
    ];

    gpioConfigList.forEach(item => {
      configMap.forEach(cfg => {
        if (item[cfg.prop]) {
          gpioConfigs = gpioConfigs.concat(
            createServiceConfig(
              localization,
              item.channel,
              item[cfg.prop],
              cfg.getService(),
              cfg.suffix
            )
          );
        }
      });
    });
  }

  return gpioConfigs;
};

// This is duplicated and used in 3 places, we need to move it to a generic place
// GPIO SpeedAssist and DriverId
// like containers/Configuration/CompanyConfig/Utils/helpers and reusit everywhere
// or to containers/Configuration/CompanyConfig/DeviceConfiguration/Utils/helpers
export const inputValidator = item => {
  return {
    number: [
      {
        validator: (_, value) => {
          const num = Number(value);
          const min = item?.min || 0;
          const max = item?.max || 999;

          if (isNaN(Number(value)) || !isFinite(Number(value)) || value === null) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.NumberTypeError')
            );
          }

          if (!Number.isInteger(num)) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.IntegerValue')
            );
          }

          if (num < min) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MinValue', {
                min: `${min}`
              })
            );
          }
          if (num > max) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MaxValue', {
                max: `${max || 999}`
              })
            );
          }

          return Promise.resolve();
        }
      }
    ],
    double: [
      {
        validator: (_, value) => {
          const num = Number(value);
          const min = item?.min || 0;
          const max = item?.max || 999;

          if (isNaN(Number(value)) || !isFinite(Number(value)) || value === null) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.NumberTypeError')
            );
          }

          if (num < min) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MinValue', {
                min: `${min}`
              })
            );
          }
          if (num > max) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MaxValue', {
                max: `${max || 999}`
              })
            );
          }

          return Promise.resolve();
        }
      }
    ]
  };
};
