import React from 'react';
import { entities, useCan } from 'features/permissions';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

export const UserActionsCell = ({ user, onDelete }) => {
  const { t } = useTranslation();
  const can = useCan();
  const canDeleteUser = can({ everyEntity: [entities.USER_DESTROY] });

  const handleDelete = () => {
    let content = `${t('Common.SureDelete')} ${t('Common.User')} ${user.name}?`;

    confirmationModal(
      `${t('Common.DeleteButton')} ${user.name}`,
      content,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      onDelete(user),
      'delete'
    );
  };

  return (
    <Tooltip title={!canDeleteUser && t('Messaging.NoPermission')}>
      <Button
        disabled={!canDeleteUser}
        type="text"
        icon={<FontAwesomeIcon icon={faTrash} color="#FF7875" />}
        onClick={handleDelete}
      />
    </Tooltip>
  );
};
