import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Button } from 'antd';
import { EventTags } from './EventTags';
import { EventSeverity } from './EventSeverity';

import styles from './EventHeader.module.scss';

export function EventHeader({
  eventId,
  eventTimeAt,
  eventTags,
  eventSeverity,
  onTagsUpdated,
  onSeverityUpdated,
  setOpenDrawer
}) {
  const { t } = useTranslation();
  return (
    <Layout.Header className={styles.header}>
      <div className={styles.start}>
        <EventTags
          eventId={eventId}
          eventTimeAt={eventTimeAt}
          eventTags={eventTags}
          eventSeverity={eventSeverity}
          onTagsUpdated={onTagsUpdated}
          size="large"
        />
        <EventSeverity
          eventId={eventId}
          eventTimeAt={eventTimeAt}
          eventTags={eventTags}
          eventSeverity={eventSeverity}
          onSeverityUpdated={onSeverityUpdated}
          size="large"
        />
      </div>
      <div>
        <Button
          type="primary"
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          {t('Event.Updates')}
        </Button>
      </div>
    </Layout.Header>
  );
}
