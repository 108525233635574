import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const vehicleTypesDataAdapter = reduxAdapter({
  persist: async vehicleTypes => {
    if (vehicleTypes && vehicleTypes.length) {
      for (const v of vehicleTypes) {
        if (v.id) {
          await TNDataService.insertOrUpdateVehicleType(v);
        }
      }
    }
  },
  load: async () => {
    const vehicleTypes = await TNDataService.getAllVehicleTypes();
    return vehicleTypes;
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_vehicle_types');
    return result;
  },
  updateLastUpdatedDate: async lastpUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_vehicle_types', lastpUpdatedDate);
    return result;
  }
});

export { vehicleTypesDataAdapter };
