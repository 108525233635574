import { API_PATH, API_PATH_WITHOUT_V1 } from 'config';
import { t_error } from 'i18nextConfig';
import request from 'superagent';

export const fetchCameraEvents = (
  startTime,
  endTime,
  devices,
  drivers,
  eventTypes,
  orderField = 'time',
  orderDirection = 'desc',
  rowOffset = 0,
  search,
  onSuccess,
  onError = null,
  videoRequest = false,
  status,
  tags,
  severities
) => (dispatch, getState) =>
  request
    .post(`${API_PATH}/devices/events/camera${videoRequest ? '/video_requests' : ''}`)
    .query('from=' + encodeURIComponent(startTime))
    .query('to=' + encodeURIComponent(endTime))
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .send({
      devices,
      drivers,
      eventTypes,
      orderField,
      orderDirection,
      rowOffset,
      search,
      status,
      tags,
      severities
    })
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      console.error(errMsg);
      if (onError) {
        onError(errMsg);
      }
    });

export const fetchSnapshots = ({
  startTime,
  endTime,
  devices,
  drivers,
  orderField = 'time',
  orderDirection = 'desc',
  rowOffset = 0,
  search,
  rowCountLimit = 100,
  latestOnly = false,
  onSuccess,
  onError
}) => (dispatch, getState) =>
  request
    .post(`${API_PATH}/devices/events/camera/snapshots`)
    .query('from=' + encodeURIComponent(startTime))
    .query('to=' + encodeURIComponent(endTime))
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .send({
      devices,
      drivers,
      orderField,
      orderDirection,
      rowOffset,
      rowCountLimit,
      search,
      latestOnly
    })
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      console.error(errMsg);
      if (onError) {
        onError(errMsg);
      }
    });

export const fetchCompanyDVRUsage = ({ companyId, onSuccess, onError }) => (dispatch, getState) =>
  request('GET', `${API_PATH_WITHOUT_V1}cameras/lightmetrics/dvr/upload/limit`)
    .query(`company_id=${companyId}`)
    .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
    });

export const fetchCompanyCameraHealthSummary = ({ startTime, companyId, onSuccess, onError }) => (
  dispatch,
  getState
) => {
  const userKey = getState().user.current.auth.key;
  const req = request('GET', `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/summary`)
    .query('startTime=' + encodeURIComponent(startTime))
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyCameraHealthEvents = ({
  startTime,
  companyId,
  search = '',
  limit = 100,
  offset = 0,
  healthEventTypes = [],
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request('GET', `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/events`)
    .query('startTime=' + encodeURIComponent(startTime))
    .query({ deviceName: search, offset, limit, healthEventTypes })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCameraHealthEvents = ({
  startTime: from,
  endTime: to,
  deviceIds,
  healthEventTypes,
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request('POST', `${API_PATH_WITHOUT_V1}cameras/health/devices/summary`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send({
      deviceIds,
      healthEventTypes,
      from,
      to
    })
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyDevicePing = ({
  companyId,
  limit = 100,
  offset = 0,
  search = '',
  sortBy = 'lastCommsAt',
  sort = 'asc',
  deviceModels = ['IQCamera', 'MultiIQ'],
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request(
    'GET',
    `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/device-ping`
  )
    .query({ search, offset, limit, sortBy, sort })
    .query('deviceModels=' + deviceModels)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyDataConsumptionSummary = ({
  companyId,
  startTime,
  endTime,
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request(
    'GET',
    `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/data-consumption/summary`
  )
    .query({ startTime, endTime })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyDataConsumption = ({
  companyId,
  startTime,
  endTime,
  dataConsumptionRate = '',
  search = '',
  offset = 0,
  limit = 5,
  sort = 'desc',
  sortBy = 'totalMobileConsumption',
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request(
    'GET',
    `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/data-consumption`
  )
    .query({
      from: startTime,
      to: endTime,
      sort,
      sortBy,
      limit,
      offset,
      search,
      dataConsumptionRate
    })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};
