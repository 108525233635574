import { Button, DatePicker, Divider, Select, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { useDispatch } from 'react-redux';

import { useCurrentCompany } from 'features/company/companySlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useTemplates } from 'features/bulk_management';
import { BulkManagementModal } from 'containers/Administration/BulkManagement/components/Modal/ImportModal';
import {
  useGetSDCDataQuery,
  useGetSDMDataQuery,
  useGetVehicleRegistrationQuery
} from 'services/nextgen';
import { entities, useCanOneOfEntities } from 'features/permissions';

import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { NewDeclarationModal } from './NewDeclarationModal';
import TMADeclarationTable from './TMADeclarationTable';

import { filterTMAData } from './helpers';
import useDebounce from 'utils/hooks/useDebounce';

//styles
import styles from './TMADeclaration.module.scss';

export const TMADeclaration = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const templates = useTemplates();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState([dayjs().subtract(30, 'days'), dayjs()]);
  const [tempDateRange, setTempDateRange] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredTMAData, setFilteredTMAData] = useState(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);
  const [registrationFilter, setRegistrationFilter] = useState(null);
  const [registrationsFilterOptions, setRegistrationFilterOptions] = useState([]);
  const [sdDataRecordTypeFilter, setSdDataRecordTypeFilter] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [tmaData, setTmaData] = useState([]);
  const canBulkImport = useCanOneOfEntities([entities.BULKIMPORT]);

  const { data: sdmData, isLoading: isSDMDataLoading } = useGetSDMDataQuery({
    companyId: currentCompany?.id,
    fromDate: isDatePickerOpen
      ? tempDateRange[0]?.endOf('day')?.toISOString()
      : dateRange[0]?.endOf('day')?.toISOString(),
    toDate: isDatePickerOpen
      ? tempDateRange[1]?.endOf('day')?.toISOString()
      : dateRange[1]?.endOf('day')?.toISOString()
  });
  const { data: sdcData, isLoading: isSDCDataLoading } = useGetSDCDataQuery({
    companyId: currentCompany?.id,
    fromDate: isDatePickerOpen
      ? tempDateRange[0]?.endOf('day')?.toISOString()
      : dateRange[0]?.endOf('day')?.toISOString(),
    toDate: isDatePickerOpen
      ? tempDateRange[1]?.endOf('day')?.toISOString()
      : dateRange[1]?.endOf('day')?.toISOString()
  });
  const { data: registrations } = useGetVehicleRegistrationQuery({ companyId: currentCompany?.id });

  const debouncedSearchText = useDebounce(searchValue, 300);

  const sdRecordOptions = [
    {
      value: '',
      label: t('Common.All')
    },
    {
      value: 'mass',
      label: t('Regulatory.TMADeclarationTable.Mass')
    },
    {
      value: 'comment',
      label: t('Regulatory.TMADeclarationTable.Comment')
    }
  ];

  useEffect(() => {
    setTmaData([sdmData, sdcData].flat());
  }, [sdcData, sdmData]);

  useEffect(() => {
    setFilteredTMAData(
      filterTMAData({
        tmaData,
        debouncedSearchText,
        sdDataRecordTypeFilter,
        registrationFilter,
        registrations
      })
    );
  }, [tmaData, debouncedSearchText, sdDataRecordTypeFilter, registrationFilter, registrations]);

  useEffect(() => {
    registrations &&
      setRegistrationFilterOptions([
        {
          value: '',
          label: 'All'
        },
        ...registrations.map(reg => ({
          value: reg.vehicleId,
          label: reg.registration
        }))
      ]);
  }, [registrations]);

  const onSearch = value => {
    setSearchValue(value);
  };

  const disabledDate = current => {
    if (!startDate && !endDate) {
      return current && current.isAfter(dayjs(), 'day');
    }

    if (startDate && !endDate) {
      const startPlus30 = startDate.clone().add(30, 'days');
      const maxEndDate = startPlus30.isAfter(dayjs()) ? dayjs() : startPlus30;
      return current && current.isAfter(maxEndDate, 'day');
    }

    if (!startDate && endDate) {
      const endDateMinus30 = endDate.clone().subtract(30, 'days');
      return current && current.isBefore(endDateMinus30, 'day');
    }
  };

  const handleDateChange = dates => {
    if (dates && dates.length === 2) {
      setDateRange([dayjs(dates[0]).startOf('day'), dayjs(dates[1]).endOf('day')]);
      setStartDate(dates[0]);
      setEndDate(dates[1]);
      setIsDatePickerOpen(false);
    } else {
      setDateRange([]);
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleCalendarChange = dates => {
    if (dates && dates[0]) {
      setStartDate(dates[0]);
    } else if (dates && dates[1]) {
      setEndDate(dates[1]);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleOpenChange = open => {
    if (open) {
      setTempDateRange(dateRange);
      setDateRange([]);
      setStartDate(null);
      setEndDate(null);
      setIsDatePickerOpen(open);
    } else {
      if (dateRange.length === 0 && tempDateRange.length === 2) {
        setDateRange(tempDateRange);
        setStartDate(tempDateRange[0]);
        setEndDate(tempDateRange[1]);
        setIsDatePickerOpen(open);
      }
    }
  };

  const handleOpenFormModal = () => {
    setIsFormModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen(false);
  };

  const handleOpenBatchModal = () => {
    setIsBatchModalOpen(true);
  };

  useEffect(() => {
    dispatch(setPageTitle(t('Regulatory.TMADeclaration')));
    dispatch(setBackButton(true));
  }, [t, dispatch]);

  return (
    <>
      <Divider className={styles.divider} />
      <div className={styles.headerActions}>
        <Tooltip placement="bottom" title={t('Regulatory.NewIndividualDeclarationTooltip')}>
          <Button type="primary" size="large" onClick={handleOpenFormModal}>
            {t('Regulatory.NewIndividualDeclaration')}
          </Button>
        </Tooltip>
        <Tooltip
          overlayClassName={styles.batchTcmTooltip}
          placement="bottom"
          title={`${t('Regulatory.BatchTCMTooltip')} ${
            !canBulkImport ? `${'\n'}${t('Regulatory.BatchTCMTooltipPermission')}` : ''
          }`}
        >
          <Button
            type="secondary"
            size="large"
            onClick={handleOpenBatchModal}
            disabled={!canBulkImport}
          >
            {t('Regulatory.BatchTCM')}
          </Button>
        </Tooltip>
        <NewDeclarationModal isFormModalOpen={isFormModalOpen} onClose={handleCloseFormModal} />
        <BulkManagementModal
          show={isBatchModalOpen}
          handleShow={setIsBatchModalOpen}
          templates={templates}
          isTmaBatch={true}
        />
      </div>
      <div className={styles.filterWrapper}>
        <Tooltip title={t('Regulatory.SearchTooltip')}>
          <AntSearchbar className={styles.filterElement} onFilter={onSearch} size={'default'} />
        </Tooltip>
        <Select
          className={styles.statusesSelect}
          onSelect={value => setRegistrationFilter(value)}
          options={registrationsFilterOptions}
          placeholder={t('Regulatory.Registration')}
        />
        <Select
          className={styles.statusesSelect}
          options={sdRecordOptions}
          onSelect={value => setSdDataRecordTypeFilter(value)}
          placeholder={t('Regulatory.SDRecordType')}
        />
        <DatePicker.RangePicker
          value={dateRange}
          format={localization.formats.time.formats.dby}
          disabledDate={disabledDate}
          onCalendarChange={handleCalendarChange}
          onChange={handleDateChange}
          onOpenChange={handleOpenChange}
        />
      </div>
      <div className={styles.tableWrapper}>
        <TMADeclarationTable
          isDataLoading={isSDMDataLoading || isSDCDataLoading}
          registrations={registrations}
          tmaData={filteredTMAData}
        />
      </div>
    </>
  );
};
