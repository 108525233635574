import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const companiesDataAdapter = reduxAdapter({
  persist: async companies => {
    if (companies && companies.length) {
      for (const c of companies) {
        if (c.id) {
          await TNDataService.insertOrUpdateCompany(c);
        }
      }
    }
  },
  load: async () => {
    const companies = await TNDataService.getAllCompanies();
    companies.forEach(c => {
      if (c.parent?.id) {
        const parentCompany = companies.find(p => p.id === c.parent.id);
        if (parentCompany) {
          c.parent = parentCompany;
        }
      }
    });
    return companies;
  }
});

export { companiesDataAdapter };
