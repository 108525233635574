import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Space, Typography, Switch } from 'antd';
import styles from '../VehiclesBulkEdit.module.scss';

export const EldVehicleSwitchModal = ({ show, onOk, onCancel }) => {
  const { t } = useTranslation();
  const [isEld, setIsEld] = useState(false);

  return (
    <Modal
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(isEld);
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{t(`Vehicles.Form.IsEldVehicle`)}</Typography.Title>

        <Switch onChange={setIsEld} />
      </Space>
    </Modal>
  );
};
