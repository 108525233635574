import React, { useRef, useMemo, useCallback, useState } from 'react';
import { useLocation } from 'react-router';

import { useWalkMeInFrame } from 'features/walkme/walkmeHooks';
import { useUser } from 'features/user/userSlice';
import { AuthUtil } from 'features/auth/authUtil';

import { Iframe } from 'components/iframe/Iframe';
import { Loading } from 'components/loading/Loading';
import { EventDetails } from './EventDetails/EventDetails';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

import styles from './ViewCameraEvents.module.scss';

function _ViewCameraEvents(props) {
  const userInfo = useUser();
  const location = useLocation();

  const frameRef = useRef(null);
  const [loadComplete, setLoadComplete] = useState(false);

  useWalkMeInFrame(frameRef);

  const eventUrl = useMemo(() => {
    const link =
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent(location.pathname.replace('/view_events', '') + location.search)}`;
    return link;
  }, [userInfo, location]);

  const handleOnLoad = useCallback(() => {
    setLoadComplete(true);
  }, []);

  const canEventTagging = useCanFeatureFlag({ featureFlag: FeatureFlag.eventTagging.flag });

  return canEventTagging ? (
    <EventDetails
      frameRef={frameRef}
      eventUrl={eventUrl}
      iframeLoaded={loadComplete}
      onIframeLoad={handleOnLoad}
    />
  ) : (
    <div
      style={{
        width: '1',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 0',
        position: 'relative'
      }}
    >
      {!loadComplete && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 0',
            width: '100%',
            height: '100%',
            background: 'white'
          }}
        >
          <Loading />
        </div>
      )}
      <Iframe
        ref={frameRef}
        src={eventUrl}
        className={styles.container}
        onLoad={handleOnLoad}
        allowFullScreen="true"
      ></Iframe>
    </div>
  );
}

export const ViewCameraEvents = React.memo(_ViewCameraEvents);
