import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Space, Typography, Select, DatePicker } from 'antd';
import styles from '../VehiclesBulkEdit.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import moment from 'moment';

export const ServiceDatePickerModal = ({ fieldProp, show, onOk, onCancel }) => {
  const { t } = useTranslation();
  const [serviceAt, setServiceAt] = useState();
  const localization = useLocalization();

  return (
    <Modal
      okButtonProps={{
        disabled: !serviceAt
      }}
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(serviceAt);
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{`${fieldProp.title}`}</Typography.Title>
        <DatePicker
          showTime={true}
          style={{ width: '100%' }}
          format={localization.formats.time.formats.dby_imp}
          onChange={(date, dateString) => {
            setServiceAt(moment(date).toISOString());
          }}
        />
      </Space>
    </Modal>
  );
};
