import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { trigger, CustomEvents } from 'utils/hooks/useCustomEventListener';
import { useTranslation } from 'react-i18next';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { Button, Space } from 'antd';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import entities from 'features/permissions/entities';
import { capitalize } from 'lodash';

const BulkEditContext = createContext(null);

export const BulkEditProvider = ({ onDelete, children, pageTitle, entity, onSave, fetchAudit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);
  const [isBulkUpdating, setIsBulkUpdating] = useState(false);
  const [isBulkEditMode, setIsBulkEditMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [errors, setErrors] = useState({});

  const entityName = {
    [entities.VEHICLE]: {
      singular: t('Vehicles.Vehicle'),
      plural: t('Vehicles.Vehicles')
    },
    [entities.USER]: {
      singular: t('Users.User'),
      plural: t('Users.Users')
    }
  };

  const toggleBulkEditMode = () => {
    if (!isBulkEditMode) {
      trigger(CustomEvents.autoCloseSidebar);
    }
    setIsBulkEditMode(prev => !prev);
  };

  const exitBulkEdit = useCallback(
    (afterExit = () => {}) => {
      const isTableTouched = !!selectedRowKeys.length;
      if (isTableTouched) {
        const confirmModalProps = {
          title: t('RouteGuard.Title'),
          message: t('RouteGuard.Message'),
          leaveBtnText: t('RouteGuard.LeavePage'),
          stayBtnText: t('RouteGuard.StayOnPage')
        };
        confirmationModal(
          confirmModalProps.title,
          confirmModalProps.message,
          confirmModalProps.leaveBtnText,
          confirmModalProps.stayBtnText,
          () => {
            if (afterExit) {
              afterExit();
            }
            setIsBulkEditMode(false);
            setSelectedRowKeys([]);
            setErrors({});
          },
          'warning'
        );
      } else {
        if (afterExit) {
          afterExit();
        }
        setIsBulkEditMode(false);
        setSelectedRowKeys([]);
        setErrors({});
      }
    },
    [dispatch, selectedRowKeys]
  );

  useEffect(() => {
    if (isBulkEditMode) {
      dispatch(
        setPageTitle(
          <Space size={4}>
            <Button
              type="text"
              onClick={() => exitBulkEdit()}
              icon={<i className="tn-i-chevron-left"></i>}
              style={{ fontSize: '24px' }}
            />
            {t('Common.BulkEdit')}
          </Space>
        )
      );
    } else {
      dispatch(setBackButton(false));
      dispatch(setPageTitle(pageTitle));
    }
  }, [dispatch, t, isBulkEditMode, exitBulkEdit]);

  const toggleHeaderSelection = allRowIds => {
    if (selectedRowKeys.length !== (allRowIds || []).length) {
      setSelectedRowKeys(allRowIds || []);
    } else {
      setSelectedRowKeys([]);
    }
  };
  const toggleRowSelection = id => {
    setSelectedRowKeys(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const clearError = () => {
    setErrors({});
  };

  const handleBulkDelete = () => {
    let questionParam = {};

    questionParam.name = `${selectedRowKeys.length} ${
      selectedRowKeys.length > 1 ? entityName[entity]?.plural : entityName[entity]?.singular
    }`;

    let deleteQuestion = t('Common.Modal.SureQuestion', questionParam);

    confirmationModal(
      t('Common.Modal.SureTitle'),
      deleteQuestion,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      () => {
        setIsBulkDeleting(true);
        dispatch(
          onDelete(selectedRowKeys, ({ successCount, failedCount, errorMap }) => {
            setErrors(errorMap);
            const allSuccess = successCount === selectedRowKeys.length;
            const allFailed = !successCount;

            const type = t(`Entity.${capitalize(entity)}`);

            dispatch(
              openToast({
                type: allSuccess
                  ? ToastType.Success
                  : allFailed
                  ? ToastType.Error
                  : ToastType.Warning,
                message: allSuccess
                  ? t('BulkEdit.Notifications.DeleteSuccess', { successCount, type })
                  : allFailed
                  ? t('BulkEdit.Notifications.DeleteFailed', { failedCount, type })
                  : t('BulkEdit.Notifications.PartialDeleteSuccess', {
                      successCount,
                      failedCount,
                      type
                    })
              })
            );

            if (!allFailed && fetchAudit) {
              fetchAudit();
            }
            setSelectedRowKeys([]);
          })
        ).then(() => {
          setIsBulkDeleting(false);
        });
      },
      'delete'
    );
  };

  const handleBulkEdit = data => {
    if (!!selectedRowKeys.length) {
      let entityString = {};

      const count = data.length;
      entityString = count > 1 ? entityName[entity]?.plural : entityName[entity]?.singular;

      const type = t(`Entity.${capitalize(entity)}`);

      confirmationModal(
        t('Common.Modal.SureTitle'),
        t('CompanyConfig.IQCamera.BULK_EDIT.SureBulkEdit', {
          count,
          entity: entityString
        }),
        t('Common.Modal.Confirm'),
        t('Common.Modal.Cancel'),
        () => {
          setIsBulkUpdating(true);
          dispatch(
            onSave(data, ({ successCount, failedCount, errorMap }) => {
              setErrors(errorMap);
              const allSuccess = successCount === data.length;
              const allFailed = !successCount;
              dispatch(
                openToast({
                  type: allSuccess
                    ? ToastType.Success
                    : allFailed
                    ? ToastType.Error
                    : ToastType.Warning,
                  message: allSuccess
                    ? t('BulkEdit.Notifications.UpdateSuccess', { successCount, type })
                    : allFailed
                    ? t('BulkEdit.Notifications.UpdateFailed', { failedCount, type })
                    : t('BulkEdit.Notifications.PartialUpdateSuccess', {
                        successCount,
                        failedCount,
                        type
                      })
                })
              );
              setSelectedRowKeys([]);

              if (!allFailed && fetchAudit) {
                fetchAudit();
              }
            })
          ).then(() => {
            setIsBulkUpdating(false);
          });
        },
        'warning'
      );
    }
  };

  return (
    <BulkEditContext.Provider
      value={{
        isBulkDeleting,
        isBulkUpdating,
        isBulkEditMode,
        selectedRowKeys,
        errors,
        clearError,
        toggleHeaderSelection,
        toggleRowSelection,
        toggleBulkEditMode,
        handleBulkDelete,
        handleBulkEdit
      }}
    >
      {children}
    </BulkEditContext.Provider>
  );
};
export const useBulkEdit = () => useContext(BulkEditContext);
