import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Space, Typography, Select } from 'antd';
import styles from '../VehiclesBulkEdit.module.scss';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  getRegistrationCountryLabel,
  getRegistrationTerritoryLabel
} from 'features/localization/localization';
import { getCountryRegions, useCountryOptions } from 'utils/countryState';

export const RegistrationSelectModal = ({ show, onOk, onCancel }) => {
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const countriesOption = useCountryOptions();
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();

  useEffect(() => {
    const countryRegions = getCountryRegions({
      country: selectedCountry,
      addCountryAsRegion: true
    });
    setStates(countryRegions);
    setSelectedState(countryRegions.length === 1 ? countryRegions[0].value : '');
  }, [selectedCountry]);

  return (
    <Modal
      okButtonProps={{
        disabled: !selectedCountry || !selectedState
      }}
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk({ country: selectedCountry, state: selectedState });
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" className={styles.entitySelect}>
        <Space direction="vertical" size={0} className={styles.entitySelect}>
          <Typography.Title level={5}>{`${getRegistrationCountryLabel(
            currentCompany?.country
          )}`}</Typography.Title>
          <Select
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            value={selectedCountry}
            options={countriesOption}
            onChange={setSelectedCountry}
          />
        </Space>
        <Space direction="vertical" size={0} className={styles.entitySelect}>
          <Typography.Title level={5}>{`${getRegistrationTerritoryLabel(
            currentCompany?.country
          )}`}</Typography.Title>
          <Select
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            value={selectedState}
            options={states}
            onChange={setSelectedState}
          />
        </Space>
      </Space>
    </Modal>
  );
};
