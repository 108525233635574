import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const permissionsDataAdapter = reduxAdapter({
  persist: async permissions => {
    if (permissions && permissions.length) {
      for (const p of permissions) {
        if (p.id) {
          await TNDataService.insertOrUpdatePermission(p);
        }
      }
    }
  },
  load: async () => {
    const permissions = await TNDataService.getAllPermissions();
    return permissions;
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_permissions');
    return result;
  },
  updateLastUpdatedDate: async lastUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_permissions', lastUpdatedDate);
    return result;
  }
});

export { permissionsDataAdapter };
