import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Space, Typography } from 'antd';
import { Select } from 'components/ant';
import { useVehicleModels, useIsFetching } from 'features/vehicles/vehicleModels';
import styles from '../VehiclesBulkEdit.module.scss';
import { convertToOptionList } from '../../helpers';

export const ModelSelectModal = ({ companyId, show, onOk, onCancel }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();
  const [selectedYearValue, setSelectedYearValue] = useState(null);
  const [selectedMakeValue, setSelectedMakeValue] = useState(null);
  const [selectedModelValue, setSelectedModelValue] = useState(null);
  const [makeModelYearList, setMakeModelYearList] = useState([]);
  const makeModelYearFullList = useVehicleModels(companyId);
  const isFetchingVehicleModels = useIsFetching();

  useEffect(() => {
    if (makeModelYearFullList && !isFetchingVehicleModels) {
      const source = 'internal';
      setMakeModelYearList(
        (makeModelYearFullList || []).filter(
          i =>
            source === undefined ||
            i.source?.toLowerCase() === source?.toLowerCase() ||
            i.displaySource?.toLowerCase() === source?.toLowerCase()
        )
      );
    }
  }, [makeModelYearFullList, isFetchingVehicleModels]);

  const isYearSelected = useCallback(() => {
    return !!selectedYearValue;
  }, [selectedYearValue]);

  const fullValues = useMemo(() => {
    const makeSet = new Set();
    const modelSet = new Set();
    const yearSet = new Set();

    (makeModelYearList || []).map(makeModelYear => {
      const makeMatch = !selectedMakeValue || selectedMakeValue === makeModelYear.make;
      const modelMatch = !selectedModelValue || selectedModelValue === makeModelYear.model;
      const yearMatch = !selectedYearValue || selectedYearValue === makeModelYear.yearOfManufacture;

      if (makeMatch && yearMatch) {
        modelSet.add(makeModelYear.model);
      }

      if (modelMatch && yearMatch) {
        makeSet.add(makeModelYear.make);
      }

      if (modelMatch && makeMatch && makeModelYear.yearOfManufacture) {
        yearSet.add(makeModelYear.yearOfManufacture);
      }
    });

    return {
      make: convertToOptionList(makeSet),
      model: convertToOptionList(modelSet),
      year: convertToOptionList(yearSet)
    };
  }, [makeModelYearList, selectedMakeValue, selectedModelValue, selectedYearValue]);

  useEffect(() => {
    if (selectedMakeValue && selectedModelValue && isYearSelected()) {
      const modelId = makeModelYearList.find(
        makeModelYear =>
          makeModelYear.make === selectedMakeValue &&
          makeModelYear.model === selectedModelValue &&
          (makeModelYear.displaySource || makeModelYear.source || null) === 'internal' &&
          makeModelYear.yearOfManufacture === parseInt(selectedYearValue, 0)
      )?.id;

      setValue(modelId);
    } else {
      setValue(undefined);
    }
  }, [makeModelYearList, selectedMakeValue, selectedModelValue, selectedYearValue]);

  return (
    <Modal
      okButtonProps={{ disabled: !value }}
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(value);
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" className={styles.entitySelect}>
        <Space direction="vertical" size={0} className={styles.entitySelect}>
          <Typography.Title level={5}>{`${t('Vehicles.Form.Make')}`}</Typography.Title>
          <Select
            name="make"
            placeholder={t('Vehicles.Form.MakePlaceholder')}
            value={selectedMakeValue}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            onSelect={setSelectedMakeValue}
            data={isFetchingVehicleModels ? [] : fullValues.make}
            onClear={setSelectedMakeValue}
            allowClear
            loading={isFetchingVehicleModels}
          />
        </Space>
        <Space direction="vertical" size={0} className={styles.entitySelect}>
          <Typography.Title level={5}>{`${t('Vehicles.Form.Model')}`}</Typography.Title>
          <Select
            name="model"
            placeholder={t('Vehicles.Form.ModelPlaceholder')}
            value={selectedModelValue}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            onSelect={setSelectedModelValue}
            allowClear
            onClear={setSelectedModelValue}
            data={isFetchingVehicleModels ? [] : fullValues.model}
            loading={isFetchingVehicleModels}
          />
        </Space>
        <Space direction="vertical" size={0} className={styles.entitySelect}>
          <Typography.Title level={5}>{`${t('Vehicles.Form.Year')}`}</Typography.Title>
          <Select
            name="year"
            placeholder={t('Vehicles.Form.YearPlaceholder')}
            value={selectedYearValue}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            allowClear={true}
            onSelect={setSelectedYearValue}
            onClear={setSelectedYearValue}
            loading={isFetchingVehicleModels}
            data={isFetchingVehicleModels ? [] : fullValues.year}
          />
        </Space>
      </Space>
    </Modal>
  );
};
