export function carriersObjectToDBO(carrier) {
  return {
    id: carrier.id,
    company_id: carrier.company?.id,
    company_version: carrier.company?.version,
    name: carrier.name,
    official_identifer: carrier.officialIdentifer,
    country: carrier.country,
    state: carrier.state,
    city: carrier.city,
    postcode: carrier.postcode,
    address1: carrier.address1,
    address2: carrier.address2,
    address3: carrier.address3,
    address4: carrier.address4,
    created_at: carrier.createdAt,
    updated_at: carrier.updatedAt,
    version: carrier.version,
    county: carrier.county,
    status: carrier.status,
    dot_number: carrier.dotNumber
  };
}

export function carriersDBOToObject(carrier) {
  return {
    id: carrier.id,
    company_id: carrier.company_id,
    company_version: carrier.company_version,
    company: {
      id: carrier.company_id,
      version: carrier.company_version
    },
    name: carrier.name,
    officialIdentifer: carrier.official_identifer,
    country: carrier.country,
    state: carrier.state,
    city: carrier.city,
    postcode: carrier.postcode,
    address1: carrier.address1,
    address2: carrier.address2,
    address3: carrier.address3,
    address4: carrier.address4,
    createdAt: carrier.created_at,
    updatedAt: carrier.updated_at,
    version: carrier.version,
    county: carrier.county,
    status: carrier.status,
    dotNumber: carrier.dot_number
  };
}
