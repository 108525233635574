import { useDispatch, useSelector } from 'react-redux';
import { fetchVehicleModels } from './thunks';
import { vehicleModelsSelectors } from './';

export const useVehicleModels = companyId => {
  const dispatch = useDispatch();
  const vehicleModels = useSelector(vehicleModelsSelectors.getVehicleModels);

  const isFetching = useSelector(vehicleModelsSelectors.isFetching);
  const isSuccess = useSelector(vehicleModelsSelectors.isSuccess);
  const isError = useSelector(vehicleModelsSelectors.isError);
  const isFetchComplete = isSuccess || isError;

  if ((!isFetchComplete || !vehicleModels[companyId]) && !isFetching) {
    dispatch(fetchVehicleModels({ query: { company_id: companyId } }));
  }

  return vehicleModels[companyId] || [];
};

export const useIsFetching = () => useSelector(vehicleModelsSelectors.isFetching);
