import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { List, AutoSizer } from 'react-virtualized';
import { Button, Divider, Empty, Space } from 'antd';
import { Spinner } from 'containers/home/Camera/Components/Loading';
import { SnapshotRow } from './SnapshotRow';
import { sizeCalculator } from '../helpers';
import styles from '../Snapshots.module.scss';
import { debounce } from 'lodash';

function _SnapshotTable({
  isLoading,
  snapshots,
  snapshotYear,
  snapshotMonth,
  onMessageVehicle,
  onRequestFootage,
  snapshotsCount = 0,
  onLoadMore,
  allLoaded
}) {
  const scrollRef = useRef();
  const onDebouncedScoll = useCallback(
    debounce(({ clientHeight, scrollHeight, scrollTop }) => {
      if (scrollRef.current) {
        const isScrollToBottom =
          scrollRef.current.scrollTop < scrollTop &&
          clientHeight + scrollTop >= scrollHeight * 0.75;
        if (isScrollToBottom && !allLoaded) {
          onLoadMore();
        }
      }
      scrollRef.current = { clientHeight, scrollHeight, scrollTop };
    }, 200),
    [scrollRef, allLoaded, onLoadMore]
  );

  const { t } = useTranslation();

  function rowRenderer({ index, style, key, parent }) {
    if (index >= snapshots.length) {
      return (
        <div key={key} style={style} className={styles.alignItemCenter}>
          {isLoading ? (
            <Spinner size="small" desciption={t('Home.Loading')} />
          ) : (
            <Divider style={{ color: '#86b341' }} plain={!!allLoaded}>
              <Space>
                {`${snapshotsCount} ${t('Home.Snapshots')}`}
                {!allLoaded && (
                  <Button type="primary" size="small" onClick={onLoadMore}>
                    {t('Common.Load More')}
                  </Button>
                )}
              </Space>
            </Divider>
          )}
        </div>
      );
    }
    const item = snapshots[index];
    return (
      <SnapshotRow
        t={t}
        key={key}
        style={style}
        width={parent.props.width}
        positionedFootages={item.positionedFootages}
        vehicleId={item.vehicleId}
        deviceId={item.deviceId}
        deviceName={item.deviceName}
        driverId={item.driverId}
        driverLoginStatus={item.driverLoginStatus}
        nodeType={item.nodeType}
        devices={item.devices}
        deviceModelName={item.deviceModelName}
        deviceTypeCode={item.deviceTypeCode}
        messagingDevice={item.messagingDevice}
        snapshotYear={snapshotYear}
        snapshotMonth={snapshotMonth}
        onMessageVehicle={onMessageVehicle}
        onRequestFootage={onRequestFootage}
      />
    );
  }

  return (
    <div className={styles.snapshotsTableContainer}>
      {snapshotsCount ? (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className={styles.snapshotsTable}
              rowCount={snapshots.length + 1}
              height={height}
              width={width - 10}
              rowHeight={sizeCalculator(width).rowHeight}
              overscanRowCount={3}
              rowRenderer={rowRenderer}
              onScroll={onDebouncedScoll}
            />
          )}
        </AutoSizer>
      ) : isLoading ? (
        <div className={styles.loading}>
          <Spinner size="large" desciption={t('Home.Loading')} />
        </div>
      ) : (
        <Empty description={t('Home.No Snapshots')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
}

export const SnapshotsTable = React.memo(_SnapshotTable);
