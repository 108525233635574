import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Row, Col, Empty } from 'antd';
import { Spinner } from 'containers/home/Camera/Components/Loading';
import { EventPreview } from './EventPreview';
import styles from './GridView.module.scss';
import { useTranslation } from 'react-i18next';

export function GridView({
  events,
  totalCount,
  isLoading,
  onLoadMoreData,
  onRequestVideo,
  onViewEvent,
  onEventTagsUpdated,
  onEventSeverityUpdated,
  ...props
}) {
  const [pseudoEvents, setPseudoEvents] = useState([]);
  const [pseudoStyle, setPseudoStyle] = useState(null);
  const containerRef = useRef(null);
  const { t } = useTranslation();

  const calculatePseudoEvents = useCallback(events => {
    const containerWidth = containerRef.current.offsetWidth;
    const cardItem = containerRef.current?.querySelector('.ant-col');
    const cardWidth = cardItem.offsetWidth;
    const maxRowCount = parseInt(containerWidth / cardWidth);

    if (maxRowCount > 1 && events.length > 0) {
      const remaindCount = events.length % maxRowCount;
      if (remaindCount === 0) {
        setPseudoEvents([]);
      } else {
        setPseudoEvents(new Array(maxRowCount - remaindCount).fill(1));
        setPseudoStyle({
          width: cardWidth + 'px'
        });
      }
    }
  }, []);

  useEffect(() => {
    let handleResizeFn = null;
    if (events?.length && containerRef.current) {
      handleResizeFn = () => calculatePseudoEvents(events);
      handleResizeFn();
      window.addEventListener('resize', handleResizeFn);
    } else {
      setPseudoEvents([]);
      setPseudoStyle(null);
    }

    return () => {
      if (handleResizeFn) {
        window.removeEventListener('resize', handleResizeFn);
      }
    };
  }, [events]);

  const handleScroll = useCallback(
    evt => {
      if (
        containerRef.current.scrollTop > 0 &&
        containerRef.current.scrollHeight > 0 &&
        containerRef.current.scrollTop + containerRef.current.offsetHeight >=
          containerRef.current.scrollHeight * 0.75
      ) {
        if (onLoadMoreData) {
          onLoadMoreData();
          containerRef.current.removeEventListener('scroll', handleScroll);
        }
      }
    },
    [onLoadMoreData]
  );

  useEffect(() => {
    if (containerRef.current !== null && events?.length < totalCount) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [events, totalCount, handleScroll]);

  return (
    <div
      ref={containerRef}
      id="eventsGridView"
      className={styles.gridView + ' showScrollbarsOnHover'}
    >
      <Row justify="space-evenly" gutter={[16, 16]}>
        {events?.map(e => (
          <Col key={e.id}>
            <EventPreview
              key={e.id}
              event={e}
              onRequestVideo={onRequestVideo}
              onViewEvent={onViewEvent}
              onTagsUpdated={onEventTagsUpdated}
              onSeverityUpdated={onEventSeverityUpdated}
            />
          </Col>
        ))}
        {pseudoEvents.map((e, i) => (
          <Col key={i} style={pseudoStyle}></Col>
        ))}
      </Row>
      {!isLoading && !events?.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {isLoading && (
        <div className={styles.loading}>
          <Spinner size="large" desciption={t('Home.Loading')} />
        </div>
      )}
    </div>
  );
}
