import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, List } from 'antd';
import { Can } from 'features/permissions';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { TILES, TILES_DIRECTION, TILES_GRID_SYSTEM, TYLE_LIST_STYLE } from './constants';
import imgRegulatory from './Images/feature-regulatory.svg';

import styles from './Regulatory.module.scss';

const { Meta } = Card;

export const Regulatory = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(t('Regulatory.Regulatory')));
    dispatch(setBackButton(false));
  }, [t, dispatch]);

  return (
    <List
      style={TYLE_LIST_STYLE}
      grid={TILES_GRID_SYSTEM}
      itemLayout={TILES_DIRECTION}
      dataSource={TILES()}
      renderItem={tile => (
        <List.Item key={tile.translationKey}>
          <Can {...tile.permissions}>
            <Card
              className={styles.dashboardCard}
              cover={<img alt={tile.title} src={tile.img || imgRegulatory} />}
              hoverable
              onClick={() => history.push(tile.path)}
            >
              <Meta title={t(tile.translationKey)} />
            </Card>
          </Can>
        </List.Item>
      )}
    ></List>
  );
};
