import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { useUserKey } from 'features/user/userSlice';
import request from 'superagent';
import { deviceModelsDataAdapter } from 'data/dataService/tn/adapter/deviceModels';
import { FeatureFlag, useCanOneOfFeatureFlags } from 'features/permissions';
import { useMemo } from 'react';
import { filter } from 'lodash';

const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

export const deviceModelsSlice = createSlice({
  name: 'device_models',
  initialState: {
    data: {},
    list: [],
    status: {
      fetching: actionTypes.init,
      error: null
    }
  },
  reducers: {
    fetchDeviceModelsStart(state) {
      state.status.fetching = actionTypes.processing;
      state.status.error = null;
    },
    fetchDeviceModelsSucceeded(state, { payload }) {
      if (payload != null && payload.length) {
        let DeviceModels = {};
        payload.forEach(i => (DeviceModels[i.name] = i));
        state.data = DeviceModels;
        state.list = payload;
      } else {
        state.data = payload;
        state.list = payload;
      }

      state.status.fetching = actionTypes.done;
    },
    fetchDeviceModelsFailed(state, { payload }) {
      state.status.error = payload;
      state.status.fetching = actionTypes.done;
    }
  }
});

export const {
  fetchDeviceModelsStart,
  fetchDeviceModelsSucceeded,
  fetchDeviceModelsFailed
} = deviceModelsSlice.actions;

export const fetchDeviceModels = userKey => async dispatch => {
  dispatch(fetchDeviceModelsStart());

  if (userKey) {
    const promise = new Promise((resolve, reject) => {
      deviceModelsDataAdapter.load().then(deviceModels => {
        if (deviceModels?.length > 0) {
          resolve(deviceModels);
        } else {
          request('GET', `${API_PATH}/devices/models?pruning=APP`)
            .set('Authorization', `Token token="${userKey}"`)
            .set('Content-Type', 'application/json')
            .then(resp => {
              deviceModelsDataAdapter.persist(resp.body);
              resolve(resp.body);
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    });

    try {
      const data = await promise;
      dispatch(fetchDeviceModelsSucceeded(data));
    } catch (err) {
      dispatch(fetchDeviceModelsFailed(err));
    }
  }
};

export const useDeviceModels = () => {
  const canEvoPhase1 = useCanOneOfFeatureFlags([
    FeatureFlag.evoPhase1.flag,
    FeatureFlag.evoPhaseTGE.flag
  ]);
  const models = useSelector(state => state.deviceModels);
  const flaggedDeviceModels = useMemo(
    () =>
      canEvoPhase1
        ? models
        : {
            ...models,
            list: filter(models.list, dModel => dModel.name !== 'MultiIQ'),
            data: filter(models.data, dModel => dModel.name !== 'MultiIQ')
          },
    [(models, canEvoPhase1)]
  );
  return flaggedDeviceModels;
};
export const useDeviceModelsList = () => {
  const userKey = useUserKey();
  const dispatch = useDispatch();
  const deviceModelsList = useSelector(state => state.deviceModels.list);
  const fetchingStatus = useSelector(state => state.deviceModels.status.fetching);
  if (deviceModelsList.length === 0 && fetchingStatus !== actionTypes.processing) {
    dispatch(fetchDeviceModels(userKey));
  }
  const canEvoPhase1 = useCanOneOfFeatureFlags([
    FeatureFlag.evoPhase1.flag,
    FeatureFlag.evoPhaseTGE.flag
  ]);
  const flaggedDeviceModelsList = useMemo(
    () =>
      canEvoPhase1
        ? deviceModelsList
        : deviceModelsList.filter(dModel => dModel.name !== 'MultiIQ'),
    [deviceModelsList, canEvoPhase1]
  );
  return flaggedDeviceModelsList;
};
export const useIsFetchingDeviceModels = () => {
  const fetchingStatus = useSelector(state => state.deviceModels.status.fetching);
  return fetchingStatus === actionTypes.processing;
};

export default deviceModelsSlice.reducer;
