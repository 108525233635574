export function geofenceObjectToDBO(geofence) {
  return {
    id: geofence.id,
    name: geofence.name,
    path: geofence.path,
    version: geofence.version,
    company_id: geofence.companyId,
    location_id: geofence.location?.id,
    external_id: geofence.externalId,
    status: geofence.status,
    doc_id: geofence.docId,
    created_at: geofence.createdAt,
    updated_at: geofence.updatedAt,
    style: geofence.style,
    shape: geofence.shape ? JSON.stringify(geofence.shape) : null,
    thrsh_overtime: geofence.thrshOvertime,
    thrsh_undertime: geofence.thrshUndertime,
    thrsh_speed: geofence.thrshSpeed,
    offset_speed: geofence.offsetSpeed,
    thrsh_duration_speed: geofence.thrshDurationSpeed,
    centroid: geofence.centroid ? JSON.stringify(geofence.centroid) : null,
    radius: geofence.radius,
    type: geofence.type,
    speed_assist: geofence.speedAssist,
    driver_notification: geofence.driverNotification,
    features: JSON.stringify(geofence.features || []),
    shape2: geofence.shape2 ? JSON.stringify(geofence.shape2) : null,
    active_time: geofence.activeTime,
    time_zone: geofence.timeZone,
    expires_at: geofence.expiresAt,
    area_sqm: geofence.area_sqm
  };
}

export function geofenceDBOToObject(geofence) {
  return {
    id: geofence.id,
    name: geofence.name,
    path: geofence.path,
    version: geofence.version,
    companyId: geofence.company_id,
    location: { id: geofence.location_id },
    externalId: geofence.external_id,
    status: geofence.status,
    docId: geofence.doc_id,
    createdAt: geofence.created_at,
    updatedAt: geofence.updated_at,
    style: geofence.style,
    shape: geofence.shape ? JSON.parse(geofence.shape) : {},
    thrshOvertime: geofence.thrsh_overtime,
    thrshUndertime: geofence.thrsh_undertime,
    thrshSpeed: geofence.thrsh_speed,
    offset_speed: geofence.offsetSpeed,
    thrshDurationSpeed: geofence.thrsh_duration_speed,
    centroid: geofence.centroid ? JSON.parse(geofence.centroid) : undefined,
    radius: geofence.radius,
    type: geofence.type,
    speedAssist: geofence.speed_assist,
    driverNotification: geofence.driver_notification,
    features: JSON.parse(geofence.features),
    shape2: geofence.shape2 ? JSON.parse(geofence.shape2) : undefined,
    activeTime: geofence.active_time,
    timeZone: geofence.time_zone,
    expiresAt: geofence.expires_at,
    area_sqm: geofence.area_sqm
  };
}

export function geofenceAssociationObjectToDBO(geofenceAssociation) {
  return {
    geofence_id: geofenceAssociation.geofenceId,
    fleet_id: geofenceAssociation.fleetId,
    vehicle_id: geofenceAssociation.vehicleId,
    device_id: geofenceAssociation.deviceId,
    created_at: geofenceAssociation.createdAt,
    updated_at: geofenceAssociation.updatedAt
  };
}

export function geofenceAssociationDBOToObject(geofenceAssociation) {
  return {
    geofenceId: geofenceAssociation.geofence_id,
    fleetId: geofenceAssociation.fleet_id,
    vehicleId: geofenceAssociation.vehicle_id,
    deviceId: geofenceAssociation.deviceId,
    createdAt: geofenceAssociation.created_at,
    updatedAt: geofenceAssociation.updated_at
  };
}
