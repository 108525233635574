export const GlobalRoles = {
  TrackingOnlyAccess: 'Tracking only Access',
  Admin: 'Admin',
  Driver: 'Driver',
  Superuser: 'Superuser',
  User: 'User',
  SentinelAdmin: 'Sentinel Admin',
  SentinelUser: 'Sentinel User',
  Supervisor: 'Supervisor',
  SiteAdmin: 'Site Admin',
  Reseller: 'Reseller',
  SmartJobsCustomer: 'SmartJobs Customer',
  APRole: 'APRole',
  OWNER: 'OWNER',
  IQCameraUser: 'IQ Camera User',
  IQCameraAdministrator: 'IQ Camera Administrator',
  SubCompanyAdmin: 'Sub Company Admin',
  VLTAdmin: 'VLT Admin',
  VLTReadOnlyAdmin: 'VLT Read-Only Admin',
  VLTUser: 'VLT User'
};

export default Object.freeze(GlobalRoles);
