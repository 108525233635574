export function deviceStatsObjectToDBO(deviceStats) {
  return {
    device_id: deviceStats.deviceId,
    last_comm_at: deviceStats.lastCommAt,
    last_event: deviceStats.lastEvent,
    last_event_at: deviceStats.lastEventAt,
    odometer: deviceStats.odometer,
    ignition: deviceStats.ignition,
    created_at: deviceStats.createdAt,
    updated_at: deviceStats.updatedAt,
    obm: deviceStats.obm,
    gps_at: deviceStats.gpsAt,
    gps: deviceStats.gps ? JSON.stringify(deviceStats.gps) : null,
    position: deviceStats.position,
    current_user_id: deviceStats.currentUserId,
    current_user: deviceStats.currentUser ? JSON.stringify(deviceStats.currentUser) : null,
    location: deviceStats.location,
    obm_at: deviceStats.obmAt,
    sdm_tcm: deviceStats.sdmTcm,
    sdm_at: deviceStats.sdmAt,
    dev_app_version: deviceStats.devAppVersion,
    dev_app_info: deviceStats.devAppInfo,
    dev_version: deviceStats.devVersion,
    dev_info: deviceStats.devInfo,
    last_duress_id: deviceStats.lastDuressId,
    storage_info: deviceStats.storageInfo,
    runsheet_id: deviceStats.runsheetId,
    squarell_version: deviceStats.squarellVersion,
    squarell_info: deviceStats.squarellInfo,
    last_amqp_connect_id: deviceStats.lastAmqpConnectId,
    last_duress_at: deviceStats.lastDuressAt,
    is_shutdown: deviceStats.isShutdown,
    shutdown_at: deviceStats.shutdownAt,
    shutdown_odo: deviceStats.shutdownOdo,
    sim_network: deviceStats.simNetwork,
    dynamic_features: deviceStats.dynamicFeatures
      ? JSON.stringify(deviceStats.dynamicFeatures)
      : null,
    smartnav_info: deviceStats.smartnavInfo,
    movement: deviceStats.movement,
    last_vpm: deviceStats.lastVpm,
    last_vpm_at: deviceStats.lastVpmAt,
    last_edr: deviceStats.lastEdr,
    last_edr_at: deviceStats.lastEdrAt,
    last_ruc: deviceStats.lastRuc,
    last_ruc_at: deviceStats.lastRucAt,
    last_gpio: deviceStats.lastGpio,
    last_gpio_at: deviceStats.lastGpioAt,
    movement_at: deviceStats.movementAt,
    aux_info: deviceStats.auxInfo,
    trip_id: deviceStats.tripId,
    last_auth_at: deviceStats.lastAuthAt,
    current_vehicle_id: deviceStats.currentVehicleId,
    last_satellite_at: deviceStats.lastSatelliteAt,
    current_user2_id: deviceStats.currentUser2Id,
    last_trip_started_at: deviceStats.lastTripStartedAt
  };
}

export function deviceStatsDBOToObject(deviceStats) {
  return {
    deviceId: deviceStats.device_id,
    lastCommAt: deviceStats.last_comm_at,
    lastEvent: deviceStats.last_event,
    lastEventAt: deviceStats.last_event_at,
    odometer: deviceStats.odometer,
    ignition: deviceStats.ignition,
    createdAt: deviceStats.created_at,
    updatedAt: deviceStats.updated_at,
    obm: deviceStats.obm,
    gpsAt: deviceStats.gps_at,
    gps: deviceStats.gps ? JSON.parse(deviceStats.gps) : undefined,
    position: deviceStats.position,
    currentUserId: deviceStats.current_user_id,
    currentUser: deviceStats.current_user ? JSON.parse(deviceStats.current_user) : undefined,
    location: deviceStats.location,
    obmAt: deviceStats.obm_at,
    sdmTcm: deviceStats.sdm_tcm,
    sdmAt: deviceStats.sdm_at,
    devAppVersion: deviceStats.dev_app_version,
    devAppInfo: deviceStats.dev_app_info,
    devVersion: deviceStats.dev_version,
    devInfo: deviceStats.dev_info,
    lastDuressId: deviceStats.last_duress_id,
    storageInfo: deviceStats.storage_info,
    runsheetId: deviceStats.runsheet_id,
    squarellVersion: deviceStats.squarell_version,
    squarellInfo: deviceStats.squarell_info,
    lastAmqpConnectId: deviceStats.last_amqp_connect_id,
    lastDuressAt: deviceStats.last_duress_at,
    isShutdown: deviceStats.is_shutdown,
    shutdownAt: deviceStats.shutdown_at,
    shutdownOdo: deviceStats.shutdown_odo,
    simNetwork: deviceStats.sim_network,
    dynamicFeatures: deviceStats.dynamic_features
      ? JSON.parse(deviceStats.dynamic_features)
      : undefined,
    smartnavInfo: deviceStats.smartnav_info,
    movement: deviceStats.movement,
    lastVpm: deviceStats.last_vpm,
    lastVpmAt: deviceStats.last_vpm_at,
    lastEdr: deviceStats.last_edr,
    lastEdrAt: deviceStats.last_edr_at,
    lastRuc: deviceStats.last_ruc,
    lastRucAt: deviceStats.last_ruc_at,
    lastGpio: deviceStats.last_gpio,
    lastGpioAt: deviceStats.last_gpio_at,
    movementAt: deviceStats.movement_at,
    auxInfo: deviceStats.aux_info,
    tripId: deviceStats.trip_id,
    lastAuthAt: deviceStats.last_auth_at,
    currentVehicleId: deviceStats.current_vehicle_id,
    lastSatelliteAt: deviceStats.last_satellite_at,
    currentUser2Id: deviceStats.current_user2_id,
    lastTripStartedAt: deviceStats.last_trip_started_at
  };
}
