export function fleetObjectToDBO(fleet) {
  return {
    id: fleet.id,
    name: fleet.name,
    path: fleet.path,
    version: fleet.version,
    status: fleet.status,
    company_id: fleet.company.id,
    doc_id: fleet.docId,
    created_at: fleet.createdAt,
    updated_at: fleet.updatedAt,
    description: fleet.description,
    colour: fleet.colour,
    icon_type: fleet.iconType,
    carrier_id: fleet.carrier?.id,
    icon_size: fleet.iconSize,
    external_id: fleet.externalId
  };
}

export function fleetDBOToObject(fleet) {
  return {
    id: fleet.id,
    name: fleet.name,
    path: fleet.path,
    version: fleet.version,
    status: fleet.status,
    company: {
      id: fleet.company_id
    },
    docId: fleet.doc_id,
    createdAt: fleet.created_at,
    updatedAt: fleet.updated_at,
    description: fleet.description,
    colour: fleet.colour,
    iconType: fleet.icon_type,
    carrierId: fleet.carrier_id,
    iconSize: fleet.icon_size,
    externalId: fleet.external_id
  };
}

export function fleetVehicleObjectToDBO(fv) {
  return {
    fleet_id: fv.fleetId,
    vehicle_id: fv.vehicleId
  };
}

export function fleetVehicleDBOToObject(fv) {
  return {
    fleetId: fv.fleet_id,
    vehicleId: fv.vehicle_id
  };
}

export function fleetDeviceObjectToDBO(fd) {
  return {
    fleet_id: fd.fleetId,
    device_id: fd.deviceId
  };
}

export function fleetDeviceDBOToObject(fd) {
  return {
    fleetId: fd.fleet_id,
    deviceId: fd.device_id
  };
}
