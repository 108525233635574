export function roleObjectToDBO(role) {
  return {
    id: role.id,
    name: role.name,
    path: role.path,
    description: role.description,
    global: role.global,
    company_id: role.companyId,
    status: role.status,
    doc_id: role.docId,
    created_at: role.createdAt,
    updated_at: role.updatedAt,
    features: JSON.stringify(role.features),
    version: role.version,
    permissionIds: JSON.stringify(role.permissionIds)
  };
}

export function roleDBOToObject(role) {
  return {
    id: role.id,
    name: role.name,
    path: role.path,
    description: role.description,
    global: Boolean(role.global),
    companyId: role.company_id,
    status: role.status,
    docId: role.doc_id,
    createdAt: role.created_at,
    updatedAt: role.updated_at,
    features: JSON.parse(role.features),
    version: role.version,
    permissionIds: JSON.parse(role.permissionIds)
  };
}

export function rolePermissionDBOToObject(rolePermission) {
  return {
    roleId: rolePermission.role_id,
    permissionId: rolePermission.permission_id
  };
}

export function rolePermissionObjectToDBO(rolePermission) {
  return {
    role_id: rolePermission.roleId,
    permission_id: rolePermission.permissionId
  };
}
