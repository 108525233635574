import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Checkbox, Divider, Popover, Space, Tooltip } from 'antd';
import { FlagFilled, FlagOutlined } from '@ant-design/icons';
import { updateEventTags } from 'features/camera/cameraEventApi';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { EVENT_SEVERITY } from '../../constant';

import styles from './EventSeverity.module.scss';

const FLAG_SIZE = {
  small: 'small',
  large: 'large'
};

const SEVERITY_COLOR = {
  0: '#b0b7c8', //not set severity
  1: '#2c8ff9',
  2: '#ffab1d',
  3: '#ff454f'
};

export function EventSeverity({
  eventId,
  eventTimeAt,
  eventTags,
  eventSeverity,
  onSeverityUpdated = ({ eventId, severity }) => {},
  size = FLAG_SIZE.small,
  plain = false
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [severity, setSeverity] = useState(eventSeverity || 0);
  const [open, setOpen] = useState(false);
  const [checkedSeverity, setCheckedSeverity] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const reqCtxRef = useRef({
    request: null,
    updateTime: null,
    error: null
  });

  const handleEventSeverityChange = useCallback(async () => {
    const reqCtx = reqCtxRef.current;
    const onReqDone = error => {
      if (reqCtxRef.current.request?.abort) {
        reqCtxRef.current.request.abort();
      }
      reqCtxRef.current = {
        request: null,
        updateTime: moment().valueOf(),
        error: error || null
      };
      setIsUpdating(false);
      setOpen(false);
      setCheckedSeverity(null);
    };

    if (reqCtx.request?.abort) {
      reqCtx.request.abort();
    }
    const originalSeverity = isNaN(Number(severity)) ? 0 : Number(severity),
      targetSeverity = isNaN(Number(checkedSeverity)) ? 0 : Number(checkedSeverity);
    const getToastMessage = success =>
      t(
        `Event.Notifications.${
          originalSeverity === 0 && targetSeverity > 0
            ? success
              ? 'Added'
              : 'AddFailed'
            : originalSeverity > 0 && targetSeverity === 0
            ? success
              ? 'Deleted'
              : 'DeleteFailed'
            : success
            ? 'Updated'
            : 'UpdateFailed'
        }`,
        {
          name: t('Event.Incident')
        }
      );

    reqCtx.request = dispatch(
      updateEventTags({
        eventId,
        eventTimeAt,
        tags: eventTags,
        severity: targetSeverity,
        onSuccess: res => {
          const updatedSeverity = res?.severity || 0;
          onReqDone();
          setSeverity(updatedSeverity);
          dispatch(
            openToast({
              type: ToastType.Success,
              message: getToastMessage(true)
            })
          );
          if (onSeverityUpdated) {
            onSeverityUpdated({ eventId, severity: updatedSeverity });
          }
        },
        onError: errMsg => {
          onReqDone(errMsg);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: getToastMessage(false)
            })
          );
        }
      })
    );

    try {
      setIsUpdating(true);
      await reqCtx.request;
    } catch (e) {
      onReqDone(e);
    }
  }, [t, dispatch, eventId, eventTimeAt, eventTags, severity, checkedSeverity, onSeverityUpdated]);

  return plain ? (
    <Space>
      <SeverityPopover
        plain
        severity={severity}
        onSeverityChange={handleEventSeverityChange}
        isUpdating={isUpdating}
        placement={'bottomLeft'}
        size={size}
        open={open}
        setOpen={setOpen}
        checkedSeverity={checkedSeverity}
        setCheckedSeverity={setCheckedSeverity}
      />
      {!!severity && t(EVENT_SEVERITY[severity])}
    </Space>
  ) : (
    <SeverityPopover
      severity={severity}
      onSeverityChange={handleEventSeverityChange}
      isUpdating={isUpdating}
      placement={size === FLAG_SIZE.large ? 'bottomLeft' : 'bottomRight'}
      size={size}
      open={open}
      setOpen={setOpen}
      checkedSeverity={checkedSeverity}
      setCheckedSeverity={setCheckedSeverity}
    />
  );
}

const SeverityPopover = ({
  plain = false,
  severity,
  onSeverityChange,
  isUpdating = false,
  placement = 'bottomRight',
  size = FLAG_SIZE.small,
  open,
  setOpen,
  checkedSeverity,
  setCheckedSeverity
}) => {
  const { t } = useTranslation();

  const tooltipContent = useMemo(
    () =>
      plain
        ? !severity
          ? t('Event.SetIncident')
          : null
        : t(severity ? EVENT_SEVERITY[severity] : 'Event.SetIncident'),
    [t, severity]
  );

  const iconStyle = useMemo(
    () => ({
      color: SEVERITY_COLOR[severity || 0]
    }),
    [severity]
  );

  const iconClassName = useMemo(
    () =>
      plain
        ? size === FLAG_SIZE.large
          ? styles.plainLargeFlag
          : styles.plainFlag
        : size === FLAG_SIZE.large
        ? styles.largeFlag
        : styles.flag,
    [plain, size]
  );

  const isDisabled = useMemo(
    () =>
      checkedSeverity === null ||
      (isNaN(Number(severity)) ? 0 : Number(severity)) ===
        (isNaN(Number(checkedSeverity)) ? 0 : Number(checkedSeverity)),
    [severity, checkedSeverity]
  );

  return (
    <Popover
      placement={placement}
      trigger={'click'}
      overlayInnerStyle={{ padding: 8 }}
      open={open}
      onOpenChange={open => {
        if (!isUpdating) {
          setOpen(open);
          if (!open) {
            setCheckedSeverity(null);
          }
        }
      }}
      content={
        <>
          <Space direction="vertical">
            {Object.keys(EVENT_SEVERITY).map(severityKey => (
              <Checkbox
                key={`severity-option-${severityKey}`}
                checked={
                  Number(severityKey) ===
                  (checkedSeverity === null
                    ? isNaN(Number(severity))
                      ? 0
                      : Number(severity)
                    : checkedSeverity)
                }
                onChange={e => setCheckedSeverity(e.target.checked ? Number(severityKey) : 0)}
              >
                {t(EVENT_SEVERITY[severityKey])}
              </Checkbox>
            ))}
          </Space>
          <Divider
            style={{
              margin: 0
            }}
          />
          <div style={{ paddingTop: 8, textAlign: 'right' }}>
            <Button
              type="primary"
              size="small"
              loading={isUpdating}
              disabled={isDisabled}
              onClick={() => onSeverityChange(checkedSeverity)}
            >
              {t('Common.Modal.OK')}
            </Button>
          </div>
        </>
      }
    >
      <Tooltip title={tooltipContent}>
        {plain ? (
          !severity ? (
            <FlagOutlined className={iconClassName} style={iconStyle} />
          ) : (
            <FlagFilled className={iconClassName} style={iconStyle} />
          )
        ) : (
          <div className={styles.flagBtn}>
            {!severity ? (
              <FlagOutlined className={iconClassName} style={iconStyle} />
            ) : (
              <FlagFilled className={iconClassName} style={iconStyle} />
            )}
          </div>
        )}
      </Tooltip>
    </Popover>
  );
};
