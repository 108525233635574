import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingTable } from 'components/grid/LoadingTable';
import { Column, Table, AutoSizer } from 'react-virtualized';

import {
  cache,
  detailsCellRenderer,
  nameCellRenderer,
  registrationCellRenderer,
  devicesCellRenderer,
  gpioCellRenderer,
  ellipsisButtonCellRenderer
} from '../CellRenderers';

import { doesVehicleHaveHermesDevice } from '../helpers';
import { TABS } from '../constants';
import {
  useCan,
  entities,
  useIQCameraUser,
  useCanOneOfRoles,
  FeatureFlag,
  GlobalRoles
} from 'features/permissions';
import services from 'features/permissions/services';
import { Empty } from 'components/ant';

export const VehiclesTable = ({
  vehicles,
  vehiclesBoth,
  companies,
  isLoading,
  setTableRef,
  typeOfEntityToDelete,
  handleDeleteAction,
  handleAction,
  filterTab
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const canViewVehicle = can && can({ everyEntity: [entities.VEHICLE_VIEW] });
  const canViewDevice = can && can({ everyEntity: [entities.DEVICE_VIEW] });

  const { canAccessNonCameraFeatures } = useIQCameraUser();
  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 275,
          details: 300,
          licence: 150,
          branch: 150,
          devices: 200,
          ellipsisButton: 60
        };

        const isDeletedTab = filterTab === TABS.deleted;

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.details +
            fixedColumnsWidth.licence +
            fixedColumnsWidth.branch +
            fixedColumnsWidth.ellipsisButton);

        const columnStyle = {
          // padding: "12px 12px 12px 0"
        };

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={vehicles.length}
            rowGetter={({ index }) => vehicles[index]}
            rowStyle={{ alignItems: 'flex-start' }}
            ref={ref => setTableRef(ref)}
            noRowsRenderer={() => <Empty />}
          >
            <Column
              style={columnStyle}
              label={t('Vehicles.Table.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => nameCellRenderer({ ...props, canViewVehicle })}
            />
            <Column
              style={columnStyle}
              label={t('Vehicles.Table.Details')}
              dataKey="companyId"
              width={fixedColumnsWidth.details}
              cellRenderer={props =>
                detailsCellRenderer({ ...props, companies: companies, vehiclesBoth })
              }
            />
            <Column
              style={columnStyle}
              label={t('Vehicles.Table.Registration')}
              dataKey="registrationState"
              width={fixedColumnsWidth.licence}
              cellRenderer={registrationCellRenderer}
            />
            <Column
              style={columnStyle}
              label={t('Vehicles.Table.Type')}
              width={fixedColumnsWidth.branch}
              cellDataGetter={({ rowData }) => rowData.type.name}
              dataKey="type"
            />
            <Column
              style={columnStyle}
              label={t('Vehicles.Table.Devices')}
              dataKey="devices"
              width={dynamicColumnWidth}
              cellRenderer={props => devicesCellRenderer({ ...props, canViewDevice })}
            />
            {canAccessNonCameraFeatures && (
              <Column
                label={t('Vehicles.Table.Actions')}
                dataKey=""
                style={{ overflow: 'visible' }}
                width={fixedColumnsWidth.ellipsisButton}
                cellRenderer={props => {
                  const isDeletingEdrVehicle = props.rowData?.devices?.some(
                    d => d.type?.code === 'EDR'
                  );

                  const customDeleteContent = isDeletingEdrVehicle ? (
                    <div>
                      {t('Vehicles.Form.UpdateEDRWarning', {
                        action: t('Vehicles.Form.Delete'),
                        type: t('Common.Vehicle').toLowerCase()
                      })}
                      <br />
                      {t('Vehicles.Form.UpdateEDRWarning2')}
                    </div>
                  ) : (
                    undefined
                  );

                  return ellipsisButtonCellRenderer({
                    ...props,
                    typeOfEntityToDelete,
                    hideEdit: isDeletedTab,
                    hideDelete: isDeletedTab,
                    enableRestoreAction: isDeletedTab,
                    showCalibrate: !isDeletedTab,
                    customDeleteContent,
                    deleteConfirmation: !isDeletingEdrVehicle,
                    showGPIO: !isDeletedTab && doesVehicleHaveHermesDevice(props?.rowData),
                    handleAction,
                    handleDeleteAction
                  });
                }}
              />
            )}
          </Table>
        );
      }}
    </AutoSizer>
  );
};
