import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { GPIOConfigForm } from './components/GPIOConfigForm';

export const GPIOFormModal = ({
  visible,
  getChannels,
  onCancel,
  onValidate,
  onSubmit,
  diginForm,
  digoutForm,
  channelTypes,
  values
}) => {
  const [form] = Form.useForm();
  const [isValid, setIsValid] = useState(false);
  const editMode = !!values?.input;
  const { t } = useTranslation();
  const gpioFormRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      form.validateFields().then(
        () => {
          setIsValid(true);
        },
        e => {
          setIsValid(false);
        }
      );
    }, 0);
  }, [Form.useWatch([], form)]);

  return (
    <Modal
      title={
        editMode
          ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Edit Config')
          : t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Add Config')
      }
      open={visible}
      onCancel={onCancel}
      okButtonProps={{ disabled: !isValid }}
      width="800px"
      onOk={() => {
        try {
          onValidate(form.getFieldsValue());
          gpioFormRef.current?.setErrorMessage(undefined);
        } catch (error) {
          gpioFormRef.current?.setErrorMessage(error.message);
          return;
        }

        form.validateFields().then(values => {
          onSubmit(values);
          form.resetFields();
        });
      }}
      destroyOnClose={true}
    >
      <GPIOConfigForm
        ref={gpioFormRef}
        form={form}
        values={values}
        diginForm={diginForm}
        digoutForm={digoutForm}
        channelTypes={channelTypes}
        getChannels={getChannels}
      />
    </Modal>
  );
};
