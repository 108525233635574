import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { Layout } from 'antd';
import { sortBy } from 'lodash';
import { format } from 'utils/dates';
import { prepareDataForMultiselect } from 'utils/filters';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import {
  fetchInspectionSummary,
  useInspectionSummary,
  useIsInspectionsFetching,
  fetchInspectionSummaryFilters,
  useUserInspectionsFilters,
  updateFilters,
  useIsFiltersFetching
} from 'features/inspectionChecklist/inspectionChecklistSlice';
import { useFleets, useIsFetching as useIsFleetsFetching } from 'features/fleets/fleetsSlice';
import { InspectionSummaryTable } from './InspectionSummaryTable';
import { InspectionSummaryToolbar } from './InspectionSummaryToolbar';
import { ExportToExcel } from './ExportToExcel';
import {
  getDaysList,
  getStatusList,
  distMileList,
  distKMList,
  engineHoursList,
  updateDistanceFilter
} from '../Inspections/utils/constants';
import { DateRangePicker } from 'components/ant';
import styles from '../VehicleMaintenanceSchedules.module.scss';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const InspectionSummary = () => {
  const localization = useLocalization();
  const dateFormat = localization.formats.time.formats.dby;
  const units = localization.formats.speed.unit_pluralize;
  const fleets = useFleets();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const daysList = getDaysList(t);
  const statusList = getStatusList(t);
  const defaultDates = [dayjs().startOf('day'), dayjs().endOf('day')];
  const [selectedDates, setSelectedDates] = useState(defaultDates);
  const [dateFrom, setDateFrom] = useState(selectedDates[0].format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(selectedDates[1].format('YYYY-MM-DD'));
  const [datePicker, setDatePicker] = useState({
    isOpen: false,
    hasBeenOpened: false,
    haveDatesBeenChanged: false
  });
  const [searchText, setSearchText] = useState(null);
  const [fleetsFilter, setFleetsFilter] = useState([]);
  const [driversFilter, setDriversFilter] = useState([]);
  const [vehiclesFilter, setVehiclesFilter] = useState([]);
  const [daysFilter, setDaysFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [distanceFilter, setDistanceFilter] = useState([]);
  const [engineHoursFilter, setEngineHoursFilter] = useState([]);
  const [passedFilter, setPassedFilter] = useState([]);
  const [failedFilter, setFailedFilter] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);
  const [restrictedInspections, setRestrictedInspections] = useState([]);
  const [forceFetchFilters, setForceFetchFilters] = useState(true);

  const FilterIds = {
    AllCompanies: 0,
    AllFleets: 0,
    AllDrivers: 0,
    AllVehicles: 0,
    AllDays: 0,
    AllStatus: 0,
    AllDistances: 0,
    AllEngineHours: 0,
    AllPassed: 0,
    AllFailed: 0,
    NoCompany: -1,
    NoFleet: -1,
    NoDriver: -1,
    NoVehicle: -1,
    NoInspections: -1,
    NoDistance: -1,
    NoEngineHours: -1,
    NoPassed: -1,
    NoFailed: -1
  };

  const inspectionSummary = useInspectionSummary(dateFrom, dateTo);
  const isFetching = useIsInspectionsFetching();
  const inspectionSummaryFilters = useUserInspectionsFilters(forceFetchFilters);
  const isFiltersFetching = useIsFiltersFetching();
  const isFleetsFetching = useIsFleetsFetching();

  useEffect(() => {
    dispatch(setPageTitle(t('VehicleMntSchedules.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(fetchInspectionSummary(dateFrom, dateTo));
    dispatch(fetchInspectionSummaryFilters());
  }, []);

  useEffect(() => {
    const limitedIns = inspectionSummary?.map(ins => {
      const filteredFleets = ins?.fleets?.filter(f => fleets?.some(fleet => f.id === fleet.id));
      return { ...ins, fleets: filteredFleets };
    });
    const noFleetPermissionExists = fleets?.some(fleet => !fleet.id);
    const finalInspections = noFleetPermissionExists
      ? limitedIns
      : limitedIns?.filter(ins => ins?.fleets?.length > 0);
    setRestrictedInspections(finalInspections);
  }, [inspectionSummary, fleets]);

  useEffect(() => {
    let fleetsList = [];
    let driversList = [];
    let vehiclesList = [];
    let passedList = [];
    let failedList = [];

    if (inspectionSummary) {
      restrictedInspections?.forEach(inspection => {
        //fleets
        if (inspection?.fleets) {
          inspection.fleets.forEach(fleet => {
            if (fleet && !fleetsList.find(f => f.id === fleet.id)) {
              fleetsList.push({ id: fleet.id, name: fleet.name });
              fleetsList.push();
            }
          });
        }
        //drivers
        if (inspection?.drivers) {
          inspection.drivers.forEach((driver, index) => {
            if (driver && !driversList.find(f => f.id === driver.id)) {
              driversList.push({ id: driver.id, name: driver?.firstName + ' ' + driver?.lastName });
              driversList.push();
            }
          });
        }
        //vehicle
        if (inspection?.vehicle) {
          const vehicle = inspection.vehicle;
          if (vehicle && !vehiclesList.find(v => v.id === vehicle.id)) {
            vehiclesList.push({ id: vehicle.id, name: vehicle.name });
          }
        }
        //passed
        if (inspection?.total) {
          const passed = inspection.total;
          if (passed && !passedList.find(v => v.id === passed)) {
            passedList.push({ id: passed, name: passed });
          }
        }
        //failed
        if (inspection?.failed) {
          const failed = inspection.failed;
          if (failed && !failedList.find(v => v.id === failed)) {
            failedList.push({ id: failed, name: failed });
          }
        }
      });

      // sort all the lists by name
      fleetsList = sortBy(fleetsList, [item => item.name?.toLowerCase()]);
      driversList = sortBy(driversList, [item => item.name?.toLowerCase()]);
      vehiclesList = sortBy(vehiclesList, [item => item.name?.toLowerCase()]);
      failedList = sortBy(failedList, [item => item.name]);
      passedList = sortBy(passedList, [item => item.name]);

      // Adding an option for no fleet/driver/vehicle/day
      fleetsList.push({ id: FilterIds.NoFleet, name: t('Common.NoFleet') });
      driversList.push({ id: FilterIds.NoDriver, name: t('Common.NoDriver') });
      vehiclesList.push({ id: FilterIds.NoVehicle, name: t('Common.NoVehicle') });
      const noInspectionsExists = statusList.some(ins => ins.id === FilterIds.NoInspections);
      !noInspectionsExists &&
        statusList.push({
          id: FilterIds.NoInspections,
          name: (
            <>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ color: '#64748b', marginRight: '5px' }}
              />
              {t('InspectionSummary.NoInspections')}
            </>
          )
        });

      const noDistanceExists =
        distKMList.some(dist => dist.id === FilterIds.NoDistance) ||
        distMileList.some(dist => dist.id === FilterIds.NoDistance);
      if (!noDistanceExists) {
        distKMList.unshift({ id: FilterIds.NoDistance, name: 0 });
        distMileList.unshift({ id: FilterIds.NoDistance, name: 0 });
      }
      const noEngineHoursExists = engineHoursList.some(hrs => hrs.id === FilterIds.NoEngineHours);
      !noEngineHoursExists && engineHoursList.unshift({ id: FilterIds.NoEngineHours, name: 0 });
      const noFailedExists = failedList.some(failed => failed.id === FilterIds.NoFailed);
      !noFailedExists && failedList.push({ id: FilterIds.NoFailed, name: t('ELD.None') });
      const noPassedExists = passedList.some(passed => passed.id === FilterIds.NoPassed);
      !noPassedExists && passedList.push({ id: FilterIds.NoPassed, name: t('ELD.None') });

      const processFilter = (filterList, inspectionFilter, allLabel) => {
        const hasMatchingIds = !inspectionFilter?.length
          ? false
          : inspectionFilter?.some(filter => filter.id === 0 && filter.checked)
          ? false
          : filterList.some(
              item =>
                item.id !== 0 &&
                // item.id !== -1 &&
                inspectionFilter?.some(filter => filter.id === item.id && filter.checked)
            );

        if (hasMatchingIds) {
          const newFilter = [
            { label: allLabel, id: 0, checked: false },
            ...filterList.map(item => {
              const filterItem = inspectionFilter?.find(
                // filter => item.id !== 0 && item.id !== -1 && filter.id === item.id
                filter => item.id !== 0 && filter.id === item.id
              );
              return {
                label: item.name,
                id: item.id,
                checked: filterItem ? filterItem.checked : item.checked
              };
            })
          ];

          const allChecked = newFilter
            .filter(filter => filter.id !== 0)
            .every(filter => filter.checked);
          const allUnChecked = inspectionFilter?.every(filter => !filter.checked);

          if (allUnChecked) {
            const tempFilter = prepareDataForMultiselect(filterList, allLabel, null);
            return tempFilter.map(item => ({
              ...item,
              checked: false
            }));
          }
          if (allChecked) {
            newFilter[0].checked = true;
          }
          return newFilter;
        } else {
          return prepareDataForMultiselect(filterList, allLabel, null);
        }
      };

      const newVehiclesFilter = processFilter(
        vehiclesList,
        inspectionSummaryFilters?.vehiclesFilter,
        t('Common.AllVehicles')
      );
      const newFleetsFilter = processFilter(
        fleetsList,
        inspectionSummaryFilters?.fleetsFilter,
        t('Common.AllFleets')
      );
      const newDriversFilter = processFilter(
        driversList,
        inspectionSummaryFilters?.driversFilter,
        t('Common.AllDrivers')
      );
      const newDaysFilter = processFilter(
        daysList,
        inspectionSummaryFilters?.daysFilter,
        t('Common.AllDays')
      );
      const newStatusFilter = processFilter(
        statusList,
        inspectionSummaryFilters?.statusFilter,
        t('Common.AllStatuses')
      );
      const newDistance = processFilter(
        units === 'km' ? distKMList : distMileList,
        inspectionSummaryFilters?.distanceFilter,
        t('Common.All')
      );
      const newEngineHours = processFilter(
        engineHoursList,
        inspectionSummaryFilters?.engineHoursFilter,
        t('Common.All')
      );
      const newPassedFilter = processFilter(
        passedList,
        inspectionSummaryFilters?.passedFilter,
        t('Common.All')
      );
      const newFailedFilter = processFilter(
        failedList,
        inspectionSummaryFilters?.failedFilter,
        t('Common.All')
      );

      setVehiclesFilter(newVehiclesFilter);
      setFleetsFilter(newFleetsFilter);
      setDriversFilter(newDriversFilter);
      setDaysFilter(newDaysFilter);
      setStatusFilter(newStatusFilter);
      setDistanceFilter(newDistance);
      setEngineHoursFilter(newEngineHours);
      setPassedFilter(newPassedFilter);
      setFailedFilter(newFailedFilter);
    }
  }, [restrictedInspections]);

  useEffect(() => {
    setForceFetchFilters(false);
  }, [restrictedInspections, inspectionSummaryFilters]);

  useEffect(() => {
    if (inspectionSummary) {
      let newfilteredInspections = [];

      restrictedInspections?.forEach(row => {
        let matchSearch = true;
        // filtering functionality
        if (matchSearch && searchText && searchText.trim()) {
          const searchTextLowerCase = searchText.trim().toLowerCase();
          matchSearch =
            row.vehicle?.name?.toLowerCase().includes(searchTextLowerCase) ||
            row.fleets?.some(f => f.name?.toLowerCase().indexOf(searchTextLowerCase) >= 0) ||
            row.inspections?.some(
              i => i.checklistName?.toLowerCase().indexOf(searchTextLowerCase) >= 0
            ) ||
            row.inspections?.some(
              i => i.location?.toLowerCase().indexOf(searchTextLowerCase) >= 0
            ) ||
            row.inspections?.some(
              i =>
                format(new Date(i.inspectionDate), 'hh:mm A')
                  ?.toLowerCase()
                  .indexOf(searchTextLowerCase) >= 0
            ) ||
            row.drivers?.some(
              d =>
                (d?.firstName?.toLowerCase() + ' ' + d?.lastName?.toLowerCase()).indexOf(
                  searchTextLowerCase
                ) >= 0
            ) ||
            row.dayOfWeek?.toLowerCase().includes(searchTextLowerCase) ||
            localization
              ?.convertDistance(row.distance)
              ?.toString()
              ?.includes(searchTextLowerCase) ||
            row.engineHours
              ?.toFixed(1)
              ?.toString()
              ?.includes(searchTextLowerCase) ||
            row.total?.toString()?.includes(searchTextLowerCase) ||
            row.failed?.toString()?.includes(searchTextLowerCase) ||
            format(new Date(row.date), localization.formats.time.formats.dby_imsp)
              ?.toLowerCase()
              .includes(searchTextLowerCase);
        }

        if (
          matchSearch &&
          fleetsFilter &&
          !fleetsFilter.find(f => f.id === FilterIds.AllFleets)?.checked
        ) {
          const hasFleet = row.fleets && row.fleets.length > 0;
          matchSearch = fleetsFilter.some(
            f =>
              f.checked &&
              ((row.fleets && row.fleets.some(fleet => f.id === fleet.id)) ||
                (f.id === FilterIds.NoFleet && !hasFleet))
          );
        }

        if (
          matchSearch &&
          vehiclesFilter &&
          !vehiclesFilter.find(f => f.id === FilterIds.AllVehicles)?.checked
        ) {
          const hasVehicle = row.vehicle && row.vehicle.id;
          matchSearch = vehiclesFilter.some(
            f =>
              f.checked &&
              ((row.vehicle && f.id === row.vehicle.id) ||
                (f.id === FilterIds.NoVehicle && !hasVehicle))
          );
        }

        if (
          matchSearch &&
          driversFilter &&
          !driversFilter.find(f => f.id === FilterIds.AllDrivers)?.checked
        ) {
          const hasDriver = row.drivers && row.drivers.length > 0;
          matchSearch = driversFilter.some(
            f =>
              f.checked &&
              ((row.drivers && row.drivers.some(driver => f.id === driver.id)) ||
                (f.id === FilterIds.NoDriver && !hasDriver))
          );
        }

        if (
          matchSearch &&
          daysFilter &&
          !daysFilter.find(f => f.id === FilterIds.AllDays)?.checked
        ) {
          matchSearch = daysFilter.some(f => f.checked && row.dayOfWeek && f.id === row.dayOfWeek);
        }

        if (
          matchSearch &&
          statusFilter &&
          !statusFilter.find(f => f.id === FilterIds.AllStatus)?.checked
        ) {
          const hasInspections = row.inspections && row.inspections.length > 0;
          matchSearch = statusFilter.some(
            f =>
              f.checked &&
              ((hasInspections && row.inspections.some(inspection => f.id === inspection.status)) ||
                (f.id === FilterIds.NoInspections && !hasInspections))
          );
        }

        if (
          matchSearch &&
          distanceFilter &&
          !distanceFilter.find(f => f.id === FilterIds.AllDistances)?.checked
        ) {
          const hasDistance = row.distance && row.distance > 0;
          const convertedDist = localization?.convertDistance(row.distance);

          matchSearch = distanceFilter.some(dist => {
            if (!dist.checked) return false;
            if (units === 'miles') {
              if (dist.id === FilterIds.NoDistance && !hasDistance) return true;
              if (dist.id === '1' && convertedDist > 0 && convertedDist <= 10) return true;
              if (dist.id === '2' && convertedDist > 10 && convertedDist <= 50) return true;
              if (dist.id === '3' && convertedDist > 50) return true;
            } else if (units === 'km') {
              if (dist.id === FilterIds.NoDistance && !hasDistance) return true;
              if (dist.id === '1' && convertedDist > 0 && convertedDist <= 20) return true;
              if (dist.id === '2' && convertedDist > 20 && convertedDist <= 100) return true;
              if (dist.id === '3' && convertedDist > 100) return true;
            } else if (dist.id === FilterIds.NoDistance && !hasDistance) return true;
            return false;
          });
        }

        if (
          matchSearch &&
          engineHoursFilter &&
          !engineHoursFilter.find(f => f.id === FilterIds.AllEngineHours)?.checked
        ) {
          const hasEngineHrs = row.engineHours && row.engineHours > 0;
          matchSearch = engineHoursFilter.some(engineHrs => {
            if (!engineHrs.checked) return false;
            if (engineHrs.id === '1' && row.engineHours > 0 && row.engineHours <= 0.5) return true;
            if (engineHrs.id === '2' && row.engineHours > 0.5 && row.engineHours <= 2) return true;
            if (engineHrs.id === '3' && row.engineHours > 2) return true;
            else if (engineHrs.id === FilterIds.NoEngineHours && !hasEngineHrs) return true;
            return false;
          });
        }

        if (
          matchSearch &&
          passedFilter &&
          !passedFilter.find(f => f.id === FilterIds.AllPassed)?.checked
        ) {
          const hasPassed = row.total > 0;
          matchSearch = passedFilter.some(
            f =>
              f.checked &&
              ((row.total && f.id === row.total) || (f.id === FilterIds.NoPassed && !hasPassed))
          );
        }

        if (
          matchSearch &&
          failedFilter &&
          !failedFilter.find(f => f.id === FilterIds.Allfailed)?.checked
        ) {
          const hasFailed = row.failed > 0;
          matchSearch = failedFilter.some(
            f =>
              f.checked &&
              ((row.failed && f.id === row.failed) || (f.id === FilterIds.NoFailed && !hasFailed))
          );
        }

        if (matchSearch) {
          const newInspectionsRow =
            row?.inspections?.filter(ins =>
              statusFilter.some(status => status.checked && ins.status === status.id)
            ) || [];
          if (newInspectionsRow.length > 0) {
            newfilteredInspections.push({ ...row, inspections: newInspectionsRow });
          } else {
            newfilteredInspections.push(row);
          }
        }
      });

      setFilteredInspections(newfilteredInspections);

      const serializedStatusFilter = statusFilter.map(status => {
        return {
          id: status.id,
          name: status.id,
          checked: status.checked
        };
      });
      const serializedDistFilter = updateDistanceFilter(distanceFilter, units);

      dispatch(
        updateFilters({
          vehiclesFilter,
          fleetsFilter,
          driversFilter,
          daysFilter,
          statusFilter: serializedStatusFilter,
          distanceFilter: serializedDistFilter,
          engineHoursFilter,
          passedFilter,
          failedFilter
        })
      );
    }
  }, [
    restrictedInspections,
    searchText,
    fleetsFilter,
    vehiclesFilter,
    driversFilter,
    daysFilter,
    statusFilter,
    distanceFilter,
    engineHoursFilter,
    passedFilter,
    failedFilter
  ]);

  const resetFilters = filters => {
    return filters.map(filter => ({
      ...filter,
      checked: true
    }));
  };

  const [searchKey, setSearchKey] = useState(0);

  const handleRestoreDefault = () => {
    setSearchText(null);
    setSearchKey(prevKey => prevKey + 1);
    setVehiclesFilter(resetFilters(vehiclesFilter));
    setFleetsFilter(resetFilters(fleetsFilter));
    setDriversFilter(resetFilters(driversFilter));
    setDaysFilter(resetFilters(daysFilter));
    setStatusFilter(resetFilters(statusFilter));
    setDistanceFilter(resetFilters(distanceFilter));
    setEngineHoursFilter(resetFilters(engineHoursFilter));
    setPassedFilter(resetFilters(passedFilter));
    setFailedFilter(resetFilters(failedFilter));
  };

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleFleetsFilterChange = useCallback(fleetsFilter => {
    setFleetsFilter(fleetsFilter);
  }, []);

  const handleVehiclesFilterChange = useCallback(vehiclesFilter => {
    setVehiclesFilter(vehiclesFilter);
  }, []);

  const handleDriversFilterChange = useCallback(driversFilter => {
    setDriversFilter(driversFilter);
  }, []);

  const handleDaysFilterChange = useCallback(daysFilter => {
    setDaysFilter(daysFilter);
  }, []);

  const handleStatusFilterChange = useCallback(statusFilter => {
    setStatusFilter(statusFilter);
  }, []);

  const handleDistanceFilterChange = useCallback(distanceFilter => {
    setDistanceFilter(distanceFilter);
  }, []);

  const handleEngineHoursFilterChange = useCallback(engineHoursFilter => {
    setEngineHoursFilter(engineHoursFilter);
  }, []);

  const handlePassedFilterChange = useCallback(passedFilter => {
    setPassedFilter(passedFilter);
  }, []);

  const handleFailedFilterChange = useCallback(failedFilter => {
    setFailedFilter(failedFilter);
  }, []);

  //date picker functionality, summary fetch
  useEffect(() => {
    const { isOpen, hasBeenOpened, haveDatesBeenChanged } = datePicker;
    if (!isOpen && hasBeenOpened && haveDatesBeenChanged) {
      dispatch(fetchInspectionSummary(dateFrom, dateTo));
      dispatch(fetchInspectionSummaryFilters());
      setDatePicker(prev => {
        return { ...prev, haveDatesBeenChanged: false };
      });
    }
  }, [datePicker, dispatch]);

  const handleDateRangeChange = newRange => {
    setSelectedDates(newRange);
    const formattedDates = newRange.map(date => date.format('YYYY-MM-DD'));
    setDateFrom(formattedDates[0]);
    setDateTo(formattedDates[1]);
    setDatePicker(prev => {
      return { ...prev, haveDatesBeenChanged: true };
    });
  };

  const handleDateRangeClose = isOpen => {
    setDatePicker(prev => {
      return { ...prev, isOpen, hasBeenOpened: true };
    });
  };

  return (
    <>
      <div className={styles.datePickerStyle}>
        <DateRangePicker
          size="large"
          maxDayRange={6}
          availableDatesRange={[0, moment().endOf('day')]}
          format={localization.formats.time.formats.dby.toUpperCase()}
          defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
          showPast14Days={false}
          showLastMonth={false}
          onDateRangeChanged={dates => {
            handleDateRangeChange(dates);
          }}
          onOpenChange={handleDateRangeClose}
        />
        <ExportToExcel
          data={filteredInspections}
          localization={localization}
          dateRange={[dayjs(dateFrom).format(dateFormat), dayjs(dateTo).format(dateFormat)]}
        />
      </div>
      <div>
        <InspectionSummaryToolbar
          filteredInspectionsCount={filteredInspections?.length}
          searchText={searchText}
          key={searchKey}
          fleetsFilter={fleetsFilter}
          driversFilter={driversFilter}
          vehiclesFilter={vehiclesFilter}
          daysFilter={daysFilter}
          statusFilter={statusFilter}
          distanceFilter={distanceFilter}
          engineHoursFilter={engineHoursFilter}
          passedFilter={passedFilter}
          failedFilter={failedFilter}
          onSearchChange={handleSearchChange}
          onFleetsFilterChange={handleFleetsFilterChange}
          onVehiclesFilterChange={handleVehiclesFilterChange}
          onDriversFilterChange={handleDriversFilterChange}
          onDaysFilterChange={handleDaysFilterChange}
          onStatusFilterChange={handleStatusFilterChange}
          onDistanceFilterChange={handleDistanceFilterChange}
          onEngineHoursFilterChange={handleEngineHoursFilterChange}
          onPassedFilterChange={handlePassedFilterChange}
          onFailedFilterChange={handleFailedFilterChange}
          onRestoreDefault={handleRestoreDefault}
          isFetching={isFetching || isFiltersFetching || isFleetsFetching}
        />
      </div>
      <Layout>
        <InspectionSummaryTable
          inspectionData={filteredInspections}
          isFetching={isFetching || isFiltersFetching || isFleetsFetching}
        />
      </Layout>
    </>
  );
};
