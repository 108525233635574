import React from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { PhoneNumberModal } from 'features/bulkEdit/BulkEditEntity/PhoneNumberModal';
import { EntityInputModal } from 'features/bulkEdit/BulkEditEntity/EntityInputModal';
import { EntitySelectModal } from 'features/bulkEdit/BulkEditEntity/EntitySelectModal';
import { BULK_EDIT_ENTITY } from '../helper';
import { RegistrationSelectModal } from './RegistrationSelectModal';
import { ServiceDatePickerModal } from './ServiceDatePickerModal';
import { useTranslation } from 'react-i18next';
import { EldVehicleSwitchModal } from './EldVehicleSwitchModal';
import { TypeSelectModal } from './TypeSelectModal';
import { ModelSelectModal } from './ModelSelectModal';
import { DeviceSelectModal } from './DeviceSelectModal';

export const EntityEditModal = ({
  show,
  onOk,
  onCancel,
  label,
  placeholder,
  entity,
  companyId,
  required,
  vehicles
}) => {
  const { t } = useTranslation();

  switch (entity) {
    case BULK_EDIT_ENTITY.DEVICE.entity:
      return (
        <DeviceSelectModal
          vehicles={vehicles}
          show={show}
          onOk={onOk}
          onCancel={onCancel}
          label={label}
        />
      );
    case BULK_EDIT_ENTITY.IN_SERVICE_AT.entity:
      return (
        <ServiceDatePickerModal
          fieldProp={{ title: t('Vehicles.Form.InServiceAt') }}
          show={show}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    case BULK_EDIT_ENTITY.OUT_SERVICE_AT.entity:
      return (
        <ServiceDatePickerModal
          fieldProp={{ title: t('Vehicles.Form.OutServiceAt') }}
          show={show}
          onOk={onOk}
          onCancel={onCancel}
        />
      );
    case BULK_EDIT_ENTITY.REGISTRATION_COUNTRY_AND_STATE.entity:
      return <RegistrationSelectModal show={show} onOk={onOk} onCancel={onCancel} />;
    case BULK_EDIT_ENTITY.PHONE_NUMBER.entity:
      return <PhoneNumberModal companyId={companyId} show={show} onOk={onOk} onCancel={onCancel} />;
    case BULK_EDIT_ENTITY.ELD_VEHICLE.entity:
      return (
        <EldVehicleSwitchModal companyId={companyId} show={show} onOk={onOk} onCancel={onCancel} />
      );
    case BULK_EDIT_ENTITY.NOTES.entity:
      return (
        <EntityInputModal
          label={label}
          placeholder={placeholder}
          Component={TextArea}
          componentProps={{ autoSize: { minRows: 5, maxRows: 10 } }}
          validator={value => !!(value && value.trim())}
          show={show}
          onOk={onOk}
          onCancel={onCancel}
          required
        />
      );
    case BULK_EDIT_ENTITY.TYPE.entity:
      return <TypeSelectModal companyId={companyId} show={show} onOk={onOk} onCancel={onCancel} />;
    case BULK_EDIT_ENTITY.VEHICLE_MODEL.entity:
      return <ModelSelectModal companyId={companyId} show={show} onOk={onOk} onCancel={onCancel} />;
    default:
      return (
        <EntitySelectModal
          show={show}
          label={label}
          entity={entity}
          onOk={onOk}
          onCancel={onCancel}
          required={required}
          companyId={companyId}
        />
      );
  }
};
