import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { setBulkUpdates, setUpdates } from 'features/mqtt/mqttSlice';
import moment from 'moment';
import { useEffect } from 'react';
import { deviceStatsDataAdapter } from 'data/dataService/tn/adapter/deviceStats';
import dayjs from 'dayjs';
import services from 'features/permissions/services';
import { useCan, GlobalRoles, FeatureFlag } from 'features/permissions';

const devicesStats = {
  list: [],
  updates: {},
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false,
    companyKey: null
  },
  filtered: {
    list: [],
    filterByDeviceTypeCode: null,
    meta: {
      lastFetched: null,
      isFetching: false,
      error: null,
      isListEmpty: false,
      companyKey: null
    }
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

function startFilteredLoading(state) {
  state.filtered.meta.isFetching = true;
}

function loadingFilteredFailed(state, action) {
  state.filtered.meta.isFetching = false;
  state.filtered.meta.lastFetched = 'now';
  state.filtered.meta.error = action.payload.err;
  state.filtered.meta.isListEmpty = true;
  state.filtered.list = [];
  state.filtered.meta.companyKey = action.payload.companyKey;
  state.filtered.filterByDeviceTypeCode = action.payload.filterByDeviceTypeCode;
}

const devicesStatsSlice = createSlice({
  name: 'devicesStats',
  initialState: devicesStats,
  reducers: {
    fetchDevicesStatsStart: startLoading,
    fetchDevicesStatsSuccess(state, { payload }) {
      state.list = payload.list;
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchDevicesStatsFailure: loadingFailed,
    fetchFilteredDevicesStatsStart: startFilteredLoading,
    fetchFilteredDevicesStatsSuccess(state, { payload }) {
      state.filtered.list = payload.list;
      state.filtered.filterByDeviceTypeCode = payload.filterByDeviceTypeCode;
      state.filtered.meta.isFetching = false;
      state.filtered.meta.lastFetched = 'now';
      state.filtered.meta.error = null;
      state.filtered.meta.isListEmpty = payload.list.length === 0;
      state.filtered.meta.companyKey = payload.companyKey;
    },
    fetchFilteredDevicesStatsFailure: loadingFilteredFailed,
    applyUpdatesOnDevices(state) {
      state.list = state.list.map(device => {
        return {
          ...device,
          ...state.updates[device.id]
        };
      });
    },
    resetUpdates(state) {
      state.updates = {};
    }
  },
  extraReducers: {
    [setUpdates]: (state, actions) => {
      let lastEventAt = moment(new Date(actions.payload.lastEventAt));
      if (lastEventAt && lastEventAt.isValid()) {
        state.updates[actions.payload.deviceId] = {
          lastEventAt: lastEventAt,
          ignition: 'ON'
        };
      }
    }
  }
});

const {
  fetchDevicesStatsStart,
  fetchDevicesStatsFailure,
  fetchFilteredDevicesStatsStart,
  fetchFilteredDevicesStatsSuccess,
  fetchFilteredDevicesStatsFailure
} = devicesStatsSlice.actions;

export const {
  applyUpdatesOnDevices,
  resetUpdates,
  fetchDevicesStatsSuccess
} = devicesStatsSlice.actions;

export const fetchDeviceStats = (
  oneWireService,
  updateLocationOnly = false,
  embedDeviceMeters = false
) => async (dispatch, getState) => {
  //embedDeviceMeters may take longer
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user?.current?.auth?.key;
  const isMqttConnected = getState().mqtt?.isMqttConnected;
  const userPreferences = getState().userPreferences;
  const isFetching = getState().devicesStats.meta.isFetching;

  try {
    if (!companyKey || isFetching) {
      return;
    }
    if (!updateLocationOnly) {
      dispatch(fetchDevicesStatsStart());
    }

    const isLocalCacheAvailable = await deviceStatsDataAdapter.available();
    let requestUrl = `${API_PATH}/devices/stats?embed=users${
      embedDeviceMeters || oneWireService ? ',meters' : ''
    }&pruning=ALL&direction=DOWN&company_id=${companyId}`;

    if (isLocalCacheAvailable) {
      const lastUpdatedAt = await deviceStatsDataAdapter.lastUpdatedDate();
      if (lastUpdatedAt) {
        requestUrl += '&last_updated=' + encodeURIComponent(lastUpdatedAt);
      }
    }

    request('GET', requestUrl)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(async resp => {
        const devicesStats = resp.body || [];
        if (isLocalCacheAvailable) {
          if (devicesStats.length > 0) {
            deviceStatsDataAdapter.persist(devicesStats);
            deviceStatsDataAdapter.updateLastUpdatedDate(dayjs().format());
          }
        }

        if (updateLocationOnly && isMqttConnected && userPreferences?.refresh?.tracking !== -1) {
          dispatch(setBulkUpdates(devicesStats));
        }

        dispatch(
          fetchDevicesStatsSuccess({
            list: embedDeviceMeters
              ? devicesStats?.map(d => ({ ...d, meters: d.meters || [] }))
              : devicesStats,
            companyKey
          })
        );
      })
      .catch(err => {
        dispatch(fetchDevicesStatsFailure({ err: err.toString(), companyKey }));
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(fetchDevicesStatsFailure({ err: err.toString(), companyKey }));
  }
};

export const fetchFilteredDeviceStatsWithMeters = filterByDeviceTypeCode => (
  dispatch,
  getState
) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user?.current?.auth?.key;
  try {
    if (!companyKey) {
      return;
    }
    dispatch(fetchFilteredDevicesStatsStart());
    const filterByDeviceType = !!filterByDeviceTypeCode
      ? `device_type_codes=${filterByDeviceTypeCode}&`
      : '';
    request(
      'GET',
      `${API_PATH}/devices/stats?${filterByDeviceType}embed=users,meters&pruning=ALL&direction=DOWN&company_id=${companyId}`
    )
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        const filteredDevicesStats = resp.body || [];
        dispatch(
          fetchFilteredDevicesStatsSuccess({
            list: filteredDevicesStats,
            companyKey,
            filterByDeviceTypeCode
          })
        );
      })
      .catch(err => {
        dispatch(
          fetchFilteredDevicesStatsFailure({
            err: err.toString(),
            companyKey,
            filterByDeviceTypeCode
          })
        );
      });
  } catch (err) {
    dispatch(
      fetchFilteredDevicesStatsFailure({ err: err.toString(), companyKey, filterByDeviceTypeCode })
    );
  }
};

export const useDevicesStats = () => {
  const dispatch = useDispatch();
  const devicesStats = useSelector(state => state.devicesStats.list);
  const isFetching = useSelector(state => state.devicesStats.meta.isFetching);
  const isListEmpty = useSelector(state => state.devicesStats.meta.isListEmpty);
  const companyKey = useStatsCompanyKey();
  const currentCompanyKey = useCurrentCompanyKey();
  const can = useCan();

  useEffect(() => {
    const oneWireService = can({
      oneOfRoles: [GlobalRoles.SiteAdmin, GlobalRoles.Admin, GlobalRoles.Reseller],
      everyCompanyService: [services.ONEWIRE],
      featureFlag: FeatureFlag.oneWireTemperature.flag
    });

    if (
      ((companyKey && companyKey !== currentCompanyKey) ||
        (devicesStats.length === 0 && !isListEmpty)) &&
      !isFetching
    ) {
      dispatch(fetchDeviceStats(oneWireService));
    }
  }, [companyKey, currentCompanyKey, devicesStats.length, isListEmpty, isFetching]);

  return devicesStats;
};

export const useDevicesStatsEmbedDeviceMeters = filterByDeviceTypeCode => {
  const dispatch = useDispatch();
  const filteredDevicesStats = useSelector(state => state.devicesStats.filtered.list);
  const isFetching = useSelector(state => state.devicesStats.filtered.meta.isFetching);
  const isListEmpty = useSelector(state => state.devicesStats.filtered.meta.isListEmpty);
  const companyKey = useSelector(state => state.devicesStats.filtered.meta.companyKey);
  const currentCompanyKey = useCurrentCompanyKey();

  if (
    ((companyKey && companyKey !== currentCompanyKey) ||
      (filteredDevicesStats.length === 0 && !isListEmpty)) &&
    !isFetching
  ) {
    dispatch(fetchFilteredDeviceStatsWithMeters(filterByDeviceTypeCode));
  }
  return filteredDevicesStats;
};

export const useDevicesStatsUpdates = () => useSelector(state => state.devicesStats.updates);
export const useIsFetchingDevicesStats = () =>
  useSelector(state => state.devicesStats.meta.isFetching);

export const useStatsCompanyKey = () => useSelector(state => state.devicesStats.meta.companyKey);

export default devicesStatsSlice.reducer;
