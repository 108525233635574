import { init } from 'data/sqlite/init';
import { SQLResponseCode, SqlMsgRequestType } from 'data/sqlite/util/common';
import { uniqueId } from 'lodash';

export class LocalDBDataService {
  static #instance = null;
  static #isInternalConstructing = false;

  #dbWorker = null;
  #currentDbName = null;
  #msgPool = {};

  constructor() {
    if (LocalDBDataService.#isInternalConstructing) {
      LocalDBDataService.#isInternalConstructing = false;
      this.#dbWorker = init();
      this.#dbWorker.addEventListener('message', this.#messageHandling.bind(this));
    } else {
      throw new TypeError('The class is not constructable');
    }
  }

  static getInstance() {
    if (!LocalDBDataService.#instance) {
      LocalDBDataService.#isInternalConstructing = true;
      LocalDBDataService.#instance = new LocalDBDataService();
    }
    return LocalDBDataService.#instance;
  }

  #messageHandling(e) {
    console.debug(e);
    if (e.data?.hook && this.#msgPool[e.data.hook]) {
      const cb = this.#msgPool[e.data.hook];
      cb(e.data);
      delete this.#msgPool[e.data.hook];
    }
  }

  async openDb(name, ver) {
    if (!name || typeof name !== 'string' || name.trim() === '') {
      return Promise.reject('db name is incorrect');
    }

    if (!ver || isNaN(parseFloat(ver))) {
      return Promise.reject('db ver is incorrect');
    }

    const dbName = name + '_' + ver;

    if (this.#currentDbName === null) {
      this.#currentDbName = dbName;
    }
    if (this.#currentDbName !== dbName) {
      return Promise.reject('there is already a db opened');
    }

    return new Promise((resolve, reject) => {
      const msgHookId = uniqueId();
      this.#msgPool[msgHookId] = e => {
        resolve(true);
      };
      this.#dbWorker.postMessage({
        type: SqlMsgRequestType.OpenDb,
        hook: msgHookId,
        value: dbName
      });
    });
  }

  async migrateDb(name, ver) {
    if (!name || typeof name !== 'string' || name.trim() === '') {
      return Promise.reject('db name is incorrect');
    }

    if (!ver || isNaN(parseFloat(ver))) {
      return Promise.reject('db ver is incorrect');
    }

    return new Promise((resolve, reject) => {
      const msgHookId = uniqueId();
      this.#msgPool[msgHookId] = e => {
        resolve(true);
      };
      this.#dbWorker.postMessage({
        type: SqlMsgRequestType.MigrateDb,
        hook: msgHookId,
        value: { name, ver }
      });
    });
  }

  getCurrentDbName() {
    return this.#currentDbName;
  }

  async isDbOpened() {
    if (this.#dbWorker === null || !this.#currentDbName) {
      return Promise.resolve(false);
    } else {
      return new Promise((resolve, reject) => {
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = e => {
          if (e.code === SQLResponseCode.Success) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
        this.#dbWorker.postMessage({ type: SqlMsgRequestType.IsDbOpened, hook: msgHookId });
      });
    }
  }

  async closeDb() {
    if (this.#dbWorker === null) {
      this.#currentDbName = null;
      return Promise.resolve(true);
    } else {
      return new Promise((resolve, reject) => {
        this.#msgPool = {};
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = () => {
          //this.#dbWorker.removeEventListener('message', this.#messageHandling.bind(this));
          this.#currentDbName = null;
          this.#msgPool = {};
          resolve(true);
        };
        this.#dbWorker.postMessage({ type: SqlMsgRequestType.CloseDb, hook: msgHookId });
      });
    }
  }

  async dropDb(name) {
    return new Promise((resolve, reject) => {
      const msgHookId = uniqueId();
      this.#msgPool[msgHookId] = e => {
        if (e.code === SQLResponseCode.Success) {
          resolve(true);
        } else {
          reject(false);
        }
      };
      this.#dbWorker.postMessage({
        type: SqlMsgRequestType.DropDb,
        hook: msgHookId,
        value: name
      });
    });
  }

  async deleteAllUserDBs(userId) {
    await this.closeDb();
    return new Promise((resolve, reject) => {
      const msgHookId = uniqueId();
      this.#msgPool[msgHookId] = e => {
        if (e.code === SQLResponseCode.Success) {
          resolve(true);
        } else {
          reject(false);
        }
      };
      this.#dbWorker.postMessage({
        type: SqlMsgRequestType.DeleteAllUserDBs,
        hook: msgHookId,
        value: userId
      });
    });
  }

  async executeSql(sql) {
    let isDbOpened = await this.isDbOpened();
    if (isDbOpened) {
      return new Promise((resolve, reject) => {
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = e => {
          if (e.code === SQLResponseCode.Success) {
            resolve(e.value);
          } else {
            reject(e.msg);
          }
        };
        this.#dbWorker.postMessage({
          type: SqlMsgRequestType.ExecuteSql,
          hook: msgHookId,
          value: sql
        });
      });
    } else {
      Promise.reject('the db is not connected');
    }
  }

  async executeSqlV2(sql) {
    let isDbOpened = await this.isDbOpened();
    if (isDbOpened) {
      return new Promise((resolve, reject) => {
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = e => {
          if (e.code === SQLResponseCode.Success) {
            resolve(e.value);
          } else {
            reject(e.msg);
          }
        };
        this.#dbWorker.postMessage({
          type: SqlMsgRequestType.ExecuteSqlV2,
          hook: msgHookId,
          value: sql
        });
      });
    } else {
      Promise.reject('the db is not connected');
    }
  }

  async executeSqlV3(sql) {
    let isDbOpened = await this.isDbOpened();
    if (isDbOpened) {
      return new Promise((resolve, reject) => {
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = e => {
          if (e.code === SQLResponseCode.Success) {
            resolve(e.value);
          } else {
            reject(e.msg);
          }
        };
        this.#dbWorker.postMessage({
          type: SqlMsgRequestType.ExecuteSqlV3,
          hook: msgHookId,
          value: sql
        });
      });
    } else {
      Promise.reject('the db is not connected');
    }
  }

  async executeMultipleSql(sql) {
    let isDbOpened = await this.isDbOpened();
    if (isDbOpened) {
      return new Promise((resolve, reject) => {
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = e => {
          if (e.code === SQLResponseCode.Success) {
            resolve(e.value);
          } else {
            reject(e.msg);
          }
        };
        this.#dbWorker.postMessage({
          type: SqlMsgRequestType.ExecuteMultipleSql,
          hook: msgHookId,
          value: sql
        });
      });
    } else {
      Promise.reject('the db is not connected');
    }
  }

  async runSql(sql) {
    let isDbOpened = await this.isDbOpened();
    if (isDbOpened) {
      return new Promise((resolve, reject) => {
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = e => {
          if (e.code === SQLResponseCode.Success) {
            resolve(true);
          } else {
            reject(e.msg);
          }
        };
        this.#dbWorker.postMessage({
          type: SqlMsgRequestType.RunSql,
          hook: msgHookId,
          value: sql
        });
      });
    } else {
      Promise.reject('the db is not connected');
    }
  }

  async getTables() {
    let isDbOpened = await this.isDbOpened();
    if (isDbOpened) {
      return new Promise((resolve, reject) => {
        const msgHookId = uniqueId();
        this.#msgPool[msgHookId] = e => {
          if (e.code === SQLResponseCode.Success) {
            resolve(e.value);
          } else {
            reject(e.msg);
          }
        };
        this.#dbWorker.postMessage({ type: SqlMsgRequestType.Tables, hook: msgHookId });
      });
    } else {
      Promise.reject('the db is not connected');
    }
  }

  closeService() {
    if (this.#dbWorker?.terminate) {
      this.#dbWorker.terminate();
      this.#dbWorker = null;
      LocalDBDataService.#instance = null;
    }
  }
}
