import { FeatureFlag, GlobalRoles, services, useCan } from 'features/permissions';
import { SpeedAssist } from '../Configurations/SpeedAssist';
import { DriverId } from '../Configurations/DriverId';
import { useMemo } from 'react';
import { Assets } from '../Configurations/Assets';
import { useTranslation } from 'react-i18next';
import { GPIOConfig } from '../Configurations/GPIOConfig';

const serviceConfigMap = {
  [services.SPEEDASSIST]: {
    serviceCode: services.SPEEDASSIST,
    permissions: {
      featureFlag: FeatureFlag.deviceConfig_speedAssist.flag,
      oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin, GlobalRoles.Admin],
      oneOfCompanyServices: [services.SPEEDASSIST]
    },
    validDeviceTypes: ['IFACE', 'EXTERNAL'],
    tabTitleKey: 'CompanyConfig.DeviceConfigurations.SPEEDASSISTConfig.Title',
    Component: SpeedAssist
  },
  [services.DRIVERPIN]: {
    serviceCode: services.DRIVERPIN,
    permissions: {
      featureFlag: FeatureFlag.driverLoginAlert.flag,
      oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin, GlobalRoles.Admin],
      oneOfCompanyServices: [services.DRIVERPIN]
    },
    validDeviceTypes: ['HERMES'],
    tabTitleKey: 'CompanyConfig.DeviceConfigurations.DriverIdConfig.NewTitle',
    Component: DriverId
  },
  [services.ASSETSUSAGE]: {
    serviceCode: services.ASSETSUSAGE,
    permissions: {
      oneOfCompanyServices: [services.ASSETSUSAGE]
    },
    tabTitleKey: 'CompanyConfig.DeviceConfigurations.AssetsConfig.Title',
    Component: Assets
  },
  [services.GPIO]: {
    serviceCode: services.GPIO,
    permissions: {
      featureFlag: FeatureFlag.gpioDeviceConfig.flag,
      oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
      oneOfCompanyServices: [services.GPIO]
    },
    validDeviceTypes: ['HERMES'],
    tabTitleKey: 'CompanyConfig.DeviceConfigurations.GPIOConfig.Title',
    Component: GPIOConfig
  }
};

export const useAvailableServices = deviceData => {
  const can = useCan();
  const { t } = useTranslation();

  const availableServices = useMemo(() => {
    const map = new Map();
    for (let [
      key,
      { permissions, validDeviceTypes, serviceCode, Component, tabTitleKey }
    ] of Object.entries(serviceConfigMap)) {
      if (
        can({ ...permissions }) &&
        (!validDeviceTypes || validDeviceTypes.includes(deviceData?.type?.code)) &&
        deviceData?.services?.includes(serviceCode)
      ) {
        map.set(serviceCode, {
          key: serviceCode,
          tabTitle: t(tabTitleKey),
          Component
        });
      }
    }
    return map;
  }, [deviceData]);

  return availableServices;
};
