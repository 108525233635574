import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchCameraEventComments } from 'features/camera/cameraEventApi';

export const useEventComments = ({ eventId, eventTimeAt, onSuccess, onError }) => {
  const dispatch = useDispatch();
  const reqCtxRef = useRef({
    request: null,
    fetchTime: null,
    error: null
  });
  const [comments, setComments] = useState();

  const fetchComments = useCallback(() => {
    const reqCtx = reqCtxRef.current;
    if (!reqCtx.isFetching && !reqCtx.fetchTime) {
      const onReqDone = error => {
        if (reqCtxRef.current.request?.abort) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current = {
          request: null,
          isFetching: false,
          fetchTime: moment().valueOf(),
          error: error || null
        };
      };

      if (reqCtx.request?.abort) {
        reqCtx.request.abort();
        reqCtx.request = null;
      }
      reqCtx.request = dispatch(
        fetchCameraEventComments({
          eventId,
          eventTimeAt,
          onSuccess: comm => {
            onReqDone();
            setComments(comm);
            if (onSuccess) {
              onSuccess(comm);
            }
          },
          onError: errMsg => {
            onReqDone(errMsg);
            setComments(null);
            if (onError) {
              onError(errMsg);
            }
          }
        })
      );
      const fetchData = async () => {
        try {
          reqCtx.isFetching = true;
          await reqCtx.request;
        } catch (e) {
          onReqDone(e);
        }
      };
      fetchData();
    }
  }, [dispatch, eventId, reqCtxRef, onSuccess, onError]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  return {
    comments,
    isFetched: reqCtxRef.current.fetchTime && !reqCtxRef.current.isFetching,
    isFetching: !reqCtxRef.current.fetchTime || reqCtxRef.current.isFetching
  };
};
