import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { EllipsisButton } from 'components/tables/EllipsisButton';
import { renderFleetsAndCompany } from './helpers';
import i18n from 'i18next';
import services from 'features/permissions/services';

export const detailsCellRenderer = ({
  rowIndex,
  rowData,
  dataKey,
  parent,
  companies,
  vehiclesBoth
}) => {
  const vehicle = rowData;
  const company = companies.find(comp => comp.id === vehicle.companyId);
  const companyName = company ? company.name : '';
  const fleets = renderFleetsAndCompany(vehicle, vehiclesBoth, companies);

  if (
    !rowData.devices ||
    rowData.fleets.length + 4 > rowData.devices.length ||
    (vehicle.externalId && rowData.fleets.length + 7 > rowData.devices.length)
  ) {
    return (
      <CellMeasurer
        cache={cache}
        key={`cell-measurer-${dataKey}-${vehicle.id}`}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          style={{
            paddingBottom: '15px'
          }}
        >
          <div
            style={{
              fontWeight: 'bold'
            }}
          >
            {i18n.t('Vehicles.Table.Company')}
          </div>
          <div>{companyName}</div>
          {fleets.length > 0 && (
            <>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingTop: '15px'
                }}
              >
                {i18n.t('Vehicles.Table.Fleets')}
              </div>
              <div>{fleets}</div>
            </>
          )}
          {vehicle.vin && (
            <>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingTop: '15px'
                }}
              >
                {i18n.t('Vehicles.Form.VIN')}
              </div>
              <div>{vehicle.vin}</div>
            </>
          )}
          {vehicle.engineNumber && (
            <>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingTop: '15px'
                }}
              >
                {i18n.t('Vehicles.Form.EngineNumber')}
              </div>
              <div>{vehicle.engineNumber}</div>
            </>
          )}
          {vehicle.phone && (
            <>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingTop: '15px'
                }}
              >
                {i18n.t('Vehicles.Form.PhoneNumber')}
              </div>
              <div>{vehicle.phone}</div>
            </>
          )}
          {vehicle.externalId && (
            <>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingTop: '15px'
                }}
              >
                {i18n.t('Vehicles.Table.ExternalID')}
              </div>
              <div>{vehicle.externalId}</div>
            </>
          )}
          {vehicle.note && (
            <>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingTop: '15px'
                }}
              >
                {i18n.t('Vehicles.Form.Notes')}
              </div>
              <div>{vehicle.note}</div>
            </>
          )}
        </div>
      </CellMeasurer>
    );
  }

  return (
    <div
      style={{
        paddingBottom: '15px'
      }}
    >
      <div
        style={{
          fontWeight: 'bold'
        }}
      >
        Company
      </div>
      <div>{companyName}</div>
      {fleets.length > 0 && (
        <>
          <div
            style={{
              fontWeight: 'bold',
              paddingTop: '15px'
            }}
          >
            Fleet(s)
          </div>
          <div>{fleets}</div>
        </>
      )}
      {vehicle.externalId && (
        <>
          <div
            style={{
              fontWeight: 'bold',
              paddingTop: '15px'
            }}
          >
            External Id
          </div>
          <div>{vehicle.externalId}</div>
        </>
      )}
    </div>
  );
};

export const gpioCellRenderer = ({ rowData, dataKey, parent, rowIndex, canViewDevice }) => {
  const vehicle = rowData;
  let gpioTemplate = [];

  if (vehicle.devices) {
    gpioTemplate = (vehicle.devices || [])
      .filter(device => device.services.includes(services.GPIO))
      .map((device, i) => {
        return device?.gpioTemplate;
      })
      .filter((obj, index, self) => !!obj && index === self.findIndex(o => o?.id === obj?.id));
  }

  return (
    <>
      {gpioTemplate.map(data => {
        return (
          <Link key={`configuration-${data.id}`} to={`/settings/company_config/gpio/id/${data.id}`}>
            <div>{data?.name}</div>
          </Link>
        );
      })}
    </>
  );
};

export const devicesCellRenderer = ({ rowData, dataKey, parent, rowIndex, canViewDevice }) => {
  const vehicle = rowData;
  let devices = [];
  if (vehicle.devices) {
    devices = vehicle.devices.map((device, i) =>
      canViewDevice ? (
        <Link key={`device-${dataKey}-${vehicle.id}-${i}`} to={`/settings/devices/id/${device.id}`}>
          <div>{device.name}</div>
        </Link>
      ) : (
        <div>{device.name}</div>
      )
    );
  }
  return (
    <CellMeasurer
      cache={cache}
      key={`cell-measurer-${dataKey}-dev-${vehicle.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div style={{ padding: '0 0 15px 0' }}>{devices}</div>
    </CellMeasurer>
  );
};

export const nameCellRenderer = ({ rowData, canViewVehicle }) => {
  return canViewVehicle ? (
    <Link to={`/settings/vehicles/id/${rowData.id}`}>{rowData.name}</Link>
  ) : (
    <span>{rowData.name}</span>
  );
};

export const registrationCellRenderer = ({ rowData }) => {
  return <div>{`${rowData.registrationState} | ${rowData.registration}`}</div>;
};

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  hideEdit,
  hideDelete,
  showCalibrate,
  showGPIO,
  typeOfEntityToDelete,
  enableRestoreAction,
  handleAction,
  handleDeleteAction,
  customDeleteContent,
  deleteConfirmation
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      path={'/settings/vehicles/'}
      data={rowData}
      canUse="VEHICLE"
      hideEdit={hideEdit}
      hideDelete={hideDelete}
      customDeleteContent={customDeleteContent}
      showCalibrate={showCalibrate}
      showGPIO={showGPIO}
      typeOfEntityToDelete={typeOfEntityToDelete}
      enableRestoreAction={enableRestoreAction}
      handleAction={handleAction}
      deleteConfirmation={deleteConfirmation}
      handleDeleteAction={handleDeleteAction}
    />
  );
};
