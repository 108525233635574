import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFleeetsByCompanyId } from 'features/fleets/fleetsSlice';
import { AutoSizer, Column, Table, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { Modal, Select, Form, Spin } from 'antd';
import styles from '../DevicesBulkEdit.module.scss';
import { BUTTON_IDS } from '../helper';

const bulkEditVehicleCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const VehicleSelectModal = ({ show, onOk, onCancel, devices }) => {
  const { t } = useTranslation();
  const [tableRef, setTableRef] = useState(null);

  const [form] = Form.useForm();
  const initialValues = useMemo(
    () =>
      devices?.reduce(
        (a, device) => ({
          ...a,
          [device.id]: {
            vehicleId: device.vehicleId
          }
        }),
        {}
      ),
    [devices]
  );

  useEffect(() => {
    if (tableRef) {
      bulkEditVehicleCache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [devices, tableRef]);

  const modelBodyHeight = useMemo(
    () => Math.min(52 * devices.length + 100, window.innerHeight - 360),
    [devices]
  );

  return (
    <Form initialValues={initialValues} form={form} component={false}>
      <Modal
        style={{ minWidth: 600 }}
        bodyStyle={{
          height: modelBodyHeight,
          display: 'block',
          padding: 0,
          marginTop: 24,
          borderTop: '1px solid #dadee3'
        }}
        okText={t('Common.Save')}
        cancelText={t('Common.Modal.Cancel')}
        title={t('Devices.BulkEdit.EditColumnTitle')}
        centered
        open={show}
        closable={false}
        maskClosable={false}
        onOk={() => {
          onOk(form.getFieldsValue(true));
        }}
        onCancel={() => {
          onCancel();
        }}
      >
        <AutoSizer>
          {({ height, width }) => {
            return (
              <Table
                deferredMeasurementCache={bulkEditVehicleCache}
                width={width}
                height={height}
                headerHeight={48}
                headerClassName={styles.tableHeader}
                gridClassName={styles.vehicleEditTable}
                rowHeight={bulkEditVehicleCache.rowHeight}
                rowCount={devices.length}
                rowGetter={({ index }) => devices[index]}
                rowStyle={{ alignItems: 'flex-start', paddingRight: 4 }}
                ref={ref => setTableRef(ref)}
              >
                <Column
                  id={BUTTON_IDS.vehicleSelectTable_device}
                  label={`${t('Entity.Device')} ${t('Devices.Table.NameLabel')}`}
                  dataKey="name"
                  width={Math.floor(width * (1 / 3))}
                />
                <Column
                  id={BUTTON_IDS.vehicleSelectTable_vehicle}
                  label={t('Devices.Table.VehicleLabel')}
                  dataKey="vehicleId"
                  width={width - Math.floor(width * (1 / 3))}
                  cellRenderer={props => (
                    <CellMeasurer
                      cache={bulkEditVehicleCache}
                      columnIndex={0}
                      key={`cell-measurer-${props.dataKey}-${props.rowData.id}`}
                      parent={props.parent}
                      rowIndex={props.rowIndex}
                    >
                      <VehicleSelect
                        name={[props.rowData.id, 'vehicleId']}
                        companyId={props.rowData.companyId}
                        placeholder={t('Vehicles.Form.VehiclePlaceholder')}
                      />
                    </CellMeasurer>
                  )}
                />
              </Table>
            );
          }}
        </AutoSizer>
      </Modal>
    </Form>
  );
};

const VehicleSelect = ({ name, placeholder, companyId }) => {
  const { isFetching, vehicles } = useFleeetsByCompanyId({ companyId });
  const options = useMemo(
    () =>
      vehicles.map(vehicle => ({
        label: `${vehicle.name} (${vehicle?.registrationState} | ${vehicle.registration})`,
        value: vehicle.id
      })),
    [vehicles]
  );

  return (
    <Form.Item name={name}>
      <Select
        key={`${name[0]}-vehicleSelect`}
        disabled={isFetching}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        allowClear
        notFoundContent={isFetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
};
