import React, { useState, useEffect, useRef } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay } from 'components/map/overlays';
import { useLocalization } from 'features/localization/localizationSlice';
import styles from '../../Tracking.module.scss';

export const TemperatureGraph = ({
  isLoading,
  activeTab,
  // onMouseEnter,
  // onMouseMove,
  // onMouseLeave,
  convertedGraphData
}) => {
  const { t } = useTranslation();

  // console.log(convertedGraphData, 'convertedGraphData');
  const localization = useLocalization();

  const [activeLines, setActiveLines] = useState({});
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const colorPalette = [
    '#f39c12',
    '#3498db',
    '#27ae60',
    '#8e44ad',
    '#c0392b',
    '#16a085',
    '#2ecc71',
    '#9b59b6',
    '#1abc9c',
    '#34495e'
  ];

  // Update sortedData and filteredData when convertedGraphData changes
  useEffect(() => {
    if (convertedGraphData) {
      const labels = Object.keys(convertedGraphData[0] || {}).filter(key => key !== 'time');
      setActiveLines(
        labels.reduce((acc, label) => {
          acc[label] = true;
          return acc;
        }, {})
      );
    }
  }, [convertedGraphData]);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight || 0);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeight);

    if (containerRef.current) {
      updateHeight(); // Initial height calculation
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, [convertedGraphData, activeTab]); // Trigger recalculation on data update

  useEffect(() => {
    if (activeTab === 'temperature' && containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight || 0);
    }
  }, [activeTab]);

  const toggleLine = line => {
    setActiveLines(prev => ({ ...prev, [line]: !prev[line] }));
  };

  const yAxisFormatter = value => {
    return localization.formatTemperature(value);
  };

  const minTemp = Math.min(...convertedGraphData.map(point => Math.min(...Object.values(point))));
  const maxTemp = Math.max(...convertedGraphData.map(point => Math.max(...Object.values(point))));

  // Create an array of ticks with 10-degree spacing
  const yAxisTicks = [];
  for (let i = Math.floor(minTemp / 10) * 10; i <= Math.ceil(maxTemp / 10) * 10; i += 10) {
    yAxisTicks.push(i);
  }

  // console.log(filteredData, 'Filtered Data');
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const now = moment();
      const time = moment(`1970-01-01T${label}:00`);
      const isPrevDay = time.hour() > now.hour();

      const tooltipDate = isPrevDay ? now.clone().subtract(1, 'day') : now.clone();
      tooltipDate.set({
        hour: time.hour(),
        minute: time.minute(),
        second: 0
      });
      const formattedDate = tooltipDate.format(localization.formats.time.formats.dby_imp);

      return (
        <div className={styles.tooltipContainer}>
          <p className={styles.tooltipTime}>{`${t('Common.DateTime')}: ${formattedDate}`}</p>
          {/* <p className={styles.tooltipTime}>{formattedDate}</p> */}

          {payload.map((item, index) => {
            const labelIndex = Object.keys(activeLines).indexOf(item.name);
            const color = colorPalette[labelIndex % colorPalette.length];

            return (
              <div
                key={index}
                className={styles.tooltipRow}
                style={{
                  color: color
                }}
              >
                <span className={styles.tooltipLabel}>{item.name}</span>
                <span>{localization.formatTemperature(Number(item.value) || 0)}</span>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return isLoading ? (
    <LoadingOverlay isLoading={isLoading} />
  ) : (
    <>
      {/* {!isLoading && !convertedGraphData?.length ? (
        <div className={styles.noData}>{t('Tracking.NoDataAvailable')}</div>
      ) : ( */}
      <div ref={containerRef} className={styles.graphContainer}>
        <div>
          <ResponsiveContainer
            width="96%"
            height={containerHeight}
            className={styles.responsiveContainer}
          >
            <div className={styles.temperatureLabel}>
              <div className={styles.verticalText}>{t('Tracking.GraphTabs.Temperature')}</div>
            </div>
            <div className={styles.disclaimerText}>{t('Tracking.GraphDisclaimerText')}</div>

            <LineChart
              data={convertedGraphData}
              // onMouseEnter={onMouseEnter}
              // onMouseMove={onMouseMove}
              // onMouseLeave={onMouseLeave}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                interval={Math.ceil(convertedGraphData?.length / 6)}

                // ticks={ticks}
                // tickFormatter={tick => moment(tick, 'HH:mm').format('HH:mm')}
                // ticks={['00:00', '04:00', '08:00', '12:00', '16:00', '20:00', '23:59']}
                // interval={60}
              />
              <YAxis ticks={yAxisTicks} tickFormatter={yAxisFormatter} />
              {/* <Tooltip /> */}
              <Tooltip content={<CustomTooltip />} />
              {Object.keys(activeLines).map(
                line =>
                  activeLines[line] && (
                    <>
                      <Line
                        key={line}
                        type="monotone"
                        dataKey={line}
                        stroke={
                          colorPalette[Object.keys(activeLines).indexOf(line) % colorPalette.length]
                        }
                        dot={false}
                        isAnimationActive={false} // Disable animation
                      />
                    </>
                  )
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className={styles.chartLines}>
          {Object.keys(activeLines).map(line => (
            <label
              key={line}
              className={styles.chartLineLabel}
              style={{
                color: colorPalette[Object.keys(activeLines).indexOf(line) % colorPalette.length]
              }}
            >
              <input
                type="checkbox"
                checked={activeLines[line]}
                onChange={() => toggleLine(line)}
              />
              <span className={styles.chartStyle}>
                {line.charAt(0).toUpperCase() + line.slice(1)}
              </span>
            </label>
          ))}
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default TemperatureGraph;
