import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Typography,
  List,
  Divider,
  Select,
  Button,
  Space,
  Radio,
  Checkbox,
  Switch
} from 'antd';

import {
  useUserPreferences,
  setUserPreferences,
  fetchPreferences
} from 'features/user/userPreferencesSlice';
import { useUserKey, useUser } from 'features/user/userSlice';
import { useDispatch } from 'react-redux';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useTranslation } from 'react-i18next';
import { services, FeatureFlag, companyFeatures } from 'features/permissions';
import { Can, useCan, useCanEveryService } from 'features/permissions';
import { changeMapLanguage } from 'features/maps/maps';
import { useAllRegions } from 'features/regions/regionsSlice';

import styles from './Preferences.module.scss';
import { DEFAULT_PREFERENCES } from 'containers/Settings/constants';
import { LANGUAGE_LOCALES } from 'features/localization/languages';
import { useCompanyGeofenceProviders } from 'features/company/companySlice';
import { sortStrings } from 'utils/strings';
import { BUTTON_IDS } from 'utils/globalConstants';
import { TNDataService } from 'data/dataService/tn/tnDataService';
import { setIsAppReady } from 'features/appState/appStateSlice';

const { Title } = Typography;
const { Option } = Select;
const { Footer } = Layout;

const getRefreshInterval = translate => {
  const seconds = translate('Common.Sec');
  const minute = translate('Common.Min');
  const minutes = translate('Common.Mins');

  return [
    { value: 15, label: `15 ${seconds}` },
    { value: 30, label: `30 ${seconds}` },
    { value: 60, label: `1 ${minute}` },
    { value: 120, label: `2 ${minutes}` },
    { value: 180, label: `3 ${minutes}` },
    { value: 240, label: `4 ${minutes}` },
    { value: 300, label: `5 ${minutes}` },
    { value: 600, label: `10 ${minutes}` },
    { value: 900, label: `15 ${minutes}` },
    { value: 1200, label: `20 ${minutes}` }
  ];
};

const getSessionTimeout = translate => {
  const minute = translate('Common.Min');
  const minutes = translate('Common.Mins');
  const hour = translate('Common.Hour');
  const hours = translate('Common.Hours');

  return [
    { value: 60000, label: `1 ${minute}` },
    { value: 300000, label: `5 ${minutes}` },
    { value: 900000, label: `15 ${minutes}` },
    { value: 1800000, label: `30 ${minutes}` },
    { value: 3600000, label: `1 ${hour}` },
    { value: 7200000, label: `2 ${hours}` },
    { value: 14400000, label: `4 ${hours}` },
    { value: 28800000, label: `8 ${hours}` }
  ];
};

const getTemperatureTimeout = translate => {
  const minute = translate('Common.Min');
  const minutes = translate('Common.Mins');
  const hour = translate('Common.Hour');
  const hours = translate('Common.Hours');

  return [
    { value: 60000, label: `1 ${minute}` },
    { value: 300000, label: `5 ${minutes}` },
    { value: 900000, label: `15 ${minutes}` },
    { value: 1800000, label: `30 ${minutes}` },
    { value: 3600000, label: `1 ${hour}` },
    { value: 7200000, label: `2 ${hours}` },
    { value: 14400000, label: `4 ${hours}` },
    { value: 28800000, label: `8 ${hours}` }
  ];
};

const getOutOfCoverageThreshold = translate => {
  const minutes = translate('Common.Mins');
  const hour = translate('Common.Hour');
  const hours = translate('Common.Hours');

  return [
    { value: 900000, label: `15 ${minutes}` },
    { value: 1800000, label: `30 ${minutes}` },
    { value: 3600000, label: `1 ${hour}` },
    { value: 7200000, label: `2 ${hours}` },
    { value: 14400000, label: `4 ${hours}` },
    { value: 21600000, label: `6 ${hours}` }
  ];
};

const getPositionUpdateInterval = translate => {
  const minute = translate('Common.Min');
  const minutes = translate('Common.Mins');

  return [
    { value: 60000, label: `1 ${minute}`, featureFlag: FeatureFlag.trackingLoadMore.flag },
    { value: 300000, label: `5 ${minutes}` },
    { value: 600000, label: `10 ${minutes}` },
    { value: 900000, label: `15 ${minutes}` }
  ];
};

export const trackingTimeTypes = {
  minutes: 'minutes',
  hours: 'hours',
  days: 'days',
  weeks: 'weeks',
  months: 'months'
};

const getTrackingTimeType = translate => {
  return [
    { value: trackingTimeTypes.minutes, label: translate('Common.Minutes') },
    { value: trackingTimeTypes.hours, label: translate('Common.HHours') },
    { value: trackingTimeTypes.days, label: translate('Common.Days') },
    { value: trackingTimeTypes.weeks, label: translate('Common.Weeks') },
    { value: trackingTimeTypes.months, label: translate('Common.Months') }
  ];
};

const trackingTimeValues = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 30, label: 30 }
];

export const VEHICLE_MARKER_LABEL_TYPES = {
  vehicleName: 'vehicleName',
  driverName: 'driverName',
  vehicleNameDriverName: 'vehicleNameDriverName',
  registrationNumber: 'registrationNumber'
};

export const VEHICLE_MARKER_LABEL_OPTIONS = {
  vehicleName: 'Preferences.MapSettings.Vehicle.Label.VehicleName',
  driverName: 'Preferences.MapSettings.Vehicle.Label.DriverName',
  vehicleNameDriverName: 'Preferences.MapSettings.Vehicle.Label.VehicleNameDriverName',
  registrationNumber: 'Preferences.MapSettings.Vehicle.Label.RegistrationNumber'
};

export const VEHICLE_MARKER_LABEL_VISIBILITY_TYPES = {
  always: 'always',
  onHover: 'onHover'
};

export const VEHICLE_MARKER_LABEL_VISIBILITY = {
  always: 'Preferences.MapSettings.Vehicle.Label.Always',
  onHover: 'Preferences.MapSettings.Vehicle.Label.OnHover'
};

const PREFERENCES_IDS = {
  language: 'preferedLanguage',
  refresh_tracking: 'preferedTrackingRefresh',
  refresh_messaging: 'preferedMessagingRefresh',
  refresh_localdb: 'preferedLocaldbRefresh',
  session_timeout: 'preferedSessionTimeout',
  tracking_time: 'preferedTrackingTime',
  tracking_time_type: 'preferedTrackingTimeType',
  tracking_ooc_threshold: 'preferedTrackingOOCThreshold',
  tracking_temperature_timeout: 'preferedTrackingTemperatureTimeout',
  tracking_position_update_interval: 'preferedTrackingPositionUpdateInterval',
  calc_distance: 'preferedCalcDistance',
  map_type_group: 'preferedMapTypeGroup',
  map_layer_traffic: 'preferedMapLayerTraffic',
  vehicle_marker: 'preferedVehicleMarker',
  vehicle_label_vis: 'preferedVehicleLabelVis',
  vehicle_clustering: 'preferedVehicleClustering',
  user_geofence: 'preferedUserGeofence',
  managed_geofence: 'preferedManagedGeofence',
  localcache: 'localcache'
};

const UserPreferences = () => {
  const userPreferences = useUserPreferences();
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const user = useUser();
  const [preferences, setPreferences] = useState({});
  const [isTrafficLayerDisabled, setIsTrafficLayerDisabled] = useState();
  const { i18n, t } = useTranslation();
  const can = useCan();
  const canLiveRefresh = useCanEveryService(services.TRACKINGLIVE);
  const regions = useAllRegions();
  const geofenceProviders = useCompanyGeofenceProviders();
  const isNonProd = can({ featureFlag: [FeatureFlag.changeLanguage.flag] });

  const languageOptions = () => {
    const options = [];
    regions.forEach(region => {
      let locales_available = [];
      try {
        locales_available = JSON.parse(region.config).locales_available;
      } catch (error) {
        console.error(error);
      }
      if (locales_available?.length) {
        locales_available.forEach(locale => {
          // do not allow locale duplicates
          if (!options.find(opt => opt.locale === locale)) {
            options.push({
              locale,
              country: t(`Preferences.ChangeLanguage.${locale}`)
            });
          }
        });
      }
    });

    // fallback to en-AU if no language is available in regions -> locales_available
    if (!options.length) {
      options.push({
        locale: LANGUAGE_LOCALES.EN_AU,
        country: t(`Preferences.ChangeLanguage.${LANGUAGE_LOCALES.EN_AU}`)
      });
    }
    options.sort((a, b) => sortStrings(a.country, b.country));
    if (isNonProd) {
      const extra = [
        { locale: 'test', country: 'Test Strings' },
        { locale: 'cimode', country: 'Test Keys' }
      ];
      return [...options, ...extra];
    } else {
      return options;
    }
  };

  const userPreferedLng = () => {
    if (!preferences?.language) {
      return DEFAULT_PREFERENCES.language;
    } else {
      const language = preferences?.language.split('-');
      if (language.length === 1) {
        return language[0];
      }
      return language[0] + '-' + language[1].toUpperCase();
    }
  };

  useEffect(() => {
    setPreferences({
      ...DEFAULT_PREFERENCES,
      ...userPreferences,
      refresh: {
        ...DEFAULT_PREFERENCES.refresh,
        ...userPreferences?.refresh
      }
    });
  }, [userPreferences]);

  useEffect(() => {
    dispatch(setPageTitle(t('Preferences.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    if (preferences.mapType === 'satellite') {
      setIsTrafficLayerDisabled(true);
      setPreferences(preferences => ({
        ...preferences,
        mapLayerTraffic: false
      }));
    } else {
      setIsTrafficLayerDisabled(false);
    }
  }, [preferences.mapType]);

  const save = useCallback(() => {
    const oldLanguage = userPreferences?.language,
      lang = preferences.language;
    const prevUseLocalCache = userPreferences?.useLocalCache;
    i18n.changeLanguage(lang, (err, t) => {
      changeMapLanguage(lang, oldLanguage);
      setUserPreferences(preferences, user.id, userKey)
        .then(res => {
          dispatch(
            openToast({
              type: ToastType.Success,
              message: `${t('Preferences.Save.SuccessMessage')}.`
            })
          );
          if (!preferences.useLocalCache) {
            TNDataService.deleteAllUserDBs(user.id);
          }

          if (prevUseLocalCache != preferences.useLocalCache) {
            dispatch(setIsAppReady(false));
          }
          dispatch(fetchPreferences());
        })
        .catch(() => {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: `${t('Preferences.Save.ErrorMessage')}`
            })
          );
        });
    });
  }, [i18n, userPreferences, preferences, user, userKey, dispatch]);

  const cancel = () => {
    setPreferences({
      ...DEFAULT_PREFERENCES,
      ...userPreferences
    });
    dispatch(
      openToast({
        type: ToastType.Info,
        message: `${t('Preferences.Save.CancelMessage')}.`
      })
    );
  };

  const handleRefreshChange = (type, val) => {
    setPreferences({
      ...preferences,
      refresh: {
        ...preferences?.refresh,
        [type]: val
      }
    });
  };

  const handleTemperatureTimeoutChange = temperatureTimeout => {
    setPreferences({
      ...preferences,
      temperatureTimeout
    });
  };

  const handleTrackingPositionUpdateIntervalChange = trackingPositionUpdateInterval => {
    setPreferences({
      ...preferences,
      trackingPositionUpdateInterval
    });
  };

  const handleOutOfCoverageThreshold = trackingOutOfCoverageThreshold => {
    setPreferences({
      ...preferences,
      trackingOutOfCoverageThreshold
    });
  };

  const onTrackingTimeValueChange = trackingTimeValue => {
    setPreferences({
      ...preferences,
      trackingTimeValue
    });
  };

  const onTrackingTimeTypeChange = trackingTimeType => {
    setPreferences({
      ...preferences,
      trackingTimeType
    });
  };

  const onLanguageChange = e => {
    setPreferences({
      ...preferences,
      language: e
    });
  };

  const onLocalCacheChange = checked => {
    setPreferences({
      ...preferences,
      useLocalCache: checked
    });
  };

  const onShowGeofenceChange = e => {
    setPreferences({
      ...preferences,
      showGeofences: e.target.checked
    });
  };

  const onShowManagedGeofenceChange = e => {
    setPreferences({
      ...preferences,
      showManagedGeofences: e.target.checked
    });
  };

  const onMapTypeChange = e => {
    setPreferences({
      ...preferences,
      mapType: e.target.value
    });
  };

  const onLabelVisibilityChange = e => {
    setPreferences({
      ...preferences,
      vehicleMarkerVisibility: e.target.value
    });
  };

  const onMapLayerTrafficChange = e => {
    setPreferences({
      ...preferences,
      mapLayerTraffic: e.target.checked
    });
  };

  const onCalculateDistanceChange = e => {
    setPreferences({
      ...preferences,
      calculateDistance: e.target.checked
    });
  };

  const onVehicleMarkerChange = vehicleMarkerLabel => {
    setPreferences({
      ...preferences,
      vehicleMarker: vehicleMarkerLabel
    });
  };

  const onClusteringChange = e => {
    setPreferences({
      ...preferences,
      clustering: e.target.value
    });
  };

  const TrackingRefreshRate = () => (
    <>
      <div className={styles.subTitle}>{t('Preferences.RefreshRate.Tracking')}</div>
      <div className={styles.label}>{t('Preferences.RefreshRate.RefreshIn')}</div>
      <Select
        id={PREFERENCES_IDS.refresh_tracking}
        value={preferences?.refresh?.tracking}
        size="large"
        className={styles.selectElement}
        listHeight={400}
        onChange={val => handleRefreshChange('tracking', val)}
      >
        {canLiveRefresh && (
          <Option key={`interval_tracking_-1`} value={-1}>
            {t('Preferences.RefreshRate.LiveRefresh')}
          </Option>
        )}
        {getRefreshInterval(t).map((interval, index) => (
          <Option key={`interval_tracking_${index}`} value={interval.value}>
            {interval.label}
          </Option>
        ))}
      </Select>
      <Divider className={styles.divider} />
    </>
  );

  const MessagingRefreshRate = () => (
    <>
      <div className={styles.subTitle}>{t('Preferences.RefreshRate.Messaging')}</div>
      <div className={styles.label}>{t('Preferences.RefreshRate.RefreshIn')}</div>
      <Select
        id={PREFERENCES_IDS.refresh_messaging}
        value={preferences?.refresh?.messaging}
        size="large"
        className={styles.selectElement}
        listHeight={400}
        onChange={val => handleRefreshChange('messaging', val)}
      >
        {getRefreshInterval(t).map((interval, index) => (
          <Option key={`interval_messaging_${index}`} value={interval.value}>
            {interval.label}
          </Option>
        ))}
      </Select>
      <Divider className={styles.divider} />
    </>
  );

  const LocalCacheRefreshRate = () => (
    <>
      <div className={styles.subTitle}>{'Sync local db'}</div>
      <div className={styles.label}>{t('Preferences.RefreshRate.RefreshIn')}</div>
      <Select
        id={PREFERENCES_IDS.refresh_localdb}
        value={preferences?.refresh?.localdb}
        size="large"
        className={styles.selectElement}
        listHeight={400}
        onChange={val => handleRefreshChange('localdb', val)}
      >
        {getRefreshInterval(t).map((interval, index) => (
          <Option key={`interval_localdb_${index}`} value={interval.value}>
            {interval.label}
          </Option>
        ))}
      </Select>
      <Divider className={styles.divider} />
    </>
  );

  const SessionTimeout = () => (
    <>
      <div className={styles.subTitle}>{t('Preferences.RefreshRate.SessionTimeout')}</div>
      <div className={styles.label}>{t('Preferences.RefreshRate.NotifyAfter')}</div>
      <Select
        id={PREFERENCES_IDS.session_timeout}
        value={preferences?.refresh?.sessionTimeout}
        size="large"
        className={styles.selectElement}
        onChange={val => handleRefreshChange('sessionTimeout', val)}
      >
        {getSessionTimeout(t).map((interval, index) => (
          <Option key={`interval_session_timeout_${index}`} value={interval.value}>
            {interval.label}
          </Option>
        ))}
      </Select>
    </>
  );

  const RefreshPreferences = () => (
    <div className={styles.container}>
      <Title level={4}>{t('Preferences.RefreshRate.RefreshRateTitle')}</Title>
      <Divider style={{ margin: 0 }} />
      <div className={styles.info}>{t('Preferences.RefreshRate.Select')}.</div>
      <Can featureHide={companyFeatures.HTF}>
        <TrackingRefreshRate />
      </Can>
      <MessagingRefreshRate />
      <SessionTimeout />
    </div>
  );

  const LanguagePreferences = () => (
    <div className={styles.container}>
      <Title level={4}>{t('Preferences.ChangeLanguage.ChangeLanguageTitle')}</Title>
      <Divider style={{ margin: 0 }} />
      <div className={styles.info}>{t('Preferences.ChangeLanguage.Select')}.</div>
      <Select
        id={PREFERENCES_IDS.language}
        onChange={onLanguageChange}
        value={userPreferedLng()}
        size="large"
        style={{
          width: '250px'
        }}
      >
        {languageOptions().map(option => (
          <Option key={option.locale} value={option.locale} id={option.locale}>
            {option.country}
          </Option>
        ))}
      </Select>
    </div>
  );

  const LanguageAndRefreshPreferences = () => (
    <>
      <LanguagePreferences />
      <RefreshPreferences />
    </>
  );

  const LocalCachePreferences = () =>
    user.isSiteAdmin ? (
      <div className={styles.container}>
        <Title level={4}>{t('Preferences.LocalCache.Title')}</Title>
        <Divider style={{ margin: 0 }} />
        <div className={styles.info}>{t('Preferences.LocalCache.Switch')}</div>
        <div className={styles.label}>{t('Preferences.LocalCache.UseLocalCache')}</div>
        <Switch
          id={PREFERENCES_IDS.localcache}
          onChange={onLocalCacheChange}
          checked={preferences.useLocalCache}
        />
        {/*the sync is incomplete, disable it for now to avoid confusing*/}
        {/*preferences.useLocalCache && <LocalCacheRefreshRate />*/}
      </div>
    ) : null;

  const TrackingPreferences = () => (
    <Can featureHide={companyFeatures.HTF}>
      <div className={styles.container}>
        <Title level={4}>{t('Preferences.TrackingSettings.Title')}</Title>
        <Divider style={{ margin: 0 }} />
        <div className={styles.info}>{t('Preferences.TrackingSettings.Info')}</div>

        <div className={styles.label}>{t('Preferences.TrackingSettings.Inactive')}</div>
        <Space size={16}>
          <Select
            id={PREFERENCES_IDS.tracking_time}
            size="large"
            style={{
              width: '150px'
            }}
            value={preferences?.trackingTimeValue || DEFAULT_PREFERENCES.trackingTimeValue}
            onChange={onTrackingTimeValueChange}
          >
            {trackingTimeValues.map((interval, index) => (
              <Option key={`tracking_interval_values_${index}`} value={interval.value}>
                {interval.label}
              </Option>
            ))}
          </Select>
          <Select
            id={PREFERENCES_IDS.tracking_time_type}
            size="large"
            style={{
              width: '150px'
            }}
            value={preferences?.trackingTimeType || DEFAULT_PREFERENCES.trackingTimeType}
            onChange={onTrackingTimeTypeChange}
          >
            {getTrackingTimeType(t).map((interval, index) => (
              <Option key={`tracking_interval_type_${index}`} value={interval.value}>
                {interval.label}
              </Option>
            ))}
          </Select>
        </Space>

        <div className={styles.label}>
          {t('Preferences.TrackingSettings.OutOfCoverageThreshold')}
        </div>
        <Select
          id={PREFERENCES_IDS.tracking_ooc_threshold}
          value={preferences?.trackingOutOfCoverageThreshold}
          size="large"
          className={styles.selectElement}
          onChange={val => handleOutOfCoverageThreshold(val)}
        >
          {getOutOfCoverageThreshold(t).map((interval, index) => (
            <Option key={`interval_temperature_timeout_${index}`} value={interval.value}>
              {interval.label}
            </Option>
          ))}
        </Select>

        <div className={styles.label}>{t('Preferences.TrackingSettings.TemperatureTimeout')}</div>
        <Select
          id={PREFERENCES_IDS.tracking_temperature_timeout}
          value={preferences?.temperatureTimeout}
          size="large"
          className={styles.selectElement}
          onChange={val => handleTemperatureTimeoutChange(val)}
        >
          {getTemperatureTimeout(t).map((interval, index) => (
            <Option key={`interval_temperature_timeout_${index}`} value={interval.value}>
              {interval.label}
            </Option>
          ))}
        </Select>

        <div className={styles.label}>
          {t('Preferences.TrackingSettings.PositionUpdateInterval')}
        </div>
        <Select
          id={PREFERENCES_IDS.tracking_position_update_interval}
          value={preferences?.trackingPositionUpdateInterval}
          size="large"
          className={styles.selectElement}
          onChange={val => handleTrackingPositionUpdateIntervalChange(val)}
        >
          {getPositionUpdateInterval(t)
            .filter(option => !option.featureFlag || can({ featureFlag: option.featureFlag }))
            .map((interval, index) => (
              <Option key={`tracking_position_update_interval_${index}`} value={interval.value}>
                {interval.label}
              </Option>
            ))}
        </Select>

        <div className={styles.label}>{t('Preferences.TrackingSettings.InfoWindow')}</div>
        <Checkbox
          id={PREFERENCES_IDS.calc_distance}
          checked={
            preferences?.calculateDistance !== undefined
              ? preferences?.calculateDistance
              : DEFAULT_PREFERENCES.calculateDistance
          }
          onChange={onCalculateDistanceChange}
        >
          {t('Preferences.TrackingSettings.CalculateDistance')}
        </Checkbox>
      </div>
    </Can>
  );

  const TrackingAndLocalCachePreferences = () => (
    <>
      <TrackingPreferences />
      <LocalCachePreferences />
    </>
  );

  const MapPreferences = () => (
    <div className={styles.container}>
      <Title level={4}>{t('Preferences.MapSettings.Title')}</Title>
      <Divider style={{ margin: 0 }} />
      <div className={styles.info}>{t('Preferences.MapSettings.Info')}</div>

      <div className={styles.subTitle}>{t('Preferences.MapSettings.Map.Title')}</div>
      <div className={styles.label}>{t('Preferences.MapSettings.Map.Type.Title')}</div>
      <Radio.Group
        id={PREFERENCES_IDS.map_type_group}
        name="mapTypeRadioGroup"
        onChange={onMapTypeChange}
        value={preferences?.mapType || DEFAULT_PREFERENCES.mapType}
      >
        <Radio className={styles.radioButton} value={'roadmap'} id="roadmap">
          {t('Preferences.MapSettings.Map.Type.Road')}
        </Radio>
        <Radio className={styles.radioButton} value={'terrain'} id="terrain">
          {t('Preferences.MapSettings.Map.Type.Terrain')}
        </Radio>
        <Radio className={styles.radioButton} value={'satellite'} id="satellite">
          {t('Preferences.MapSettings.Map.Type.Satellite')}
        </Radio>
        <Radio className={styles.radioButton} value={'hybrid'} id="hybrid">
          {t('Preferences.MapSettings.Map.Type.Hybrid')}
        </Radio>
      </Radio.Group>
      <div className={styles.label}>{t('Preferences.MapSettings.Map.Layers.Title')}</div>
      <Checkbox
        id={PREFERENCES_IDS.map_layer_traffic}
        checked={preferences?.mapLayerTraffic || DEFAULT_PREFERENCES.mapLayerTraffic}
        onChange={onMapLayerTrafficChange}
        disabled={isTrafficLayerDisabled}
      >
        {t('Preferences.MapSettings.Map.Layers.Traffic')}
      </Checkbox>
      <Divider className={styles.divider} />

      <div className={styles.subTitle}>{t('Preferences.MapSettings.Vehicle.Title')}</div>
      <div className={styles.label}>{t('Preferences.MapSettings.Vehicle.Label.Title')}</div>
      <Select
        id={PREFERENCES_IDS.vehicle_marker}
        onChange={onVehicleMarkerChange}
        value={preferences?.vehicleMarker || DEFAULT_PREFERENCES.vehicleMarker}
        size="large"
        style={{
          width: '250px'
        }}
      >
        {Object.keys(VEHICLE_MARKER_LABEL_OPTIONS).map((option, index) => (
          <Option key={`vml${index}`} value={option} id={option}>
            {t(VEHICLE_MARKER_LABEL_OPTIONS[option])}
          </Option>
        ))}
      </Select>
      <div className={styles.label}>{t('Preferences.MapSettings.Vehicle.Label.Visibility')}</div>
      <Radio.Group
        id={PREFERENCES_IDS.vehicle_label_vis}
        name="vehicleLabelVisRadioGroup"
        onChange={onLabelVisibilityChange}
        value={preferences?.vehicleMarkerVisibility || DEFAULT_PREFERENCES.vehicleMarkerVisibility}
      >
        <Radio
          className={styles.radioButton}
          value={VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.onHover}
          id={VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.onHover}
        >
          {t(VEHICLE_MARKER_LABEL_VISIBILITY.onHover)}
        </Radio>
        <Radio
          className={styles.radioButton}
          value={VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.always}
          id={VEHICLE_MARKER_LABEL_VISIBILITY_TYPES.always}
        >
          {t(VEHICLE_MARKER_LABEL_VISIBILITY.always)}
        </Radio>
      </Radio.Group>
      <div className={styles.label}>{t('Preferences.MapSettings.Vehicle.Clustering.Title')}</div>
      <Radio.Group
        id={PREFERENCES_IDS.vehicle_clustering}
        name="vehicleClusteringRadioGroup"
        onChange={onClusteringChange}
        value={preferences?.clustering || DEFAULT_PREFERENCES.clustering}
      >
        <Radio className={styles.radioButton} value={true} id="clustered">
          {t('Preferences.MapSettings.Vehicle.Clustering.Clustered')}
        </Radio>
        <Radio className={styles.radioButton} value={false} id="unclustered">
          {t('Preferences.MapSettings.Vehicle.Clustering.Unclustered')}
        </Radio>
      </Radio.Group>
      <Divider className={styles.divider} />

      <div className={styles.subTitle}>{t('Common.Geofence')}</div>
      <Checkbox
        id={PREFERENCES_IDS.user_geofence}
        checked={
          preferences?.showGeofences !== undefined
            ? preferences?.showGeofences
            : DEFAULT_PREFERENCES.showGeofences
        }
        onChange={onShowGeofenceChange}
      >
        {t('Preferences.TrackingSettings.ShowGeofences')}
      </Checkbox>
      {geofenceProviders?.length > 0 && (
        <Checkbox
          id={PREFERENCES_IDS.managed_geofence}
          checked={
            preferences?.showManagedGeofences !== undefined
              ? preferences?.showManagedGeofences
              : DEFAULT_PREFERENCES.showManagedGeofences
          }
          onChange={onShowManagedGeofenceChange}
        >
          {t('Preferences.TrackingSettings.ShowManagedGeofences')}
        </Checkbox>
      )}
    </div>
  );

  return (
    <Layout className={styles.mainContainer}>
      <List
        style={{ paddingBottom: 64 }}
        grid={{
          gutter: 24,
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 2,
          xxl: 3
        }}
        itemLayout={'horizontal'}
        loading={!preferences}
        dataSource={[
          LanguageAndRefreshPreferences,
          MapPreferences,
          TrackingAndLocalCachePreferences
        ]}
        renderItem={(component, index) => <List.Item key={index}>{component()}</List.Item>}
      />

      <Footer className={styles.footer}>
        <Space size={16}>
          <Button type="primary" size="large" onClick={save} id={BUTTON_IDS.preferencesSave}>
            {t('Common.SaveButton')}
          </Button>
          <Button size="large" onClick={cancel} id={BUTTON_IDS.preferencesCancel}>
            {t('Common.CancelButton')}
          </Button>
        </Space>
      </Footer>
    </Layout>
  );
};

export default UserPreferences;
