import MockDrawerImage from './MockDrawerImage.svg';
import { Button } from 'antd';
import styles from './WidgetCard.module.scss';
import { useTranslation } from 'react-i18next';

export const WidgetCard = ({ widget, handleAdd }) => {
  const { t } = useTranslation();

  const handleAddWidget = () => {
    handleAdd(widget.id);
  };

  return (
    <div className={styles.widgetCardContainer}>
      <div className={styles.imageContainer}>
        <img src={MockDrawerImage} alt="Widget Image" className={styles.image} />
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.textContainer}>
          <h4>{widget.title}</h4>
          <p>Small text that describes what the widget does, and who might find it usefull</p>
        </div>
        <div className={styles.buttonContainer}>
          <Button type="primary" size="small" onClick={handleAddWidget}>
            {t('NewHome.Drawer.Add')}
          </Button>
        </div>
      </div>
    </div>
  );
};
