import { EllipsisButton } from 'components/tables/EllipsisButton';
import i18n from 'i18next';
import { default as React } from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import styles from './Devices.module.scss';
import services from 'features/permissions/services';
import { deviceTypeModelTranslate } from './constants';
import { Tooltip } from 'components/ant';
import Draggable from 'react-draggable';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const detailsCellRenderer = ({
  rowIndex,
  rowData,
  dataKey,
  parent,
  companies,
  fleetsAPIList
}) => {
  const device = rowData;
  const company = companies?.find(comp => comp.id === device.companyId);
  const companyName = company ? company.name : '';
  const fleets = (device.fleetInfo || [])
    .filter(fleet => fleet.id)
    .map((fleet, i) => {
      const companyFleetName =
        companies?.find(
          company =>
            company.id === fleetsAPIList?.find(fleetAPI => fleetAPI.id === fleet.id)?.company?.id
        )?.name || '';
      return (
        <div key={`row-${dataKey}-${device.id}-${i}`} className={styles.cellEllipsisContent}>
          {fleet.name} {`(${companyFleetName})`}
          {i < device.fleetInfo.length - 1 ? ',' : ''}
        </div>
      );
    });
  const { serialNumber, phone, note, externalId, sim } = device;

  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${device.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div className={styles.cellMultilineContainer}>
        <div className={styles.cellSubtitle}>{i18n.t('Devices.Company')}</div>
        <div className={styles.cellEllipsisContent}>{companyName}</div>
        {fleets.length > 0 && (
          <>
            <div className={styles.cellSubtitle}>{i18n.t('Devices.Fleet')}</div>
            <div className={styles.cellEllipsisContent}>{fleets}</div>
          </>
        )}
        {serialNumber && (
          <>
            <div className={styles.cellSubtitle}>{i18n.t('Devices.SerialNumber')}</div>
            <div className={styles.cellEllipsisContent}>{serialNumber}</div>
          </>
        )}
        {phone && (
          <>
            <div className={styles.cellSubtitle}>{i18n.t('Devices.PhoneNumber')}</div>
            <div className={styles.cellEllipsisContent}>{phone}</div>
          </>
        )}
        {sim && (
          <>
            <div className={styles.cellSubtitle}>{i18n.t('Devices.SIM')}</div>
            <div className={styles.cellEllipsisContent}>{sim}</div>
          </>
        )}
        {externalId && (
          <>
            <div className={styles.cellSubtitle}>{i18n.t('Devices.ExternalID')}</div>
            <div className={styles.cellEllipsisContent}>{externalId}</div>
          </>
        )}
        {note && (
          <>
            <div className={styles.cellSubtitle}>{i18n.t('Devices.Notes')}</div>
            <Tooltip
              content={note}
              target={<div className={styles.cellEllipsisContent}>{note}</div>}
            />
          </>
        )}
      </div>
    </CellMeasurer>
  );
};

export const deviceNameCellRenderer = ({ dataKey, parent, rowIndex, rowData, canViewDevice }) => {
  return canViewDevice ? (
    <Link to={`/settings/devices/id/${rowData.id}`}>{rowData.name}</Link>
  ) : (
    <span>{rowData.name}</span>
  );
};

export const typeCellRenderer = ({ typeName, modelName }) => {
  return (
    <Tooltip
      content={deviceTypeModelTranslate(modelName || typeName)}
      target={deviceTypeModelTranslate(typeName)}
    />
  );
};

export const gpioCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <>
      {rowData?.gpioTemplate !== undefined && rowData?.services.includes(services.GPIO) ? (
        <Link
          key={`configuration-${rowData?.gpioTemplate?.id}`}
          to={`/settings/company_config/gpio/id/${rowData?.gpioTemplate?.id}`}
        >
          <div className={styles.cellEllipsisContent}>{rowData?.gpioTemplate?.name}</div>
        </Link>
      ) : (
        <>-</>
      )}
    </>
  );
};

export const vehicleCellRenderer = ({ dataKey, parent, rowIndex, rowData, canViewVehicle }) => {
  return (
    <div className={styles.cellMultilineContainer}>
      {rowData.vehicle && rowData.vehicleInfo ? (
        <>
          <div className={styles.cellSubtitle}>{i18n.t('Devices.Name')}</div>
          {canViewVehicle ? (
            <Link to={`/settings/vehicles/id/${rowData.vehicleInfo.id}`}>
              {rowData.vehicleInfo.name}
            </Link>
          ) : (
            <span>{rowData.vehicleInfo.name}</span>
          )}
          <div className={styles.cellSubtitle}>{i18n.t('Devices.Registration')}</div>
          <div className={styles.cellEllipsisContent}>
            {rowData.vehicle && rowData.vehicle.registrationState} |{' '}
            {rowData.vehicle && rowData.vehicle.registration}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  typeOfEntityToDelete,
  hideEdit,
  hideConfig,
  hideDelete,
  enableRestoreAction,
  handleDeleteAction,
  handleAction,
  customAction,
  customDeleteContent
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      typeOfEntityToDelete={typeOfEntityToDelete}
      path={'/settings/devices/'}
      data={rowData}
      canUse="DEVICE"
      hideEdit={hideEdit}
      hideConfig={hideConfig}
      hideDelete={hideDelete}
      hideCopy={true}
      customDeleteContent={customDeleteContent}
      enableRestoreAction={enableRestoreAction}
      handleDeleteAction={handleDeleteAction}
      handleAction={handleAction}
      customAction={customAction}
    />
  );
};

export const OrderedTextRenderer = ({ text = [], color = '#fff' }) => (
  <ol className={styles.orderedText} style={{ color }}>
    {text.map((t, index) =>
      t instanceof Array ? (
        <ol type="a" className={styles.orderedSubText} key={`points-${index}`}>
          {t.map((subT, subIndex) => (
            <li key={`point-${subIndex}`}>{subT}</li>
          ))}
        </ol>
      ) : (
        <li key={`point-${index}`}>{t}</li>
      )
    )}
  </ol>
);

export const modelCellRenderer = ({ modelName }) => {
  return <Tooltip content={modelName} />;
};

export const headerRenderer = ({ resizeRow, dataKey, label }) => {
  if (dataKey === 'actions') {
    return (
      <div className={`ActionsCol_AlignRight ${styles.actionsHeader}`}>
        <div className="ReactVirtualized__Table__headerTruncatedText">{label}</div>
      </div>
    );
  }
  return (
    <div key={dataKey}>
      <div className={styles.resizableColumnWrapper}>
        <div className="ReactVirtualized__Table__headerTruncatedText">{label}</div>
        <Draggable
          axis="x"
          defaultClassName={styles.resizableColumnDragHandle}
          onDrag={(_, { deltaX }) =>
            resizeRow({
              dataKey,
              deltaX
            })
          }
          position={{ x: 0 }}
        >
          <span className="DragHandleIcon">⋮</span>
        </Draggable>
      </div>
    </div>
  );
};
