import { TNDataService } from '../tnDataService';
import { locationTypesDataAdapter } from './locationTypes';
import { reduxAdapter } from './reduxAdapter';

const locationsDataAdapter = reduxAdapter({
  persist: async locations => {
    if (locations && locations.length) {
      for (const l of locations) {
        if (l.id) {
          await TNDataService.insertOrUpdateLocation(l);
        }
      }
    }
  },
  load: async () => {
    const locations = await TNDataService.getAllLocations();
    const locationTypes = await locationTypesDataAdapter.load();
    locations?.forEach(l => {
      if (l.type?.id) {
        const locationType = locationTypes.find(lt => lt.id === l.type.id);
        if (locationType) {
          l.type = locationType;
        }
      }
    });
    return locations;
  }
});

export { locationsDataAdapter };
