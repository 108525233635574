import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import moment from 'moment';
import { keyBy, isArray, toString } from 'lodash';
import request from 'superagent';

import { ApiClient, DevicesApi } from 'nextgen_api';

import { openToast } from 'features/toasts/toastsSlice';
import { fetchFleets } from 'features/fleets/fleetsSlice';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { fetchDeletedDevices } from './devicesDeletedSlice';

import { ToastType } from 'components/notifications/toasts/Toast';

import { api } from 'utils/api';
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage, parseUserErrorMessage } from 'utils/strings';

import { API_PATH } from 'config';
import { useEffect, useMemo, useState } from 'react';
import {
  deviceCapabilities,
  getCompanyDeviceFeatures
} from 'features/permissions/deviceFeaturesByFirmware';
import { devicesDataAdapter } from 'data/dataService/tn/adapter/devices';
import { useUserKey } from 'features/user/userSlice';
import { ngGpioConfigurationApi } from 'services/nextgen/ngGpioConfigurationApi';

const DEVICE_DELETE_URL = '/devices/';
const DEVICES_URL = '/devices';

const devices = {
  byID: {},
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false,
    companyId: null,
    isBulkEditing: false,
    isBulkAssigningAgreement: false,
    bulkUpdate: {
      isUpdating: false,
      failureDevicesMap: null,
      lastUpdated: null
    },
    bulkDelete: {
      isDeleting: false,
      failureDevicesMap: null,
      lastDeleted: null
    }
  },
  devicesIOREvtsMeta: {
    isFetching: false,
    error: null
  }
};

const devicesSlice = createSlice({
  name: 'devicesSlice',
  initialState: devices,
  reducers: {
    fetchDevicesStart(state, { payload }) {
      state.meta.isFetching = true;
      state.meta.companyId = payload?.companyId;
    },
    fetchDevicesSuccess(state, { payload }) {
      state.byID = keyBy(payload.list, 'id');
      state.meta.isFetching = false;
      state.meta.lastFetched = moment().format();
      state.meta.error = null;
      state.meta.isListEmpty = !payload.list.length;
    },
    fetchDevicesFailure(state, { payload }) {
      state.byID = {};
      state.meta.isFetching = false;
      state.meta.lastFetched = moment().format();
      state.meta.error = payload.err;
      state.meta.isListEmpty = false;
    },
    fetchDevicesStatsStart(state) {},
    fetchDevicesStatsSuccess(state, { payload }) {
      const devicesWithStats = state.byID;
      payload.list.forEach(deviceStat => {
        if (state.byID[deviceStat.deviceId]) {
          devicesWithStats[deviceStat.deviceId] = {
            ...deviceStat,
            ...state.byID[deviceStat.deviceId]
          };
        }
      });
      state.byID = devicesWithStats;
      state.meta.lastFetched = moment().format();
      state.meta.error = null;
      state.meta.isListEmpty = !payload.list.length;
    },
    fetchDevicesStatsFailure(state, { payload }) {
      state.byID = {};
      state.meta.lastFetched = moment().format();
      state.meta.error = payload.err;
      state.meta.isListEmpty = false;
    },
    fetchDevicesIOREvtsStart(state, { payload }) {
      state.devicesIOREvtsMeta.isFetching = true;
    },
    fetchDevicesIOREvtsSuccess(state, { payload }) {
      state.devicesIOREvtsMeta.isFetching = false;
      state.devicesIOREvtsMeta.error = null;
    },
    fetchDevicesIOREvtsFailure(state, { payload }) {
      state.devicesIOREvtsMeta.isFetching = false;
      state.devicesIOREvtsMeta.error = payload.error;
    },
    bulkUpdateDevicesStart(state, { payload }) {
      state.meta.bulkUpdate.isUpdating = true;
      const ids = (payload.devices || []).map(d => d.id);
      for (const id of ids) {
        delete state.meta.bulkUpdate.failureDevicesMap?.[id];
      }
    },
    bulkUpdateDevicesEnd(state, { payload }) {
      state.meta.bulkUpdate.isUpdating = false;
      state.meta.bulkUpdate.lastUpdated = moment().format();
      state.meta.bulkUpdate.failureDevicesMap = {
        ...state.meta.bulkUpdate.failureDevicesMap,
        ...payload.failureDevicesMap
      };
    },
    bulkDeleteDevicesStart(state, { payload }) {
      state.meta.bulkDelete.isDeleting = true;
      const ids = (payload.devices || []).map(d => d.id);
      for (const id of ids) {
        delete state.meta.bulkDelete.failureDevicesMap?.[id];
      }
    },
    bulkDeleteDevicesEnd(state, { payload }) {
      state.meta.bulkDelete.isDeleting = false;
      state.meta.bulkDelete.lastDeleted = moment().format();
      state.meta.bulkDelete.failureDevicesMap = {
        ...state.meta.bulkDelete.failureDevicesMap,
        ...payload.failureDevicesMap
      };
    },
    clearBulkError(state, { payload }) {
      state.meta.bulkUpdate.failureDevicesMap = null;
      state.meta.bulkDelete.failureDevicesMap = null;
    },
    setIsBulkEdit(state, { payload }) {
      state.meta.isBulkEditing = payload.isBulkEditing;
    },
    setIsBulkAgreementAssignment(state, { payload }) {
      state.meta.isBulkAssigningAgreement = payload.isBulkAssigningAgreement;
    }
  }
});

const {
  fetchDevicesStart,
  fetchDevicesSuccess,
  fetchDevicesFailure,
  fetchDevicesStatsStart,
  fetchDevicesStatsSuccess,
  fetchDevicesStatsFailure,
  fetchDevicesIOREvtsStart,
  fetchDevicesIOREvtsSuccess,
  fetchDevicesIOREvtsFailure,
  bulkUpdateDevicesStart,
  bulkUpdateDevicesEnd,
  bulkDeleteDevicesStart,
  bulkDeleteDevicesEnd,
  clearBulkError,
  setIsBulkEdit,
  setIsBulkAgreementAssignment
} = devicesSlice.actions;

export const fetchDeviceStats = () => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies?.current?.id;
  try {
    if (!companyKey || !companyId || !userKey) {
      return;
    }
    dispatch(fetchDevicesStatsStart());
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    const devicesStatsApi = new DevicesApi(apiClient);
    devicesStatsApi.deviceStats(
      userKey,
      { embed: 'users', pruning: 'APP', company_id: companyId },
      (err, data, resp) => {
        if (err) {
          dispatch(fetchDevicesStatsFailure({ err: err.toString(), companyKey }));
        } else {
          dispatch(fetchDevicesStatsSuccess({ list: resp.body, companyKey }));
        }
      }
    );
  } catch (err) {
    dispatch(fetchDevicesStatsFailure({ err: err.toString(), companyKey }));
  }
};

export const fetchDevices = (embed = 'services,vehicles,users') => (dispatch, getState) => {
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;
  const meta = getState().entities.devices.meta;
  const isFetchingCurrentCompany = meta?.isFetching && meta?.companyId === companyId;

  try {
    if (!companyId || !userKey || isFetchingCurrentCompany) {
      return;
    }

    dispatch(fetchDevicesStart({ companyId }));
    devicesDataAdapter.load().then(devices => {
      if (devices?.length > 0) {
        dispatch(fetchDevicesSuccess({ list: devices }));
        dispatch(fetchDeviceStats());
      } else {
        request('GET', API_PATH + '/devices/')
          .set('Authorization', `Token token="${userKey}"`)
          .query({
            embed: embed,
            company_id: companyId
          })
          .then(resp => {
            if (resp.error) {
              dispatch(fetchDevicesFailure({ err: resp.body.toString() }));
            } else {
              devicesDataAdapter.persist(resp.body);
              dispatch(fetchDevicesSuccess({ list: resp.body }));
              dispatch(fetchDeviceStats());
            }
          });
      }
    });
  } catch (err) {
    dispatch(fetchDevicesFailure({ err: err.toString() }));
  }
};

export const deleteDeviceApi = (data, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const { id, name, companyId } = data;
  const parameters = `?company_id=${companyId}`;

  try {
    const response = await api.delete(`${DEVICE_DELETE_URL}${id}${parameters}`, { authKey });
    const { ok, redirect, error } = response;

    // error
    if (!ok && !redirect) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: error
        })
      );
    }

    // success
    if (ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Devices.Notifications.DeviceDeleted', {
            name: name
          })
        })
      );
      dispatch(fetchFleets());
      dispatch(fetchDeletedDevices());
      history && canHistoryGoBack(history, '/settings/devices');
    }
  } catch (err) {
    console.log(err);
  }
};

export const restoreDeviceApi = data => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `${API_PATH}/devices/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Devices.Notifications.DeviceRestoredSuccess', { name })
          })
        );
        dispatch(fetchFleets());
        dispatch(fetchDeletedDevices());
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export const useDevices = embed => {
  const dispatch = useDispatch();
  const devices = useSelector(state => state.entities.devices.byID);
  const isFetching = useSelector(state => state.entities.devices.meta.isFetching);
  const lastFetched = useSelector(state => state.entities.devices.meta.lastFetched);
  const lastCompanyId = useSelector(state => state.entities.devices.meta.companyId);
  const isCompanyDifferent = useCurrentCompanyId() !== lastCompanyId;

  if (!isFetching && (!lastFetched || isCompanyDifferent)) {
    dispatch(fetchDevices(embed));
  }

  return devices;
};

export const useIsFetchingDevices = () =>
  useSelector(state => state.entities.devices.meta?.isFetching);

export const saveDeviceFleetsApi = (id, payload) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyId = getState().companies.current.id;

  try {
    const response = await api.put(
      `/devices/${id}/fleets`,
      {
        authKey,
        query: {
          company_id: companyId
        }
      },
      payload
    );

    if (!response || response.status !== 200) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `Something went wrong while associating fleets to devices!`
        })
      );
    }

    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};

export const saveDeviceMeterLabel = (id, payload) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;

  try {
    const response = await api.post(
      `/devices/${id}/updatemeterlabels`,
      {
        authKey
      },
      payload
    );

    if (!response || response.status !== 200) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `Something went wrong while associating label to source for OneWire!`
        })
      );
    }

    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};

export const saveDeviceApi = (method, id, payload, hasVehicleAssociated) => async (
  dispatch,
  getState
) => {
  const authKey = getState().user.current.auth.key;
  const companyId = getState().companies.current.id;
  let url = DEVICES_URL;
  if (id) {
    url = `${DEVICES_URL}/${id}`;
  }

  try {
    const response = await api.post(
      url,
      {
        authKey,
        query: {
          copy_vehicle_fleets: hasVehicleAssociated ? true : false,
          company_id: companyId
        }
      },
      payload,
      method
    );

    if (!response || response.status !== 200) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `Something went wrong while saving a device!`
        })
      );
    }

    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};

export const fetchDevicesIOREvts = (deviceIds, isoDateStart, isoDateEnd) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(fetchDevicesIOREvtsStart());
    const userKey = getState().user.current.auth.key;
    return Promise.all(
      deviceIds.map(deviceId =>
        request(
          'GET',
          `${API_PATH}/devices/${deviceId}/events?from=${isoDateStart}&to=${isoDateEnd}&type=IOR&orderBy=created_at`
        )
          .set('Authorization', `Token token="${userKey}"`)
          .set('Content-Type', 'application/json')
          .then(res => (res.body || []).filter(event => event.subType === 'OFF'))
          .catch(() => [])
      )
    ).then(allEvents => {
      dispatch(fetchDevicesIOREvtsSuccess());
      return allEvents.reduce((a, c) => a.concat(c), []);
    });
  } catch (error) {
    dispatch(fetchDevicesIOREvtsFailure({ error }));
  }
};

export const useIsFetchingDevicesIOREvts = () =>
  useSelector(state => state.entities.devices.devicesIOREvtsMeta.isFetching);

export const useDevicesFeatures = companyId => {
  const [devicesFeatures, setDevicesFeatures] = useState(
    Object.keys(deviceCapabilities).reduce((prev, curr) => {
      prev[curr] = false;
      return prev;
    }, {})
  );

  const devices = useDevices();

  useEffect(() => {
    const features = getCompanyDeviceFeatures(companyId, devices);
    setDevicesFeatures(features);
  }, [devices, companyId]);

  return devicesFeatures;
};

const fetchDeviceConfig = (deviceId, serviceCode, userKey, successHandler, errorHandler) => {
  request('GET', API_PATH + '/devices/' + deviceId + '/config?service_code=' + serviceCode)
    .set('Authorization', `Token token="${userKey}"`)
    .then(resp => {
      successHandler(resp.body);
    })
    .catch(err => {
      errorHandler(err);
    });
};

export const useDeviceConfig = ({ deviceId, serviceCode, onSuccess, onError }) => {
  const userKey = useUserKey();
  const [configs, setConfigs] = useState({});

  const { shouldFetch, deviceConfig } = useMemo(() => {
    const deviceConfig = configs?.[deviceId]?.[serviceCode];
    const isFetching = configs?.[deviceId]?.[serviceCode]?.meta?.isFetching;
    return {
      deviceConfig,
      shouldFetch: !deviceConfig && !isFetching,
      isFetching
    };
  }, [configs, deviceId, serviceCode]);

  useEffect(() => {
    if (shouldFetch) {
      setConfigs(prev => ({
        ...prev,
        [deviceId]: {
          ...prev?.[deviceId],
          [serviceCode]: {
            ...prev?.[deviceId]?.[serviceCode],
            meta: {
              isFetching: true,
              error: null
            }
          }
        }
      }));
      fetchDeviceConfig(
        deviceId,
        serviceCode,
        userKey,
        value => {
          setConfigs(prev => ({
            ...prev,
            [deviceId]: {
              ...prev?.[deviceId],
              [serviceCode]: {
                ...prev?.[deviceId]?.[serviceCode],
                value,
                meta: {
                  isFetching: false,
                  error: null
                }
              }
            }
          }));
          if (onSuccess) {
            onSuccess(value);
          }
        },
        error => {
          setConfigs(prev => ({
            ...prev,
            [deviceId]: {
              ...prev?.[deviceId],
              [serviceCode]: {
                meta: {
                  isFetching: false,
                  error: error
                }
              }
            }
          }));
          if (onError) {
            onError(error);
          }
        }
      );
    }
  }, [shouldFetch, userKey, serviceCode, deviceId, onSuccess, onError]);
  return deviceConfig;
};

export const bulkUpdateDevices = ({
  devices = [],
  onUpdated = ({ successCount, failedCount }) => {}
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  try {
    dispatch(bulkUpdateDevicesStart({ devices }));
    request('PUT', API_PATH + '/devices/bulk')
      .set('Authorization', `Token token="${userKey}"`)
      .send(devices)
      .then(resp => {
        const allFailedErrorMessage = resp.error
          ? resp.body?.toString()
          : resp.body?.errors && !isArray(resp.body?.errors)
          ? toString(resp.body?.errors)
          : null;
        if (allFailedErrorMessage) {
          const failureDevicesMap = devices.reduce(
            (a, device) => ({
              ...a,
              [device.id]: { id: device.id, message: allFailedErrorMessage }
            }),
            {}
          );
          dispatch(bulkUpdateDevicesEnd({ failureDevicesMap }));
          dispatch(fetchFleets());
          dispatch(ngGpioConfigurationApi.util.invalidateTags(['gpioConfigurationTemplate']));
          onUpdated({ successCount: 0, failedCount: devices.length });
        } else {
          const failureDevices = resp.body?.errors || [];
          const failureDevicesMap = failureDevices.reduce(
            (a, device) => ({ ...a, [device.id]: device }),
            {}
          );
          const successCount = devices.length - failureDevices.length;
          dispatch(bulkUpdateDevicesEnd({ failureDevicesMap }));
          onUpdated({
            successCount,
            failedCount: failureDevices.length
          });
          if (successCount) {
            dispatch(fetchFleets());
          }
        }
      })
      .catch(err => {
        const failureDevicesMap = devices.reduce(
          (a, device) => ({ ...a, [device.id]: { id: device.id, message: err?.toString() } }),
          {}
        );
        dispatch(bulkUpdateDevicesEnd({ failureDevicesMap }));
        onUpdated({ successCount: 0, failedCount: devices.length });
      });
  } catch (err) {
    const failureDevicesMap = devices.reduce(
      (a, device) => ({ ...a, [device.id]: { id: device.id, message: err?.toString() } }),
      {}
    );
    dispatch(bulkUpdateDevicesEnd({ failureDevicesMap }));
    onUpdated({ successCount: 0, failedCount: devices.length });
  }
};

export const bulkDeleteDevices = ({
  devices = [],
  onDeleted = ({ successCount, failedCount }) => {}
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  try {
    dispatch(bulkDeleteDevicesStart({ devices }));
    request('DELETE', API_PATH + '/devices/bulk')
      .set('Authorization', `Token token="${userKey}"`)
      .send(devices)
      .then(resp => {
        const allFailedErrorMessage = resp.error
          ? resp.body?.toString()
          : resp.body?.errors && !isArray(resp.body?.errors)
          ? toString(resp.body?.errors)
          : null;
        if (allFailedErrorMessage) {
          const failureDevicesMap = devices.reduce(
            (a, device) => ({
              ...a,
              [device.id]: { id: device.id, message: allFailedErrorMessage }
            }),
            {}
          );
          dispatch(bulkDeleteDevicesEnd({ failureDevicesMap }));
          onDeleted({ successCount: 0, failedCount: devices.length });
        } else {
          const failureDevices = resp.body?.errors || [];
          const failureDevicesMap = failureDevices.reduce(
            (a, device) => ({ ...a, [device.id]: device }),
            {}
          );
          dispatch(bulkDeleteDevicesEnd({ failureDevicesMap }));
          const successCount = devices.length - failureDevices.length;
          onDeleted({
            successCount,
            failedCount: failureDevices.length
          });
          if (successCount) {
            dispatch(fetchFleets());
            dispatch(fetchDeletedDevices());
          }
        }
      })
      .catch(err => {
        const failureDevicesMap = devices.reduce(
          (a, device) => ({ ...a, [device.id]: { id: device.id, message: err?.toString() } }),
          {}
        );
        dispatch(bulkDeleteDevicesEnd({ failureDevicesMap }));
        onDeleted({ successCount: 0, failedCount: devices.length });
      });
  } catch (err) {
    const failureDevicesMap = devices.reduce(
      (a, device) => ({ ...a, [device.id]: { id: device.id, message: err?.toString() } }),
      {}
    );
    dispatch(bulkDeleteDevicesEnd({ failureDevicesMap }));
    onDeleted({ successCount: 0, failedCount: devices.length });
  }
};

export const clearBulkEditError = () => (dispatch, getState) => {
  dispatch(clearBulkError());
  dispatch(setIsBulkEdit({ isBulkEditing: false }));
  dispatch(setIsBulkAgreementAssignment({ isBulkAssigningAgreement: false }));
};
export const swithBulkEditMode = editing => (dispatch, getState) =>
  dispatch(setIsBulkEdit({ isBulkEditing: editing }));
export const useIsBulkEditing = () =>
  useSelector(state => state.entities.devices.meta.isBulkEditing);

export const switchBulkAgreementAssignmentMode = editing => (dispatch, getState) =>
  dispatch(setIsBulkAgreementAssignment({ isBulkAssigningAgreement: editing }));
export const useIsBulkAgreementAssignment = () =>
  useSelector(state => state.entities.devices.meta.isBulkAssigningAgreement);

export const useIsBulkUpdating = () =>
  useSelector(state => state.entities.devices.meta.bulkUpdate.isUpdating);
export const useIsBulkDeleting = () =>
  useSelector(state => state.entities.devices.meta.bulkDelete.isDeleting);
export const useBulkError = () => {
  const editError = useSelector(state => state.entities.devices.meta.bulkUpdate.failureDevicesMap);
  const deleteError = useSelector(
    state => state.entities.devices.meta.bulkDelete.failureDevicesMap
  );
  return {
    ...editError,
    ...deleteError
  };
};

export default devicesSlice.reducer;
