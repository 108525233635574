import { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchCameraEventTags } from 'features/camera/cameraEventApi';

export const useEventTags = ({ eventId, eventTimeAt }) => {
  const dispatch = useDispatch();
  const reqCtxRef = useRef({
    request: null,
    fetchTime: null,
    error: null
  });

  const [data, setData] = useState();

  const fetchTags = useCallback(() => {
    const reqCtx = reqCtxRef.current;
    if (!reqCtx.isFetching && !reqCtx.fetchTime) {
      const onReqDone = error => {
        if (reqCtxRef.current.request?.abort) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current = {
          request: null,
          isFetching: false,
          fetchTime: moment().valueOf(),
          error: error || null
        };
      };

      if (reqCtx.request?.abort) {
        reqCtx.request.abort();
        reqCtx.request = null;
      }
      reqCtx.request = dispatch(
        fetchCameraEventTags({
          eventId,
          eventTimeAt,
          onSuccess: data => {
            onReqDone();
            setData(data);
          },
          onError: errMsg => {
            onReqDone(errMsg);
            setData(null);
          }
        })
      );
      const fetchData = async () => {
        try {
          reqCtx.isFetching = true;
          await reqCtx.request;
        } catch (e) {
          onReqDone(e);
        }
      };
      fetchData();
    }
  }, [dispatch, eventId, eventTimeAt, reqCtxRef]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  const setTags = useCallback(tags => setData(pre => ({ ...pre, tags })), []);

  const setSeverity = useCallback(severity => setData(pre => ({ ...pre, severity })), []);

  return {
    tags: data?.tags,
    severity: data?.severity,
    eventIssueId: data?.id,
    setTags,
    setSeverity,
    isFetched: reqCtxRef.current.fetchTime && !reqCtxRef.current.isFetching,
    isFetching: !reqCtxRef.current.fetchTime || reqCtxRef.current.isFetching
  };
};
