import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { locationTypesDataAdapter } from 'data/dataService/tn/adapter/locationTypes';

const locationTypes = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload;
  state.meta.list = [];
  state.meta.isListEmpty = true;
}

const locationTypesSlice = createSlice({
  name: 'locationTypes',
  initialState: locationTypes,
  reducers: {
    fetchLocationTypesStart: startLoading,
    fetchLocationTypesSuccess(state, { payload }) {
      state.list = payload;
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.length === 0;
    },
    fetchLocationTypesFailure: loadingFailed
  }
});

const {
  fetchLocationTypesStart,
  fetchLocationTypesSuccess,
  fetchLocationTypesFailure
} = locationTypesSlice.actions;

export const fetchLocationTypes = () => (dispatch, getState) => {
  try {
    const companyKey = getState().companies?.current?.api_key;
    const companyId = getState().companies?.current?.id;
    const userKey = getState().user?.current?.auth?.key;
    if (!companyKey || !companyId || !userKey) {
      return;
    }
    dispatch(fetchLocationTypesStart());
    const url = `${API_PATH}/locationtypes?company_id=${companyId}`;
    locationTypesDataAdapter.load().then(locationTypes => {
      if (locationTypes?.length > 0) {
        dispatch(fetchLocationTypesSuccess(locationTypes));
      } else {
        request('GET', url)
          .set('Authorization', `Token token="${userKey}"`)
          .set('Content-Type', 'application/json')
          .then(resp => {
            locationTypesDataAdapter.persist(resp.body);
            dispatch(fetchLocationTypesSuccess(resp.body));
          })
          .catch(err => {
            dispatch(fetchLocationTypesFailure(err.toString()));
          });
      }
    });
  } catch (err) {
    dispatch(fetchLocationTypesFailure(err.toString()));
  }
};

export const useLocationTypes = () => {
  const dispatch = useDispatch();
  const locationTypes = useSelector(state => state.locationTypes.list);
  const isFetching = useSelector(state => state.locationTypes.meta.isFetching);
  const isListEmpty = useSelector(state => state.locationTypes.meta.isListEmpty);

  if (locationTypes.length === 0 && !isFetching && !isListEmpty) {
    dispatch(fetchLocationTypes());
  }

  return locationTypes;
};

export default locationTypesSlice.reducer;
