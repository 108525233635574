import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18next from 'i18next';
import request from 'superagent';
import { useTranslation } from 'react-i18next';
import { parseErrorMessage } from 'utils/strings';

export const installationAuditLog_Data = {
  installationDetailsByDevice: null,
  installationDetailsByUser: null,
  installationDetailsByCompany: null,
  installationLogsViewData: null,
  viewByType: 'vehicle',
  installationViewPhotosByEventId: null,
  scheduledReportsByUser: null,
  loading: false,
  errMsg: null
};

const InstallationLogsSlice = createSlice({
  name: 'installationLogsSData',
  initialState: installationAuditLog_Data,
  reducers: {
    fetchinstallationDetailsByCompanyStart(state, { payload }) {
      state.loading = true;
      state.installationDetailsByCompany = null;
    },
    fetchinstallationDetailsByCompanySucceeded(state, { payload }) {
      const { data } = payload;
      state.loading = false;
      state.installationDetailsByCompany = data;
    },
    fetchinstallationDetailsByCompanyFailed(state, { payload }) {
      state.loading = false;
      state.installationDetailsByCompany = null;
    },
    fetchinstallationDetailsByDeviceStart(state, { payload }) {
      state.installationDetailsByDevice = null;
    },
    fetchinstallationDetailsByDeviceSucceeded(state, { payload }) {
      const { data } = payload;
      state.installationDetailsByDevice = data;
    },
    fetchinstallationDetailsByDeviceFailed(state, { payload }) {
      state.installationDetailsByDevice = null;
    },
    fetchinstallationDetailsByUserStart(state, { payload }) {
      state.installationDetailsByUser = null;
    },
    fetchinstallationDetailsByUserSucceeded(state, { payload }) {
      const { data } = payload;
      state.installationDetailsByUser = data;
    },
    fetchinstallationDetailsByUserFailed(state, { payload }) {
      state.installationDetailsByUser = null;
    },
    setViewByTypeStart(state) {
      state.viewByType = 'vehicle';
    },
    setViewByTypeSuccess(state, { payload }) {
      state.viewByType = payload;
    },
    // setInstallationLogsViewDataStart(state) {
    //   state.installationLogsViewData = {};
    // },
    setInstallationLogsViewDataSuccess(state, { payload }) {
      state.installationLogsViewData = payload;
    },
    fetchInstallationViewPhotosByEventIdRequest(state, { payload }) {
      state.loading = true;
      state.installationViewPhotosByEventId = null;
    },
    fetchInstallationViewPhotosByEventIdSuccess(state, { payload }) {
      state.loading = false;
      const { data } = payload;
      state.installationViewPhotosByEventId = data;
    },
    fetchInstallationViewPhotosByEventIdFailure(state, { payload }) {
      state.loading = false;
      state.errMsg = payload;
      state.installationViewPhotosByEventId = null;
    },
    fetchScheduledReportsByUserRequest(state, { payload }) {
      state.loading = true;
      state.scheduledReportsByUser = null;
    },
    fetchScheduledReportsByUserSuccess(state, { payload }) {
      state.loading = false;
      const { data } = payload;
      state.scheduledReportsByUser = data;
    },
    fetchScheduledReportsByUserFailure(state, { payload }) {
      state.loading = false;
      state.errMsg = payload;
      state.scheduledReportsByUser = null;
    }
  }
});
export const {
  fetchinstallationDetailsByCompanyStart,
  fetchinstallationDetailsByCompanySucceeded,
  fetchinstallationDetailsByCompanyFailed,
  fetchinstallationDetailsByDeviceStart,
  fetchinstallationDetailsByDeviceSucceeded,
  fetchinstallationDetailsByDeviceFailed,
  fetchinstallationDetailsByUserStart,
  fetchinstallationDetailsByUserSucceeded,
  fetchinstallationDetailsByUserFailed,
  setViewByTypeStart,
  setViewByTypeSuccess,
  // setInstallationLogsViewDataStart,
  setInstallationLogsViewDataSuccess,
  fetchInstallationViewPhotosByEventIdRequest,
  fetchInstallationViewPhotosByEventIdSuccess,
  fetchInstallationViewPhotosByEventIdFailure,
  fetchScheduledReportsByUserRequest,
  fetchScheduledReportsByUserSuccess,
  fetchScheduledReportsByUserFailure
} = InstallationLogsSlice.actions;

export const fetchinstallationDetailsByCompany = (fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchinstallationDetailsByCompanyStart());

  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/installations/jobs?company_id=${companyId}&from=${fromDate}&to=${toDate}&embed=vehicle,device,user,company,attachment`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  try {
    const data = await callPromise;
    dispatch(fetchinstallationDetailsByCompanySucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchinstallationDetailsByCompanyFailed(payload));
  }
};
export const fetchinstallationDetailsByDevice = (fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchinstallationDetailsByDeviceStart());

  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/installations/jobs?company_id=${companyId}&from=${fromDate}&to=${toDate}&embed=device`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  try {
    const data = await callPromise;
    // console.log("data====", data)
    dispatch(fetchinstallationDetailsByDeviceSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchinstallationDetailsByDeviceFailed(payload));
  }
};
export const fetchinstallationDetailsByUser = (fromDate, toDate) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchinstallationDetailsByUserStart());

  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/installations/jobs?company_id=${companyId}&from=${fromDate}&to=${toDate}&embed=user`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  try {
    const data = await callPromise;
    dispatch(fetchinstallationDetailsByUserSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchinstallationDetailsByUserFailed(payload));
  }
};

export const fetchInstallationViewPhotosByEventId = (eventId, companyId) => async (
  dispatch,
  state
) => {
  const userKey = state().user?.current?.auth?.key;
  dispatch(fetchInstallationViewPhotosByEventIdRequest());
  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const url = `/installations/jobs/${eventId}?company_id=${companyId}&embed=vehicle,device,user,company,attachment`;
    apiClient.callApi(url, 'GET', {}, {}, {}, {}, {}, [], [], [], null, null, (err, data, resp) => {
      if (err && (resp == null || resp.status !== 200)) {
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });
  try {
    const data = await callPromise;
    dispatch(fetchInstallationViewPhotosByEventIdSuccess({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchInstallationViewPhotosByEventIdFailure(payload));
  }
};

export const fetchScheduledReportsByUser = () => async (dispatch, state) => {
  const userKey = state().user?.current?.auth?.key;
  dispatch(fetchScheduledReportsByUserRequest());
  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const url = `/reports/schedules`;
    apiClient.callApi(url, 'GET', {}, {}, {}, {}, {}, [], [], [], null, null, (err, data, resp) => {
      if (err && (resp == null || resp.status !== 200)) {
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });
  try {
    const data = await callPromise;
    dispatch(fetchScheduledReportsByUserSuccess({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchScheduledReportsByUserFailure(payload));
  }
};

export const deleteScheduledReportsById = (id, name) => async (dispatch, state) => {
  const userKey = state().user?.current?.auth?.key;
  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const url = `/reports/${id}/delete`;
    apiClient.callApi(
      url,
      'DELETE',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  try {
    const data = await callPromise;
    dispatch(
      openToast({
        type: ToastType.Success,
        message: i18next.t('SupportToolsELD.DeleteNotification', {
          name: name
        })
      })
    );
    dispatch(fetchScheduledReportsByUser());
  } catch (err) {
    const payload = { error: err.toString() };
  }
};

export const setViewByType = type => (dispatch, getState) => {
  dispatch(setViewByTypeSuccess(type));
};
export const setInstallationLogsViewData = payload => (dispatch, getState) => {
  dispatch(setInstallationLogsViewDataSuccess(payload));
};
export const useInstallationLogsDatabyCompany = () => {
  const { loading, installationDetailsByCompany } =
    useSelector(state => state?.installationLogsData) || {};
  return { loading, installationLogsDataByCompany: installationDetailsByCompany };
};
export const useInstallationLogsDatabyDevice = () => {
  const data = useSelector(state => state?.installationLogsData?.installationDetailsByDevice);
  return data;
};
export const useInstallationLogsDatabyUser = () => {
  const data = useSelector(state => state?.installationLogsData?.installationDetailsByUser);
  return data;
};
export const useSetViewByType = () => {
  const data = useSelector(state => state?.installationLogsData?.viewByType);
  return data;
};
export const useInstallationLogsViewData = () => {
  const data = useSelector(state => state?.installationLogsData?.installationLogsViewData);
  return data;
};

export const useInstallationViewPhotosByEventId = () => {
  const { loading, errMsg, installationViewPhotosByEventId } =
    useSelector(state => state?.installationLogsData) || {};
  return { loading, data: installationViewPhotosByEventId, errMsg };
};

export const useScheduledReportsByUser = () => {
  const { loading, errMsg, scheduledReportsByUser } =
    useSelector(state => state?.installationLogsData) || {};
  return { loading, data: scheduledReportsByUser, errMsg };
};
export default InstallationLogsSlice.reducer;

/**
 * saveScheduledReportApi
 * @param {Object} data - The payload data containing report details.
 * @returns {Promise} - API response for saving the scheduled report.
 * Dispatches success or error notifications based on response.
 */
export const saveScheduledReportApi = data => async (dispatch, getState) => {
  try {
    const userKey = getState().user.current.auth.key;
    const url = `${API_PATH}/reports/scheduled-report`;
    const response = await request('POST', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(data);
    dispatch(fetchScheduledReportsByUser());
    return response;
  } catch (error) {
    console.error('saveScheduledReportApi error: ', error);
    throw error;
  }
};
