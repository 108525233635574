import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Button, Tooltip } from 'antd';

import { EventAttachments } from './EventAttachments';
import { EventComments } from './EventComments';
import { EventAudits } from './EventAudits';

export function EventDrawer({
  open,
  setOpen,
  eventId,
  eventIssueId,
  eventDetails,
  eventDetailsUpdater
}) {
  const { t } = useTranslation();
  const [openAudits, setOpenAudits] = useState(false);

  return (
    <Drawer
      title={t('Event.Updates')}
      width={680}
      onClose={() => setOpen(false)}
      open={open}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Tooltip title={!eventIssueId && t('Audits.NoAudit')} placement="left">
          <Button disabled={!eventIssueId} onClick={() => setOpenAudits(true)}>
            {t('Permission.Audit')}
          </Button>
        </Tooltip>
      }
    >
      <EventAttachments
        attachments={eventDetails.attachments}
        onAdd={eventDetailsUpdater.onAddAttachment}
        onDelete={eventDetailsUpdater.onDeleteAttachment}
      />
      <EventComments
        eventId={eventId}
        comments={eventDetails.comments}
        onAdd={eventDetailsUpdater.onAddComment}
        onEdit={eventDetailsUpdater.onEditComment}
        onDelete={eventDetailsUpdater.onDeleteComment}
      />
      <EventAudits
        eventIssueId={eventIssueId}
        open={openAudits}
        onClose={() => setOpenAudits(false)}
      />
    </Drawer>
  );
}
