//incrase the schema_version whenever there are any changes to table schemas
export const schema_version = '1.42';

export const fleetsTable = `
CREATE TABLE IF NOT EXISTS ng_fleets (
	id int(4) NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	path varchar(255) NULL,
	version int(4) NULL DEFAULT 0,
	status varchar(255) NULL DEFAULT 'ENABLED',
	company_id int(4) NULL,
	doc_id varchar(255) NULL,
	created_at timestamptz NULL,
	updated_at timestamptz NULL,
	description varchar(255) NULL,
	colour varchar(255) NULL,
	icon_type varchar(255) NULL,
	carrier_id int(4) NULL,
	icon_size text NULL,
	external_id varchar(255) NULL
);
`;

export const vehiclesTable = `
CREATE TABLE IF NOT EXISTS ng_vehicles (
	id int(4) NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	path varchar(255) NULL,
	version int(2) NULL DEFAULT 0,
	status varchar(255) NULL DEFAULT 'ENABLED',
	company_id int(4) NOT NULL,
	vehicle_type_id int(2) NOT NULL,
	location_id int(4) NULL,
	registration varchar(255) NOT NULL,
	registration_state varchar(255) NOT NULL,
	registration_state_name varchar(255) NULL,
	vin varchar(255) NOT NULL,
	engine_number varchar(255) NULL,
	make varchar(255) NULL,
	model varchar(255) NULL,
	note text NULL,
	doc_id varchar(255) NULL,
	external_id varchar(255) NULL,
	sync_in_at timestamptz NULL,
	created_at timestamptz NULL,
	updated_at timestamptz NULL,
	service_on date NULL,
	manufactured_on date NULL,
	odometer float8 NULL,
	engine_hours float8 NULL,
	readings_on timestamptz NULL,
	engine_summary_source text NOT NULL DEFAULT 'GPS_DIFF',
	engine_profile_id int(2) NULL,
	external_reference text NULL,
	phone text NULL,
	tags text NULL,
	trgm_compilation text NULL,
	image_url text NULL,
	vin_auto text NULL,
	make_auto text NULL,
	model_auto text NULL,
	year_auto text NULL,
	extra_auto text NULL,
	vin_resolved_at timestamptz NULL,
	vehicle_model_id int(4) NULL,
	registration_country varchar(255) NULL,
	registration_country_name varchar(255) NULL,
	eld_vehicle bool NULL,
	operational_vehicle_model_id int(4) NULL,
	default_driver INT(4) NULL
);
`;

export const devicesTable = `
CREATE TABLE IF NOT EXISTS ng_devices (
	id int(4) NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	path varchar(255) NULL,
	version int(4) NULL DEFAULT 0,
	status varchar(255) NULL DEFAULT 'ENABLED',
	company_id int(4) NOT NULL,
	vehicle_id int(4) NULL,
	device_type_id int(4) NOT NULL,
	device_model_id int(4) NOT NULL,
	did varchar(255) NULL,
	registration varchar(255) NULL,
	serial_number varchar(255) NOT NULL,
	imei varchar(255) NULL,
	sim varchar(255) NULL,
	phone varchar(255) NULL,
	operating_system varchar(255) NULL,
	firmware_version varchar(255) NULL,
	software_version varchar(255) NULL,
	note text NULL,
	auto bool NULL DEFAULT false,
	iap_id varchar(255) NULL,
	doc_id varchar(255) NULL,
	created_at timestamptz NULL,
	updated_at timestamptz NULL,
	release_version text NULL,
	external_id text NULL,
	tags text NULL,
	trgm_compilation text NULL,
	registered_at timestamptz NULL,
	location_id int(4) NULL,
	camera_sensitivity varchar(255) NULL,
	volume varchar(255) NULL,
	services text NULL
);
`;
export const fleetsVehiclesTable = `
CREATE TABLE IF NOT EXISTS ng_fleet_vehicles (
	fleet_id int(4) NOT NULL,
	vehicle_id int(4) NOT NULL
);
CREATE INDEX IF NOT EXISTS idx_fleet_vehicles_fleet_id ON ng_fleet_vehicles (fleet_id);
CREATE INDEX IF NOT EXISTS idx_fleet_vehicles_vehicle_id ON ng_fleet_vehicles (vehicle_id);
CREATE UNIQUE INDEX IF NOT EXISTS uni_fleet_vehicles ON ng_fleet_vehicles (fleet_id, vehicle_id);
`;

export const fleetsDevicesTable = `
CREATE TABLE IF NOT EXISTS ng_fleet_devices (
	fleet_id int(4) NOT NULL,
	device_id int(4) NOT NULL
);
CREATE INDEX IF NOT EXISTS idx_fleet_devices_fleet_id ON ng_fleet_devices (fleet_id);
CREATE INDEX IF NOT EXISTS idx_fleet_devices_device_id ON ng_fleet_devices (device_id);
CREATE UNIQUE INDEX IF NOT EXISTS uni_fleet_devices ON ng_fleet_devices (fleet_id, device_id);
`;

export const companyTables = `
CREATE TABLE IF NOT EXISTS ng_companies (
	id int(4) NOT NULL PRIMARY KEY,
    name varchar(255) NOT NULL,
    slug varchar(255) NOT NULL,
    parent_id int(4) NULL,
    path varchar(255) NULL,
    version int(4) NULL DEFAULT 0,
	status varchar(255) NULL DEFAULT 'ENABLED',
	logo varchar(255) NULL,
	doc_id varchar(255) NULL,
	created_at varchar(255) NULL,
    updated_at varchar(255) NULL,
    customer_number text NULL,
	external_id text NULL,
    country varchar(255) NULL,
	features text NULL,
	tags text NULL,
	trgm_compilation text NULL,
    statistics_tz text NULL,
	locale text NULL,
	realm text NULL,
	salesforce_id text NULL,
	stream_name text NULL,
    geofence_providers text NULL
);
`;

export const deviceTypesTable = `
CREATE TABLE IF NOT EXISTS ng_device_types (
	id int4 NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	description text NULL,
	created_at text NULL,
	updated_at text NULL,
	code text NULL,
	dynamic_features text NULL,
	priority int4 NULL
);`;

export const deviceModelsTable = `
CREATE TABLE IF NOT EXISTS ng_device_models (
	id INT4 NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	description text NULL,
	created_at text NULL,
	updated_at text NULL,
	device_type_id int4 NULL
);`;

export const locationTypesTable = `
CREATE TABLE IF NOT EXISTS ng_location_types (
	id int4 NOT NULL PRIMARY KEY,
	name varchar(255) NULL,
	description text NULL,
	code varchar(255) NULL,
	created_at text  NULL,
	updated_at text NULL
);`;

export const locationsTable = `
CREATE TABLE IF NOT EXISTS ng_locations (
	id int4 NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	path varchar(255) NULL,
	version int4 NULL DEFAULT 0,
	company_id int4 NOT NULL,
	location_type_id int4 NULL,
	address_id int4 NULL,
	site_notes text NULL,
	external_id varchar(255) NULL,
	sync_in_at text NULL,
	sync_out_at text NULL,
	verify bool NULL,
	sync bool NULL,
	status varchar(255) NULL DEFAULT 'ENABLED',
	doc_id varchar(255) NULL,
	created_at text NULL,
	updated_at text NULL,
	position text NULL,
	time_zone varchar(255) NULL,
	customer_id int4 NULL
);`;

export const regionsTable = `
CREATE TABLE IF NOT EXISTS ng_regions (
	id int(4) NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	code varchar(255) NULL,
	states text NULL,
	geocode text NULL,
	time_zones text NULL,
	config text NULL,
	locale varchar(255) NULL,
	platform varchar(255) NULL,
	nextgen_url text NULL,
	tn360_url text NULL,
	tn360_pdf_url text NULL,
	api_url text NULL,
	support_email text NULL,
	instance varchar(255) NULL
);`;

export const vehicleTypesTable = `
CREATE TABLE IF NOT EXISTS ng_vehicle_types (
	id int(4) NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	code varchar(255) NULL,
	company_id int4 NOT NULL,
	description text NULL,
	global bool NULL,
	icon varchar(255) NULL,
	status varchar(255) NOT NULL DEFAULT 'ENABLED',
	created_at text NULL,
	updated_at text NULL,
	fule_type varchar(255) NULL,
	speed_limit_class varchar(255) NULL
);
`;

export const rolesTable = `
CREATE TABLE IF NOT EXISTS ng_roles
(
	id int4 NOT NULL PRIMARY KEY,
	name varchar(255) NULL,
	path varchar(255) NULL,
	description text NULL,
	global bool NULL DEFAULT false,
	company_id int4 NULL,
	status varchar(255) NULL DEFAULT 'ENABLED',
	doc_id varchar(255) NULL,
	created_at text NULL,
	updated_at text NULL,
	features _text NULL,
	version int4 NULL,
	permissionIds text NULL
);
`;

export const permissionsTable = `
CREATE TABLE IF NOT EXISTS ng_permissions
(
	id int4 NOT NULL PRIMARY KEY,
	subject varchar(255) NULL,
	code varchar(255) NULL,
	tag varchar(255) NULL,
	name varchar(255) NULL,
	action varchar(255) NULL,
	category varchar(255) NULL,
	type varchar(255) NULL,
	description text NULL,
	site_admin bool NULL DEFAULT false,
	created_at text NULL,
	updated_at text NULL,
	status text NULL
);
`;

export const rolePermissionsTable = `
CREATE TABLE IF NOT EXISTS ng_role_permissions (
	role_id int(4) NOT NULL,
	permission_id int(4) NOT NULL
);
`;

export const usersTable = `
CREATE TABLE IF NOT EXISTS ng_users (
	id int(4) NOT NULL PRIMARY KEY,
	email varchar(255) NOT NULL DEFAULT '',
	company_id int4 NOT NULL,
	user_type_id int4 NOT NULL,
	location_id int4 NULL,
	first_name varchar(255) NOT NULL,
	last_name varchar(255) NOT NULL,
	username varchar(255) NULL,
	mobile varchar(255) NULL,
	path varchar(255) NULL DEFAULT '/',
	version int4 NULL DEFAULT 0,
	status varchar(255) NULL DEFAULT 'ENABLED',
	site_admin bool NULL DEFAULT false,
	time_zone varchar(255) NULL,
	external_id varchar(255) NULL,
	sync_in_at text NULL,
	doc_id varchar(255) NULL,
	licence_state varchar(255) NULL,
	licence_number varchar(255) NULL,
	sentinel_driver_id int4 NULL DEFAULT 0,
	sentinel_driver_code varchar(255) NULL DEFAULT 0,
	created_at text NULL,
	updated_at text NULL,
	sentinel_time_zone varchar(255) NULL,
	sentinel bool NULL DEFAULT true,
	sentinel_platform int4 NULL,
	external_pin text NULL,
	external_reference text NULL,
	callable bool NULL,
	tags text NULL,
	trgm_compilation text NULL,
	features text NULL,
	image_url text NULL,
	licence_country text NULL,
	sentinel_exemption_notes text NULL,
	start_time_of_day time NULL,
	associations text NULL
);
`;

export const userRolesTable = `
CREATE TABLE IF NOT EXISTS ng_user_roles (
	user_id int4 NOT NULL,
	role_id int4 NOT NULL
);
`;

export const rulesetsTable = `
CREATE TABLE IF NOT EXISTS ng_rulesets (
	name varchar(512) NOT NULL PRIMARY KEY,
	actionMap text NULL,
	actions text NULL,
	desc text NULL,
	forELD bool NULL DEFAULT false,
	isPreRelease bool NULL DEFAULT false,
	minRest INT NULL,
	options text NULL,
	period INT NULL,
	region VARCHAR(255) NULL,
	reportMap text NULL,
	rules text NULL
);
`;

export const userRulesetsTable = `
CREATE TABLE IF NOT EXISTS ng_user_rulesets (
	id INT4 NOT NULL PRIMARY KEY,
	user_id int4 NOT NULL,
	company_id int4 NOT NULL,
	ruleset text NOT NULL,
	enabled_at text NOT NULL,
	expires_at text NULL,
	created_at text NULL,
	updated_at text NULL
);
`;

export const geofencesTable = `
CREATE TABLE IF NOT EXISTS ng_geofences (
	id int4 NOT NULL PRIMARY KEY,
	name varchar(255) NOT NULL,
	path varchar(255) NULL,
	version int4 NULL DEFAULT 0,
	company_id int4 NOT NULL,
	location_id int4 NULL,
	external_id varchar(255) NULL,
	status varchar(255) NULL DEFAULT 'ENABLED',
	doc_id varchar(255) NULL,
	created_at text NULL,
	updated_at text NULL,
	style varchar(255) NULL,
	shape text NULL,
	thrsh_overtime int4 NULL,
	thrsh_undertime int4 NULL,
	thrsh_speed int4 NULL,
	offset_speed int4 NULL,
	thrsh_duration_speed int4 NULL,
	centroid text NULL,
	radius float8 NULL,
	type text NOT NULL DEFAULT 'USER',
	speed_assist bool NULL DEFAULT false,
	driver_notification text NULL,
	features text NULL,
	shape2 text NULL,
	active_time text NULL,
	time_zone text NULL,
	expires_at text NULL,
	area_sqm float NULL
);
`;

export const geofenceAssociationsTable = `
CREATE TABLE IF NOT EXISTS ng_geofence_associations (
	geofence_id int4 NOT NULL,
	fleet_id int4 NULL,
	vehicle_id int4 NULL,
	device_id int4 NULL,
	created_at text NULL,
	updated_at text NULL
);
`;

export const entityLastUpdateTable = `
CREATE TABLE IF NOT EXISTS ng_entity_last_update (
	tableName varchar(255) NOT NULL PRIMARY KEY,
	last_updated_date varchar(255) NOT NULL
);
`;

export const vehicleStatsTable = `
CREATE TABLE IF NOT EXISTS ng_vehicle_stats (
	vehicle_id int4 NOT NULL PRIMARY KEY,
	trip_id int4 NULL,
	current_user_id int4 NULL,
	gps_odometer float8 NOT NULL DEFAULT 0,
	gps_odometerRef float8 NOT NULL DEFAULT 0,
	gps_engineHours float8 NOT NULL DEFAULT 0,
	gps_engine_hours_ref float8 NOT NULL DEFAULT 0,
	can_diff_odometer float8 NOT NULL DEFAULT 0,
	can_diff_oOdometer_ref float8 NOT NULL DEFAULT 0,
	can_diff_engine_hours float8 NOT NULL DEFAULT 0,
	can_diff_engine_hours_ref float8 NOT NULL DEFAULT 0,
	can_diff_fuel float8 NOT NULL DEFAULT 0,
	can_diff_fuel_ref float8 NOT NULL DEFAULT 0,
	can_odometer float8 NOT NULL DEFAULT 0,
	can_engine_hours float8 NOT NULL DEFAULT 0,
	can_fuel float8 NOT NULL DEFAULT 0,
	last_readings_at text NULL,
	current_device_id int4 NULL,
	current_user text NULL,
	current_user2 text NULL,
	meters text NULL,
	runsheet_id int4 NULL,
	runsheet text NULL,
	position text NULL,
	gps text NULL,
	eld_seq int8 NULL,
	vin_status text NULL,
	last_auth_at text NULL,
	last_eld_diagnostic_codes text NULL,
	last_eld_diagnostics_at text NULL,
	current_user2_id int4 NULL,
	last_trip_started_at text NULL,
	cumulated_range float8 NULL,
	cumulated_energy_usage float8 NULL,
	last_bleconfig text NULL,
	last_bleconfig_at text NULL,
	last_event_type text NULL,
	last_event_subtype text NULL,
	last_event_at text NULL,
	last_event_origin text NULL,
	attr text NULL,
	fleets text NULL,
	tacho_fatigue_status_event text NULL,
	ev_device_meters text NULL
);
`;

export const deviceStatsTable = `
CREATE TABLE IF NOT EXISTS ng_device_stats (
	device_id int4 NOT NULL PRIMARY KEY,
	last_comm_at text NULL,
	last_event varchar(255) NULL,
	last_event_at text NULL,
	odometer float8 NULL DEFAULT 0.0,
	ignition varchar(255) NULL,
	created_at text NULL,
	updated_at text NULL,
	obm text NULL,
	gps_at text NULL,
	gps text NULL,
	position text NULL,
	current_user_id int4 NULL,
	current_user text NULL,
	location text NULL,
	obm_at text NULL,
	sdm_tcm float8 NULL,
	sdm_at text NULL,
	dev_app_version text NULL,
	dev_app_info text NULL,
	dev_version text NULL,
	dev_info text NULL,
	last_duress_id int4 NULL,
	storage_info text NULL,
	runsheet_id int4 NULL,
	squarell_version text NULL,
	squarell_info text NULL,
	last_amqp_connect_id int4 NULL,
	last_duress_at text NULL,
	is_shutdown bool NULL,
	shutdown_at text NULL,
	shutdown_odo float8 NULL DEFAULT 0.0,
	sim_network text NULL,
	dynamic_features text NULL,
	smartnav_info text NULL,
	movement text NULL,
	last_vpm text NULL,
	last_vpm_at text NULL,
	last_edr text NULL,
	last_edr_at text NULL,
	last_ruc text NULL,
	last_ruc_at text NULL,
	last_gpio text NULL,
	last_gpio_at text NULL,
	movement_at text NULL,
	aux_info text NULL,
	trip_id int4 NULL,
	last_auth_at text NULL,
	current_vehicle_id int4 NULL,
	last_satellite_at text NULL,
	current_user2_id int4 NULL,
	last_trip_started_at text NULL
);
`;

export const carriersTable = `
CREATE TABLE IF NOT EXISTS ng_carriers (
	id int4 NOT NULL PRIMARY KEY,
    company_id int4 NOT NULL,
    company_version int4,
    name text,
    official_identifer text,
    country text,
    state text,
    city text,
    postcode text,
    address1 text,
    address2 text,
    address3 text,
    address4 text,
    created_at text,
    updated_at text,
    version int4,
    county text,
    status varchar(255) NULL DEFAULT 'ENABLED',
	dot_number int4 NULL
);
`;
