import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Alert, Button, Modal, Space, Tooltip } from 'antd';
import { fetchCameraEventAudits } from 'features/camera/cameraEventApi';
import { AuditsTable } from 'components/auditsTable/AuditsTable';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { EVENT_SEVERITY, EVENT_TAG } from '../../constant';
import { sortBy } from 'lodash';

const AUDIT_CHANGE_KEY = {
  TAGS: 'tags',
  SEVERITY: 'severity',
  ISSUE_STATUS: 'status'
};

export const EventAudits = ({ eventIssueId, open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reqCtxRef = useRef({
    request: null,
    fetchTime: null,
    error: null
  });
  const [audits, setAudits] = useState();
  const [isLoading, setIsLoading] = useState();

  const fetchAudits = useCallback(
    force => {
      if (!open) {
        return;
      }
      const reqCtx = reqCtxRef.current;
      if (force || (!reqCtx.isFetching && !reqCtx.fetchTime)) {
        const onReqDone = error => {
          if (reqCtxRef.current.request?.abort) {
            reqCtxRef.current.request.abort();
          }
          reqCtxRef.current = {
            request: null,
            isFetching: false,
            fetchTime: moment().valueOf(),
            error: error || null
          };
          setIsLoading(false);
        };

        if (reqCtx.request?.abort) {
          reqCtx.request.abort();
          reqCtx.request = null;
        }
        reqCtx.request = dispatch(
          fetchCameraEventAudits({
            eventIssueId,
            onSuccess: audits => {
              onReqDone();
              setAudits(audits);
            },
            onError: errMsg => {
              onReqDone(errMsg);
              setAudits(null);
            }
          })
        );
        const fetchData = async () => {
          try {
            reqCtx.isFetching = true;
            setIsLoading(true);
            await reqCtx.request;
          } catch (e) {
            onReqDone(e);
          }
        };
        fetchData();
      }
    },
    [dispatch, eventIssueId, open]
  );

  useEffect(() => {
    fetchAudits();
  }, [fetchAudits]);

  const hasAuditKeyPermission = useCallback(
    auditKey => auditKey !== AUDIT_CHANGE_KEY.ISSUE_STATUS,
    []
  );

  const onRenderChangeItemValue = useCallback(
    (displayValue, { changeItem, colKey, changeKey }) => {
      const item = colKey === 'from' ? changeItem[0] : changeItem[1];
      if (changeKey === AUDIT_CHANGE_KEY.TAGS) {
        return Array.isArray(item)
          ? sortBy(item, tagKey => Object.keys(EVENT_TAG).indexOf(tagKey))
              .map(tag => t([`Event.Tag.${tag}`, tag]))
              .join(', ')
          : !item
          ? ''
          : displayValue;
      } else if (changeKey === AUDIT_CHANGE_KEY.SEVERITY) {
        return EVENT_SEVERITY[displayValue]
          ? t(EVENT_SEVERITY[displayValue])
          : !item
          ? ''
          : displayValue;
      }
      return displayValue;
    },
    [t]
  );

  const onRenderChangeItemKey = useCallback(
    changeKey => {
      if (changeKey === AUDIT_CHANGE_KEY.TAGS) {
        return t('Tracking.Tags');
      } else if (changeKey === AUDIT_CHANGE_KEY.SEVERITY) {
        return t('CompanyConfig.IQCamera.severity');
      }
      return changeKey;
    },
    [t]
  );

  const onCancel = useCallback(() => {
    if (reqCtxRef.current?.request?.abort) {
      reqCtxRef.current.request.abort();
    }
    reqCtxRef.current = {
      request: null,
      fetchTime: null,
      error: null
    };
    onClose();
  }, [onClose]);

  return (
    <Modal
      title={t('Audits.AuditHistory')}
      width={'80vw'}
      bodyStyle={{ overflowY: 'hidden', maxHeight: '60vh' }}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {reqCtxRef.current?.error && (
          <Alert
            showIcon
            description={t('Audits.AuditFetchError', { error: reqCtxRef.current.error })}
            type="error"
            action={
              <Tooltip title={t('SmartJobs.UnavailableLinkComponent.DescriptionAPIFail')}>
                <Button size="small" danger loading={isLoading} onClick={() => fetchAudits(true)}>
                  {t('Common.Refresh')}
                </Button>
              </Tooltip>
            }
          />
        )}
        <AuditsTable
          audits={audits}
          entity={AUDIT_ENTITY.CAMERA_EVENT_ISSUE}
          hasAuditKeyPermission={hasAuditKeyPermission}
          onRenderChangeItemKey={onRenderChangeItemKey}
          onRenderChangeItemValue={onRenderChangeItemValue}
          isLoading={!reqCtxRef.current.fetchTime || isLoading}
          hideEmptyChangeItem
        />
      </Space>
    </Modal>
  );
};
