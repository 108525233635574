import { TNDataService } from '../tnDataService';
import { deviceTypesDataAdapter } from './deviceTypes';
import { reduxAdapter } from './reduxAdapter';

const deviceModelsDataAdapter = reduxAdapter({
  persist: async deviceModels => {
    if (deviceModels && deviceModels.length) {
      for (const d of deviceModels) {
        if (d.id) {
          await TNDataService.insertOrUpdateDeviceModel(d);
        }
      }
    }
  },
  load: async () => {
    const deviceModels = await TNDataService.getAllDeviceModels();
    const deviceTypes = await deviceTypesDataAdapter.load();
    deviceModels?.forEach(dm => {
      if (dm.deviceType?.id) {
        const deviceType = deviceTypes.find(d => d.id === dm.deviceType.id);
        dm.deviceType = deviceType;
      }
    });
    return deviceModels;
  }
});

export { deviceModelsDataAdapter };
