// Author: Prasad K
// Date: 2023-10-12
// Description: This component handles the scheduled report form functionality using Formly.js.

import React, { useEffect, useState, useCallback } from 'react';
import { Modal } from 'antd';
import ScheduledReportForm from './ScheduledReportForm';
import dayjs from 'dayjs';
import { useUser } from 'features/user/userSlice';
import { useDispatch } from 'react-redux';
import { saveScheduledReportApi } from 'features/installation_logs/InstallationLogsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import styles from './ScheduledReportForm.module.scss';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useTranslation } from 'react-i18next';
import { parseErrorMessage } from 'utils/strings';
import * as moment from 'moment-timezone';

export const NewScheduledReportModal = ({
  title,
  isOpen,
  exisitngReportNames,
  onClose,
  currentReport
}) => {
  const { email } = useUser();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const { t } = useTranslation();

  const [parsedFilters, setParsedFilters] = useState({
    companyIds: [],
    fleetIds: [],
    eventTime: {}
  });
  const [parsedRecipientList, setParsedRecipientList] = useState([email]);

  const parseCurrentReport = useCallback(() => {
    if (currentReport) {
      try {
        const filters = JSON.parse(currentReport.filters || '{}');
        setParsedFilters({
          companyIds: filters?.companyIds || [],
          fleetIds: filters?.fleetIds || []
        });
      } catch (error) {
        console.error('Failed to parse filters:', error);
        setParsedFilters({ companyIds: [], fleetIds: [] });
      }

      try {
        const emailString = currentReport?.otherEmails;
        if (emailString && emailString.trim() !== '') {
          const emailList = emailString
            .replace('[', '')
            .replace(']', '')
            .split(',')
            .map(email => email.trim());
          setParsedRecipientList(emailList);
        } else {
          setParsedRecipientList([email]);
        }
      } catch (error) {
        console.error('Failed to parse recipient emails:', error);
        setParsedRecipientList([email]);
      }
    } else {
      // Fallback for when `currentReport` is null or undefined
      setParsedRecipientList([email]);
      setParsedFilters({
        companyIds: [],
        fleetIds: []
      });
    }
  }, [currentReport, email]);

  useEffect(() => {
    parseCurrentReport();
  }, [parseCurrentReport]);

  const dateFormat =
    localization?.formats?.time?.formats?.dby?.toUpperCase() + ' HH:mm' || 'YYYY-MM-DD HH:mm';

  const initialValues = {
    companies: parsedFilters?.companyIds,
    fleets: parsedFilters?.fleetIds,
    recipientList: parsedRecipientList,
    deliveryFormat: 'EXCEL',
    name: currentReport?.name || '',
    description: currentReport?.description || '',
    frequency: currentReport?.frequency || 'ONCE_OFF',
    nextRunAt: currentReport?.nextRunAt
      ? moment(currentReport.nextRunAt).format(dateFormat)
      : dayjs().format(dateFormat),
    endAt: currentReport?.endAt ? moment(currentReport.endAt).format(dateFormat) : '',
    nextPeriodFrom: currentReport?.nextPeriodFrom
      ? moment(currentReport.nextPeriodFrom).format(dateFormat)
      : '',
    nextPeriodTo: currentReport?.nextPeriodTo
      ? moment(currentReport.nextPeriodTo).format(dateFormat)
      : ''
  };

  const isEditMode = Boolean(currentReport);

  const handleFormSubmit = async data => {
    try {
      const payload = isEditMode ? { ...data, id: currentReport?.id } : data;
      const toastMessage = isEditMode
        ? t('Event.Notifications.Updated', { name: `${data.name}` })
        : t('Event.Notifications.Added', { name: `${data.name}` });
      await dispatch(saveScheduledReportApi(payload));
      // Dispatch the toast notification
      dispatch(
        openToast({
          type: ToastType.Success,
          message: toastMessage
        })
      );
      onClose(); // Close the modal after successful submission
    } catch (error) {
      let toastMessage;

      // Check if error response exists and extract message
      if (error.response) {
        const resp = error.response.body || error.response.text;
        toastMessage = parseErrorMessage(resp?.localizedMessage);
      }

      // Fallback to generic error message if specific one is unavailable
      if (!toastMessage) {
        const errorKey = isEditMode ? 'UpdateError' : 'CreateError';
        toastMessage = t(`SupportToolsELD.ScheduledReports.ErrorMessages.${errorKey}`);
      }

      // Dispatch the error toast
      dispatch(
        openToast({
          type: ToastType.Error,
          message: toastMessage
        })
      );
    }
  };
  return (
    <Modal
      title={title}
      open={isOpen}
      centered
      closable
      footer={null}
      onCancel={onClose}
      className={styles.customModal}
      maskClosable={false}
      destroyOnClose
    >
      <div className={styles.customModalBody}>
        <ScheduledReportForm
          initialValues={initialValues}
          exisitngReportNames={exisitngReportNames}
          onClose={onClose}
          onSubmit={handleFormSubmit}
        />
      </div>
    </Modal>
  );
};

export default NewScheduledReportModal;
