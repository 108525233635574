import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Modal, Select, Spin, Divider, Typography } from 'antd';
import { useFleeetsByCompanyId } from 'features/fleets/fleetsSlice';
import { useCompanies } from 'features/company/companySlice';

import styles from './EditEntity.module.scss';
import { BulkEditEntity } from '../constant';

const FleetsSelect = ({ companyId, value, onChange }) => {
  const { t } = useTranslation();

  const companies = useCompanies();
  const getCompanyName = useCallback(
    companyId => companies?.find(comp => String(comp.id) === String(companyId))?.name || '',
    [companies]
  );

  const { isFetching, fleets } = useFleeetsByCompanyId({ companyId });
  const options = useMemo(
    () =>
      fleets
        ?.filter(fleet => !!fleet.id)
        .map(fleet => ({
          label: `${fleet.name} (${getCompanyName(fleet.company?.id)})`,
          value: fleet.id
        })),
    [fleets]
  );

  return (
    <Select
      placeholder={t('Vehicles.Form.FleetPlaceholder')}
      maxTagCount={5}
      mode="multiple"
      showSearch
      allowClear
      filterOption={(input, option) =>
        option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
      }
      value={value}
      onChange={onChange}
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      options={options}
      dropdownRender={menu => (
        <>
          <>
            <Button
              className={styles.selectAllBtn}
              type="link"
              size="small"
              onClick={_ => {
                onChange(value =>
                  value?.length === options?.length ? [] : options?.map(opt => opt.value)
                );
              }}
            >
              {value?.length === options?.length ? 'Unselect All' : 'Select All'}
            </Button>
            <Divider style={{ margin: '8px 0' }} />
          </>
          {menu}
        </>
      )}
    />
  );
};

const EntitySelect = ({
  entity,
  companyId,
  mode,
  notFoundContent,
  value,
  onChange,
  options,
  optionRender,
  placeholder
}) => {
  switch (entity) {
    case BulkEditEntity.FLEETS:
      return (
        <FleetsSelect
          companyId={companyId}
          value={value}
          onChange={onChange}
          optionRender={optionRender}
        />
      );
    default:
      return (
        <Select
          placeholder={placeholder}
          maxTagCount={5}
          mode={mode}
          showSearch
          allowClear
          notFoundContent={notFoundContent}
          value={value}
          onChange={onChange}
          options={options}
          filterOption={(input, option) =>
            option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          dropdownRender={menu => (
            <>
              {mode === 'multiple' && (
                <>
                  <Button
                    className={styles.selectAllBtn}
                    type="link"
                    size="small"
                    onClick={_ => {
                      onChange(value =>
                        value?.length === options?.length ? [] : options?.map(opt => opt.value)
                      );
                    }}
                  >
                    {value?.length === options?.length ? 'Unselect All' : 'Select All'}
                  </Button>
                  <Divider style={{ margin: '8px 0' }} />
                </>
              )}
              {menu}
            </>
          )}
        />
      );
  }
};

export const EntitySelectModal = ({ show, onOk, onCancel, entity, companyId, label, required }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();

  const companies = useCompanies();
  const getSelectMeta = useCallback(
    entity => {
      switch (entity) {
        case BulkEditEntity.COMPANY:
          return {
            placeholder: t('Locations.Form.CompanyPlaceholder'),
            options: companies?.map(company => ({
              label: company.name,
              value: company.id
            }))
          };
        default:
          break;
      }
    },
    [companies]
  );

  return (
    <Modal
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      okButtonProps={{
        disabled: required ? !value : false
      }}
      onOk={() => {
        onOk(value);
        setValue();
      }}
      onCancel={() => {
        setValue();
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{label}</Typography.Title>
        <EntitySelect
          entity={entity}
          companyId={companyId}
          value={value}
          onChange={setValue}
          companies={companies}
          {...getSelectMeta(entity)}
        />
      </Space>
    </Modal>
  );
};
