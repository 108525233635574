import {
  fetchDevicesSuccess,
  fetchFleetsCancelled,
  fetchFleetsStart,
  fetchFleetsSuccess,
  fetchVehiclesSucces
} from 'features/fleets/fleetsSlice';
import { TNDataService } from '../tnDataService';

export async function persistDataToLocalDB(store) {
  const startTime = performance.now();
  const { fleets, vehicles, devices } = store.getState();

  if (devices.list?.length > 0) {
    for (const d of devices.list) {
      await TNDataService.insertOrUpdateDevice(d);
    }
  }

  if (vehicles.list?.length > 0) {
    for (const v of vehicles.list) {
      if (v.id) {
        await TNDataService.insertOrUpdateVehicle(v);
      }
    }
  }

  if (vehicles.vehicles?.length > 0) {
    for (const v of vehicles.vehicles) {
      if (v.id) {
        await TNDataService.insertOrUpdateVehicle(v);
      }
    }
  }

  if (fleets.list?.length > 0) {
    for (const f of fleets.list) {
      if (f.id) {
        await TNDataService.insertOrUpdateFleet(f);

        if (f.vehicles?.length > 0) {
          for (const v of f.vehicles) {
            if (v.id) {
              const fv = {
                fleetId: f.id,
                vehicleId: v.id
              };
              await TNDataService.insertFleetVehicleAssociation(fv);
            }
          }
        }

        if (f.devices?.length > 0) {
          for (const d of f.devices) {
            if (d.id) {
              const fd = {
                fleetId: f.id,
                deviceId: d.id
              };
              await TNDataService.insertFleetDeviceAssociation(fd);
            }
          }
        }
      }
    }

    for (const f of fleets.deletedList) {
      if (f.id) {
        await TNDataService.insertOrUpdateFleet(f);
      }
    }
  }

  const endTime = performance.now();
  console.log('Persist Done - takes time ' + (endTime - startTime));
}

export async function loadDataFromLocalDB(store, user) {
  const startTime = performance.now();
  const companyKey = store.getState().companies.current.api_key;
  await store.dispatch(fetchFleetsStart({ companyKey: companyKey }));
  await TNDataService.openDb(user);
  const fleets = await TNDataService.getAllFleets();
  const vehicles = await TNDataService.getAllVehicles();
  const devices = await TNDataService.getAllDevices();
  const fleetsVehicles = await TNDataService.getFleetsVehiclesAssociaitons();
  const fleetsDevices = await TNDataService.getFleetsDevicesAssociations();

  if (fleets?.length > 0 || vehicles?.length > 0 || devices?.length > 0) {
    const normalFleets = [];
    const deletedFleets = [];

    const vehicleDict = vehicles.reduce((dict, v) => {
      dict[v.id] = v;
      return dict;
    }, {});

    const deviceDict = devices.reduce((dict, d) => {
      dict[d.id] = d;
      return dict;
    }, {});

    const fleetsAssociationDict = {};
    const vehiclesWithFleets = new Set();
    const devicesWithFleets = new Set();

    fleetsVehicles.forEach(fv => {
      if (!fleetsAssociationDict[fv.fleetId]) {
        fleetsAssociationDict[fv.fleetId] = {
          vehicles: [],
          devices: []
        };
      }
      fleetsAssociationDict[fv.fleetId].vehicles.push(fv.vehicleId);
      vehiclesWithFleets.add(fv.vehicleId);
    });

    fleetsDevices.forEach(fd => {
      if (!fleetsAssociationDict[fd.fleetId]) {
        fleetsAssociationDict[fd.fleetId] = {
          vehicles: [],
          devices: []
        };
      }
      fleetsAssociationDict[fd.fleetId].devices.push(fd.deviceId);
      devicesWithFleets.add(fd.deviceId);
    });

    for (const f of fleets) {
      if (f.status === 'E' || f.status === 'ENABLED') {
        normalFleets.push(f);
      } else {
        deletedFleets.push(f);
      }

      //populate vehicles
      f.vehicles = fleetsAssociationDict[f.id]?.vehicles.map(id => vehicleDict[id]) || [];

      //populate device for vehicles
      if (f.vehicles.length > 0) {
        f.vehicles.forEach(v => {
          v.devices = devices.filter(d => d.vehicleId === v.id);
        });
      }

      //pouplate devices for fleets
      f.devices = fleetsAssociationDict[f.id]?.devices.map(id => deviceDict[id]) || [];
    }

    //populate No Fleet Vehicles & Devices
    const noFleetVehiclesEnabled = [];
    const noFleetVehiclesDeleted = [];
    const noFleetDevicesEnabled = [];
    const noFleetDevicesDeleted = [];
    vehicles.forEach(v => {
      if (!vehiclesWithFleets.has(v.id)) {
        if (v.status === 'E' || v.status === 'ENABLED') {
          noFleetVehiclesEnabled.push(v);
        } else {
          noFleetVehiclesDeleted.push(v);
        }
      }
    });

    devices.forEach(d => {
      if (!devicesWithFleets.has(d.id)) {
        if (d.status === 'E' || d.status === 'ENABLED') {
          noFleetDevicesEnabled.push(d);
        } else {
          noFleetDevicesDeleted.push(d);
        }
      }
    });
    normalFleets.push({
      vehicles: noFleetVehiclesEnabled,
      devices: noFleetDevicesEnabled
    });
    deletedFleets.push({
      vehicles: noFleetVehiclesDeleted,
      devices: noFleetDevicesDeleted
    });

    store.dispatch(
      fetchFleetsSuccess({
        list: normalFleets,
        deletedList: deletedFleets,
        userKey: user.auth.key,
        companyKey: companyKey
      })
    );

    store.dispatch(
      fetchVehiclesSucces({
        list: normalFleets,
        userKey: deletedFleets,
        companyKey: companyKey
      })
    );

    store.dispatch(
      fetchDevicesSuccess({
        list: normalFleets,
        userKey: user.auth.key,
        companyKey: companyKey
      })
    );
  } else {
    store.dispatch(fetchFleetsCancelled());
  }

  const endTime = performance.now();
  console.log('Loading Done - takes time ' + (endTime - startTime));
}
