import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchFleetsByCompanyId, useFleeetsByCompanyId } from 'features/fleets/fleetsSlice';
import { AutoSizer, Column, Table, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { Modal, Select, Form, Spin, Tag } from 'antd';
import styles from '../VehiclesBulkEdit.module.scss';
import { BUTTON_IDS } from '../helper';
import { useDispatch } from 'react-redux';

const bulkEditVehicleCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const DeviceSelectModal = ({ show, onOk, onCancel, vehicles }) => {
  const { t } = useTranslation();
  const [tableRef, setTableRef] = useState(null);

  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const initialValues = useMemo(
    () =>
      vehicles?.reduce(
        (a, v) => ({
          ...a,
          [v.id]: {
            deviceId: v.devices?.map(i => i.id) || []
          }
        }),
        {}
      ),
    [vehicles]
  );

  useEffect(() => {
    if (tableRef) {
      bulkEditVehicleCache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [vehicles, tableRef]);

  const modelBodyHeight = useMemo(
    () => Math.min(52 * vehicles.length + 100, window.innerHeight - 360),
    [vehicles]
  );

  return (
    <Form initialValues={initialValues} form={form} component={false}>
      <Modal
        style={{ minWidth: 600 }}
        bodyStyle={{
          height: modelBodyHeight,
          display: 'block',
          padding: 0,
          marginTop: 24,
          borderTop: '1px solid #dadee3'
        }}
        okText={t('Common.Save')}
        cancelText={t('Common.Modal.Cancel')}
        title={t('Devices.BulkEdit.EditColumnTitle')}
        centered
        open={show}
        closable={false}
        maskClosable={false}
        onOk={() => {
          onOk(form.getFieldsValue(true));
        }}
        onCancel={() => {
          onCancel();
        }}
      >
        <AutoSizer>
          {({ height, width }) => {
            return (
              <Table
                deferredMeasurementCache={bulkEditVehicleCache}
                width={width}
                height={height}
                headerHeight={48}
                headerClassName={styles.tableHeader}
                gridClassName={styles.vehicleEditTable}
                rowHeight={bulkEditVehicleCache.rowHeight}
                rowCount={vehicles.length}
                rowGetter={({ index }) => vehicles[index]}
                rowStyle={{ alignItems: 'flex-start', paddingRight: 4 }}
                ref={ref => setTableRef(ref)}
              >
                <Column
                  id={BUTTON_IDS.deviceSelectTable_vehicle}
                  label={`${t('Entity.Vehicle')} ${t('Vehicles.View.Name')}`}
                  dataKey="name"
                  width={Math.floor(width * (1 / 3))}
                />
                <Column
                  id={BUTTON_IDS.deviceSelectTable_device}
                  label={t('Vehicles.Table.Devices')}
                  dataKey="vehicleId"
                  width={width - Math.floor(width * (1 / 3))}
                  cellRenderer={props => (
                    <CellMeasurer
                      cache={bulkEditVehicleCache}
                      columnIndex={0}
                      key={`cell-measurer-${props.dataKey}-${props.rowData.id}`}
                      parent={props.parent}
                      rowIndex={props.rowIndex}
                    >
                      <DeviceSelect
                        name={[props.rowData.id, 'deviceId']}
                        companyId={props.rowData.companyId}
                        vehicleId={props.rowData.id}
                        formValues={formValues}
                        placeholder={t('Vehicles.Form.DevicePlaceholder')}
                      />
                    </CellMeasurer>
                  )}
                />
              </Table>
            );
          }}
        </AutoSizer>
      </Modal>
    </Form>
  );
};

const DeviceSelect = ({ name, placeholder, companyId, formValues, vehicleId }) => {
  const { isFetching, devices } = useFleeetsByCompanyId({ companyId });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFleetsByCompanyId(companyId));
  }, []);

  const unAvailableDeviceIds = Object.entries(formValues)
    .filter(([key]) => parseInt(key) !== parseInt(vehicleId))
    .flatMap(([, value]) => value.deviceId);

  const options = useMemo(() => {
    return devices
      .filter(
        i =>
          !unAvailableDeviceIds.includes(i.id) &&
          !(
            i.vehicleId &&
            !Object.keys(formValues)
              .map(Number)
              .includes(i.vehicleId)
          )
      )
      .map(d => ({
        label: d.name,
        value: d.id
      }));
  }, [devices, unAvailableDeviceIds, formValues]);

  return (
    <Form.Item name={name}>
      <Select
        key={`${name[0]}-deviceSelect`}
        disabled={isFetching}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        mode="multiple"
        allowClear
        maxTagCount={'responsive'}
        tagRender={({ value, closable, onClose }) => {
          return (
            <Tag color={'default'} closable={closable} onClose={onClose}>
              {devices.find(i => i.id === value)?.name}
            </Tag>
          );
        }}
        notFoundContent={isFetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
};
