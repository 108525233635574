import { FeatureFlag, services } from 'features/permissions';
import { useUserInfo } from 'features/user/userSlice';

export const TILES_DIRECTION = 'horizontal';

export const TILES_GRID_SYSTEM = {
  gutter: 12,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 6
};

export const TYLE_LIST_STYLE = { padding: 20 };

export const PATHS = {
  REGULATORY: '/regulatory',
  TMA_DECLARATION: '/regulatory/tma_declaration',
  TCA_ENROLMENTS: '/settings/enrolments'
};

export const TILES = () => {
  const user = useUserInfo();
  const isSiteAdmin = () => user.siteAdmin;

  return [
    {
      translationKey: 'Regulatory.TMADeclaration',
      img: null,
      path: PATHS.TMA_DECLARATION,
      permissions: {
        everyCompanyService: [services.TMA]
      }
    },
    {
      translationKey: 'Common.TCAEnrolments',
      img: null,
      path: PATHS.TCA_ENROLMENTS,
      permissions: {
        featureFlag: FeatureFlag.TCAEnrolments.flag,
        otherConditions: [isSiteAdmin]
      }
    }
  ];
};

export const commentTypes = t => [
  {
    id: 'Road Closure',
    label: t('Regulatory.Modal.Form.CommentTypes.Road Closure')
  },
  {
    id: 'Redirection By Authorised Officer',
    label: t('Regulatory.Modal.Form.CommentTypes.Redirection by Authorised Officer')
  },
  {
    id: 'Operation Under Special Permit',
    label: t('Regulatory.Modal.Form.CommentTypes.Operation Under Special Permit')
  },
  {
    id: 'Other',
    label: t('Regulatory.Modal.Form.CommentTypes.Other')
  }
];

export const radioOptions = t => [
  {
    label: t('Regulatory.Modal.Form.RadioOptions.VehicleType'),
    value: 1
  },
  {
    label: t('Regulatory.Modal.Form.RadioOptions.Comment'),
    value: 2
  }
];

export const DEFAULT_TCA_TIMEZONE_CODE = 'ACT';
export const DATEPICKER_API_FORMAT = 'YYYY-MM-DDTHH:mm';
export const MOCKED_TIMEZONE = { offset: '+1100' };
