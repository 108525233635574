import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Badge } from 'antd';
import { Select } from 'components/ant';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';

import { FilterColumnsModal } from './Modals/FilterColumnsModal/FilterColumnsModal';
import { ViewsConfigModal } from './Modals/ViewsConfigModal/ViewsConfigModal';
import { AddViewModal } from './Modals/ViewsConfigModal/AddViewModal';

import { useUserGridSettings, updateUserGridSettings } from 'features/user/userGridSettingsSlice';
import { useCurrentCompany } from 'features/company/companySlice';

import { prepareDataForMultiselect } from 'utils/filters';
import { sortStrings } from 'utils/strings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify, faFilter } from '@fortawesome/free-solid-svg-icons';

import styles from '../grid.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ViewsGridHeader = ({
  allColumns,
  fleets,
  count,
  gridSettingsKey,
  defaultGridConfig,
  recordTypeSingular,
  recordTypePlural,
  showSearch,
  showCount,
  onSearchChange,
  onSortChange,
  oneWireMultiTempData,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const currentCompany = useCurrentCompany();
  const [filteredFleets, setFilteredFleets] = useState([]);
  const [showFilterColumnsModal, setShowFilterColumnsModal] = useState(false);
  const [showViewsConfigModal, setShowViewsConfigModal] = useState(false);
  const [showAddViewModal, setShowAddViewModal] = useState(false);

  const handleSearchChange = text => {
    if (onSearchChange) {
      onSearchChange(text);
    }
  };

  const handleViewSelect = item => {
    if (userGridSettings && userGridSettings.config) {
      let newUserGridSettings = JSON.parse(JSON.stringify(userGridSettings.config)); // deep copy
      newUserGridSettings.selectedView = item;
      dispatch(updateUserGridSettings(newUserGridSettings, gridSettingsKey, () => {}, false));
    }
  };

  const handleFilterdFleetsChange = newFilteredFleets => {
    setFilteredFleets(newFilteredFleets);

    if (userGridSettings && userGridSettings.lastFetched) {
      // Make a copy of the config from the API call for editing
      let newConfig;
      if (!userGridSettings.config) {
        newConfig = JSON.parse(JSON.stringify(defaultGridConfig)); // deep copy
      } else {
        newConfig = JSON.parse(JSON.stringify(userGridSettings.config)); // deep copy
      }

      let viewConfig = newConfig.views[newConfig.selectedView];

      viewConfig.filterFleets = {};
      if (newFilteredFleets.find(f => f.checked && f.id === 0)) {
        viewConfig.filterFleets.includeAllFleets = true;
        viewConfig.filterFleets.includedFleetIds = [];
      } else {
        viewConfig.filterFleets.includeAllFleets = false;
        viewConfig.filterFleets.includedFleetIds = newFilteredFleets
          .filter(f => f.checked)
          .map(f => f.id);
      }
      viewConfig.filterFleets.companyId = currentCompany.id;

      dispatch(updateUserGridSettings(newConfig, gridSettingsKey, () => {}, false));
    }
  };

  useEffect(() => {
    let checkedIds = null;

    // If userGridSettings has fleet filter setup for current company then use as initial state in drop down
    if (userGridSettings && userGridSettings.config) {
      const viewConfig = userGridSettings.config.views[userGridSettings.config.selectedView];
      const filterFleets = viewConfig.filterFleets;
      if (
        filterFleets &&
        filterFleets.companyId === currentCompany.id &&
        !filterFleets.includeAllFleets
      ) {
        checkedIds = filterFleets.includedFleetIds;
      }
    }

    const fleetsOptions = fleets.filter(fleet => fleet.id !== -1);
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilteredFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), checkedIds));
  }, [fleets, userGridSettings, currentCompany]);

  let views = [{ id: 'Default' }];
  let selectedView = 'Default';
  let numFilters = 0;
  if (userGridSettings && userGridSettings.config) {
    views = Object.keys(userGridSettings.config.views)
      .sort((a, b) => sortStrings(a, b))
      .map(view => ({ id: view }));
    selectedView = userGridSettings.config.selectedView;
    let filters =
      userGridSettings.config.views[userGridSettings.config.selectedView].filterColumns || [];
    const oneWireMultiFilterValues = [
      ...Object.values(oneWireMultiTempData || {}).map(value => {
        return {
          title: value,
          label: value,
          dataIndex: value,
          key: value,
          ellipsis: true,
          type: ColumnTypes.Number,
          isConfigurable: true,
          isFilterable: true,
          isSearchable: true,
          isSelectedByDefault: false,
          width: 150
        };
      }),
      ...allColumns
    ];
    filters = filters.filter(filter =>
      oneWireMultiFilterValues.find(column => column.key === filter.columnKey)
    );
    numFilters = filters.length;
  }

  return (
    <header className={styles.gridHeader} {...props}>
      <Select
        className={styles.viewSelect}
        data={views}
        width={160}
        value={selectedView}
        size={'middle'}
        onSelect={handleViewSelect}
      />
      <AntMultiselect
        className={styles.fleetFilter}
        size={'middle'}
        title={
          filteredFleets?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
        }
        onFilter={f => handleFilterdFleetsChange(f)}
        data={filteredFleets}
      />
      {showSearch && (
        <AntSearchbar size="default" className={styles.searchBar} onFilter={handleSearchChange} />
      )}
      <Badge
        offset={[-13, 3]}
        count={numFilters}
        style={{
          minWidth: '16px',
          height: '16px',
          padding: '0 2px',
          lineHeight: '16px',
          borderRadius: '8px'
        }}
      >
        <Button
          className={styles.filterColumnsButton}
          id={BUTTON_IDS.showFilterColumnsModal}
          onClick={() => {
            setShowFilterColumnsModal(true);
          }}
        >
          <FontAwesomeIcon className={styles.filterIcon} icon={faFilter} />
        </Button>
      </Badge>
      <Button
        className={styles.viewsConfigButton}
        id={BUTTON_IDS.viewsConfigModal}
        onClick={() => {
          setShowViewsConfigModal(true);
        }}
      >
        <FontAwesomeIcon className={styles.viewsConfigIcon} rotation={90} icon={faAlignJustify} />
      </Button>
      {showCount && (
        <span className={styles.recordCount}>
          {count + ' ' + (count === 1 ? recordTypeSingular : recordTypePlural)}
        </span>
      )}
      {showFilterColumnsModal && (
        <FilterColumnsModal
          allColumns={allColumns}
          gridSettingsKey={gridSettingsKey}
          defaultGridConfig={defaultGridConfig}
          isOpen={showFilterColumnsModal}
          oneWireMultiTempData={oneWireMultiTempData}
          onClose={() => {
            setShowFilterColumnsModal(false);
          }}
        />
      )}
      {showViewsConfigModal && (
        <ViewsConfigModal
          allColumns={allColumns}
          gridSettingsKey={gridSettingsKey}
          defaultGridConfig={defaultGridConfig}
          isOpen={showViewsConfigModal}
          onClose={() => {
            setShowViewsConfigModal(false);
          }}
          onCreateNewView={() => {
            setShowViewsConfigModal(false);
            setShowAddViewModal(true);
          }}
          oneWireMultiTempData={oneWireMultiTempData}
        />
      )}
      {showAddViewModal && (
        <AddViewModal
          allColumns={allColumns}
          gridSettingsKey={gridSettingsKey}
          defaultGridConfig={defaultGridConfig}
          isOpen={showAddViewModal}
          onClose={() => {
            setShowAddViewModal(false);
          }}
          oneWireMultiTempData={oneWireMultiTempData}
        />
      )}
    </header>
  );
};
