export function locationTypeObjectToDBO(locationType) {
  return {
    id: locationType.id,
    name: locationType.name,
    description: locationType.description,
    code: locationType.code,
    created_at: locationType.createdAt,
    updated_at: locationType.updatedAt
  };
}

export function locationTypeDBOToObject(locationType) {
  return {
    id: locationType.id,
    name: locationType.name,
    description: locationType.description,
    code: locationType.code,
    createdAt: locationType.created_at,
    updatedAt: locationType.updated_at
  };
}
