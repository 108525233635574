import { Button } from 'antd';
import { DeleteFilled, DeleteOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './Widget.module.scss';
export const Widget = ({ widget, handleDelete }) => {
  const sizeClass = styles[widget.size] || styles.small;

  const handleDeleteWidget = () => {
    handleDelete(widget.id);
  };

  return (
    <div className={classNames(styles.widgetContainer, sizeClass)}>
      <h4 className={styles.widgetTitle}>{widget.title}</h4>
      <Button
        type="secondary"
        size="small"
        className={styles.widgetDelete}
        onClick={handleDeleteWidget}
      >
        <DeleteFilled className={styles.deleteIcon} />
      </Button>
    </div>
  );
};
