import React, { useState } from 'react';
import { Tab } from '../Components/Tab';
import { AvailableTableColumns, ViewMode } from '../constant';

export function EventsTab({ sharedData, setSharedData }) {
  const [eventsColumns] = useState([
    AvailableTableColumns.Device,
    AvailableTableColumns.Vehicle,
    AvailableTableColumns.Fleet,
    AvailableTableColumns.Driver,
    AvailableTableColumns.EventTime,
    AvailableTableColumns.Location,
    AvailableTableColumns.Type,
    AvailableTableColumns.Status,
    AvailableTableColumns.Tags,
    AvailableTableColumns.Actions
  ]);

  return (
    <Tab
      id="eventsTab"
      defaultView={ViewMode.GridView}
      tableColumns={eventsColumns}
      sharedData={sharedData}
      setSharedData={setSharedData}
    />
  );
}
