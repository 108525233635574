import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { List, Divider, Button } from 'antd';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

export function EventAttachments({
  attachments,
  onAdd = (attachment, onSuccess, onError) => {},
  onDelete = (attachment, onSuccess, onError) => {}
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localization = useLocalization();

  const [isProcessing, setIsProcessing] = useState(false);

  const onAddAttachment = useCallback(() => {
    setIsProcessing(true);
    onAdd(
      {},
      () => {
        setIsProcessing(false);
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Event.Notifications.Added', { name: t('SmartJobs.Status.attachment') })
          })
        );
      },
      errMsg => {
        setIsProcessing(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Event.Notifications.AddFailed', {
              name: t('SmartJobs.Status.attachment'),
              error: errMsg
            })
          })
        );
      }
    );
  }, [dispatch, t]);
  return (
    <>
      <List
        size="small"
        header={<p>{t('SmartJobs.Attachments')}</p>}
        dataSource={attachments}
        renderItem={attachment => (
          <List.Item>
            {
              <div>
                <p>{attachment.name}</p>
                {moment(attachment.created_at).format(localization.formats.time.formats.dby_imsp)}
              </div>
            }
          </List.Item>
        )}
      />
      <Button loading={isProcessing} onClick={onAddAttachment}>
        {`${t('Common.Add')} ${t('SmartJobs.Status.attachment')}`}
      </Button>
      <Divider />
    </>
  );
}
