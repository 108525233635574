import { CellMeasurerCache } from 'react-virtualized';
import { join } from 'lodash';
import moment from 'moment';
import { BulkEditEntity } from 'features/bulkEdit/constant';

export const BUTTON_IDS = {
  edit_single_vehicle: 'bulkEditVehicles_edit_single_vehicle',
  delete_single_vehicle: 'bulkEditVehicles_delete_single_vehicle',
  delete_multi_vehicles: 'bulkEditVehicles_delete_multi_vehicles',
  deviceSelectTable_device: 'bulkEditVehicles_DeviceSelectTable_device',
  deviceSelectTable_vehicle: 'bulkEditVehicles_DeviceSelectTable_vehicle',
  edit_dropdown: 'bulkEditVehicles_vehicles_dropdown',
  edit_fleets: 'bulkEditVehicles_fleets',
  edit_phone: 'bulkEdiVehicles_phone',
  edit_notes: 'bulkEditVehicles_notes',
  edit_is_eld: 'bulkEditVehicles_is_eld',
  edit_type: 'bulkEditVehicles_type',
  edit_model: 'bulkEditVehicles_model',
  edit_devices: 'bulkEditVehicles_devices',
  edit_in_service_at: 'bulkEditVehicles_in_service_at',
  edit_out_service_at: 'bulkEditVehicles_out_service_at',
  edit_registration: 'bulkEditVehicles_registration'
};

export const bulkEditCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 100
});

const spliter = '-';
export const BULK_EDIT_Entity_OF_Audit = {
  status: {
    title: 'Common.status',
    key: 'status'
  },
  fleets: {
    title: 'Fleets.Fleets',
    key: 'fleets',
    getChangeValueKey(fleets = []) {
      return join(
        fleets.map(f => f?.id || f),
        spliter
      );
    }
  },
  type: {
    title: 'Vehicles.Form.Type',
    key: 'type',
    getChangeValueKey(types = []) {
      return join(
        types.map(f => f?.id || f),
        spliter
      );
    }
  },
  phone: {
    title: 'Devices.ActualForm.PhoneLabel',
    key: 'phone'
  },
  make: {
    title: 'Vehicles.Form.Make',
    key: 'make'
  },
  model: {
    title: 'Vehicles.Form.Model',
    key: 'model'
  },
  device: {
    title: 'Entity.Device',
    key: 'vehicle',
    getChangeValueKey(devices = []) {
      return join(
        devices.map(d => d?.id || d),
        spliter
      );
    }
  },
  manufacturedOn: {
    title: 'Vehicles.Form.Year',
    key: 'manufacturedOn'
  },
  note: {
    title: 'Devices.ActualForm.NotesLabel',
    key: 'note'
  },
  registrationCountry: {
    title: 'ExportToExcel.RegistrationCountry',
    key: 'registrationCountry'
  },
  registrationState: {
    title: 'ExportToExcel.RegistrationState',
    key: 'registrationState'
  },
  inServiceAt: {
    title: 'Vehicles.Form.InServiceAt',
    key: 'inServiceAt'
  },
  outServiceAt: {
    title: 'Vehicles.Form.OutServiceAt',
    key: 'outServiceAt'
  },
  eldVehicle: {
    title: 'Vehicles.Form.IsEldVehicle',
    key: 'eldVehicle'
  }
};

export const toBulkUpdatePayload = (
  entity,
  entityValue,
  selectedRows = [],
  extra = { getDateTime: date => date }
) => {
  const getEntityPayload =
    BULK_EDIT_ENTITY[entity].getPayload ||
    ((entityValue, v, extra) => ({
      id: v.id,
      [BULK_EDIT_ENTITY[entity].key]: entityValue
    }));
  return selectedRows.map(v => getEntityPayload(entityValue, v, extra));
};

export const BULK_EDIT_ENTITY = {
  IN_SERVICE_AT: {
    entity: 'IN_SERVICE_AT',
    key: 'inServiceAt'
  },
  OUT_SERVICE_AT: {
    entity: 'OUT_SERVICE_AT',
    key: 'outServiceAt'
  },
  REGISTRATION_COUNTRY_AND_STATE: {
    entity: 'REGISTRATION_COUNTRY_AND_STATE',
    key: 'registrationCountryAndState',
    getPayload(entityValue, v) {
      return {
        id: v.id,
        registrationCountry: entityValue.country,
        registrationState: entityValue.state
      };
    }
  },
  TYPE: {
    entity: 'TYPE',
    key: 'type',
    getPayload(entityValue, v) {
      return {
        id: v.id,
        type: { id: entityValue }
      };
    }
  },
  DEVICE: {
    entity: 'DEVICE',
    key: 'device',
    getPayload(entityValue, vehicle) {
      const deviceIds = entityValue[vehicle.id]?.deviceId;
      return {
        id: vehicle.id,
        devices: deviceIds.map(i => {
          return { id: i };
        })
      };
    }
  },
  VEHICLE_MODEL: {
    entity: 'VEHICLE_MODEL',
    key: 'vehicleModelId'
  },
  FLEETS: {
    entity: BulkEditEntity.FLEETS,
    key: 'fleetIds',
    getPayload(entityValue, device) {
      return {
        id: device.id,
        fleets: entityValue?.map(fleetId => ({ id: fleetId })) || []
      };
    }
  },
  ELD_VEHICLE: {
    entity: 'ELD_VEHICLE',
    key: 'eldVehicle'
  },
  PHONE_NUMBER: {
    entity: 'PHONE_NUMBER',
    key: 'phone'
  },
  NOTES: {
    entity: 'NOTES',
    key: 'note',
    getPayload(entityValue, v, { getDateTime }) {
      const note = `${v.note || ''}
*Bulk added note on ${getDateTime(moment.now())}:*
${entityValue || ''}`;
      return { id: v.id, note };
    }
  }
};
