import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const rolesDataAdapter = reduxAdapter({
  persist: async roles => {
    if (roles && roles.length) {
      const rolesToClearPermissions = [];
      const rolesToRemove = [];
      const rolePermissionsToInsert = [];
      const rolesToAdd = [];

      for (const r of roles) {
        if (r.id) {
          rolesToClearPermissions.push(r.id);
          if (r.status === 'DISABLED') {
            rolesToRemove.push(r.id);
          } else {
            rolesToAdd.push(r);
            if (r.permissionIds) {
              r.permissionIds.forEach(p =>
                rolePermissionsToInsert.push({ roleId: r.id, permissionId: p })
              );
            }
          }
        }
      }

      if (rolesToClearPermissions.length) {
        await TNDataService.deleteRolePermissionsInBulk(rolesToClearPermissions);
      }

      if (rolesToRemove.length) {
        await TNDataService.deleteRolesInBulk(rolesToRemove);
      }

      if (rolesToAdd.length) {
        await TNDataService.insertOrUpdateRolesInBulk(rolesToAdd);
      }

      if (rolePermissionsToInsert.length) {
        await TNDataService.insertOrUpdateRolePermissionsInBulk(rolePermissionsToInsert);
      }
    }
  },
  load: async (loadPermissions = true) => {
    const roles = await TNDataService.getAllRoles();
    let permissions = null;
    let rolePermissions = null;

    if (loadPermissions) {
      permissions = await TNDataService.getAllPermissions();
      rolePermissions = await TNDataService.getAllRolePermissions();

      roles.forEach(r => {
        const rp = rolePermissions.reduce((a, c) => {
          if (c.roleId === r.id) {
            a.add(c.permissionId);
          }
          return a;
        }, new Set());
        r.permissions = Array.from(permissions.filter(p => rp.has(p.id)) || []);
      });
    }
    return roles;
  },
  // persistSpecificRole: async role => {
  //   if (role && role.id) {
  //     await TNDataService.getAllRolePermissions(role);
  //   }
  // },
  // loadSpecificRole: async () => {
  //   const permissions = await TNDataService.getAllPermissions();
  //   //wip
  //   return permissions;
  // },
  delete: async roleId => {
    await TNDataService.deleteRole(roleId);
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_roles');
    return result;
  },
  updateLastUpdatedDate: async lastUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_roles', lastUpdatedDate);
    return result;
  }
});

export { rolesDataAdapter };
