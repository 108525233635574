import { Route, Switch } from 'react-router';
import { NoAccessBody } from 'components/pages/NoAccess';
import { TCAEnrolments } from 'containers/Configuration/TCAEnrolments';
import { Paths as TCAPATHS } from 'containers/Configuration/TCAEnrolments/constants';
import { Can, FeatureFlag, services } from 'features/permissions';
import { useUserInfo } from 'features/user/userSlice';
import { PATHS } from './constants';
import { Regulatory } from './Regulatory';
import { TMADeclaration } from './TMADeclaration';

export const RegulatoryRouter = () => {
  const user = useUserInfo();
  const isSiteAdmin = () => user.siteAdmin;

  return (
    <Can everyCompanyService={[services.TMA]} onFail={<NoAccessBody />}>
      <Switch>
        <Route exact path={TCAPATHS.ENROLMENTS_TABLE}>
          <Can featureFlag={FeatureFlag.TCAEnrolments.flag} otherConditions={[isSiteAdmin]}>
            <TCAEnrolments />
          </Can>
        </Route>
        <Route exact path={PATHS.TMA_DECLARATION}>
          <Can everyCompanyService={[services.TMA]} onFail={<NoAccessBody />}>
            <TMADeclaration />
          </Can>
        </Route>
        <Route path={PATHS.REGULATORY}>
          <Can everyCompanyService={[services.TMA]} onFail={<NoAccessBody />}>
            <Regulatory />
          </Can>
        </Route>
      </Switch>
    </Can>
  );
};
