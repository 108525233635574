import { TEMPLATE_TYPES } from './constants';

export const formatTemplates = (templates, option) => {
  return templates.reduce((temps, temp) => {
    const fileExtension = temp.filename.split('.')[1];
    option = option.split('_')[0];
    return (
      (temp.type === TEMPLATE_TYPES[option] && {
        ...temps,
        [fileExtension]: {
          id: temp.id || -1,
          fileName: temp.filename || 'N/A',
          infoName: `${option}-template.${fileExtension}` || 'N/A'
        }
      }) ||
      temps
    );
  }, {});
};

export const filterBulkData = ({ bulkData, debouncedSearchText, typeFilter, statusFilter }) => {
  if (!bulkData) {
    return [];
  }
  return bulkData.reduce((acc, entry) => {
    // filters are not selected, return all the data
    if (!typeFilter && !statusFilter) {
      acc.push(entry);
    }

    // both filters selected, filter by both
    if (statusFilter && typeFilter) {
      entry.type === typeFilter && entry.status === statusFilter && acc.push(entry);
    }

    // only status filter selected
    if (statusFilter && !typeFilter) {
      entry.status === statusFilter && acc.push(entry);
    }

    // only type filter selected
    if (!statusFilter && typeFilter) {
      entry.type === typeFilter && acc.push(entry);
    }

    // search text filter
    if (debouncedSearchText) {
      acc = acc.filter(data => data.fileName?.toLowerCase()?.includes(debouncedSearchText));
    }

    return acc;
  }, []);
};
