export function permissionObjectToDBO(permission) {
  return {
    id: permission.id,
    subject: permission.subject,
    code: permission.code,
    tag: permission.tag,
    name: permission.name,
    action: permission.action,
    category: permission.category,
    type: permission.type,
    description: permission.description,
    site_admin: permission.siteAdmin,
    created_at: permission.createdAt,
    updated_at: permission.updatedAt,
    status: permission.status
  };
}

export function permissionDBOToObject(permission) {
  return {
    id: permission.id,
    subject: permission.subject,
    code: permission.code,
    tag: permission.tag,
    name: permission.name,
    action: permission.action,
    category: permission.category,
    type: permission.type,
    description: permission.description,
    siteAdmin: permission.site_admin,
    createdAt: permission.created_at,
    updatedAt: permission.updated_at,
    status: permission.status
  };
}
