import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DriverStatusId } from 'containers/ELD/Constants';
import { rulesetsDataAdapter } from 'data/dataService/tn/adapter/rulesets';

export const actionTypes = {
  init: 0,
  pending: 1,
  processing: 2,
  error: 3,
  done: 4
};

const statusType = {
  DriverELDStatus: 0
};

function getStatusId(type, id, fromDate, toDate) {
  switch (type) {
    case statusType.DriverELDStatus:
      return `user_eld_status_${id}_${fromDate.getTime()}_${toDate.getTime()}`;
    default:
      return null;
  }
}

const REST_TIME = 60 * 1000;
const MAX_REQUEST_COUNT = 5;

export const eld_data = {
  eldRuleset: null,
  pendingStartOfDay: null,
  unsignedLogs: null,
  suggestedDriverEvents: null,
  suggestedUDT: null,
  companies: {
    /*structure
    [company id]: {
      [user id]: {
          'dateRange': {
            eld object
          }
        }
      }
    }
    */
  },
  status: {}
};

const eldSlice = createSlice({
  name: 'eldData',
  initialState: eld_data,
  reducers: {
    //fetch eld status by user id
    fetchELDStatusStart(state, { payload }) {
      const { userId, fromDate, toDate } = payload;
      const statusId = getStatusId(statusType.DriverELDStatus, userId, fromDate, toDate);
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchELDStatusSucceeded(state, { payload }) {
      const { userId, data, fromDate, toDate, companyId } = payload;
      const statusId = getStatusId(statusType.DriverELDStatus, userId, fromDate, toDate);
      if (data != null) {
        if (state.companies[companyId] == null) {
          state.companies[companyId] = {};
        }

        if (state.companies[companyId][userId] == null) {
          state.companies[companyId][userId] = {};
        }

        state.companies[companyId][userId][fromDate.getTime() + '_' + toDate.getTime()] = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchELDStatusFailed(state, { payload }) {
      const { userId, error, fromDate, toDate } = payload;
      const statusId = getStatusId(statusType.DriverELDStatus, userId, fromDate, toDate);
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    },
    fetchUserSessionsSuccess(state, { payload }) {
      const { companyId, data, fromDate, toDate } = payload;
      for (let driverId in data) {
        const statusId = getStatusId(statusType.DriverELDStatus, driverId, fromDate, toDate);

        if (state.companies[companyId] == null) {
          state.companies[companyId] = {};
        }

        if (state.companies[companyId][driverId] == null) {
          state.companies[companyId][driverId] = {};
        }

        const oldData =
          state.companies[companyId][driverId][fromDate.getTime() + '_' + toDate.getTime()];

        const driverAction = DriverStatusId[oldData?.lastStatusEvent?.action];
        if (
          oldData == null ||
          oldData.checkpointAt !== data[driverId].checkpointAt ||
          oldData.pivotTimeAt !== data[driverId].pivotTimeAt ||
          oldData.lastEvent?.timeAt !== data[driverId].lastEvent?.timeAt ||
          oldData.lastEvent?.eventAt !== data[driverId].lastEvent?.eventAt ||
          driverAction === DriverStatusId.Driving ||
          driverAction === DriverStatusId.OnDuty
        ) {
          state.companies[companyId][driverId][fromDate.getTime() + '_' + toDate.getTime()] =
            data[driverId];
          state.status[statusId] = {
            ...state.status[statusId],
            fetching: actionTypes.done,
            error: null
          };
        }
      }
    },
    pendingFetchELDStatus(state, { payload }) {
      const { driverIdList, fromDate, toDate } = payload;
      //check and set request status

      for (const index in driverIdList) {
        const driverId = driverIdList[index].id;
        const statusId = getStatusId(statusType.DriverELDStatus, driverId, fromDate, toDate);
        const fetchStatus = state.status[statusId];
        if (
          fetchStatus == null ||
          (fetchStatus.fetching !== actionTypes.processing &&
            fetchStatus.fetching !== actionTypes.pending &&
            moment().valueOf() - fetchStatus.fetchingTime > REST_TIME)
        ) {
          state.status[statusId] = {
            fetching: actionTypes.pending,
            fetchingTime: null,
            error: null
          };
        }
      }
    },
    fetchELDRulesetStart(state, { payload }) {
      state.status.eldRuleset = {
        fetchingTime: moment().valueOf(),
        fetching: actionTypes.processing,
        error: null
      };
    },
    fetchELDRulesetSucceeded(state, { payload }) {
      const { data } = payload;
      state.eldRuleset = data;
      state.status.eldRuleset = {
        ...state.status.eldRuleset,
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchELDRulesetFailed(state, { payload }) {
      const { error } = payload;
      state.status.eldRuleset = {
        ...state.status.eldRuleset,
        fetching: actionTypes.error,
        error: error
      };
    },
    fetchPendingStartOfDayStart(state, { payload }) {
      state.pendingStartOfDay = null;
    },
    fetchPendingStartOfDaySucceeded(state, { payload }) {
      const { data } = payload;
      state.pendingStartOfDay = data;
    },
    fetchPendingStartOfDayFailed(state, { payload }) {
      state.pendingStartOfDay = null;
    },
    fetchUnsignedLogsStart(state, { payload }) {
      state.unsignedLogs = null;
    },
    fetchUnsignedLogsSucceeded(state, { payload }) {
      const { data } = payload;
      state.unsignedLogs = data;
    },
    fetchUnsignedLogsFailed(state, { payload }) {
      state.unsignedLogs = null;
    },
    fetchSuggestedDriverEventsStart(state, { payload }) {
      state.suggestedDriverEvents = null;
    },
    fetchSuggestedDriverEventsSucceeded(state, { payload }) {
      const { data } = payload;
      state.suggestedDriverEvents = data;
    },
    fetchSuggestedDriverEventsFailed(state, { payload }) {
      state.suggestedDriverEvents = null;
    },
    fetchSuggestedUDTStart(state, { payload }) {
      state.suggestedUDT = null;
    },
    fetchSuggestedUDTSucceeded(state, { payload }) {
      const { data } = payload;
      state.suggestedUDT = data;
    },
    fetchSuggestedUDTFailed(state, { payload }) {
      state.suggestedUDT = null;
    }
  }
});

export const {
  fetchELDStatusStart,
  fetchELDStatusSucceeded,
  fetchELDStatusFailed,
  pendingFetchELDStatus,
  fetchUserSessionsSuccess,

  fetchELDRulesetStart,
  fetchELDRulesetSucceeded,
  fetchELDRulesetFailed,

  fetchPendingStartOfDayStart,
  fetchPendingStartOfDaySucceeded,
  fetchPendingStartOfDayFailed,
  fetchUnsignedLogsStart,
  fetchUnsignedLogsSucceeded,
  fetchUnsignedLogsFailed,
  fetchSuggestedDriverEventsStart,
  fetchSuggestedDriverEventsSucceeded,
  fetchSuggestedDriverEventsFailed,
  fetchSuggestedUDTStart,
  fetchSuggestedUDTSucceeded,
  fetchSuggestedUDTFailed
} = eldSlice.actions;

export const fetchELDDataByDriverId = (driverId, companyKey, ruleSet, fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const statusId = getStatusId(statusType.DriverELDStatus, driverId, fromDate, toDate);
  const fetchInfo = getState().eldData.status[statusId];
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;
  if (
    fetchInfo?.fetching === actionTypes.processing ||
    companyKey == null ||
    companyId == null ||
    !ruleSet?.length
  ) {
    return driverId;
  }

  dispatch(fetchELDStatusStart({ userId: driverId, fromDate, toDate }));

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    const isNow = moment().isSame(fromDate, 'day');
    const sentinelUrl = `/sentinel/users/${driverId}/status?company_id=${companyId}`;
    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        checkpoint: isNow ? moment().toISOString() : toDate.toISOString()
      },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(
      fetchELDStatusSucceeded({
        userId: driverId,
        data: data,
        fromDate,
        toDate,
        companyId: companyId
      })
    );
  } catch (err) {
    const payload = { userId: driverId, error: err.toString(), fromDate, toDate };
    dispatch(fetchELDStatusFailed(payload));
  }
  return driverId;
};

export const fetchPendingStartOfDay = driverId => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;

  dispatch(fetchPendingStartOfDayStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/future/actions/${driverId}`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchPendingStartOfDaySucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchPendingStartOfDayFailed(payload));
  }
};

export const fetchOverviewELDData = (driverIdList, companyKey, companyId, userKey) => async (
  dispatch,
  getState
) => {
  if (driverIdList?.length > 0 && userKey && companyKey) {
    const fromDate = moment().startOf('day');
    const toDate = moment(fromDate).endOf('day');

    await dispatch(
      pendingFetchELDStatus({ driverIdList, fromDate: fromDate.toDate(), toDate: toDate.toDate() })
    );

    //fetch status reports from users api first
    const usersFetchingPromise = new Promise((resolve, reject) => {
      const apiClient = new ApiClient();
      apiClient.basePath = API_PATH;
      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };
      apiClient.callApi(
        `/sentinel/users/statusreports?embed=user_session&company_id=${companyId}`,
        'GET',
        {},
        {},
        {},
        {},
        {},
        [],
        [],
        [],
        null,
        null,
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });
    try {
      const driverStatusReportList = await usersFetchingPromise;
      const driverStatusData = {};
      driverStatusReportList.forEach(d => {
        let lastStatusReport = d.userSession?.lastStatusReport;
        const hasData = !!lastStatusReport;
        if (hasData) {
          driverStatusData[d.id] = JSON.parse(lastStatusReport);
          driverIdList.splice(
            driverIdList.findIndex(i => i.id === d.id),
            1
          );
        }
      });
      dispatch(
        fetchUserSessionsSuccess({
          companyId,
          data: driverStatusData,
          fromDate: fromDate.toDate(),
          toDate: toDate.toDate()
        })
      );
    } catch (err) {
      console.debug(err?.message);
    }

    //do requests with restricted by maximum request account
    const queueRequests = [];
    for (const idx in driverIdList) {
      const driverId = driverIdList[idx].id;
      if (queueRequests.length >= MAX_REQUEST_COUNT) {
        await Promise.race(queueRequests).then(driverId => {
          queueRequests.splice(
            queueRequests.findIndex(req => req.driverId === driverId),
            1
          );
        });
      }

      const ruleset = driverIdList[idx].rulesets?.[0]?.ruleset;
      let newReq = dispatch(
        fetchELDDataByDriverId(driverId, companyKey, ruleset, fromDate.toDate(), toDate.toDate())
      );
      newReq.driverId = driverId;
      queueRequests.push(newReq);
    }
    await Promise.all(queueRequests); //wait all requests completed.
  }
};

export const fetchELDRuleset = includeRulesetPreRelease => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchELDRulesetStart());
  const cachedRulesets = await rulesetsDataAdapter.load(includeRulesetPreRelease, true);
  if (cachedRulesets.length > 0) {
    dispatch(fetchELDRulesetSucceeded({ data: cachedRulesets }));
  } else {
    const promise = new Promise((resolve, reject) => {
      const apiClient = new ApiClient();
      apiClient.basePath = API_PATH;

      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };

      var includePreRelease = includeRulesetPreRelease ? '&includePreRelease=true' : '';
      var sentinelUrl = `/sentinel/rulesets/ELD?company_id=${companyId}${includePreRelease}`;

      apiClient.callApi(
        sentinelUrl,
        'GET',
        {},
        {},
        {},
        {},
        {},
        [],
        [],
        [],
        null,
        null,
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    try {
      const data = await promise;
      rulesetsDataAdapter.persist(data);
      dispatch(fetchELDRulesetSucceeded({ data: data }));
    } catch (err) {
      const payload = { error: err.toString() };
      dispatch(fetchELDRulesetFailed(payload));
    }
  }
};

export const fetchUnsignedLogs = driverId => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;

  dispatch(fetchUnsignedLogsStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/sentinel/users/${driverId}/signlogs`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchUnsignedLogsSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchUnsignedLogsFailed(payload));
  }
};

export const fetchSuggestedDriverEvents = driverId => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;

  dispatch(fetchSuggestedDriverEventsStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/sentinel/users/${driverId}/suggest/de`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchSuggestedDriverEventsSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchSuggestedDriverEventsFailed(payload));
  }
};

export const fetchSuggestedUDT = driverId => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;

  dispatch(fetchSuggestedUDTStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/sentinel/users/${driverId}/suggest/udt`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchSuggestedUDTSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchSuggestedUDTFailed(payload));
  }
};

export const useELDStatus = () => useSelector(state => state.eldData.status);
export const useAllELDData = () => useSelector(state => state.eldData.companies);

export const useGetPercentageOfFetchingOverviewELDData = drivers => {
  const fromDate = moment().startOf('day');
  const toDate = moment(fromDate).endOf('day');
  const eldStatus = useELDStatus();
  if (drivers == null || drivers.length === 0) return 1;

  let processAndPendingFetch = 0;
  drivers.forEach(driver => {
    const fetchingELDStatus =
      eldStatus[
        getStatusId(statusType.DriverELDStatus, driver.id, fromDate.toDate(), toDate.toDate())
      ];
    if (
      fetchingELDStatus?.fetching !== actionTypes.pending &&
      fetchingELDStatus?.fetching !== actionTypes.processing
    ) {
      return processAndPendingFetch++;
    }
  });
  return Math.min(processAndPendingFetch / drivers.length, 1);
};

export const useGetDriversELDData = companyId => {
  const eldData = useSelector(state => state.eldData.companies[companyId]);
  return eldData;
};

export const useDriverELDData = (companyId, driverId, fromDate, toDate) => {
  if (fromDate == null) {
    fromDate = moment()
      .startOf('day')
      .toDate();
    toDate = moment(fromDate)
      .endOf('day')
      .toDate();
  }
  const eldData = useSelector(
    state =>
      state.eldData.companies[companyId]?.[driverId]?.[fromDate.getTime() + '_' + toDate.getTime()]
  );
  return eldData;
};

export const useELDRuleset = () => {
  const ruleset = useSelector(state => state.eldData.eldRuleset);
  return ruleset;
};

export const usePendingStartOfDay = () => {
  const pending = useSelector(state => state.eldData.pendingStartOfDay);
  return pending;
};

export const useUnsignedLogs = () => {
  const pending = useSelector(state => state.eldData.unsignedLogs);
  return pending;
};

export const useSuggestedDriverEvents = () => {
  return useSelector(state => state.eldData.suggestedDriverEvents);
};
export const useSuggestedUDT = () => {
  return useSelector(state => state.eldData.suggestedUDT);
};

export default eldSlice.reducer;
