import { useGpioRelatedConfigKeys } from 'services/nextgen/ngConfigKeyApi';
import { BPM_VPM_KEYS, DRIVER_KEYS, IDLE_KEYS, LED_KEYS, SIREN_KEYS } from '../constants';
import {
  FeatureFlag,
  services,
  useCanEveryCompanyService,
  useCanFeatureFlag
} from 'features/permissions';
import { constructForm } from '../helpers';

export const useGPIORelatedConfigs = () => {
  const driverLoginalertFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.driverLoginAlert.flag
  });

  const isVPMEnabled = useCanEveryCompanyService(services.VPM);
  const isBPMEnabled = useCanEveryCompanyService(services.BPM);
  const isDriverPinServiceEnabled = useCanEveryCompanyService(services.DRIVERPIN);
  const isDriverEnabled = driverLoginalertFlag ? isDriverPinServiceEnabled : false;

  const isSpeedsirenEnabled = useCanEveryCompanyService(services.SPEEDSIREN);

  const {
    gpioData: gpioConfigs,
    vpmData,
    bpmData,
    driverData,
    speedsirenData,
    isFetching
  } = useGpioRelatedConfigKeys();

  const vpmConfigs = vpmData?.filter(s => BPM_VPM_KEYS.includes(s.key));
  const bpmConfigs = bpmData?.filter(s => BPM_VPM_KEYS.includes(s.key));
  const idleConfigs = isVPMEnabled
    ? vpmData?.filter(s => IDLE_KEYS.includes(s.key))
    : bpmData?.filter(s => IDLE_KEYS.includes(s.key));
  const driverConfigs = driverData?.filter(s => DRIVER_KEYS.includes(s.key));
  const sirenConfigs = speedsirenData?.filter(s => SIREN_KEYS.includes(s.key));
  const ledConfigs = speedsirenData?.filter(s => LED_KEYS.includes(s.key));

  const extraConfigs = [];
  if (isVPMEnabled) {
    extraConfigs.push(constructForm('vpm', vpmConfigs));
  }
  if (isBPMEnabled) {
    extraConfigs.push(constructForm('bpm', bpmConfigs));
  }
  if (isSpeedsirenEnabled) {
    extraConfigs.push(
      constructForm(
        'speedsiren',
        sirenConfigs?.filter(s => !s.key.endsWith('.1'))
      )
    );
    extraConfigs.push(constructForm('led', ledConfigs, 'led'));
  }
  if (isVPMEnabled || isBPMEnabled) {
    extraConfigs.push(constructForm('idle', idleConfigs));
  }
  if (isDriverEnabled) {
    extraConfigs.push(constructForm('driver', driverConfigs));
  }

  return {
    gpioConfigs,
    vpmConfigs,
    bpmConfigs,
    idleConfigs,
    driverConfigs,
    sirenConfigs,
    extraConfigs,
    isFetching
  };
};
