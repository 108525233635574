import { TNDataService } from '../tnDataService';
import { reduxAdapter } from './reduxAdapter';

const vehicleStatsDataAdapter = reduxAdapter({
  persist: async vehicleStats => {
    if (vehicleStats && vehicleStats.length) {
      await TNDataService.insertOrUpdateVehicleStatsInBulk(vehicleStats);
    }
  },
  load: async () => {
    const vehicleStats = await TNDataService.getAllVehicleStats();
    return vehicleStats;
  },
  lastUpdatedDate: async () => {
    const result = await TNDataService.getEntityLastUpdate('ng_vehicle_stats');
    return result;
  },
  updateLastUpdatedDate: async lastpUpdatedDate => {
    const result = await TNDataService.putEntityLastUpdate('ng_vehicle_stats', lastpUpdatedDate);
    return result;
  }
});

export { vehicleStatsDataAdapter };
