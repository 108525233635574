export function deviceModelObjectToDBO(deviceModel) {
  return {
    id: deviceModel.id,
    name: deviceModel.name,
    description: deviceModel.description,
    created_at: deviceModel.createdAt,
    updated_at: deviceModel.updatedAt,
    device_type_id: deviceModel.deviceType?.id
  };
}

export function deviceModelDBOToObject(deviceModel) {
  return {
    id: deviceModel.id,
    name: deviceModel.name,
    description: deviceModel.description,
    createdAt: deviceModel.createdAt,
    updatedAt: deviceModel.updatedAt,
    deviceType: {
      id: deviceModel.device_type_id
    }
  };
}
