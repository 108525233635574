import React, { useMemo } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, FormGroup, FormControl, FormLabel, Alert } from 'react-bootstrap';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Button as AntdButton } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { LoginLogo } from './LoginLogo';
import { login, useUser, useIsUserBlocked, useIsUserCompanyBlocked } from 'features/user/userSlice';
import {
  useCan,
  FeatureFlag,
  GlobalRoles,
  services,
  entities,
  useIQCameraUser
} from 'features/permissions';

import styles from './Login.module.scss';

import {
  fetchPermissions,
  useServicePermissons,
  usePermissions
} from 'features/permissions/permissionsSlice';
import {
  fetchCompanies,
  useCurrentCompanyServices,
  useCompanyServicesFetchingDone
} from 'features/company/companySlice';
import { CompanyLocked } from 'components/pages/NoAccess';
import { LANGUAGE_LOCALES } from 'features/localization/languages';
import { changeMapLanguage } from 'features/maps/maps';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { usePages } from 'Pages';

const LoginForm = ({ isLoading, onSubmit }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const disabled = useMemo(() => !(username.length > 0 && password.length > 0 && !isLoading), [
    username,
    password,
    isLoading
  ]);

  const handleSubmit = event => {
    event.preventDefault();
    localStorage.setItem('userActionTime', moment().format('X'));
    onSubmit(username, password);
  };

  return (
    <div className={styles.Login}>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username">
          <FormLabel>{t('Username')}</FormLabel>
          <FormControl
            autoFocus
            type="username"
            value={username}
            onChange={event => setUsername(event.target.value)}
            autoComplete="off"
            tabIndex={1}
          />
        </FormGroup>
        <FormGroup controlId="password">
          <FormGroup className={styles.password}>
            <FormLabel>{t('Password')}</FormLabel>
            <Link className={styles.forgotPassword} to="/resetpassword">
              {t('Forgot Password')}?
            </Link>
          </FormGroup>

          <FormControl
            value={password}
            onChange={event => setPassword(event.target.value)}
            type="password"
            autoComplete="no-pwd-store"
            tabIndex={2}
          />
        </FormGroup>
        <Button block id={BUTTON_IDS.loginSignIn} disabled={disabled} type="submit" tabIndex={3}>
          {t('Sign In')}
        </Button>
      </form>
    </div>
  );
};

const ErrorMessage = () => {
  const { t } = useTranslation();
  const userIsBlocked = useIsUserBlocked();
  return (
    <Alert className={styles.errorMessage}>
      {!userIsBlocked?.isBlocked && <div>{t('LoginFailed')}</div>}
      {userIsBlocked?.attempsLeft > 0 && (
        <div>{`${userIsBlocked?.attempsLeft} ${t('AttemptsRemaining')}`}</div>
      )}
      {userIsBlocked?.isBlocked && <div>{t('AccountLocked')}</div>}
    </Alert>
  );
};

const Login = props => {
  const dispatch = useDispatch();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [error, setError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const can = useCan();
  const userInfo = useUser();
  const servicesPermissions = useServicePermissons();
  const userPermissions = usePermissions();
  const companyServices = useCurrentCompanyServices();
  const companyServicesDone = useCompanyServicesFetchingDone();
  const userCompanyIsBlocked = useIsUserCompanyBlocked();
  const { i18n, t } = useTranslation();
  const userPreferences = useUserPreferences();
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const pages = usePages();

  const isNonProd = can({ featureFlag: [FeatureFlag.changeLanguage.flag] });

  const regexp = /360-(.*).tele/gi;

  let languageValues = [...Object.values(LANGUAGE_LOCALES), 'test', 'cimode'];
  const regionMapping = {
    LATAM: [
      LANGUAGE_LOCALES.ES_MX,
      LANGUAGE_LOCALES.EN_MX,
      LANGUAGE_LOCALES.EN_CL,
      LANGUAGE_LOCALES.ES_CL
    ],
    MX: [
      LANGUAGE_LOCALES.ES_MX,
      LANGUAGE_LOCALES.EN_MX,
      LANGUAGE_LOCALES.EN_CL,
      LANGUAGE_LOCALES.ES_CL
    ],
    US: [LANGUAGE_LOCALES.ES_US, LANGUAGE_LOCALES.EN_US, LANGUAGE_LOCALES.EN_CA],
    AU: [LANGUAGE_LOCALES.EN_AU, LANGUAGE_LOCALES.EN_NZ],
    NZ: [LANGUAGE_LOCALES.EN_AU, LANGUAGE_LOCALES.EN_NZ],
    CA: [LANGUAGE_LOCALES.EN_CA],
    UK: [LANGUAGE_LOCALES.EN_GB],
    DA: [LANGUAGE_LOCALES.DA_DK],
    FR: [LANGUAGE_LOCALES.FR_FR]
  };
  const regexpRes = regexp.exec(window.location.hostname);
  if (!isNonProd && regexpRes != null) {
    languageValues = regionMapping[regexpRes[1].toUpperCase()];
  }

  const lngStored = (localStorage.getItem('i18nextLng') || LANGUAGE_LOCALES.EN_AU).split('-');
  const currentLanguage =
    lngStored.length === 1 ? lngStored[0] : lngStored[0] + '-' + lngStored[1].toUpperCase();

  const handleLanguageChange = lang => {
    sessionStorage.setItem('newLoginLanguageSelected', true);
    i18n.changeLanguage(lang);
    changeMapLanguage(lang, currentLanguage);
  };

  const onLanguageChange = e => {
    handleLanguageChange(e.key);
  };

  const redirectPath = () => {
    const locationState = props.location.state;
    let pathname = locationState && locationState.from && locationState.from.pathname;
    if (pathname === '/login') {
      pathname = '/';
    }
    if (pathname === '/logout') {
      pathname = '/';
    }

    if (locationState?.from?.search != null) {
      pathname += locationState.from.search;
    }

    if (
      userInfo.roles &&
      userInfo.roles.some(role => role.name === GlobalRoles.TrackingOnlyAccess)
    ) {
      pathname = '/tracking';
    }

    const isDriverPortal = can({
      everyCompanyService: [services.ELD],
      everyEntity: [entities.DRIVERPORTAL],
      featureFlag: [FeatureFlag.driverPortal.flag],
      otherConditions: [() => userInfo?.type?.code === 'DRIVER']
    });

    if (isDriverPortal) {
      pathname = '/fatigue/eld/driverportal/' + userInfo.id;
    }

    if (userPreferences && userPreferences.homeUrl && canAccessNonCameraFeatures) {
      const accessiblePages = pages.filter(page => can({ ...page }));

      if (accessiblePages.find(i => i.link === userPreferences.homeUrl)) {
        pathname = userPreferences.homeUrl;
      }
    }

    // If user has no roles but has access to a service/entity - redirect to that
    if (pathname === '/' && !userInfo.roles?.length) {
      const serviceToPathName = {
        [services.FBTMANAGER]: '/fbtmanager',
        [services.TRACKING]: '/tracking',
        [services.TRACKINGHD]: '/tracking',
        [services.SCORECARD]: '/scorecard',
        [services.SMARTJOBS]: '/smartjobs',
        [services.MESSAGING]: '/messaging',
        [services.INSIGHTSBASIC]: '/insights',
        [services.INSIGHTSSEARCH]: '/insights',
        [services.INSIGHTSADVPROD]: '/insights',
        [services.INSIGHTSADVSAFETY]: '/insights',
        [services.INSIGHTSADVCOMP]: '/insights',
        [services.INSIGHTSBUILDER]: '/insights',
        [services.INSIGHTSVIZ]: '/insights',
        [services.INSIGHTSAI]: '/insights',
        [services.INSIGHTSSUSTAINABILITYSNAPSHOT]: '/insights',
        [services.INSIGHTSSUSTAINABILITYGOALS]: '/insights',
        [services.INSIGHTSSUSTAINABILITYFLEETS]: '/insights',
        [services.INSIGHTSREPORTS]: '/insights',
        [services.INSIGHTSNPI]: '/insights',
        [services.DIRECTORINSIGHTS]: '/insights',
        [services.VEHMNT]: '/vehiclemaintenance',
        [services.DMGT]: '/drivermanagement',
        [services.EASYDOCS]: '/easydocs',
        [services.JOURNEYPLANNER]: '/journeyplanner',
        [services.TACHO]: '/fatigue/tacho',
        [services.ELD]: '/fatigue/eld',
        [services.EWD]: '/fatigue',
        [services.SWD]: '/fatigue'
      };

      if (servicesPermissions?.length) {
        if (servicesPermissions.includes(services.COMMON)) {
          pathname = '/home';
        } else {
          pathname = serviceToPathName[servicesPermissions[0]];
        }
      }
    }

    return pathname || '/';
  };

  const handleSubmit = (username, password) => {
    setError(false);
    setIsLoading(true);
    dispatch(login(username, password, fetchPermissions, fetchCompanies)).then(response => {
      if (response === 'error') {
        setShouldRedirect(false);
        setError(true);
        setIsLoading(false);
        return;
      }
    });
  };

  const renderLanguageMenu = () => {
    const menuItems = [];

    languageValues.forEach(language => {
      menuItems.push({
        key: language,
        label: (
          <div>
            {t(
              `Preferences.ChangeLanguage.${
                !isNonProd && regexpRes != null ? language.split('-')[0].toLowerCase() : language
              }`
            )}
          </div>
        )
      });
    });

    return menuItems;
  };

  if (
    shouldRedirect ||
    (userInfo != null &&
      userInfo.auth.key != null &&
      userInfo?.type?.code &&
      userPermissions &&
      companyServices &&
      companyServicesDone &&
      userPreferences)
  ) {
    return <Redirect to={redirectPath()} />;
  }

  return userCompanyIsBlocked ? (
    <CompanyLocked />
  ) : (
    <div className={styles.loginContainer}>
      {isNonProd && (
        <div className={styles.languageDropdownContainer}>
          <Dropdown
            menu={{
              items: renderLanguageMenu(),
              onClick: onLanguageChange,
              selectable: true,
              defaultSelectedKeys: currentLanguage
            }}
          >
            <AntdButton className={isNonProd ? styles.languageButtonNonPrd : styles.languageButton}>
              {t(
                `Preferences.ChangeLanguage.${
                  !isNonProd && regexpRes != null
                    ? currentLanguage.split('-')[0].toLowerCase()
                    : currentLanguage
                }`
              )}
              <CaretDownOutlined className={styles.caretDown} />
            </AntdButton>
          </Dropdown>
        </div>
      )}
      <div className={styles.commonContainer}>
        <LoginLogo />
        <p>{t('Sign In')}</p>
        <div className={styles.form}>
          {error ? <ErrorMessage /> : ''}
          <LoginForm isLoading={isLoading} onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Login;
