import React, { useState, useEffect } from 'react';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { Button } from 'antd';
import { Widget } from './Widget';
import { useTranslation } from 'react-i18next';
import { WidgetsDrawer } from './WidgetsDrawer';
import classNames from 'classnames';
import styles from './NewHome.module.scss';
import { useGetActiveDevicesQuery, useGetDriversQuery } from 'services/nextgen';

export const NewHome = () => {
  const { t } = useTranslation();
  const { data: driversData } = useGetDriversQuery();
  const { data: devicesData } = useGetActiveDevicesQuery();
  //TODO: Proper re-render for when data is fetched

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [widgets, setWidgets] = useState([
    { size: 'large', id: 1, isSelected: true, title: `Title1-large` },
    {
      size: 'medium',
      id: 2,
      isSelected: true,
      type: 'activeDrivers',
      widgetData: driversData
      //title: 'Title2-medium'
    },
    {
      size: 'medium',
      id: 3,
      isSelected: true,
      type: 'activeDevices',
      widgetData: devicesData
      //title: 'Title3-medium'
    },
    { size: 'medium', id: 4, isSelected: true, title: 'Title4-medium' },
    { size: 'small', id: 5, isSelected: true, title: 'Title5-small' },
    { size: 'small', id: 6, isSelected: true, title: 'Title6-small' },
    { size: 'medium', id: 7, isSelected: false, title: 'Title7-medium' },
    { size: 'small', id: 8, isSelected: false, title: 'Title8-small' },
    { size: 'large', id: 9, isSelected: false, title: 'Title9-large' },
    { size: 'small', id: 10, isSelected: false, title: 'Title10-small' }
  ]);

  useEffect(() => {
    setWidgets([
      { size: 'large', id: 1, isSelected: true, title: `Title1-large` },
      {
        size: 'medium',
        id: 2,
        isSelected: true,
        type: 'activeDrivers',
        widgetData: driversData
        //title: 'Title2-medium'
      },
      {
        size: 'medium',
        id: 3,
        isSelected: true,
        type: 'activeDevices',
        widgetData: devicesData
        //title: 'Title3-medium'
      },
      { size: 'medium', id: 4, isSelected: true, title: 'Title4-medium' },
      { size: 'small', id: 5, isSelected: true, title: 'Title5-small' },
      { size: 'small', id: 6, isSelected: true, title: 'Title6-small' },
      { size: 'medium', id: 7, isSelected: false, title: 'Title7-medium' },
      { size: 'small', id: 8, isSelected: false, title: 'Title8-small' },
      { size: 'large', id: 9, isSelected: false, title: 'Title9-large' },
      { size: 'small', id: 10, isSelected: false, title: 'Title10-small' }
    ]);
  }, [driversData, devicesData]);

  const toggleSelectedWidgets = id => {
    setWidgets(prevWidgets =>
      prevWidgets.map(widget =>
        widget.id === id ? { ...widget, isSelected: !widget.isSelected } : widget
      )
    );
  };

  return (
    <section
      className={classNames(styles.newHome, isOpenDrawer ? styles.drawerOpen : styles.drawerClosed)}
    >
      <WidgetsDrawer
        open={isOpenDrawer}
        setOpen={setIsOpenDrawer}
        widgetItems={widgets}
        handleAdd={toggleSelectedWidgets}
      />
      <HeaderPageWrapper>
        <Button
          type="secondary"
          size="middle"
          className={styles.newHomeEditButton}
          onClick={() => setIsOpenDrawer(true)}
        >
          {t('NewHome.AddWidgets')}
        </Button>
      </HeaderPageWrapper>
      <div className={styles.newHomeGridWrapper}>
        {widgets.map(
          widget =>
            widget.isSelected && (
              <Widget
                key={widget.id}
                size={widget.size}
                type={widget.type}
                widgetData={widget.widgetData}
                widget={widget}
                handleDelete={toggleSelectedWidgets}
              />
            )
        )}
      </div>
    </section>
  );
};
