import i18n from 'i18next';
import { MeterType } from './meterType';

export const getMeterValue = meter => {
  const ret = meter.useDifferential || meter.is_diff ? meter.base + meter.diff : meter.value;
  return isNaN(ret) ? 0 : ret;
};

export const getCalculatedBy = meter => {
  return meter.useDifferential ? i18n.t('Vehicles.Elapsed') : i18n.t('Vehicles.Actual');
};

export const formatMeterValue = (localization, meter) => {
  switch (meter.type.toLowerCase()) {
    case MeterType.OilLifeRemaining:
    case MeterType.FuelLevel:
      return `${meter.value.toFixed(1)}%`;
    case MeterType.Battery:
      return meter.value.toFixed(1) + ' V';
    case MeterType.Odometer:
    case MeterType.Distance:
      return localization.formatDistance(getMeterValue(meter));
    case MeterType.Hours:
      return (
        getMeterValue(meter)?.toLocaleString(localization.locale, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }) +
        ' ' +
        i18n.t('Common.hrs')
      );
    case MeterType.Fuel:
      return localization.formatFuel(meter.value);
    case MeterType.Temperature:
      return localization.formatTemperature(meter.value);
    case MeterType.Charge:
      return meter.value.toFixed(1) + ' %';
    case MeterType.Soc:
      return `${meter.value.toFixed(1)}%`;
    case MeterType.Range:
      return localization.formatDistance(meter.value);
    default:
      return meter.value.toFixed(1);
  }
};

export const formatGPIOValue = (localization, meter) => {
  return (
    getMeterValue(meter)?.toLocaleString(localization.locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }) +
    ' ' +
    i18n.t('Common.hrs')
  );
};

export const formatMeterTemp = (localization, meter) => {
  // Conditional object creation
  if (meter && meter.length > 0) {
    const meterData = {
      type: meter[0]?.type,
      value: meter[0]?.value,
      useDifferential: false
    };

    return formatMeterValue(localization, meterData);
  }
};

export const formatMeterBase = (localization, meter) => {
  return formatMeterValue(localization, {
    type: meter.type,
    value: meter.base,
    useDifferential: false
  });
};

export const formatMeterDiff = (localization, meter) => {
  return formatMeterValue(localization, {
    type: meter.type,
    value: meter.diff,
    useDifferential: false
  });
};

export const formatEngineHours = (localization, value) => {
  return (
    value?.toString() &&
    value.toLocaleString(localization.locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }) +
      ' ' +
      i18n.t('Common.hrs')
  );
};

export const formatOdometer = (localization, value) => {
  return value?.toString() && localization.formatDistance(value);
};

// returns the meter object by type
export const getMeterByType = (meter, type) => {
  return meter?.meters?.find(meter => meter.type === type);
};

// returns the meter object by source
export const getMeterBySource = (meter, source) => {
  return meter?.meters?.find(meter => meter.source === source);
};

export const getVehicleOdometer = (
  vehicleMeterOdometer,
  engineSummarySource,
  canOdometer,
  canDiffOdometer,
  gpsOdometer
) => {
  if (vehicleMeterOdometer) {
    return getMeterValue(vehicleMeterOdometer);
  } else {
    switch (engineSummarySource) {
      case 'CAN':
        return canOdometer;
      case 'CAN_DIFF':
        return canDiffOdometer;
      default:
        return gpsOdometer;
    }
  }
};

export const getVehicleEngineHours = (
  vehicleMeterHours,
  engineSummarySource,
  gpsEngineHours,
  canEngineHours,
  canDiffEngineHours
) => {
  if (vehicleMeterHours) {
    return getMeterValue(vehicleMeterHours);
  } else {
    switch (engineSummarySource) {
      case 'GPS_DIFF':
        return gpsEngineHours;
      case 'CAN':
        return canEngineHours;
      default:
        return canDiffEngineHours;
    }
  }
};
