import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

//components
import { Table } from 'components/ant';

//styles
import styles from './AuditsTable.module.scss';

//slices
import { useIsAuditsFetching } from 'features/audits';
import { useLocalization } from 'features/localization/localizationSlice';

//helpers
import { prepareAuditsColumnsForTable, prepareAuditsDataForTable } from './helpers';
import { useCan } from 'features/permissions';
import { PROTECTED_AUDIT_KEYS } from './constants';

export const AuditsTable = ({
  audits,
  entity,
  timeKeys,
  hasAuditKeyPermission = auditKey => true,
  hideEmptyChangeItem = false,
  onRenderChangeItemValue = (displayValue, { changeItem, changeKey, colKey }) => displayValue,
  onRenderChangeItemKey = changeKey => changeKey,
  isLoading = false
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const isFetching = useIsAuditsFetching();
  const can = useCan();

  const hasPermission = useCallback(
    auditKey =>
      PROTECTED_AUDIT_KEYS[auditKey]?.can
        ? can(PROTECTED_AUDIT_KEYS[auditKey].can)
        : hasAuditKeyPermission
        ? hasAuditKeyPermission(auditKey)
        : true,
    [can]
  );

  const showPagination =
    (audits?.length > 100 && { pageSize: 100, showSizeChanger: false }) || false;
  const paginationHeight = (showPagination && 55) || 0;

  return (
    <Table
      className={styles.auditsTable}
      dataSource={prepareAuditsDataForTable(
        audits || [],
        localization,
        t,
        timeKeys,
        hasPermission,
        onRenderChangeItemValue,
        onRenderChangeItemKey,
        hideEmptyChangeItem
      )}
      columns={prepareAuditsColumnsForTable(t)}
      pagination={showPagination}
      loading={isLoading || isFetching}
      sticky
      size="small"
      scroll={{ y: `calc(100vh - 100px - ${paginationHeight}px)` }}
    />
  );
};
