import React from 'react';
import { Switch } from 'react-router-dom';
import CanRoute from 'components/route/CanRoute';
import { Proximity } from './Proximity/Proximity';
import { MapTiles } from './MapTiles/MapTiles';
import { CardView } from './CardView/CardView';
import { Track } from './Track/Track';
import { TrackingPaths } from './constants';
import { TrackEventsTabs } from './Track/TrackEvents';
import GlobalRoles from 'features/permissions/GlobalRoles';
import { services } from 'features/permissions';

export const TrackingRouter = () => {
  return (
    <Switch>
      <CanRoute path={TrackingPaths.TrackSummary} render={<Track tab={TrackEventsTabs.Events} />} />
      <CanRoute
        path={TrackingPaths.TrackDetails}
        beyondRoles={[GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]}
        render={<Track tab={TrackEventsTabs.Details} />}
      />
      <CanRoute
        path={TrackingPaths.TrackParameters}
        render={<Track tab={TrackEventsTabs.Parameters} />}
      />
      <CanRoute
        path={TrackingPaths.TrackHDData}
        everyService={[services.TRACKINGHD]}
        everyCompanyService={[services.TRACKINGHD]}
        // Setting key to force reset of state for hddata version of page
        render={<Track tab={TrackEventsTabs.Events} hddata={true} key="hddata" />}
      />
      <CanRoute path={TrackingPaths.Proximity} render={<Proximity />} />
      <CanRoute path={TrackingPaths.MapTiles} render={<MapTiles />} />
      <CanRoute path={TrackingPaths.CardView} render={<CardView />} />
      <CanRoute path={[TrackingPaths.Track, TrackingPaths.Default]} render={<Track />} />
    </Switch>
  );
};
